import React, { ElementType, PropsWithChildren, ReactNode, useRef, MouseEvent } from 'react';

import { UserIdentity } from '../../../graphql/fragments';
import { MenuItem, Typography } from '../../../ui';
import { UserAvatar } from '../../asset-display';
import { FormatDate } from '../../formatters';

import styles from './Notification.module.scss';

let globalId = 0;

interface NotificationProps extends VecticeHTMLProps<HTMLLIElement> {
  creator?: UserIdentity | null;
  createdDate: Date;
  extra?: ReactNode;
  onClose?: () => void;
}

export const Notification = <E extends ElementType>({
  creator,
  createdDate,
  extra,
  onClick,
  onClose,
  children,
  ...props
}: PropsWithChildren<NotificationProps> & WithComponentProps<E>) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(globalId++);

  return (
    <MenuItem
      {...props}
      clickable
      color="secondary"
      onClick={(e: MouseEvent) => {
        onClick?.(e);
        onClose?.();
      }}
      role="listitem"
      aria-labelledby={`notification-label-${id}`}
      className={styles.notification}
    >
      <UserAvatar user={creator} size="sm" aria-hidden />
      <div className={styles.content}>
        <Typography component="div" variant="callout" color="primary" paragraph id={`notification-label-${id}`}>
          {children}
        </Typography>
        {extra || null}
        <Typography component="div" variant="footnote" color="tertiary">
          <FormatDate date={createdDate} fromNow />
        </Typography>
      </div>
    </MenuItem>
  );
};
