import React, { useEffect, useRef } from 'react';

import { getActivityTargetTypeOptions } from '../../../entities/utils';
import { ActivityTargetType } from '../../../gql/graphql';
import { Icon } from '../../../ui';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'activityTargetTypes';

const sizeIcon = 18;

export interface ActivityTargetFilterProps {
  activityTargetTypes: ActivityTargetType[];
}

class ActivityTypeFilterNode<T extends { activityTargetTypes: ActivityTargetType[] }> extends FilterNode<T> {
  private options = getActivityTargetTypeOptions()
    .map(({ icon, ...option }) => ({
      ...option,
      icon: icon ? <Icon icon={icon} size={sizeIcon} /> : null,
    }))
    .filter((option) => this.props?.activityTargetTypes.includes(option.value));

  updateProps(props?: T) {
    super.updateProps(props);
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="activity-type-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const ActivityTypeFilter = ({ activityTargetTypes }: ActivityTargetFilterProps) => {
  const { current: node } = useRef(new ActivityTypeFilterNode({ activityTargetTypes }));

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.activityType.label', defaultMessage: 'Activity Type' }),
    node,
  });

  useEffect(() => {
    node.updateProps({ activityTargetTypes });
  }, [activityTargetTypes]);

  return null;
};
