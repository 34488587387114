import { DocumentationPageStatus } from '../../gql/graphql';
import { ProgressBarColor } from '../../ui';

type documentationPageStatusType = DocumentationPageStatus | string | null;

export const getDocumentationPageStatusDisplayName = (status?: documentationPageStatusType) => {
  switch (status) {
    case 'Draft':
      return $t({ id: 'documentationPageStatus.inProgress.label', defaultMessage: 'In Progress' });
    case 'Completed':
      return $t({ id: 'documentationPageStatus.completed.label', defaultMessage: 'Completed' });
    case 'InReview':
      return $t({ id: 'documentationPageStatus.inReview.label', defaultMessage: 'In Review' });
    case 'NotStarted':
      return $t({ id: 'documentationPageStatus.notStarted.label', defaultMessage: 'Not Started' });
    default:
      return status || $t({ id: 'documentationPageStatus.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getDocumentationPageStatusColor = (status?: documentationPageStatusType): ProgressBarColor => {
  switch (status) {
    case DocumentationPageStatus.Draft:
      return 'on-going';
    case DocumentationPageStatus.InReview:
      return 'on-track';
    case DocumentationPageStatus.Completed:
      return 'success';
    case DocumentationPageStatus.NotStarted:
    default:
      return 'default';
  }
};

export const getDocumentationPageStatusOptions = () => [
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.NotStarted),
    value: DocumentationPageStatus.NotStarted,
  },
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.Draft),
    value: DocumentationPageStatus.Draft,
  },
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.Completed),
    value: DocumentationPageStatus.Completed,
  },
];

export const getDocumentationPageStatusFullOptions = () => [
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.NotStarted),
    value: DocumentationPageStatus.NotStarted,
  },
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.Draft),
    value: DocumentationPageStatus.Draft,
  },
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.InReview),
    value: DocumentationPageStatus.InReview,
  },
  {
    label: getDocumentationPageStatusDisplayName(DocumentationPageStatus.Completed),
    value: DocumentationPageStatus.Completed,
  },
];
