import { FindingStatus } from '../../gql/graphql';
import { IconColors } from '../../ui';

type findingStatusType = FindingStatus | string | null;

export const getFindingStatusDisplayName = (status?: findingStatusType) => {
  switch (status) {
    case FindingStatus.Cancelled:
      return $t({ id: 'issueStatus.cancelled.label', defaultMessage: 'Cancelled' });
    case FindingStatus.Completed:
      return $t({ id: 'issueStatus.completed.label', defaultMessage: 'Completed' });
    case FindingStatus.InProgress:
      return $t({ id: 'issueStatus.inProgress.label', defaultMessage: 'In progress' });
    case FindingStatus.NotStarted:
      return $t({ id: 'issueStatus.notStarted.label', defaultMessage: 'To do' });
    case FindingStatus.RemediationPending:
      return $t({ id: 'issueStatus.remediationPending.label', defaultMessage: 'Remediation pending' });
    default:
      return status || $t({ id: 'issueStatus.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getFindingStatusColor = (status?: findingStatusType): IconColors => {
  switch (status) {
    case FindingStatus.Cancelled:
      return 'abandoned';
    case FindingStatus.InProgress:
      return 'on-track';
    case FindingStatus.Completed:
      return 'success';
    case FindingStatus.RemediationPending:
      return 'accent';
    case FindingStatus.NotStarted:
    default:
      return 'default';
  }
};

export const getFindingStatusOptions = () => [
  {
    label: getFindingStatusDisplayName(FindingStatus.NotStarted),
    value: FindingStatus.NotStarted,
  },
  {
    label: getFindingStatusDisplayName(FindingStatus.InProgress),
    value: FindingStatus.InProgress,
  },
  {
    label: getFindingStatusDisplayName(FindingStatus.Completed),
    value: FindingStatus.Completed,
  },
  {
    label: getFindingStatusDisplayName(FindingStatus.RemediationPending),
    value: FindingStatus.RemediationPending,
  },
  {
    label: getFindingStatusDisplayName(FindingStatus.Cancelled),
    value: FindingStatus.Cancelled,
  },
];
