import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo, useState } from 'react';
import { Control, Controller, UseFormRegister, UseFormSetFocus } from 'react-hook-form';

import { ReactComponent as AddIcon } from '../../../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/interface/ic-remove.svg';
import { CodeVersionType } from '../../../../gql/graphql';
import { useEntityFiles } from '../../../../hooks';
import { Button, FlexContainer, Icon, Input, Picker, Select, Typography, Upload } from '../../../../ui';
import {
  defaultNameFieldConfiguration,
  defaultValueFieldConfiguration,
  getMimeTypeIcon,
  validateURL,
  VecticeResourceType,
} from '../../../../utils';
import { commonDatePickerConfig } from '../../../filters/ui';
import { FULL_FORMAT } from '../../../formatters';
import { DeletionPrompt } from '../../../modals/DeletionPrompt';
import { LineageFormData, inputsLabel } from '../type';

import styles from './CodeTab.module.scss';

interface CodeTabProps {
  allowFileUpload?: boolean;
  control: Control<LineageFormData, any>;
  lineageId?: number;
  type?: CodeVersionType;
  registerWithErrors: UseFormRegister<LineageFormData>;
  setFocus: UseFormSetFocus<LineageFormData>;
  setType: (type?: CodeVersionType) => void;
}

const commonConfig = {
  ...commonDatePickerConfig,
  allowClear: true,
  clearIcon: <Icon className={styles.picker_icon} icon={ClearIcon} size={20} />,
  format: FULL_FORMAT,
  showTime: {
    use12Hours: true,
    showSecond: false,
    format: 'hh:mm A',
  },
  use12Hours: true,
  disabledDate: (date: Dayjs) => date.isAfter(undefined),
};

export const CodeTab = ({
  allowFileUpload,
  control,
  lineageId,
  type,
  registerWithErrors,
  setFocus,
  setType,
}: CodeTabProps) => {
  const { attachments, addEntityFiles, removeEntityFile, uploading } = useEntityFiles(
    lineageId,
    VecticeResourceType.RUN,
  );
  const [showFileToDelete, setShowFileToDelete] = useState(false);

  const displayUploadTab = allowFileUpload && !!lineageId;

  const typeOptions = useMemo(
    () => [
      {
        id: 'None',
        label: displayUploadTab
          ? $t({ id: 'component.editLineage.tab.codeFile', defaultMessage: 'Code file' })
          : $t({ id: 'component.editLineage.tab.noCodeSource', defaultMessage: 'None' }),
        value: 'None',
      },
      {
        id: CodeVersionType.Git,
        label: $t({ id: 'component.editLineage.tab.git', defaultMessage: 'Git' }),
        value: CodeVersionType.Git,
      },
      {
        id: CodeVersionType.Databricks,
        label: $t({ id: 'component.editLineage.tab.databricks', defaultMessage: 'Databricks' }),
        value: CodeVersionType.Databricks,
      },
      {
        id: CodeVersionType.Other,
        label: $t({ id: 'component.editLineage.tab.other', defaultMessage: 'Other' }),
        value: CodeVersionType.Other,
      },
    ],
    [displayUploadTab],
  );

  return (
    <>
      <Select
        className={styles.inputs}
        label={$t({ id: 'editLineageModal.origin.edit.source', defaultMessage: 'Source type' })}
        hint={null}
        value={type ?? 'None'}
        onChange={(e) => {
          setType(e.currentTarget.value === 'None' ? undefined : (e.currentTarget.value as CodeVersionType));
          setFocus('codeLineageInput.url');
        }}
        options={typeOptions}
      />
      {type ? (
        <>
          <Input
            {...registerWithErrors('codeLineageInput.url', {
              ...defaultValueFieldConfiguration(),
              validate: (url) =>
                !!validateURL(url) ||
                $t({ id: 'component.editLineage.modal.error.url', defaultMessage: 'This is not a valid URL' }),
              required: $t({ id: 'editLineageModal.origin.requiredURL', defaultMessage: 'A URL is required' }),
            })}
            label={inputsLabel[type].url()}
            className={styles.inputs}
            autoFocus
            clearable
          />
          <Input
            {...registerWithErrors('codeLineageInput.firstAdditionalInformation', {
              ...defaultNameFieldConfiguration(),
              required: false,
            })}
            label={inputsLabel[type].firstAdditionalInformation()}
            className={styles.inputs}
            clearable
          />
          {type === CodeVersionType.Databricks && (
            <>
              <FlexContainer className={styles.dateTime} align="center" justify="space-between" gap={0}>
                <Typography
                  htmlFor="databricks-datetime"
                  component="label"
                  variant="callout"
                  weight="semi-bold"
                  color="primary"
                >
                  {inputsLabel[type].secondAdditionalInformation()}
                </Typography>
                <Typography variant="callout" color="tertiary">
                  {$t({ id: 'modal.createResource.optional', defaultMessage: 'Optional' })}
                </Typography>
              </FlexContainer>

              <Controller
                aria-label="databricks-datetime"
                control={control}
                name="codeLineageInput.secondAdditionalInformation"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Picker<Dayjs>
                      {...commonConfig}
                      placeholder={$t({
                        id: 'editLineageModal.origin.timeStamp',
                        defaultMessage: 'Select a timestamp',
                      })}
                      className={styles.inputs}
                      value={value ? dayjs(value) : null}
                      onSelect={onChange}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </>
          )}
          {type !== CodeVersionType.Databricks && (
            <Input
              {...registerWithErrors('codeLineageInput.secondAdditionalInformation', {
                ...defaultNameFieldConfiguration(),
                required: false,
              })}
              label={inputsLabel[type].secondAdditionalInformation()}
              className={styles.inputs}
              clearable
            />
          )}
          <Input
            {...registerWithErrors('codeLineageInput.thirdAdditionalInformation', {
              ...defaultNameFieldConfiguration(),
              required: false,
            })}
            label={inputsLabel[type].thirdAdditionalInformation()}
            clearable
          />
        </>
      ) : (
        allowFileUpload &&
        lineageId && (
          <>
            <Typography variant="callout" color="tertiary">
              {$t({
                id: 'modal.attachment.optional',
                defaultMessage: 'Add a code source file if no official code source is available',
              })}
            </Typography>
            <div className={styles.attachmentContainer}>
              {!attachments[0] && (
                <FlexContainer justify="center">
                  <Upload
                    accept=".ipynb, .py, .r"
                    icon={AddIcon}
                    multiple={false}
                    variant="white"
                    onUpload={(file) => addEntityFiles([file])}
                    disabled={uploading}
                    uploading={uploading}
                  >
                    {$t({ id: 'file.button.add', defaultMessage: 'Add file' })}
                  </Upload>
                </FlexContainer>
              )}
              {!!attachments[0] && (
                <FlexContainer align="center" justify="space-between">
                  <FlexContainer align="center">
                    <Icon icon={getMimeTypeIcon(attachments[0].mimeType)} />
                    <Typography>{attachments[0].fileName}</Typography>
                  </FlexContainer>
                  <Button
                    leftIcon={DeleteIcon}
                    color="gray"
                    variant="phantom"
                    onClick={() => setShowFileToDelete(true)}
                  />
                </FlexContainer>
              )}
              {showFileToDelete && !!attachments[0] && (
                <DeletionPrompt
                  resourceName={attachments[0].fileName || ''}
                  title={$t({ id: 'VersionFiles.deleteFile.title', defaultMessage: 'Delete file' })}
                  onClose={() => setShowFileToDelete(false)}
                  onConfirm={() => {
                    removeEntityFile({ ...attachments[0], name: attachments[0].fileName ?? '' });
                    setShowFileToDelete(false);
                  }}
                />
              )}
            </div>
          </>
        )
      )}
    </>
  );
};
