import React from 'react';

import { getUserRoleDisplayName } from '../../entities/utils';
import { UserRole } from '../../gql/graphql';
import { Badge, BadgeProps } from '../../ui';

interface UserRoleBadgeProps extends BadgeProps {
  userRole?: UserRole | null;
}

export const UserRoleBadge = ({ userRole, ...props }: UserRoleBadgeProps) =>
  userRole ? (
    <Badge color="accent" size="sm" {...props}>
      {getUserRoleDisplayName(userRole)}
    </Badge>
  ) : null;
