import { useMutation } from '@apollo/client';

import { ADD_TO_STARRED_VERSIONS } from '../components_v3/model-versions/addToStarredVersions.mutation';

export const useStarModelVersion = () => {
  const [updateStarModelVersion] = useMutation(ADD_TO_STARRED_VERSIONS, {
    optimisticResponse: (variables) => ({
      addToStarredModelVersions: {
        vecticeId: variables.resource.resourceId,
        isStarred: variables.resource.isStarred,
      },
    }),
    errorPolicy: 'ignore',
  });

  return { updateStarModelVersion };
};
