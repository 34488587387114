import { useMutation } from '@apollo/client';
import React, { useMemo } from 'react';

import { DatasetType } from '../../components_v3/dataset/DatasetType';
import { getDatasetTypeOptions } from '../../entities/utils';
import { DataSet, DatasetType as DatasetTypeEnum } from '../../gql/graphql';
import { UPDATE_DATASET_TYPE } from '../../graphql/queries';
import { Menu, MenuItem, message } from '../../ui';

type DatasetTypeMenuProps = {
  id: string;
  type: DatasetTypeEnum;
};

export const DatasetTypeMenu = ({ id, type }: DatasetTypeMenuProps) => {
  const datasetTypeOptions = useMemo(() => getDatasetTypeOptions(), []);

  const [updateDatasetType] = useMutation(UPDATE_DATASET_TYPE, {
    onError: (error) => message.error(error.message),
    optimisticResponse: ({ id, type }) => ({
      updateDataset: {
        vecticeId: id.toString(),
        type,
        __typename: 'DataSet' as DataSet['__typename'],
      },
    }),
    refetchQueries: ({ data }) =>
      data?.updateDataset.type === DatasetTypeEnum.Modeling || type === DatasetTypeEnum.Modeling
        ? ['getDatasetVersionsList', 'getDatasetVersionResources']
        : [],
  });

  const handleTypeChange = async (value: DatasetTypeEnum) => {
    if (value !== type) {
      await updateDatasetType({
        variables: {
          id,
          type: value,
        },
      });
    }
  };

  return (
    <Menu>
      {datasetTypeOptions.map((option) => (
        <MenuItem key={option.value} selected={type === option.value} onClick={() => handleTypeChange(option.value)}>
          <DatasetType type={option.value} />
        </MenuItem>
      ))}
    </Menu>
  );
};
