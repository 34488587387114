import cn from 'classnames';
import React from 'react';

import { formatCssSizes } from '../../../utils';

import { CommonSkeletonProps } from './interfaces';

import styles from './Skeleton.module.scss';

interface SkeletonShapeProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'children'>, CommonSkeletonProps {
  shape?: 'circle' | 'square';
  height?: number | string;
  width?: number | string;
}

export const SkeletonShape = ({
  shape = 'square',
  height,
  width,
  className,
  style,
  light,
  dark,
  darker,
  ...props
}: SkeletonShapeProps) => (
  <span
    className={cn(
      styles.skeleton,
      styles[shape],
      { [styles.light]: light },
      { [styles.dark]: dark },
      { [styles.darker]: darker },
      className,
    )}
    style={{
      height: formatCssSizes(height),
      width: formatCssSizes(width),
      ...style,
    }}
    {...props}
  />
);
