import { graphql } from '../../gql/gql';

export const CDT_REPORT_INFO = graphql(`
  query CDTReportInfo($id: Float!) {
    CDTReport(id: $id) {
      id
      modelVersion {
        id
        vecticeId
        inventoryReference
      }
    }
  }
`);
