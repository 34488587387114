import cn from 'classnames';
import React, { MouseEvent, useEffect, useState } from 'react';

import type { LabelProps } from '../form/interfaces';

import styles from './Toggle.module.scss';

export type ToggleProps = Omit<VecticeHTMLProps<HTMLButtonElement>, 'label' | 'type'> & {
  checked?: boolean;
  toggleSize?: 'sm' | 'lg';
  onToggle: (state: boolean) => any;
} & LabelProps;

export const Toggle = React.forwardRef<HTMLButtonElement, ToggleProps>(
  ({ className, checked = false, children, label, toggleSize = 'lg', onToggle, ...props }, ref) => {
    const [ariaChecked, setAriaChecked] = useState(checked);

    useEffect(() => setAriaChecked(checked), [checked]);

    const handleChange = (e: MouseEvent<HTMLButtonElement>) => {
      if (!props.disabled) {
        onToggle(!ariaChecked);
        setAriaChecked(!ariaChecked);
        props.onClick?.(e);
      }
    };

    return (
      <button
        ref={ref}
        aria-checked={ariaChecked}
        className={cn(styles.toggle, styles[toggleSize], className)}
        role="switch"
        type="button"
        onClick={handleChange}
        {...props}
      >
        {label}
      </button>
    );
  },
);
