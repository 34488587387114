import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/ic-project.svg';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { Workspace } from '../../../gql/graphql';
import { AssetRow, FlexContainer, Typography } from '../../../ui';
import { ProjectStatusBadge } from '../../badges';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { PhasesProgress } from '../../progress';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const ProjectRowFragment = graphql(`
  fragment projectRow on Project {
    vecticeId
    name
    status
    phasesCounts {
      done
      ongoing
      toDo
      total
    }
    updatedDate
    owner {
      ...userFields
    }
  }
`);

interface ProjectRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  project?: FragmentType<typeof ProjectRowFragment>;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const ProjectRow = (props: ProjectRowProps) => {
  const project = useFragment(ProjectRowFragment, props.project);

  if (!project) {
    return null;
  }

  return (
    <AssetRow icon={ProjectIcon} label={project.name} {...props}>
      <Typography variant="footnote" color="secondary">
        {project.vecticeId}
      </Typography>
      {props.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace name={props.workspace.name} iconSize={14} type={props.workspace.type} ellipsis />
        </Typography>
      )}
      <ProjectStatusBadge status={project.status} />
      <FlexContainer gap={4}>
        <Typography id="phase-progress" variant="footnote" color="secondary">
          {$t({ id: 'project.phaseProgress', defaultMessage: 'Phase Progress:' })}
        </Typography>
        <PhasesProgress aria-labelledby="phase-progress" phasesCounts={project.phasesCounts} />
      </FlexContainer>
      {!!project.owner && (
        <FlexContainer gap={4}>
          <Typography variant="footnote" color="secondary">
            {$t({
              id: 'project.row.owner',
              defaultMessage: 'Project owner',
            })}
          </Typography>
          <UserAvatar user={project.owner} size="xs" />
        </FlexContainer>
      )}
      {!!project.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy date={<FormatDate date={project.updatedDate} fromNow />} />
        </Typography>
      )}
    </AssetRow>
  );
};
