import React from 'react';

import type { Placement, TypographyColors } from '../../ui';

import { ReactComponent as CopyIcon } from '../../assets/icons/interface/ic-copy.svg';
import { FlexContainer, Icon, Typography } from '../../ui';
import { WithCopyOnClick } from '../copy-on-click';

import styles from './WithCopyID.module.scss';

interface WithCopyIDProps extends VecticeHTMLProps<HTMLDivElement> {
  iconLabel?: string;
  idValue?: string;
  color?: TypographyColors | string; // string to avoid compatibility issues with HTMLProps
  tooltipPlacement?: Placement;
}

export const WithCopyID = ({
  iconLabel,
  idValue,
  color = 'secondary',
  tooltipPlacement,
  className,
}: WithCopyIDProps) => (
  <WithCopyOnClick value={idValue} tooltipPlacement={tooltipPlacement}>
    <FlexContainer gap={4} style={{ cursor: 'pointer' }} className={className}>
      <Typography variant="callout" color={color} ellipsis>
        {idValue}
      </Typography>
      <Icon
        aria-label={iconLabel || 'Copy input content'}
        className={styles.copy_icon}
        icon={CopyIcon}
        role="button"
        size={16}
      />
    </FlexContainer>
  </WithCopyOnClick>
);
