import { graphql } from '../../gql';

export const UPDATE_DATASET_TYPE = graphql(`
  mutation updateDatasetType($id: VecticeId!, $type: DatasetType!) {
    updateDataset(datasetId: $id, dataset: { type: $type }) {
      vecticeId
      type
    }
  }
`);
