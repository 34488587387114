import cn from 'classnames';
import React from 'react';

import type { ProgressBarColor } from './types';

import { Tooltip } from '../tooltip';

import styles from './ProgressBar.module.scss';

interface ProgressBarProps extends VecticeHTMLProps<HTMLSpanElement> {
  thin?: boolean;
  total: number;
  value: number;
  tooltip?: string;
  color: ProgressBarColor;
}

export const ProgressBar = ({ className, color, thin, total, value, tooltip, ...props }: ProgressBarProps) => (
  <Tooltip text={tooltip} placement="top">
    <span
      aria-label={color}
      aria-valuemax={total}
      aria-valuemin={0}
      aria-valuenow={value}
      className={cn(styles.progress_bar, styles[color], { [styles.thin]: thin }, className)}
      role="progressbar"
      style={{ flexGrow: value / total }}
      {...props}
    />
  </Tooltip>
);
