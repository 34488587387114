const smoothPercentages = (percentages: number[]) => {
  const offset = 100 - percentages.reduce((acc, percentage) => acc + Math.round(percentage), 0);

  const getDelta = (x: number) => Math.round(x) - x;
  const sortByDelta = (a: number, b: number) => getDelta(b) - getDelta(a);
  const smoothPercentage = (p: number, i: number) =>
    p === 0 ? 0 : Math.round(p) + (offset > i ? 1 : 0) - (i >= percentages.length + offset ? 1 : 0);

  return percentages
    .map((percentage, index) => ({ percentage, index }))
    .sort((a, b) => sortByDelta(a.percentage, b.percentage))
    .map(({ percentage, index }, i) => ({ index, percentage: smoothPercentage(percentage, i) }))
    .sort((a, b) => a.index - b.index)
    .map(({ percentage }) => percentage);
};

export const getPercentage = (value: number, total: number) => (total > 0 ? (value / total) * 100 : 0);

export const getPercentages = (values: number[], total: number) => {
  const percentages = values.map((value) => getPercentage(value, total));

  return smoothPercentages(percentages);
};
