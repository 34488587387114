import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { Phase } from '../../gql/graphql';
import { CHECK_PHASE_NAME_AVAILABILITY, UPDATE_PHASE } from '../../graphql/queries';
import { useValidateName, useVecticeForm } from '../../hooks';
import { Input, message, ModalForm, TextArea, WithAsyncValidation } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';

export interface FormData {
  name: string;
  description: string;
}

interface EditPhaseModalProps {
  phase: Pick<Phase, 'vecticeId' | 'name' | 'description'>;
  projectId?: string;
  onClose: () => void;
}

export const EditPhaseModal = ({ phase, projectId, onClose }: EditPhaseModalProps) => {
  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: phase.name,
      description: phase.description ?? '',
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updatePhase] = useMutation(UPDATE_PHASE, {
    optimisticResponse: ({ id: vecticeId, updateModel: { name } }) => ({
      updatePhase: {
        vecticeId: vecticeId.toString(),
        name: name ?? phase!.name,
      },
    }),
    update: () => onClose(),
    onCompleted: ({ updatePhase: { name } }) =>
      message.success(
        $t({ id: 'phase.modal.edit.success', defaultMessage: 'Phase "{name}" has been updated', values: { name } }),
      ),
    onError: (error) => message.error(error.message),
  });

  const [checkNameAvailability] = useLazyQuery(CHECK_PHASE_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const handleNameAvailability = useCallback(
    async (value: string) => {
      const { data: { checkPhaseNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          parentId: projectId ?? '',
          resourceId: phase.vecticeId,
          name: value,
        },
      });

      return !!checkPhaseNameAvailability;
    },
    [phase, projectId, checkNameAvailability],
  );

  const { validateName } = useValidateName(handleNameAvailability, VecticeResourceType.PHASE);

  const handleSubmit = useCallback(
    async ({ name, description }: FormData) => {
      await updatePhase({
        variables: {
          id: phase.vecticeId,
          updateModel: { name, description },
        },
      });
    },
    [phase, updatePhase],
  );

  return (
    <ModalForm
      title={$t({ id: 'phase.modal.rename.title', defaultMessage: 'Edit phase' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          label={$t({
            id: 'form.phaseEdit.nameLabel',
            defaultMessage: 'Phase name',
          })}
          gutterBottom
          required
          autoFocus
        />
      </WithAsyncValidation>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        gutterBottom
        label={$t({ id: 'modal.createResource.description', defaultMessage: 'Description' })}
        hint={$t({ id: 'modal.createResource.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
