import { useQuery, useSubscription } from '@apollo/client';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/interface/ic-remove.svg';
import { graphql } from '../../gql';
import { Button, Divider, FlexContainer, List, message, Typography, WithLoading } from '../../ui';

import { GET_USER_NOTIFICATION_LIST } from './getUserNotificationList.query';
import { NotificationFactory } from './notification';
import { USER_NOTIFICATION_CREATED } from './userNotificationCreated.subscription';

import styles from './NotificationsList.module.scss';

const PAGE_SIZE = 30;

export const NotificationsList = ({ onClose }: { onClose: () => void }) => {
  const { data, loading, fetchMore } = useQuery(GET_USER_NOTIFICATION_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      pageSize: PAGE_SIZE,
    },
    onError: (error) => message.error(error.message),
  });

  useSubscription(USER_NOTIFICATION_CREATED, {
    onData: ({ data: subscriptionData, client }) => {
      if (subscriptionData.data) {
        const {
          userNotificationCreatedUnseen: { payload },
        } = subscriptionData.data;

        if (payload) {
          client.cache.modify({
            fields: {
              getUserNotificationList(existingUserNotificationRefs: any[] = []) {
                const newUserNotificationRef = client.cache.writeFragment({
                  // @ts-expect-error Might be an issue with the fragment in USER_NOTIFICATION_CREATED
                  data: payload,
                  fragment: graphql(`
                    fragment NewUserNotification on UserNotification {
                      id
                      type
                      content
                      createdDate
                      creator {
                        id
                        ...userFields
                      }
                      workspace {
                        vecticeId
                        name
                      }
                    }
                  `),
                });

                return [newUserNotificationRef, ...existingUserNotificationRefs];
              },
            },
          });
        }
      }
    },
  });

  const { items: notifications = [], page } = data?.getUserNotificationList || {};
  const { afterCursor, hasNextPage } = page || {};

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <FlexContainer align="center" justify="space-between">
          <Typography color="primary" weight="semi-bold" id="notifications-dropdown-title">
            {$t({ id: 'notification.notifications', defaultMessage: 'Notifications' })}
          </Typography>
          <Button color="gray" leftIcon={CloseIcon} size="sm" variant="phantom" onClick={onClose} />
        </FlexContainer>

        <Divider />
      </div>

      <div className={styles.content}>
        <List
          aria-labelledby="notifications-dropdown-title"
          loading={loading && !notifications.length}
          emptyText={
            <Typography variant="callout" color="tertiary" align="center">
              {$t({ id: 'notification.noNotifications', defaultMessage: "You don't have any notifications" })}
            </Typography>
          }
          empty={!notifications.length}
        >
          {notifications.map((notification) => (
            <NotificationFactory notification={notification} key={notification.id} onClose={onClose} />
          ))}
        </List>

        <WithLoading loading={loading && notifications.length > 0} loadingSize={24}>
          {hasNextPage && (
            <div className={styles.show_more}>
              <Button variant="white" size="sm" onClick={handleShowMore}>
                {$t({ id: 'button.showMore', defaultMessage: 'Show More' })}
              </Button>
            </div>
          )}
        </WithLoading>
      </div>
    </div>
  );
};
