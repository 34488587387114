import cn from 'classnames';
import React from 'react';

import styles from './Statistics.module.scss';

export const Statistics = ({ className, children, ...props }: VecticeHTMLProps<HTMLTableElement>) => {
  return (
    <table {...props} className={cn(styles.table, className)}>
      <colgroup>
        <col style={{ width: '50%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '15%%' }} />
      </colgroup>
      <tbody>
        {children}
        <tr className={styles.shadow_row}>
          <td />
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  );
};
