import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { FragmentType, graphql, useFragment } from '../../gql';
import { Button, EmptyList, Loading, WithLoading } from '../../ui';
import { NoResults } from '../no-results';

import { Activity } from './activity';
import { ActivityListSkeleton } from './activity/ActivityListSkeleton';

import styles from './ActivityList.module.scss';

const UserActivitiesFragment = graphql(`
  fragment userActivities on PaginatedUserActivityResponse {
    items {
      id
      ...userActivity
    }
  }
`);

interface ActivityListProps extends VecticeHTMLProps<HTMLUListElement> {
  activities?: FragmentType<typeof UserActivitiesFragment>;
  loading?: boolean;
  showBreadcrumbs?: boolean;
  hasMore?: boolean | null;
  hideRestore?: boolean;
  withFilters?: boolean;
  onShowMore?: () => void;
}

export const ActivityList = ({
  activities: fragment,
  loading,
  showBreadcrumbs,
  hasMore,
  hideRestore,
  withFilters,
  onShowMore,
  ...props
}: ActivityListProps) => {
  const { items: activities } = useFragment(UserActivitiesFragment, fragment) ?? {};

  if (!loading && withFilters && activities?.length === 0) {
    return <NoResults borderless />;
  }

  if (!loading && !withFilters && activities?.length === 0) {
    return (
      <EmptyList
        icon={VecticeIcon}
        message={$t({ id: 'component.ActivityList', defaultMessage: 'No activities' })}
        borderless
      />
    );
  }

  return (
    <WithLoading loading={loading && !activities} skeleton={<ActivityListSkeleton />}>
      {activities && activities.length > 0 && (
        <ul {...props}>
          {activities.map((activity) => (
            <Activity
              activity={activity}
              showBreadcrumbs={showBreadcrumbs}
              hideRestore={hideRestore}
              key={activity.id}
            />
          ))}
        </ul>
      )}
      {hasMore && (
        <Button variant="outlined" size="sm" onClick={onShowMore} className={styles.show_more}>
          {$t({ id: 'button.showMore', defaultMessage: 'Show More' })}
        </Button>
      )}
      {loading && <Loading />}
    </WithLoading>
  );
};
