import cn from 'classnames';
import React from 'react';

import { ReactComponent as ImageIcon } from '../../../assets/icons/interface/ic-image-file-landscape.svg';
import { Card, FlexContainer, Icon, Skeleton } from '../../../ui';

import styles from './GetStartedCard.module.scss';

export const GetStartedCardSkeleton = () => {
  return (
    <Card
      align="stretch"
      className={cn(styles.card, styles.skeleton)}
      component={FlexContainer}
      direction="column"
      gap={0}
      inert
      justify="space-between"
    >
      <FlexContainer className={styles.visual}>
        <Skeleton.Shape height="100%" width="100%" />
        <Icon className={styles.icon} icon={ImageIcon} size={60} />
      </FlexContainer>
      <FlexContainer className={styles.content} direction="column" gap={4}>
        <Skeleton.Paragraph height="18px" width="50px" className={styles.badge} />
        <Skeleton.Paragraph width={75} />
        <Skeleton.Paragraph width={30} />
      </FlexContainer>
    </Card>
  );
};
