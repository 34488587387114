import { useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import type { AssetWidgetProps } from '../../../ui';

import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetWidget, FlexContainer, Icon, Typography } from '../../../ui';
import { getVecticeResourceTypeIcon, VecticeResourceType } from '../../../utils';

import { ModelRow } from './ModelRow';

export const GET_MODEL_WIDGET = graphql(`
  query getModelWidget($id: VecticeId!) {
    getModelById(modelId: $id) {
      vecticeId
      ...modelRow
    }
  }
`);

let globalId = 0;

interface ModelWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary'> {
  id: string;
}

export const ModelWidget = ({ id, ...props }: ModelWidgetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`model-widget-${globalId++}`);
  const { data, error, loading } = useQuery(GET_MODEL_WIDGET, {
    variables: { id },
  });

  const model = data?.getModelById;

  if (error) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        className="hide-pdf-export"
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getVecticeResourceTypeIcon(VecticeResourceType.MODEL)} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                {$t({ id: 'modelWidget.asset.deleted', defaultMessage: 'Asset was deleted' })}
              </Typography>
            </FlexContainer>
          </div>
        }
        {...props}
        open={false}
        expandable={false}
      />
    );
  }

  return (
    <AssetWidget
      aria-labelledby={ariaId}
      loading={loading}
      summary={
        <Link to={buildLink(VecticeRoutes.MODEL, { modelId: id })} target="_blank">
          <ModelRow id={ariaId} model={model} interaction="tab" />
        </Link>
      }
      {...props}
    />
  );
};
