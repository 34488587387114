import { graphql } from '../../gql';

export const GET_CODE_ARTIFACT_BY_JOB_RUN_ID = graphql(`
  query getCodeArtifactByJobRunId($lineageId: Float!) {
    getJobArtifactByJobRunId(lineageId: $lineageId) {
      items {
        id
        version
        lineageArtifactType
        lineageId
        artifactType
        codeVersion {
          id
          code {
            name
            description
          }
          gitVersion {
            branchName
            commitHash
            repositoryName
            uri
            entrypoint
            isDirty
            additionalInformation
          }
          databricksVersion {
            urlNotebook
            relativePath
            timestamp
            additionalInformation
          }
          otherCodeVersion {
            id
            url
            firstAdditionalInformation
            secondAdditionalInformation
            thirdAdditionalInformation
          }
        }
      }
    }
  }
`);
