import { graphql } from '../../gql';

export const EXTRACT_ENTITY_FILE = graphql(`
  mutation extractEntityFile($id: Float!) {
    extractEntityFile(id: $id) {
      images
      tables
    }
  }
`);
