import { useQuery, useSubscription } from '@apollo/client';
import cn from 'classnames';
import React from 'react';

import { ReactComponent as CommentIcon } from '../../assets/icons/interface/ic-messages-bubble-square-text.svg';
import { SidebarDocumentationOption } from '../../documentation/types/types';
import { ThreadStatus } from '../../gql/graphql';
import { Button, Tooltip } from '../../ui';

import { SUBSCRIBE_TO_UNRESOLVED_COMMENT_LIST } from './subscribeToUnresolvedCommentList.subscription';
import { THREADS_UNRESOLVED_COUNT } from './threadsUnResolvedCount.query';

import styles from './CommentToolbarButton.module.scss';

interface CommentToolbarButtonProps {
  sidebar: SidebarDocumentationOption | null;
  fileId?: number;
  onClick: () => void;
}

const PAGE_SIZE = 100;

export const CommentToolbarButton = ({ sidebar, fileId, onClick }: CommentToolbarButtonProps) => {
  const { data } = useQuery(THREADS_UNRESOLVED_COUNT, {
    fetchPolicy: 'network-only',
    skip: !fileId,
    variables: {
      id: fileId!,
      pageSize: PAGE_SIZE,
    },
  });

  const unResolvedCommentCount = data?.threads.total || 0;

  useSubscription(SUBSCRIBE_TO_UNRESOLVED_COMMENT_LIST, {
    skip: !fileId,
    variables: {
      fileId: fileId!,
    },
    onData: ({ data: subscriptionData, client }) => {
      const unResolvedComments = data ? [...data.threads.items] : [];
      if (subscriptionData.data) {
        const {
          subscribeToFileComments: { threadId, status, isDeleted, replyId },
        } = subscriptionData.data;
        if (replyId !== null) return;

        if (unResolvedComments && unResolvedComments?.length > 0) {
          if (status === ThreadStatus.Resolved || isDeleted) {
            const indexToRemove = unResolvedComments.findIndex((comment) => comment.id === threadId);
            if (indexToRemove >= 0) unResolvedComments.splice(indexToRemove, 1);
          } else if (
            status === ThreadStatus.Unresolved &&
            unResolvedComments.every((comment) => comment.id !== threadId)
          )
            unResolvedComments.push({ id: threadId });

          client.cache.updateQuery(
            {
              query: THREADS_UNRESOLVED_COUNT,
              variables: {
                id: fileId!,
                pageSize: PAGE_SIZE,
              },
            },
            () => ({
              threads: {
                items: unResolvedComments,
                total: unResolvedComments?.length ?? 0,
              },
            }),
          );
        } else {
          client.cache.updateQuery(
            {
              query: THREADS_UNRESOLVED_COUNT,
              variables: {
                id: fileId!,
                pageSize: PAGE_SIZE,
              },
            },
            () => ({
              threads: {
                items: [{ id: threadId }],
                total: 1,
              },
            }),
          );
        }
      }
    },
  });
  const toolTipUnresolvedComment = unResolvedCommentCount
    ? $t({
        id: 'editor.toggle.openComments.count.unread',
        defaultMessage:
          '{unResolvedCommentCount, plural, one {You have # open comment} other {You have # open comments}}',
        values: { unResolvedCommentCount },
      })
    : $t({ id: 'editor.toggle.openComments.count.noUnread', defaultMessage: 'You have no open comments' });
  return (
    <Tooltip text={toolTipUnresolvedComment} placement="top">
      <Button
        aria-label={$t({ id: 'editor.toggle.openComments', defaultMessage: 'Open Comments' })}
        role="switch"
        aria-checked={sidebar === 'comments'}
        className={cn(styles.button, { [styles.hasUnresolved]: !!unResolvedCommentCount })}
        data-notifications={unResolvedCommentCount}
        leftIcon={CommentIcon}
        variant={sidebar === 'comments' ? 'outlined' : 'phantom'}
        color="gray"
        size="sm"
        onClick={onClick}
      />
    </Tooltip>
  );
};
