import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

interface NoDatasetVersionsProps {
  borderless?: boolean;
}

export const NoDatasetVersions = ({ borderless }: NoDatasetVersionsProps) => (
  <EmptyList
    icon={VecticeIcon}
    borderless={borderless}
    message={$t({ id: 'NoDatasetVersions.noVersion', defaultMessage: 'No dataset versions!' })}
    hint={
      <>
        {$t({
          id: 'NoDatasetVersions.hint',
          defaultMessage: 'The registered dataset version used in this Project and its metadata will be visible here.',
        })}
        <br />
        {$t({
          id: 'NoDatasetVersions.useCheatSheet',
          defaultMessage: 'Use the <api_cheat_sheet>Vectice API cheat sheet</api_cheat_sheet> to get started.',
        })}
      </>
    }
  />
);
