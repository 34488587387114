import React, { ReactElement, ReactNode } from 'react';

import { Button } from '../button';

import { Dialog, DialogContent, DialogActions, DialogProps } from './internals';

interface ModalProps extends DialogProps {
  extraAction?: ReactElement;
  contentClassName?: string;
  closeLabel: ReactNode;
}

export const Modal = ({
  closeLabel,
  onClose,
  extraAction,
  contentClassName,
  withBackground,
  children,
  ...props
}: ModalProps) => (
  <Dialog onClose={onClose} {...props}>
    <DialogContent withBackground={withBackground} className={contentClassName}>
      {children}
    </DialogContent>
    <DialogActions>
      {extraAction}
      <Button variant="phantom" onClick={onClose}>
        {closeLabel}
      </Button>
    </DialogActions>
  </Dialog>
);
