import { useMutation, useQuery, useSubscription } from '@apollo/client';
import cn from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as BellIcon } from '../../assets/icons/interface/ic-alarm-bell.svg';
import { Drawer, Icon, Tooltip } from '../../ui';

import { COUNT_UNSEEN_USER_NOTIFICATIONS } from './countUnseenUserNotifications.query';
import { MARK_ALL_USER_NOTIFICATIONS_AS_SEEN } from './markAllUserNotificationsAsSeen.mutation';
import { NotificationsList } from './NotificationsList';
import { UNSEEN_USER_NOTIFICATION_COUNT_CHANGED } from './unseenUserNotificationCountChanged.subscription';

import styles from './Notifications.module.scss';

export const Notifications = () => {
  const [open, setOpen] = useState(false);
  const { data: notificationCountData } = useQuery(COUNT_UNSEEN_USER_NOTIFICATIONS, {
    errorPolicy: 'ignore',
  });

  const { countUnseenUserNotifications: unreadNotificationCount } = notificationCountData || {};

  useSubscription(UNSEEN_USER_NOTIFICATION_COUNT_CHANGED, {
    onData: ({ data: subscriptionData, client }) => {
      if (subscriptionData.data) {
        const {
          userNotificationChanged: { unseenCount },
        } = subscriptionData.data;

        client.writeQuery({
          query: COUNT_UNSEEN_USER_NOTIFICATIONS,
          data: {
            countUnseenUserNotifications: unseenCount,
          },
        });
      }
    },
  });

  const [markAllUserNotificationsAsSeen] = useMutation(MARK_ALL_USER_NOTIFICATIONS_AS_SEEN, {
    errorPolicy: 'ignore',
    update: (cache, { data }) => {
      if (data?.markAllUserNotificationsAsSeen) {
        cache.writeQuery({
          query: COUNT_UNSEEN_USER_NOTIFICATIONS,
          data: {
            countUnseenUserNotifications: 0,
          },
        });
      }
    },
  });

  const handleOpen = () => {
    markAllUserNotificationsAsSeen();
    setOpen(true);
  };

  const tooltip = unreadNotificationCount
    ? $t({
        id: 'notification.unread',
        defaultMessage: 'You have {unreadNotificationCount} unread notifications',
        values: { unreadNotificationCount },
      })
    : $t({ id: 'notification.noUnread', defaultMessage: 'You have no unread notifications' });

  return (
    <>
      <Tooltip text={tooltip} placement="bottom">
        <button
          aria-label={tooltip}
          className={cn(styles.button, { [styles.hasUnread]: !!unreadNotificationCount })}
          data-notifications={unreadNotificationCount}
          onClick={handleOpen}
          type="button"
        >
          <span className={styles.button_label}>
            <Icon icon={BellIcon} />
          </span>
        </button>
      </Tooltip>
      <Drawer.Overlay open={open} placement="right" onClose={() => setOpen(false)}>
        <NotificationsList onClose={() => setOpen(false)} />
      </Drawer.Overlay>
    </>
  );
};
