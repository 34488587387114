import { isDefined, isNil } from './utils';

export const PropertyPositionValues = {
  top: 1,
  right: 2,
  bottom: 4,
  left: 8,
  all: 15,
};

export enum PropertyPosition {
  top,
  right,
  bottom,
  left,
}

/**
 * Return a css property with a complete directional format
 *
 * @example formatDirectionalProperty('red') returns 'red red red red'
 * @example formatDirectionalProperty('red', 'blue') returns 'red blue red blue'
 * @example formatDirectionalProperty('red', 'blue', 'green') returns 'red blue green blue'
 *
 * @param property - The CSS property to format.
 * @param override - An optional object containing specific values to override the default directional values.
 * @returns The formatted CSS property.
 */
export const formatDirectionalProperty = (
  property: string | number,
  override?: { top?: string | number; right?: string | number; bottom?: string | number; left?: string | number },
) => {
  // First remove spaces in the potential rgb values
  const initialValue = property.toString().replace(/rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g, 'rgb($1,$2,$3)');
  const values = initialValue.split(' ');
  const [top, right, bottom, left] = values;

  const topValue = override?.top ?? top;
  const rightValue = override?.right ?? right ?? top;
  const bottomValue = override?.bottom ?? bottom ?? top;
  const leftValue = override?.left ?? left ?? right ?? top;

  return `${topValue} ${rightValue} ${bottomValue} ${leftValue}`;
};

/**
 * Returns the common directional value from a CSS property.
 *
 * @example getCommonDirectionalValue('red') returns 'red'
 * @example getCommonDirectionalValue('red red red red') returns 'red'
 * @example getCommonDirectionalValue('red red red blue') returns undefined
 *
 * @param property - The CSS property to extract the common directional value from.
 * @returns The common directional value if all values are the same, otherwise undefined.
 */
export const getCommonDirectionalValue = (property?: string) => {
  if (!isDefined(property)) return undefined;

  let commonValue: string | undefined = undefined;
  const values = property.split(' ');
  values.forEach((value, index) => {
    if (index === 0) {
      commonValue = value;
    } else if (commonValue !== value) {
      commonValue = undefined;
      return;
    }
  });
  return commonValue;
};

/**
 * Patch a directional CSS property with a new value.
 *
 * @example patchDirectionalProperty('red', 'blue', 1) returns 'blue red red red'
 * @example patchDirectionalProperty('red', 'blue', 2) returns 'red blue red red'
 * @example patchDirectionalProperty('red', 'blue', 9) returns 'blue red red blue'
 * @example patchDirectionalProperty('red yellow', 'blue', 2) returns 'red blue red yellow'
 *
 * @param property - The CSS property to patch.
 * @param newProperty - The new value to patch the property with.
 * @param positions - The positions to patch the property at. Should be a combination of PropertyPosition values.
 * @returns The patched CSS property.
 */
export const patchDirectionalProperty = (
  property: string | undefined,
  newProperty: string | undefined,
  positions: number,
) => {
  if (isNil(property)) return undefined;
  if (isNil(newProperty)) return property;

  const shouldOverride = (position: PropertyPosition) => positions & (1 << position);

  const override = {
    top: shouldOverride(PropertyPosition.top) ? newProperty : undefined,
    right: shouldOverride(PropertyPosition.right) ? newProperty : undefined,
    bottom: shouldOverride(PropertyPosition.bottom) ? newProperty : undefined,
    left: shouldOverride(PropertyPosition.left) ? newProperty : undefined,
  };

  return formatDirectionalProperty(property, override);
};
