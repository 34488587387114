import React, { useRef } from 'react';

import type { CardProps } from '../../../ui';

import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/interface/ic-arrow-right.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/icons/interface/ic-link.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/interface/ic-play.svg';
import { Badge, Card, FlexContainer, Graphic, Icon, Typography } from '../../../ui';
import { NewBadge } from '../../badges';

import styles from './GetStartedCard.module.scss';

export interface GetStartedCardProps extends CardProps {
  backgroundColor: string;
  duration?: string;
  external?: boolean;
  isNew?: boolean;
  isVideo?: boolean;
  label: string;
  linkLabel: string;
  tag?: string;
  visual: string;
  onClick?: () => void;
}

let globalId = 0;

export const GetStartedCard = ({
  backgroundColor,
  duration,
  external,
  isNew,
  isVideo,
  label,
  linkLabel,
  tag,
  visual,
  onClick,
}: GetStartedCardProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`get-started-card-${globalId++}`);

  return (
    <Card
      component={FlexContainer}
      direction="column"
      align="stretch"
      justify="space-between"
      gap={0}
      aria-labelledby={id}
      inert
      className={styles.card}
      onClick={onClick}
    >
      <FlexContainer
        align="center"
        className={styles.visual}
        justify="center"
        style={{ backgroundImage: backgroundColor }}
      >
        <span className={styles.backdrop} />
        <Graphic className={styles.graphic} size={60} src={visual} />
        {isNew && <NewBadge className={styles.new} />}
      </FlexContainer>
      <div className={styles.content}>
        {(duration || tag) && (
          <FlexContainer gap={4} className={styles.badges}>
            {duration && (
              <Badge color="accent" size="sm">
                {duration}
              </Badge>
            )}
            {tag && (
              <Badge color="success" size="sm">
                {tag}
              </Badge>
            )}
          </FlexContainer>
        )}
        <FlexContainer gap={4}>
          <Typography id={id} weight="semi-bold" variant="callout" color="primary" ellipsis>
            {label}
          </Typography>
        </FlexContainer>
        <Typography component={FlexContainer} gap={4} variant="footnote" color="accent">
          {isVideo ? (
            <>
              <span className={styles.link}>{$t({ id: 'GetStarted.watch', defaultMessage: 'Watch' })}</span>
              <Icon icon={VideoIcon} size={12} />
            </>
          ) : (
            <>
              <span className={styles.link}>{linkLabel}</span>
              <Icon icon={external ? ExternalLinkIcon : ArrowRightIcon} size={12} />
            </>
          )}
        </Typography>
      </div>
    </Card>
  );
};
