import cn from 'classnames';
import React from 'react';

import { WithLoading } from '../loading-animation';

import { MenuSkeleton } from './MenuSkeleton';

import styles from './Menu.module.scss';

interface MenuProps extends VecticeHTMLProps<HTMLUListElement> {
  preventCloseOnClick?: boolean;
  loading?: boolean;
}

export const Menu = React.forwardRef<HTMLUListElement, MenuProps>(
  ({ preventCloseOnClick, loading, className, children, ...props }, ref) => (
    <ul
      role="menu"
      tabIndex={0}
      onClick={(e) => preventCloseOnClick && e.stopPropagation()}
      className={cn(styles.menu, className)}
      ref={ref}
      {...props}
    >
      <WithLoading loading={loading} skeleton={<MenuSkeleton />}>
        {children}
      </WithLoading>
    </ul>
  ),
);
