import React from 'react';

import type { FallbackRender } from '@sentry/react/types/errorboundary';

import {
  ConnectionLostError,
  InternalServerError,
  OfflineError,
  UnauthenticatedError,
  VersionMismatchError,
} from '../errors';

import { ConnectionLostPage } from './ConnectionLostPage';
import { InternalServerErrorPage } from './InternalServerErrorPage';
import { LoggedOutErrorPage } from './LoggedOutErrorPage';
import { OfflinePage } from './OfflinePage';
import { UnexpectedErrorPage } from './UnexpectedErrorPage';
import { VersionMismatchPage } from './VersionMismatchPage';

export const ErrorFallback: FallbackRender = ({ error, resetError }) => {
  if (error instanceof UnauthenticatedError) {
    return <LoggedOutErrorPage onHandleError={resetError} />;
  }
  if (error instanceof VersionMismatchError) {
    return <VersionMismatchPage />;
  }
  if (error instanceof ConnectionLostError) {
    return <ConnectionLostPage />;
  }
  if (error instanceof InternalServerError) {
    return <InternalServerErrorPage error={error} onHandleError={resetError} />;
  }
  if (error instanceof OfflineError) {
    return <OfflinePage />;
  }
  return <UnexpectedErrorPage error={error} onHandleError={resetError} />;
};
