import React from 'react';

import { FragmentType, graphql, useFragment } from '../../gql';
import { Grid, GridCell, WithLoading } from '../../ui';
import { NoResults, NoWorkspaces } from '../no-results';

import { WorkspaceCard } from './WorkspaceCard';
import { WorkspaceGridSkeleton } from './WorkspaceGridSkeleton';

const WorkspaceGridFragment = graphql(`
  fragment WorkspaceGrid on PaginatedWorkspaceResponseOutput {
    items {
      vecticeId
      ...WorkspaceCard
    }
  }
`);

interface WorkspaceGridProps {
  workspaceList?: FragmentType<typeof WorkspaceGridFragment>;
  loading?: boolean;
  total?: number;
  hasSearch?: boolean;
}

export const WorkspacesGrid = ({ workspaceList, loading, total, hasSearch }: WorkspaceGridProps) => {
  const workspaces = useFragment(WorkspaceGridFragment, workspaceList);

  if (!loading && total === 0) {
    if (hasSearch) {
      return <NoResults />;
    }
    return <NoWorkspaces />;
  }

  return (
    <WithLoading loading={loading} skeleton={<WorkspaceGridSkeleton />}>
      <Grid columns={3} gap={32}>
        {workspaces?.items?.map((workspace) => (
          <GridCell key={workspace.vecticeId}>
            <WorkspaceCard workspace={workspace} />
          </GridCell>
        ))}
      </Grid>
    </WithLoading>
  );
};
