import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { graphql } from '../../gql';
import { Iteration } from '../../gql/graphql';
import { useValidateName, useVecticeForm } from '../../hooks';
import { Input, message, ModalForm, TextArea, WithAsyncValidation } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';

export const CHECK_ITERATION_NAME_AVAILABILITY = graphql(`
  query checkIterationNameAvailability($parentId: VecticeId!, $name: String!, $resourceId: VecticeId) {
    checkIterationNameAvailability(parentId: $parentId, name: $name, resourceId: $resourceId)
  }
`);

export const UPDATE_ITERATION = graphql(`
  mutation updateIteration($id: VecticeId!, $data: IterationUpdateInput!) {
    updateIteration(id: $id, data: $data) {
      vecticeId
      name
      description
    }
  }
`);

export interface FormData {
  name: string;
  description: string;
}

interface EditIterationModalProps {
  iteration: Pick<Iteration, 'vecticeId' | 'name' | 'description' | 'phaseId'>;
  onClose: () => void;
}

export const EditIterationModal = ({ iteration, onClose }: EditIterationModalProps) => {
  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: iteration.name,
      description: iteration.description ?? '',
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updateIteration] = useMutation(UPDATE_ITERATION, {
    optimisticResponse: ({ id: vecticeId, data: { name, description } }) => ({
      updateIteration: {
        vecticeId: vecticeId.toString(),
        name: name ?? iteration.name,
        description: description ?? iteration.description,
      },
    }),
    update: () => onClose(),
    onCompleted: ({ updateIteration: { name } }) =>
      message.success(
        $t({
          id: 'iteration.modal.edit.success',
          defaultMessage: 'Iteration "{name}" has been updated',
          values: { name },
        }),
      ),
    onError: (error) => message.error(error.message),
  });

  const [checkNameAvailability] = useLazyQuery(CHECK_ITERATION_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const handleNameAvailability = useCallback(
    async (value: string) => {
      const { data: { checkIterationNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          parentId: iteration.phaseId,
          name: value,
          resourceId: iteration.vecticeId,
        },
      });

      return !!checkIterationNameAvailability;
    },
    [iteration, checkNameAvailability],
  );

  const { validateName } = useValidateName(handleNameAvailability, VecticeResourceType.ITERATION);

  const handleSubmit = useCallback(
    async ({ name, description }: FormData) => {
      await updateIteration({
        variables: {
          id: iteration.vecticeId,
          data: { name, description },
        },
      });
    },
    [iteration, updateIteration],
  );

  return (
    <ModalForm
      title={$t({ id: 'iteration.modal.edit.title', defaultMessage: 'Edit iteration' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          label={$t({
            id: 'form.iterationEdit.nameLabel',
            defaultMessage: 'Iteration name',
          })}
          required
          gutterBottom
          autoFocus
        />
      </WithAsyncValidation>
      <TextArea
        label={$t({
          id: 'form.iterationEdit.descriptionLabel',
          defaultMessage: 'Iteration description',
        })}
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        placeholder={$t({ id: 'asset.details.description.placeholder', defaultMessage: 'Add description' })}
        hint={$t({ id: 'form.iterationEdit.description.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
