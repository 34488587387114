import cn from 'classnames';
import RcDropdown from 'rc-dropdown';
import { DropdownProps } from 'rc-dropdown/lib/Dropdown';
import React from 'react';

import 'rc-dropdown/assets/index.css';
import styles from './Dropdown.module.scss';

export const Dropdown = ({
  overlayClassName,
  children,
  ...props
}: DropdownProps & { destroyPopupOnHide?: boolean }) => (
  <RcDropdown overlayClassName={cn(styles.overlay, overlayClassName)} destroyPopupOnHide {...props}>
    {children}
  </RcDropdown>
);
