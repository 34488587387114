import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  FieldFunctionOptions,
  InMemoryCache,
  split,
} from '@apollo/client';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import type { SafeReadonly } from '@apollo/client/cache/core/types/common';

import Config from '../../config';
import { DEFAULT_HTTP_HEADERS } from '../rest';

export function getApolloClient(handleError: ErrorHandler, handleConnectionLoss: () => void) {
  // Remove the `__typename` property from the data received,
  // it avoids some issues when saving objects as the typename for the type and the input are different
  const cleanupLink = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query);
    if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
      return forward(operation);
    }

    if (operation.variables) {
      const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);
      // eslint-disable-next-line no-param-reassign
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => data);
  });

  const errorLink = onError(handleError);

  const httpLink = createHttpLink({
    uri: Config.APOLLO_URL,
    credentials: 'include',
    headers: DEFAULT_HTTP_HEADERS,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: Config.SUBSCRIPTIONS_URL,
      lazy: true,
      shouldRetry: () => true,
      retryAttempts: 16,
      retryWait: (retries) => {
        if (retries === 14) {
          handleConnectionLoss();
        }
        if (retries === 15) {
          return Promise.reject('Impossible to open websocket connection');
        }
        return new Promise((resolve) => {
          // Exponential backoff strategy
          // We do a lot of fast retries at first to reconnect asap
          // Then the time between each retry increases exponentially until server is back up
          const timeout = Math.floor(Math.pow((retries + 1) / 10, 2) * 5000);
          setTimeout(resolve, timeout);
        });
      },
    }),
  );

  // Send queries and mutations to the /graphql endpoint using http
  // Send subscriptions to the /subscriptions using websocket
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    link: ApolloLink.from([errorLink, cleanupLink, splitLink]),
    cache: getApolloCache(),
    defaultOptions: {
      query: { errorPolicy: 'all', fetchPolicy: 'network-only' },
      watchQuery: { errorPolicy: 'all', fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' },
      mutate: { errorPolicy: 'none' },
    },
  });
}

export function getApolloCache() {
  return new InMemoryCache({
    dataIdFromObject(responseObject) {
      if (responseObject?.__typename === 'WorkspaceUser') {
        return `WorkspaceUser:${responseObject.userId}:${responseObject.workspaceId}`;
      }
      if (responseObject?.__typename === 'OrgFeatureFlagValue') {
        if (responseObject.organizationId) {
          return `OrgFeatureFlagValue:${responseObject.organizationId}:${responseObject.code}`;
        }
        return `FeatureFlag:${responseObject.code}`;
      }
      if (responseObject?.__typename === 'FeatureFlagValue') {
        return `FeatureFlagValue:${responseObject.code}`;
      }
      if (responseObject?.__typename && ['EpicFeatureFlag', 'StoryFeatureFlag'].includes(responseObject.__typename)) {
        return `${responseObject.__typename}:${responseObject.code}`;
      }
      if (responseObject?.__typename === 'AssetOriginOutput') {
        return `AssetOriginOutput:${responseObject.id}:${responseObject.name}`;
      }
      if ('vecticeId' in responseObject) {
        return `${responseObject.__typename}:${responseObject.vecticeId}`;
      }
      return defaultDataIdFromObject(responseObject);
    },
    typePolicies: {
      ConfigOutput: {
        keyFields: [],
      },
      OrganizationConfigOutput: {
        keyFields: [],
      },
      OrganizationLicenseOptions: {
        keyFields: [],
      },
      WorkspaceNumberAssetsByCreator: {
        keyFields: false,
      },
      WorkspaceStatsData: {
        keyFields: [],
      },
      WorkspaceStatsResult: {
        keyFields: [],
      },
      ModelVersionStatistics: {
        merge: (existing = {}, incoming) => ({
          ...existing,
          ...incoming,
        }),
      },
      DocumentationFile: {
        fields: {
          blocks: {
            merge(_, incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          getUserActivityList: {
            keyArgs: ['workspaceIdList', 'filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
          CDTTemplates: {
            keyArgs: ['filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
          countUnseenUserNotifications: {
            keyArgs: false,
          },
          getFileHistoryList: {
            keyArgs: ['fileId', 'filters'],
            merge: mergeCursorPaginatedList,
          },
          getUserNotificationList: {
            keyArgs: ['filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
          getPhaseList: {
            keyArgs: ['filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
          getIterationList: {
            keyArgs: ['filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
          getReviewList: {
            keyArgs: ['filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
          CDTReports: {
            keyArgs: ['filters', 'order'],
            merge: mergeCursorPaginatedList,
          },
        },
      },
    },
  });
}

// Concatenate the incoming list items with the existing list items.
// This works as cursor paginated lists can't skip pages
function mergeCursorPaginatedList(
  existing: SafeReadonly<any> = { items: [] },
  incoming: SafeReadonly<any>,
  { args }: FieldFunctionOptions,
) {
  if (args?.page?.afterCursor) {
    return {
      ...existing,
      ...incoming,
      items: [...existing.items, ...incoming.items],
    };
  }

  return incoming;
}
