import React, { useRef } from 'react';

import { getModelTypeOptions } from '../../../entities/utils';
import { Icon } from '../../../ui';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'types';

const sizeIcon = 18;

class ModelTypeFilterNode<T> extends FilterNode<T> {
  private options = getModelTypeOptions().map(({ icon, ...option }) => ({
    ...option,
    icon: <Icon icon={icon} size={sizeIcon} />,
  }));

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="model-type-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const ModelTypeFilter = () => {
  const { current: node } = useRef(new ModelTypeFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.modelType.label', defaultMessage: 'Model type' }),
    node,
  });

  return null;
};
