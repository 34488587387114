import React from 'react';

import { SideNavTab, SideNavTabs } from '../navigation';

import { FilterCount } from './internal';

import styles from './FilterMenu.module.scss';

interface FilterMenuProps {
  filters: Record<string, any>;
  plugins: {
    field: string;
    label: string;
  }[];
  isSelected: (field: string) => boolean;
  selectPlugin: (field: string) => void;
}

export const FilterMenu = ({ filters, plugins, isSelected, selectPlugin }: FilterMenuProps) => (
  <SideNavTabs>
    {plugins.map(({ label, field }) => (
      <SideNavTab
        key={field}
        addonAfter={<FilterCount filter={filters[field]} />}
        active={isSelected(field)}
        onClick={() => selectPlugin(field)}
        className={styles.menu_item}
      >
        {label}
      </SideNavTab>
    ))}
  </SideNavTabs>
);
