import React, { FormEvent } from 'react';

export const Form = ({ onSubmit, ...props }: VecticeHTMLProps<HTMLFormElement>) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit?.(e);
  };

  return <form onSubmit={handleSubmit} {...props} />;
};
