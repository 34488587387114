import cn from 'classnames';
import React, { MouseEvent, PropsWithChildren, useEffect, useState } from 'react';

import { getCategoryTypeLabel } from '../../../entities/utils';
import { CategoryType } from '../../../gql/graphql';
import { DataList, FlexContainer, Icon, Summary, Tooltip, Typography } from '../../../ui';
import { getDbTableTypeIcon, getMimeTypeIcon } from '../../../utils';

import styles from './ResourcesMenuItem.module.scss';

const HeadResourcesMenuCell = ({
  id,
  bold,
  className,
  ...props
}: PropsWithChildren<{ id: string; className?: string; bold?: boolean }>) => (
  <Typography
    id={id}
    component="dt"
    variant="footnote"
    color="secondary"
    weight={bold ? 'semi-bold' : 'regular'}
    ellipsis
    className={cn(styles.cell, className)}
    {...props}
  />
);

const DataResourcesMenuCell = ({ bold, ...props }: PropsWithChildren<{ bold?: boolean }>) => (
  <Typography
    component="dd"
    variant="footnote"
    color="tertiary"
    weight={bold ? 'semi-bold' : 'regular'}
    ellipsis
    className={styles.cell}
    {...props}
  />
);

interface ResourcesMenuProps {
  resource: {
    id: number;
    name: string;
    type?: string;
    mimeType?: string;
    rowsNumber?: number | null;
    columnsNumber?: number | null;
    columnsTypesCounts: { categoryType: CategoryType; count: number }[];
  };
  active?: boolean;
  onSelectResource?: (id: number) => void;
}

export const ResourcesMenuItem = ({ resource, active, onSelectResource }: ResourcesMenuProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (active) {
      setOpen(true);
    }
  }, [active]);

  const resourceIcon = resource.mimeType ? getMimeTypeIcon(resource.mimeType) : getDbTableTypeIcon(resource.type);

  return (
    <Summary
      summary={
        <Tooltip key={resource.id} text={resource.name} placement="right">
          <FlexContainer
            gap={4}
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.preventDefault();
              onSelectResource?.(resource.id);
            }}
            className={styles.name}
          >
            {resourceIcon && <Icon icon={resourceIcon} size={18} />}
            <Typography variant="callout" color={active ? 'accent' : 'inherit'} ellipsis>
              {resource.name}
            </Typography>
          </FlexContainer>
        </Tooltip>
      }
      open={open}
      onToggle={setOpen}
      className={styles.menu_item}
      summaryClassName={cn(styles.summary, { [styles.active]: active })}
      detailsClassName={styles.details}
    >
      <DataList>
        <HeadResourcesMenuCell bold id={`number-columns-${resource.id}`}>
          {$t({ id: 'resource.card.columnNumber', defaultMessage: 'Nb of Columns' })}
        </HeadResourcesMenuCell>
        <DataResourcesMenuCell aria-labelledby={`number-columns-${resource.id}`}>
          {resource.columnsNumber ?? $t({ id: 'resource.menu.item.columnNumber.noValue', defaultMessage: 'N/A' })}
        </DataResourcesMenuCell>
        {resource.columnsTypesCounts.map((column) => (
          <React.Fragment key={column.categoryType}>
            <HeadResourcesMenuCell id={`number-${column.categoryType}-${resource.id}`} className={styles.subHeadCell}>
              {getCategoryTypeLabel(column.categoryType)}
            </HeadResourcesMenuCell>
            <DataResourcesMenuCell aria-labelledby={`number-${column.categoryType}-${resource.id}`}>
              {column.count ?? $t({ id: 'resource.card.menu.item.count.noValue', defaultMessage: 'N/A' })}
            </DataResourcesMenuCell>
          </React.Fragment>
        ))}
        <HeadResourcesMenuCell id={`number-rows-${resource.id}`} bold>
          {$t({ id: 'resource.card.rowsNumber', defaultMessage: 'Nb of Rows' })}
        </HeadResourcesMenuCell>
        <DataResourcesMenuCell aria-labelledby={`number-rows-${resource.id}`}>
          {resource.rowsNumber ?? $t({ id: 'resource.card.menu.item.rowsNumber.noValue', defaultMessage: 'N/A' })}
        </DataResourcesMenuCell>
      </DataList>
    </Summary>
  );
};
