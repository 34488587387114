import { useQuery } from '@apollo/client';
import { max, StackedBarChart } from '@vectice/data-viz';
import React, { useMemo } from 'react';

import { graphql } from '../../../gql';
import { ProjectStatus } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { EmptyData, FlexContainer, Typography } from '../../../ui';
import { generateArray } from '../../../utils';
import { UserAvatar } from '../../asset-display';
import { ProjectStatusBadge } from '../../badges';
import { FormatUserName } from '../../formatters';
import { ColorSwatch } from '../colors';
import { DashboardCard } from '../internals';

import styles from './PhasesCompleted.module.scss';

export const GET_COMPLETED_PHASES = graphql(`
  query getWorkspaceCompletedPhasesCountPerUser($workspaceIdList: [VecticeId!]!) {
    getWorkspaceCompletedPhasesCountPerUser(
      workspaceIdList: $workspaceIdList
      page: { index: 1, size: 10 }
      numberOfDays: 30
    ) {
      items {
        user {
          id
          ...userFields
        }
        count
      }
    }
  }
`);

interface Datum {
  index: number;
  count: number;
}

const getIndex = (datum: Datum) => datum.index;
const getCount = (datum: Datum) => datum.count;

const margins = { top: 10, bottom: 40, left: 32, right: 10 };
const getColor = () => ColorSwatch.summerGreen;

const tooltip =
  (users: UserIdentity[]) =>
  ({ count, index }: Datum) =>
    (
      <FlexContainer component={Typography} direction="column" gap={8} variant="callout" color="white">
        <FlexContainer align="center" gap={4}>
          <UserAvatar user={users[index]} size="xs" />
          <Typography weight="semi-bold" ellipsis>
            <FormatUserName user={users[index]} />
          </Typography>
        </FlexContainer>
        <FlexContainer align="center" gap={4}>
          <Typography>
            {$t({
              id: 'dashboard.phasesCompleted.tooltip.count',
              defaultMessage: '{count, plural, one {<bold>#</bold> Phase} other {<bold>#</bold> Phases}}',
              values: { count },
            })}
          </Typography>
          <ProjectStatusBadge status={ProjectStatus.Completed} contrast />
        </FlexContainer>
      </FlexContainer>
    );

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.phasesCompleted.empty',
      defaultMessage: 'No Phases Completed',
    })}
  />
);

interface PhasesCompletedProps {
  workspaceIdList: string[];
}

export const PhasesCompleted = ({ workspaceIdList }: PhasesCompletedProps) => {
  const { data, loading } = useQuery(GET_COMPLETED_PHASES, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdList,
    },
  });

  const { users, formattedData, maxValue } = useMemo(() => {
    const phasesCompleted = data?.getWorkspaceCompletedPhasesCountPerUser.items ?? [];
    const users = phasesCompleted.map((d) => d.user);
    const formattedData: Datum[] = generateArray(10).map((d, index) => ({
      index,
      count: phasesCompleted[index]?.count ?? 0,
    }));
    return {
      users,
      formattedData,
      maxValue: max(formattedData, getCount),
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.phasesCompleted.title', defaultMessage: 'Phases Completed' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.relativeTime', defaultMessage: 'In the last 30 days' })}
      hint={$t({
        id: 'dashboard.phasesCompleted.hint',
        defaultMessage: 'The count of phases completed for the top 10 phase owners.',
      })}
      extraLeftSpace={maxValue > 9999}
      loading={loading}
    >
      {maxValue === 0 ? (
        <NoData />
      ) : (
        <figure className={styles.figure}>
          <StackedBarChart
            data={formattedData}
            keys={['count']}
            getX={getIndex}
            getY={getCount}
            getColor={getColor}
            margins={margins}
            thickness={8}
            maxValue={Math.max(maxValue, 5)}
            leftAxis={{
              numTicks: 5,
              tickFormat: (tick) => (typeof tick === 'number' ? tick : tick.valueOf()).toFixed(),
            }}
            bottomAxis={{
              numTicks: 10,
              tickRenderFn: (tick) => {
                const index = tick ? parseInt(tick.toString()) : 0;
                const user = users[index];
                if (user) {
                  return <UserAvatar user={user} size="xs" />;
                }
                return null;
              },
              tickHeight: 24,
              tickWidth: 24,
            }}
            gridRows={{ numTicks: 5 }}
            tooltip={tooltip(users)}
          />
        </figure>
      )}
    </DashboardCard>
  );
};
