import { graphql } from '../../gql';

export const GET_USER_NOTIFICATION_LIST = graphql(`
  query getUserNotificationList(
    $order: ListOrderInput
    $filters: UserNotificationListFiltersInput
    $afterCursor: String
    $pageSize: Int!
  ) {
    getUserNotificationList(order: $order, page: { size: $pageSize, afterCursor: $afterCursor }, filters: $filters) {
      items {
        id
        type
        content
        createdDate
        creator {
          id
          ...userFields
        }
        workspace {
          vecticeId
          name
        }
      }
      page {
        afterCursor
        hasNextPage
      }
      total
    }
  }
`);
