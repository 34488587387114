import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as ReportIcon } from '../../../assets/icons/specials/ic-report.svg';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { AssetRow, Typography } from '../../../ui';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const ReportRowFragment = graphql(`
  fragment reportRow on CDTReport {
    id
    name
    updatedDate
    createdBy {
      ...userFields
    }
    project {
      id
      name
      workspace {
        vecticeId
        type
        name
        type
      }
    }
  }
`);

interface ReportRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  report?: FragmentType<typeof ReportRowFragment>;
}

export const ReportRow = (props: ReportRowProps) => {
  const report = useFragment(ReportRowFragment, props.report);

  if (!report) {
    return null;
  }

  return (
    <AssetRow icon={ReportIcon} parents={report.project.name} label={report.name} {...props}>
      {report.project.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace
            name={report.project.workspace.name}
            iconSize={14}
            type={report.project.workspace.type}
            ellipsis
          />
        </Typography>
      )}
      {!!report.createdBy && <UserAvatar user={report.createdBy} size="xs" />}
      {!!report.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy date={<FormatDate date={report.updatedDate} fromNow />} />
        </Typography>
      )}
    </AssetRow>
  );
};
