import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import { graphql } from '../../../gql';
import { Divider, EmptySelect, FilterLabel, FilterSearch, Radio, RadioGroup, WithLoading } from '../../../ui';
import { CommonFilterProps } from '../types';

import styles from './Filters.module.scss';

export const getWorkspaceQuery = graphql(`
  query getUserWorkspaceFiltersList($search: String!) {
    getUserWorkspaceList(filters: { searchFilter: { search: $search, fields: ["name"] } }) {
      items {
        vecticeId
        name
      }
    }
  }
`);

export const WorkspaceFilterComponent = ({ filter, onFilterUpdate }: CommonFilterProps) => {
  const [search, setSearch] = useState('');

  const { data, loading } = useQuery(getWorkspaceQuery, {
    fetchPolicy: 'network-only',
    variables: { search },
  });

  const workspaces = data?.getUserWorkspaceList?.items;

  const options = useMemo(
    () =>
      (workspaces || []).map((workspace) => ({
        label: workspace.name,
        value: workspace.vecticeId,
      })),
    [workspaces],
  );

  return (
    <>
      <FilterSearch initialSearch={search} onSearch={setSearch} />
      <Divider className={styles.divider} />
      <WithLoading loading={loading}>
        <RadioGroup
          name="workspace-radio-group"
          value={filter}
          onChange={(value) => onFilterUpdate(value.length ? value : undefined)}
          className={styles.options}
        >
          {options.map((workspace: any) => (
            <Radio key={workspace.label} className={styles.option} value={workspace.value} gutterBottom>
              <FilterLabel>{workspace.label}</FilterLabel>
            </Radio>
          ))}
        </RadioGroup>
        {search && !options.length && <EmptySelect />}
      </WithLoading>
    </>
  );
};
