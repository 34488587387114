import { OnboardingStatus } from '../gql/graphql';

export enum LegacyTrialStep {
  IntroductionVideo = 'introductionVideo',
  ExploreQuickstartProject = 'exploreQuickstartProject',
  ExploreSampleProject = 'exploreSampleProject',
  InviteColleagues = 'inviteColleagues',
}

type excludedOptions = typeof OnboardingStatus.Skipped;
export type OnboardingStep = Exclude<OnboardingStatus, excludedOptions>;

export type OnboardingSteps = Record<
  OnboardingStep,
  {
    getAction: (element?: React.ReactNode) => string;
    getHint: () => string;
  }
>;

export const onboardingSteps: OnboardingSteps = {
  [OnboardingStatus.Step1]: {
    getAction: (link: React.ReactNode) =>
      $t({
        id: 'onboarding.step1.action',
        defaultMessage: '<link>Watch the introduction video</link>',
        values: { link },
      }),
    getHint: () =>
      $t({
        id: 'onboarding.step1.hint',
        defaultMessage: 'to see how Vectice simplifies AI/ML documentation',
      }),
  },
  [OnboardingStatus.Step2]: {
    getAction: (link: React.ReactNode) =>
      $t({
        id: 'onboarding.step2.action',
        defaultMessage: '<link>Run the Quickstart project</link>',
        values: { link },
      }),
    getHint: () =>
      $t({
        id: 'onboarding.step2.hint',
        defaultMessage: 'to test drive Vectice',
      }),
  },
  [OnboardingStatus.Step3]: {
    getAction: (link: React.ReactNode) =>
      $t({
        id: 'onboarding.step3.action',
        defaultMessage: '<link>Invite your colleagues</link>',
        values: { link },
      }),
    getHint: () =>
      $t({
        id: 'onboarding.step3.hint',
        defaultMessage: 'to experience Vectice',
      }),
  },
  [OnboardingStatus.Step4]: {
    getAction: (link: React.ReactNode) =>
      $t({
        id: 'onboarding.step4.action',
        defaultMessage: '<link>Discover advanced features</link>',
        values: { link },
      }),
    getHint: () =>
      $t({
        id: 'onboarding.step4.hint',
        defaultMessage: 'in our Sample tutorial project',
      }),
  },
};

export const isTrialOnboardingComplete = (onboardingStatus: OnboardingStatus[] | undefined): boolean => {
  if (!onboardingStatus) return false;
  return (
    onboardingStatus.includes(OnboardingStatus.Step1) &&
    onboardingStatus.includes(OnboardingStatus.Step2) &&
    onboardingStatus.includes(OnboardingStatus.Step3) &&
    onboardingStatus.includes(OnboardingStatus.Step4)
  );
};
