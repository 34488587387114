import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { DataSetVersion } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const DELETE_DATASET_VERSION = graphql(`
  mutation deleteDatasetVersion($datasetVersionId: VecticeId!) {
    deleteDatasetVersion(datasetVersionId: $datasetVersionId)
  }
`);

interface DeleteDatasetVersionModalProps {
  datasetVersion: Pick<DataSetVersion, 'vecticeId' | 'versionNumber'>;
  datasetId?: string | null;
  onClose: () => void;
}

export const DeleteDatasetVersionModal = ({ datasetVersion, datasetId, onClose }: DeleteDatasetVersionModalProps) => {
  const navigate = useNavigate();

  const [deleteDatasetVersion, { loading: deleting }] = useMutation(DELETE_DATASET_VERSION, {
    variables: {
      datasetVersionId: datasetVersion?.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        onClose();
        navigate(buildLink(VecticeRoutes.DATASET, { datasetId }));
        flushFromGraphQLCache(cache, { vecticeId: data.deleteDatasetVersion, __typename: 'DataSetVersion' });
      }
    },
    onCompleted: () => {
      if (datasetVersion) {
        message.success(
          $t({
            id: 'component.action.deleteDatasetVersion.successMessage',
            defaultMessage: 'The version has been deleted',
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <DeletionPrompt
      processing={deleting}
      resourceName={$t({
        id: 'DatasetVersionPage.version',
        defaultMessage: 'Version {versionNumber}',
        values: {
          versionNumber: datasetVersion.versionNumber,
        },
      })}
      title={$t({ id: 'datasetVersion.modal.delete.title', defaultMessage: 'Delete Version' })}
      onClose={onClose}
      onConfirm={deleteDatasetVersion}
    />
  );
};
