import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, { ChangeEvent, useCallback, useEffect, useImperativeHandle, useRef } from 'react';

import { FormItemContainer } from '../FormItemContainer';
import { CommonProps, LabelProps, SizeProps } from '../interfaces';

import { DEBOUNCE_WAIT_TIME } from './internals';

import styles from './TextArea.module.scss';

let globalId = 0;

type TextAreaProps = CommonProps<HTMLTextAreaElement> &
  LabelProps &
  SizeProps & {
    hideResize?: boolean;
  };

export const TextArea = React.forwardRef(
  (
    {
      className,
      error,
      gutterBottom,
      help,
      hideResize,
      hint,
      label,
      name,
      smaller,
      small,
      big,
      bigger,
      onChange,
      onDebouncedChange,
      autoFocus,
      ...props
    }: TextAreaProps,
    ref: React.Ref<HTMLTextAreaElement | null>,
  ) => {
    // eslint-disable-next-line no-plusplus
    const { current: textareaId } = useRef(`${name || 'textarea'}-${globalId++}`);
    const innerRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => innerRef.current);

    useEffect(() => {
      if (autoFocus && innerRef.current) {
        innerRef.current.focus();
      }
    }, [innerRef, autoFocus]);

    const debounceChange = useCallback(
      debounce((event) => onDebouncedChange?.(event), DEBOUNCE_WAIT_TIME),
      [onDebouncedChange],
    );

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(e);
      debounceChange(e.currentTarget.value);
    };

    return (
      <FormItemContainer
        label={label}
        hint={hint}
        help={help}
        gutterBottom={gutterBottom}
        error={error}
        className={className}
        id={textareaId}
      >
        <textarea
          id={textareaId}
          name={name}
          className={cn(styles.textarea, {
            [styles.hideResize]: hideResize,
            [styles.smaller]: smaller,
            [styles.small]: small,
            [styles.big]: big,
            [styles.bigger]: bigger,
          })}
          onChange={handleChange}
          {...props}
          ref={innerRef}
        />
      </FormItemContainer>
    );
  },
);
