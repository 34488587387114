import React, { HTMLAttributes } from 'react';

import { ReactComponent as GoldLogoIcon } from '../../assets/brand/logo-gold.svg';
import { ReactComponent as LogoWithNameIcon } from '../../assets/brand/logo-with-name.svg';
import { ReactComponent as LogoIcon } from '../../assets/brand/logo.svg';
import { Icon } from '../svg';

export const Logo = ({
  size = 30,
  withName,
  variant = 'default',
  ...props
}: Omit<HTMLAttributes<HTMLOrSVGElement>, 'color'> & {
  size?: number;
  withName?: boolean;
  variant?: 'gold' | 'default';
}) => {
  if (withName) {
    return <LogoWithNameIcon height={size} width="auto" aria-label="Vectice" {...props} />;
  }

  if (variant === 'gold') {
    return <Icon icon={GoldLogoIcon} size={size} {...props} />;
  }

  return <Icon icon={LogoIcon} size={size} {...props} />;
};
