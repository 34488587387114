import React, { useEffect, useRef } from 'react';

import { MatchEnum } from '../../../gql/graphql';
import { NoMetrics } from '../../no-results';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { EntitiesFilterComponent } from '../ui';
import { EntitiesProps } from '../ui/EntitiesFilterComponent';

export const pluginField = 'metrics';

const options = [
  { id: MatchEnum.Greater, label: '>', value: MatchEnum.Greater },
  { id: MatchEnum.Lower, label: '<', value: MatchEnum.Lower },
  { id: MatchEnum.GreaterOrEqual, label: '≥', value: MatchEnum.GreaterOrEqual },
  { id: MatchEnum.LowerOrEqual, label: '≤', value: MatchEnum.LowerOrEqual },
  { id: MatchEnum.Equal, label: '=', value: MatchEnum.Equal },
  { id: MatchEnum.Different, label: '≠', value: MatchEnum.Different },
];

class MetricsFilterNode<T extends EntitiesProps> extends FilterNode<T> {
  updateProps(props?: T) {
    super.updateProps(props);
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    if (this.props?.entitiesNames?.length === 0) return <NoMetrics />;
    return (
      <EntitiesFilterComponent
        key={pluginField}
        {...this.props}
        inputType="number"
        matchOptions={options}
        defaultMatch={MatchEnum.Lower}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const MetricsFilter = ({ entitiesNames }: EntitiesProps) => {
  const { current: node } = useRef(new MetricsFilterNode<EntitiesProps>({ entitiesNames }));

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.metrics.label', defaultMessage: 'Metrics' }),
    node,
  });

  useEffect(() => {
    node.updateProps({ entitiesNames });
  }, [entitiesNames]);

  return null;
};
