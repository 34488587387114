import { ReactComponent as MicrosoftWordIcon } from '../assets/icons/files/ic-microsoft-word.svg';
import { ReactComponent as PDFIcon } from '../assets/icons/files/ic-pdf.svg';

export enum ExportOptionsType {
  Pdf = 'pdf',
  Doc = 'docx',
}

export const getExportIcon = (option: ExportOptionsType) => {
  switch (option) {
    case ExportOptionsType.Doc:
      return MicrosoftWordIcon;
    case ExportOptionsType.Pdf:
      return PDFIcon;
    default:
      return null;
  }
};

export const getExportLabel = (option: ExportOptionsType) => {
  switch (option) {
    case ExportOptionsType.Doc:
      return $t({ id: 'projectExport.word.label', defaultMessage: 'Microsoft Word (.doc)' });
    case ExportOptionsType.Pdf:
      return $t({ id: 'projectExport.pdf.label', defaultMessage: 'PDF Document (.pdf)' });
    default:
      return 'not supported';
  }
};
