import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { MatchEnum, OrderDirection } from '../gql/graphql';
import {
  CHECK_STEP_DEFINITION_NAME_AVAILABILITY,
  CREATE_STEP_DEFINITION,
  GET_STEP_DEFINITION_LIST,
  UPDATE_STEP_POSITION,
} from '../graphql/queries';
import { message } from '../ui';
import { getReorderedItems } from '../utils';

export const useSteps = (phaseId?: string, sectionNames?: string[]) => {
  const apolloClient = useApolloClient();

  const getStepListVariables = useMemo(
    () => ({
      filters: {
        parentId: phaseId!,
        advancedFilters:
          sectionNames && sectionNames.length > 0
            ? [
                {
                  filters: [
                    {
                      valuesWithMatch: sectionNames.map((name) => ({ value: name, match: MatchEnum.Different })),
                      field: 'name',
                    },
                  ],
                },
              ]
            : [],
      },
      order: {
        field: 'index',
        direction: OrderDirection.Asc,
      },
    }),
    [phaseId, sectionNames],
  );

  const { data: stepsData, loading } = useQuery(GET_STEP_DEFINITION_LIST, {
    skip: !phaseId,
    fetchPolicy: 'network-only',
    variables: getStepListVariables,
    onError: (error) => message.error(error.message),
  });
  const steps = stepsData?.getStepDefinitionList.items;
  const total = stepsData?.getStepDefinitionList.total;

  const [checkNameAvailability] = useLazyQuery(CHECK_STEP_DEFINITION_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const checkStepNameAvailability = useCallback(
    (resourceId?: number) => async (name: string) => {
      if (phaseId) {
        const { data: { checkStepDefinitionNameAvailability } = {} } = await checkNameAvailability({
          variables: {
            name,
            parentId: phaseId,
            resourceId,
          },
        });

        return !!checkStepDefinitionNameAvailability;
      }

      return true;
    },
    [phaseId],
  );

  const [createStep, { loading: creating }] = useMutation(CREATE_STEP_DEFINITION, {
    refetchQueries: ['getStepDefinitionList'],
    update: (cache) => {
      if (phaseId) {
        cache.modify({
          id: cache.identify({ vecticeId: phaseId, __typename: 'Phase' }),
          fields: {
            stepsCount(cachedData) {
              return cachedData + 1;
            },
          },
        });
      }
    },
  });

  const [updateStepPosition] = useMutation(UPDATE_STEP_POSITION);

  const reorderStep = (oldPosition: number, newPosition: number) => {
    const step = steps?.[oldPosition];

    if (!steps || !step) return;

    const oldData = apolloClient.readQuery({
      query: GET_STEP_DEFINITION_LIST,
      variables: getStepListVariables,
    });
    const oldSteps = oldData?.getStepDefinitionList?.items;

    if (!oldSteps) return;

    apolloClient.writeQuery({
      query: GET_STEP_DEFINITION_LIST,
      variables: getStepListVariables,
      data: {
        getStepDefinitionList: {
          ...oldData?.getStepDefinitionList,
          items: getReorderedItems(oldSteps, oldPosition, newPosition),
        },
      },
    });

    updateStepPosition({
      variables: {
        id: step.id,
        updateModel: {
          name: step.name,
          index: newPosition,
        },
      },
    });
  };

  return {
    creating,
    loading,
    steps,
    total,
    checkStepNameAvailability,
    createStep,
    reorderStep,
  };
};
