import React from 'react';

import { OwnerFilter } from './OwnerFilter';

export const IterationOwnerFilter = ({ workspaceIdList }: { workspaceIdList: string[] }) => (
  <OwnerFilter
    workspaceIdList={workspaceIdList}
    label={$t({ id: 'filters.iteration.owner.label', defaultMessage: 'Iteration owner' })}
  />
);
