import React, { Suspense } from 'react';

import { Lightbox, Loading } from '../../ui';
import { downloadAIRegulation, getAIRegulationImpactUrl } from '../../utils';

const PdfPreview = React.lazy(() => import('../previews/pdf'));

interface CheatSheetPreviewProps {
  onClose: () => void;
}

export const AIRegulationImpactPreview = ({ onClose }: CheatSheetPreviewProps) => (
  <Lightbox
    title={$t({
      id: 'productTour.airegulation.title',
      defaultMessage: 'AI regulation impact',
    })}
    content={
      <Suspense fallback={<Loading color="white" />}>
        <PdfPreview url={getAIRegulationImpactUrl()} noPreviewVariant="white" />
      </Suspense>
    }
    onDownload={downloadAIRegulation}
    onClose={onClose}
  />
);
