import React from 'react';

import { ColumnSourceType } from '../../../gql/graphql';
import { SourceColumnWithFragment } from '../types';

import { DatasetSourceColumnWrapper } from './DatasetSourceColumnWrapper';

interface FallbackSourceColumnProps {
  column: SourceColumnWithFragment;
  columnSourceType: ColumnSourceType;
}

export const FallbackSourceColumn = ({ column, columnSourceType }: FallbackSourceColumnProps) => (
  <DatasetSourceColumnWrapper column={column} columnSourceType={columnSourceType} />
);
