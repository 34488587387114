import React from 'react';

import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/ic-model-version.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink, TypographyVariants } from '../../../ui';

import { ModelVersionTooltip } from './ModelVersionTooltip';

interface ModelVersionLinkProps {
  name?: string | null;
  resourceId?: string | null;
  iconSize?: number;
  variant?: TypographyVariants;
}

export const ModelVersionLink = ({ name, resourceId, variant, iconSize }: ModelVersionLinkProps) => (
  <ModelVersionTooltip id={resourceId} placement="topLeft">
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.MODEL_VERSION, { modelVersionId: resourceId }) : undefined}
      icon={ModelVersionIcon}
      variant={variant}
      iconSize={iconSize}
    />
  </ModelVersionTooltip>
);
