import React, { useCallback } from 'react';

import LauncherGraphic from '../assets/graphics/splash/launcher.svg';
import { broadcastClient } from '../services';
import { Button, Crash, Typography } from '../ui';

import styles from './Errors.module.scss';

const reloadPage = () => window.location.reload();

export const VersionMismatchPage = () => {
  const handleUpdate = useCallback(() => {
    broadcastClient.post('update', true);
    reloadPage();
  }, []);

  return (
    <Crash graphic={LauncherGraphic}>
      <Typography variant="heading1" gutterBottom>
        {$t({ id: 'errorPage.versionMismatch.title', defaultMessage: 'New Version Available' })}
      </Typography>
      <Typography paragraph gutterBottom>
        {$t({
          id: 'errorPage.versionMismatch.description',
          defaultMessage:
            'Looks like a new version of Vectice is available.\nPlease contact <support>Support</support> if the issue persists.',
        })}
      </Typography>
      <div className={styles.actions}>
        {!!window.location && (
          <Button variant="primary" onClick={handleUpdate}>
            {$t({ id: 'errorPage.versionMismatch.update', defaultMessage: 'Update Vectice' })}
          </Button>
        )}
      </div>
    </Crash>
  );
};
