import cn from 'classnames';
import React, { ReactNode } from 'react';

import { FlexContainer } from '../containers';
import { Typography } from '../typography';

import { CommonProps } from './interfaces';

import styles from './Form.module.scss';

interface InputContainerProps<Element> extends CommonProps<Element> {
  label?: ReactNode;
  afterLabel?: ReactNode;
}

export const FormItemContainer = <Element extends HTMLElement>({
  label,
  hint,
  afterLabel,
  help,
  id,
  error,
  gutterBottom,
  className,
  children,
}: InputContainerProps<Element>) => {
  const footerColor = error ? 'danger' : 'secondary';

  return (
    <div className={cn(styles.itemContainer, { [styles.gutterBottom]: gutterBottom }, className)}>
      {(label || hint) && (
        <div className={styles.itemHeader}>
          {label && (
            <FlexContainer align="center" gap={0}>
              <Typography
                htmlFor={id}
                component="label"
                variant="callout"
                weight="semi-bold"
                color="primary"
                className={styles.itemLabel}
              >
                {label}
              </Typography>
              {afterLabel}
            </FlexContainer>
          )}
          {hint && (
            <Typography variant="callout" color="tertiary" className={styles.itemHint}>
              {hint}
            </Typography>
          )}
        </div>
      )}
      {children}
      {(help || error) && (
        <Typography variant="footnote" weight="semi-bold" color={footerColor} className={styles.itemFooter}>
          {error || help}
        </Typography>
      )}
    </div>
  );
};
