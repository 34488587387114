import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { OrderDirection } from '../../gql/graphql';
import { Breadcrumb, Breadcrumbs, Button, Dropdown, FlexContainer, MenuItem, Typography } from '../../ui';
import { MenuWithSearch } from '../../ui-presets';

import { SOURCE_COLUMNS } from './sourceColumn.query';

import styles from './AssetSelector.module.scss';

export const SourceColumnSelector = ({
  fileId,
  fileType,
  selectedAssetId: targetColumnName,
  disabled = false,
  canClearAsset = true,
  triggerClassName,
  onSelect,
}: AssetSelectorProps) => {
  const [search, setSearch] = useState<string | undefined>(undefined);

  const { data: sourceColumnsData, loading: loadingSourceColumns } = useQuery(SOURCE_COLUMNS, {
    skip: !fileId || disabled || !fileType,
    variables: {
      id: fileId!,
      search,
      columnSourceType: fileType!,
      order: { field: 'updatedDate', direction: OrderDirection.Desc },
      page: {
        size: 10,
        index: 1,
      },
    },
  });
  const options = sourceColumnsData?.sourceColumns.items;

  const clearAsset = () => {
    onSelect(null);
  };

  const overlay = (
    <MenuWithSearch
      search={search}
      onSearch={setSearch}
      loading={loadingSourceColumns}
      isEmpty={!options || !options.length}
    >
      {options?.map((column) => (
        <MenuItem
          key={`option-${column.id}`}
          onClick={() => {
            onSelect(column.name);
          }}
        >
          <FlexContainer>
            <Typography variant="callout">{column.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  if (!loadingSourceColumns && search === '' && options?.length === 0) {
    return (
      <Typography className={styles.emptyAssetSelector} variant="callout" color="disabled" weight="semi-bold">
        {$t({ id: 'assetSelector.sourceColumnSelector.empty', defaultMessage: 'No column available' })}
      </Typography>
    );
  }

  return (
    <>
      {!targetColumnName && (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={(visible) => {
            if (!visible) setSearch('');
          }}
        >
          <Button
            aria-label="Select asset"
            className={cn(styles.trigger, triggerClassName, { [styles.disabled]: disabled })}
            disabled={disabled}
            variant="white"
          >
            <Typography ellipsis color="tertiary">
              {$t({ id: 'assetSelector.selectAColumn', defaultMessage: 'Select a column' })}
            </Typography>
          </Button>
        </Dropdown>
      )}
      {targetColumnName && (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb aria-label={targetColumnName} color="primary" inert weight="regular">
              {targetColumnName}
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      )}
    </>
  );
};
