import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { CdtReport } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const REMOVE_CDT_REPORT = graphql(`
  mutation removeCDTReport($id: Float!) {
    removeCDTReport(id: $id)
  }
`);

interface DeleteCDTReportModalProps {
  projectId: string | null;
  report: Pick<CdtReport, 'id' | 'name'>;
  onClose: () => void;
}

export const DeleteCDTReportModal = ({ projectId, report, onClose }: DeleteCDTReportModalProps) => {
  const navigate = useNavigate();

  const [deleteReport, { loading }] = useMutation(REMOVE_CDT_REPORT, {
    variables: {
      id: report?.id,
    },
    update: (cache, { data }) => {
      if (data) {
        flushFromGraphQLCache(cache, { id: data.removeCDTReport, __typename: 'CDTReport' });
      }
    },
    onCompleted: () => {
      if (report) {
        onClose();
        message.success(
          $t({
            id: 'component.action.deleteCDTReport.successMessage',
            defaultMessage: 'Report "{name}" has been deleted',
            values: {
              name: report?.name,
            },
          }),
        );
        navigate(buildLink(VecticeRoutes.PROJECT_CDT_REPORTS, { projectId }));
      }
    },
    onError: (error) => message.error(error.message),
  });

  return (
    <DeletionPrompt
      processing={loading}
      resourceName={report.name}
      title={$t({ id: 'report.modal.delete.title', defaultMessage: 'Delete report' })}
      onClose={onClose}
      onConfirm={deleteReport}
    />
  );
};
