import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType } from '../../../gql/graphql';
import { PhaseLink, ProjectLink } from '../../asset-display';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const ReviewCommentActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const project = <ProjectLink name={activity.project?.name} resourceId={activity.project?.vecticeId} />;

  const phase = (
    <PhaseLink
      name={activity.sourceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.sourceVecticeId}
    />
  );

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.reviewComment.created',
          defaultMessage: '{user} commented on review for {phase} in {project}',
          values: {
            user,
            phase,
            project,
          },
        })}
      </>
    );
  }

  return null;
};
