import React, { useMemo } from 'react';

import { round } from '../../utils';

import { THOUSAND } from './constants';

export const formatNumber = (value?: number | null) => {
  const NUMBER_ABBR_UNITS = [
    $t({ id: 'numberAbbrUnit.none', defaultMessage: '' }),
    $t({ id: 'numberAbbrUnit.thousand', defaultMessage: 'K' }),
    $t({ id: 'numberAbbrUnit.million', defaultMessage: 'M' }),
    $t({ id: 'numberAbbrUnit.billion', defaultMessage: 'B' }),
    $t({ id: 'numberAbbrUnit.trillion', defaultMessage: 'T' }),
  ];

  if (value === null || value === undefined) {
    return $t({ id: 'formatters.formatNumber.noValue', defaultMessage: 'N/A' });
  }

  if (!value) {
    return '0';
  }

  // We only add units for numbers over 10k
  if (Math.abs(value) < 10 * THOUSAND) {
    return `${round(value)}`;
  }

  let unit = 0;
  const r = 10;

  do {
    // eslint-disable-next-line no-param-reassign
    value /= THOUSAND;
    unit += 1;
  } while (Math.round(Math.abs(value) * r) / r >= THOUSAND && unit < NUMBER_ABBR_UNITS.length - 1);

  return `${round(value)} ${NUMBER_ABBR_UNITS[unit]}`;
};

export interface FormatNumberProps {
  value?: number | null;
}

export const FormatNumber = ({ value }: FormatNumberProps) => {
  const formattedSize = useMemo(() => formatNumber(value), [value]);

  if (!formattedSize) {
    return null;
  }

  return <>{formattedSize}</>;
};
