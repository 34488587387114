import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Tooltip, Typography } from '../../../../ui';

import styles from './StatisticCell.module.scss';

interface StatisticCellProps extends Omit<VecticeHTMLProps<HTMLTableCellElement>, 'label'> {
  label: ReactNode;
  tooltip?: string | null;
  header?: boolean;
}

export const StatisticCell = ({ label, tooltip, header, className, ...props }: StatisticCellProps) => {
  const Component = header ? 'th' : 'td';
  return (
    <Component className={cn(styles.cell, className)} {...props}>
      <Tooltip text={tooltip ?? label} placement="top">
        <Typography component="div" ellipsis>
          {label}
        </Typography>
      </Tooltip>
    </Component>
  );
};
