import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as FilterIcon } from '../../assets/icons/interface/ic-filter.svg';
import { Button, Dropdown, FilterMenu, FilterPills, FilterOverlay, Filters } from '../../ui';

import { FilterComposerContext } from './context';
import { contributorField, workspaceField } from './plugins';
import { RegisteredPlugin } from './types';
import { cleanFilters } from './utils';

interface FilterComposerProps {
  filters?: Record<string, any>;
  nbOfVisiblePills?: number;
  onFiltersUpdate?: (filters: Record<string, any>) => void;
}

const defaultFilters = {};

const sortPlugins = (a: RegisteredPlugin, b: RegisteredPlugin) => a.label.localeCompare(b.label);

export const FilterComposer = ({
  children,
  filters,
  nbOfVisiblePills,
  onFiltersUpdate,
}: PropsWithChildren<FilterComposerProps>) => {
  const [appliedFilters, setAppliedFilters] = useState<Record<string, any>>(defaultFilters);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedPlugin, setSelectedPlugin] = useState<string>();
  const [registeredPlugins, setRegisteredPlugins] = useState<string[]>([]);
  const [plugins, setPlugins] = useState<Record<string, RegisteredPlugin>>({});

  useEffect(() => {
    setAppliedFilters(filters || defaultFilters);
  }, [filters]);

  const isSelected = (field: string) => field === selectedPlugin;

  const handleShowFilter = (field: string) => {
    setSelectedPlugin(field);
    setShowFilters(true);
  };

  const isEmpty = useMemo(() => {
    if (selectedPlugin === 'properties' || selectedPlugin === 'metrics') {
      return plugins[selectedPlugin]?.node.props.entitiesNames.length === 0;
    }
    return false;
  }, [selectedPlugin, isSelected]);

  const { current: register } = useRef((plugin: RegisteredPlugin) => {
    setPlugins((prevState) => {
      const newState = { ...prevState, [plugin.field]: plugin };

      const sortedPlugins = Object.values(newState)
        .sort(sortPlugins)
        .map((plugin) => plugin.field);
      setRegisteredPlugins(sortedPlugins);
      setSelectedPlugin(sortedPlugins[0]);

      return newState;
    });

    return () => {
      setRegisteredPlugins((prevState) => {
        const newState = prevState.filter((field) => field !== plugin.field);
        const [firstPlugin] = newState;

        setSelectedPlugin((current) => {
          if (current === plugin.field) {
            return firstPlugin;
          }

          return current;
        });

        return newState;
      });
      setPlugins((prevState) => {
        const copy = { ...prevState };
        delete copy[plugin.field];
        return copy;
      });
    };
  });

  const handleClearFilter = useCallback(
    (field?: string) => {
      if (field) {
        setAppliedFilters((prevState) => {
          const newFilters = {
            ...prevState,
          };
          delete newFilters[field];

          onFiltersUpdate?.(newFilters);

          return newFilters;
        });
      }
    },
    [onFiltersUpdate],
  );

  const handleClearAllFilter = useCallback(() => {
    setAppliedFilters(() => {
      const newFilters = {};

      onFiltersUpdate?.(newFilters);

      return newFilters;
    });
  }, [onFiltersUpdate]);

  const onFilterUpdate = useCallback(
    (field: string, filter: any) => {
      setAppliedFilters((prevState) => {
        const newFilters = {
          ...prevState,
          [field]: filter,
        };

        // reset contributor filter if workspace filter is changed
        if (field === workspaceField) {
          delete newFilters[contributorField];
        }

        if (filter === undefined) delete newFilters[field];

        onFiltersUpdate?.(newFilters);

        return newFilters;
      });
    },
    [onFiltersUpdate],
  );

  const overlay = () => (
    <FilterOverlay
      menu={
        <FilterMenu
          filters={cleanFilters(registeredPlugins, appliedFilters)}
          plugins={registeredPlugins.map((field) => plugins[field])}
          isSelected={isSelected}
          selectPlugin={setSelectedPlugin}
        />
      }
      onClearFilter={() => handleClearFilter(selectedPlugin)}
      onClearAllFilters={handleClearAllFilter}
      hideClearButton={isEmpty}
      resetLabel={$t({
        id: 'FilterComposer.resetAllFilters',
        defaultMessage: 'Reset all filters',
      })}
      clearLabel={$t({
        id: 'FilterComposer.clear',
        defaultMessage: 'Clear',
      })}
    >
      {selectedPlugin && plugins[selectedPlugin]?.node.render(appliedFilters[selectedPlugin], onFilterUpdate)}
    </FilterOverlay>
  );

  return (
    <FilterComposerContext.Provider value={{ register }}>
      <Filters>
        <Dropdown overlay={overlay()} visible={showFilters} onVisibleChange={setShowFilters} trigger="click">
          <Button leftIcon={FilterIcon} variant="white">
            {$t({ id: 'FilterComposer.filters', defaultMessage: 'Filters' })}
          </Button>
        </Dropdown>
        <FilterPills
          filters={cleanFilters(registeredPlugins, appliedFilters)}
          plugins={plugins}
          resetLabel={$t({
            id: 'FilterComposer.resetAllFilters',
            defaultMessage: 'Reset all filters',
          })}
          onSelectFilter={handleShowFilter}
          onClearFilter={handleClearFilter}
          onClearAllFilters={handleClearAllFilter}
          nbOfVisiblePills={nbOfVisiblePills}
        />
      </Filters>
      {children}
    </FilterComposerContext.Provider>
  );
};
