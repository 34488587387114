import React, { useEffect, useRef } from 'react';

import { getUserRoleDisplayName, getUserRoleOptions } from '../../../entities/utils';
import { UserRole } from '../../../gql/graphql';
import { useWithFeatureFlags } from '../../../guards';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'role';

const visibleOptions = [UserRole.OrgAdmin, UserRole.Member];

class UserRoleFilterNode<T> extends FilterNode<T> {
  private options = getUserRoleOptions().filter(({ value }) => visibleOptions.includes(value));

  addObserverRole() {
    if (!this.options.find((option) => option.value === UserRole.Observer)) {
      this.options.push({
        label: getUserRoleDisplayName(UserRole.Observer),
        value: UserRole.Observer,
      });
    }
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="user-role-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const UserRoleFilter = () => {
  const { allowed } = useWithFeatureFlags({ featureFlag: 'viewer-role' });
  const { current: node } = useRef(new UserRoleFilterNode());

  useEffect(() => {
    if (allowed) {
      node.addObserverRole();
    }
  }, [allowed]);

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.userRole.label', defaultMessage: 'Role' }),
    node,
  });

  return null;
};
