import cn from 'classnames';
import RcTable from 'rc-table';
import { ColumnType as RcColumnType } from 'rc-table/lib/interface';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import React, { ReactNode, useMemo } from 'react';

import { WithLoading } from '../loading-animation';

import { Cell, HeadCell } from './Cells';
import { useSelection, useSorting, UseSelectionProps, UseSortingProps } from './hooks';
import { TableSkeleton } from './TableSkeleton';
import { DefaultRecordType } from './types';
import { convertChildrenToColumns, getExpandableProps } from './utils';

import styles from './Table.module.scss';

interface TableProps<RecordType>
  extends Omit<RcTableProps<RecordType>, 'columns' | 'emptyText' | 'scroll'>,
    Omit<UseSelectionProps<RecordType>, 'data'>,
    UseSortingProps {
  borderless?: boolean;
  emptyText: ReactNode;
  loading?: boolean;
  scroll?: {
    x?: true;
    y?: boolean;
  };
  size?: 'lg' | 'md' | 'sm';
}

export const Table = <RecordType extends DefaultRecordType>({
  children,
  className,
  data,
  initialSelection,
  initialSort,
  size = 'md',
  borderless,
  loading,
  rowKey = 'id',
  selectedRows,
  singleSelect,
  onSelectAll,
  onSelectRow,
  onSelectionChange,
  onSort,
  expandable,
  scroll,
  emptyText,
  ...props
}: TableProps<RecordType>) => {
  const { injectSorting } = useSorting<RecordType>({ initialSort, onSort });
  const { injectSelectable } = useSelection<RecordType>({
    initialSelection,
    selectedRows,
    singleSelect,
    onSelectRow,
    onSelectAll,
    onSelectionChange,
    data,
  });

  const columns: RcColumnType<RecordType>[] = useMemo(() => {
    const cols = convertChildrenToColumns<RecordType>(children, injectSorting);

    return injectSelectable(cols);
  }, [children, injectSorting, injectSelectable]);

  const mergedExpandable = getExpandableProps<RecordType>(
    expandable,
    !!(onSelectionChange || onSelectAll || onSelectRow),
  );

  if (!data?.length && !loading) {
    return <div className={styles.empty_container}>{emptyText}</div>;
  }

  return (
    <WithLoading loading={loading} skeleton={<TableSkeleton size={size} />}>
      <RcTable<RecordType>
        components={{
          header: {
            cell: HeadCell,
          },
          body: {
            cell: Cell,
          },
        }}
        data={data}
        rowKey={rowKey}
        columns={columns}
        className={cn(
          styles.tableData,
          {
            [styles.borderless]: borderless,
            [styles.empty]: !data?.length,
          },
          styles[size],
          className,
        )}
        rowClassName={styles.tableRow}
        prefixCls="vectice"
        expandable={mergedExpandable}
        scroll={{
          x: scroll?.x,
          y: scroll?.y ? 1 : undefined,
        }}
        {...props}
      />
    </WithLoading>
  );
};
