import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationMention } from '../types';

import { Notification } from './Notification';

interface WorkspaceMentionNotificationPhaseProps {
  phaseId: string;
  notification: UserNotificationMention;
  phaseName?: string;
  onClose: () => void;
}

const GET_PHASE_BY_ID = graphql(`
  query getPhaseForMention($id: VecticeId!) {
    getPhaseById(id: $id) {
      vecticeId
      name
    }
  }
`);

export const MentionNotificationPhase = ({
  phaseId,
  notification: { creator, createdDate },
  phaseName,
  onClose,
}: WorkspaceMentionNotificationPhaseProps) => {
  const { data, loading } = useQuery(GET_PHASE_BY_ID, {
    variables: {
      id: phaseId,
    },
    skip: phaseName !== undefined,
  });

  const fetchedPhaseName = data?.getPhaseById.name || $t({ id: 'generic.unknown', defaultMessage: 'Unknown' });

  if (loading) {
    return null;
  }

  return (
    <Notification
      component={Link}
      to={buildLink(VecticeRoutes.PHASE_DOCUMENTATION, { phaseId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.mention.phase',
        defaultMessage: '<bold>{creator}</bold> mentioned <bold>you</bold> in phase <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title: phaseName || fetchedPhaseName,
        },
      })}
    </Notification>
  );
};
