import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { CdtReport } from '../../gql/graphql';
import { useValidateName, useVecticeForm } from '../../hooks';
import { useCheckReportNameAvailability } from '../../hooks/useCheckReportNameAvailability';
import { buildLink, VecticeRoutes } from '../../routes';
import { Input, message, ModalForm, WithAsyncValidation } from '../../ui';
import { defaultNameFieldConfiguration, getCopiedName, VecticeResourceType } from '../../utils';

export const DUPLICATE_CDT_REPORT = graphql(`
  mutation duplicateCDTReport($id: Float!, $duplicateInput: BaseDocumentationDuplicateInput!) {
    duplicateCDTReport(id: $id, duplicateInput: $duplicateInput) {
      id
    }
  }
`);

interface FormData {
  name: string;
}

interface DuplicateCDTReportModalProps {
  report: Pick<CdtReport, 'id' | 'name'>;
  projectId: string;
  onClose: () => void;
}

export const DuplicateCDTReportModal = ({ report, projectId, onClose }: DuplicateCDTReportModalProps) => {
  const navigate = useNavigate();

  const { checkCDTReportNameAvailability } = useCheckReportNameAvailability(projectId, report.id);
  const { validateName } = useValidateName(checkCDTReportNameAvailability, VecticeResourceType.CDT_REPORT);

  const duplicateLabel = $t({ id: 'modal.duplicateReport.duplicateResource', defaultMessage: 'Duplicate Report' });

  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: report.name ? getCopiedName(report.name) : '',
    },
  });
  const { hasErrors, isSubmitting } = formState;

  const [duplicateReport] = useMutation(DUPLICATE_CDT_REPORT, {
    refetchQueries: ['CDTReports'],
    onCompleted: ({ duplicateCDTReport: { id } }) => {
      onClose();
      navigate(buildLink(VecticeRoutes.CDT_REPORT, { reportId: id }));
    },
    onError: (error) => message.error(error.message),
  });

  const onSubmit: SubmitHandler<FormData> = async ({ name }) =>
    await duplicateReport({
      variables: {
        id: report.id,
        duplicateInput: {
          name,
        },
      },
    });

  useEffect(() => {
    trigger('name');
  }, [report.name, validateName]);

  return (
    <ModalForm
      disabled={hasErrors}
      isSubmitting={isSubmitting}
      title={duplicateLabel}
      submitLabel={duplicateLabel}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(onSubmit)}
      onClose={onClose}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          autoFocus
          gutterBottom
          label={$t({ id: 'modal.duplicateReport.nameLabel', defaultMessage: 'Report Name' })}
          placeholder={$t({ id: 'modal.duplicateReport.untitled', defaultMessage: 'Untitled' })}
          required
        />
      </WithAsyncValidation>
    </ModalForm>
  );
};
