import cn from 'classnames';
import RcDialog, { DialogProps as RcDialogProps } from 'rc-dialog';
import React, { ReactNode } from 'react';

import 'rc-dialog/assets/index.css';

import { ReactComponent as CloseIcon } from '../../../assets/icons/interface/ic-remove.svg';
import { Icon } from '../../svg';

import { DialogTitle } from './DialogTitle';

import styles from './Dialog.module.scss';

export interface DialogProps extends Omit<RcDialogProps, 'title' | 'footer'> {
  title: ReactNode;
  titleBadge?: ReactNode;
  subtitle?: ReactNode;
  responsive?: boolean;
  withBackground?: boolean;
}

export const Dialog = ({ className, title, titleBadge, subtitle, responsive, visible, ...props }: DialogProps) => (
  <RcDialog
    className={cn(styles.dialog, { [styles.responsive]: responsive }, className)}
    closeIcon={!!props.onClose && <Icon icon={CloseIcon} size={20} />}
    footer={null}
    title={<DialogTitle title={title} titleBadge={titleBadge} subtitle={subtitle} className={styles.title} />}
    visible={visible === undefined ? true : visible}
    destroyOnClose
    closable={!!props.onClose}
    {...props}
  />
);
