import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/ic-model-version.svg';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { AssetRow, Typography } from '../../../ui';
import { ModelVersionStatusBadge, VersionBadge } from '../../badges';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';

const ModelVersionRowFragment = graphql(`
  fragment modelVersionRow on ModelVersion {
    vecticeId
    versionNumber
    status
    algorithmName
    isStarred
    model {
      vecticeId
      name
    }
    updatedDate
    lastUpdatedBy {
      ...userFields
    }
  }
`);

interface ModelVersionRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  modelVersion?: FragmentType<typeof ModelVersionRowFragment>;
}

export const ModelVersionRow = (props: ModelVersionRowProps) => {
  const modelVersion = useFragment(ModelVersionRowFragment, props.modelVersion);

  if (!modelVersion) {
    return null;
  }

  return (
    <AssetRow
      icon={ModelVersionIcon}
      label={modelVersion.model.name}
      versionBadge={<VersionBadge versionNumber={modelVersion.versionNumber} />}
      isStarred={modelVersion.isStarred}
      {...props}
    >
      <Typography variant="footnote" color="secondary">
        {modelVersion.vecticeId}
      </Typography>
      <ModelVersionStatusBadge status={modelVersion.status} />
      {!!modelVersion.algorithmName && (
        <Typography variant="footnote" color="secondary">
          {$t({
            id: 'assetDisplay.modelVersion.technique.label',
            defaultMessage: 'Technique:',
          })}{' '}
          {modelVersion.algorithmName}
        </Typography>
      )}
      {!!modelVersion.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy
            date={<FormatDate date={modelVersion.updatedDate} fromNow />}
            user={<UserAvatar user={modelVersion.lastUpdatedBy} size="xs" />}
          />
        </Typography>
      )}
    </AssetRow>
  );
};
