import { graphql } from '../../gql';

export const GET_USER_ACTIVITY_LIST = graphql(`
  query getUserActivityList(
    $workspaceIdList: [VecticeId!]
    $projectIds: [VecticeId!]
    $contributors: [ID!]
    $targetFilter: ActivityTargetFilter
    $activityTargetTypes: [ActivityTargetType!]
    $activityTypes: [ActivityType!]
    $afterCursor: String
    $pageSize: Int!
    $onlyMine: Boolean
  ) {
    getUserActivityList(
      workspaceIdList: $workspaceIdList
      filters: {
        projectIds: $projectIds
        contributors: $contributors
        activityTypes: $activityTypes
        activityTargetTypes: $activityTargetTypes
        targetFilter: $targetFilter
        onlyMine: $onlyMine
      }
      page: { size: $pageSize, afterCursor: $afterCursor }
    ) {
      ...userActivities
      page {
        hasNextPage
        afterCursor
      }
      total
    }
  }
`);
