import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ApiKeyIcon } from '../../../assets/icons/menu/ic-key.svg';
import { ReactComponent as ApiKeyAddIcon } from '../../../assets/icons/specials/ic-key-add.svg';
import { useAuthentication } from '../../../context';
import { UserRole } from '../../../gql/graphql';
import { WithRoles } from '../../../guards';
import { useAnalytics } from '../../../hooks';
import { buildLink, VecticeRoutes } from '../../../routes';
import { Dropdown, Icon, Menu, MenuItem, Tooltip } from '../../../ui';
import { ApiKeyModal } from '../../modals';

import styles from './Menus.module.scss';

export const ApiKeyMenu = () => {
  const { user } = useAuthentication();

  const [showModal, setShowModal] = useState(false);
  const { logClick } = useAnalytics();

  const menu = (
    <Menu className={styles.menu}>
      <Link to={buildLink(VecticeRoutes.ACCOUNT_API_KEYS)} className={styles.link}>
        <MenuItem clickable leftIcon={ApiKeyIcon}>
          {$t({ id: 'apiKeyMenu.manageApiKeys', defaultMessage: 'Manage API keys' })}
        </MenuItem>
      </Link>

      <MenuItem
        leftIcon={ApiKeyAddIcon}
        onClick={() => {
          logClick('API_Key', 'fast-create', 'click');
          setShowModal(true);
        }}
      >
        {$t({ id: 'apiKeyMenu.createApiKey', defaultMessage: 'Create API key' })}
      </MenuItem>
    </Menu>
  );

  return (
    <WithRoles organizationRole={[UserRole.OrgAdmin, UserRole.Member]}>
      {user && (
        <Tooltip text={$t({ id: 'apiKeyMenu.tooltip', defaultMessage: 'API key' })} placement="bottom">
          <Dropdown overlay={menu} trigger={['click']}>
            <button aria-label="API Key" className={styles.button} type="button">
              <span className={styles.button_label}>
                <Icon icon={ApiKeyIcon} />
              </span>
            </button>
          </Dropdown>
        </Tooltip>
      )}
      {showModal && <ApiKeyModal onClose={() => setShowModal(false)} />}
    </WithRoles>
  );
};
