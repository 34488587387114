import React, { ReactElement, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs, FlexContainer, MoreActions } from '../../ui';
import { getArray } from '../../utils';
import { StarringButton } from '../buttons';

import styles from './AssetVersionHeader.module.scss';

interface ParentElement {
  label: string;
  to?: LinkProps['to'];
}

interface AssetVersionHeaderProps extends VecticeHTMLProps<HTMLDivElement> {
  moreActionsMenu?: ReactElement;
  parents?: ParentElement | ParentElement[];
  versionName: ReactNode;
  starred?: boolean;
  updateStar?: (value: boolean) => any;
}

export const AssetVersionHeader = ({
  className,
  moreActionsMenu,
  parents,
  versionName,
  starred = false,
  updateStar,
}: AssetVersionHeaderProps) => {
  const parentElements = parents ? getArray(parents) : null;

  return (
    <FlexContainer className={className} gap={8}>
      <Breadcrumbs>
        {parentElements?.map(({ label, to }) => (
          <Breadcrumb
            tooltip={label}
            key={label}
            aria-label={label}
            to={to}
            variant="heading2"
            color="primary"
            weight="regular"
            wide={parentElements.length === 1}
          >
            {label}
          </Breadcrumb>
        ))}
        <Breadcrumb iconSize={24} variant="heading2" color="primary" inert>
          {versionName}
        </Breadcrumb>
      </Breadcrumbs>
      {!!updateStar && <StarringButton size="xs" starred={starred} onChange={(value) => updateStar(value)} />}
      {moreActionsMenu && <MoreActions className={styles.moreAction} moreActionsMenu={moreActionsMenu} size="xs" />}
    </FlexContainer>
  );
};
