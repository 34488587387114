import cn from 'classnames';
import React from 'react';

import { ReactComponent as ClearIcon } from '../../../../assets/icons/interface/ic-remove.svg';
import { Icon } from '../../../svg';

import styles from './ClearInput.module.scss';

interface ClearInputProps {
  className?: string;
  onClear?: () => void;
}

export const ClearInput = ({ className, onClear }: ClearInputProps) => (
  <Icon
    role="button"
    color="gray"
    className={cn(className, styles.clearIcon)}
    aria-label="Clear Input"
    style={{ cursor: 'pointer' }}
    icon={ClearIcon}
    size={20}
    onClick={onClear}
  />
);
