import { AccessibilityLevel } from '../../gql/graphql';

export const getAccessibilityLevelDisplayName = (level?: AccessibilityLevel | string | null) => {
  switch (level) {
    case AccessibilityLevel.Manager:
      return $t({ id: 'accessibilityLevel.admin.label', defaultMessage: 'Manager' });
    case AccessibilityLevel.Editor:
      return $t({ id: 'accessibilityLevel.member.label', defaultMessage: 'Editor' });
    case AccessibilityLevel.ReadOnly:
      return $t({ id: 'accessibilityLevel.observer.label', defaultMessage: 'Reader' });
    default:
      return level || $t({ id: 'accessibilityLevel.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getAccessibilityLevelDescription = (level?: AccessibilityLevel | string | null) => {
  switch (level) {
    case AccessibilityLevel.Manager:
      return $t({
        id: 'accessibilityLevel.admin.description',
        defaultMessage: 'Full access to the workspace settings and user management',
      });
    case AccessibilityLevel.Editor:
      return $t({
        id: 'accessibilityLevel.member.description',
        defaultMessage: 'Standard access to the workspace resources and functionalities',
      });
    case AccessibilityLevel.ReadOnly:
      return $t({
        id: 'accessibilityLevel.observer.description',
        defaultMessage: 'Read-only access with ability to comment, review and export documentation',
      });
    default:
      return level || $t({ id: 'accessibilityLevel.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getAccessibilityLevelOptions = () => [
  {
    label: getAccessibilityLevelDisplayName(AccessibilityLevel.Manager),
    value: AccessibilityLevel.Manager,
  },
  {
    label: getAccessibilityLevelDisplayName(AccessibilityLevel.Editor),
    value: AccessibilityLevel.Editor,
  },
  {
    label: getAccessibilityLevelDisplayName(AccessibilityLevel.ReadOnly),
    value: AccessibilityLevel.ReadOnly,
  },
];
