import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { graphql } from '../../../gql';
import { DatasetSource, DatasetSourceType } from '../../../gql/graphql';

export const GET_DATASET_SOURCE_FILE_SOURCE_LIST = graphql(`
  query getDataSourceFileSourceList($datasetSourceId: Float!) {
    fileSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {
      items {
        id
        filename
        mimeType
        rowsNumber
        columnsNumber
        columnsTypesCounts {
          categoryType
          count
        }
      }
      total
    }
  }
`);

export const GET_DATASET_SOURCE_DB_SOURCE_LIST = graphql(`
  query getDataSourceDbSourceList($datasetSourceId: Float!) {
    dbSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {
      items {
        id
        tablename
        type
        rowsNumber
        columnsNumber
        columnsTypesCounts {
          categoryType
          count
        }
      }
      total
    }
  }
`);

export const useDatasetSourceList = (datasetSource?: Pick<DatasetSource, 'id' | 'type'> | null) => {
  const { data: fileData, loading: fileLoading } = useQuery(GET_DATASET_SOURCE_FILE_SOURCE_LIST, {
    fetchPolicy: 'no-cache',
    skip: !datasetSource || datasetSource.type !== DatasetSourceType.Files,
    variables: {
      datasetSourceId: datasetSource?.id!,
    },
  });

  const fileSources = useMemo(
    () =>
      fileData?.fileSources.items?.map((item) => ({
        ...item,
        name: item.filename,
      })),
    [fileData],
  );

  const { data: dbData, loading: dbLoading } = useQuery(GET_DATASET_SOURCE_DB_SOURCE_LIST, {
    fetchPolicy: 'no-cache',
    skip: !datasetSource || datasetSource.type !== DatasetSourceType.Db,
    variables: {
      datasetSourceId: datasetSource?.id!,
    },
  });

  const dbSources = useMemo(
    () =>
      dbData?.dbSources.items?.map((item) => ({
        ...item,
        name: item.tablename,
      })),
    [dbData],
  );

  return {
    loading: fileLoading || dbLoading,
    sources: dbSources ?? fileSources,
    total: dbData?.dbSources.total ?? fileData?.fileSources.total ?? 0,
  };
};
