import cn from 'classnames';
import React, { ElementType, ReactNode, useMemo, useState } from 'react';

import { ReactComponent as DirtyCodeIcon } from '../../../assets/icons/interface/ic-alert-triangle.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/interface/ic-edit.svg';
import { ReactComponent as ViewIcon } from '../../../assets/icons/interface/ic-fullscreen.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/interface/ic-link.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/interface/ic-upload.svg';
import { ReactComponent as GitBranchIcon } from '../../../assets/icons/specials/flat/ic-branch.svg';
import { useFragment } from '../../../gql';
import { EntityFileRowFragment } from '../../../graphql/fragments';
import { useEntityFiles } from '../../../hooks/useEntityFiles';
import { Badge, Button, FlexContainer, Icon, Tooltip, Typography, Upload } from '../../../ui';
import {
  getDataBricksHostIcon,
  getDataBricksSubIcon,
  getGitHostIcon,
  getGitHostLink,
  getMimeTypeIcon,
  getOtherIcon,
  VecticeResourceType,
} from '../../../utils';
import { FormatDate, FULL_FORMAT } from '../../formatters';
import { LightboxPreview } from '../../previews';
import { LineageCodeFragment } from '../fragments';

import { LineageCodeProps } from './interfaces';

import styles from './LineageCode.module.scss';

type CodeInfo = {
  hostIcon?: ElementType;
  hostLinkIcon?: ElementType;
  hostLink?: string;
  info1?: string | null;
  info2?: string | ReactNode;
  info3?: string;
  info4?: string | null;
  subIcon?: ElementType;
  subMessages?: ReactNode;
};

export const LineageCode = ({
  codeFile: codeFileFragment,
  codeSource: codeSourceFragment,
  editMode,
  lineageId,
  onEdit,
}: LineageCodeProps) => {
  const { uploading, addEntityFiles, removeEntityFile } = useEntityFiles(lineageId, VecticeResourceType.RUN);

  const [openPreview, setOpenPreview] = useState(false);

  const codeFile = useFragment(EntityFileRowFragment, codeFileFragment);
  const codeSource = useFragment(LineageCodeFragment, codeSourceFragment);
  const gitVersion = codeSource?.codeVersion?.gitVersion;
  const dataBrickVersion = codeSource?.codeVersion?.databricksVersion;
  const otherCodeVersion = codeSource?.codeVersion?.otherCodeVersion;

  let codeInfo: CodeInfo;

  if (dataBrickVersion) {
    codeInfo = {
      hostIcon: getDataBricksHostIcon(),
      hostLink: dataBrickVersion.urlNotebook,
      info1: dataBrickVersion.relativePath?.trim(),
      info2: dataBrickVersion.timestamp ? <FormatDate date={dataBrickVersion.timestamp} format={FULL_FORMAT} /> : null,
      subIcon: getDataBricksSubIcon(),
      info3: dataBrickVersion.urlNotebook?.trim(),
      info4: dataBrickVersion.additionalInformation?.trim(),
    };
  } else if (otherCodeVersion)
    codeInfo = {
      hostLinkIcon: getOtherIcon(),
      hostLink: otherCodeVersion.url,
      info1: otherCodeVersion.firstAdditionalInformation?.trim(),
      info2: otherCodeVersion.secondAdditionalInformation?.trim(),
      info3: otherCodeVersion.url?.trim(),
      info4: otherCodeVersion.thirdAdditionalInformation?.trim(),
    };
  else
    codeInfo = {
      hostIcon: getGitHostIcon(gitVersion),
      hostLink: getGitHostLink(gitVersion),
      info1: gitVersion?.repositoryName,
      info2: gitVersion?.branchName?.trim(),
      info3: (gitVersion?.uri || codeSource?.codeVersion?.code?.name)?.trim() ?? '',
      info4: gitVersion?.additionalInformation?.trim(),
      subIcon: GitBranchIcon,
      subMessages: gitVersion?.commitHash ? (
        <>
          <Typography color="tertiary" className={styles.code_detail_hash} ellipsis>
            {gitVersion?.commitHash}
          </Typography>
          {gitVersion?.isDirty && (
            <Tooltip
              placement="top"
              text={$t({
                id: 'lineage.dirtyCommit.tooltip',
                defaultMessage: 'Commit may not include the latest changes',
              })}
            >
              <Badge color="warning" leftIcon={DirtyCodeIcon} size="sm" className={styles.dirtyCommit} />
            </Tooltip>
          )}
        </>
      ) : null,
    };

  const codeComponent = useMemo(() => {
    if (codeFile && !codeSource) {
      return null;
    }

    if (!codeSource || !codeInfo)
      return (
        <Typography variant="callout" color="tertiary">
          {$t({ id: 'LineageCode.noSourceCode', defaultMessage: 'No source code defined' })}
        </Typography>
      );

    return (
      <FlexContainer className={styles.content} direction="column" gap={0}>
        {codeInfo.info3 !== '' && (
          <Typography component="div" variant="callout" color="accent" paragraph ellipsis>
            {codeInfo.hostLinkIcon && <Icon icon={codeInfo.hostLinkIcon} color="dark-gray" size={14} />}
            {codeInfo.info3}
          </Typography>
        )}
        {codeInfo.info1 !== '' && (
          <FlexContainer>
            {codeInfo.hostIcon && <Icon icon={codeInfo.hostIcon} size={14} />}
            <Typography color="secondary" ellipsis paragraph variant="callout">
              {codeInfo.info1}
            </Typography>
          </FlexContainer>
        )}
        {(!!codeInfo.info2 || !!codeInfo.subMessages) && (
          <FlexContainer>
            {codeInfo.subIcon && <Icon icon={codeInfo.subIcon} size={14} />}
            <Typography color="secondary" ellipsis paragraph variant="callout">
              {codeInfo.info2}
            </Typography>
            <Typography component="div" color="secondary" ellipsis paragraph variant="callout">
              {codeInfo.subMessages}
            </Typography>
          </FlexContainer>
        )}
        {codeInfo.info4 !== '' && (
          <Typography component="div" variant="callout" color="secondary" paragraph ellipsis>
            {codeInfo.info4}
          </Typography>
        )}
      </FlexContainer>
    );
  }, [codeFile, codeInfo, codeSource]);

  const codeFileComponent = useMemo(() => {
    if (!codeFile && !editMode) {
      return null;
    }

    if (codeFile) {
      return (
        <FlexContainer className={styles.file}>
          <Icon icon={getMimeTypeIcon(codeFile.mimeType)} size={14} />
          <Typography color="secondary" variant="callout">
            {codeFile.fileName}
          </Typography>
          <Tooltip text={$t({ id: 'lineageCodeFile.previewFile', defaultMessage: 'Preview file' })}>
            <Button
              aria-label={$t({ id: 'lineageCodeFile.previewFile', defaultMessage: 'Preview file' })}
              className={styles.button}
              color="gray"
              leftIcon={ViewIcon}
              size="xxs"
              variant="phantom"
              onClick={(e) => {
                e.preventDefault();
                setOpenPreview(true);
              }}
            />
          </Tooltip>
          {editMode && (
            <Tooltip text={$t({ id: 'lineageCodeFile.removeFile', defaultMessage: 'Remove file' })}>
              <Button
                aria-label={$t({ id: 'lineageCodeFile.removeFile', defaultMessage: 'Remove file' })}
                className={styles.button}
                color="gray"
                leftIcon={DeleteIcon}
                variant="phantom"
                size="xxs"
                onClick={(e) => {
                  e.preventDefault();
                  removeEntityFile({ ...codeFile, name: codeFile.fileName ?? '' });
                }}
              />
            </Tooltip>
          )}
        </FlexContainer>
      );
    }

    return null;
  }, [codeFile, editMode]);

  return (
    <>
      <FlexContainer className={cn('hover-reveal-parent', styles.container)} justify="space-between">
        <FlexContainer className={styles.container} direction="column" gap={0}>
          {codeComponent && (
            <Typography
              className={styles.lineageCode}
              component={codeInfo.hostLink ? 'a' : 'div'}
              href={codeInfo.hostLink}
              rel="noreferrer"
              target="_blank"
            >
              {codeComponent}
              {!editMode && (
                <FlexContainer className={cn('hover-reveal-child', styles.view)}>
                  <Typography variant="callout" weight="semi-bold" color="accent">
                    {$t({ id: 'lineage.viewLink', defaultMessage: 'View' })}
                  </Typography>
                  <Icon color="accent" icon={LinkIcon} size={20} />
                </FlexContainer>
              )}
            </Typography>
          )}
          {codeFileComponent}
        </FlexContainer>
        {editMode && (
          <FlexContainer className={styles.buttons}>
            {!codeFile && (
              <Tooltip
                placement="top"
                text={$t({ id: 'LineageCode.upload.hint', defaultMessage: 'Upload a code file' })}
              >
                <span>
                  <Upload
                    accept=".ipynb, .py, .r"
                    disabled={uploading}
                    icon={UploadIcon}
                    multiple={false}
                    uploading={uploading}
                    variant="white"
                    onUpload={(file) => addEntityFiles([file])}
                  >
                    {$t({ id: 'button.upload', defaultMessage: 'Upload' })}
                  </Upload>
                </span>
              </Tooltip>
            )}
            <Tooltip placement="top" text={$t({ id: 'LineageCode.edit.hint', defaultMessage: 'Edit the code source' })}>
              <Button className={styles.edit} leftIcon={EditIcon} variant="white" onClick={onEdit}>
                {$t({ id: 'button.edit', defaultMessage: 'Edit' })}
              </Button>
            </Tooltip>
          </FlexContainer>
        )}
      </FlexContainer>
      {codeFile && openPreview && <LightboxPreview entityFile={codeFile} onClose={() => setOpenPreview(false)} />}
    </>
  );
};
