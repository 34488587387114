import React from 'react';

import { useFragment } from '../../../gql';
import { ColumnSourceType } from '../../../gql/graphql';
import { isDefined } from '../../../utils';
import { FormatDate, FormatPercentage, getPercentageTooltip } from '../../formatters';
import { DateSourceColumn as DateSourceColumnType, DateSourceColumnFragment } from '../types';

import { DatasetSourceColumnWrapper } from './DatasetSourceColumnWrapper';
import { Statistics, StatisticRow, StatisticCell } from './internal';

interface DateSourceColumnProps {
  column: DateSourceColumnType;
  columnSourceType: ColumnSourceType;
}

export const DateSourceColumn = ({ column, columnSourceType }: DateSourceColumnProps) => {
  const statistics = useFragment(DateSourceColumnFragment, column.statistics);

  return (
    <DatasetSourceColumnWrapper column={column} columnSourceType={columnSourceType}>
      {!!statistics?.date && (
        <Statistics>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.date.minimum', defaultMessage: 'Minimum' })} />
            <StatisticCell label={<FormatDate date={statistics.date.minimum} withTooltip={false} utc />} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.date.mean', defaultMessage: 'Mean' })} />
            <StatisticCell label={<FormatDate date={statistics.date.mean} withTooltip={false} utc />} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.date.median', defaultMessage: 'Median' })} />
            <StatisticCell label={<FormatDate date={statistics.date.median} withTooltip={false} utc />} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.date.maximum', defaultMessage: 'Maximum' })} />
            <StatisticCell label={<FormatDate date={statistics.date.maximum} withTooltip={false} utc />} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.date.missing', defaultMessage: 'Missing' })} />
            <StatisticCell
              label={
                <FormatPercentage value={isDefined(statistics.date.missing) ? statistics.date.missing * 100 : null} />
              }
              tooltip={getPercentageTooltip(isDefined(statistics.date.missing) ? statistics.date.missing * 100 : null)}
            />
          </StatisticRow>
        </Statistics>
      )}
    </DatasetSourceColumnWrapper>
  );
};
