import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { Phase, OrderDirection } from '../gql/graphql';
import { GET_PHASE_LIST, UPDATE_PHASE } from '../graphql/queries';
import { message } from '../ui';
import { getReorderedItems } from '../utils';

export interface UsePhasesProps {
  projectId?: string;
}

export const usePhases = ({ projectId }: UsePhasesProps) => {
  const apolloClient = useApolloClient();

  const getPhaseListVariables = useMemo(
    () => ({
      filters: {
        parentId: projectId!,
      },
      order: {
        field: 'index',
        direction: OrderDirection.Asc,
      },
      page: {
        size: 100,
      },
    }),
    [projectId],
  );

  const { data, loading } = useQuery(GET_PHASE_LIST, {
    skip: projectId === undefined,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: getPhaseListVariables,
  });
  const phases = data?.getPhaseList.items;

  const [updatePhase] = useMutation(UPDATE_PHASE, {
    onError: () => {
      message.error(
        $t({
          id: 'phase.hook.phase.moveResourceFailure',
          defaultMessage: 'Oops, something went wrong! The Phase could not be reordered.',
        }),
      );
    },
  });

  const reorderPhase = (oldPosition: number, newPosition: number) => {
    const phase = phases?.[oldPosition];

    if (!phases || !phase) return;

    const oldData = apolloClient.readQuery({
      query: GET_PHASE_LIST,
      variables: getPhaseListVariables,
    });
    const oldPhases = oldData?.getPhaseList?.items;

    if (!oldPhases) return;

    apolloClient.writeQuery({
      query: GET_PHASE_LIST,
      variables: getPhaseListVariables,
      data: {
        getPhaseList: {
          ...oldData?.getPhaseList,
          // @ts-expect-error Issue with our current use of Fragments
          items: getReorderedItems<Phase>(oldPhases, oldPosition, newPosition),
        },
      },
    });

    updatePhase({
      variables: {
        id: phase.vecticeId,
        updateModel: {
          index: newPosition,
        },
      },
    });
  };

  return { loading, phases, reorderPhase };
};
