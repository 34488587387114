import { graphql } from '../../gql';

export const CREATE_PROJECT = graphql(`
  mutation createProject($workspaceId: VecticeId!, $data: ProjectCreateInput!) {
    createProject(workspaceId: $workspaceId, data: $data) {
      vecticeId
      name
      description
    }
  }
`);
