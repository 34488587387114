import React from 'react';

import { useFragment } from '../../gql';
import { User } from '../../gql/graphql';
import { UserFragment, UserIdentity } from '../../graphql/fragments';

export const formatUserName = (user?: Partial<Pick<User, 'name' | 'email'>> | null) => {
  if (user === null) return $t({ id: 'ActivityUser.automationUser', defaultMessage: 'Automation for Vectice' });

  if (!user) return 'unknown';

  return user.name || user.email;
};

export interface FormatUserProps {
  user?: UserIdentity | null;
}

export const FormatUserName = ({ user: userFragment }: FormatUserProps) => {
  const user = useFragment(UserFragment, userFragment);

  return <>{formatUserName(user)}</>;
};
