import React, { useMemo } from 'react';

import { VecticeResourceType } from '../../utils';

import { CDTReportSelector } from './CDTReportSelector';
import { DatasetVersionSelector } from './DatasetVersionSelector';
import { AssetSelectorProps } from './interfaces';
import { IterationSelector } from './IterationSelector';
import { ModelVersionSelector } from './ModelVersionSelector';
import { PhaseSelector } from './PhaseSelector';
import { ProjectSelector } from './ProjectSelector';
import { SourceColumnSelector } from './SourceColumnSelector';
import { WorkspaceSelector } from './WorkspaceSelector';

export const AssetSelector = ({ assetType, onSelect, ...props }: AssetSelectorProps) => {
  return useMemo(() => {
    switch (assetType) {
      case VecticeResourceType.WORKSPACE:
        return <WorkspaceSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.PROJECT:
        return <ProjectSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.DATASET_VERSION:
        return <DatasetVersionSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.MODEL_VERSION:
        return <ModelVersionSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.ITERATION:
        return <IterationSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.PHASE:
        return <PhaseSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.CDT_REPORT:
        return <CDTReportSelector assetType={assetType} onSelect={onSelect} {...props} />;
      case VecticeResourceType.SOURCE_COLUMN:
        return <SourceColumnSelector assetType={assetType} onSelect={onSelect} {...props} />;
      default:
        return null;
    }
  }, [assetType, onSelect]);
};
