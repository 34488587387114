import React from 'react';

import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { Button, ButtonProps, Tooltip } from '../../ui';
import { VecticeResourceType, getVecticeResourceTypeLabel } from '../../utils';

interface EditButtonProps extends ButtonProps {
  text?: string;
  resourceType?: VecticeResourceType;
}

export const EditButton = ({ resourceType, text, ...props }: EditButtonProps) => {
  const textDisplay =
    text ||
    (resourceType
      ? $t({
          id: 'component.editButton.name.edit',
          defaultMessage: 'Edit {resourceName}',
          values: { resourceName: getVecticeResourceTypeLabel(resourceType).toLowerCase() },
        })
      : $t({
          id: 'component.editButton.name.fallback',
          defaultMessage: 'Edit',
        }));
  return (
    <Tooltip text={textDisplay} placement="bottomRight">
      <Button aria-label={textDisplay} leftIcon={EditIcon} color="gray" variant="phantom" size="xs" {...props} />
    </Tooltip>
  );
};
