import { graphql } from '../../gql';

export const MODEL_VERSION = graphql(`
  query getModelVersionName($modelVersionId: VecticeId!) {
    getModelVersion(modelVersionId: $modelVersionId) {
      vecticeId
      versionNumber
      model {
        vecticeId
        name
        project {
          vecticeId
        }
      }
    }
  }
`);
