import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import DefaultTemplate from '../assets/graphics/project-templates/default.svg';
import EmptyTemplate from '../assets/graphics/project-templates/empty.svg';
import QuickstartTemplate from '../assets/graphics/project-templates/existing-project.svg';
import TutorialTemplate from '../assets/graphics/project-templates/tutorial.svg';
import { useAuthentication } from '../context';
import { AccessibilityLevel, Workspace, WorkspaceType } from '../gql/graphql';
import { CREATE_PROJECT } from '../graphql/mutations';
import { CHECK_PROJECT_NAME_AVAILABILITY, GET_USER_WORKSPACE_TARGET_LIST } from '../graphql/queries';
import { useWithFeatureFlags } from '../guards';
import { useValidateName, useVecticeForm } from '../hooks';
import { buildLink, VecticeRoutes } from '../routes';
import {
  ComboBox,
  FlexContainer,
  Graphic,
  Input,
  message,
  ModalForm,
  Radio,
  TextArea,
  Typography,
  WithAsyncValidation,
} from '../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../utils';

import styles from './Modals.module.scss';

export interface ProjectCreationFormValues {
  workspace: Pick<Workspace, 'vecticeId' | 'name'> | null;
  projectName: string;
  description?: string | null;
  templateName?: string;
}

export interface CreateProjectModalProps {
  workspace?: Pick<Workspace, 'vecticeId' | 'name' | 'type' | 'userContext'> | null;
  onClose: () => void;
}

const acceptableAccessibilityLevels = [AccessibilityLevel.Editor, AccessibilityLevel.Manager];

export const CreateProjectModal = ({ workspace: contextWorkspace, onClose }: CreateProjectModalProps) => {
  const navigate = useNavigate();
  const { defaultWorkspace } = useAuthentication();
  const [workspaceSearch, setWorkspaceSearch] = useState<string | null>(null);
  const { allowed: masterProject } = useWithFeatureFlags({ featureFlag: 'create_master_project' });

  const { control, formState, preSubmit, registerWithErrors, setError, trigger, watch } =
    useVecticeForm<ProjectCreationFormValues>({
      mode: 'all',
      defaultValues: {
        workspace:
          (contextWorkspace && contextWorkspace.type === WorkspaceType.Public) ||
          (contextWorkspace?.userContext.accessibility &&
            acceptableAccessibilityLevels.includes(contextWorkspace.userContext.accessibility))
            ? contextWorkspace
            : defaultWorkspace,
        projectName: '',
        templateName: 'default',
      },
    });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const workspace = watch('workspace');

  const { data } = useQuery(GET_USER_WORKSPACE_TARGET_LIST, {
    variables: {
      filters: {
        searchFilter: { search: workspaceSearch ?? '', fields: ['name'] },
        access: acceptableAccessibilityLevels,
      },
    },
    onError: (error) => message.error(error.message),
  });

  const workspaces = data?.getUserWorkspaceList.items;

  const workspaceOptions = useMemo(
    () =>
      (workspaces ?? []).map((workspace) => ({
        label: workspace.name,
        workspace,
      })),
    [workspaces],
  );

  const [checkNameAvailability] = useLazyQuery(CHECK_PROJECT_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const handleNameAvailability = useCallback(
    async (value: string) => {
      if (!workspace || !value) return true;

      const { data: { checkProjectNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          workspaceId: workspace.vecticeId,
          name: value,
        },
      });

      return !!checkProjectNameAvailability;
    },
    [checkNameAvailability, workspace],
  );

  const { validateName } = useValidateName(handleNameAvailability, VecticeResourceType.PROJECT);

  const [createProject] = useMutation(CREATE_PROJECT, {
    onCompleted: ({ createProject: { vecticeId, name } }) => {
      if (vecticeId) {
        message.success(
          $t({
            id: 'workspace.project.name',
            defaultMessage: '{name} project successfully created.',
            values: { name },
          }),
        );
        navigate(buildLink(VecticeRoutes.PROJECT, { projectId: vecticeId }));
        onClose();
      }
    },
    onError: (error) => message.error(error.message),
  });

  const handleSubmit = async (values: ProjectCreationFormValues) => {
    if (values.workspace) {
      await createProject({
        variables: {
          workspaceId: values.workspace.vecticeId,
          data: {
            name: values.projectName,
            description: values.description,
            templateName: values.templateName,
          },
        },
      });
    }
  };

  return (
    <ModalForm
      title={$t({ id: 'project.modal.create.title', defaultMessage: 'Create project' })}
      submitLabel={$t({ id: 'modal.create', defaultMessage: 'Create' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
      className={styles.modal}
    >
      <Controller
        control={control}
        name="workspace"
        rules={defaultNameFieldConfiguration()}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <ComboBox
            label={$t({ id: 'modal.projectCreation.workspaceLabel', defaultMessage: 'Workspace' })}
            value={value?.name}
            options={workspaceOptions}
            onSearch={setWorkspaceSearch}
            onSelectOption={async (option) => {
              onChange(option?.workspace ?? null);
              await trigger('workspace');
            }}
            error={error?.message}
            autoFocus={!contextWorkspace && !defaultWorkspace}
            gutterBottom
          />
        )}
      />
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('projectName')}
        onError={(error) => setError('projectName', error)}
      >
        <Input
          {...registerWithErrors('projectName', defaultNameFieldConfiguration())}
          label={$t({ id: 'form.projectCreation.nameLabel', defaultMessage: 'Project Name' })}
          autoFocus={!!contextWorkspace || !!defaultWorkspace}
          gutterBottom
        />
      </WithAsyncValidation>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        label={$t({ id: 'form.projectCreation.descriptionLabel', defaultMessage: 'Description' })}
        gutterBottom
      />
      <Typography id="radio-group-label" variant="callout" weight="semi-bold" color="primary">
        {$t({ id: 'form.projectCreation.selectTemplate', defaultMessage: 'Select an Option' })}
      </Typography>
      <div aria-labelledby="radio-group-label">
        <Radio {...registerWithErrors('templateName')} value="default" className={styles.radio}>
          <FlexContainer align="center" gap={10}>
            <Graphic src={DefaultTemplate} size={40} />
            <FlexContainer direction="column" gap={0}>
              <Typography variant="callout" weight="semi-bold">
                {$t({
                  id: 'form.projectCreation.defaultTemplate.title',
                  defaultMessage: 'Standard Project with CRISP-DM Template',
                })}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({
                  id: 'form.projectCreation.defaultTemplate.definition',
                  defaultMessage: 'A standard project following the CRISP-DM workflow',
                })}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </Radio>
        <Radio {...registerWithErrors('templateName')} value="" className={styles.radio}>
          <FlexContainer align="center" gap={10}>
            <Graphic src={EmptyTemplate} size={40} />
            <FlexContainer direction="column" gap={0}>
              <Typography variant="callout" weight="semi-bold">
                {$t({
                  id: 'form.projectCreation.blankTemplate.title',
                  defaultMessage: 'Empty Project',
                })}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({
                  id: 'form.projectCreation.blankTemplate.definition',
                  defaultMessage: 'An empty project if you want to define your phases and steps from scratch',
                })}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </Radio>
        <Radio {...registerWithErrors('templateName')} value="quickstart" className={styles.radio}>
          <FlexContainer align="center" gap={10}>
            <Graphic src={QuickstartTemplate} size={40} />
            <FlexContainer direction="column" gap={0}>
              <Typography variant="callout" weight="semi-bold">
                {$t({
                  id: 'form.projectCreation.quickstartTemplate.title',
                  defaultMessage: 'Quickstart project',
                })}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({
                  id: 'form.projectCreation.quickstartTemplate.definition',
                  defaultMessage: 'A project to discover Vectice in a few easy steps',
                })}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </Radio>
        <Radio {...registerWithErrors('templateName')} value="tutorial" className={styles.radio}>
          <FlexContainer align="center" gap={10}>
            <Graphic src={TutorialTemplate} size={40} />
            <FlexContainer direction="column" gap={0}>
              <Typography variant="callout" weight="semi-bold">
                {$t({
                  id: 'form.projectCreation.sampleTutorialTemplate.title',
                  defaultMessage: 'Sample Tutorial Project',
                })}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({
                  id: 'form.projectCreation.sampleTutorialTemplate.definition',
                  defaultMessage: 'A project that enables you to learn Vectice',
                })}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </Radio>
        {masterProject && (
          <Radio {...registerWithErrors('templateName')} value="master" className={styles.radio}>
            <FlexContainer align="center" gap={10}>
              <Graphic src={EmptyTemplate} size={40} />
              <FlexContainer direction="column" gap={0}>
                <Typography variant="callout" weight="semi-bold">
                  {$t({
                    id: 'form.projectCreation.masterTemplate.title',
                    defaultMessage: 'Master bank',
                  })}
                </Typography>
                <Typography variant="callout" color="tertiary">
                  {$t({
                    id: 'form.projectCreation.masterTemplate.definition',
                    defaultMessage: 'A full project for bank and financial industries',
                  })}
                </Typography>
              </FlexContainer>
            </FlexContainer>
          </Radio>
        )}
      </div>
    </ModalForm>
  );
};
