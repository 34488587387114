import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as UsersIcon } from '../../../assets/icons/menu/ic-multiple-neutral.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/flat/ic-project.svg';
import { getWorkspaceIcon, getWorkspaceTypeDisplayName } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { AssetTooltip, Icon, Tooltip, Typography } from '../../../ui';

export const GET_WORKSPACE_TOOLTIP = graphql(`
  query getWorkspaceTooltip($workspaceId: VecticeId!) {
    getWorkspaceById(workspaceId: $workspaceId) {
      vecticeId
      name
      type
      projectCount
      userCount(filters: { includeDisabledUsers: false })
    }
  }
`);

interface WorkspaceTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const WorkspaceTooltip = ({ id, placement = 'top', children }: WorkspaceTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`workspace-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_WORKSPACE_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      workspaceId: id!,
    },
  });

  const workspace = data?.getWorkspaceById;

  useEffect(() => {
    setDataLoaded(!!workspace || !!error);
  }, [workspace, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={getWorkspaceIcon(workspace?.type, true)}
      resourceTypeLabel={getWorkspaceTypeDisplayName(workspace?.type)}
      label={workspace?.name}
      deleted={!!error}
    >
      <Typography variant="callout" color="white" paragraph>
        <Icon icon={ProjectIcon} size={20} />{' '}
        {$t({
          id: 'WorkspaceTooltip.projectCount',
          defaultMessage: `{count, plural, one {# project} other {# projects}}`,
          values: { count: workspace?.projectCount || 0 },
        })}
      </Typography>
      <Typography variant="callout" color="white" paragraph>
        <Icon icon={UsersIcon} size={20} />{' '}
        {$t({
          id: 'WorkspaceTooltip.memberCount',
          defaultMessage: `{count, plural, one {# member} other {# members}}`,
          values: { count: workspace?.userCount || 0 },
        })}
      </Typography>
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
