import cn from 'classnames';
import React from 'react';

import styles from './FilterCount.module.scss';

interface FilterCountProps {
  filter?: any;
  className?: string;
}

export const FilterCount = ({ filter, className }: FilterCountProps) =>
  filter !== undefined ? (
    <span className={cn(styles.count, className)}>{Array.isArray(filter) ? filter.length : '1'}</span>
  ) : null;
