import React from 'react';

import { ReactComponent as SearchIcon } from '../../../assets/icons/interface/ic-search.svg';
import { Input } from '../input';
import { CommonProps, LabelProps, SizeProps } from '../interfaces';

type SearchInputProps = CommonProps<HTMLInputElement> & SizeProps & LabelProps & { loading?: boolean };

export const SearchInput = React.forwardRef((props: SearchInputProps, ref: React.Ref<HTMLInputElement>) => (
  <Input {...props} iconBefore={SearchIcon} ref={ref} />
));
