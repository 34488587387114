import cn from 'classnames';
import React, { useMemo, useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import { getFindingStatusColor, getFindingStatusOptions, getFindingStatusDisplayName } from '../../entities/utils';
import { FindingStatus as FindingStatusEnum } from '../../gql/graphql';
import { Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';
import { FindingStatusBadge } from '../badges';

import styles from './RevampedAssetStatus.module.scss';

interface FindingStatusProps extends ButtonProps {
  options?: FindingStatusEnum[];
  status?: FindingStatusEnum;
  handleStatusUpdate: (status: FindingStatusEnum) => void;
}

export const FindingStatus = ({ className, options, status, handleStatusUpdate, ...props }: FindingStatusProps) => {
  const { current: statusOptions } = useRef(
    getFindingStatusOptions().filter((option) => {
      if (!options || options.length === 0) return true;
      return options.includes(option.value);
    }),
  );

  const menu = useMemo(
    () => (
      <Menu aria-label="Issue Status Menu">
        {statusOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={status === option.value}
            onClick={() => handleStatusUpdate(option.value)}
          >
            <FindingStatusBadge status={option.value} />
          </MenuItem>
        ))}
      </Menu>
    ),
    [statusOptions, status, handleStatusUpdate],
  );

  if (!status) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        aria-label="Issue status select"
        className={cn(styles[getFindingStatusColor(status)], className)}
        {...props}
      >
        {getFindingStatusDisplayName(status)}
      </DropdownTrigger>
    </Dropdown>
  );
};
