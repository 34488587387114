import { graphql } from '../../gql';

export const GET_PHASE_BY_ID = graphql(`
  query getPhaseById($id: VecticeId!) {
    getPhaseById(id: $id) {
      vecticeId
      name
      ownerId
      enforceRequirementsAsSections
      activeReview {
        status
      }
      parent {
        vecticeId
        workspace {
          vecticeId
          name
          type
        }
      }
      ...PhaseSidebar
    }
  }
`);
