import { graphql } from '../../gql';

export const SUBSCRIBE_TO_REVIEW_COMMENT_LIST = graphql(`
  subscription subscribeToReviewCommentList($filters: ReviewCommentFiltersInput!) {
    subscribeToReviewCommentList(filters: $filters) {
      items {
        id
        createdBy {
          ...userFields
        }
        createdDate
        content
      }
    }
  }
`);
