import { TextFormatType } from 'lexical';

import type { HeadingTagType } from '@lexical/rich-text';

import { FontType } from '../../config';

export const IS_BOLD = 1;
export const IS_ITALIC = 1 << 1;
export const IS_STRIKETHROUGH = 1 << 2;
export const IS_UNDERLINE = 1 << 3;

export function isMicrosoftWordNestedList(domNode: Node) {
  if (
    domNode instanceof Element &&
    (domNode.nodeName.toLowerCase() === 'ol' || domNode.nodeName.toLowerCase() === 'ul')
  ) {
    // MS Word can wrap single list items in a <ol/ul> tag: <ol/ul><li data-aria-level="2" /></ol/ul>
    let dataListId: string | null;
    let dataItemLevel: string | null;
    const children = Array.from(domNode.children || []);
    return (
      children.length > 0 &&
      children.every((child, index) => {
        const childListId = child.getAttribute('data-listid');
        const childItemLevel = child.getAttribute('data-aria-level');
        if (index === 0) {
          dataListId = childListId;
          dataItemLevel = childItemLevel;
          return dataItemLevel && Number(dataItemLevel) > 1;
        }
        return childListId === dataListId && childItemLevel === dataItemLevel;
      })
    );
  }
  return false;
}

export const guesstimateFontType = (fontFamily: string): FontType => {
  if (fontFamily.toLowerCase().includes('arial')) {
    return 'Arial';
  }
  if (fontFamily.toLowerCase().includes('courier')) {
    return 'Courier New';
  }
  if (fontFamily.toLowerCase().includes('georgia')) {
    return 'Georgia';
  }
  if (fontFamily.toLowerCase().includes('times')) {
    return 'Times New Roman';
  }
  if (fontFamily.toLowerCase().includes('trebuchet')) {
    return 'Trebuchet MS';
  }
  if (fontFamily.toLowerCase().includes('verdana')) {
    return 'Verdana';
  }
  return 'Inter';
};

export function guesstimateMicrosoftWordHeadingTag(parastyle: string): HeadingTagType | null {
  switch (parastyle.toLowerCase()) {
    case 'heading 1':
      return 'h1';
    case 'heading 2':
      return 'h2';
    case 'heading 3':
      return 'h3';
    case 'heading 4':
      return 'h4';
    case 'heading 5':
      return 'h5';
    case 'heading 6':
      return 'h6';
    default:
      return null;
  }
}

export function isMicrosoftWordHeading(domNode: Node): false | HeadingTagType {
  if (domNode instanceof Element && domNode.nodeName.toLowerCase() === 'p') {
    // MS Word can wrap heading in a <p> tag: <p role="heading" aria-level="1" />
    const role = domNode.getAttribute('role');
    const level = domNode.getAttribute('aria-level');
    const headingTag = guesstimateMicrosoftWordHeadingTag(`${role} ${level}`);
    return headingTag ?? false;
  }
  return false;
}

export function isMicrosoftWordListItem(domNode: Node): boolean {
  if (domNode instanceof Element && domNode.nodeName.toLowerCase() === 'p') {
    // MS Word can wrap list items in a <p> tag: <p class="MsoListParagraphCxSpMiddle" style="mso-list:l0 level2 lfo1" />
    return (
      Array.from(domNode.classList).find((className) => className.startsWith('MsoList')) !== undefined ||
      (domNode.getAttribute('style') || '').includes('mso-list')
    );
  }
  return false;
}

const nodeNameToTextFormat: Record<string, TextFormatType> = {
  code: 'code',
  em: 'italic',
  i: 'italic',
  s: 'strikethrough',
  strong: 'bold',
  sub: 'subscript',
  sup: 'superscript',
  u: 'underline',
};

export function extractNodeStyle(node: HTMLElement) {
  const { style } = node;

  const backgroundColor = style.backgroundColor;
  const color = style.color;
  const fontFamily = style.fontFamily;
  const fontSize = style.fontSize;
  const fontStyle = style.fontStyle;
  const fontWeight = style.fontWeight;
  const textDecoration = style.textDecoration;

  const inlineStyleParts = [];

  if (backgroundColor) inlineStyleParts.push(`background-color: ${backgroundColor}`);
  if (color) inlineStyleParts.push(`color: ${color}`);
  if (fontFamily) inlineStyleParts.push(`font-family: ${guesstimateFontType(fontFamily)}`);
  if (fontSize) inlineStyleParts.push(`font-size: ${fontSize}`);
  // font-style, font-weight and text-decoration are not supported in inline style but by the Lexical nodes

  const inlineStyle = inlineStyleParts.join('; ');

  const format = nodeNameToTextFormat[node.nodeName.toLowerCase()];

  const isBold =
    format === 'bold' || fontWeight === 'bold' || fontWeight === 'bolder' || parseInt(fontWeight, 10) >= 700;
  const isItalic = format === 'italic' || fontStyle === 'italic';
  const isUnderline = format === 'underline' || textDecoration === 'underline';

  return { fontSize, isBold, isItalic, isUnderline, inlineStyle };
}
