import React from 'react';

import { getDatasetSourceUsageName } from '../../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { CategoryType, DatasetSourceType } from '../../../gql/graphql';
import { FlexContainer, SideNavTitle, Typography } from '../../../ui';
import { isNil } from '../../../utils';
import { FormatFileSize } from '../../formatters';

import { ResourcesMenuItem } from './ResourcesMenuItem';

import styles from './ResourcesMenu.module.scss';

export const ResourceMenuFragment = graphql(`
  fragment datasetSourceMenu on DatasetSource {
    id
    itemsCount
    columnsCount
    size
    type
    usage
  }
`);

interface ResourcesMenuProps {
  resources: {
    id: number;
    name: string;
    type?: string;
    mimeType?: string;
    rowsNumber?: number | null;
    columnsNumber?: number | null;
    columnsTypesCounts: { categoryType: CategoryType; count: number }[];
  }[];
  source: FragmentType<typeof ResourceMenuFragment>;
  total?: number;
  selectedResourceId?: number;
  onSelectResource?: (id: number, type: DatasetSourceType) => void;
}

export const ResourcesMenu = ({
  resources,
  source: sourceFragment,
  total,
  selectedResourceId,
  onSelectResource,
}: ResourcesMenuProps) => {
  const source = useFragment(ResourceMenuFragment, sourceFragment);

  return (
    <>
      <SideNavTitle className={styles.title}>
        <Typography ellipsis>
          {source.usage
            ? $t({
                id: 'DatasetVersionResources.usage',
                defaultMessage: '{usage} Resources',
                values: {
                  usage: getDatasetSourceUsageName(source.usage),
                },
              })
            : $t({ id: 'DatasetVersionResources.resources', defaultMessage: 'Resources' })}
        </Typography>
        {source.size && (
          <Typography color="tertiary" variant="footnote" weight="regular" noBreak>
            <FormatFileSize size={source.size} />
          </Typography>
        )}
      </SideNavTitle>
      <FlexContainer gap="2px 4px" align="center" wrap="wrap" className={styles.resource_details}>
        {!isNil(source.itemsCount) && (
          <Typography color="tertiary" variant="footnote" ellipsis>
            {$t({
              id: 'datasetSource.details.filesCountLabel',
              defaultMessage: `{itemCount, plural, one {# File} other {# Files}}`,
              values: { itemCount: source.itemsCount },
            })}
          </Typography>
        )}
        {!isNil(source.columnsCount) && (
          <Typography color="tertiary" variant="footnote" ellipsis>
            {$t({
              id: 'datasetSource.details.columnsCountLabel',
              defaultMessage: `{itemCount, plural, one {# Column Total} other {# Columns Total}}`,
              values: { itemCount: source.columnsCount },
            })}
          </Typography>
        )}
      </FlexContainer>
      {resources?.map((resource) => (
        <ResourcesMenuItem
          key={resource.id}
          resource={resource}
          active={resource.id === selectedResourceId}
          onSelectResource={(id) => onSelectResource?.(id, source.type)}
        />
      ))}
      {total !== undefined && total > 15 && (
        <Typography variant="footnote" color="tertiary" className={styles.resource_details}>
          {$t({
            id: 'datasetSource.details.moreResources',
            defaultMessage: '{total} more resources',
            values: { total: total - 15 },
          })}
        </Typography>
      )}
    </>
  );
};
