import Config from '../../config';

import { DEFAULT_HTTP_HEADERS } from './default-http-headers';
import { HttpFetchError } from './http-fetch-error';

type HttpLink = (input: RequestInfo) => RequestInfo;
class RestClient {
  private readonly httpLink: HttpLink;

  constructor(_httpLink: HttpLink) {
    this.httpLink = _httpLink;
  }

  async fetch(input: RequestInfo, options?: RequestInit): Promise<Response> {
    const requestInfo = this.httpLink(input);

    return fetch(requestInfo, {
      ...options,
      headers: {
        ...DEFAULT_HTTP_HEADERS,
        ...options?.headers,
      },
      credentials: 'include',
    }).then(async (response) => {
      if (response.ok) {
        return response;
      }
      const error = await response.text();
      throw new HttpFetchError(error, response);
    });
  }

  get(input: RequestInfo, options?: RequestInit): Promise<Response> {
    return this.fetch(input, {
      ...options,
      method: 'get',
    });
  }

  post(input: RequestInfo, options?: RequestInit): Promise<Response> {
    return this.fetch(input, {
      ...options,
      method: 'post',
    });
  }

  put(input: RequestInfo, options?: RequestInit): Promise<Response> {
    return this.fetch(input, {
      ...options,
      method: 'put',
    });
  }

  patch(input: RequestInfo, options?: RequestInit): Promise<Response> {
    return this.fetch(input, {
      ...options,
      method: 'patch',
    });
  }

  delete(input: RequestInfo, options?: RequestInit): Promise<Response> {
    return this.fetch(input, {
      ...options,
      method: 'delete',
    });
  }
}

const httpLink: HttpLink = (input) => {
  if (typeof input === 'string') {
    if (input.startsWith(Config.API_URL)) {
      return input;
    }
    if (input.match(/^\/.*/)) {
      return `${Config.API_URL}${input}`;
    }
    return `${Config.API_URL}/${input}`;
  }

  return input;
};

export const restClient = new RestClient(httpLink);
