/* eslint-disable no-console */

import * as Sentry from '@sentry/react';

import config from '../../config';
import packageInfo from '../../package-version.json';

export class Logger implements ILogger {
  readonly environment: string;

  readonly sentry: boolean;

  static init() {
    const { enabled, environment, dsn } = config.SENTRY;
    if (enabled) {
      Sentry.init({
        dsn,
        release: `frontend@${packageInfo.version}`,
        environment,
      });
    }

    (window || global).logger = new Logger(environment, enabled);
  }

  constructor(environment: string, sentry: boolean) {
    this.environment = environment;
    this.sentry = sentry;
  }

  log(...data: unknown[]) {
    if (this.environment !== 'production') {
      console.log(...data);
    }
  }

  error(error: Error | string) {
    console.error(error);

    if (this.sentry) {
      if (error instanceof Error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureException(new Error(error));
      }
    }
  }

  setUser(user: Sentry.User) {
    Sentry.setUser(user);

    return () => Sentry.setUser(null);
  }

  setTags(tags: Record<string, number | string | boolean | null | undefined>) {
    Object.keys(tags).forEach((key) => Sentry.setTag(key, tags[key] ?? null));

    return () => Object.keys(tags).forEach((key) => Sentry.setTag(key, null));
  }
}
