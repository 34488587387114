import cn from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';

import { Placement, Tooltip } from '../tooltip';

import styles from './RestrictedAccessTooltip.module.scss';

interface RestrictedAccessTooltipProps {
  message?: ReactNode;
  restricted?: boolean;
  placement?: Placement;
  fullWidth?: boolean;
  className?: string;
}

export const RestrictedAccessTooltip = ({
  children,
  restricted,
  message,
  className,
  fullWidth,
  placement = 'top',
}: PropsWithChildren<RestrictedAccessTooltipProps>) => {
  if (!restricted) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      placement={placement}
      text={message || $t({ id: 'RestrictedAccessTooltip.message', defaultMessage: 'Restricted Access' })}
      trigger={['hover']}
    >
      <span className={cn(styles.wrapper, className, { [styles.fullWidth]: fullWidth })}>{children}</span>
    </Tooltip>
  );
};
