import cn from 'classnames';
import React, { ChangeEvent, useRef, useContext, useImperativeHandle, useEffect } from 'react';

import { ReactComponent as CheckedCheckboxIcon } from '../../../assets/icons/interface/ic-check.svg';
import { ReactComponent as UncheckedCheckboxIcon } from '../../../assets/icons/interface/ic-checkbox.svg';
import { ReactComponent as IndeterminateCheckboxIcon } from '../../../assets/icons/interface/ic-subtract-square.svg';
import { isNil } from '../../../utils';
import { Icon } from '../../svg';
import { Typography } from '../../typography';
import { CommonProps, LabelProps } from '../interfaces';

import { CheckboxContainer } from './CheckboxContainer';
import { CheckboxGroupContext } from './context';

import styles from './Checkbox.module.scss';

let globalId = 0;

type CheckboxProps = Omit<CommonProps<HTMLInputElement>, 'hint' | 'borderless' | 'onDebouncedChange' | 'error'> & {
  indeterminate?: boolean;
} & LabelProps;

export const Checkbox = React.forwardRef(
  (
    {
      checked,
      className,
      disabled,
      gutterBottom,
      help,
      indeterminate,
      label,
      name,
      value,
      onChange,
      ...props
    }: CheckboxProps,
    ref: React.Ref<HTMLInputElement | null>,
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => checkboxRef.current);

    const context = useContext(CheckboxGroupContext);
    const checkboxProps = {
      name: context?.name || name || 'generic-checkbox',
      checked: context ? context.checkedValues?.includes(value) || checked || false : checked,
      disabled: context?.disabled || disabled,
    };

    // eslint-disable-next-line no-plusplus
    const { current: checkboxId } = useRef(`${name || 'checkbox'}-${globalId++}`);

    useEffect(() => {
      if (checkboxRef.current && !isNil(indeterminate)) {
        checkboxRef.current.indeterminate = indeterminate;
      }
    }, [checkboxRef, indeterminate]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!checkboxProps?.disabled) {
        onChange?.(e);
        context?.onChange?.(value, e);
      }
    };

    return (
      <CheckboxContainer help={help} gutterBottom={gutterBottom}>
        <Typography
          ellipsis
          component="label"
          variant="callout"
          weight="semi-bold"
          color={checkboxProps.disabled ? 'disabled' : 'primary'}
          className={cn(styles.label, { [styles.disabled]: checkboxProps.disabled }, className)}
          htmlFor={checkboxId}
        >
          <span>
            <input
              ref={checkboxRef}
              className={styles.input}
              type="checkbox"
              name={checkboxProps.name}
              id={checkboxId}
              disabled={checkboxProps.disabled}
              checked={checkboxProps.checked}
              onChange={handleChange}
              {...props}
            />
            <Icon icon={CheckedCheckboxIcon} className={cn(styles.checked, styles.icon)} size={20} />
            <Icon icon={UncheckedCheckboxIcon} className={cn(styles.unchecked, styles.icon)} size={20} />
            <Icon icon={IndeterminateCheckboxIcon} className={cn(styles.indeterminate, styles.icon)} size={20} />
          </span>
          {label}
        </Typography>
      </CheckboxContainer>
    );
  },
);
