import { OrderDirection } from '../../../gql/graphql';
import { DefaultRecordType } from '../types';

type HeadCellState = 'false' | 'true' | 'mixed';

export const deepCountItems = <RecordType extends DefaultRecordType>(items?: readonly RecordType[]) => {
  let childrenLength = 0;

  items?.forEach((item) => (childrenLength += deepCountItems<RecordType>(item?.children)));

  return (items?.length || 0) + childrenLength;
};

export const deepSelectAllItems = <RecordType extends DefaultRecordType>(items?: readonly RecordType[]) => {
  const selectedItems = [...(items || [])];

  items?.forEach((item) => selectedItems.push(...deepSelectAllItems<RecordType>(item.children)));

  return selectedItems;
};

export const deepUnselectItem = <RecordType extends DefaultRecordType>(
  record: RecordType,
  currentSelection: readonly RecordType[],
) => {
  let selectedItems = [...currentSelection];

  const id = selectedItems.findIndex((item) => item === record);
  if (id > -1) {
    selectedItems.splice(id, 1);
  }

  record.children?.forEach((item: RecordType) => (selectedItems = deepUnselectItem(item, selectedItems)));

  return selectedItems;
};

export const deepSelectItem = <RecordType extends DefaultRecordType>(
  record: RecordType,
  currentSelection: readonly RecordType[],
) => {
  let selectedItems = [...currentSelection];

  const id = selectedItems.findIndex((item) => item === record);
  if (id === -1) {
    selectedItems.push(record);
  }

  record.children?.forEach((item: RecordType) => (selectedItems = deepSelectItem(item, selectedItems)));

  return selectedItems;
};

export const getSelectableHeadCellState = (currentSelectionLength?: number, dataLength?: number): HeadCellState => {
  if (currentSelectionLength && dataLength) {
    if (currentSelectionLength === dataLength) {
      return 'true';
    }
    return 'mixed';
  }

  return 'false';
};

export const getSelectableHeadCellLabel = (state: HeadCellState) =>
  state === 'true' ? 'Unselect all rows' : 'Select all rows';

export const getAriaSort = (direction?: OrderDirection) => {
  if (direction === OrderDirection.Asc) return 'ascending';
  if (direction === OrderDirection.Desc) return 'descending';
  return undefined;
};

export const getNewDirection = (direction?: OrderDirection) => {
  if (direction === OrderDirection.Asc) return 'DESC';
  return 'ASC';
};
