import React from 'react';
import { Link } from 'react-router-dom';

import { MentionedInThreadContent } from '../../../entities';
import { getVecticeResourceTypeLabel } from '../../../utils';
import { FormatUserName } from '../../formatters';
import { UserNotificationMentionedInThread } from '../types';
import { getThreadNotificationInformation } from '../utils';

import { Notification } from './Notification';

interface WorkspaceMentionNotificationProps {
  notification: UserNotificationMentionedInThread;
  onClose: () => void;
}

export const MentionedInThreadNotification = ({
  notification: { creator, content, createdDate },
  onClose,
}: WorkspaceMentionNotificationProps) => {
  const parsedContent: MentionedInThreadContent = JSON.parse(content);

  const { type, link, title } = getThreadNotificationInformation(parsedContent);

  return (
    <Notification component={Link} to={link} creator={creator} createdDate={createdDate} onClose={onClose}>
      {$t({
        id: 'notification.mention.comment',
        defaultMessage: '<bold>{creator}</bold> mentioned <bold>you</bold> in a comment in {type} <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title,
          type: getVecticeResourceTypeLabel(type).toLowerCase(),
        },
      })}
    </Notification>
  );
};
