import React, { PropsWithChildren } from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as TableIcon } from '../../../assets/icons/editor/ic-table.svg';
import { AssetRow } from '../../../ui';

interface EntityMetadataRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  name?: string;
}

export const EntityMetadataRow = (props: PropsWithChildren<EntityMetadataRowProps>) => {
  return (
    <AssetRow
      icon={TableIcon}
      label={props.name ?? $t({ id: 'asset.entityMetadata.label', defaultMessage: 'Unnamed' })}
      {...props}
    />
  );
};
