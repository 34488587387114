import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PrivateWorkspace } from '../assets/icons/specials/flat/ic-workspace-private.svg';
import { ReactComponent as PublicWorkspace } from '../assets/icons/specials/flat/ic-workspace.svg';
import { useOrgConfig } from '../context';
import { getWorkspaceTypeDisplayName } from '../entities/utils';
import { graphql } from '../gql';
import { OrganizationLevel, UserRole, WorkspaceType } from '../gql/graphql';
import { CHECK_WORKSPACE_NAME_AVAILABILITY } from '../graphql/queries';
import { useWithRoles } from '../guards';
import { useValidateName, useVecticeForm } from '../hooks';
import { buildLink, VecticeRoutes } from '../routes';
import {
  FlexContainer,
  Icon,
  Input,
  message,
  ModalForm,
  Radio,
  RestrictedAccessTooltip,
  TextArea,
  Typography,
  WithAsyncValidation,
} from '../ui';
import {
  VecticeResourceType,
  WORKSPACE_NAME_FORMAT,
  defaultDescriptionFieldConfiguration,
  defaultNameFieldConfiguration,
} from '../utils';

import styles from './Modals.module.scss';

export interface WorkspaceCreationFormValues {
  name: string;
  description?: string;
  type?: WorkspaceType;
}

export const CREATE_WORKSPACE = graphql(`
  mutation createWorkspace($data: WorkspaceCreateInput!) {
    createWorkspace(data: $data) {
      vecticeId
      name
      description
    }
  }
`);

export interface CreateWorkspaceModalProps {
  onClose: () => void;
}

export const CreateWorkspaceModal = ({ onClose }: CreateWorkspaceModalProps) => {
  const navigate = useNavigate();
  const { allowed: isOrgAdmin } = useWithRoles({ organizationRole: UserRole.OrgAdmin });
  const {
    organization: { displayName: orgName, level },
  } = useOrgConfig();

  const [checkNameAvailability] = useLazyQuery(CHECK_WORKSPACE_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const handleNameAvailability = useCallback(
    async (value: string) => {
      const { data: { checkWorkspaceNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          name: value,
        },
      });

      return !!checkWorkspaceNameAvailability;
    },
    [checkNameAvailability],
  );

  const { validateName } = useValidateName(handleNameAvailability, VecticeResourceType.WORKSPACE);

  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<WorkspaceCreationFormValues>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      type: WorkspaceType.Private,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [createWorkspace] = useMutation(CREATE_WORKSPACE, {
    onCompleted: ({ createWorkspace: { vecticeId, name } }) => {
      message.success(
        $t({
          id: 'pages.workspace.creation.messageSuccess',
          defaultMessage: '{name} workspace created',
          values: { name },
        }),
      );
      navigate(buildLink(VecticeRoutes.WORKSPACE, { workspaceId: vecticeId }));
      onClose();
    },
    onError: (error) => message.error(error.message),
  });

  const handleSubmit = useCallback(
    async ({ name, description, type }) => {
      await createWorkspace({
        variables: {
          data: { name, description, type },
        },
      });
    },
    [createWorkspace],
  );

  return (
    <ModalForm
      title="Create workspace"
      submitLabel={$t({ id: 'modal.create', defaultMessage: 'Create' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
      className={styles.modal}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', {
            ...defaultNameFieldConfiguration(),
            pattern: {
              value: WORKSPACE_NAME_FORMAT,
              message: $t({
                id: 'form.WorkspaceForm.nameError.dotPrefix',
                defaultMessage: "Invalid workspace name. Can not use '.' to prefix the workspace name.",
              }),
            },
          } as RegisterOptions<WorkspaceCreationFormValues, 'name'>)}
          autoFocus
          gutterBottom
          label={$t({
            id: 'form.WorkspaceForm.nameLabel',
            defaultMessage: 'Workspace Name',
          })}
        />
      </WithAsyncValidation>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        gutterBottom
        label={$t({ id: 'workspaceCreationForm.description.label', defaultMessage: 'Description' })}
      />

      <Typography id="radio-group-label" variant="callout" weight="semi-bold" color="primary">
        {$t({ id: 'form.workspaceCreation.selectType', defaultMessage: 'Select an Option' })}
      </Typography>
      <div aria-labelledby="radio-group-label">
        <Radio {...registerWithErrors('type')} value={WorkspaceType.Private} className={styles.radio}>
          <FlexContainer align="center" gap={10}>
            <Icon color="dark-gray" icon={PrivateWorkspace} size={20} />
            <FlexContainer direction="column" gap={0}>
              <Typography variant="callout" weight="semi-bold">
                {getWorkspaceTypeDisplayName(WorkspaceType.Private)}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({
                  id: 'form.workspaceCreation.privateWorkspace.definition',
                  defaultMessage: 'Only specific people have access, can only be viewed or joined by invitation',
                })}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </Radio>
        {level !== OrganizationLevel.Trial && (
          <RestrictedAccessTooltip
            message={$t({
              id: 'form.workspaceCreation.publicWorkspace.disabled',
              defaultMessage: 'Only Org Admins can create Organization Shared Workspace',
            })}
            restricted={!isOrgAdmin}
            placement="left"
            className={styles.radio}
          >
            <Radio
              {...registerWithErrors('type')}
              value={WorkspaceType.Public}
              disabled={!isOrgAdmin}
              className={styles.radio}
            >
              <FlexContainer align="center" gap={10}>
                <Icon color="dark-gray" icon={PublicWorkspace} size={20} />
                <FlexContainer direction="column" gap={0}>
                  <Typography variant="callout" weight="semi-bold">
                    {getWorkspaceTypeDisplayName(WorkspaceType.Public)}
                  </Typography>
                  <Typography variant="callout" color="tertiary">
                    {$t({
                      id: 'form.workspaceCreation.publicWorkspace.definition',
                      defaultMessage: 'Anyone in <bold>{organization}</bold> has access',
                      values: {
                        organization: orgName,
                      },
                    })}
                  </Typography>
                </FlexContainer>
              </FlexContainer>
            </Radio>
          </RestrictedAccessTooltip>
        )}
      </div>
    </ModalForm>
  );
};
