import { graphql } from '../../gql';

export const CHECK_WORKSPACE_NAME_AVAILABILITY = graphql(`
  query checkWorkspaceNameAvailability($name: String!, $exceptId: VecticeId) {
    checkWorkspaceNameAvailability(name: $name, exceptId: $exceptId)
  }
`);

export const CHECK_PROJECT_NAME_AVAILABILITY = graphql(`
  query checkProjectNameAvailability($workspaceId: VecticeId!, $projectId: VecticeId, $name: String!) {
    checkProjectNameAvailability(workspaceId: $workspaceId, projectId: $projectId, name: $name)
  }
`);

export const CHECK_CDTREPORT_NAME_AVAILABILITY = graphql(`
  query checkCDTReportNameAvailability($parentId: VecticeId!, $resourceId: Float, $name: String!) {
    checkCDTReportNameAvailability(parentId: $parentId, resourceId: $resourceId, name: $name)
  }
`);

export const CHECK_CDTTEMPLATE_NAME_AVAILABILITY = graphql(`
  query checkCDTTemplateNameAvailability($resourceId: Float, $name: String!, $isRichText: Boolean) {
    checkCDTTemplateNameAvailability(resourceId: $resourceId, name: $name, isRichText: $isRichText)
  }
`);
