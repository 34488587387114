import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Workspace } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { ProjectRow } from './ProjectRow';

const ProjectListItemFragment = graphql(`
  fragment projectListItem on Project {
    vecticeId
    ...projectRow
  }
`);

let globalId = 0;

interface ProjectListItemProps {
  project: FragmentType<typeof ProjectListItemFragment>;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const ProjectListItem = ({ workspace, ...props }: ProjectListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`project-list-item-${globalId++}`);
  const project = useFragment(ProjectListItemFragment, props.project);

  return (
    <AssetListItem to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })} aria-labelledby={ariaId}>
      <ProjectRow id={ariaId} project={project} workspace={workspace} />
    </AssetListItem>
  );
};
