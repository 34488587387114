import { CdtTemplateAssetType } from '../../gql/graphql';
import { getVecticeResourceTypeIcon, getVecticeResourceTypeLabel, VecticeResourceType } from '../../utils';

export const getCdtAssetTypeOptions = () => [
  {
    value: CdtTemplateAssetType.DatasetVersion,
    label: getVecticeResourceTypeLabel(VecticeResourceType.DATASET_VERSION),
    icon: getVecticeResourceTypeIcon(VecticeResourceType.DATASET_VERSION),
  },
  {
    value: CdtTemplateAssetType.ModelVersion,
    label: getVecticeResourceTypeLabel(VecticeResourceType.MODEL_VERSION),
    icon: getVecticeResourceTypeIcon(VecticeResourceType.MODEL_VERSION),
  },
  {
    value: CdtTemplateAssetType.Phase,
    label: getVecticeResourceTypeLabel(VecticeResourceType.PHASE),
    icon: getVecticeResourceTypeIcon(VecticeResourceType.PHASE),
  },
  {
    value: CdtTemplateAssetType.Iteration,
    label: getVecticeResourceTypeLabel(VecticeResourceType.ITERATION),
    icon: getVecticeResourceTypeIcon(VecticeResourceType.ITERATION),
  },
];
