import { useMutation } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';

import { IterationStep } from '../../gql/graphql';
import { CREATE_ITERATION_SECTION, UPDATE_ITERATION_SECTION } from '../../graphql/queries';
import { useValidateName, useVecticeForm } from '../../hooks';
import {
  AutoComplete,
  FormItemContainer,
  ModalForm,
  TextArea,
  Typography,
  WithAsyncValidation,
  message,
} from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';

import styles from './IterationSectionModalForm.module.scss';

interface FormData {
  name: string;
  description?: string | null;
}

interface Props {
  iterationId?: string;
  requirements: Pick<IterationStep, 'id' | 'name' | 'description'>[] | undefined;
  section?: Pick<IterationStep, 'id' | 'name' | 'description'>;
  onCheckAvailability: (value: string) => Promise<boolean>;
  onCreate: (id: number) => void;
  onClose: () => void;
}

export const IterationSectionModalForm = ({
  iterationId,
  requirements = [],
  section,
  onCheckAvailability,
  onCreate,
  onClose,
}: Props) => {
  const { validateName } = useValidateName(onCheckAvailability, VecticeResourceType.ITERATION_SECTION);
  const { control, formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: section?.name ?? undefined,
      description: section?.description ?? undefined,
    },
  });
  const { errors, hasErrors, isDirty, isSubmitting } = formState;
  const [defaultDescription, setDefaultDescription] = useState<string | null>(null);

  const [createSection] = useMutation(CREATE_ITERATION_SECTION, {
    refetchQueries: ['getIterationStepList', 'getSections'],
    onCompleted: ({ createIterationStep: { id } }) => {
      onCreate(id);
      onClose();
    },
    onError: (error) => {
      message.error(error.message);
      onClose();
    },
  });

  const [editSection] = useMutation(UPDATE_ITERATION_SECTION, {
    onCompleted: () => onClose(),
    onError: (error) => {
      message.error(error.message);
      onClose();
    },
  });

  const onSubmit: SubmitHandler<FormData> = async ({ name, description }) => {
    const data = {
      name,
      description: description || defaultDescription || '',
    };

    if (section) {
      await editSection({
        variables: {
          id: section.id,
          updateModel: data,
        },
      });
    } else if (iterationId) {
      await createSection({
        variables: {
          parentId: iterationId,
          createModel: data,
        },
      });
    }
  };

  const requirementOptions = useMemo(() => requirements.map(({ name }) => name), [requirements]);

  return (
    <ModalForm
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
      title={
        section
          ? $t({
              id: 'modal.iterationSection.editSection',
              defaultMessage: 'Edit Section',
            })
          : $t({
              id: 'modal.iterationSection.createSection',
              defaultMessage: 'Create Section',
            })
      }
      submitLabel={
        section
          ? $t({ id: 'modal.save', defaultMessage: 'Save' })
          : $t({ id: 'modal.create', defaultMessage: 'Create' })
      }
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(onSubmit)}
      onClose={onClose}
    >
      <FormItemContainer
        label={$t({ id: 'modal.iterationSection.name', defaultMessage: 'Section Name' })}
        gutterBottom
        error={errors.name?.message}
      >
        <Controller
          control={control}
          name="name"
          rules={defaultNameFieldConfiguration()}
          render={({ field: { name, value, onChange } }) => (
            <WithAsyncValidation
              validate={validateName}
              onSuccess={() => trigger(name)}
              onError={(error) => setError(name, error)}
            >
              <AutoComplete
                aria-label="Section Name"
                autoComplete="off"
                autoFocus
                beforeOptions={
                  <Typography
                    className={styles.optionsHint}
                    color="primary"
                    component="div"
                    variant="callout"
                    weight="bold"
                  >
                    {$t({ id: 'modal.iterationSection.requirement.hint', defaultMessage: 'Requirements' })}
                  </Typography>
                }
                hideNoData
                options={requirementOptions}
                placeholder={$t({ id: 'modal.iterationSection.name.placeholder', defaultMessage: 'Section' })}
                value={value}
                onChange={onChange}
                onSelectOption={(option) => {
                  onChange(option ?? '');

                  const selectedRequirements = requirements.find(({ name }) => name === option);
                  if (selectedRequirements) {
                    setDefaultDescription(selectedRequirements.description ?? null);
                  } else {
                    setDefaultDescription(null);
                  }
                }}
              />
            </WithAsyncValidation>
          )}
        />
      </FormItemContainer>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        gutterBottom
        label={$t({ id: 'modal.iterationSection.description.label', defaultMessage: 'Description' })}
        hint={$t({ id: 'modal.iterationSection.description.optional', defaultMessage: 'Optional' })}
        placeholder={
          defaultDescription ??
          $t({
            id: 'modal.iterationSection.description.placeholder',
            defaultMessage: 'Describe the section',
          })
        }
      />
    </ModalForm>
  );
};
