import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { Project } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';
import { DeletionPrompt } from '../modals';

import { DELETE_PROJECT } from './deleteProject.mutation';
import { DangerCard } from './internals';

interface DeleteProjectProps {
  project: Pick<Project, 'vecticeId' | 'name'>;
  workspaceId?: string | null;
}

export const DeleteProject = ({ project, workspaceId }: DeleteProjectProps) => {
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);

  const [deleteProject, { loading }] = useMutation(DELETE_PROJECT, {
    variables: {
      projectId: project.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        setShowPrompt(false);
        flushFromGraphQLCache(cache, { vecticeId: data.deleteProject, __typename: 'Project' });
        navigate(buildLink(VecticeRoutes.WORKSPACE_PROJECTS, { workspaceId }));
      }
    },
    onCompleted: () => {
      if (project) {
        message.success(
          $t({
            id: 'component.action.deleteProject.successMessage',
            defaultMessage: 'Project "{name}" has been deleted',
            values: {
              name: project.name,
            },
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  const handleDeleteProject = () => setShowPrompt(true);

  return (
    <>
      <DangerCard
        buttonIcon={DeleteIcon}
        buttonLabel={$t({ id: 'component.action.deleteProject.button', defaultMessage: 'Delete Project' })}
        hint={$t({
          id: 'component.action.deleteProject.hint',
          defaultMessage: 'Deleting a project also deletes all phases, models, and datasets, associated with it.',
        })}
        onClick={handleDeleteProject}
      />
      {showPrompt && (
        <DeletionPrompt
          processing={loading}
          resourceName={project.name}
          title={$t({ id: 'component.action.deleteProject.modal.title', defaultMessage: 'Delete Project' })}
          onClose={() => setShowPrompt(false)}
          onConfirm={deleteProject}
        />
      )}
    </>
  );
};
