import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { DatasetRow } from './DatasetRow';

const DatasetListItemFragment = graphql(`
  fragment datasetListItem on DataSet {
    vecticeId
    ...datasetRow
  }
`);

let globalId = 0;

interface DatasetListItemProps {
  dataset: FragmentType<typeof DatasetListItemFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const DatasetListItem = ({ project, workspace, ...props }: DatasetListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`dataset-list-item-${globalId++}`);
  const dataset = useFragment(DatasetListItemFragment, props.dataset);

  return (
    <AssetListItem to={buildLink(VecticeRoutes.DATASET, { datasetId: dataset.vecticeId })} aria-labelledby={ariaId}>
      <DatasetRow id={ariaId} dataset={dataset} project={project} workspace={workspace} />
    </AssetListItem>
  );
};
