import cn from 'classnames';
import { DrawerProps as RcDrawerProps } from 'rc-drawer';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

import { ReactComponent as LeftIcon } from '../../assets/icons/interface/ic-chevron-left.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/interface/ic-chevron-right.svg';
import { Icon } from '../svg';
import { Tooltip } from '../tooltip';

import styles from './PersistentDrawer.module.scss';

interface PersistentDrawerProps extends Omit<RcDrawerProps, 'width'> {
  drawerClassName?: string;
  drawerContent: ReactNode;
}

export const PersistentDrawer = ({
  className,
  children,
  drawerClassName,
  drawerContent,
  placement = 'right',
  ...props
}: PropsWithChildren<PersistentDrawerProps>) => {
  const [open, setOpen] = useState(true);

  return (
    <div className={cn(styles.persistentDrawer, styles[placement], { [styles.open]: open }, className)} {...props}>
      <aside className={cn(styles.drawer)}>
        <button
          aria-label={open ? 'Collapse Sidebar' : 'Expand Sidebar'}
          onClick={() => setOpen(!open)}
          className={styles.control}
        >
          <Tooltip text={open ? 'Collapse' : 'Expand'} placement={placement === 'right' ? 'left' : 'right'}>
            <div className={styles.toggle}>
              <Icon icon={placement === 'right' ? LeftIcon : RightIcon} size={17} />
            </div>
          </Tooltip>
        </button>
        <div className={cn(styles.drawerContent, drawerClassName)}>{drawerContent}</div>
      </aside>
      {children}
    </div>
  );
};
