import { useQuery } from '@apollo/client';

import { useAuthentication } from '../context';
import { graphql } from '../gql';
import { MatchEnum } from '../gql/graphql';

const GET_PROJECT_LIST = graphql(`
  query getUserProjects($workspaceId: VecticeId, $filters: ProjectListFiltersInput) {
    getProjectList(workspaceId: $workspaceId, filters: $filters, order: { field: "createdDate", direction: ASC }) {
      items {
        vecticeId
        templateUsed
      }
    }
  }
`);

export const useUserProjects = () => {
  const { defaultWorkspace } = useAuthentication();

  const { data } = useQuery(GET_PROJECT_LIST, {
    skip: !defaultWorkspace,
    variables: {
      workspaceId: defaultWorkspace?.vecticeId!,
      filters: {
        advancedFilters: [
          {
            filters: [
              {
                field: 'templateUsed',
                values: ['quickstart', 'trial_sample', 'tutorial-private', 'tutorial'],
                // "trial_sample" is the template used for tutorial creation in a trial organization
                // "tutorial-private" is the template used for tutorial creation in a private deployment context
                // "tutorial" is the template used for tutorial creation in a classic organization
                match: MatchEnum.Equal,
              },
            ],
          },
        ],
      },
    },
  });
  const quickstartProject = data?.getProjectList?.items?.find((project) => project.templateUsed === 'quickstart');
  const sampleProject = data?.getProjectList?.items?.find(
    (project) =>
      project.templateUsed === 'trial_sample' ||
      project.templateUsed === 'tutorial-private' ||
      project.templateUsed === 'tutorial',
  );

  return { quickstartProject, sampleProject };
};
