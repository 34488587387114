import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { Phase } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const DELETE_PHASE = graphql(`
  mutation removePhase($phaseId: VecticeId!) {
    removePhase(id: $phaseId)
  }
`);

interface DeletePhaseModalProps {
  phase: Pick<Phase, 'vecticeId' | 'name'>;
  projectId?: string | null;
  onClose: () => void;
}

export const DeletePhaseModal = ({ phase, projectId, onClose }: DeletePhaseModalProps) => {
  const navigate = useNavigate();

  const [deletePhase, { loading: deleting }] = useMutation(DELETE_PHASE, {
    variables: {
      phaseId: phase?.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        flushFromGraphQLCache(cache, { vecticeId: data.removePhase, __typename: 'Phase' });
        navigate(buildLink(VecticeRoutes.PROJECT_PHASES, { projectId }));
        onClose();
      }
    },
    onCompleted: () => {
      if (phase) {
        message.success(
          $t({
            id: 'component.action.deletePhase.successMessage',
            defaultMessage: '{name} phase deleted',
            values: { name: phase?.name },
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <DeletionPrompt
      processing={deleting}
      resourceName={phase.name}
      title={$t({ id: 'phase.modal.delete.title', defaultMessage: 'Delete Phase' })}
      onClose={onClose}
      onConfirm={deletePhase}
    />
  );
};
