import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { DocumentationPageStatus, PhaseUpdateInput } from '../gql/graphql';
import { GET_PHASE_BY_ID, UPDATE_PHASE } from '../graphql/queries';
import { buildLink, VecticeRoutes } from '../routes';

export const usePhase = (phaseId?: string) => {
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_PHASE_BY_ID, {
    skip: !phaseId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: phaseId!,
    },
    onError: () => navigate(buildLink(VecticeRoutes.NOT_FOUND), { replace: true }),
  });

  const phase = data?.getPhaseById;

  const [updatePhase] = useMutation(UPDATE_PHASE);
  const savePhase = (model: PhaseUpdateInput) => {
    if (phase) {
      updatePhase({
        variables: {
          id: phase.vecticeId,
          updateModel: model,
        },
      });
    }
  };

  const savePhaseOwner = (ownerId: number | null) => savePhase({ ownerId });
  const savePhaseStatus = (status: DocumentationPageStatus) => savePhase({ status });

  return {
    loading,
    phase,
    savePhaseOwner,
    savePhaseStatus,
  };
};
