import { useQuery } from '@apollo/client';

import { useAuthentication, usePageContext } from '../context';
import { GET_USER_WORKSPACE_ACCESSIBILITY } from '../graphql/queries';

import { WithRolesProps } from './interfaces';
import { getArray } from './utils';

export const useWithRoles = ({ organizationRole, workspaceRole, skip = false }: WithRolesProps) => {
  const { user } = useAuthentication();
  const userRole = user?.role;
  const pageContext = usePageContext(true);

  const {
    data,
    loading: a11yLoading,
    error,
  } = useQuery(GET_USER_WORKSPACE_ACCESSIBILITY, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: { userId: Number(user?.id), workspaceId: pageContext?.workspace?.vecticeId! },
    skip: !workspaceRole || !pageContext?.workspace || skip,
  });

  const loading = pageContext?.loading || a11yLoading;

  if (skip) return { loading: false, allowed: true };

  const organizationRoleArray = organizationRole ? getArray(organizationRole) : undefined;
  const workspaceRoleArray = workspaceRole ? getArray(workspaceRole) : undefined;

  const userAccessibility = data?.accessLevel;

  const hasValidOrgRole = !!userRole && !!organizationRoleArray?.includes(userRole);
  const hasValidWorkspaceRole = !!userAccessibility && !!workspaceRoleArray?.includes(userAccessibility);

  const allowed = !error && !loading && (hasValidOrgRole || hasValidWorkspaceRole);

  return {
    allowed,
    error,
    loading,
    userRole,
  };
};
