import { graphql } from '../../gql';

export const CREATE_FINDING = graphql(`
  mutation createFinding($projectId: VecticeId!, $data: FindingCreateInput!) {
    createFinding(projectId: $projectId, data: $data) {
      id
      name
    }
  }
`);
