import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/flat/ic-project.svg';
import { PhaseProgress } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, FlexContainer, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { PhaseProgressBadge, ProjectStatusBadge } from '../../badges';

export const GET_PROJECT_TOOLTIP = graphql(`
  query getProjectTooltip($projectId: VecticeId!) {
    getProjectById(projectId: $projectId) {
      vecticeId
      name
      status
      phasesCounts {
        done
        ongoing
        toDo
        total
      }
    }
  }
`);

interface ProjectTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const ProjectTooltip = ({ id, placement = 'top', children }: ProjectTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`project-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_PROJECT_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      projectId: id!,
    },
  });

  const project = data?.getProjectById;

  useEffect(() => {
    setDataLoaded(!!project || !!error);
  }, [project, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={ProjectIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.PROJECT)}
      label={project?.name}
      deleted={!!error}
    >
      {project && (
        <DataList>
          <Typography id={`status-${ariaId}`} component="dt" variant="callout" color="white">
            Status
          </Typography>
          <ProjectStatusBadge aria-labelledby={`status-${ariaId}`} contrast status={project.status} />
          <Typography id={`phases-${ariaId}`} component="dt" variant="callout" color="white">
            Phases
          </Typography>
          <FlexContainer direction="column" gap={2} aria-labelledby={`phases-${ariaId}`}>
            <PhaseProgressBadge status={PhaseProgress.ToDo} nbOfPages={project.phasesCounts.toDo || 0} contrast />
            <PhaseProgressBadge status={PhaseProgress.Ongoing} nbOfPages={project.phasesCounts.ongoing || 0} contrast />
            <PhaseProgressBadge status={PhaseProgress.Done} nbOfPages={project.phasesCounts.done || 0} contrast />
          </FlexContainer>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
