import cn from 'classnames';
import React from 'react';

import type { ProgressBarColor } from './types';

import { formatCssSizes, getArray } from '../../utils';
import { FlexContainer } from '../containers';

import { ProgressBar } from './ProgressBar';

import styles from './Progress.module.scss';

type ProgressBarValue = {
  color: ProgressBarColor;
  value: number;
  tooltip?: string;
};

interface ProgressProps extends VecticeHTMLProps<HTMLDivElement> {
  thin?: boolean;
  total: number;
  width?: number | string;
  values: ProgressBarValue | ProgressBarValue[];
}

export const Progress = ({ className, thin, total, values, width, ...props }: ProgressProps) => {
  const array = getArray(values);

  return (
    <FlexContainer
      className={cn(styles.progress, { [styles.thin]: thin }, className)}
      gap={0}
      style={{ width: formatCssSizes(width) }}
      {...props}
    >
      {array.map(({ color, tooltip, value }) => (
        <ProgressBar color={color} key={color} thin={thin} tooltip={tooltip} total={total} value={value} />
      ))}
    </FlexContainer>
  );
};
