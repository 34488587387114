import React from 'react';

import { ReactComponent as ReportIcon } from '../../../assets/icons/specials/ic-report.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink, AssetLinkProps } from '../../../ui';

import { ReportTooltip } from './ReportTooltip';

interface ReportLinkProps extends AssetLinkProps {
  resourceId?: number | null;
}

export const ReportLink = ({ resourceId, ...props }: ReportLinkProps) => (
  <ReportTooltip id={resourceId}>
    <AssetLink
      icon={ReportIcon}
      to={resourceId ? buildLink(VecticeRoutes.CDT_REPORT, { reportId: resourceId }) : undefined}
      {...props}
    />
  </ReportTooltip>
);
