import Config from '../config';

export const getUserAvatarUrl = (avatarId?: number | null) =>
  avatarId ? `${Config.API_URL}/avatars/${avatarId}` : undefined;

export const getUserInitial = (name?: string) => {
  if (!name || name.trim() === '') {
    return undefined;
  }
  return name[0].toLocaleUpperCase();
};
