import { graphql } from '../../gql';

export const MOVE_PROJECT = graphql(`
  mutation moveProject($projectId: VecticeId!, $projectName: String!, $targetWorkspaceId: VecticeId!) {
    moveProject(projectMoveInput: { id: $projectId, name: $projectName, targetWorkspaceId: $targetWorkspaceId }) {
      vecticeId
      workspace {
        vecticeId
        name
      }
    }
  }
`);
