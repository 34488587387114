import { graphql } from '../../gql';

export const UserActivityFragment = graphql(`
  fragment userActivity on UserActivity {
    id
    fromValue
    fromUser {
      id
      ...userFields
    }
    toValue
    toUser {
      id
      ...userFields
    }
    date
    isAutomated
    type
    targetId
    targetVecticeId
    targetName
    targetType
    updateType
    workspace {
      vecticeId
      name
      type
    }
    project {
      vecticeId
      name
    }
    actor {
      id
      ...userFields
    }
    sourceId
    sourceVecticeId
    sourceName
    sourceWorkspaceVecticeId
    parentVecticeId
    parentName
  }
`);
