import { useApolloClient, useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';

import { ReactComponent as UpdateIcon } from '../../../assets/icons/interface/ic-download.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/interface/ic-export.svg';
import Config from '../../../config';
import { useOrgConfig } from '../../../context';
import { graphql } from '../../../gql';
import { ColumnSourceType, DatasetSourceType } from '../../../gql/graphql';
import { WithRoles } from '../../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../../guards/utils';
import { restClient } from '../../../services';
import {
  Button,
  FlexContainer,
  List,
  message,
  Pagination,
  SearchInput,
  Tooltip,
  Typography,
  Upload,
} from '../../../ui';
import { downloadDatasetSourceCsv } from '../../../utils';
import { DocumentationLink } from '../../links';
import { NoResults } from '../../no-results';
import { HintTooltip } from '../../tooltip';

import { DatasetSourceColumnFactory } from './DatasetSourceColumnFactory';

import styles from './DatasetSourceColumns.module.scss';

export const GET_DATASET_VERSION_FILE_COLUMNS = graphql(`
  query getDatasetVersionFileColumns($fileSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {
    getFileSourceColumnsList(
      fileSourceId: $fileSourceId
      page: { index: $pageIndex, size: $pageSize }
      filters: { searchFilter: { search: $search, fields: ["name"] } }
    ) {
      items {
        id
        name
        description
        categoryType
        statistics {
          ...BooleanSourceColumnStatistics
          ...DateSourceColumnStatistics
          ...NumericalSourceColumnStatistics
          ...TextSourceColumnStatistics
        }
      }
      total
    }
  }
`);

export const GET_DATASET_VERSION_DB_COLUMNS = graphql(`
  query getDatasetVersionDbColumns($dbSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {
    getDBSourceColumnsList(
      dbSourceId: $dbSourceId
      page: { index: $pageIndex, size: $pageSize }
      filters: { searchFilter: { search: $search, fields: ["name"] } }
    ) {
      items {
        id
        name
        description
        categoryType
        statistics {
          ...BooleanSourceColumnStatistics
          ...DateSourceColumnStatistics
          ...NumericalSourceColumnStatistics
          ...TextSourceColumnStatistics
        }
      }
      total
    }
  }
`);

const PAGE_SIZE = 10;

interface DatasetSourceColumnsProps {
  datasetVersionId: string;
  skippedStatistics?: boolean | null;
  sourceId?: number;
  sourceType?: DatasetSourceType;
}

export const DatasetSourceColumns = ({
  datasetVersionId,
  skippedStatistics,
  sourceId,
  sourceType,
}: DatasetSourceColumnsProps) => {
  const apolloClient = useApolloClient();
  const {
    configuration: { dfStatisticsMaxColumns },
  } = useOrgConfig();
  const columnSourceType = useMemo(
    () => (sourceType === DatasetSourceType.Db ? ColumnSourceType.DbColumnSource : ColumnSourceType.FileSourceColumn),
    [sourceType],
  );

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [uploading, setUploading] = useState(false);

  const { data: dbData, loading: dbLoading } = useQuery(GET_DATASET_VERSION_DB_COLUMNS, {
    fetchPolicy: 'no-cache',
    skip: !sourceId || sourceType !== DatasetSourceType.Db,
    variables: {
      dbSourceId: sourceId!,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE,
      search,
    },
  });

  const { data: fileData, loading: fileLoading } = useQuery(GET_DATASET_VERSION_FILE_COLUMNS, {
    fetchPolicy: 'no-cache',
    skip: !sourceId || sourceType !== DatasetSourceType.Files,
    variables: {
      fileSourceId: sourceId!,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE,
      search,
    },
  });

  const loading = dbLoading || fileLoading;
  const columns = dbData?.getDBSourceColumnsList.items || fileData?.getFileSourceColumnsList.items;
  const totalItems = dbData?.getDBSourceColumnsList.total || fileData?.getFileSourceColumnsList.total || 0;

  const noColumns = !search && !totalItems && !loading;

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setCurrentPage(1);
  }, []);

  const onUpload = async (file: File) => {
    setUploading(true);
    const { name, size } = file;

    const fileSize = Math.round(size / 1024);
    if (fileSize >= 102400) {
      throw new Error(`${name} exceeds the maximum size of 100 MB.`);
    }

    const formData = new FormData();
    formData.append('myFile', file);
    try {
      const response = await restClient.post(`${Config.API_URL}/columns-description/${datasetVersionId}`, {
        body: formData,
      });
      if (sourceType === DatasetSourceType.Db)
        apolloClient.refetchQueries({
          include: ['getDatasetVersionDbColumns'],
        });
      else if (sourceType === DatasetSourceType.Files)
        apolloClient.refetchQueries({
          include: ['getDatasetVersionFileColumns'],
        });

      const { updatedColumnsCount } = await response.json();
      if (updatedColumnsCount)
        message.success(
          $t({
            id: 'ColumnsDescription.uploadSuccess',
            defaultMessage: `Columns descriptions successfully updated.`,
          }),
        );
      else
        message.warning(
          $t({
            id: 'ColumnsDescription.uploadWarning',
            defaultMessage: `No matching columns found, no update made. Make sure the file is well formatted.`,
          }),
        );
    } catch {
      message.error(
        $t({
          id: 'ColumnsDescription.uploadError',
          defaultMessage: `Can not update columns descriptions. Something went wrong.`,
        }),
      );
      throw new Error('Can not upload file. Something went wrong');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={styles.columns}>
      <FlexContainer align="center" gap={4} className={styles.title}>
        <Typography variant="callout" weight="semi-bold" color="primary">
          {$t({ id: 'datasetSourceColumns.title', defaultMessage: 'Column Statistics' })}
        </Typography>

        <HintTooltip
          hint={$t({
            id: 'datasetSourceColumns.limitation.message',
            defaultMessage:
              'Vectice captures all columns and calculates statistics for the first {dfStatisticsMaxColumns} columns.',
            values: {
              dfStatisticsMaxColumns,
            },
          })}
        />
      </FlexContainer>

      <FlexContainer justify="space-between" align="flex-start" wrap="reverse-wrap">
        <SearchInput
          aria-label={$t({ id: 'datasetSourceColumns.search.label', defaultMessage: 'Search columns' })}
          className={styles.search}
          placeholder={$t({ id: 'datasetSourceColumns.search.placeholder', defaultMessage: 'Search...' })}
          help={
            <Typography weight="regular" color="tertiary">
              {$t({
                id: 'datasetSourceColumns.search.results.count',
                defaultMessage: '{count, plural, one {# Result} other {# Results}}',
                values: { count: totalItems ?? 0 },
              })}
            </Typography>
          }
          onDebouncedChange={handleSearch}
          gutterBottom
        />
        <FlexContainer justify="flex-end" align="flex-start" wrap="reverse-wrap">
          <WithRoles workspaceRole={DEFAULT_WRITER_ACCESSIBILITY_LEVELS}>
            <Tooltip
              text={$t({
                id: 'datasetSourceColumns.update.tooltip',
                defaultMessage:
                  'Columns descriptions update : Import a csv file that has the first column as your column name and the second as description.',
              })}
              placement="topRight"
            >
              <div>
                <Upload
                  variant="white"
                  accept="text/csv"
                  icon={UpdateIcon}
                  className={styles.export}
                  onUpload={onUpload}
                  disabled={uploading || noColumns}
                  uploading={uploading}
                >
                  {$t({
                    id: 'datasetSourceColumns.update.label',
                    defaultMessage: 'Import descriptions',
                  })}
                </Upload>
              </div>
            </Tooltip>
          </WithRoles>
          <Button
            variant="white"
            leftIcon={ExportIcon}
            onClick={() => downloadDatasetSourceCsv(sourceId, sourceType)}
            disabled={noColumns}
            className={styles.export}
          >
            {$t({
              id: 'datasetSourceColumns.export.label',
              defaultMessage: 'Export Columns',
            })}
          </Button>
        </FlexContainer>
      </FlexContainer>

      {noColumns ? (
        <>
          <Typography
            variant="callout"
            color="tertiary"
            align="center"
            paragraph
            gutterBottom
            className={styles.message}
          >
            {$t({
              id: 'datasetSourceColumns.noStatisticsAvailable',
              defaultMessage: 'No dataset statistics available',
            })}
          </Typography>
          <Typography
            variant="callout"
            color="tertiary"
            align="center"
            paragraph
            gutterBottom
            className={styles.message}
          >
            {$t({
              id: 'datasetSourceColumns.noStatisticsAvailable.hint',
              defaultMessage: 'To capture dataset statistics, use the Vectice API.',
            })}
            <br />
            <DocumentationLink asTypography>
              {$t({
                id: 'datasetSourceColumns.noStatisticsAvailable.readOurDocs',
                defaultMessage: 'Read our docs to learn more.',
              })}
            </DocumentationLink>
          </Typography>
        </>
      ) : (
        <>
          {skippedStatistics && (
            <Typography
              align="center"
              className={styles.message}
              color="tertiary"
              gutterBottom
              paragraph
              variant="callout"
            >
              {$t({
                id: 'datasetSourceColumns.noStatisticsComputed',
                defaultMessage:
                  'Statistics were not captured to ensure data privacy.\nThe minimum number of rows required for capturing statistics is determined by your admin.',
              })}
            </Typography>
          )}

          <List empty={!columns?.length} emptyText={<NoResults borderless />} loading={loading} collapsed>
            {columns?.map((column) => (
              <DatasetSourceColumnFactory key={column.id} column={column} columnSourceType={columnSourceType} />
            ))}
          </List>

          <Pagination
            total={totalItems}
            current={currentPage}
            pageSize={PAGE_SIZE}
            onChange={setCurrentPage}
            showLessItems
          />
        </>
      )}
    </div>
  );
};
