import cn from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import type { CellSize } from '../config';
import type { ReactNode } from 'react';

import { Typography } from '../../../ui';

import styles from './AssetNameCell.module.scss';

interface AssetNameCellProps {
  assetName: ReactNode;
  size?: CellSize;
  target?: string;
  to?: LinkProps['to'];
}

export const AssetNameCell = ({ assetName, size = 'lg', to, target }: AssetNameCellProps) => (
  <Typography
    className={cn(styles.asset_name, styles[size])}
    component={to ? Link : 'span'}
    ellipsis
    link={!!to}
    target={target}
    to={to}
    variant="callout"
    weight="semi-bold"
  >
    {assetName}
  </Typography>
);
