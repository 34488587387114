import { graphql } from '../../gql';

export const UPDATE_ITERATION_STEP_ARTIFACT = graphql(`
  mutation updateIterationStepArtifact($id: Float!, $data: UpdateIterationStepArtifactInput!) {
    updateIterationStepArtifact(id: $id, data: $data) {
      id
      text
    }
  }
`);
