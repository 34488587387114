import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { GET_ORGANIZATION_USER } from '../../../editor/plugins/mention/getOrganizationUser.query';
import { AdminNotifWorkspaceRoleChangedNotificationContent } from '../../../entities';
import { getAccessibilityLevelDisplayName } from '../../../entities/utils';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { AdminNotifWorkspaceRoleChangedNotification as AdminNotifWorkspaceRoleChangedNotificationType } from '../types';

import { Notification } from './Notification';

interface AdminNotifWorkspaceRoleChangedNotificationProps {
  notification: AdminNotifWorkspaceRoleChangedNotificationType;
  onClose: () => void;
}

export const AdminNotifWorkspaceRoleChangedNotification = ({
  notification: { creator, content, createdDate, workspace },
  onClose,
}: AdminNotifWorkspaceRoleChangedNotificationProps) => {
  const { newRole, oldRole, userId }: AdminNotifWorkspaceRoleChangedNotificationContent = JSON.parse(content);

  const {
    data: userData,
    loading: loadingUser,
    error,
  } = useQuery(GET_ORGANIZATION_USER, {
    skip: !userId,
    variables: {
      userId: userId!,
    },
  });
  const user = userData?.getOrganizationUser;

  if (loadingUser) {
    return null;
  }

  return (
    <Notification
      component={!error ? Link : undefined}
      to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId: workspace?.vecticeId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.workspace.adminNotifWorkspaceRoleChanged',
        defaultMessage:
          "<bold>{user}</bold>'s role was updated from <bold>{oldRole}</bold> to <bold>{newRole}</bold> in <bold>{workspaceName}</bold> workspace",
        values: {
          creator: <FormatUserName user={creator} />,
          user: <FormatUserName user={user} />,
          workspaceName: workspace?.name,
          oldRole: getAccessibilityLevelDisplayName(oldRole),
          newRole: getAccessibilityLevelDisplayName(newRole),
        },
      })}
    </Notification>
  );
};
