import { graphql } from '../../gql/gql';

export const MODEL_VERSION_INFO = graphql(`
  query getModelVersionInfo($modelVersionId: VecticeId!) {
    getModelVersion(modelVersionId: $modelVersionId) {
      id
      vecticeId
      inventoryReference
    }
  }
`);
