import cn from 'classnames';
import React from 'react';

import { ReactComponent as LockIcon } from '../../assets/icons/interface/ic-lock.svg';
import { UserIdentity } from '../../graphql/fragments';
import { Icon, Typography } from '../../ui';
import { Banner } from '../banners';
import { FormatUserName } from '../formatters';

import styles from './LockedUserBanner.module.scss';

export interface LockedUserBannerProps {
  lockedUser?: UserIdentity | null;
  gutterBottom?: boolean;
}

export const LockedUserBanner = ({ lockedUser, gutterBottom }: LockedUserBannerProps) => (
  <Banner className={cn(styles.banner, { [styles.gutterBottom]: gutterBottom })}>
    <Icon icon={LockIcon} color="white" className={styles.icon} />
    <Typography weight="semi-bold">
      {$t({
        id: 'phase.report.button.currentlyInEdition',
        defaultMessage: 'The document is currently locked for editing by {name}',
        values: {
          name: <FormatUserName user={lockedUser} />,
        },
      })}
    </Typography>
  </Banner>
);
