import cn from 'classnames';
import RcSelect from 'rc-select';
import React, { useRef } from 'react';

import type { AdvancedSelectProps } from './interface';
import type { DefaultOptionType } from 'rc-select/lib/Select';

import { ReactComponent as CheckIcon } from '../../../assets/icons/interface/ic-check-circle.svg';
import { EmptySelect } from '../../empty';
import { Icon } from '../../svg';
import { FormItemContainer } from '../FormItemContainer';

import { Tag } from './Tag';

import styles from './RcSelect.module.scss';

let globalId = 0;

export const SelectMultiple = <ValueType, OptionType extends DefaultOptionType = DefaultOptionType>({
  value,
  label,
  name,
  hint,
  help,
  error,
  small,
  disabled,
  gutterBottom,
  className,
  selectMultiple = true,
  filterOption = false,
  virtual = false,
  autoClearSearchValue = true,
  onSelect,
  onDeselect,
  ...props
}: AdvancedSelectProps<ValueType, OptionType>) => {
  // eslint-disable-next-line no-plusplus
  const { current: inputId } = useRef(`${name || 'select-multiple'}-${globalId++}`);

  return (
    <FormItemContainer
      label={label}
      hint={hint}
      help={help}
      error={error}
      gutterBottom={gutterBottom}
      className={className}
      id={inputId}
    >
      <RcSelect<ValueType, OptionType>
        id={inputId}
        value={value as ValueType}
        disabled={disabled}
        tagRender={Tag}
        autoClearSearchValue={autoClearSearchValue}
        virtual={virtual}
        className={cn(styles.select, { [styles.small]: small, [styles.disabled]: disabled })}
        dropdownClassName={styles.dropdown}
        mode={selectMultiple ? 'multiple' : 'combobox'}
        filterOption={filterOption}
        menuItemSelectedIcon={<Icon icon={CheckIcon} size={18} />}
        allowClear={false}
        notFoundContent={<EmptySelect />}
        onSelect={onSelect as any}
        onDeselect={onDeselect as any}
        {...props}
      />
    </FormItemContainer>
  );
};
