import React from 'react';

import { AssetType } from '../../gql/graphql';
import { FlexContainer, List, Pagination, Section, WithLoading } from '../../ui';
import { DatasetListItem, ModelListItem, ProjectListItem } from '../asset-display';
import { NoResults } from '../no-results';

import { GlobalSearchFilters } from './GlobalSearchFilters';
import { GlobalSearchResults } from './GlobalSearchResults';
import { GlobalSearchTypeSwitch } from './GlobalSearchTypeSwitch';
import { useGlobalSearch } from './useGlobalSearch';

import styles from './GlobalSearch.module.scss';

const PAGE_SIZE = 20;

export const GlobalSearch = () => {
  const {
    assetType,
    assetsCount,
    datasets,
    filters,
    loading,
    models,
    pageIndex,
    projects,
    workspaceIdList,
    onFiltersUpdate,
    setAssetType,
    setPageIndex,
  } = useGlobalSearch(PAGE_SIZE);

  const empty = !assetsCount[assetType];
  const emptyText = <NoResults className={styles.noResults} />;

  return (
    <FlexContainer component={Section} direction="column" gap={16} className={styles.container}>
      <GlobalSearchTypeSwitch
        assetsCount={assetsCount}
        assetType={assetType}
        onAssetTypeChange={(type) => {
          setAssetType(type);
          setPageIndex(1);
        }}
      />
      <GlobalSearchFilters
        assetType={assetType}
        filters={filters}
        workspaceIdList={workspaceIdList}
        onFiltersUpdate={onFiltersUpdate}
      />
      <WithLoading loading={loading} loadingSize={48}>
        {assetType && (
          <>
            <GlobalSearchResults selectedAssetsCount={assetsCount[assetType]} />
            <div className={styles.scroller}>
              {assetType === AssetType.Project && (
                <List collapsed empty={empty} emptyText={emptyText}>
                  {projects?.map((project) => (
                    <ProjectListItem key={project.vecticeId} project={project} workspace={project.workspace} />
                  ))}
                </List>
              )}
              {assetType === AssetType.Dataset && (
                <List collapsed empty={empty} emptyText={emptyText}>
                  {datasets?.map((dataset) => (
                    <DatasetListItem
                      key={dataset.vecticeId}
                      dataset={dataset}
                      project={dataset.project}
                      workspace={dataset.project.workspace}
                    />
                  ))}
                </List>
              )}
              {assetType === AssetType.Model && (
                <List collapsed empty={empty} emptyText={emptyText}>
                  {models?.map((model) => (
                    <ModelListItem
                      key={model.vecticeId}
                      model={model}
                      project={model.project}
                      workspace={model.project.workspace}
                    />
                  ))}
                </List>
              )}
              <Pagination
                current={pageIndex}
                pageSize={PAGE_SIZE}
                total={assetsCount[assetType]}
                onChange={setPageIndex}
              />
            </div>
          </>
        )}
      </WithLoading>
    </FlexContainer>
  );
};
