import React from 'react';
import { Link } from 'react-router-dom';

import { buildLink, formatSearchParameters, SearchParameter, VecticeRoutes } from '../../../routes';
import { LinkAsButton } from '../../../ui';
import { FormatUserName } from '../../formatters';
import { UserAskedForUpgradeNotification as UserAskedForUpgradeNotificationType } from '../types';

import { Notification } from './Notification';

import styles from './UserAskedForUpgradeNotification.module.scss';

interface UserAskedForUpgradeNotificationProps {
  notification: UserAskedForUpgradeNotificationType;
  onClose?: () => void;
}

export const UserAskedForUpgradeNotification = ({
  notification: { creator, createdDate },
  onClose,
}: UserAskedForUpgradeNotificationProps) => (
  <Notification creator={creator} createdDate={createdDate}>
    {$t({
      id: 'notification.userAskedForUpgrade',
      defaultMessage: '<bold>{creator}</bold> has requested to become a member of organization Vectice',
      values: {
        creator: <FormatUserName user={creator} />,
      },
    })}
    <LinkAsButton
      className={styles.button}
      component={Link}
      fullWidth
      to={{
        pathname: buildLink(VecticeRoutes.ORGANIZATION_USERS),
        search: formatSearchParameters({
          // @ts-expect-error issue with creator type
          [SearchParameter.SEARCH]: creator.email,
        }),
      }}
      onClick={onClose}
    >
      {$t({ id: 'button.goToUserManagement', defaultMessage: 'Go to “Manage users” page' })}
    </LinkAsButton>
  </Notification>
);
