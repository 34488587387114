import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { graphql } from '../../gql';
import { Model } from '../../gql/graphql';
import { useValidateName, useVecticeForm } from '../../hooks';
import { Input, message, ModalForm, TextArea, WithAsyncValidation } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';

export const CHECK_MODEL_NAME_AVAILABILITY = graphql(`
  query checkModelNameAvailability($modelId: VecticeId!, $projectId: VecticeId!, $name: String!) {
    checkModelNameAvailability(modelId: $modelId, projectId: $projectId, name: $name)
  }
`);

export const UPDATE_MODEL = graphql(`
  mutation updateModel($modelId: VecticeId!, $data: ModelUpdateInput!) {
    updateModel(modelId: $modelId, model: $data) {
      vecticeId
      name
      description
    }
  }
`);

export interface FormData {
  name: string;
  description?: string | null;
}

interface EditModelModalProps {
  model: Pick<Model, 'vecticeId' | 'name' | 'description'>;
  projectId?: string;
  onClose: () => void;
}

export const EditModelModal = ({ model, projectId, onClose }: EditModelModalProps) => {
  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: model.name,
      description: model.description,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updateModel] = useMutation(UPDATE_MODEL, {
    optimisticResponse: ({ modelId: vecticeId, data: { name, description } }) => ({
      updateModel: {
        vecticeId: vecticeId.toString(),
        name: name ?? model!.name,
        description: description ?? model!.description,
      },
    }),
    update: () => onClose(),
    onCompleted: ({ updateModel: { name } }) =>
      message.success(
        $t({
          id: 'model.modal.success',
          defaultMessage: '{name} model updated',
          values: { name },
        }),
      ),
    onError: (error) => message.error(error.message),
  });

  const [checkNameAvailability] = useLazyQuery(CHECK_MODEL_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const handleNameAvailability = useCallback(
    async (value: string) => {
      const { data: { checkModelNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          projectId: projectId ?? '',
          modelId: model.vecticeId,
          name: value,
        },
      });

      return !!checkModelNameAvailability;
    },
    [model, projectId, checkNameAvailability],
  );

  const { validateName } = useValidateName(handleNameAvailability, VecticeResourceType.MODEL);

  const handleSubmit = useCallback(
    async ({ name, description }: FormData) => {
      await updateModel({
        variables: {
          modelId: model.vecticeId,
          data: { name, description },
        },
      });
    },
    [model, updateModel],
  );

  return (
    <ModalForm
      title={$t({ id: 'model.modal.edit.title', defaultMessage: 'Edit model' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          label={$t({
            id: 'form.modelEdit.nameLabel',
            defaultMessage: 'Model name',
          })}
          required
          gutterBottom
          autoFocus
        />
      </WithAsyncValidation>
      <TextArea
        label={$t({
          id: 'form.modelEdit.descriptionLabel',
          defaultMessage: 'Model description',
        })}
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        placeholder={$t({ id: 'asset.details.description.placeholder', defaultMessage: 'Add description' })}
        hint={$t({ id: 'modal.duplicateProject.description.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
