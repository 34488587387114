import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from '../../../assets/icons/interface/ic-search.svg';
import { useQueryParam, useVecticeForm } from '../../../hooks';
import {
  buildBrowseLink,
  buildLink,
  formatSearchParameters,
  getRoutePath,
  SearchParameter,
  VecticeRoutes,
} from '../../../routes';
import { FlexContainer, Form, Icon } from '../../../ui';
import { doABarrelRoll, getVecticeIdType, isVecticeId } from '../../../utils';

import styles from './SearchInput.module.scss';

interface FormData {
  search: string;
}

export const SearchInput = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchParam] = useQueryParam(SearchParameter.SEARCH);
  const [assetParam] = useQueryParam(SearchParameter.ASSET);

  const { preSubmit, registerWithErrors, setValue, watch } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: { search: '' },
  });

  const search = watch('search');

  useEffect(() => {
    if (pathname !== getRoutePath(VecticeRoutes.SEARCH)) {
      setValue('search', '');
    } else {
      setValue('search', searchParam ?? '');
    }
  }, [pathname, setValue, searchParam]);

  const handleSubmit = useCallback(
    ({ search }: FormData) => {
      if (search?.match(/do a barrel roll/i)) {
        doABarrelRoll();
      }

      if (isVecticeId(search)) {
        navigate(buildBrowseLink({ id: search, type: getVecticeIdType(search) }));
      } else {
        navigate({
          pathname: buildLink(VecticeRoutes.SEARCH),
          search: formatSearchParameters({
            [SearchParameter.SEARCH]: search?.trim() || undefined,
            [SearchParameter.ASSET]: assetParam,
          }),
        });
      }
    },
    [navigate, assetParam],
  );

  return (
    <Form onSubmit={preSubmit(handleSubmit)} className={styles.form}>
      <FlexContainer className={styles.wrapper}>
        <Icon icon={SearchIcon} size={18} />
        <input
          {...registerWithErrors('search')}
          className={cn(styles.input, { [styles.has_search]: !!search })}
          placeholder={$t({
            id: 'component.navbar.search',
            defaultMessage: 'Search projects, datasets, models, or asset IDs',
          })}
          type="search"
        />
      </FlexContainer>
    </Form>
  );
};
