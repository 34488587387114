import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../assets/icons/interface/ic-remove.svg';
import { graphql } from '../../gql';
import { Button, FlexContainer, message, ModalPrompt, Typography } from '../../ui';

import styles from './ViewerBanner.module.scss';

const ASK_FOR_UPGRADE = graphql(`
  mutation askUserTypeUpgrade {
    askUserTypeUpgrade
  }
`);

export const ViewerBanner = ({ onClose }: { onClose: () => void }) => {
  const [showRequestModal, setShowRequestModal] = useState(false);

  const [requestUpgrade, { loading }] = useMutation(ASK_FOR_UPGRADE, {
    onCompleted: () => {
      setShowRequestModal(false);
      message.success($t({ id: 'viewer.requestAccess.success', defaultMessage: 'Your request has been sent' }));
    },
  });

  return (
    <>
      <FlexContainer className={styles.banner} justify="center">
        <Typography variant="callout">
          {$t({
            id: 'viewer.warning',
            defaultMessage: 'You are a viewer with read-only permissions. Contact your Admin to request access.',
          })}
        </Typography>
        <Button className={styles.request} onClick={() => setShowRequestModal(true)}>
          {$t({ id: 'viewer.requestAccess', defaultMessage: 'Request access' })}
        </Button>
        <Button className={styles.close} leftIcon={CloseIcon} size="xs" onClick={onClose} />
      </FlexContainer>
      {showRequestModal && (
        <ModalPrompt
          cancelLabel={$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
          confirmLabel={$t({ id: 'viewer.requestAccess.confirmRequest', defaultMessage: 'Yes, send request' })}
          processing={loading}
          title={$t({ id: 'viewer.requestAccess', defaultMessage: 'Request access' })}
          onClose={() => setShowRequestModal(false)}
          onConfirm={() => requestUpgrade()}
        >
          {$t({
            id: 'viewer.requestAccess.message',
            defaultMessage:
              'Are you sure you want to request a permissions upgrade from your Admin? Approval of this request will grant you Member permissions, enabling you to collaborate on and edit projects.',
          })}
        </ModalPrompt>
      )}
    </>
  );
};
