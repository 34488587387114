import debounce from 'lodash/debounce';
import { useCallback } from 'react';

import { DEBOUNCE_WAIT_TIME } from '../ui/form/input/internals';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../utils';

export const useValidateName = (
  onCheckAvailability: (value: string) => Promise<boolean>,
  resourceType: VecticeResourceType,
) => {
  const debouncedValidation = useCallback(
    debounce(async (value: string, resolve: (value: void) => void, reject: (reason?: any) => void) => {
      if (!value || !onCheckAvailability) {
        resolve();
      }

      const resourceLabel = getVecticeResourceTypeLabel(resourceType);

      let errorMessage = $t({
        id: 'validateName.nameAlreadyInUse',
        defaultMessage: '{type} "{name}" already exists. Please choose another name.',
        values: {
          type: resourceLabel,
          name: value,
        },
      });
      if (resourceType === VecticeResourceType.PROJECT) {
        errorMessage = $t({
          id: 'validateName.workspace.nameAlreadyInUse',
          defaultMessage: '{type} "{name}" already exists in your workspace. Please choose another name.',
          values: {
            type: resourceLabel,
            name: value,
          },
        });
      } else if (
        resourceType === VecticeResourceType.CDT_MACRO ||
        resourceType === VecticeResourceType.CDT_TEMPLATE ||
        resourceType === VecticeResourceType.CDT_REPORT ||
        resourceType === VecticeResourceType.DATASET ||
        resourceType === VecticeResourceType.MODEL
      ) {
        errorMessage = $t({
          id: 'validateName.project.nameAlreadyInUse',
          defaultMessage: '{type} "{name}" already exists in this project. Please choose another name.',
          values: {
            type: resourceLabel,
            name: value,
          },
        });
      } else if (resourceType === VecticeResourceType.ITERATION_SECTION) {
        errorMessage = $t({
          id: 'modal.iterationSection.name.error.alreadyUsed',
          defaultMessage: 'Section "{name}" already exists. Please choose another name.',
          values: {
            name: value,
          },
        });
      } else if (resourceType === VecticeResourceType.FINDING) {
        errorMessage = $t({
          id: 'validateName.issue.error.alreadyUsed',
          defaultMessage: 'Issue "{name}" already exists for that model version. Please choose another name.',
          values: {
            name: value,
          },
        });
      }

      const isNameAvailable = await onCheckAvailability(value.trim());

      if (isNameAvailable) {
        resolve();
      }

      reject(new Error(errorMessage));
    }, DEBOUNCE_WAIT_TIME),
    [onCheckAvailability, resourceType],
  );

  const validateName = useCallback(
    (name: string) => {
      return new Promise<void>((resolve, reject) => debouncedValidation(name, resolve, reject));
    },
    [debouncedValidation],
  );

  return { validateName };
};
