import cn from 'classnames';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import React, { MouseEvent } from 'react';

import { ReactComponent as CaretIcon } from '../../assets/icons/interface/ic-caret-down.svg';
import { Icon } from '../svg';

import { DefaultRecordType } from './types';

import styles from './Table.module.scss';

export const ExpandIcon = <RecordType extends DefaultRecordType>({
  expandable,
  expanded,
  record,
  onExpand,
}: RenderExpandIconProps<RecordType>) =>
  expandable ? (
    <Icon
      icon={CaretIcon}
      size={18}
      className={cn(styles.expand, { [styles.expanded]: expanded })}
      onClick={(e: MouseEvent<HTMLElement>) => onExpand(record, e)}
    />
  ) : (
    <span className={styles.expand_placeholder} />
  );
