export class UIError extends Error {}

export class UnauthenticatedError extends UIError {}

export class VersionMismatchError extends UIError {}

export class ConnectionLostError extends UIError {}

export class OfflineError extends UIError {}

export class InternalServerError extends UIError {
  constructor(message: string, options: ErrorOptions) {
    super(message, options);
    this.name = 'InternalServerError';
  }
}
