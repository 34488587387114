import React from 'react';

import { FlexContainer, Menu, MenuItem } from '../../ui';
import { ExportOptionsType, getExportIcon, getExportLabel } from '../../utils';
import { BetaFeatureBadge, ComingSoonBadge } from '../badges';

export const ExportMenu = ({
  options,
  onExport,
}: {
  options: ExportOptionsType[];
  onExport: (option: ExportOptionsType) => void;
}) => {
  const exportOptionArray = Object.values(ExportOptionsType);
  const displayOption = (option: ExportOptionsType) => {
    const inOptionsList = options.includes(option);
    const isWord = inOptionsList && option === ExportOptionsType.Doc;
    return (
      <MenuItem
        key={option}
        leftIcon={getExportIcon(option)}
        disabled={!inOptionsList}
        onClick={() => onExport(option)}
      >
        {inOptionsList ? (
          <FlexContainer justify="between">
            {getExportLabel(option)}
            {isWord && <BetaFeatureBadge />}
          </FlexContainer>
        ) : (
          <FlexContainer direction="column" gap={4}>
            {getExportLabel(option)}
            <ComingSoonBadge color="default" />
          </FlexContainer>
        )}
      </MenuItem>
    );
  };

  return <Menu>{exportOptionArray.map(displayOption)}</Menu>;
};
