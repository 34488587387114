import React, { useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'enabled';

class EnabledFilterNode<T> extends FilterNode<T> {
  private options = [
    {
      label: $t({ id: 'enabledFilter.enabled.label', defaultMessage: 'Enabled' }),
      value: 'enabled',
    },
    {
      label: $t({ id: 'enabledFilter.disabled.label', defaultMessage: 'Disabled' }),
      value: 'disabled',
    },
  ];

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="availability-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const EnabledFilter = () => {
  const { current: node } = useRef(new EnabledFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.enabled.label', defaultMessage: 'Status' }),
    node,
  });

  return null;
};
