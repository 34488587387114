import { ReactNode } from 'react';

import { DefaultRecordType, ColumnType } from './types';

export interface ColumnProps<RecordType, DataType> extends Omit<ColumnType<RecordType>, 'children'> {
  children(value: DataType, record: RecordType, index: number): ReactNode;
}

export const Column = <RecordType extends DefaultRecordType, DataType = unknown>(
  _: Omit<ColumnProps<RecordType, DataType>, 'children'> | Pick<ColumnProps<RecordType, DataType>, 'children'>,
) => null;
