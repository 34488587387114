import React, { ElementType } from 'react';

import { FlexContainer, Icon, Typography } from '../../ui';

interface PhaseStatisticBlockProps extends VecticeHTMLProps<HTMLDivElement> {
  count: number;
  icon: ElementType;
  iconClassName: string;
  percentage: number;
  status: string;
  total: number;
}

export const PhaseStatisticBlock = ({
  className,
  count,
  icon,
  iconClassName,
  percentage,
  status,
  total,
  ...props
}: PhaseStatisticBlockProps) => (
  <FlexContainer className={className} gap={12} {...props}>
    <div className={iconClassName}>
      <Icon icon={icon} size={30} />
    </div>
    <FlexContainer direction="column" gap={0}>
      <Typography variant="footnote" color="tertiary">
        {`${count} of ${total} ${status}`}
      </Typography>
      <Typography variant="subtitle" weight="bold">
        {percentage} %
      </Typography>
    </FlexContainer>
  </FlexContainer>
);
