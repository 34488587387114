import { UserAndInvitesStatus } from '../../gql/graphql';

export const getUserOrInviteStatusDisplayName = (status?: UserAndInvitesStatus | string | null) => {
  switch (status) {
    case UserAndInvitesStatus.Disabled:
      return $t({ id: 'userOrInvite.deactivated.label', defaultMessage: 'Deactivated' });
    case UserAndInvitesStatus.Enabled:
      return $t({ id: 'userOrInvite.active.label', defaultMessage: 'Active' });
    case UserAndInvitesStatus.Pending:
      return $t({ id: 'userOrInvite.pending.label', defaultMessage: 'Pending' });
    default:
      return status || $t({ id: 'userOrInvite.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getUserOrInviteStatusOptions = () => [
  {
    label: getUserOrInviteStatusDisplayName(UserAndInvitesStatus.Enabled),
    value: UserAndInvitesStatus.Enabled,
  },
  {
    label: getUserOrInviteStatusDisplayName(UserAndInvitesStatus.Disabled),
    value: UserAndInvitesStatus.Disabled,
  },
  {
    label: getUserOrInviteStatusDisplayName(UserAndInvitesStatus.Pending),
    value: UserAndInvitesStatus.Pending,
  },
];
