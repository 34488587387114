import React, { PropsWithChildren, ReactNode } from 'react';

import { Button } from '../button';
import { Divider } from '../common';

import styles from './FilterOverlay.module.scss';

interface FiltersProps {
  menu?: ReactNode;
  hideClearButton: boolean;
  resetLabel: string;
  clearLabel: string;
  onClearFilter: () => void;
  onClearAllFilters: () => void;
}

export const FilterOverlay = ({
  children,
  menu,
  hideClearButton,
  resetLabel,
  clearLabel,
  onClearFilter,
  onClearAllFilters,
}: PropsWithChildren<FiltersProps>) => (
  <div className={styles.filters} role="dialog" aria-label="Filters">
    <div className={styles.column}>
      <div className={styles.content}>{menu}</div>
      <Divider className={styles.divider} />
      <div className={styles.footer}>
        <Button variant="phantom" onClick={onClearAllFilters}>
          {resetLabel}
        </Button>
      </div>
    </div>
    <div className={styles.column}>
      <div className={styles.content}>{children}</div>
      {!hideClearButton && (
        <>
          <Divider className={styles.divider} />
          <div className={styles.footer}>
            <Button variant="phantom" onClick={onClearFilter}>
              {clearLabel}
            </Button>
          </div>
        </>
      )}
    </div>
  </div>
);
