import { graphql } from '../../gql';

export const GET_ALL_ORGANIZATIONS = graphql(`
  query getAllOrganizations($order: ListOrderInput, $search: String, $levels: [OrganizationLevel!], $page: PageInput) {
    getAllOrganizations(filters: { search: $search, levels: $levels }, order: $order, page: $page) {
      items {
        id
        name
        level
        description
        createdDate
        emailDomains {
          domain
        }
      }
      total
    }
  }
`);

export const GET_ACCESSIBILITY_LEVELS = graphql(`
  query getAccessibilityLevels {
    getAccessibilityLevels
  }
`);

export const SET_USER_ROLE = graphql(`
  mutation setUserRole($role: UserRole!, $userId: Float!) {
    setUserRole(role: $role, userId: $userId) {
      id
      role
    }
  }
`);

export const DISABLE_USER = graphql(`
  mutation disableUser($userId: Float!) {
    disableUser(userId: $userId) {
      id
      disabled
    }
  }
`);

export const ENABLE_USER = graphql(`
  mutation enableUser($userId: Float!) {
    enableUser(userId: $userId) {
      id
      disabled
    }
  }
`);

export const CREATE_ORGANIZATION = graphql(`
  mutation createOrganization($data: OrganizationCreateInput!) {
    createOrganization(data: $data) {
      id
      name
      description
    }
  }
`);

export const UPDATE_ORGANIZATION_DESCRIPTION_AND_LEVEL = graphql(`
  mutation updateOrganizationDescription($organizationId: ID!, $data: OrganizationUpdateInput!) {
    updateOrganization(data: $data, organizationId: $organizationId) {
      id
      description
      level
    }
  }
`);

export const DELETE_ORG = graphql(`
  mutation deleteOrganization($organizationId: Float!) {
    deleteOrganization(organizationId: $organizationId)
  }
`);

export const CHECK_DOMAIN_AVAILABILITY = graphql(`
  query checkOrganizationEmailDomainAvailability($userEmail: String!) {
    checkOrganizationEmailDomainAvailability(userEmail: $userEmail)
  }
`);

export const GET_REALM_FROM_EMAIL = graphql(`
  query getRealmInformationFromEmail($email: String!) {
    getRealmInformationFromEmail(email: $email) {
      realm
      clientId
    }
  }
`);

export const TRIAL_SIGN_UP = graphql(`
  mutation inviteTrialUser($email: String!) {
    inviteTrialUser(email: $email)
  }
`);
