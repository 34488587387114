import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { graphql } from '../../gql';
import { AssetType, GroupFilter } from '../../gql/graphql';
import { GET_ALL_USER_WORKSPACES } from '../../graphql/queries';
import {
  buildAdvancedDatasetSourceOriginFilter,
  buildAdvancedOwnerFilter,
  buildAdvancedProjectStatusFilter,
} from '../../graphql/utils';
import { useQueryParam } from '../../hooks';
import { SearchParameter } from '../../routes';
import { message } from '../../ui';
import { useUrlFilters } from '../filters/useUrlFilters';

import { AssetsCount } from './interfaces';

export const GET_INITIAL_ASSET_COUNT = graphql(`
  query getInitialAssetCount(
    $workspaceIdList: [VecticeId!]
    $assetType: AssetType!
    $search: String
    $pageIndex: Int!
    $pageSize: Int!
  ) {
    searchAssets(
      workspaceIdList: $workspaceIdList
      assetType: $assetType
      filters: { search: $search }
      page: { size: $pageSize, index: $pageIndex }
    ) {
      project {
        total
      }
      dataset {
        total
      }
      model {
        total
      }
    }
  }
`);
export const SEARCH_PROJECT_ASSETS = graphql(`
  query searchProjectAssets(
    $workspaceIdList: [VecticeId!]
    $assetType: AssetType!
    $search: String
    $contributors: [ID!]
    $advancedFilters: [GroupFilter!]
    $pageIndex: Int!
    $pageSize: Int!
  ) {
    searchAssets(
      workspaceIdList: $workspaceIdList
      assetType: $assetType
      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }
      page: { size: $pageSize, index: $pageIndex }
    ) {
      project {
        total
        items {
          vecticeId
          ...projectListItem
          workspace {
            vecticeId
            name
            type
          }
        }
      }
      dataset {
        total
      }
      model {
        total
      }
    }
  }
`);
export const SEARCH_DATASET_ASSETS = graphql(`
  query searchDatasetAssets(
    $workspaceIdList: [VecticeId!]
    $assetType: AssetType!
    $search: String
    $contributors: [ID!]
    $dsTypes: [DatasetType!]
    $advancedFilters: [GroupFilter!]
    $pageIndex: Int!
    $pageSize: Int!
  ) {
    searchAssets(
      workspaceIdList: $workspaceIdList
      assetType: $assetType
      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }
      page: { size: $pageSize, index: $pageIndex }
    ) {
      project {
        total
      }
      dataset {
        total
        items {
          vecticeId
          ...datasetListItem
          project {
            vecticeId
            name
            workspace {
              vecticeId
              name
              type
            }
          }
        }
      }
      model {
        total
      }
    }
  }
`);
export const SEARCH_MODEL_ASSETS = graphql(`
  query searchModelAssets(
    $workspaceIdList: [VecticeId!]
    $assetType: AssetType!
    $search: String
    $contributors: [ID!]
    $types: [ModelType!]
    $modelVersionStatus: [ModelVersionStatus!]
    $algorithmNames: [String!]
    $inventoryReferences: [String!]
    $pageIndex: Int!
    $pageSize: Int!
  ) {
    searchAssets(
      workspaceIdList: $workspaceIdList
      assetType: $assetType
      filters: {
        search: $search
        contributors: $contributors
        types: $types
        modelVersionStatus: $modelVersionStatus
        algorithmNames: $algorithmNames
        inventoryReferences: $inventoryReferences
      }
      page: { size: $pageSize, index: $pageIndex }
    ) {
      project {
        total
      }
      dataset {
        total
      }
      model {
        total
        items {
          vecticeId
          ...modelListItem
          project {
            vecticeId
            name
            workspace {
              vecticeId
              name
              type
            }
          }
        }
      }
    }
  }
`);

const initialAssetsCount: AssetsCount = {
  [AssetType.Project]: 0,
  [AssetType.Dataset]: 0,
  [AssetType.Model]: 0,
  [AssetType.User]: 0,
};

export const useGlobalSearch = (pageSize: number) => {
  const [search] = useQueryParam(SearchParameter.SEARCH);
  const [assetType, setAssetType] = useQueryParam(SearchParameter.ASSET);

  const [filters, setFilters] = useUrlFilters();

  const [pageIndex, setPageIndex] = useState(1);
  const [assetsCount, setAssetsCount] = useState<AssetsCount>(initialAssetsCount);

  const { data } = useQuery(GET_ALL_USER_WORKSPACES, {
    onError: (error) => message.error(error.message),
  });

  const workspaceIdList = useMemo(() => data?.getUserWorkspaceList.items?.map((ws) => ws.vecticeId), [data]);

  useEffect(() => {
    setPageIndex(1);
  }, [search]);

  const onFiltersUpdate = (updatedFilters: Record<string, any>) => {
    setPageIndex(1);
    setFilters(updatedFilters);
  };

  const variables = useMemo(() => {
    let advancedFilters: GroupFilter[];
    switch (assetType) {
      case AssetType.Project: {
        advancedFilters = [
          ...buildAdvancedProjectStatusFilter(filters?.status),
          ...buildAdvancedOwnerFilter(((filters?.owner || []) as string[]).map((id) => +id)),
        ];
        break;
      }
      case AssetType.Dataset: {
        advancedFilters = [...buildAdvancedDatasetSourceOriginFilter(filters?.sourceOrigin)];
        break;
      }
      default: {
        advancedFilters = [];
        break;
      }
    }
    return {
      ...filters,
      workspaceIdList: filters?.workspace ? [filters.workspace] : undefined,
      workspace: undefined,
      assetType: assetType ? (assetType as AssetType) : AssetType.Project,
      pageIndex,
      search: search ?? '',
      advancedFilters,
      pageSize,
    };
  }, [assetType, filters, pageIndex, pageSize, search]);

  const { loading: assetCountLoading } = useQuery(GET_INITIAL_ASSET_COUNT, {
    fetchPolicy: 'network-only',
    skip: assetType !== undefined,
    variables,
    onCompleted: ({ searchAssets }) => {
      const navigateOption = { replace: true };
      setAssetsCount({
        [AssetType.Dataset]: searchAssets.dataset?.total || 0,
        [AssetType.Model]: searchAssets.model?.total || 0,
        [AssetType.Project]: searchAssets.project?.total || 0,
        [AssetType.User]: 0,
      });

      if (searchAssets.project.total > 0) {
        setAssetType(AssetType.Project, navigateOption);
        return;
      }
      if (searchAssets.dataset.total > 0) {
        setAssetType(AssetType.Dataset, navigateOption);
        return;
      }
      if (searchAssets.model.total > 0) {
        setAssetType(AssetType.Model, navigateOption);
        return;
      }
      setAssetType(AssetType.Project, navigateOption);
    },
  });

  const { data: projectAssetsData, loading: projectAssetsLoading } = useQuery(SEARCH_PROJECT_ASSETS, {
    fetchPolicy: 'network-only',
    skip: assetType !== AssetType.Project,
    variables,
    onCompleted: ({ searchAssets }) => {
      setAssetsCount({
        [AssetType.Dataset]: searchAssets.dataset?.total || 0,
        [AssetType.Model]: searchAssets.model?.total || 0,
        [AssetType.Project]: searchAssets.project?.total || 0,
        [AssetType.User]: 0,
      });
    },
  });

  const { data: datasetAssetsData, loading: datasetAssetsLoading } = useQuery(SEARCH_DATASET_ASSETS, {
    fetchPolicy: 'network-only',
    skip: assetType !== AssetType.Dataset,
    variables,
    onCompleted: ({ searchAssets }) => {
      setAssetsCount({
        [AssetType.Dataset]: searchAssets.dataset?.total || 0,
        [AssetType.Model]: searchAssets.model?.total || 0,
        [AssetType.Project]: searchAssets.project?.total || 0,
        [AssetType.User]: 0,
      });
    },
  });

  const { data: modelAssetsData, loading: modelAssetsLoading } = useQuery(SEARCH_MODEL_ASSETS, {
    fetchPolicy: 'network-only',
    skip: assetType !== AssetType.Model,
    variables,
    onCompleted: ({ searchAssets }) => {
      setAssetsCount({
        [AssetType.Dataset]: searchAssets.dataset?.total || 0,
        [AssetType.Model]: searchAssets.model?.total || 0,
        [AssetType.Project]: searchAssets.project?.total || 0,
        [AssetType.User]: 0,
      });
    },
  });

  return {
    assetType: assetType as AssetType,
    assetsCount,
    datasets: datasetAssetsData?.searchAssets.dataset.items,
    filters,
    loading: assetCountLoading || projectAssetsLoading || datasetAssetsLoading || modelAssetsLoading,
    models: modelAssetsData?.searchAssets.model.items,
    pageIndex,
    projects: projectAssetsData?.searchAssets.project.items,
    workspaceIdList,
    onFiltersUpdate,
    setAssetType,
    setPageIndex,
  };
};
