import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

import type { Dispatch, SetStateAction } from 'react';

import { ModelVersionStatus } from '../../gql/graphql';
import { useWithFeatureFlags } from '../../guards';

import styles from './ModelVersionAssetsContext.module.scss';

type excludedOptions = typeof ModelVersionStatus.Discarded | typeof ModelVersionStatus.Retired;
export type Category = Exclude<ModelVersionStatus, excludedOptions>;

interface ModelVersionAssetsContextType {
  category: Category | undefined;
  setCategory: Dispatch<SetStateAction<Category>> | undefined;
}

const defaultReportContext: ModelVersionAssetsContextType = {
  category: undefined,
  setCategory: undefined,
};

const Context = createContext<ModelVersionAssetsContextType>(defaultReportContext);

export const useModelVersionAssetsContext = () => {
  const context = useContext<ModelVersionAssetsContextType>(
    Context as unknown as React.Context<ModelVersionAssetsContextType>,
  );

  logger.log('⚠️ Context is not defined, make sure to use ModelVersionAssetsContext if needed');

  return context ?? defaultReportContext;
};

export const ModelVersionAssetsContext = ({ children }: PropsWithChildren<unknown>) => {
  const { allowed } = useWithFeatureFlags({ featureFlag: 'mdv_assets_by_status' });

  const [category, setCategory] = useState<Category>(ModelVersionStatus.Experimentation);

  if (!allowed) {
    return <Context.Provider value={defaultReportContext}>{children}</Context.Provider>;
  }

  return (
    <Context.Provider value={{ category, setCategory }}>
      <div className={styles.container}>{children}</div>
    </Context.Provider>
  );
};

export const ModelVersionAssetsContextConsumer = Context.Consumer;
