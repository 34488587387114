import React from 'react';

import { AssetType } from '../../gql/graphql';
import { Tab, Tabs } from '../../ui';

import { AssetsCount } from './interfaces';

import styles from './GlobalSearchTypeSwitch.module.scss';

interface GlobalSearchTypeSwitchProps {
  assetType?: AssetType;
  assetsCount: AssetsCount;
  onAssetTypeChange: (assetType: AssetType) => void;
}

export const GlobalSearchTypeSwitch = ({ assetsCount, assetType, onAssetTypeChange }: GlobalSearchTypeSwitchProps) => {
  const controls = [
    {
      label: $t({ id: 'GlobalSearchTypeSwitch.projects', defaultMessage: 'Projects' }),
      type: AssetType.Project,
    },
    {
      label: $t({ id: 'GlobalSearchTypeSwitch.datasets', defaultMessage: 'Datasets' }),
      type: AssetType.Dataset,
    },
    {
      label: $t({ id: 'GlobalSearchTypeSwitch.models', defaultMessage: 'Models' }),
      type: AssetType.Model,
    },
  ];

  return (
    <Tabs aria-label="Filter by asset" className={styles.tabs}>
      {controls.map(({ label, type }) => (
        <Tab
          key={`asset-type-${type}`}
          active={assetType === type}
          count={assetsCount ? assetsCount[type] : 0}
          onClick={() => onAssetTypeChange(type)}
        >
          {label}
        </Tab>
      ))}
    </Tabs>
  );
};
