import cn from 'classnames';
import React, { ReactElement, ReactNode } from 'react';

import { MoreActions } from '../button';
import { FlexContainer } from '../containers';
import { Skeleton, WithLoading } from '../loading-animation';
import { Summary } from '../summary';

import styles from './AssetWidget.module.scss';

export interface AssetWidgetProps extends Omit<VecticeHTMLProps<HTMLDetailsElement>, 'open' | 'summary'> {
  open: boolean;
  summary: ReactNode;
  actionsMenu?: ReactElement;
  customizeMenu?: ReactElement;
  expandable?: boolean;
  loading?: boolean;
  onToggle?: (open: boolean) => void;
}

export const AssetWidget = ({
  actionsMenu,
  expandable,
  loading,
  summary,
  onClick,
  className,
  ...props
}: AssetWidgetProps) => (
  <Summary
    summary={
      <WithLoading
        loading={loading}
        skeleton={
          <FlexContainer className={styles.summarySkeleton} direction="column" gap={12}>
            <Skeleton.Paragraph variant="subtitle" width="350px" />
            <Skeleton.Paragraph variant="body" width="450px" />
          </FlexContainer>
        }
      >
        <FlexContainer className={styles.summaryWrapper} onClick={onClick}>
          <div className={styles.summaryContent}>{summary}</div>
          {!!actionsMenu && (
            <MoreActions
              moreActionsMenu={actionsMenu}
              size="xxs"
              onClick={(e) => e.stopPropagation()}
              className={styles.actions}
            />
          )}
        </FlexContainer>
      </WithLoading>
    }
    expandable={expandable}
    className={cn(styles.assetWidget, className)}
    summaryClassName={cn(styles.summary, { [styles.expandable]: expandable ?? true })}
    detailsClassName={styles.details}
    {...props}
  />
);
