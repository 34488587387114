export enum ColorSwatch {
  summerGreen = '#90BFA2',
  portage = '#A792EE',
  tacha = '#D8B367',
  burntSienna = '#EE7464',
  pineGlade = '#BAC793',
  cornflowerBlue = '#72A4E8',
  iron = '#D6D8DB',
  selectiveYellow = '#FDB600',
}
