import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CalendarIcon } from '../../assets/icons/interface/ic-calendar.svg';
import { ReactComponent as FolderIcon } from '../../assets/icons/interface/ic-folder-file.svg';
import { getWorkspaceIcon, getWorkspaceTypeDisplayName } from '../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../gql';
import { AccessibilityLevel, UserRole } from '../../gql/graphql';
import { useWithRoles } from '../../guards';
import { buildLink, VecticeRoutes } from '../../routes';
import { Card, Typography, Icon, FlexContainer, Tooltip } from '../../ui';
import { UserAvatars } from '../asset-display';
import { ReadOnlyBadge } from '../badges/ReadOnlyBadge';
import { FormatDate } from '../formatters';

import styles from './WorkspaceCard.module.scss';

const WorkspaceFragment = graphql(`
  fragment WorkspaceCard on Workspace {
    vecticeId
    name
    type
    description
    userList(filters: { includeDisabledUsers: false }) {
      total
      items {
        id
        ...userFields
      }
    }
    projectCount
    updatedDate
    userContext {
      accessibility
    }
  }
`);

interface WorkspaceCardProps {
  variant?: 'default' | 'inline';
  workspace: FragmentType<typeof WorkspaceFragment>;
}

export const WorkspaceCard = ({ variant = 'default', workspace }: WorkspaceCardProps) => {
  const {
    description,
    name,
    projectCount,
    type,
    updatedDate,
    userContext,
    userList: { items: users, total: totalUsers },
    vecticeId,
  } = useFragment(WorkspaceFragment, workspace);
  const { allowed: isViewer } = useWithRoles({ organizationRole: UserRole.Observer });

  const labelId = `workspace-${name.replace(/\s/g, '-')}`;

  return (
    <Link aria-labelledby={labelId} to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId: vecticeId })}>
      <Card className={cn(styles.workspaceCard, { [styles.inline]: variant === 'inline' })}>
        <FlexContainer className={styles.name} gap={4}>
          <Tooltip text={getWorkspaceTypeDisplayName(type)} placement="top">
            <Icon icon={getWorkspaceIcon(type)} size={20} />
          </Tooltip>
          <Typography id={labelId} title={name} weight="semi-bold" color="primary" paragraph ellipsis>
            {name}
          </Typography>
          {!isViewer && userContext.accessibility === AccessibilityLevel.ReadOnly && <ReadOnlyBadge />}
        </FlexContainer>
        {description ? (
          <Typography variant="callout" color="secondary" paragraph className={styles.description}>
            {description}
          </Typography>
        ) : (
          <Typography variant="callout" color="tertiary" paragraph className={styles.description}>
            {$t({ id: 'WorkspaceCard.noDescription', defaultMessage: 'No description' })}
          </Typography>
        )}
        <FlexContainer className={styles.insights} direction="column" gap={variant === 'inline' ? 48 : 8}>
          <FlexContainer
            align="center"
            color="secondary"
            component={Typography}
            ellipsis
            gap={0}
            paragraph
            variant="callout"
          >
            <Icon color="gray" icon={FolderIcon} size={18} />
            {$t({
              id: 'WorkspaceCard.projectCount',
              defaultMessage: `{count, plural, =0 {# projects} one {# project} other {# projects}}`,
              values: { count: projectCount },
            })}
          </FlexContainer>
          <FlexContainer
            align="center"
            color="secondary"
            component={Typography}
            ellipsis
            gap={0}
            paragraph
            variant="callout"
          >
            <Icon color="dark-gray" icon={CalendarIcon} size={18} />
            {$t({ id: 'WorkspaceCard.updated', defaultMessage: 'Updated' })}
            &nbsp;
            <FormatDate date={updatedDate} fromNow />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className={styles.members} gap={16}>
          <Typography variant="callout" color="secondary" paragraph>
            {$t({
              id: 'WorkspaceCard.memberCount',
              defaultMessage: `{count, plural, =0 {No members} one {# member} other {# members}}`,
              values: { count: totalUsers },
            })}
          </Typography>
          {users && <UserAvatars users={users} total={totalUsers} />}
        </FlexContainer>
      </Card>
    </Link>
  );
};
