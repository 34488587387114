import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { getWorkspaceIcon, getWorkspaceTypeDisplayName } from '../../entities/utils';
import { AccessibilityLevel, UserRole } from '../../gql/graphql';
import { useWithPrivateWorkspace, useWithRoles } from '../../guards';
import { useAnalytics, useRetrievePageContext } from '../../hooks';
import { buildLink, getRoutePath, VecticeRoutes } from '../../routes';
import {
  FlexContainer,
  Icon,
  RestrictedAccessTooltip,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  WithLoading,
} from '../../ui';
import { ReadOnlyBadge } from '../badges/ReadOnlyBadge';

import { useIsTabActive } from './useIsTabActive';

import styles from './WorkspacePageHeader.module.scss';

export const WorkspaceNavigation = () => {
  const { workspaceId } = useParams();
  const { workspace, loading } = useRetrievePageContext();
  const { isTabActive } = useIsTabActive();
  const { allowed: isPrivateWorkspace } = useWithPrivateWorkspace();
  const { allowed: isViewer } = useWithRoles({ organizationRole: UserRole.Observer });
  const { logClick } = useAnalytics();

  const linkParams = { workspaceId: workspaceId ?? workspace?.vecticeId };

  return (
    <>
      <div className={styles.title_wrapper}>
        <WithLoading loading={loading && !workspace} skeleton={<Skeleton.Title variant="heading2" width="350px" />}>
          {workspace && (
            <FlexContainer gap={4}>
              <Link to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId })} className={styles.title_link}>
                <Typography component="h1" variant="heading2" color="primary" link className={styles.title}>
                  <Tooltip text={getWorkspaceTypeDisplayName(workspace.type)} placement="top">
                    <Icon icon={getWorkspaceIcon(workspace.type, true)} size={24} />
                  </Tooltip>
                  <Typography ellipsis>{workspace.name}</Typography>
                </Typography>
              </Link>
              {!isViewer && workspace.userContext.accessibility === AccessibilityLevel.ReadOnly && <ReadOnlyBadge />}
            </FlexContainer>
          )}
        </WithLoading>
      </div>
      <Tabs className={styles.tabs}>
        <Tab
          component={Link}
          to={buildLink(VecticeRoutes.WORKSPACE, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.WORKSPACE_PROJECTS))}
          onAuxClick={() => logClick('Workspace', 'projects', 'auxclick')}
          onClick={() => logClick('Workspace', 'projects', 'click')}
        >
          {$t({ id: 'navigation.workspace.tabs.projects', defaultMessage: 'Projects' })}
        </Tab>
        <Tab
          component={Link}
          to={buildLink(VecticeRoutes.WORKSPACE_DASHBOARD, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.WORKSPACE_DASHBOARD))}
          onAuxClick={() => logClick('Workspace', 'dashboard', 'auxclick')}
          onClick={() => logClick('Workspace', 'dashboard', 'click')}
        >
          {$t({ id: 'navigation.workspace.tabs.dashboard', defaultMessage: 'Dashboard' })}
        </Tab>
        <Tab
          component={Link}
          to={buildLink(VecticeRoutes.WORKSPACE_ACTIVITY, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.WORKSPACE_ACTIVITY))}
          onAuxClick={() => logClick('Workspace', 'activity', 'auxclick')}
          onClick={() => logClick('Workspace', 'activity', 'click')}
        >
          {$t({ id: 'navigation.workspace.tabs.activity', defaultMessage: 'Activity' })}
        </Tab>
        <RestrictedAccessTooltip
          message={$t({
            id: 'workspace.settings.navigation.members.restricted',
            defaultMessage: 'All users are members of this Organization Shared Workspace.',
          })}
          restricted={!isPrivateWorkspace}
          placement="top"
        >
          <Tab
            component={Link}
            to={buildLink(VecticeRoutes.WORKSPACE_MEMBERS, linkParams)}
            active={isTabActive(getRoutePath(VecticeRoutes.WORKSPACE_MEMBERS))}
            disabled={!isPrivateWorkspace}
            onAuxClick={() => logClick('Workspace', 'members', 'auxclick')}
            onClick={() => logClick('Workspace', 'members', 'click')}
          >
            {$t({ id: 'navigation.workspace.tabs.members', defaultMessage: 'Members' })}
          </Tab>
        </RestrictedAccessTooltip>
        <Tab
          component={Link}
          to={buildLink(VecticeRoutes.WORKSPACE_SETTINGS, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.WORKSPACE_SETTINGS))}
          onAuxClick={() => logClick('Workspace', 'settings', 'auxclick')}
          onClick={() => logClick('Workspace', 'settings', 'click')}
        >
          {$t({ id: 'navigation.workspace.tabs.settings', defaultMessage: 'Settings' })}
        </Tab>
      </Tabs>
    </>
  );
};
