import React from 'react';

import { useFragment } from '../../../gql';
import { User, UserRole } from '../../../gql/graphql';
import { UserFragment, UserIdentity } from '../../../graphql/fragments';
import { Badge, FlexContainer, Thumbnail, Typography } from '../../../ui';
import { getUserAvatarUrl, getUserInitial } from '../../../utils';
import { UserRoleBadge } from '../../badges';
import { formatUserName, FormatUserName } from '../../formatters';

import styles from './UserCard.module.scss';

interface UserCardProps extends VecticeHTMLProps<HTMLDivElement> {
  user: UserIdentity & Partial<Pick<User, 'role' | 'disabled'>>;
  contrast?: boolean;
}

export const UserCard = ({ user: userFragment, contrast, ...props }: UserCardProps) => {
  const user = useFragment(UserFragment, userFragment);
  const url = getUserAvatarUrl(user?.avatarId);
  const name = formatUserName(user);
  const initials = getUserInitial(name);

  return (
    <FlexContainer {...props}>
      <Thumbnail color={user?.color} src={url} initials={initials} name={name} />
      <div className={styles.user_information}>
        <Typography variant="callout" color={contrast ? 'white' : 'primary'} paragraph ellipsis>
          <FormatUserName user={userFragment} />
        </Typography>
        <Typography variant="footnote" color={contrast ? 'disabled' : 'tertiary'} paragraph ellipsis>
          {user.email}
        </Typography>
        {userFragment.role === UserRole.OrgAdmin && (
          <UserRoleBadge userRole={userFragment.role} contrast={contrast} className={styles.badge} />
        )}
        {userFragment.role === UserRole.Observer && (
          <UserRoleBadge userRole={userFragment.role} contrast={contrast} className={styles.badge} />
        )}
        {userFragment.disabled && (
          <Badge size="sm" contrast={contrast}>
            {$t({ id: 'user.status.deactivated', defaultMessage: 'Deactivated' })}
          </Badge>
        )}
      </div>
    </FlexContainer>
  );
};
