import { graphql } from '../../gql';

export const GET_REVIEW_LIST = graphql(`
  query getReviewList($filters: ReviewFiltersInput!, $order: ListOrderInput, $page: PageInput) {
    getReviewList(filters: $filters, order: $order, page: $page) {
      items {
        vecticeId
        status
        ...Review
      }
      total
    }
  }
`);
