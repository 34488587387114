import React from 'react';

import { UserNotificationAccessRequestRejected } from '../types';

import { Notification } from './Notification';

interface InviteAcceptedNotificationProps {
  notification: UserNotificationAccessRequestRejected;
}

export const AccessRequestRejectedNotification = ({
  notification: { creator, createdDate },
}: InviteAcceptedNotificationProps) => (
  <Notification creator={creator} createdDate={createdDate}>
    {$t({ id: 'notification.access.rejected', defaultMessage: 'Your request to access a Workspace was rejected' })}
  </Notification>
);
