import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';

import { Upload } from '../../ui';
import { getArray } from '../../utils';

import { ImgCrop } from './ImgCrop';

interface WithImgCropProps {
  beforeCrop?: (file: File) => boolean;
}

export const WithImgCrop = ({ beforeCrop, children }: PropsWithChildren<WithImgCropProps>) => {
  const [file, setFile] = useState<File | null>(null);
  const onUploadRef = useRef<(file: File) => void>();

  const uploadComponent = useMemo(() => {
    const [upload] = getArray<any>(children);

    if (upload?.type?.name !== Upload.name) {
      throw new Error('WithImgCrop must have an Upload as its first child');
    }

    const { onUpload, accept, ...restUploadProps } = upload.props;
    onUploadRef.current = onUpload;

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || 'image/*',
        onUpload: (files: File | File[] | null) => {
          const uploadedFiles = getArray(files);
          const [fileToCrop] = uploadedFiles;

          if (fileToCrop) {
            if (beforeCrop) {
              const isValid = beforeCrop(fileToCrop);
              if (!isValid) {
                return;
              }
            }

            setFile(fileToCrop);
          }
        },
      },
    };
  }, [beforeCrop, children]);

  const handleClose = () => {
    setFile(null);
  };

  return (
    <>
      {uploadComponent}
      {file && <ImgCrop file={file} onSubmit={onUploadRef.current} onClose={handleClose} />}
    </>
  );
};
