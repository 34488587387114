import cn from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';

import { FlexContainer, Section, WithLoading } from '../../ui';

import styles from './AssetDetails.module.scss';

interface AssetDetailsProps {
  className?: string;
  label: React.ReactNode | ((ariaId: string) => React.ReactNode);
  loading?: boolean;
  skeleton?: ReactNode;
}

export const AssetDetails = ({
  children,
  className,
  label,
  loading,
  skeleton,
}: PropsWithChildren<AssetDetailsProps>) => (
  <Section label={label} className={cn(styles.section, className)}>
    <WithLoading loading={loading} skeleton={skeleton}>
      <FlexContainer direction="column" gap={24}>
        {children}
      </FlexContainer>
    </WithLoading>
  </Section>
);
