import { UserRole } from '../../gql/graphql';

export const userRolesRanked: string[] = [UserRole.Observer, UserRole.Member, UserRole.OrgAdmin];

export const getUserRoleDescription = (role?: UserRole | string | null) => {
  switch (role) {
    case UserRole.OrgAdmin:
      return $t({
        id: 'userRole.orgAdmin.description',
        defaultMessage: 'Full access to the organization settings and user management',
      });
    case UserRole.Member:
      return $t({
        id: 'userRole.user.description',
        defaultMessage: 'Standard access to workspace resources and functionalities',
      });
    case UserRole.Observer:
      return $t({
        id: 'userRole.observer.description',
        defaultMessage: 'Read-only access with ability to comment, review and export documentation',
      });
    default:
      return role || $t({ id: 'userRole.unknown.description', defaultMessage: 'Unknown' });
  }
};

export const getUserRoleDisplayName = (role?: UserRole | string | null) => {
  switch (role) {
    case UserRole.Superadmin:
      return $t({ id: 'userRole.superAdmin.label', defaultMessage: 'Super Admin' });
    case UserRole.OrgAdmin:
      return $t({ id: 'userRole.orgAdmin.label', defaultMessage: 'Admin' });
    case UserRole.Member:
      return $t({ id: 'userRole.user.label', defaultMessage: 'Member' });
    case UserRole.Observer:
      return $t({ id: 'userRole.observer.label', defaultMessage: 'Viewer' });
    default:
      return role || $t({ id: 'userRole.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getUserRoleOptions = () => [
  {
    label: getUserRoleDisplayName(UserRole.OrgAdmin),
    value: UserRole.OrgAdmin,
  },
  {
    label: getUserRoleDisplayName(UserRole.Member),
    value: UserRole.Member,
  },
  {
    label: getUserRoleDisplayName(UserRole.Observer),
    value: UserRole.Observer,
  },
];
