import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { FormItemContainer } from '../FormItemContainer';
import { CommonProps, LabelProps } from '../interfaces';

import styles from './Select.module.scss';

let globalId = 0;

type SelectProps = Omit<CommonProps<HTMLSelectElement>, 'onDebouncedChange'> &
  LabelProps & {
    options: VecticeHTMLProps<HTMLOptionElement>[];
  };

export const Select = React.forwardRef(
  (
    {
      borderless,
      className,
      disabled,
      error,
      gutterBottom,
      help,
      hint,
      label,
      name,
      options,
      autoFocus,
      ...props
    }: SelectProps,
    ref: React.Ref<HTMLSelectElement | null>,
  ) => {
    // eslint-disable-next-line no-plusplus
    const { current: selectId } = useRef(`${name || 'select'}-${globalId++}`);
    const innerRef = useRef<HTMLSelectElement>(null);
    useImperativeHandle(ref, () => innerRef.current);

    useEffect(() => {
      if (autoFocus && innerRef.current) {
        innerRef.current.focus();
      }
    }, [innerRef, autoFocus]);

    return (
      <FormItemContainer
        className={className}
        error={error}
        gutterBottom={gutterBottom}
        help={help}
        hint={hint}
        id={selectId}
        label={label}
      >
        <select ref={innerRef} id={selectId} name={name} className={styles.select} disabled={disabled} {...props}>
          {options.map(({ label: optionLabel, ...option }) => (
            <option key={`select-${selectId}-option-${option.value}`} {...option}>
              {optionLabel}
            </option>
          ))}
        </select>
      </FormItemContainer>
    );
  },
);
