import React from 'react';

import { getReviewStatusColor, getReviewStatusLabel } from '../../entities/utils';
import { ReviewStatus } from '../../gql/graphql';
import { Badge, BadgeColors } from '../../ui';
import { getReviewStatusIcon } from '../../utils';

interface ReviewBadgeProps {
  status?: ReviewStatus;
  contrast?: boolean;
}

export const ReviewBadge = ({ status, contrast = false }: ReviewBadgeProps) => (
  <Badge
    contrast={contrast}
    leftIcon={getReviewStatusIcon(status)}
    color={getReviewStatusColor(status) as BadgeColors}
    size="sm"
  >
    {getReviewStatusLabel(status)}
  </Badge>
);
