import { Buffer } from 'buffer';

import { useCallback, useState } from 'react';
import { DragStart, DragUpdate, DraggableLocation, DropResult } from 'react-beautiful-dnd';

export type DragContext = {
  status: string;
  isMulti: boolean;
  idsDragging: string[];
  draggableId?: string;
  droppableId?: string;
  draggable?: DragIdData;
  droppable?: DropIdData;
  source?: DraggableLocation;
  destination?: DraggableLocation;
};

export interface DragIdData extends DropIdData {
  draggableDataId: string;
}

export interface DropIdData {
  droppableDataId: string;
  type: string;
}

const initContext = (): DragContext => ({
  status: 'end',
  isMulti: false,
  idsDragging: [],
});

export const parseIdData = (id: string): DragIdData | DropIdData => {
  return JSON.parse(Buffer.from(id, 'base64').toString());
};

export const buildDroppableId = (data: DropIdData) => {
  return buildId(data);
};

export const buildDraggableId = (data: DragIdData) => {
  return buildId(data);
};

const buildId = (obj: object) => {
  return Buffer.from(JSON.stringify(obj, Object.keys(obj).sort())).toString('base64');
};

export const useDnd = (
  selectedIds: string[],
  events: {
    onBeforeEnd?: (result: DropResult) => Promise<void>;
    onAfterEnd?: (result: DropResult) => Promise<void>;
    onUpdate?: (context: DragContext) => void;
  },
) => {
  const [context, setContext] = useState<DragContext>(initContext());

  const buildBaseContext = (draggableId: string, selected: string[], source: DraggableLocation) => {
    const draggable = parseIdData(draggableId) as DragIdData;
    const isMulti = selected.length > 0 && selected.includes(draggableId);
    const idsDragging = isMulti ? [...selected] : [draggableId];
    return { draggable, isMulti, idsDragging, draggableId, source };
  };

  const onBeforeDragStart = useCallback(
    ({ draggableId, source }: DragStart) => {
      const base = buildBaseContext(draggableId, selectedIds, source);
      setContext({ status: 'started', ...base });
    },
    [selectedIds],
  );

  const onDragStart = useCallback(
    ({ draggableId, source }: DragStart) => {
      const base = buildBaseContext(draggableId, selectedIds, source);
      setContext({ status: 'started', ...base });
    },
    [selectedIds],
  );

  const onDragUpdate = useCallback(
    (result: DragUpdate) => {
      const destination = result.destination;

      const base = buildBaseContext(result.draggableId, selectedIds, result.source);
      if (!destination) {
        setContext({ ...context, status: 'started', ...base });
        return;
      }
      const { droppableId } = destination;
      const droppable = parseIdData(droppableId) as DropIdData;

      const newContext = {
        ...context,
        ...base,
        droppableId,
        draggableId: result.draggableId,
        destination,
        droppable,
        status: 'started',
      };
      events.onUpdate && events.onUpdate(newContext);
      setContext(newContext);
    },
    [context],
  );

  const onDragEnd = async (result: DropResult) => {
    try {
      events.onBeforeEnd && (await events.onBeforeEnd(result));
    } finally {
      setContext(initContext());
    }
    events.onAfterEnd && (await events.onAfterEnd(result));
  };

  const onCustomEvent = (context: DragContext) => setContext(context);

  return {
    context,
    onBeforeDragStart,
    onDragStart,
    onDragEnd,
    onDragUpdate,
    onCustomEvent,
  };
};
