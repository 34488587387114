import { graphql } from '../../gql';

export const UPDATE_USER = graphql(`
  mutation updateUser($data: UserUpdateInput!) {
    updateUser(data: $data) {
      ...userFields
      about
    }
  }
`);

export const UPDATE_USER_PREFERENCES = graphql(`
  mutation updateUserPreferences($data: UserPreferencesInput!) {
    updateUser(data: { preferences: $data }) {
      preferences {
        ...userPreferences
        onboardingStatus
      }
    }
  }
`);
