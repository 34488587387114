import React from 'react';

import LauncherGraphic from '../../assets/graphics/splash/launcher.svg';
import { Logo } from '../brand';
import { Navbar } from '../navbar';
import { Graphic } from '../svg';
import { Typography } from '../typography';

import styles from './Splashes.module.scss';

export const Launch = () => (
  <div className={styles.page}>
    <Navbar logo={<Logo aria-label="Vectice" />} />
    <div className={styles.container}>
      <Graphic src={LauncherGraphic} className={styles.graphic} aria-hidden />
      <Typography component="div" weight="semi-bold" align="center">
        Hang tight... getting ready to launch
      </Typography>
    </div>
  </div>
);
