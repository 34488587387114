import React from 'react';

import { IterationStepArtifactFragment, IterationStepArtifactType } from '../../gql/graphql';
import { FlexContainer, Typography } from '../../ui';
import { HintTooltip } from '../tooltip';

import { DroppableIterationStep } from './DroppableIterationStep';

interface IterationAssetsProps {
  artifacts: ({
    __typename?: 'IterationStepArtifact';
    id: number;
    type: IterationStepArtifactType;
    index: number;
  } & {
    ' $fragmentRefs'?: { IterationStepArtifactFragment: IterationStepArtifactFragment };
  })[];
  status: string;
  draggingId?: string;
  dividerIndex?: number;
  idsDragging: string[];
  readOnly?: boolean;
  onInternalCapture?: (dragId: string) => void;
}

export const IterationAssets = ({ artifacts, readOnly, ...props }: IterationAssetsProps) => {
  const summary = (
    <FlexContainer>
      <Typography variant="body" weight="semi-bold">
        {$t({
          id: 'IterationArtifacts.noSection',
          defaultMessage: 'Assets not assigned to section',
        })}
      </Typography>
      {!readOnly && (
        <HintTooltip
          hint={$t({
            id: 'IterationArtifacts.description',
            defaultMessage:
              'This default section cannot be edited or deleted. It will be hidden once you assign the assets to other sections.',
          })}
        />
      )}
    </FlexContainer>
  );

  const artifactsCount = artifacts?.length ?? 0;
  const dropIdData = { droppableDataId: 'none', type: 'asset' };

  return (
    <DroppableIterationStep
      artifacts={artifacts}
      artifactsCount={artifactsCount}
      readOnly={readOnly}
      dropIdData={dropIdData}
      isDragging={false}
      stepDragAllowed={false}
      summary={summary}
      {...props}
    />
  );
};
