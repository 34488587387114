import { $createHeadingNode } from '@lexical/rich-text';
import { $isAtNodeEnd } from '@lexical/selection';
import { $insertNodeToNearestRoot } from '@lexical/utils';
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isNodeSelection,
  $isParagraphNode,
  $isRangeSelection,
  $setSelection,
} from 'lexical';

import type { TableSelection } from '../plugins/lexical-table';
import type { BaseSelection, ElementNode, LexicalNode, NodeSelection, RangeSelection, TextNode } from 'lexical';

import {
  $createTableCellNode,
  $createTableNode,
  $createTableRowNode,
  $isTableNode,
  TableCellHeaderStates,
  TableNode,
} from '../plugins/lexical-table';

export const clearEmptyParagraph = (
  selection: BaseSelection | RangeSelection | NodeSelection | TableSelection | null,
) => {
  const nodes = selection?.getNodes();

  if (nodes && nodes.length === 1 && $isParagraphNode(nodes[0])) {
    const selectedParagraph = nodes[0];
    if (selectedParagraph.isEmpty() && selectedParagraph.getPreviousSibling()) {
      selectedParagraph.remove();
    }
  }
};

export const getSelectedNode = (selection: RangeSelection): TextNode | ElementNode => {
  const { anchor, focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }

  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
};

export const insertTableWithData = (header: string[], content: string[][]): TableNode => {
  const tableNode = $createTableNode();

  const rowNode = $createTableRowNode();
  rowNode.append(
    ...header.map((headerStr) => {
      const pNode = $createParagraphNode();
      const textNode = $createTextNode(headerStr);
      textNode.setFormat('bold');
      pNode.append(textNode);
      return $createTableCellNode(TableCellHeaderStates.NO_STATUS).append(pNode);
    }),
  );
  tableNode.append(rowNode);

  for (const cells of content) {
    const rowNode = $createTableRowNode();
    rowNode.append(
      ...cells.map((cellStr) => {
        const pNode = $createParagraphNode();
        const textNode = $createTextNode(cellStr ?? '-');
        pNode.append(textNode);
        return $createTableCellNode(TableCellHeaderStates.NO_STATUS).append(pNode);
      }),
    );
    tableNode.append(rowNode);
  }
  return tableNode;
};

export function $insertNodeNextToSelection<T extends LexicalNode>(nodeToInsert: T, selection: BaseSelection | null) {
  $setSelection(selection);
  if ($isNodeSelection(selection)) {
    const nodes = selection.getNodes();
    if (nodes.length > 0) {
      nodes[nodes.length - 1].insertAfter(nodeToInsert);
      return;
    }
  }
  if ($isRangeSelection(selection)) {
    clearEmptyParagraph(selection);
    const focusNode = selection.focus.getNode();
    if ($isTableNode(focusNode)) return focusNode.insertAfter(nodeToInsert);
  }
  return $insertNodeToNearestRoot(nodeToInsert);
}

export function $insertNodesNextToSelection<T extends LexicalNode>(nodesToInsert: T[]) {
  const selection = $getSelection();
  selection?.insertNodes(nodesToInsert);
}

export const isJSONString = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const isContentEmpty = (content: string | null | undefined): boolean => {
  if (!content) return true;
  if (isJSONString(content)) {
    const root = JSON.parse(content).root;
    return (
      root.children.length === 0 ||
      (root.children.length === 1 && root.children[0].type === 'paragraph' && root.children[0].children.length === 0)
    );
  } else {
    return content.trim().length === 0;
  }
};

export function $getRangeSelection() {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) return { selection: null, text: null };

  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();

  // Handle the case where the selection is a single point
  if (anchorNode.is(focusNode) && anchor.offset === focus.offset) {
    // Selection doesn't contain any text, bookmark cannot be created
    if (anchorNode.getTextContent().length === 0) return { selection: null, text: null };

    let bookmarkSelection: RangeSelection;
    // Selection contains text, creating a selection with the first character if the selection is at the beginning
    const anchorText = anchorNode.getTextContent();
    if (anchor.offset === 0) {
      bookmarkSelection = selection.clone();
      bookmarkSelection.focus.set(
        bookmarkSelection.focus.key,
        bookmarkSelection.anchor.offset + 1,
        bookmarkSelection.focus.type,
      );
      return { selection: bookmarkSelection, text: anchorText[0] };
    } else {
      // otherwise select the text behind the focus point
      bookmarkSelection = selection.clone();
      bookmarkSelection.focus.set(bookmarkSelection.focus.key, 0, bookmarkSelection.focus.type);
      return { selection: bookmarkSelection, text: anchorText.slice(0, anchor.offset) };
    }
  }

  const text = selection.getTextContent();

  // Selection doesn't contain any text, bookmark cannot be created
  if (text.length === 0) {
    return { selection: null, text: null };
  }

  return { selection, text };
}

export const addHeader = (title: string) => {
  const headingNode = $createHeadingNode('h3');
  headingNode.append($createTextNode(title));
  return headingNode;
};
