import { DatasetSource, DatasetSourceUsage } from '../../gql/graphql';

const getDataSource = <T extends Pick<DatasetSource, 'usage'>>(
  datasetSources: T[],
  dataSourceType: DatasetSourceUsage | null,
): T | undefined => datasetSources.find((source) => source.usage === dataSourceType);

export const getDataSourceSize = <T extends Pick<DatasetSource, 'usage' | 'size'>>(
  datasetSources: T[],
  dataSourceType: DatasetSourceUsage | null,
): T['size'] | undefined => getDataSource(datasetSources, dataSourceType)?.size;

export const getDataSourceFileCount = <
  T extends { usage?: DatasetSource['usage']; itemsCount?: DatasetSource['itemsCount'] },
>(
  datasetSources: T[],
  dataSourceType: DatasetSourceUsage | null,
): T['itemsCount'] | undefined => getDataSource(datasetSources, dataSourceType)?.itemsCount;

export const getDataSourceColumnCount = <
  T extends { usage?: DatasetSource['usage']; columnsCount?: DatasetSource['columnsCount'] },
>(
  datasetSources: T[],
  dataSourceType: DatasetSourceUsage | null,
): T['columnsCount'] | undefined => getDataSource(datasetSources, dataSourceType)?.columnsCount;

export const getDatasetSourceUsageName = (usage: DatasetSourceUsage | null) => {
  switch (usage) {
    case DatasetSourceUsage.Testing:
      return $t({ id: 'datasetSourceUsage.Testing', defaultMessage: 'Testing' });
    case DatasetSourceUsage.Training:
      return $t({ id: 'datasetSourceUsage.Training', defaultMessage: 'Training' });
    case DatasetSourceUsage.Validation:
      return $t({ id: 'datasetSourceUsage.Validation', defaultMessage: 'Validation' });
    default:
      return '';
  }
};
