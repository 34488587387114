import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { UPDATE_ITERATION_STEP_ARTIFACT } from '../../graphql/queries/updateIterationStepArtifact.mutation';
import { useVecticeForm } from '../../hooks';
import { message, ModalForm, TextArea } from '../../ui';
import { defaultDescriptionFieldConfiguration } from '../../utils';

export interface FormData {
  text: string;
}

interface EditIterationCommentModalProps {
  artifactId: number;
  text: string;
  onClose: () => void;
}

export const EditIterationCommentModal = ({ artifactId, text, onClose }: EditIterationCommentModalProps) => {
  const { formState, preSubmit, registerWithErrors } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      text,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updateIterationStepArtifact] = useMutation(UPDATE_ITERATION_STEP_ARTIFACT, {
    update: () => onClose(),
    onError: (error) => message.error(error.message),
  });

  const handleSubmit = useCallback(
    async ({ text }: FormData) => {
      await updateIterationStepArtifact({
        variables: {
          id: artifactId,
          data: { text },
        },
      });
    },
    [artifactId, updateIterationStepArtifact],
  );

  return (
    <ModalForm
      title={$t({ id: 'modal.EditIterationComment.title', defaultMessage: 'Edit note' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <TextArea
        {...registerWithErrors('text', {
          ...defaultDescriptionFieldConfiguration(),
          required: $t({ id: 'modal.EditIterationComment.note.required', defaultMessage: 'The note is required' }),
        })}
        label={$t({ id: 'modal.EditIterationComment.note', defaultMessage: 'Note' })}
        hint={null}
        required={true}
      />
    </ModalForm>
  );
};
