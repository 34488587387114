import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

export const NoModels = () => (
  <EmptyList
    icon={VecticeIcon}
    message={$t({ id: 'NoModels.noModels', defaultMessage: 'No Models yet!' })}
    hint={
      <>
        {$t({
          id: 'NoModels.hint',
          defaultMessage:
            'Every registered model (and its various versions) used in this Project will be visible here, along with their type and other information.',
        })}
        <br />
        {$t({
          id: 'NoModels.useCheatSheet',
          defaultMessage: 'Use the <api_cheat_sheet>Vectice API cheat sheet</api_cheat_sheet> to get started.',
        })}
      </>
    }
  />
);
