import cn from 'classnames';
import React from 'react';

import styles from './Main.module.scss';

interface MainProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'size'> {
  scroll?: boolean;
  fullscreen?: boolean;
}

export const Main = ({ scroll, fullscreen, className, ...props }: MainProps) => (
  <main
    className={cn(styles.main, { [styles.fullscreen]: fullscreen, [styles.scroll]: scroll }, className)}
    {...props}
  />
);
