import React, { useCallback } from 'react';

import { AttachmentOutput } from '../../gql/graphql';
import { useVecticeForm } from '../../hooks';
import { Input, ModalForm } from '../../ui';
import { defaultNameFieldConfiguration } from '../../utils';

import styles from './RenameAttachmentModal.module.scss';

export interface FormData {
  name: string;
}

interface RenameAttachmentModalProps {
  attachment: Pick<AttachmentOutput, 'id' | 'name'>;
  onClose: () => void;
  onConfirm: (newName: string) => void;
}

export const RenameAttachmentModal = ({ attachment, onClose, onConfirm }: RenameAttachmentModalProps) => {
  const splittedFilename = attachment.name!.split('.');
  const name = splittedFilename.length > 1 ? splittedFilename.slice(0, -1).join('.') : attachment.name!;
  const extension = splittedFilename.length > 1 ? `.${splittedFilename.pop()}` : null;

  const { formState, preSubmit, registerWithErrors } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const handleSubmit = useCallback(async ({ name }: FormData) => {
    onConfirm(name);
  }, []);

  return (
    <ModalForm
      title={$t({
        id: 'VersionAttachments.renameModal.title',
        defaultMessage: 'Rename {name}',
        values: { name: attachment.name ?? '' },
      })}
      submitLabel={$t({ id: 'modal.rename', defaultMessage: 'Rename' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <Input
        {...registerWithErrors('name', defaultNameFieldConfiguration())}
        label={$t({
          id: 'form.renameAttachement.nameLabel',
          defaultMessage: 'Name',
        })}
        required
        autoFocus
        className={styles.name}
        addonAfter={extension ? <div className={styles.extension}>{extension}</div> : null}
      />
    </ModalForm>
  );
};
