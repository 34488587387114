import React, { useEffect, useRef } from 'react';

import { MatchEnum } from '../../../gql/graphql';
import { NoProperties } from '../../no-results';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { EntitiesFilterComponent } from '../ui';
import { EntitiesProps } from '../ui/EntitiesFilterComponent';

export const pluginField = 'properties';

const options = [
  { id: MatchEnum.Contains, label: 'CONTAINS', value: MatchEnum.Contains },
  { id: MatchEnum.NotContains, label: 'CONTAINS NOT', value: MatchEnum.NotContains },
];

class PropertiesFilterNode<T extends EntitiesProps> extends FilterNode<T> {
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    if (this.props?.entitiesNames?.length === 0) return <NoProperties />;
    return (
      <EntitiesFilterComponent
        key={pluginField}
        {...this.props}
        matchOptions={options}
        defaultMatch={MatchEnum.Contains}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const PropertiesFilter = ({ entitiesNames }: EntitiesProps) => {
  const { current: node } = useRef(new PropertiesFilterNode<EntitiesProps>({ entitiesNames }));

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.properties.label', defaultMessage: 'Properties' }),
    node,
  });

  useEffect(() => {
    node.updateProps({ entitiesNames });
  }, [entitiesNames]);

  return null;
};
