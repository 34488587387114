import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { defaultActivityTypes, useUserActivities } from '../../hooks';
import { Section } from '../../ui';
import { getActivityTargetByResourceType, getActivityTargetFilterForPhase, VecticeResourceType } from '../../utils';
import { ActivityList } from '../activities';

export const PhaseActivities = () => {
  const { phaseId } = useParams();
  const { current: phaseIds } = useRef(phaseId ? [phaseId] : []);
  const { activities, hasNextPage, loading, handleShowMore } = useUserActivities({
    activityTypes: defaultActivityTypes,
    targetFilter: getActivityTargetFilterForPhase(phaseIds),
    activityTargetTypes: getActivityTargetByResourceType(VecticeResourceType.PHASE),
  });

  return (
    <Section label={$t({ id: 'phaseTabs.activity', defaultMessage: 'Phase activity' })}>
      <ActivityList activities={activities} hasMore={hasNextPage} loading={loading} onShowMore={handleShowMore} />
    </Section>
  );
};
