import { useQuery } from '@apollo/client';
import { useState } from 'react';

import { ActivityTargetFilter, ActivityTargetType, ActivityType } from '../gql/graphql';
import { GET_USER_ACTIVITY_LIST } from '../graphql/queries';

const PAGE_SIZE = 30;

export const defaultActivityTypes = [
  ActivityType.Created,
  ActivityType.Deleted,
  ActivityType.Duplicated,
  ActivityType.Moved,
  ActivityType.Restored,
  ActivityType.UpdatedProp,
];

interface UseUserActivitiesProps {
  activityTargetTypes?: ActivityTargetType[];
  activityTypes?: ActivityType[];
  contributors?: string[];
  datasetIds?: string[];
  modelIds?: string[];
  pageSize?: number;
  projectIds?: string[];
  reportIds?: string[];
  targetFilter?: ActivityTargetFilter;
  workspaceIdList?: string[];
  onlyMine?: boolean;
}

export const useUserActivities = (defaultFilters: UseUserActivitiesProps, skipQuery?: boolean) => {
  const [filters, setFilters] = useState<Record<string, any>>();

  const { data, loading, fetchMore } = useQuery(GET_USER_ACTIVITY_LIST, {
    skip: skipQuery,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      pageSize: PAGE_SIZE,
      activityTypes: defaultActivityTypes,
      ...defaultFilters,
      ...filters,
    },
  });

  const { page, total } = data?.getUserActivityList || {};
  const { hasNextPage, afterCursor } = page || {};

  const handleFiltersChange = (newValues: Record<string, any>) => {
    if (JSON.stringify(newValues) !== JSON.stringify(filters)) {
      setFilters(newValues);
    }
  };

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  };

  return {
    activities: data?.getUserActivityList,
    hasNextPage,
    loading,
    total,
    handleFiltersChange,
    handleShowMore,
  };
};
