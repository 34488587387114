import { graphql } from '../../gql';

export const CREATE_USER_INVITE = graphql(`
  mutation createUserInvite($data: UserInviteInput!) {
    createUserInvite(data: $data) {
      email
      status
      invite {
        inviteLinkToken
        email
      }
    }
  }
`);

export const CANCEL_INVITE = graphql(`
  mutation cancelUserInvite($id: Float!) {
    cancelUserInvite(id: $id)
  }
`);

export const RESEND_INVITE = graphql(`
  mutation resendUserInvite($id: Float!) {
    resendUserInvite(id: $id)
  }
`);
