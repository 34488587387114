import React from 'react';
import { SubmitHandler } from 'react-hook-form';

import { useValidateName, useVecticeForm } from '../../hooks';
import { Input, message, ModalForm, TextArea, WithAsyncValidation } from '../../ui';
import {
  VecticeResourceType,
  defaultDescriptionFieldConfiguration,
  defaultNameFieldConfiguration,
  getVecticeResourceTypeLabel,
} from '../../utils';

interface FormData {
  description?: string;
  name: string;
}

interface ResourceModalProps {
  loading?: boolean;
  edit?: boolean;
  defaultValues?: FormData;
  descriptionPlaceholder?: string;
  resourceType: VecticeResourceType;
  withDescription?: boolean;
  checkNameAvailability: (value: string) => Promise<boolean>;
  onClose: () => void;
  onSubmit: SubmitHandler<FormData>;
}

export const ResourceModal = ({
  loading,
  edit,
  defaultValues,
  descriptionPlaceholder,
  resourceType,
  withDescription,
  checkNameAvailability,
  onClose,
  onSubmit,
}: ResourceModalProps) => {
  const { validateName } = useValidateName(checkNameAvailability, resourceType);

  const resourceLabel = getVecticeResourceTypeLabel(resourceType);

  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues,
  });
  const { hasErrors, isDirty } = formState;

  const createResourceLabel = $t({
    id: 'modal.createResource.createResource',
    defaultMessage: 'Create {resourceType}',
    values: {
      resourceType: resourceLabel.toLocaleLowerCase(),
    },
  });

  const editResourceLabel = $t({
    id: 'modal.editResource.createResource',
    defaultMessage: 'Edit {resourceType}',
    values: {
      resourceType: resourceLabel.toLocaleLowerCase(),
    },
  });

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      await onSubmit(formData);
      onClose();
    } catch (error) {
      message.error(
        $t({
          id: 'modal.createResource.createFailure',
          defaultMessage: 'Oops, something went wrong! The {resourceType} could not be created.',
          values: { resourceType: resourceLabel },
        }),
      );
    }
  };

  return (
    <ModalForm
      disabled={!isDirty || hasErrors}
      isSubmitting={loading}
      title={edit ? editResourceLabel : createResourceLabel}
      submitLabel={edit ? editResourceLabel : createResourceLabel}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          autoFocus
          gutterBottom
          label={$t({
            id: 'modal.resource.name',
            defaultMessage: '{resource} name',
            values: {
              resource: resourceLabel,
            },
          })}
          placeholder={resourceLabel}
        />
      </WithAsyncValidation>
      {withDescription && (
        <TextArea
          {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
          gutterBottom
          label={$t({ id: 'modal.resource.description', defaultMessage: 'Description' })}
          hint={$t({ id: 'modal.resource.optional', defaultMessage: 'Optional' })}
          placeholder={descriptionPlaceholder}
        />
      )}
    </ModalForm>
  );
};
