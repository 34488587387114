import React, { useEffect, useState } from 'react';
import { RegisterOptions, SubmitHandler } from 'react-hook-form';

import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { useVecticeForm } from '../../hooks';
import { Button, FlexContainer, Form, Input, Typography } from '../../ui';

import { AssetDetails } from './AssetDetails';

import styles from './AssetInput.module.scss';

interface FormData {
  value?: string | null;
}

interface AssetInputProps {
  label: string;
  registerOptions?: RegisterOptions<FormData>;
  value?: string | null;
  onChange?: (value?: string | null) => any;
}

export const AssetInput = ({ label, registerOptions, value, onChange }: AssetInputProps) => {
  const [editing, setEditing] = useState(false);

  const { preSubmit, reset, registerWithErrors, formState } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: { value },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  useEffect(() => {
    reset({ value });
  }, [value]);

  const handleSubmit: SubmitHandler<FormData> = async ({ value }) => {
    await onChange?.(value);
    setEditing(false);
    reset({ value });
  };

  const handleCancel = () => {
    setEditing(false);
    reset({ value });
  };

  if (editing) {
    return (
      <AssetDetails
        className={styles.input}
        label={(ariaId) => (
          <Typography id={ariaId} color="tertiary" variant="footnote">
            {label}
          </Typography>
        )}
      >
        <FlexContainer component={Form} direction="column" gap={4} onSubmit={preSubmit(handleSubmit)}>
          <Input {...registerWithErrors('value', registerOptions)} aria-label={label} hint={null} />
          <FlexContainer align="center" justify="flex-end" gap={0}>
            <Button size="xs" variant="phantom" onClick={handleCancel}>
              {$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
            </Button>
            <Button size="xs" disabled={hasErrors || !isDirty} loading={isSubmitting} type="submit">
              {$t({ id: 'button.save', defaultMessage: 'Save' })}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </AssetDetails>
    );
  }

  return (
    <AssetDetails
      className={styles.input}
      label={
        !!onChange
          ? (ariaId) => (
              <FlexContainer justify="space-between">
                <Typography id={ariaId} color="tertiary" variant="footnote">
                  {label}
                </Typography>
                <Button
                  aria-label={$t({ id: 'button.edit', defaultMessage: 'Edit' })}
                  leftIcon={EditIcon}
                  size="xs"
                  color="gray"
                  variant="phantom"
                  onClick={() => setEditing(true)}
                />
              </FlexContainer>
            )
          : (ariaId) => (
              <Typography id={ariaId} color="tertiary" variant="footnote">
                {label}
              </Typography>
            )
      }
    >
      {value ? (
        <Typography variant="callout" color="primary" paragraph>
          {value}
        </Typography>
      ) : (
        <Typography variant="callout" color="disabled" paragraph>
          {$t({ id: 'global.N/A', defaultMessage: 'N/A' })}
        </Typography>
      )}
    </AssetDetails>
  );
};
