import React from 'react';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/flat/ic-project.svg';
import { Card, FlexContainer, Icon, Skeleton } from '../../../ui';

import styles from './ProjectCard.module.scss';

export const ProjectCardSkeleton = () => (
  <Card className={styles.skeleton} inert>
    <FlexContainer>
      <Icon className={styles.icon} icon={ProjectIcon} size={20} />
      <FlexContainer direction="column">
        <Skeleton.Paragraph width="80px" />
        <Skeleton.Paragraph width="120px" />
      </FlexContainer>
    </FlexContainer>
  </Card>
);
