import { useMutation } from '@apollo/client';

import { UPDATE_LINEAGE } from '../graphql/mutations';
import { message } from '../ui';
import { VecticeResourceType } from '../utils';

export const useEditLineage = (type: VecticeResourceType) => {
  const [editLineage, { loading: updating }] = useMutation(UPDATE_LINEAGE, {
    refetchQueries:
      type === VecticeResourceType.DATASET
        ? ['getJobArtifactByJobRunId', 'getDatasetVersionWithOrigin']
        : ['getJobArtifactByJobRunId', 'getModelVersionOverview'],
    onError: (error) => message.error(error.message),
    onCompleted: () =>
      message.success($t({ id: 'component.editLineage.edit.success', defaultMessage: 'Lineage was updated' })),
  });

  return { updating, editLineage };
};
