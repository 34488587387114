import cn from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as InviteUsersIcon } from '../../../assets/icons/menu/ic-single-neutral-actions-add.svg';
import { useOrgConfig } from '../../../context';
import { UserRole } from '../../../gql/graphql';
import { Allowed, Fallback, WithRoles } from '../../../guards';
import { Button, RestrictedAccessTooltip } from '../../../ui';
import { InviteModal } from '../../invite-user';

import styles from './InviteButton.module.scss';

export const InviteButton = () => {
  const [showModal, setShowModal] = useState(false);

  const {
    configuration: { everyOneCanInviteUser },
  } = useOrgConfig();

  return (
    <>
      <WithRoles organizationRole={UserRole.OrgAdmin}>
        <Allowed>
          <Button leftIcon={InviteUsersIcon} size="sm" onClick={() => setShowModal(true)}>
            {$t({ id: 'navbar.invite', defaultMessage: 'Invite' })}
          </Button>
        </Allowed>
        <Fallback>
          <RestrictedAccessTooltip
            restricted={!everyOneCanInviteUser}
            message={$t({
              id: 'restricted.everyOneCanInvite',
              defaultMessage: 'Contact your Org admin to invite users',
            })}
          >
            <Button
              leftIcon={InviteUsersIcon}
              className={cn({ [styles.disabled]: !everyOneCanInviteUser })}
              disabled={!everyOneCanInviteUser}
              onClick={() => setShowModal(true)}
              size="sm"
            >
              {$t({ id: 'navbar.invite', defaultMessage: 'Invite' })}
            </Button>
          </RestrictedAccessTooltip>
        </Fallback>
      </WithRoles>
      {showModal && <InviteModal onClose={() => setShowModal(false)} />}
    </>
  );
};
