import React from 'react';

import { UserAndInvites, UserAndInvitesType } from '../../../gql/graphql';

import { InviteCell } from './InviteCell';
import { UserCell } from './UserCell';

export const InviteOrUserCell = ({ record, hideTooltip }: { record: UserAndInvites; hideTooltip?: boolean }) => {
  if (record.type === UserAndInvitesType.User) {
    return <UserCell user={record.user?.[0]} hideTooltip={hideTooltip} />;
  }

  if (record.type === UserAndInvitesType.UserInvite) {
    return <InviteCell email={record.userInvite?.[0].email} />;
  }

  return null;
};
