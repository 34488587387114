import React from 'react';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/ic-project.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { ProjectTooltip } from './ProjectTooltip';

interface ProjectLinkProps {
  name?: string | null;
  resourceId?: string | null;
}

export const ProjectLink = ({ name, resourceId }: ProjectLinkProps) => (
  <ProjectTooltip id={resourceId} placement="topLeft">
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.PROJECT, { projectId: resourceId }) : undefined}
      icon={ProjectIcon}
    />
  </ProjectTooltip>
);
