import React, { useMemo } from 'react';

import { NotificationWithFragments } from '../types';
import {
  isAccessRequestApprovedNotification,
  isAccessRequestRejectedNotification,
  isInviteAcceptedNotification,
  isMentionedNotification,
  isNewWorkspaceAccessRequestNotification,
  isOwnUserTypeChangedNotification,
  isPhaseOwnerChanged,
  isProjectDeleted,
  isProjectOwnerChanged,
  isProjectOwnershipTransferred,
  isProjectStatusChanged,
  isQuickstartProjectCreated,
  isReviewApprovedNotification,
  isReviewCommentAddedNotification,
  isReviewRejectedNotification,
  isReviewRequestedNotification,
  isThreadCreated,
  isThreadReplied,
  isThreadResolved,
  isThreadDeleted,
  isUserAddedToWorkspaceNotification,
  isUserAddedToWorkspaceYouOwnNotification,
  isUserAskedForUpgradeNotification,
  isUserJoinedOrganizationNotification,
  isUserMentionedInThread,
  isWorkspaceDeleted,
  isWorkspaceInviteNotification,
  isAdminNotifWorkspaceRoleChangedNotification,
  isUserRemovedFromWorkspaceNotification,
  isFindingReviewerChange,
  isFindingStatusChange,
  isFindingOwnerChange,
} from '../utils';

import { AccessRequestApprovedNotification } from './AccessRequestApprovedNotification';
import { AccessRequestRejectedNotification } from './AccessRequestRejectedNotification';
import { AdminNotifWorkspaceRoleChangedNotification } from './AdminNotifWorkspaceRoleChangedNotification';
import { FindingOwnerChangeNotification } from './FindingOwnerChangeNotification';
import { FindingReviewerChangeNotification } from './FindingReviewerChangeNotification';
import { FindingStatusChangeNotification } from './FindingStatusChangeNotification';
import { InviteAcceptedNotification } from './InviteAcceptedNotification';
import { MentionedInThreadNotification } from './MentionedInThreadNotification';
import { MentionNotification } from './MentionNotification';
import { NewWorkspaceAccessRequestNotification } from './NewWorkspaceAccessRequestNotification';
import { OwnUserTypeChangedNotification } from './OwnUserTypeChangedNotification';
import { PhaseOwnerChangedNotification } from './PhaseOwnerChangedNotification';
import { ProjectDeletedNotification } from './ProjectDeletedNotification';
import { ProjectOwnerChangedNotification } from './ProjectOwnerChangedNotification';
import { ProjectOwnershipTransferredNotification } from './ProjectOwnershipTransferredNotification';
import { ProjectStatusChangedNotification } from './ProjectStatusChangedNotification';
import { QuickstartProjectCreated } from './QuickstartProjectCreated';
import { ReviewNotification } from './ReviewNotification';
import { ThreadCreatedNotification } from './ThreadCreatedNotification';
import { ThreadDeletedNotification } from './ThreadDeletedNotification';
import { ThreadRepliedNotification } from './ThreadRepliedNotification';
import { ThreadResolvedNotification } from './ThreadResolvedNotification';
import { UserAddedToWorkspaceNotification } from './UserAddedToWorkspaceNotification';
import { UserAskedForUpgradeNotification } from './UserAskedForUpgradeNotification';
import { UserJoinedOrganizationNotification } from './UserJoinedOrganizationNotification';
import { UserRemovedFromWorkspaceNotification } from './UserRemovedFromWorkspaceNotification';
import { WorkspaceDeletedNotification } from './WorkspaceDeletedNotification';
import { WorkspaceInviteNotification } from './WorkspaceInviteNotification';

interface NotificationFactoryProps {
  notification: NotificationWithFragments;
  onClose: () => void;
}

export const NotificationFactory = ({ notification, ...props }: NotificationFactoryProps) =>
  useMemo(() => {
    if (isWorkspaceInviteNotification(notification)) {
      return <WorkspaceInviteNotification notification={notification} {...props} />;
    }

    if (isUserJoinedOrganizationNotification(notification)) {
      return <UserJoinedOrganizationNotification notification={notification} {...props} />;
    }

    if (isMentionedNotification(notification)) {
      return <MentionNotification notification={notification} {...props} />;
    }

    if (isInviteAcceptedNotification(notification)) {
      return <InviteAcceptedNotification notification={notification} {...props} />;
    }

    if (isNewWorkspaceAccessRequestNotification(notification)) {
      return <NewWorkspaceAccessRequestNotification notification={notification} {...props} />;
    }

    if (isAccessRequestApprovedNotification(notification)) {
      return <AccessRequestApprovedNotification notification={notification} {...props} />;
    }

    if (isAccessRequestRejectedNotification(notification)) {
      return <AccessRequestRejectedNotification notification={notification} {...props} />;
    }

    if (isPhaseOwnerChanged(notification)) {
      return <PhaseOwnerChangedNotification notification={notification} {...props} />;
    }

    if (isQuickstartProjectCreated(notification)) {
      return <QuickstartProjectCreated notification={notification} {...props} />;
    }

    if (isProjectOwnerChanged(notification)) {
      return <ProjectOwnerChangedNotification notification={notification} {...props} />;
    }

    if (isProjectStatusChanged(notification)) {
      return <ProjectStatusChangedNotification notification={notification} {...props} />;
    }

    if (isProjectOwnershipTransferred(notification)) {
      return <ProjectOwnershipTransferredNotification notification={notification} {...props} />;
    }

    if (isProjectDeleted(notification)) {
      return <ProjectDeletedNotification notification={notification} {...props} />;
    }

    if (isWorkspaceDeleted(notification)) {
      return <WorkspaceDeletedNotification notification={notification} {...props} />;
    }

    if (
      isReviewRequestedNotification(notification) ||
      isReviewApprovedNotification(notification) ||
      isReviewRejectedNotification(notification) ||
      isReviewCommentAddedNotification(notification)
    ) {
      return <ReviewNotification notification={notification} {...props} />;
    }

    if (isUserAddedToWorkspaceNotification(notification) || isUserAddedToWorkspaceYouOwnNotification(notification)) {
      return <UserAddedToWorkspaceNotification notification={notification} {...props} />;
    }

    if (isUserRemovedFromWorkspaceNotification(notification)) {
      return <UserRemovedFromWorkspaceNotification notification={notification} {...props} />;
    }

    if (isThreadCreated(notification)) {
      return <ThreadCreatedNotification notification={notification} {...props} />;
    }

    if (isThreadReplied(notification)) {
      return <ThreadRepliedNotification notification={notification} {...props} />;
    }

    if (isThreadResolved(notification)) {
      return <ThreadResolvedNotification notification={notification} {...props} />;
    }

    if (isThreadDeleted(notification)) {
      return <ThreadDeletedNotification notification={notification} {...props} />;
    }

    if (isUserMentionedInThread(notification)) {
      return <MentionedInThreadNotification notification={notification} {...props} />;
    }

    if (isUserAskedForUpgradeNotification(notification)) {
      return <UserAskedForUpgradeNotification notification={notification} {...props} />;
    }

    if (isOwnUserTypeChangedNotification(notification)) {
      return <OwnUserTypeChangedNotification notification={notification} {...props} />;
    }

    if (isAdminNotifWorkspaceRoleChangedNotification(notification)) {
      return <AdminNotifWorkspaceRoleChangedNotification notification={notification} {...props} />;
    }

    if (isFindingOwnerChange(notification)) {
      return <FindingOwnerChangeNotification notification={notification} {...props} />;
    }

    if (isFindingReviewerChange(notification)) {
      return <FindingReviewerChangeNotification notification={notification} {...props} />;
    }

    if (isFindingStatusChange(notification)) {
      return <FindingStatusChangeNotification notification={notification} {...props} />;
    }

    return null;
  }, [notification, props]);
