import cn from 'classnames';
import React from 'react';

import { ReactComponent as CheckIcon } from '../../assets/icons/interface/ic-check-circle.svg';
import { Checkbox } from '../form';
import { MenuItem } from '../menu';
import { Icon } from '../svg';
import { Typography } from '../typography';

import { FilterOptionProps } from './interfaces';

import styles from './FilterOption.module.scss';

export const FilterSingleOption = ({ icon, label, selected, value, onClick }: FilterOptionProps) => (
  <MenuItem
    key={`option-${value}`}
    className={cn(styles.option, styles.single, { [styles.selected]: selected })}
    clickable
    onClick={() => {
      if (onClick) onClick(value);
    }}
  >
    {icon}
    {!icon && selected && <Icon className={styles.icon} icon={CheckIcon} size={18} />}
    <Typography variant="callout" ellipsis className={styles.label}>
      {label}
    </Typography>
  </MenuItem>
);

export const FilterMultipleOption = ({ icon, label, value }: FilterOptionProps) => (
  <Checkbox
    label={
      <>
        {icon}
        <Typography variant="callout" ellipsis className={styles.label}>
          {label}
        </Typography>
      </>
    }
    className={cn(styles.option, styles.multiple, { [styles.withIcon]: !!icon })}
    value={value}
  />
);
