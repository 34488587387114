import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { ReactComponent as ChevronDownIcon } from '../../assets/icons/interface/ic-chevron-down.svg';
import { Button, ButtonProps } from '../button/Button';
import { Typography } from '../typography';

import styles from './DropdownTrigger.module.scss';

export const DropdownTrigger = ({ children, className, disabled, ...props }: PropsWithChildren<ButtonProps>) => (
  <Button
    variant="white"
    rightIcon={!disabled ? ChevronDownIcon : undefined}
    disabled={disabled}
    className={cn(styles.trigger, className)}
    {...props}
  >
    <Typography className={styles.content} ellipsis>
      {children}
    </Typography>
  </Button>
);
