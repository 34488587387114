import React, { useState } from 'react';

import { Section, Tab, Tabs, Typography } from '../../../ui';
import { HintTooltip } from '../../tooltip';

import { IterationsTab } from './IterationsTab';
import { PendingReviewsTab } from './PendingReviewsTab';
import { PhasesTab } from './PhasesTab';
import { ReportsTab } from './ReportsTab';

import styles from './HomeTabs.module.scss';

enum HomeTab {
  phases,
  iterations,
  reviews,
  reports,
}

export const HomeTabs = () => {
  const [activeTab, setActiveTab] = useState(HomeTab.phases);

  return (
    <Section
      label={(ariaId) => (
        <Typography id={ariaId} component="h3" variant="body" weight="semi-bold">
          {$t({ id: 'home.tabs.title', defaultMessage: 'Your work' })}
        </Typography>
      )}
    >
      <Tabs className={styles.tabs}>
        <Tab
          active={activeTab === HomeTab.phases}
          onClick={() => setActiveTab(HomeTab.phases)}
          extra={
            <HintTooltip
              hint={$t({
                id: 'home.tabs.phases.hint',
                defaultMessage: 'All the Phases you are an owner of that aren’t complete',
              })}
            />
          }
          hideDot
        >
          {$t({ id: 'home.tabs.phases', defaultMessage: 'Phases' })}
        </Tab>
        <Tab
          active={activeTab === HomeTab.iterations}
          onClick={() => setActiveTab(HomeTab.iterations)}
          extra={
            <HintTooltip
              hint={$t({
                id: 'home.tabs.iterations.hint',
                defaultMessage: 'All the Iterations you’re an owner of',
              })}
            />
          }
          hideDot
        >
          {$t({ id: 'home.tabs.iterations', defaultMessage: 'Iterations' })}
        </Tab>
        <Tab
          active={activeTab === HomeTab.reviews}
          onClick={() => setActiveTab(HomeTab.reviews)}
          extra={
            <HintTooltip
              hint={$t({
                id: 'home.tabs.reviews.hint',
                defaultMessage: 'All pending requests for you to review',
              })}
            />
          }
          hideDot
        >
          {$t({ id: 'home.tabs.reviews', defaultMessage: 'Pending Reviews' })}
        </Tab>
        <Tab
          active={activeTab === HomeTab.reports}
          onClick={() => setActiveTab(HomeTab.reports)}
          extra={
            <HintTooltip
              hint={$t({
                id: 'home.tabs.reports.hint',
                defaultMessage: 'All reports you have worked on in the last 30 days',
              })}
            />
          }
          hideDot
        >
          {$t({ id: 'home.tabs.reports', defaultMessage: 'Reports' })}
        </Tab>
      </Tabs>
      {activeTab === HomeTab.phases && <PhasesTab />}
      {activeTab === HomeTab.iterations && <IterationsTab />}
      {activeTab === HomeTab.reviews && <PendingReviewsTab />}
      {activeTab === HomeTab.reports && <ReportsTab />}
    </Section>
  );
};
