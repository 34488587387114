import { DropdownProps } from 'rc-dropdown/lib/Dropdown';
import React, { ReactElement } from 'react';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu/ic-navigation-menu-horizontal.svg';
import { Dropdown } from '../dropdown';

import { Button, ButtonProps } from './Button';

interface MoreActionsProps extends ButtonProps {
  dropdownProps?: Omit<DropdownProps, 'children'>;
  moreActionsMenu: ReactElement;
}

export const MoreActions = ({ dropdownProps, moreActionsMenu, size = 'sm', ...props }: MoreActionsProps) => (
  <Dropdown overlay={moreActionsMenu} placement="bottomRight" trigger="click" {...dropdownProps}>
    <Button aria-label="options" color="gray" leftIcon={MenuIcon} size={size} variant="phantom" {...props} />
  </Dropdown>
);
