import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import type { SelectedPhaseModal } from '../phase';

import { IterationsCounts, Phase, ReviewsCounts } from '../../gql/graphql';
import { buildLink, isIterationRoute, VecticeRoutes } from '../../routes';
import { MoreActions, Summary, Tooltip, Typography } from '../../ui';
import { DeletePhaseModal, DuplicatePhaseModal, EditPhaseModal } from '../modals';
import { PhaseMenu } from '../phase';

import styles from './PhaseNavigationItem.module.scss';

interface PhaseNavigationItemProps {
  currentPageId?: string;
  phase: Pick<Phase, 'vecticeId' | 'name' | 'description' | 'status'>;
  iterationsCount: Pick<IterationsCounts, 'total'>;
  reviewsCount: Pick<ReviewsCounts, 'totalReviews'>;
  projectId: string;
  canEdit?: boolean;
}

export const PhaseNavigationItem = ({
  currentPageId,
  phase,
  iterationsCount,
  reviewsCount,
  projectId,
  canEdit,
}: PhaseNavigationItemProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openedModal, setOpenedModal] = useState<SelectedPhaseModal>(null);

  useEffect(() => {
    setOpen(currentPageId === phase.vecticeId);
  }, [currentPageId, phase.vecticeId]);

  const labelId = `phase-row-${phase.vecticeId}`;

  const summary = (
    <Tooltip text={phase.name}>
      <Typography
        aria-labelledby={labelId}
        className={styles.link_content}
        component={Link}
        color="primary"
        ellipsis
        to={buildLink(VecticeRoutes.PHASE, { phaseId: phase.vecticeId })}
      >
        <Typography component="div" ellipsis id={labelId} variant="callout" weight="semi-bold">
          {phase.name}
        </Typography>
        {canEdit && (
          <MoreActions
            className={styles.menu_trigger}
            dropdownProps={{
              onOverlayClick: (e) => e.stopPropagation(),
            }}
            moreActionsMenu={<PhaseMenu onOpenModal={setOpenedModal} />}
          />
        )}
      </Typography>
    </Tooltip>
  );

  return (
    <>
      <Summary
        className={styles.details}
        detailsClassName={styles.links}
        summary={summary}
        summaryClassName={styles.summary}
        open={open}
        onToggle={setOpen}
      >
        <Typography
          component={NavLink}
          to={buildLink(VecticeRoutes.PHASE_DOCUMENTATION, { phaseId: phase.vecticeId })}
          variant="callout"
          className={styles.link}
          end
        >
          {$t({ id: 'phase.tabs.documentation', defaultMessage: 'Documentation' })}
        </Typography>
        <Typography
          component={NavLink}
          to={buildLink(VecticeRoutes.PHASE_ITERATIONS, { phaseId: phase.vecticeId })}
          variant="callout"
          className={cn(styles.link, { active: isIterationRoute(location.pathname) })}
        >
          {$t({ id: 'phase.tabs.iterations', defaultMessage: 'Iterations' })}
          {' · '}
          {iterationsCount.total}
        </Typography>
        <Typography
          component={NavLink}
          to={buildLink(VecticeRoutes.PHASE_REVIEWS, { phaseId: phase.vecticeId })}
          variant="callout"
          className={styles.link}
        >
          {$t({ id: 'phase.tabs.reviews', defaultMessage: 'Reviews' })}
          {' · '}
          {reviewsCount.totalReviews}
        </Typography>
        <Typography
          component={NavLink}
          to={buildLink(VecticeRoutes.PHASE_SETTINGS, { phaseId: phase.vecticeId })}
          variant="callout"
          className={styles.link}
          end
        >
          {$t({ id: 'phase.tabs.settings', defaultMessage: 'Phase settings' })}
        </Typography>
      </Summary>

      {openedModal === 'edit' && (
        <EditPhaseModal phase={phase} projectId={projectId} onClose={() => setOpenedModal(null)} />
      )}

      {openedModal === 'duplicate' && (
        <DuplicatePhaseModal phase={phase} projectId={projectId} onClose={() => setOpenedModal(null)} />
      )}

      {openedModal === 'delete' && phase && (
        <DeletePhaseModal phase={phase} projectId={projectId} onClose={() => setOpenedModal(null)} />
      )}
    </>
  );
};
