import cn from 'classnames';
import React, { ElementType, PropsWithChildren } from 'react';

import { Loading } from '../loading-animation';
import { Icon } from '../svg';

import { ButtonVariants, ButtonColors, ButtonSizes } from './types';

import styles from './Button.module.scss';

const getIconSize = (size: ButtonSizes) => {
  if (size === 'xxs' || size === 'xs') {
    return 18;
  }

  return 20;
};

export interface ButtonProps extends Omit<VecticeHTMLProps<HTMLButtonElement>, 'size'> {
  variant?: ButtonVariants;
  color?: ButtonColors;
  size?: ButtonSizes;
  iconSize?: number;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  leftIcon?: ElementType;
  rightIcon?: ElementType;
  loading?: boolean;
  disabled?: boolean;
  regular?: boolean;
}

export const Button = React.forwardRef(
  (
    {
      variant = 'primary',
      color = 'accent',
      size = 'sm',
      iconSize,
      type = 'button',
      fullWidth,
      rightIcon,
      leftIcon,
      className,
      children,
      loading,
      disabled,
      regular,
      ...props
    }: PropsWithChildren<ButtonProps>,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <button
      type={type}
      className={cn(
        styles.button,
        styles[variant],
        styles[color],
        styles[size],
        {
          [styles.fullWidth]: fullWidth,
          [styles.regular]: regular,
          [styles.withChildren]: children,
        },
        className,
      )}
      disabled={loading || disabled}
      {...props}
      ref={ref}
    >
      {leftIcon && !loading && (
        <Icon icon={leftIcon} size={iconSize ?? getIconSize(size)} className={styles.leftIcon} />
      )}
      {loading && <Loading size={iconSize ?? getIconSize(size)} color="inherit" className={styles.leftIcon} />}
      {children}
      {children && rightIcon && (
        <Icon icon={rightIcon} size={iconSize ?? getIconSize(size)} className={styles.rightIcon} />
      )}
    </button>
  ),
);
