import cn from 'classnames';
import React, { ElementType, HTMLProps, ReactElement, useRef } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LinkIcon } from '../../../assets/icons/interface/ic-link.svg';
import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/ic-dataset-version.svg';
import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/ic-model-version.svg';
import { ArtifactType } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FlexContainer, Icon, Typography } from '../../../ui';
import { VersionBadge } from '../../badges';
import { IOArtifact } from '../interfaces';

import styles from './LineageAsset.module.scss';

interface LineageAssetProps {
  assetArtifact?: IOArtifact;
  currentArtifact?: {
    artifactType: ArtifactType;
    dataSetVersionId?: string;
    modelVersionId?: string;
  };
  file?: ReactElement;
  linkTarget?: HTMLProps<HTMLLinkElement>['target'];
}

let globalId = 0;

export const LineageAsset = ({ assetArtifact, currentArtifact, file, linkTarget = '_self' }: LineageAssetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`lineage-asset-${globalId++}`);
  const artifactType = assetArtifact?.artifactType;
  const dataSetVersion = assetArtifact?.dataSetVersion;
  const modelVersion = assetArtifact?.modelVersion;

  const isCurrentArtifact =
    artifactType === currentArtifact?.artifactType &&
    ((currentArtifact?.dataSetVersionId && currentArtifact.dataSetVersionId === dataSetVersion?.vecticeId) ||
      (currentArtifact?.modelVersionId && currentArtifact.modelVersionId === modelVersion?.vecticeId));

  const name = dataSetVersion?.dataSet?.name ?? modelVersion?.model?.name ?? '-';
  const versionNumber = dataSetVersion?.versionNumber ?? modelVersion?.versionNumber;
  const icon = artifactType === ArtifactType.Dataset ? DatasetVersionIcon : ModelVersionIcon;
  const routePath =
    artifactType === ArtifactType.Dataset
      ? buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: dataSetVersion?.vecticeId })
      : buildLink(VecticeRoutes.MODEL_VERSION, { modelVersionId: modelVersion?.vecticeId });

  const Component = isCurrentArtifact ? ('div' as ElementType) : Link;

  const linkProps = isCurrentArtifact
    ? {}
    : {
        to: routePath,
        target: linkTarget,
      };

  return (
    <Component className={cn('hover-reveal-parent', styles.lineageAsset)} {...linkProps} aria-labelledby={ariaId}>
      <FlexContainer className={styles.content} gap={4}>
        <Icon icon={icon} size={20} />
        <Typography id={ariaId} component="div" variant="callout" color="secondary" ellipsis>
          {name} <VersionBadge versionNumber={versionNumber} />
        </Typography>
      </FlexContainer>
      <FlexContainer align="center" className={styles.currentLabel}>
        {!!file && <>{file}</>}
        {isCurrentArtifact && (
          <Typography paragraph variant="callout" color="tertiary">
            {artifactType === ArtifactType.Dataset
              ? $t({ id: 'components.lineage.thisDatasetVersion', defaultMessage: 'This Dataset version' })
              : $t({ id: 'components.lineage.thisModelVersion', defaultMessage: 'This Model version' })}
          </Typography>
        )}
      </FlexContainer>
      {!isCurrentArtifact && (
        <FlexContainer className="hover-reveal-child">
          <Typography variant="callout" weight="semi-bold" color="accent">
            {$t({ id: 'lineage.viewLink', defaultMessage: 'View' })}
          </Typography>
          <Icon color="accent" icon={LinkIcon} size={20} />
        </FlexContainer>
      )}
    </Component>
  );
};
