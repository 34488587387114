import { graphql } from '../../gql';

export const TRANSFER_PROJECT_OWNERSHIP = graphql(`
  mutation transferProjectOwnership($projectId: VecticeId!, $data: ProjectUpdateInput!) {
    updateProject(projectId: $projectId, data: $data) {
      vecticeId
      ownerId
      owner {
        ...userFields
      }
    }
  }
`);
