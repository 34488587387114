import React from 'react';

import { getModelVersionRiskColor, getModelVersionRiskDisplayName } from '../../entities/utils';
import { ModelVersionRisk } from '../../gql/graphql';
import { Badge, BadgeProps } from '../../ui';

interface ModelVersionRiskBadgeProps extends BadgeProps {
  risk?: ModelVersionRisk | null;
}

export const ModelVersionRiskBadge = ({
  size = 'sm',
  contrast = false,
  risk,
  ...props
}: ModelVersionRiskBadgeProps) => (
  <Badge size={size} contrast={contrast} color={getModelVersionRiskColor(risk)} {...props}>
    {getModelVersionRiskDisplayName(risk)}
  </Badge>
);
