import { useMutation } from '@apollo/client';

import { Phase, StepDefinition } from '../gql/graphql';
import { REMOVE_STEP, UPDATE_STEP } from '../graphql/queries';
import { flushFromGraphQLCache } from '../graphql/utils';

export const useStep = (
  stepDefinition: Pick<StepDefinition, 'id' | 'slug' | 'name' | 'description'> & { parent: Pick<Phase, 'vecticeId'> },
) => {
  const [updateStep] = useMutation(UPDATE_STEP, {
    optimisticResponse: ({ updateModel }) => ({
      updateStepDefinition: {
        ...stepDefinition,
        name: updateModel?.name ?? '',
        description: updateModel?.description,
      },
    }),
  });

  const [deleteStep] = useMutation(REMOVE_STEP, {
    variables: { id: stepDefinition.id },
    update: (cache, { data: updatedData }) => {
      if (updatedData) {
        flushFromGraphQLCache(cache, { id: updatedData.removeStepDefinition, __typename: 'StepDefinition' });
      }
      cache.modify({
        id: cache.identify({ vecticeId: stepDefinition.parent.vecticeId, __typename: 'Phase' }),
        fields: {
          stepsCount(cachedData) {
            return Math.max(cachedData - 1, 0);
          },
        },
      });
    },
  });

  return {
    updateStep,
    deleteStep,
  };
};
