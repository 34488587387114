import React, { ElementType, ReactElement, ReactNode } from 'react';

import { Button } from '../button';

import { Dialog, DialogContent, DialogActions, DialogProps } from './internals';

import styles from './internals/Dialog.module.scss';

interface ModalPromptProps extends DialogProps {
  cancelLabel: ReactNode;
  confirmIcon?: ElementType;
  confirmLabel: ReactNode;
  contentClassName?: string;
  destructive?: boolean;
  disabled?: boolean;
  extraAction?: ReactElement;
  headerInfo?: ReactNode;
  previousLabel?: ReactNode;
  processing?: boolean;
  onConfirm: () => void;
  onPrevious?: () => void;
}

export const ModalPrompt = ({
  cancelLabel,
  children,
  confirmIcon,
  confirmLabel,
  contentClassName,
  destructive,
  disabled,
  extraAction,
  headerInfo,
  previousLabel,
  processing,
  withBackground,
  onClose,
  onConfirm,
  onPrevious,
  ...props
}: ModalPromptProps) => (
  <Dialog onClose={onClose} {...props}>
    {headerInfo}
    <DialogContent withBackground={withBackground} className={contentClassName}>
      {children}
    </DialogContent>
    <DialogActions>
      <Button disabled={processing} variant="phantom" onClick={onClose} className={styles.dialog_cancel}>
        {cancelLabel}
      </Button>
      {onPrevious && (
        <Button disabled={processing} variant="phantom" onClick={onPrevious}>
          {previousLabel}
        </Button>
      )}
      {extraAction}
      <Button
        leftIcon={confirmIcon}
        loading={processing}
        disabled={disabled}
        onClick={onConfirm}
        color={destructive ? 'danger' : 'accent'}
      >
        {confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);
