import React from 'react';

import { GetPhaseListForProjectOverviewQuery } from '../../gql/graphql';
import { List } from '../../ui';
import { PhaseListItem } from '../asset-display';
import { NoData } from '../no-results';

interface PhaseListProps {
  phases?: GetPhaseListForProjectOverviewQuery['getPhaseList']['items'];
  loading: boolean;
}

export const PhaseList = ({ phases, loading }: PhaseListProps) => {
  return (
    <List collapsed empty={phases?.length === 0} emptyText={<NoData />} loading={loading}>
      {phases?.map((phase) => (
        <PhaseListItem key={phase.vecticeId} phase={phase} />
      ))}
    </List>
  );
};
