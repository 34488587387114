import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { Phase } from '../../gql/graphql';
import { UPDATE_FINDING_NAME } from '../../graphql/queries';
import { useVecticeForm } from '../../hooks';
import { Input, message, ModalForm } from '../../ui';
import { defaultNameFieldConfiguration } from '../../utils';

export interface FormData {
  name: string;
}

interface EditFindingModalProps {
  finding: Pick<Phase, 'id' | 'name'>;
  onClose: () => void;
}

export const EditFindingModal = ({ finding, onClose }: EditFindingModalProps) => {
  const { formState, preSubmit, registerWithErrors } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: finding.name,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updateFinding] = useMutation(UPDATE_FINDING_NAME, {
    optimisticResponse: ({ id, data: { name } }) => ({
      updateFinding: {
        id,
        name: name ?? finding!.name,
      },
    }),
    update: () => onClose(),
    onCompleted: ({ updateFinding: { name } }) =>
      message.success(
        $t({ id: 'issue.modal.edit.success', defaultMessage: 'Issue "{name}" has been updated', values: { name } }),
      ),
    onError: (error) => message.error(error.message),
  });

  const handleSubmit = useCallback(
    async ({ name }: FormData) => {
      await updateFinding({
        variables: {
          id: finding.id,
          data: { name },
        },
      });
    },
    [finding, updateFinding],
  );

  return (
    <ModalForm
      title={$t({ id: 'issue.modal.rename.title', defaultMessage: 'Edit issue' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <Input
        {...registerWithErrors('name', defaultNameFieldConfiguration())}
        label={$t({
          id: 'form.issueEdit.nameLabel',
          defaultMessage: 'Issue name',
        })}
        gutterBottom
        required
        autoFocus
      />
    </ModalForm>
  );
};
