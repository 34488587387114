import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { ModelVersion } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const DELETE_MODEL_VERSION = graphql(`
  mutation deleteModelVersion($modelVersionId: VecticeId!) {
    deleteModelVersion(modelVersionId: $modelVersionId)
  }
`);

interface DeleteModelVersionModalProps {
  modelVersion: Pick<ModelVersion, 'vecticeId' | 'versionNumber'>;
  modelId?: string | null;
  onClose: () => void;
}

export const DeleteModelVersionModal = ({ modelVersion, modelId, onClose }: DeleteModelVersionModalProps) => {
  const navigate = useNavigate();

  const [deleteModelVersion, { loading: deleting }] = useMutation(DELETE_MODEL_VERSION, {
    variables: {
      modelVersionId: modelVersion?.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        onClose();
        navigate(buildLink(VecticeRoutes.MODEL, { modelId }));
        flushFromGraphQLCache(cache, { vecticeId: data.deleteModelVersion, __typename: 'ModelVersion' });
      }
    },
    onCompleted: () => {
      if (modelVersion) {
        message.success(
          $t({
            id: 'component.action.deleteModelVersion.successMessage',
            defaultMessage: 'The version has been deleted',
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <DeletionPrompt
      processing={deleting}
      resourceName={$t({
        id: 'ModelVersionPage.version',
        defaultMessage: 'Version {versionNumber}',
        values: {
          versionNumber: modelVersion.versionNumber,
        },
      })}
      title={$t({ id: 'modelVersion.modal.delete.title', defaultMessage: 'Delete Version' })}
      onClose={onClose}
      onConfirm={deleteModelVersion}
    />
  );
};
