import { graphql } from '../../gql';

export const USER_NOTIFICATION_CREATED = graphql(`
  subscription userNotificationCreatedUnseen {
    userNotificationCreatedUnseen {
      payload {
        id
        type
        content
        createdDate
        status
        creator {
          ...userFields
        }
        workspace {
          vecticeId
          name
        }
      }
    }
  }
`);
