import { IterationStatus } from '../../gql/graphql';
import { IconColors } from '../../ui';
import { getIterationStatusIcon } from '../../utils';

type iterationStatusType = IterationStatus | string | null;

export const getIterationStatusDisplayName = (status?: iterationStatusType) => {
  switch (status) {
    case IterationStatus.Abandoned:
      return $t({ id: 'iterationStatus.abandoned.label', defaultMessage: 'Cancelled' });
    case IterationStatus.Completed:
      return $t({ id: 'iterationStatus.completed.label', defaultMessage: 'Completed' });
    case IterationStatus.InProgress:
      return $t({ id: 'iterationStatus.inProgress.label', defaultMessage: 'In Progress' });
    case IterationStatus.InReview:
      return $t({ id: 'iterationStatus.inReview.label', defaultMessage: 'In Review' });
    case IterationStatus.NotStarted:
      return $t({ id: 'iterationStatus.toDo.label', defaultMessage: 'To Do' });
    default:
      return status ?? $t({ id: 'iterationStatus.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getIterationStatusColor = (status?: iterationStatusType): IconColors => {
  switch (status) {
    case IterationStatus.InProgress:
      return 'on-going';
    case IterationStatus.Abandoned:
      return 'abandoned';
    case IterationStatus.Completed:
      return 'success';
    default:
      return 'default';
  }
};

export const getIterationStatusOptions = () => [
  {
    color: getIterationStatusColor(IterationStatus.NotStarted),
    icon: getIterationStatusIcon(IterationStatus.NotStarted),
    label: getIterationStatusDisplayName(IterationStatus.NotStarted),
    value: IterationStatus.NotStarted,
  },
  {
    color: getIterationStatusColor(IterationStatus.InProgress),
    icon: getIterationStatusIcon(IterationStatus.InProgress),
    label: getIterationStatusDisplayName(IterationStatus.InProgress),
    value: IterationStatus.InProgress,
  },
  {
    color: getIterationStatusColor(IterationStatus.Completed),
    icon: getIterationStatusIcon(IterationStatus.Completed),
    label: getIterationStatusDisplayName(IterationStatus.Completed),
    value: IterationStatus.Completed,
  },
  {
    color: getIterationStatusColor(IterationStatus.Abandoned),
    icon: getIterationStatusIcon(IterationStatus.Abandoned),
    label: getIterationStatusDisplayName(IterationStatus.Abandoned),
    value: IterationStatus.Abandoned,
  },
];

const EDITABLE_STATUS: Record<IterationStatus, boolean> = {
  [IterationStatus.Abandoned]: false,
  [IterationStatus.Completed]: false,
  [IterationStatus.InProgress]: true,
  [IterationStatus.InReview]: true,
  [IterationStatus.NotStarted]: true,
};

export const isIterationEditable = (status?: IterationStatus) => {
  if (!status) return false;
  return EDITABLE_STATUS[status];
};
