import { graphql } from '../../gql';

export const GET_PHASE_LIST = graphql(`
  query getPhaseList($filters: PhaseFiltersInput!, $order: ListOrderInput, $page: PageInput) {
    getPhaseList(filters: $filters, order: $order, page: $page) {
      items {
        vecticeId
        index
        name
        description
        status
        iterationsCount {
          total
        }
        reviewsCount {
          totalReviews
        }
        parent {
          vecticeId
        }
      }
    }
  }
`);
