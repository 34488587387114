import React, { useEffect } from 'react';

import { UserIdentity } from '../../graphql/fragments';
import { useVecticeForm } from '../../hooks';
import { ModalForm, PasswordInput } from '../../ui';
import { PASSWORD_FORMAT } from '../../utils';
import { FormatUserName } from '../formatters';

interface ResetUserPasswordModalProps {
  user?: UserIdentity;
  onSubmit: (newPassword: string) => Promise<void>;
  onClose: () => void;
}

export const ResetUserPasswordModal = ({ user, onSubmit, onClose }: ResetUserPasswordModalProps) => {
  const { formState, preSubmit, registerWithErrors, watch, trigger } = useVecticeForm({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  const passwordValue = watch('newPassword', '');

  const passwordRequirements = $t({
    id: 'modal.resetUserPassword.pwdRequirements',
    defaultMessage: 'Use 8 or more characters with a mix of upper case letters, lower case letters, numbers & symbols.',
  });

  const { isDirty, hasErrors, isSubmitting } = formState;

  useEffect(() => {
    if (passwordValue) {
      trigger('confirmPassword');
    }
  }, [trigger, passwordValue]);

  return (
    <ModalForm
      title={$t({
        id: 'modal.resetUserPassword.title',
        defaultMessage: `Reset password for {user}`,
        values: { user: <FormatUserName user={user} /> },
      })}
      onClose={onClose}
      onSubmit={preSubmit(async (data: { newPassword: string }) => {
        await onSubmit(data.newPassword);
      })}
      submitLabel={$t({ id: 'modal.resetUserPassword.resetPwd', defaultMessage: 'Reset Password' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <PasswordInput
        {...registerWithErrors('newPassword', {
          required: $t({ id: 'modal.resetUserPassword.pwdRequired', defaultMessage: 'The password is required' }),
          pattern: {
            value: PASSWORD_FORMAT,
            message: passwordRequirements,
          },
        })}
        label={$t({ id: 'modal.resetUserPassword.newPwdLabel', defaultMessage: 'New Password' })}
        placeholder={$t({ id: 'modal.resetUserPassword.newPwdPlaceholder', defaultMessage: 'Type new password' })}
        help={passwordRequirements}
        autoFocus
        gutterBottom
      />
      <PasswordInput
        {...registerWithErrors('confirmPassword', {
          required: $t({
            id: 'modal.resetUserPassword.confirmationPwdRequired',
            defaultMessage: 'The confirmation password is required',
          }),
          validate: (value) =>
            value === passwordValue ||
            $t({ id: 'modal.resetUserPassword.pwdIdentical', defaultMessage: 'The passwords must be identical' }),
        })}
        label={$t({ id: 'modal.resetUserPassword.confirmationPwdLabel', defaultMessage: 'Confirm Password' })}
        placeholder={$t({
          id: 'modal.resetUserPassword.confirmationPwdPlaceholder',
          defaultMessage: 'Type password again',
        })}
      />
    </ModalForm>
  );
};
