import cn from 'classnames';
import React from 'react';

import type { LineageProps } from './interfaces';

import { useAssetLineage } from '../../hooks';
import { FlexContainer, Skeleton, Typography, WithLoading } from '../../ui';
import { DocumentationLink } from '../links';

import { LineageEdition } from './LineageEdition';
import { LineagePreview } from './LineagePreview';

import styles from './Lineage.module.scss';

export const Lineage = ({ editMode, datasetInputs, lineageContext, linkTarget = '_blank' }: LineageProps) => {
  const { code, codeFile, inputs, isEmpty, loading, outputs } = useAssetLineage(lineageContext?.lineageId);

  if (!loading && isEmpty) {
    return (
      <div className={cn(styles.lineage, styles.empty)}>
        <Typography variant="footnote" color="tertiary" align="center" paragraph>
          {$t({
            id: 'lineage.empty',
            defaultMessage: 'To display the lineage, you will need to use the Vectice API.',
          })}
          <br />
          <DocumentationLink asTypography>
            {$t({
              id: 'lineage.readOurDocs',
              defaultMessage: 'Read our docs to learn more.',
            })}
          </DocumentationLink>
        </Typography>
      </div>
    );
  }

  return (
    <div className={styles.lineage}>
      <WithLoading
        loading={loading}
        skeleton={
          <FlexContainer direction="column" gap={4}>
            <Skeleton.Shape height={44} width="100%" />
            <Skeleton.Shape height={80} width="100%" />
            <Skeleton.Shape height={44} width="100%" />
            <Skeleton.Shape height={40} width="100%" />
          </FlexContainer>
        }
      >
        {editMode ? (
          <LineageEdition
            codeFile={codeFile}
            codeSource={code}
            currentArtifact={
              lineageContext ? { ...lineageContext, dataSetVersionId: lineageContext.versionId } : undefined
            }
            datasetInputs={datasetInputs}
            lineageContext={lineageContext}
            outputs={outputs}
          />
        ) : (
          <LineagePreview
            codeFile={codeFile}
            codeSource={code}
            currentArtifact={
              lineageContext ? { ...lineageContext, dataSetVersionId: lineageContext.versionId } : undefined
            }
            inputs={inputs}
            lineageId={lineageContext?.lineageId}
            linkTarget={linkTarget}
            outputs={outputs}
          />
        )}
      </WithLoading>
    </div>
  );
};
