import React, { CSSProperties, Fragment, PropsWithChildren } from 'react';

import { getArray } from '../../utils';
import { FlexContainer } from '../containers';
import { Typography } from '../typography';

import styles from './AssetInfos.module.scss';

export const AssetInfos = ({ wrap = 'wrap', children }: PropsWithChildren<{ wrap?: CSSProperties['flexWrap'] }>) => {
  const childrenArray = getArray(children).filter((child) => !!child);

  return (
    <FlexContainer wrap={wrap} gap={0} className={styles.infos}>
      {childrenArray.map((child, index) => (
        <Fragment key={`asset-info-${index}`}>
          {index !== 0 && (
            <Typography variant="footnote" color="tertiary" className={styles.dot}>
              &nbsp;•&nbsp;
            </Typography>
          )}
          {child}
        </Fragment>
      ))}
    </FlexContainer>
  );
};
