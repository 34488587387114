import cn from 'classnames';
import React, { ReactNode, ElementType } from 'react';

import { Graphic, Icon } from '../svg';
import { Typography } from '../typography';

import styles from './Empty.module.scss';

interface EmptyListProps extends VecticeHTMLProps<HTMLDivElement> {
  borderless?: boolean;
  hint?: ReactNode;
  actionButton?: ReactNode;
  graphic?: string;
  icon?: ElementType;
  iconSize?: number;
  message: ReactNode;
}

export const EmptyList = ({
  borderless,
  hint,
  actionButton,
  graphic,
  icon,
  iconSize = 72,
  message,
  className,
  ...props
}: EmptyListProps) => (
  <div className={cn(styles.empty, { [styles.borderless]: borderless }, className)} {...props}>
    {graphic && <Graphic src={graphic} size={iconSize} className={styles.icon} />}
    {icon && <Icon icon={icon} size={iconSize} className={styles.icon} />}
    <Typography
      paragraph
      variant="callout"
      weight="semi-bold"
      color="primary"
      align="center"
      className={styles.message}
    >
      {message}
    </Typography>
    {hint && (
      <Typography
        component="div"
        paragraph
        variant="footnote"
        color="tertiary"
        align="center"
        className={cn(styles.hint, { [styles.actionBelow]: !!actionButton })}
      >
        {hint}
      </Typography>
    )}
    {actionButton}
  </div>
);
