import { graphql } from '../../gql';

export const SUBSCRIBE_TO_UNRESOLVED_COMMENT_LIST = graphql(`
  subscription subscribeToUnresolvedFileComments($fileId: Float!) {
    subscribeToFileComments(fileId: $fileId) {
      fileId
      threadId
      status
      isDeleted
      replyId
    }
  }
`);
