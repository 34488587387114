import React, { Key, Suspense } from 'react';

import { Skeleton, Typography } from '../../ui';

import styles from './ApiHelp.module.scss';

const CodePreview = React.lazy(() => import('../previews/code'));

export type Snippet = { label?: string; hint?: string; code: string };

interface VecticeCodeSnippetsProps {
  snippets: Snippet[];
  tab?: Key;
  footerContent?: string[];
}

export const VecticeCodeSnippets = ({ snippets, tab = 'Default', footerContent = [] }: VecticeCodeSnippetsProps) => (
  <>
    {snippets.map(({ label, hint, code }, index) => (
      <div key={`${label ?? ''}-${tab}-${index}`} className={styles.snippet}>
        {label && (
          <Typography variant="callout" weight="semi-bold" color="primary" paragraph>
            {label}
          </Typography>
        )}
        <Suspense fallback={<Skeleton.Shape height={50} width="100%" dark />}>
          <CodePreview content={code} language="vectice" className={styles.code} />
        </Suspense>
        {!!hint && <Typography variant="footnote">{hint}</Typography>}
      </div>
    ))}
    {footerContent?.map((content, index) => (
      <>
        <Typography weight="semi-bold" variant="callout" color="primary">
          {content}
        </Typography>
        {index !== footerContent.length && <br />}
      </>
    ))}
  </>
);
