import { graphql } from '../../../gql';

export const GET_UNIQUE_ALGORITHM_NAMES = graphql(`
  query getUniqueAlgorithmNames(
    $workspaceIdList: [VecticeId!]!
    $modelIdList: [VecticeId!]
    $search: String!
    $projectIdList: [VecticeId!]
  ) {
    getUniqueAlgorithmNames(
      workspaceIdList: $workspaceIdList
      modelIdList: $modelIdList
      projectIdList: $projectIdList
      filters: { search: $search }
    ) {
      items {
        algorithmName
      }
    }
  }
`);
