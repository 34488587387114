import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { Column, Loading, Pagination, Table, Typography } from '../../../ui';
import { NoData } from '../../no-results';
import { NoPreview } from '../../previews/NoPreview';

import { ENTITY_METADATA_CONTENT } from './entityMetadataPreview.query';

import styles from './EntityMetadataPreview.module.scss';

interface EntityMetaPreviewProps {
  entityMetadataId: number;
}

const PAGE_SIZE = 30;

export const EntityMetadataPreview = ({ entityMetadataId }: EntityMetaPreviewProps) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { data, loading } = useQuery(ENTITY_METADATA_CONTENT, {
    variables: { id: entityMetadataId },
  });

  const entityMetadata = data?.entityMetadata;
  const content = entityMetadata?.content ?? [];

  useEffect(() => {
    setCurrentPage(0);
  }, [content]);

  if (loading) return <Loading color="white" />;
  if (!data) return <NoPreview />;

  const tableData = content ? (Object.values(content) as any[])[0] : [];
  const columns = tableData ? Object.keys(tableData[0]) : [];

  const slicedData = tableData ? tableData.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE) : [];

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <Table
          data={slicedData}
          emptyText={<NoData />}
          rowKey={(_, index) => `data-preview-row-${index}`}
          scroll={{ x: true, y: true }}
          borderless
        >
          {columns.map((column) => (
            <Column key={column} title={column.trim()} width={'100px'} ellipsis>
              {(data?: string | boolean | number | null) => {
                if (typeof data === 'object' && data !== null)
                  return (
                    <Typography ellipsis className={styles.data}>
                      {$t({ id: 'components.entityMetadata.object', defaultMessage: 'Object' })}
                    </Typography>
                  );
                else
                  return (
                    <Typography ellipsis className={styles.data}>
                      {data?.toString() ?? '-'}
                    </Typography>
                  );
              }}
            </Column>
          ))}
        </Table>
        <Pagination
          current={currentPage + 1}
          pageSize={PAGE_SIZE}
          total={tableData.length}
          onChange={(page) => setCurrentPage(page - 1)}
        />
      </div>
    </div>
  );
};
