import { ReactComponent as InProgressIcon } from '../../assets/icons/interface/ic-in-progress.svg';
import { ReactComponent as NotStartedIcon } from '../../assets/icons/interface/ic-not-started.svg';
import { ReactComponent as CompletedIcon } from '../../assets/icons/interface/ic-radio-checked.svg';
import { IconColors } from '../../ui';

type phaseProgressType = PhaseProgress | string | null;

export enum PhaseProgress {
  ToDo = 'ToDo',
  Ongoing = 'Ongoing',
  Done = 'Done',
}

export const getPhaseProgressDisplayName = (status?: phaseProgressType) => {
  switch (status) {
    case PhaseProgress.ToDo:
      return $t({ id: 'phaseProgress.toDo.label', defaultMessage: 'To Do' });
    case PhaseProgress.Ongoing:
      return $t({ id: 'phaseProgress.ongoing.label', defaultMessage: 'Ongoing' });
    case PhaseProgress.Done:
      return $t({ id: 'phaseProgress.done.label', defaultMessage: 'Done' });
    default:
      return $t({ id: 'phaseProgress.unknown.label', defaultMessage: 'Unknown Status' });
  }
};

export const getPhaseProgressColor = (status?: phaseProgressType): IconColors => {
  switch (status) {
    case PhaseProgress.Ongoing:
      return 'on-going';
    case PhaseProgress.Done:
      return 'success';
    default:
      return 'default';
  }
};

export const getPhaseProgressIcon = (status?: phaseProgressType) => {
  switch (status) {
    case PhaseProgress.Ongoing:
      return InProgressIcon;
    case PhaseProgress.Done:
      return CompletedIcon;
    default:
      return NotStartedIcon;
  }
};
