let Prism: any;

const operators = ['if', 'else', 'endif', 'for', 'not', 'in', 'endfor', 'set'];
const operatorMatch = new RegExp(`(\\b${operators.join('\\b|\\b')}\\b)`, 'i');
const punctuationMatch = {
  pattern: /[!#()*\/<=>@\[\\\]^`|~]/,
  greedy: true,
};
const variableMatch = {
  pattern: /\{\{[^{]*}}/,
  greedy: true,
};

const functionMatch = {
  pattern: /\{%[^{]*%}/,
  greedy: true,
  inside: {
    punctuation: punctuationMatch,
    variable: {
      pattern: /[\w.]+/,
      greedy: true,
      inside: {
        operator: operatorMatch,
        punctuation: punctuationMatch,
      },
    },
  },
};

function extendPrism(prism: any) {
  if ('vectice' in prism.languages) {
    return;
  }

  prism.languages['vectice'] = prism.languages.extend('handlebars', {
    brackets: {
      ...prism.languages['handlebars'].brackets,
      inside: {
        function: functionMatch,
        variable: variableMatch,
        punctuation: /\[|\]/,
      },
    },
    function: functionMatch,
    variable: variableMatch,
    punctuation: punctuationMatch,
    string: {
      pattern: prism.languages['handlebars'].string,
      inside: {
        function: functionMatch,
        variable: variableMatch,
      },
    },
  });
}

export function usePrism() {
  if (!Prism) {
    throw new Promise(async (resolve) => {
      Prism = await import('../services/imports/prismjs');
      extendPrism(Prism.default);
      resolve(null);
    });
  }

  return Prism.default;
}
