import React from 'react';
import { Link } from 'react-router-dom';

import { ReviewNotificationProjectContent } from '../../../entities';
import { buildLink, VecticeRoutes } from '../../../routes';
import { UserNotificationQuickStartProjectCreated } from '../types';

import { Notification } from './Notification';

interface QuickstartProjectCreatedProps {
  notification: UserNotificationQuickStartProjectCreated;
  onClose: () => void;
}

export const QuickstartProjectCreated = ({
  notification: { creator, content, createdDate },
  onClose,
}: QuickstartProjectCreatedProps) => {
  const parsedContent: ReviewNotificationProjectContent = JSON.parse(content);
  const projectId = parsedContent?.projectId;

  return (
    <Notification
      component={Link}
      to={buildLink(VecticeRoutes.PROJECT, { projectId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.quickstqrt.project.created',
        defaultMessage: `Welcome to <bold>Vectice</bold>!\n<bold>Click here</bold> to start your <bold>Quickstart</bold> project to learn how to use Vectice.`,
      })}
    </Notification>
  );
};
