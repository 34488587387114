import React, { PropsWithChildren, useRef } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { DocumentationLink } from '../components_v3';
import config from '../config';
import messages from '../en-US.json';
import { buildLink, VecticeRoutes } from '../routes';
import { Typography } from '../ui';

const MessagesProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { formatMessage } = useIntl();
  const { current: globalI18n } = useRef(
    ({ id, defaultMessage, values }: { id: string; defaultMessage: string; values?: Record<string, unknown> }) => {
      return formatMessage(
        { id, defaultMessage },
        {
          ...values,
          login: (str) =>
            (
              <Typography component={Link} to={buildLink(VecticeRoutes.LOGIN)} color="accent" link>
                {str}
              </Typography>
            ) as unknown as string,
          signup: (str) =>
            (
              <Typography component={Link} to={buildLink(VecticeRoutes.SIGNUP)} color="accent" link>
                {str}
              </Typography>
            ) as unknown as string,
          bold: (str) =>
            (
              <Typography component="strong" weight="semi-bold">
                {str}
              </Typography>
            ) as unknown as string,
          support: (str) => (<a href={`mailto:${config.SUPPORT_EMAIL}`}>{str}</a>) as unknown as string,
          tos_link: (str) =>
            (
              <Typography color="accent" link href={config.TOS_URL} target="_blank">
                {str}
              </Typography>
            ) as unknown as string,
          documentation: (str) =>
            (
              <DocumentationLink url="documentation" asTypography>
                {str}
              </DocumentationLink>
            ) as unknown as string,
          api_reference: (str) =>
            (
              <DocumentationLink url="api_reference" asTypography>
                {str}
              </DocumentationLink>
            ) as unknown as string,
          autolog_doc: (str) =>
            (
              <DocumentationLink url="autolog_doc" asTypography>
                {str}
              </DocumentationLink>
            ) as unknown as string,
          r_cheatsheet: (str) =>
            (
              <DocumentationLink url="r_cheatsheet" asTypography>
                {str}
              </DocumentationLink>
            ) as unknown as string,
          python_cheatsheet: (str) =>
            (
              <DocumentationLink url="python_cheatsheet" asTypography>
                {str}
              </DocumentationLink>
            ) as unknown as string,
          api_token_page: (str) =>
            (
              <Typography component={Link} to={buildLink(VecticeRoutes.ACCOUNT_API_KEYS)} color="accent" link>
                {str}
              </Typography>
            ) as unknown as string,
          api_cheat_sheet: (str) =>
            (
              <DocumentationLink url="api_cheat_sheet" asTypography>
                {str}
              </DocumentationLink>
            ) as unknown as string,
        },
      );
    },
  );

  (window || global).$t = globalI18n;

  return <>{children}</>;
};

export const I18nProvider = ({
  children,
  locale,
  onError,
}: PropsWithChildren<{ locale?: string; onError?: () => void }>) => {
  const navigatorLocale = navigator.language; // language of the browser UI

  return (
    <IntlProvider
      locale={locale || navigatorLocale}
      messages={messages}
      onError={
        onError ||
        ((err) => {
          if (err.code === 'MISSING_TRANSLATION') return;
          throw err;
        })
      }
    >
      <MessagesProvider>{children}</MessagesProvider>
    </IntlProvider>
  );
};
