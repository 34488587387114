import cn from 'classnames';
import debounce from 'lodash/debounce';
import React, {
  ChangeEvent,
  ElementType,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Loading } from '../../loading-animation';
import { Icon } from '../../svg';
import { ClearInput } from '../advanced-input/internal';
import { FormItemContainer } from '../FormItemContainer';
import { CommonProps, LabelProps, SizeProps } from '../interfaces';

import { DEBOUNCE_WAIT_TIME, createSyntheticEvent } from './internals';

import styles from './Input.module.scss';

let globalId = 0;

interface AddonProps {
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  clearable?: boolean;
  debounceDelay?: number;
  iconAfter?: ElementType;
  iconBefore?: ElementType;
  loading?: boolean;
}

type InputProps = CommonProps<HTMLInputElement> & LabelProps & SizeProps & AddonProps;

export const Input = React.forwardRef(
  (
    {
      addonAfter,
      addonBefore,
      afterLabel,
      autoFocus,
      borderless,
      className,
      clearable,
      debounceDelay = DEBOUNCE_WAIT_TIME,
      disabled,
      error,
      gutterBottom,
      help,
      hint,
      iconAfter,
      iconBefore,
      label,
      loading,
      name,
      small,
      onChange,
      onDebouncedChange,
      ...props
    }: InputProps,
    ref: React.Ref<HTMLInputElement | null>,
  ) => {
    // eslint-disable-next-line no-plusplus
    const { current: inputId } = useRef(`${name || 'input'}-${globalId++}`);
    const innerRef = useRef<HTMLInputElement>(null);
    const [showClear, setShowClear] = useState(clearable);
    useImperativeHandle(ref, () => innerRef.current);

    useEffect(() => {
      if (autoFocus && innerRef.current) {
        innerRef.current.focus();
      }
    }, [innerRef, autoFocus]);

    const contentBefore = addonBefore || (iconBefore && <Icon icon={iconBefore} size={18} />);
    const contentAfter =
      (loading && <Loading size={18} />) || addonAfter || (iconAfter && <Icon icon={iconAfter} size={18} />);

    const debounceChange = useCallback(
      debounce((event) => onDebouncedChange?.(event), debounceDelay),
      [onDebouncedChange],
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setShowClear(true);
      onChange?.(e);
      debounceChange(e.currentTarget.value);
    };

    const handleClear = () => {
      debounceChange('');
      if (innerRef.current) {
        innerRef.current.value = '';
        innerRef.current.focus();
        setShowClear(false);
        // Dispatch empty event to be compliant with forms validation
        const event = new Event('change', { bubbles: true });
        Object.defineProperty(event, 'target', { writable: false, value: innerRef.current });
        const syntheticEvent = createSyntheticEvent(event) as React.ChangeEvent<typeof innerRef.current>;
        onChange?.(syntheticEvent);
      }
    };

    return (
      <FormItemContainer
        label={label}
        hint={hint}
        afterLabel={afterLabel}
        help={help}
        error={error}
        gutterBottom={gutterBottom}
        className={className}
        id={inputId}
      >
        <div
          className={cn(styles.wrapper, {
            [styles.disabled]: disabled,
            [styles.borderless]: borderless,
            [styles.small]: small,
          })}
        >
          {contentBefore && <div className={styles.before}>{contentBefore}</div>}
          <input
            id={inputId}
            name={name}
            className={styles.input}
            disabled={disabled}
            ref={innerRef}
            onChange={handleChange}
            {...props}
          />
          {clearable && showClear && innerRef.current?.value && (
            <ClearInput className={styles.after} onClear={handleClear} />
          )}
          {contentAfter && <div className={styles.after}>{contentAfter}</div>}
        </div>
      </FormItemContainer>
    );
  },
);
