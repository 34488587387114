import React from 'react';

import { IterationStepArtifactType } from '../../../gql/graphql';
import { AssetRow } from '../../../ui';
import { assetInfoRecord } from '../../../utils';

interface DeletedAssetRowProps {
  id?: string;
  type: IterationStepArtifactType;
}

export const DeletedAssetRow = ({ id, type }: DeletedAssetRowProps) => {
  const assetInfo = assetInfoRecord[type];

  return (
    <AssetRow
      icon={assetInfo.icon}
      id={id}
      label={$t({
        id: 'deletedAsset.label',
        defaultMessage: '{asset} was deleted',
        values: { asset: assetInfo.text() },
      })}
    />
  );
};
