import React, { useCallback, useEffect } from 'react';

import { useVecticeForm } from '../../../hooks';
import { Button, FlexContainer, Form, TextArea, Typography } from '../../../ui';
import { defaultDescriptionFieldConfiguration } from '../../../utils';

import styles from './EditableDescription.module.scss';

interface EditableDescriptionProps extends VecticeHTMLProps<HTMLDivElement> {
  description: string | null;
  editable: boolean;
  hideNoDescription?: boolean;
  onCancel: () => void;
  onSave?: (description: string) => any;
}

export const EditableDescription = ({
  className,
  description,
  editable,
  hideNoDescription,
  placeholder,
  onCancel,
  onSave,
}: EditableDescriptionProps) => {
  const { formState, preSubmit, registerWithErrors, reset } = useVecticeForm<{ description: string }>({
    mode: 'onChange',
    defaultValues: { description: description ?? '' },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const handleSubmit = useCallback(({ description: value }) => {
    onSave?.(value);
  }, []);

  const handleCancel = useCallback(() => {
    onCancel?.();
    reset({ description: description ?? '' });
  }, [description]);

  useEffect(() => {
    reset({ description: description ?? '' });
  }, [description]);

  return (
    <FlexContainer className={className}>
      {!editable && description && (
        <Typography variant="callout" color="primary" paragraph>
          {description}
        </Typography>
      )}
      {!editable && !description && !hideNoDescription && (
        <Typography variant="callout" color="disabled" paragraph>
          {$t({ id: 'EditableDescription.noDescription', defaultMessage: 'No description' })}
        </Typography>
      )}
      {onSave && editable ? (
        <Form className={styles.form} onSubmit={preSubmit(handleSubmit)}>
          <TextArea
            aria-label="Description"
            autoFocus
            {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
            hint={null}
            placeholder={placeholder}
            small
          />
          <FlexContainer gap={0} justify="flex-end">
            <Button variant="phantom" onClick={handleCancel}>
              {$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
            </Button>
            <Button disabled={hasErrors || !isDirty} loading={isSubmitting} type="submit">
              {$t({ id: 'button.save', defaultMessage: 'Save' })}
            </Button>
          </FlexContainer>
        </Form>
      ) : null}
    </FlexContainer>
  );
};
