import React, { ReactNode } from 'react';

import { FlexContainer } from '../../containers';
import { Typography } from '../../typography';

interface DialogTitleProps {
  title: ReactNode;
  titleBadge?: ReactNode;
  subtitle?: ReactNode;
  className?: string;
}

export const DialogTitle = ({ title, titleBadge, subtitle, className }: DialogTitleProps) => (
  <>
    <FlexContainer gap={16} className={className}>
      <Typography component="div" variant="heading3" color="primary" ellipsis>
        {title}
      </Typography>
      {titleBadge}
    </FlexContainer>
    {subtitle}
  </>
);
