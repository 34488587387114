import React from 'react';
import { Link } from 'react-router-dom';

import { buildLink, VecticeRoutes } from '../../../routes';
import { UserNotificationAccessRequestApproved } from '../types';

import { Notification } from './Notification';

interface InviteAcceptedNotificationProps {
  notification: UserNotificationAccessRequestApproved;
  onClose: () => void;
}

export const AccessRequestApprovedNotification = ({
  notification: { creator, workspace, createdDate },
  onClose,
}: InviteAcceptedNotificationProps) => {
  if (!workspace) return null;

  return (
    <Notification
      component={Link}
      to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId: workspace.vecticeId })}
      onClose={onClose}
      creator={creator}
      createdDate={createdDate}
    >
      {$t({
        id: 'notification.access.approved',
        defaultMessage: 'Your request to access Workspace <bold>{workspace}</bold> was approved',
        values: {
          workspace: workspace.name,
        },
      })}
    </Notification>
  );
};
