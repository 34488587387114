import React from 'react';

import { ReactComponent as AddIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { Button } from '../../ui';

import styles from './PhaseNavigationHeader.module.scss';

interface PhaseNavigationHeaderProps {
  onCreatePage?: () => void;
}

export const PhaseNavigationHeader = ({ onCreatePage }: PhaseNavigationHeaderProps) => {
  const { allowed: canCreatePhase } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });

  return (
    <div className={styles.title}>
      {canCreatePhase && (
        <Button variant="phantom" leftIcon={AddIcon} disabled={!canCreatePhase} onClick={onCreatePage}>
          {$t({ id: 'button.create', defaultMessage: 'Create phase' })}
        </Button>
      )}
    </div>
  );
};
