import { graphql } from '../../gql';

export const CDT_TEMPLATES = graphql(`
  query CDTTemplates($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {
    CDTTemplates(order: $order, page: $page, filters: $filters) {
      items {
        id
        exportId
        name
        description
        isMacro
        isRichText
        enabled
        groupName
        organizationId
        createdBy {
          ...userFields
        }
        updatedDate
      }
      page {
        afterCursor
      }
      total
    }
  }
`);

export const CDT_TEMPLATES_VARIABLES = graphql(`
  query CDTTemplatesVariables($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {
    CDTTemplates(order: $order, page: $page, filters: $filters) {
      items {
        id
        exportId
        assets {
          name
          type
        }
      }
      total
    }
  }
`);

export const GET_CDT_TEMPLATE_CONTENT = graphql(`
  query CDTTemplateContent($id: Float, $globalId: String) {
    CDTTemplate(id: $id, globalId: $globalId) {
      id
      exportId
      name
      enabled
      description
      groupName
      content
      isMacro
      headerContent
      footerContent
      organizationId
      assets {
        id
        name
        type
      }
    }
  }
`);

export const CREATE_REPORT = graphql(`
  mutation createCDTReport($projectId: VecticeId!, $data: CDTReportCreateInput!) {
    createCDTReport(projectId: $projectId, data: $data) {
      id
      name
    }
  }
`);

export const GENERATE_MARKDOWN = graphql(`
  mutation generateCDTMarkdown($data: CDTGenerateMarkdownInput!) {
    generateCDTMarkdown(data: $data)
  }
`);

export const PREVIEW_MACRO = graphql(`
  mutation previewMacro($assets: [CDTReportAssetInput!]!, $content: String!) {
    previewMacro(assets: $assets, content: $content)
  }
`);

export const REMOVE_CDT_TEMPLATE = graphql(`
  mutation removeCDTTemplate($id: Float!) {
    removeCDTTemplate(id: $id)
  }
`);

export const CREATE_CDT_TEMPLATE = graphql(`
  mutation createCDTTemplate($data: CDTTemplateCreateInput!) {
    createCDTTemplate(data: $data) {
      id
      name
      enabled
    }
  }
`);

export const UPDATE_CDT_TEMPLATE = graphql(`
  mutation updateCDTTemplate($id: Float!, $data: CDTTemplateUpdateInput!) {
    updateCDTTemplate(id: $id, data: $data) {
      id
      name
      enabled
      description
    }
  }
`);

export const GET_GLOBAL_TEMPLATE = graphql(`
  query GlobalCDTTemplate($globalId: String) {
    CDTTemplate(globalId: $globalId) {
      id
      exportId
      name
      content
      headerContent
      footerContent
    }
  }
`);
