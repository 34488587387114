import { useMutation } from '@apollo/client';

import { UserInvite } from '../gql/graphql';
import { CANCEL_INVITE } from '../graphql/queries';

export const useCancelInvite = () => {
  const [cancelUserInvite] = useMutation(CANCEL_INVITE, {
    refetchQueries: ['getUserInvitesList', 'getUserAndInvitesList'],
  });

  const cancel = async (invite: UserInvite) => cancelUserInvite({ variables: { id: parseInt(invite.id, 10) } });

  return { cancel };
};
