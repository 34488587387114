import { useQuery } from '@apollo/client';
import React, { ElementType, ReactElement, useRef } from 'react';

import { ReactComponent as FlatReviewIcon } from '../../../assets/icons/specials/flat/ic-approved.svg';
import { ReactComponent as FlatDatasetIcon } from '../../../assets/icons/specials/flat/ic-dataset-version.svg';
import { ReactComponent as FlatModelIcon } from '../../../assets/icons/specials/flat/ic-model-version.svg';
import { ReactComponent as FlatPhaseIcon } from '../../../assets/icons/specials/flat/ic-phase.svg';
import { ReactComponent as FlatProjectIcon } from '../../../assets/icons/specials/flat/ic-project.svg';
import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/workspace-dashboard/ic-dataset-version.svg';
import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/workspace-dashboard/ic-model-version.svg';
import { ReactComponent as PhaseIcon } from '../../../assets/icons/specials/workspace-dashboard/ic-phase.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/workspace-dashboard/ic-project.svg';
import { ReactComponent as ReviewIcon } from '../../../assets/icons/specials/workspace-dashboard/ic-review.svg';
import { graphql } from '../../../gql';
import { FlexContainer, Grid, GridCell, Icon, Tooltip, Typography } from '../../../ui';
import { DashboardCard } from '../internals';

export const GET_ACTIVITY_INSIGHTS = graphql(`
  query getActivityInsights($workspaceIdList: [VecticeId!]!) {
    getActivityInsights(workspaceIdList: $workspaceIdList, numberOfDays: 30) {
      completedProjectsCount
      completedPhasesCount
      approvedPhasesCount
      modelVersionsCount
      datasetVersionsCount
    }
  }
`);

interface InsightCardProps {
  icon: ElementType;
  label: string;
  count: number;
}

let globalId = 0;
const InsightCard = ({ icon, label, count, ...props }: InsightCardProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`insight-card-${globalId++}`);

  return (
    <GridCell aria-labelledby={id} {...props}>
      <FlexContainer>
        <Icon icon={icon} size={36} />
        <div>
          <Typography component="div" variant="callout" color="primary" id={id}>
            {label}
          </Typography>
          <Typography component="div" weight="semi-bold" color="primary">
            {count}
          </Typography>
        </div>
      </FlexContainer>
    </GridCell>
  );
};

interface InsightTooltipProps {
  icon: ElementType;
  title: string;
  label: string;
  children: ReactElement;
}

const InsightTooltip = ({ icon, title, label, children }: InsightTooltipProps) => {
  return (
    <Tooltip
      overlay={
        <Typography component="div" variant="callout" color="white">
          <FlexContainer>
            <Icon icon={icon} />
            <Typography component="div" weight="semi-bold">
              {title}
            </Typography>
          </FlexContainer>
          <div>{label}</div>
        </Typography>
      }
      placement="top"
      children={children}
    />
  );
};

interface ActivityInsightsProps {
  workspaceIdList: string[];
}

export const ActivityInsights = ({ workspaceIdList }: ActivityInsightsProps) => {
  const { data, loading } = useQuery(GET_ACTIVITY_INSIGHTS, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdList,
    },
  });

  const insights = data?.getActivityInsights;

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.ActivityInsights.title', defaultMessage: 'Activity Insights' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.relativeTime', defaultMessage: 'In the last 30 days' })}
      hint={$t({
        id: 'dashboard.ActivityInsights.hint',
        defaultMessage: 'The count of specific actions completed in Vectice.',
      })}
      loading={loading}
    >
      {!!insights && (
        <Grid columns={2} gap={8}>
          <InsightTooltip
            icon={FlatProjectIcon}
            title={$t({
              id: 'dashboard.ActivityInsights.completedProjects.label',
              defaultMessage: 'Completed Projects',
            })}
            label={$t({
              id: 'dashboard.ActivityInsights.completedProjects.tooltip',
              defaultMessage: '{count} completed in the last 30 days',
              values: {
                count: insights.completedProjectsCount,
              },
            })}
          >
            <InsightCard
              icon={ProjectIcon}
              label={$t({
                id: 'dashboard.ActivityInsights.completedProjects.label',
                defaultMessage: 'Completed Projects',
              })}
              count={insights.completedProjectsCount}
            />
          </InsightTooltip>
          <InsightTooltip
            icon={FlatPhaseIcon}
            title={$t({ id: 'dashboard.ActivityInsights.completedPhases.label', defaultMessage: 'Completed Phases' })}
            label={$t({
              id: 'dashboard.ActivityInsights.completedPhases.tooltip',
              defaultMessage: '{count} completed in the last 30 days',
              values: {
                count: insights.completedPhasesCount,
              },
            })}
          >
            <InsightCard
              icon={PhaseIcon}
              label={$t({ id: 'dashboard.ActivityInsights.completedPhases.label', defaultMessage: 'Completed Phases' })}
              count={insights.completedPhasesCount}
            />
          </InsightTooltip>
          <InsightTooltip
            icon={FlatReviewIcon}
            title={$t({ id: 'dashboard.ActivityInsights.approvedPhases.label', defaultMessage: 'Approved Phases' })}
            label={$t({
              id: 'dashboard.ActivityInsights.approvedPhases.tooltip',
              defaultMessage: '{count} approved in the last 30 days',
              values: {
                count: insights.approvedPhasesCount,
              },
            })}
          >
            <InsightCard
              icon={ReviewIcon}
              label={$t({ id: 'dashboard.ActivityInsights.approvedPhases.label', defaultMessage: 'Approved Phases' })}
              count={insights.approvedPhasesCount}
            />
          </InsightTooltip>
          <InsightTooltip
            icon={FlatModelIcon}
            title={$t({ id: 'dashboard.ActivityInsights.modelVersions.label', defaultMessage: 'New Model versions' })}
            label={$t({
              id: 'dashboard.ActivityInsights.modelVersions.tooltip',
              defaultMessage: '{count} new in the last 30 days',
              values: {
                count: insights.modelVersionsCount,
              },
            })}
          >
            <InsightCard
              icon={ModelVersionIcon}
              label={$t({ id: 'dashboard.ActivityInsights.modelVersions.label', defaultMessage: 'New Model versions' })}
              count={insights.modelVersionsCount}
            />
          </InsightTooltip>
          <InsightTooltip
            icon={FlatDatasetIcon}
            title={$t({
              id: 'dashboard.ActivityInsights.datasetVersions.label',
              defaultMessage: 'New Dataset versions',
            })}
            label={$t({
              id: 'dashboard.ActivityInsights.datasetVersions.tooltip',
              defaultMessage: '{count} new in the last 30 days',
              values: {
                count: insights.datasetVersionsCount,
              },
            })}
          >
            <InsightCard
              icon={DatasetVersionIcon}
              label={$t({
                id: 'dashboard.ActivityInsights.datasetVersions.label',
                defaultMessage: 'New Dataset versions',
              })}
              count={insights.datasetVersionsCount}
            />
          </InsightTooltip>
        </Grid>
      )}
    </DashboardCard>
  );
};
