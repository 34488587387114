import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as IterationIcon } from '../../../assets/icons/specials/flat/ic-iteration.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { IterationStatusBadge } from '../../badges';
import { FormatUserName } from '../../formatters';

export const GET_ITERATION_TOOLTIP = graphql(`
  query getIterationTooltip($iterationId: VecticeId!) {
    getIterationById(id: $iterationId) {
      vecticeId
      index
      name
      status
      createdBy {
        ...userFields
      }
      createdDate
    }
  }
`);

interface IterationTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const IterationTooltip = ({ id, placement = 'top', children }: IterationTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`iteration-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_ITERATION_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      iterationId: id!,
    },
  });

  const iteration = data?.getIterationById;

  useEffect(() => {
    setDataLoaded(!!iteration || !!error);
  }, [iteration, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={IterationIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.ITERATION)}
      label={iteration?.name}
      deleted={!!error}
    >
      {iteration && (
        <DataList>
          <Typography id={`created-${ariaId}`} component="dt" variant="callout" color="white">
            {$t({ id: 'tooltips.iteration.created', defaultMessage: 'Created by' })}
          </Typography>
          <Typography aria-labelledby={`created-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            <FormatUserName user={iteration.createdBy} />
          </Typography>
          <Typography id={`status-${ariaId}`} component="dt" variant="callout" color="white">
            {$t({ id: 'tooltips.iteration.status', defaultMessage: 'Status' })}
          </Typography>
          <dd aria-labelledby={`status-${ariaId}`}>
            <IterationStatusBadge status={iteration.status} contrast />
          </dd>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
