import React from 'react';

import { ReactComponent as RestoreIcon } from '../../../assets/icons/interface/ic-history.svg';
import { useFragment } from '../../../gql';
import { useWithRoles } from '../../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../../guards/utils';
import { Button } from '../../../ui';
import { ActivityProps } from '../activity/interfaces';
import { UserActivityFragment } from '../fragments';
import { useRestore } from '../hooks';

import styles from './RestoreButton.module.scss';

export const RestoreButton = ({ activity: fragment }: ActivityProps) => {
  const { allowed: canRestore } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });
  const activity = useFragment(UserActivityFragment, fragment);
  const { restoring, restoreActivity } = useRestore(activity);

  if (!canRestore) return null;

  return (
    <Button
      className={styles.restore}
      disabled={restoring}
      leftIcon={RestoreIcon}
      variant="phantom"
      onClick={restoreActivity}
    >
      {$t({ id: 'activities.restore.label', defaultMessage: 'Restore' })}
    </Button>
  );
};
