import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Typography } from '../../typography';

import styles from './Dialog.module.scss';

export const DialogContent = ({
  withBackground = true,
  className,
  children,
}: PropsWithChildren<{
  className?: string;
  withBackground?: boolean;
}>) => (
  <Typography
    component="div"
    paragraph
    className={cn(styles.dialog_content, className, {
      [styles.withBackground]: withBackground,
    })}
  >
    {children}
  </Typography>
);
