import { FragmentType, graphql } from '../../../gql';
import { CategoryType, DbColumnSource, FileSourceColumn } from '../../../gql/graphql';

export const BooleanSourceColumnFragment = graphql(`
  fragment BooleanSourceColumnStatistics on DataframeStatistics {
    boolean {
      false
      missing
      true
    }
  }
`);

export const DateSourceColumnFragment = graphql(`
  fragment DateSourceColumnStatistics on DataframeStatistics {
    date {
      maximum
      mean
      median
      minimum
      missing
    }
  }
`);

export const NumericalSourceColumnFragment = graphql(`
  fragment NumericalSourceColumnStatistics on DataframeStatistics {
    numerical {
      mean
      missing
      quantiles {
        qMin
        q25
        q50
        q75
        qMax
      }
      stdDeviation
    }
  }
`);

export const TextSourceColumnFragment = graphql(`
  fragment TextSourceColumnStatistics on DataframeStatistics {
    text {
      missing
      mostCommons {
        frequency
        value
      }
      unique
    }
  }
`);

export type SourceColumnWithFragment = Pick<
  FileSourceColumn | DbColumnSource,
  'id' | 'name' | 'description' | 'categoryType'
>;

export interface BooleanSourceColumn extends Omit<SourceColumnWithFragment, 'categoryType'> {
  categoryType: CategoryType.Boolean;
  statistics?: FragmentType<typeof BooleanSourceColumnFragment>;
}

export interface DateSourceColumn extends Omit<SourceColumnWithFragment, 'categoryType'> {
  categoryType: CategoryType.Date;
  statistics?: FragmentType<typeof DateSourceColumnFragment>;
}

export interface NumericalSourceColumn extends Omit<SourceColumnWithFragment, 'categoryType'> {
  categoryType: CategoryType.Numerical;
  statistics?: FragmentType<typeof NumericalSourceColumnFragment>;
}

export interface TextSourceColumn extends Omit<SourceColumnWithFragment, 'categoryType'> {
  categoryType: CategoryType.Text;
  statistics?: FragmentType<typeof TextSourceColumnFragment>;
}
