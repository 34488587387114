import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { IterationStepFragment } from '../components_v3/iteration/IterationStepFragment';
import {
  GetIterationByIdQuery,
  GetIterationStepListQuery,
  IterationStepArtifactFragment,
  IterationStepArtifactType,
  OrderDirection,
} from '../gql/graphql';
import {
  CHECK_ITERATION_SECTION_NAME_AVAILABILITY,
  GET_ITERATION_BY_ID,
  GET_ITERATION_SECTIONS,
  MOVE_ARTIFACTS,
  REMOVE_ITERATION_SECTION,
  UPDATE_ITERATION_SECTION,
} from '../graphql/queries';
import { flushFromGraphQLCache } from '../graphql/utils';
import { message } from '../ui';
import { getReorderedItems } from '../utils';

export const useSections = (iterationId?: string) => {
  const apolloClient = useApolloClient();

  const getIterationSectionsVariables = useMemo(
    () => ({
      parentId: iterationId!,
      order: { field: 'index', direction: OrderDirection.Asc },
    }),
    [iterationId],
  );

  const getIterationContentVariables = useMemo(
    () => ({
      id: iterationId!,
    }),
    [iterationId],
  );

  const {
    data: sectionsData,
    loading: loadingSections,
    refetch,
  } = useQuery(GET_ITERATION_SECTIONS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !iterationId,
    variables: getIterationSectionsVariables,
  });
  const sections = sectionsData?.sections.items;

  const [checkNameAvailability] = useLazyQuery(CHECK_ITERATION_SECTION_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const checkSectionNameAvailability = useCallback(
    (resourceId?: number) => async (name: string) => {
      if (iterationId && name) {
        const { data: { checkIterationStepNameAvailability } = {} } = await checkNameAvailability({
          variables: {
            name,
            parentId: iterationId,
            resourceId,
          },
        });

        return !!checkIterationStepNameAvailability;
      }

      return true;
    },
    [iterationId],
  );

  const [deleteSection, { loading: deleting }] = useMutation(REMOVE_ITERATION_SECTION, {
    refetchQueries: ['getIterationById'],
    optimisticResponse: ({ id }) => ({
      removeIterationStep: id.toString(),
    }),
    update: (cache, { data }) => {
      if (data?.removeIterationStep) {
        flushFromGraphQLCache(cache, { id: data.removeIterationStep, __typename: 'IterationStep' });
      }
    },
  });

  const [updateIterationSection, { loading: updatingIterationSection }] = useMutation(UPDATE_ITERATION_SECTION, {
    refetchQueries: ['getIterationStepList'],
    awaitRefetchQueries: true,
  });

  const reorderSection = async (oldPosition: number, newPosition: number) => {
    const section = sections?.[oldPosition];

    if (!sections || !section) return;

    const oldData = apolloClient.readQuery({
      query: GET_ITERATION_SECTIONS,
      variables: getIterationSectionsVariables,
    });
    const oldSections = oldData?.sections?.items;

    if (!oldSections) return;

    apolloClient.writeQuery({
      query: GET_ITERATION_SECTIONS,
      variables: getIterationSectionsVariables,
      data: {
        sections: {
          ...oldData?.sections,
          items: getReorderedItems(oldSections, oldPosition, newPosition),
        },
      },
    });

    await updateIterationSection({
      variables: {
        id: section.id,
        updateModel: {
          name: section.name,
          index: newPosition,
        },
      },
    });
  };

  const [moveArtifactsCall, { loading: movingArtifacts }] = useMutation(MOVE_ARTIFACTS, {
    refetchQueries: ['getIterationById', 'getIterationStepList'],
    awaitRefetchQueries: true,
  });

  const readIterationStepFragment = (step: { __typename?: 'IterationStep'; id: number }) => {
    const id = apolloClient.cache.identify({ id: step.id, __typename: step.__typename });

    return apolloClient.cache.readFragment({
      id,
      fragment: IterationStepFragment,
      fragmentName: 'IterationStep',
    });
  };

  const insertItemsInList = <T>(list: T[], itemsToInsert: T[], indexToInsert: number): T[] => {
    return [...list.slice(0, indexToInsert), ...itemsToInsert, ...list.slice(indexToInsert, list.length)];
  };

  const getArtifactFromCache = (
    oldSectionData: GetIterationStepListQuery | null,
    oldContentData: GetIterationByIdQuery | null,
    { id: artifactId, stepId }: { stepId?: number; id: number },
  ) => {
    if (stepId === undefined) {
      return oldContentData?.getIterationById.paginatedArtifacts.items.find(({ id }) => id === artifactId);
    } else {
      const sectionItems = oldSectionData?.sections.items;
      if (!sectionItems) return;
      const currentStep = sectionItems.find((item) => item.id === stepId);
      if (currentStep) {
        const fragment = readIterationStepFragment(currentStep);
        return fragment?.paginatedArtifacts.items.find(({ id }) => id === artifactId);
      }
    }
  };

  const getArtifactsFromCache = (
    oldSectionData: GetIterationStepListQuery | null,
    oldContentData: GetIterationByIdQuery | null,
    artifactsId: { stepId?: number; id: number }[],
  ) => {
    return artifactsId.map((artifactId) => {
      const artifact = getArtifactFromCache(oldSectionData, oldContentData, artifactId);
      return {
        stepId: artifactId.stepId,
        artifact,
      };
    }) as { artifact: IterationStepArtifactFragment; stepId: number }[];
  };

  const filterExistingArtifacts = (
    items: ({
      __typename?: 'IterationStepArtifact';
      id: number;
      type: IterationStepArtifactType;
      index: number;
    } & {
      ' $fragmentRefs'?: {
        IterationStepArtifactFragment: IterationStepArtifactFragment;
      };
    })[],
    artifacts: IterationStepArtifactFragment[],
  ) => {
    const artifactIdRecord: Record<number, true> = artifacts.reduce<Record<number, true>>((acc, { id }) => {
      acc[id] = true;
      return acc;
    }, {});
    return items.filter(({ id }) => !artifactIdRecord[id]);
  };

  const moveArtifactsInSectionCache = (
    oldSectionData: GetIterationStepListQuery | null,
    artifactsWithSteps: { artifact: IterationStepArtifactFragment; stepId: number }[],
    targetStepId?: number,
    indexInTargetStep = 0,
  ) => {
    const sectionItems = oldSectionData?.sections.items;
    if (!sectionItems) return;
    const artifactMapByStepId: Record<number, IterationStepArtifactFragment[]> = artifactsWithSteps.reduce<
      Record<number, IterationStepArtifactFragment[]>
    >((acc, { artifact, stepId }) => {
      if (!acc[stepId]) acc[stepId] = [];
      acc[stepId].push(artifact);
      return acc;
    }, {});
    const artifacts = artifactsWithSteps.map(({ artifact }) => artifact);
    const newSectionItems = sectionItems.map((section) => {
      const artifactsInStep = artifactMapByStepId[section.id] ?? [];
      const shouldFilter = artifactsInStep.length > 0;
      const shouldInsert = section.id === targetStepId;
      if (shouldInsert || shouldFilter) {
        const fragment = readIterationStepFragment(section);
        if (!fragment) return section;
        const items = fragment.paginatedArtifacts.items;
        const filtered = shouldFilter
          ? filterExistingArtifacts(fragment.paginatedArtifacts.items, artifactsInStep)
          : items;
        const newItems = shouldInsert ? insertItemsInList(filtered, artifacts, indexInTargetStep) : filtered;
        return {
          ...section,
          ...fragment,
          paginatedArtifacts: {
            ...fragment.paginatedArtifacts,
            items: newItems,
            total: newItems.length,
          },
        };
      }

      return section;
    });

    apolloClient.cache.writeQuery({
      query: GET_ITERATION_SECTIONS,
      variables: getIterationSectionsVariables,
      data: {
        sections: {
          ...oldSectionData,
          items: [...newSectionItems],
        },
      } as GetIterationStepListQuery,
    });
  };

  const moveArtifactInContentCache = (
    oldContentData: GetIterationByIdQuery | null,
    artifactsWithSteps: { artifact: IterationStepArtifactFragment; stepId: number }[],
    targetStepId?: number,
    indexInTargetStep = 0,
  ) => {
    if (!oldContentData) return;

    const artifactsInStep = artifactsWithSteps
      .filter(({ stepId }) => stepId === undefined)
      .map(({ artifact }) => artifact);
    const artifacts = artifactsWithSteps.map(({ artifact }) => artifact);

    const shouldFilter = artifactsInStep.length > 0;
    const shouldInsert = targetStepId === undefined;
    const items = oldContentData.getIterationById?.paginatedArtifacts.items;
    const filtered = shouldFilter ? filterExistingArtifacts(items, artifactsInStep) : items;
    const newItems = shouldInsert ? insertItemsInList(filtered, artifacts, indexInTargetStep) : filtered;

    apolloClient.cache.writeQuery({
      query: GET_ITERATION_BY_ID,
      variables: getIterationContentVariables,
      data: {
        getIterationById: {
          ...oldContentData.getIterationById,
          paginatedArtifacts: {
            ...oldContentData.getIterationById?.paginatedArtifacts,
            items: newItems,
            total: newItems.length,
          },
        },
      },
    });
  };

  const moveArtifactInCache = (
    oldSectionData: GetIterationStepListQuery | null,
    oldContentData: GetIterationByIdQuery | null,
    artifactsIdsWithStepIds: { stepId?: number; id: number }[],
    targetStepId?: number,
    indexInTargetStep?: number,
  ) => {
    const artifacts = getArtifactsFromCache(oldSectionData, oldContentData, artifactsIdsWithStepIds);
    if (artifacts.length === 0) return;

    moveArtifactsInSectionCache(oldSectionData, artifacts, targetStepId, indexInTargetStep);
    moveArtifactInContentCache(oldContentData, artifacts, targetStepId, indexInTargetStep);
  };

  // as multi drag and drop is a virtual multi move and only a single item moves, we need to take into consideration items that are selected in the target section and that are before the targetStepIndex
  const computeRealTargetIndex = (
    oldSectionData: GetIterationStepListQuery | null,
    oldContentData: GetIterationByIdQuery | null,
    artifactsId: { stepId?: number; id: number }[],
    draggedId: { stepId?: number; id: number },
    targetStepId?: number,
    indexInTargetStep = 0,
  ): number => {
    let items;
    if (targetStepId === undefined) {
      items = oldContentData?.getIterationById.paginatedArtifacts.items;
    } else {
      const section = oldSectionData?.sections.items.find(({ id }) => id === targetStepId);
      if (!section) return indexInTargetStep;
      items = readIterationStepFragment(section)?.paginatedArtifacts.items;
    }
    if (!items) return indexInTargetStep;

    const artifactIdRecord: Record<number, true> = artifactsId.reduce<Record<number, true>>((acc, { id }) => {
      acc[id] = true;
      return acc;
    }, {});

    const itemsBeforeIndexInTargetStep = items.reduce<number>((acc, artifact, index) => {
      if (index > indexInTargetStep) return acc;
      if (artifact.id === draggedId.id) return acc;
      if (artifactIdRecord[artifact.id]) return acc + 1;
      return acc;
    }, 0);
    return indexInTargetStep - itemsBeforeIndexInTargetStep;
  };

  const moveArtifacts = useCallback(
    async (
      artifactsId: { stepId?: number; id: number }[],
      draggedId: { stepId?: number; id: number },
      targetStepId?: number,
      indexInTargetStep = 0,
    ) => {
      if (!iterationId) return;
      const oldSectionData = apolloClient.cache.readQuery({
        query: GET_ITERATION_SECTIONS,
        variables: getIterationSectionsVariables,
      });

      const oldContentData = apolloClient.cache.readQuery({
        query: GET_ITERATION_BY_ID,
        variables: getIterationContentVariables,
      });

      const realTargetIndex = computeRealTargetIndex(
        oldSectionData,
        oldContentData,
        artifactsId,
        draggedId,
        targetStepId,
        indexInTargetStep,
      );

      moveArtifactInCache(oldSectionData, oldContentData, artifactsId, targetStepId, realTargetIndex);
      await moveArtifactsCall({
        variables: {
          iterationId,
          artifactsIdList: artifactsId.map(({ id }) => id),
          targetStepId,
          targetStepIndex: realTargetIndex,
        },
      });
    },
    [iterationId],
  );

  return {
    deleting,
    loadingSections,
    updatingIterationSection,
    movingArtifacts,
    sections,
    checkSectionNameAvailability,
    deleteSection,
    moveArtifacts,
    refreshSections: refetch,
    reorderSection,
  };
};
