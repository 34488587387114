import { FragmentType, graphql } from '../gql';

export const UserFragment = graphql(`
  fragment userFields on User {
    id
    avatarId
    name
    email
    color
  }
`);

export const UserPreferencesFragment = graphql(`
  fragment userPreferences on UserPreferencesOutput {
    onboardingStatus
  }
`);

export type UserIdentity = FragmentType<typeof UserFragment>;

export const ModelVersionInformationFragment = graphql(`
  fragment modelVersionInformationFields on ModelVersion {
    vecticeId
    versionNumber
    model {
      name
    }
  }
`);
export type ModelVersionInformation = FragmentType<typeof ModelVersionInformationFragment>;

graphql(`
  fragment metricsFields on EntityMetric {
    id
    key
    value
  }
`);

graphql(`
  fragment propertiesFields on EntityProperty {
    id
    key
    value
  }
`);

graphql(`
  fragment pageAfterCursorFields on PageOutput {
    afterCursor
    hasNextPage
  }
`);

graphql(`
  fragment pageIndexFields on PageOutput {
    index
    size
  }
`);

export const EntityFileRowFragment = graphql(`
  fragment entityFileFragment on EntityFile {
    id
    fileName
    mimeType
    hasPreview
  }
`);

export const AttachmentFragment = graphql(`
  fragment attachmentFragment on AttachmentOutput {
    id
    attachmentType
    name
    entityId
    type
    content
    mimeType
    hasPreview
  }
`);
