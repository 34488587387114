import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { getAuthenticationProviderInfo } from '../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../gql';
import { buildLink, VecticeRoutes } from '../../routes';
import { Badge, FlexContainer, Icon, Typography } from '../../ui';

import styles from './AuthMethodCard.module.scss';

const AuthMethodCardFragment = graphql(`
  fragment authMethodCard on KeycloakIdentityProvider {
    alias
    displayName
    enabled
    providerId
  }
`);

let globalId = 0;

interface AuthMethodCardProps {
  identityProvider: FragmentType<typeof AuthMethodCardFragment>;
  className?: string;
}

export const AuthMethodCard = ({ className, ...props }: AuthMethodCardProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`auth-method-card-${globalId++}`);

  const identityProvider = useFragment(AuthMethodCardFragment, props.identityProvider);
  const { icon: providerIcon } = getAuthenticationProviderInfo(identityProvider.providerId);

  return (
    <FlexContainer
      component={Link}
      to={buildLink(VecticeRoutes.ORGANIZATION_AUTHENTICATION_EDIT, { ...identityProvider })}
      aria-labelledby={ariaId}
      className={styles.card}
      gap={16}
    >
      <Icon icon={providerIcon} size={48} />
      <FlexContainer gap={2} direction="column" className={styles.content}>
        <Typography id={ariaId} variant="callout" weight="semi-bold" color="primary" className={styles.label}>
          {identityProvider.displayName || identityProvider.alias}
        </Typography>
        <FlexContainer justify="space-between">
          <Typography color="tertiary" variant="callout">
            {identityProvider.providerId}
          </Typography>
          <Badge color={identityProvider.enabled ? 'success' : 'danger'} size="sm">
            {identityProvider.enabled
              ? $t({ id: 'AuthenticationMethodSettings.status.enabled', defaultMessage: 'enabled' })
              : $t({ id: 'AuthenticationMethodSettings.status.disabled', defaultMessage: 'disabled' })}
          </Badge>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
