import cn from 'classnames';
import React from 'react';

import styles from './Tabs.module.scss';

export const Tabs = ({ children, className, ...props }: VecticeHTMLProps<HTMLDivElement>) => (
  <nav {...props}>
    <ul className={cn(styles.tabs, className)}>{children}</ul>
  </nav>
);
