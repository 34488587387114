import React from 'react';

import { ApiKey } from '../../gql/graphql';
import { ModalPrompt, Typography } from '../../ui';

interface DeleteApiKeyModalProps {
  apiKey: ApiKey;
  isActive?: boolean;
  onDelete: () => void;
  onDeactivate: () => void;
  onCancel: () => void;
}

export const DeleteApiKeyModal = ({
  apiKey,
  isActive = true,
  onDelete,
  onDeactivate,
  onCancel,
}: DeleteApiKeyModalProps) => (
  <ModalPrompt
    title={$t({ id: 'DeleteApiKey.deleteApiToken', defaultMessage: 'Delete API Key' })}
    confirmLabel={$t({ id: 'modal.delete', defaultMessage: 'Yes, delete' })}
    cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
    destructive
    onClose={onCancel}
    onConfirm={onDelete}
  >
    {isActive
      ? $t({
          id: 'DeleteApiKey.warning.active',
          defaultMessage:
            'Are you sure you want to delete <bold>{name}</bold> API Key? Any existing integrations will stop working and you will not be able to retrieve it. You can {deactivateLink} it instead.',
          values: {
            name: apiKey.name,
            deactivateLink: (
              <Typography color="accent" onClick={onDeactivate} style={{ cursor: 'pointer' }} role="button">
                {$t({ id: 'DeleteApiKey.deactivate', defaultMessage: 'deactivate' })}
              </Typography>
            ),
          },
        })
      : $t({
          id: 'DeleteApiKey.warning.inactive',
          defaultMessage:
            'Are you sure you want to delete <bold>{name}</bold> API Key? Any existing integrations will stop working and you will not be able to retrieve it.',
          values: {
            name: apiKey.name,
          },
        })}
  </ModalPrompt>
);
