import cn from 'classnames';
import React, { ElementType } from 'react';

import { Icon } from '../svg';
import { Typography } from '../typography';

import { ButtonProps } from './Button';

import styles from './Button.module.scss';

export const LinkAsButton = <E extends ElementType>({
  component,
  linkProps,
  variant = 'primary',
  color = 'accent',
  size = 'sm',
  fullWidth,
  rightIcon,
  leftIcon,
  disabled,
  className,
  children,
  ...props
}: Omit<ButtonProps, 'type'> & WithComponentProps<E>) => (
  <Typography
    component={disabled ? 'span' : component}
    className={cn(
      styles.button,
      styles[variant],
      styles[color],
      styles[size],
      {
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
        [styles.withChildren]: children,
      },
      className,
    )}
    {...props}
  >
    {leftIcon && <Icon icon={leftIcon} size={size === 'xs' ? 18 : 20} className={styles.leftIcon} />}
    {children}
    {rightIcon && <Icon icon={rightIcon} size={size === 'xs' ? 18 : 20} className={styles.rightIcon} />}
  </Typography>
);
