import React from 'react';

import { UserIdentity } from '../../../graphql/fragments';
import { FlexContainer } from '../../../ui';
import { isNil } from '../../../utils';
import { UserAvatar } from '../../asset-display';

import styles from './AvatarCell.module.scss';

interface AvatarCellProps {
  user?: UserIdentity | null;
}

export const AvatarCell = ({ user }: AvatarCellProps) => {
  if (isNil(user)) {
    return null;
  }

  return (
    <FlexContainer className={styles.avatar} justify="center">
      <UserAvatar user={user} size="sm" />
    </FlexContainer>
  );
};
