import { createContext, ChangeEvent } from 'react';

interface CheckboxGroupContextType {
  name: string;
  checkedValues?: any[] | null;
  disabled?: boolean;
  onChange?: (value: any, event: ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxGroupContext = createContext<CheckboxGroupContextType | null>(null);

export const CheckboxGroupContextProvider = CheckboxGroupContext.Provider;
