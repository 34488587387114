import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { Column, EmptySection, Pagination, Table, Tooltip, message } from '../../ui';
import { round } from '../../utils';
import { GET_ENTITY_METRICS } from '../version/getEntityMetrics.query';

const PAGE_SIZE = 5;

interface VersionMetricsProps {
  resourceId: string;
  readOnly?: boolean;
  emptyMessage?: string;
}

export const VersionMetricsWidget = ({ resourceId, emptyMessage }: VersionMetricsProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading } = useQuery(GET_ENTITY_METRICS, {
    fetchPolicy: 'no-cache',
    variables: {
      entityId: resourceId,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE,
    },
    onError: () =>
      message.error($t({ id: 'VersionMetrics.fetchError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const total = data?.getResource.total || 0;
  const metrics = data?.getResource.items || [];

  return (
    <>
      <Table
        data={metrics}
        tableLayout="fixed"
        size="md"
        rowKey="key"
        emptyText={
          <EmptySection
            message={
              emptyMessage ??
              $t({
                id: 'metrics.empty.message',
                defaultMessage: 'To display metrics, click the add button or use the Vectice API.',
              })
            }
          />
        }
        loading={loading}
      >
        <Column key="key" title="Metric" />
        <Column key="value" title="Value">
          {(value: number) => (
            <Tooltip text={value} placement="topLeft">
              <div>{round(value)}</div>
            </Tooltip>
          )}
        </Column>
      </Table>
      <Pagination pageSize={PAGE_SIZE} current={currentPage} total={total} onChange={setCurrentPage} />
    </>
  );
};
