import { useFeatureFlags } from '../context';

import { WithFeatureFlagsProps } from './interfaces';

export const useWithFeatureFlags = ({ featureFlag }: WithFeatureFlagsProps) => {
  const { featureFlags, loading, error } = useFeatureFlags();

  const allowed = !error && !loading && featureFlags?.find((element) => element.code === featureFlag)?.enabled;

  return {
    loading,
    error,
    allowed,
  };
};
