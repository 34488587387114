import React, { ElementType, FormEvent, ReactElement, ReactNode } from 'react';

import { Button } from '../button';
import { Form } from '../form';

import { Dialog, DialogActions, DialogContent, DialogProps } from './internals';

import styles from './internals/Dialog.module.scss';

interface ModalFormProps extends DialogProps {
  submitLabel?: ReactNode;
  cancelLabel: ReactNode;
  previousLabel?: ReactNode;
  submitIcon?: ElementType;
  contentClassName?: string;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void | Promise<void>;
  onPrevious?: () => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  extraAction?: ReactElement;
  autoComplete?: string;
}

export const ModalForm = ({
  submitLabel,
  cancelLabel,
  submitIcon,
  autoComplete,
  extraAction,
  previousLabel,
  contentClassName,
  onSubmit,
  onClose,
  onPrevious,
  isSubmitting,
  disabled,
  withBackground,
  children,
  ...props
}: ModalFormProps) => (
  <Dialog
    onClose={onClose}
    modalRender={(node) => (
      <Form onSubmit={onSubmit} autoComplete={autoComplete}>
        {node}
      </Form>
    )}
    {...props}
  >
    <DialogContent withBackground={withBackground} className={contentClassName}>
      {children}
    </DialogContent>
    <DialogActions>
      <Button variant="phantom" onClick={onClose} className={styles.dialog_cancel}>
        {cancelLabel}
      </Button>
      {onPrevious && (
        <Button variant="phantom" onClick={onPrevious}>
          {previousLabel}
        </Button>
      )}
      {extraAction}
      {!!submitLabel && (
        <Button type="submit" leftIcon={submitIcon} disabled={disabled} loading={isSubmitting}>
          {submitLabel}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
