import React from 'react';

import { getWorkspaceIcon, getWorkspaceTypeDisplayName } from '../../../entities/utils';
import { WorkspaceType } from '../../../gql/graphql';
import { AssetLink, Placement, Tooltip } from '../../../ui';

interface FormatWorkspaceProps {
  name?: string | null;
  ellipsis?: boolean;
  type?: WorkspaceType | null;
  iconSize?: number;
  placement?: Placement;
}

export const FormatWorkspace = ({ type, placement = 'bottom', ...props }: FormatWorkspaceProps) => (
  <Tooltip text={getWorkspaceTypeDisplayName(type)} placement={placement}>
    <AssetLink icon={getWorkspaceIcon(type, true)} color="inherit" {...props} />
  </Tooltip>
);
