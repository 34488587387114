import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ThreadRepliedContent } from '../../../entities';
import { getVecticeResourceTypeLabel } from '../../../utils';
import { FormatUserName } from '../../formatters';
import { ThreadNotificationProps, UserNotificationThreadReplied } from '../types';
import { getThreadNotificationInformation } from '../utils';

import { Notification } from './Notification';

export const ThreadRepliedNotification = ({
  notification: { content, creator, createdDate },
  onClose,
}: ThreadNotificationProps<UserNotificationThreadReplied>) => {
  const parsedContent: ThreadRepliedContent = useMemo(() => {
    try {
      return JSON.parse(content);
    } catch {
      return {};
    }
  }, [content]);

  const { type, link, title } = getThreadNotificationInformation(parsedContent);

  const { isThreadReopened, isUserMentioned } = parsedContent;

  const notificationWording = () => {
    if (isThreadReopened) {
      if (isUserMentioned)
        return $t({
          id: 'notification.thread.replied.reopenAndMentioned',
          defaultMessage: `<bold>{creator}</bold> reopened a comment and mentioned <bold>you</bold> in {type} <bold>{title}</bold>`,
          values: {
            creator: <FormatUserName user={creator} />,
            title,
            type: getVecticeResourceTypeLabel(type).toLowerCase(),
          },
        });
      return $t({
        id: 'notification.thread.replied.reopen',
        defaultMessage: `<bold>{creator}</bold> reopened a comment in {type} <bold>{title}</bold>`,
        values: {
          creator: <FormatUserName user={creator} />,
          title,
          type: getVecticeResourceTypeLabel(type).toLowerCase(),
        },
      });
    }
    if (isUserMentioned)
      return $t({
        id: 'notification.thread.replied.mentioned',
        defaultMessage: `<bold>{creator}</bold> mentioned <bold>you</bold> in a comment in {type} <bold>{title}</bold>`,
        values: {
          creator: <FormatUserName user={creator} />,
          title,
          type: getVecticeResourceTypeLabel(type).toLowerCase(),
        },
      });
    return $t({
      id: 'notification.thread.replied',
      defaultMessage: `<bold>{creator}</bold> replied to a comment in {type} <bold>{title}</bold>`,
      values: {
        creator: <FormatUserName user={creator} />,
        title,
        type: getVecticeResourceTypeLabel(type).toLowerCase(),
      },
    });
  };

  return (
    <Notification component={Link} to={link} creator={creator} createdDate={createdDate} onClose={onClose}>
      {notificationWording()}
    </Notification>
  );
};
