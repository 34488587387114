import React, { PropsWithChildren, ReactNode } from 'react';

import { Loading } from './Loading';

export interface WithLoadingProps extends VecticeHTMLProps<HTMLDivElement> {
  loading?: boolean;
  loadingColor?: 'primary' | 'white' | 'inherit';
  loadingSize?: number;
  skeleton?: ReactNode;
}

export const WithLoading = ({
  children,
  loading,
  loadingColor,
  loadingSize,
  skeleton,
  ...props
}: PropsWithChildren<WithLoadingProps>) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div role="alert" aria-live="polite" aria-label="Loading..." {...props}>
      {skeleton || <Loading size={loadingSize} color={loadingColor} />}
    </div>
  );
};
