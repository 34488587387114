import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { ModelRow } from './ModelRow';

const ModelListItemFragment = graphql(`
  fragment modelListItem on Model {
    vecticeId
    ...modelRow
  }
`);

let globalId = 0;

interface ModelListItemProps {
  model: FragmentType<typeof ModelListItemFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const ModelListItem = ({ project, workspace, ...props }: ModelListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`model-list-item-${globalId++}`);
  const model = useFragment(ModelListItemFragment, props.model);

  return (
    <AssetListItem to={buildLink(VecticeRoutes.MODEL, { modelId: model.vecticeId })} aria-labelledby={ariaId}>
      <ModelRow id={ariaId} model={model} project={project} workspace={workspace} />
    </AssetListItem>
  );
};
