import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/flat/ic-dataset-version.svg';
import { getDataSourceFileCount, getDataSourceSize } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { DatasetSourceUsage } from '../../../gql/graphql';
import { AssetTooltip, Badge, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { FormatDate, FormatFileSize, FormatUserName } from '../../formatters';

import styles from './DatasetVersionTooltip.module.scss';

export const GET_DATASET_VERSION_TOOLTIP = graphql(`
  query getDataSetVersionTooltip($datasetVersionId: VecticeId!) {
    getDatasetVersion(datasetVersionId: $datasetVersionId) {
      vecticeId
      dataSet {
        vecticeId
        name
        type
      }
      versionNumber
      datasetSources {
        id
        usage
        size
        itemsCount
      }
      properties {
        id
      }
      createdBy {
        ...userFields
      }
      createdDate
    }
  }
`);

interface DatasetVersionTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const DatasetVersionTooltip = ({ id, placement = 'top', children }: DatasetVersionTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`dataset-version-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_DATASET_VERSION_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      datasetVersionId: id!,
    },
  });

  const datasetVersion = data?.getDatasetVersion;
  const datasetSources = datasetVersion?.datasetSources;
  const hasTraining = datasetSources?.some((source) => source.usage === DatasetSourceUsage.Training);
  const hasTesting = datasetSources?.some((source) => source.usage === DatasetSourceUsage.Testing);
  const hasValidation = datasetSources?.some((source) => source.usage === DatasetSourceUsage.Validation);

  useEffect(() => {
    setDataLoaded(!!datasetVersion || !!error);
  }, [datasetVersion, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={DatasetVersionIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.DATASET_VERSION)}
      label={datasetVersion?.dataSet.name}
      versionNumber={datasetVersion?.versionNumber}
      deleted={!!error}
    >
      {datasetVersion && (
        <>
          <DataList>
            <Typography id={`created-${ariaId}`} component="dt" variant="callout" color="white">
              Created
            </Typography>
            <Typography aria-labelledby={`created-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
              <FormatDate date={datasetVersion.createdDate} fromNow /> by{' '}
              <FormatUserName user={datasetVersion.createdBy} />
            </Typography>
            {!!datasetSources && (
              <>
                {hasTraining && (
                  <>
                    <Typography id={`testing-resources-${ariaId}`} component="dt" variant="callout" color="white">
                      {getDataSourceFileCount(datasetSources, DatasetSourceUsage.Testing)} Testing Resources
                    </Typography>
                    <Typography
                      aria-labelledby={`testing-resources-${ariaId}`}
                      component="dd"
                      variant="callout"
                      color="white"
                      paragraph
                    >
                      <FormatFileSize size={getDataSourceSize(datasetSources, DatasetSourceUsage.Testing)} />
                    </Typography>
                  </>
                )}
                {hasTesting && (
                  <>
                    <Typography id={`training-resources-${ariaId}`} component="dt" variant="callout" color="white">
                      {getDataSourceFileCount(datasetSources, DatasetSourceUsage.Training)} Training Resources
                    </Typography>
                    <Typography
                      aria-labelledby={`training-resources-${ariaId}`}
                      component="dd"
                      variant="callout"
                      color="white"
                      paragraph
                    >
                      <FormatFileSize size={getDataSourceSize(datasetSources, DatasetSourceUsage.Training)} />
                    </Typography>
                  </>
                )}
                {hasValidation && (
                  <>
                    <Typography id={`validation-resources-${ariaId}`} component="dt" variant="callout" color="white">
                      {getDataSourceFileCount(datasetSources, DatasetSourceUsage.Validation)} Validation Resources
                    </Typography>
                    <Typography
                      aria-labelledby={`validation-resources-${ariaId}`}
                      component="dd"
                      variant="callout"
                      color="white"
                      paragraph
                    >
                      <FormatFileSize size={getDataSourceSize(datasetSources, DatasetSourceUsage.Validation)} />
                    </Typography>
                  </>
                )}
                {!hasTraining && !hasTesting && !hasValidation && (
                  <>
                    <Typography id={`files-${ariaId}`} component="dt" variant="callout" color="white">
                      {datasetSources[0]?.itemsCount ?? 0} Resources
                    </Typography>
                    <Typography
                      aria-labelledby={`files-${ariaId}`}
                      component="dd"
                      variant="callout"
                      color="white"
                      paragraph
                    >
                      <FormatFileSize size={datasetSources[0]?.size} />
                    </Typography>
                  </>
                )}
              </>
            )}
          </DataList>
          <div className={styles.badges}>
            <Badge contrast size="sm">
              {datasetVersion.properties.length} Properties
            </Badge>
          </div>
        </>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
