import React from 'react';

import { Typography } from '../../ui';

interface GlobalSearchResultsProps {
  selectedAssetsCount?: number;
}

export const GlobalSearchResults = ({ selectedAssetsCount }: GlobalSearchResultsProps) => {
  const displayedCount = selectedAssetsCount ?? '-';

  return (
    <Typography paragraph variant="callout" color="tertiary">
      {$t({
        id: 'component.GlobalSearchResults.results',
        defaultMessage: '{results} Sorted By Last Update',
        values: {
          results: $t({
            id: 'component.GlobalSearchResults.count',
            defaultMessage: '{count, plural, =0 {No Results} one {# Result} other {# Results}}',
            values: {
              count: displayedCount,
            },
          }),
        },
      })}
    </Typography>
  );
};
