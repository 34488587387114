import { VecticeResourceType } from '../../utils';

// /!\ DO NOT UPDATE ENUM ORDER, ALWAYS ADD NEW OPTIONS AT THE END, DO NOT REMOVE OR REPLACE OPTIONS
export enum AssetOption {
  lineage,
  metrics,
  properties,
  attachments,
  resources,
}

export const assetOptions: Record<string, AssetOption[]> = {
  [VecticeResourceType.DATASET_VERSION]: [
    AssetOption.lineage,
    AssetOption.properties,
    AssetOption.attachments,
    AssetOption.resources,
  ],
  [VecticeResourceType.MODEL_VERSION]: [
    AssetOption.lineage,
    AssetOption.properties,
    AssetOption.metrics,
    AssetOption.attachments,
  ],
};

export function showOption(option: AssetOption, options: number | undefined = 0) {
  const mask = 1 << option;
  return !!(options & mask);
}

export const showLineage = (options: number | undefined) => showOption(AssetOption.lineage, options);

export const showMetrics = (options: number | undefined) => showOption(AssetOption.metrics, options);

export const showProperties = (options: number | undefined) => showOption(AssetOption.properties, options);

export const showAttachments = (options: number | undefined) => showOption(AssetOption.attachments, options);

export const showResources = (options: number | undefined) => showOption(AssetOption.resources, options);

export function toggleOption(option: AssetOption, options: number | undefined = 0) {
  const mask = 1 << option;
  return options ^ mask;
}

export function clearOptions(optionsToClear: AssetOption[], options: number | undefined = 0) {
  let updatedOptions = options;

  optionsToClear.forEach((option) => {
    const mask = 1 << option;
    updatedOptions &= ~mask;
  });

  return updatedOptions;
}

export function setOptions(optionsToSet: AssetOption[], options: number | undefined = 0) {
  let updatedOptions = options;

  optionsToSet.forEach((option) => {
    const mask = 1 << option;
    updatedOptions |= mask;
  });

  return updatedOptions;
}

export function setAllOptionsOn(type: VecticeResourceType | undefined) {
  if (!type) return 0;
  if (!assetOptions[type]) return 0;
  return setOptions(assetOptions[type], 0);
}
