import { parseJobArtifacts } from '../../../../entities/utils';
import { CodeLineageUpdateInput, CodeVersionType, GetCodeArtifactByJobRunIdQuery } from '../../../../gql/graphql';

export const codeLineageDefaultValues = (data: GetCodeArtifactByJobRunIdQuery): CodeLineageUpdateInput | null => {
  const jobArtifacts = data?.getJobArtifactByJobRunId?.items;

  const { code } = parseJobArtifacts(jobArtifacts);

  const gitVersion = code?.codeVersion?.gitVersion;
  const dataBrickVersion = code?.codeVersion?.databricksVersion;
  const otherCodeVersion = code?.codeVersion?.otherCodeVersion;

  if (gitVersion)
    return {
      type: CodeVersionType.Git,
      url: gitVersion?.uri ?? '',
      firstAdditionalInformation: gitVersion?.branchName,
      secondAdditionalInformation: gitVersion?.commitHash,
      thirdAdditionalInformation: gitVersion?.additionalInformation,
    };
  else if (dataBrickVersion)
    return {
      type: CodeVersionType.Databricks,
      url: dataBrickVersion?.urlNotebook ?? '',
      firstAdditionalInformation: dataBrickVersion?.relativePath ?? '',
      secondAdditionalInformation: dataBrickVersion?.timestamp,
      thirdAdditionalInformation: dataBrickVersion?.additionalInformation,
    };
  else if (otherCodeVersion)
    return {
      type: CodeVersionType.Other,
      url: otherCodeVersion?.url ?? '',
      firstAdditionalInformation: otherCodeVersion?.firstAdditionalInformation ?? '',
      secondAdditionalInformation: otherCodeVersion?.secondAdditionalInformation,
      thirdAdditionalInformation: otherCodeVersion?.thirdAdditionalInformation,
    };
  return null;
};
