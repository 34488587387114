import React from 'react';
import { useLocation } from 'react-router-dom';

import { isWorkspaceRoute } from '../../routes';
import { Header } from '../../ui';

import { ProjectNavigation } from './ProjectNavigation';
import { WorkspaceNavigation } from './WorkspaceNavigation';

import styles from './WorkspacePageHeader.module.scss';

export const WorkspacePageHeader = () => {
  const location = useLocation();

  return (
    <Header className={styles.header}>
      {isWorkspaceRoute(location.pathname) ? <WorkspaceNavigation /> : <ProjectNavigation />}
    </Header>
  );
};
