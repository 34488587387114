import React from 'react';

import config from '../config';
import { Modal } from '../ui';

export interface ContactSupportModalProps {
  onClose?: () => void;
}

export const ContactSupportModal = ({ onClose }: ContactSupportModalProps) => (
  <Modal
    title={$t({ id: 'modal.contactSupport.title', defaultMessage: 'Need Help?' })}
    closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
    visible
    onClose={onClose}
  >
    {$t({
      id: 'modal.contactSupport.content',
      defaultMessage: 'Please contact <support>{supportEmail}</support> for questions, or to send feedback.',
      values: { supportEmail: config.SUPPORT_EMAIL },
    })}
  </Modal>
);
