import { useMutation } from '@apollo/client';
import React from 'react';

import { ColumnSourceType, DatasetSourceType } from '../../../gql/graphql';
import { useWithRoles, WithFeatureFlags } from '../../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../../guards/utils';
import { DataEntry, DataList, FlexContainer, Icon, Loading, message, Tooltip, Typography } from '../../../ui';
import { getDbTableTypeIcon, getMimeTypeIcon, VecticeResourceType } from '../../../utils';
import { AssetSelector } from '../../asset-selector';
import { FormatDate, FormatFileSize, FormatNumber } from '../../formatters';
import { useDatasetSource } from '../hooks';
import { DatasetSourceColumns } from '../source-columns';

import { UPDATE_TARGET_COLUMN } from './updateTargetColumn.mutation';

import styles from './ResourcePreview.module.scss';

interface ResourcePreviewProps {
  datasetVersionId: string;
  targetColumn?: string;
  resourceId?: number;
  sourceType?: DatasetSourceType;
}

export const ResourcePreview = ({ datasetVersionId, targetColumn, resourceId, sourceType }: ResourcePreviewProps) => {
  const { dbSourceDetails, fileSourceDetails, loading } = useDatasetSource(resourceId, sourceType);
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });
  const sourceDetails = dbSourceDetails || fileSourceDetails;

  const [updateTargetColumn] = useMutation(UPDATE_TARGET_COLUMN, {
    optimisticResponse: ({ datasetVersionId: vecticeId, targetColumn }) => ({
      updateDatasetVersion: {
        vecticeId: vecticeId.toString(),
        targetColumn: targetColumn?.toString(),
      },
    }),
    onCompleted: () =>
      message.success($t({ id: 'resourcePreview.update.success', defaultMessage: 'Target column has been updated' })),
    onError: (error) => message.error(error.message),
  });

  if (loading) {
    return (
      <div className={styles.container}>
        <FlexContainer justify="center" className={styles.loading}>
          <Loading />
        </FlexContainer>
      </div>
    );
  }

  if (!sourceDetails) {
    return <div className={styles.container} />;
  }

  const mimeTypeIcon = 'mimeType' in sourceDetails ? getMimeTypeIcon(sourceDetails.mimeType) : null;
  const tableTypeIcon = 'type' in sourceDetails ? getDbTableTypeIcon(sourceDetails.type) : null;

  const sourceTypeIcon = mimeTypeIcon ?? tableTypeIcon;

  return (
    <div className={styles.container}>
      <div className={styles.metadata}>
        <FlexContainer gap={4} className={styles.header}>
          {sourceTypeIcon && <Icon icon={sourceTypeIcon} />}
          <Tooltip text={sourceDetails.name} placement="topLeft">
            <Typography variant="subtitle" weight="semi-bold" color="primary" ellipsis>
              {sourceDetails.name}
            </Typography>
          </Tooltip>
        </FlexContainer>
        <DataList gutterBottom>
          {'size' in sourceDetails && (
            <DataEntry
              label={$t({ id: 'datasetSource.preview.size.label', defaultMessage: 'Resource Size:' })}
              verticalAlign="top"
            >
              <FormatFileSize size={sourceDetails.size} />
            </DataEntry>
          )}
          {'uri' in sourceDetails && (
            <DataEntry
              label={$t({ id: 'datasetSource.preview.uri.label', defaultMessage: 'Path:' })}
              verticalAlign="top"
            >
              {sourceDetails.uri ?? $t({ id: 'datasetSource.preview.uri.noValue', defaultMessage: 'N/A' })}
            </DataEntry>
          )}
          <DataEntry
            label={$t({ id: 'datasetSource.preview.columnsNumber.label', defaultMessage: 'Nb of Columns:' })}
            verticalAlign="top"
          >
            {<FormatNumber value={sourceDetails.columnsNumber} /> ??
              $t({ id: 'datasetSource.preview.columnsNumber.noValue', defaultMessage: 'N/A' })}
          </DataEntry>
          <DataEntry
            label={$t({ id: 'datasetSource.preview.rowsNumber.label', defaultMessage: 'Nb of Rows:' })}
            verticalAlign="top"
          >
            {<FormatNumber value={sourceDetails.rowsNumber} /> ??
              $t({ id: 'datasetSource.preview.rowsNumber.noValue', defaultMessage: 'N/A' })}
          </DataEntry>
          {!!sourceDetails.createdDate && (
            <DataEntry
              label={$t({ id: 'datasetSource.preview.createdDate.label', defaultMessage: 'Created:' })}
              verticalAlign="top"
            >
              <FormatDate date={sourceDetails.createdDate} />
            </DataEntry>
          )}
          {!!sourceDetails.updatedDate && (
            <DataEntry
              label={$t({ id: 'datasetSource.preview.updatedDate.label', defaultMessage: 'Updated:' })}
              verticalAlign="top"
            >
              <FormatDate date={sourceDetails.updatedDate} />
            </DataEntry>
          )}
          {sourceDetails.extraMetadata?.map(({ key, value, displayName }) => (
            <DataEntry label={`${displayName ?? key}:`} verticalAlign="top">
              {value ?? $t({ id: 'datasetSource.preview.extraMetadata.noValue', defaultMessage: 'N/A' })}
            </DataEntry>
          ))}
          {sourceType && (
            <WithFeatureFlags featureFlag="target-column">
              <DataEntry
                label={$t({ id: 'datasetSource.preview.targetColumn.label', defaultMessage: 'Target column:' })}
                verticalAlign="center"
              >
                <AssetSelector
                  assetType={VecticeResourceType.SOURCE_COLUMN}
                  fileType={
                    sourceType === DatasetSourceType.Db
                      ? ColumnSourceType.DbColumnSource
                      : ColumnSourceType.FileSourceColumn
                  }
                  disabled={!canEdit}
                  fileId={resourceId ?? undefined}
                  selectedAssetId={targetColumn}
                  onSelect={(selectedTargetColumn) => {
                    if (selectedTargetColumn !== targetColumn) {
                      updateTargetColumn({
                        variables: {
                          datasetVersionId: datasetVersionId,
                          targetColumn: selectedTargetColumn,
                        },
                      });
                    }
                  }}
                />
              </DataEntry>
            </WithFeatureFlags>
          )}
        </DataList>
        <DatasetSourceColumns
          datasetVersionId={datasetVersionId}
          skippedStatistics={sourceDetails.skippedStatistics}
          sourceId={resourceId}
          sourceType={sourceType}
        />
      </div>
    </div>
  );
};
