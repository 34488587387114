import React from 'react';

import { Button } from '../button';
import { Dropdown } from '../dropdown';
import { Menu, MenuItem } from '../menu';

import { FilterCount, FilterPill } from './internal';

import styles from './FilterPills.module.scss';

interface FilterPillsProps {
  filters: Record<string, any>;
  plugins: Record<string, { label: string }>;
  nbOfVisiblePills?: number;
  resetLabel: string;
  onSelectFilter: (field: string) => void;
  onClearFilter: (field: string) => void;
  onClearAllFilters: () => void;
}

export const FilterPills = ({
  filters,
  plugins,
  nbOfVisiblePills = 3,
  resetLabel,
  onSelectFilter,
  onClearFilter,
  onClearAllFilters,
}: FilterPillsProps) => {
  const fields = Object.keys(filters);

  if (fields?.length) {
    const visibleFields = fields.slice(0, nbOfVisiblePills);
    const moreFields = fields.slice(nbOfVisiblePills);

    const menu = (
      <Menu>
        {moreFields.map((field) =>
          plugins[field] ? (
            <MenuItem key={field} onClick={() => onSelectFilter(field)}>
              {plugins[field].label} <FilterCount filter={filters[field]} className={styles.count} />
            </MenuItem>
          ) : null,
        )}
      </Menu>
    );

    return (
      <>
        {visibleFields.map((field) =>
          plugins[field] ? (
            <FilterPill key={field} onClick={() => onSelectFilter(field)} onClear={() => onClearFilter(field)}>
              {plugins[field].label} <FilterCount filter={filters[field]} className={styles.count} />
            </FilterPill>
          ) : null,
        )}
        {!!moreFields.length && (
          <Dropdown overlay={menu} trigger="click">
            <FilterPill>
              + {moreFields.length} {moreFields.length > 1 ? 'Filters' : 'Filter'}
            </FilterPill>
          </Dropdown>
        )}
        {!!visibleFields.length && (
          <Button
            className={styles.reset_button}
            title={resetLabel}
            aria-label={resetLabel}
            variant="phantom"
            onClick={onClearAllFilters}
          >
            {resetLabel}
          </Button>
        )}
      </>
    );
  }

  return null;
};
