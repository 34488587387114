import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { CHECK_CDTTEMPLATE_NAME_AVAILABILITY } from '../graphql/queries';

export const useCheckTemplateNameAvailability = (resourceId: number) => {
  const [checkNameAvailability] = useLazyQuery(CHECK_CDTTEMPLATE_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
  });

  const checkTemplateNameAvailability = useCallback(
    async (value: string) => {
      if (!value) return true;
      const { data: { checkCDTTemplateNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          name: value,
          resourceId,
          isRichText: true,
        },
      });
      return !!checkCDTTemplateNameAvailability;
    },
    [checkNameAvailability],
  );

  return { checkTemplateNameAvailability };
};
