import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReviewNotificationProjectContent } from '../../../entities';
import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationProjectStatusChanged } from '../types';

import { Notification } from './Notification';

interface ProjectStatusChangedNotificationProps {
  notification: UserNotificationProjectStatusChanged;
  onClose: () => void;
}

const GET_PROJECT_BY_ID = graphql(`
  query getProjectForProjectStatusChange($id: VecticeId!) {
    getProjectById(projectId: $id) {
      vecticeId
      name
    }
  }
`);

export const ProjectStatusChangedNotification = ({
  notification: { creator, content, createdDate },
  onClose,
}: ProjectStatusChangedNotificationProps) => {
  const parsedContent: ReviewNotificationProjectContent = JSON.parse(content);
  const projectId = parsedContent?.projectId;

  const { data, loading, error } = useQuery(GET_PROJECT_BY_ID, {
    skip: !projectId,
    variables: {
      id: projectId!,
    },
  });
  const project = data?.getProjectById;

  if (!project || loading) {
    return null;
  }

  return (
    <Notification
      component={!error ? Link : undefined}
      to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.project.statusChanged',
        defaultMessage: '<bold>{creator}</bold> updated the status of project <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title: project.name || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
        },
      })}
    </Notification>
  );
};
