import React, { Suspense, useEffect, useRef, useState } from 'react';

import { ReactComponent as DownloadIcon } from '../../assets/icons/interface/ic-download.svg';
import config from '../../config';
import { useApiKey } from '../../hooks';
import { Button, Loading, Modal, Tab, Tabs, Typography, WithLoading } from '../../ui';
import { autologApiKeySnippet, downloadFile, getConnectionSnippet } from '../../utils';
import { VecticeCodeSnippets } from '../code-snippets/VecticeCodeSnippets';
import { CopyInput } from '../copy-on-click';

interface ApiKeyModalProps {
  keyName?: string;
  onClose?: () => void;
}

const ApiKeyTabs = ({ apiKey }: { apiKey: string }) => {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Tabs>
        {[{ tabContent: 'Autolog' }, { tabContent: 'Core API' }].map(({ tabContent }, index) => (
          <Tab key={`outputs-${index + 1}`} onClick={() => setTab(index)} active={index === tab}>
            {tabContent}
          </Tab>
        ))}
      </Tabs>
      <Suspense fallback={<Loading />}>
        <VecticeCodeSnippets
          tab={tab}
          snippets={[[{ code: autologApiKeySnippet(apiKey) }], [{ code: getConnectionSnippet(apiKey) }]][tab]}
        />
      </Suspense>
    </>
  );
};

export const ApiKeyModal = ({ keyName, onClose }: ApiKeyModalProps) => {
  const { current: apiKeyName } = useRef(keyName ?? `tmp_${new Date().toISOString()}`);
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState<string>();

  const { generateApiKey } = useApiKey();

  useEffect(() => {
    generateApiKey(apiKeyName)
      .then(({ data }) => {
        if (data) {
          setApiKey(data.generateApiKey.key);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const downloadKey = () => {
    const filename = `${apiKeyName}.json`;
    const contentType = 'application/json;charset=utf-8;';
    const jsonValues = { name: apiKeyName, VECTICE_API_TOKEN: apiKey, VECTICE_HOST: config.ORIGIN };
    const url = `data:${contentType},${encodeURIComponent(JSON.stringify(jsonValues))}`;
    downloadFile(url, filename);
  };

  return (
    <Modal
      title={$t({ id: 'modal.apiKey.title', defaultMessage: 'Create API key' })}
      closeLabel={$t({ id: 'modal.apiKey.actions.close', defaultMessage: 'Close' })}
      onClose={onClose}
      extraAction={
        <Button leftIcon={DownloadIcon} variant="phantom" onClick={downloadKey}>
          {$t({ id: 'modal.apiKey.actions.download', defaultMessage: 'Download key' })}
        </Button>
      }
    >
      <WithLoading loading={loading}>
        <Typography gutterBottom paragraph variant="callout">
          {$t({
            id: 'modal.apiKey.hint',
            defaultMessage:
              'Create your Vectice API key for easy app access and workflow integration. Choose to copy and securely store the key, or download it as a JSON file.',
          })}
        </Typography>
        <Typography component="strong" gutterBottom paragraph variant="callout">
          {$t({
            id: 'modal.apiKey.warning',
            defaultMessage: 'Note: If you don’t copy or download the key now, you’ll lose access to it forever.',
          })}
        </Typography>
        <CopyInput
          label={$t({ id: 'modal.apiKey.input.apiKey.label', defaultMessage: 'Copy API key' })}
          value={apiKey}
          gutterBottom
        />
        <Typography variant="callout" weight="semi-bold" color="primary">
          {$t({
            id: 'modal.apiKey.codeSnippet.title',
            defaultMessage: 'Or copy this code to connect to Vectice in your notebook',
          })}
        </Typography>
        <ApiKeyTabs apiKey={apiKey ?? ''} />
      </WithLoading>
    </Modal>
  );
};
