import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as FindingIcon } from '../../../assets/icons/interface/ic-finding.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { DocumentationPageStatusBadge } from '../../badges';
import { FormatUserName } from '../../formatters';

export const GET_FINDING_TOOLTIP = graphql(`
  query getFindingTooltip($id: Float!) {
    Finding(id: $id) {
      id
      name
      status
      owner {
        ...userFields
      }
    }
  }
`);

interface FindingTooltipProps {
  id?: number | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const FindingTooltip = ({ id, placement = 'top', children }: FindingTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`finding-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_FINDING_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      id: id!,
    },
  });

  const finding = data?.Finding;

  useEffect(() => {
    setDataLoaded(!!finding || !!error);
  }, [finding, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={FindingIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.FINDING)}
      label={finding?.name}
      deleted={!!error}
    >
      {finding && (
        <DataList>
          <Typography component="dt" variant="callout" color="white">
            {$t({ id: 'issue.tooltip.status', defaultMessage: 'Status' })}
          </Typography>
          <DocumentationPageStatusBadge contrast status={finding.status} />
          <Typography id={`owner-${ariaId}`} component="dt" variant="callout" color="white">
            {$t({ id: 'issue.tooltip.owner', defaultMessage: 'Owner' })}
          </Typography>
          <Typography aria-labelledby={`owner-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            {finding.owner ? (
              <FormatUserName user={finding.owner} />
            ) : (
              $t({ id: 'issue.ownerName.unassigned', defaultMessage: 'Unassigned' })
            )}
          </Typography>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
