import { graphql } from '../../gql';

export const AUTHENTICATE_WITH_PASSWORD = graphql(`
  query authenticateWithPassword($email: String!, $password: String!) {
    authenticateWithPassword(email: $email, password: $password) {
      realm
      clientId
    }
  }
`);
