import cn from 'classnames';
import React, { ReactNode, useEffect, useRef } from 'react';

import { ReactComponent as DownArrow } from '../../assets/icons/interface/ic-chevron-down.svg';
import { Loading } from '../loading-animation';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './Summary.module.scss';

interface Props extends Omit<VecticeHTMLProps<HTMLDetailsElement>, 'summary'> {
  detailsClassName?: string;
  expandable?: boolean;
  iconClassName?: string;
  loading?: boolean;
  open?: boolean;
  summary: ReactNode;
  summaryClassName?: string;
  summaryContentClassName?: string;
  withBackground?: boolean;
  disableToggle?: boolean;
  onToggle?: (open: boolean) => void;
}

export const Summary = ({
  children,
  className,
  detailsClassName,
  expandable = true,
  iconClassName,
  loading,
  open,
  summary,
  summaryClassName,
  summaryContentClassName,
  withBackground = true,
  disableToggle = false,
  onToggle,
  ...props
}: Props) => {
  const detailsEl = useRef<HTMLDetailsElement>(null);

  // Listen for changed to the `open` attribute, calling `onToggle` when it changes.
  useEffect(() => {
    if (detailsEl.current && onToggle) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.attributeName === 'open' && detailsEl.current) {
            onToggle(detailsEl.current.open);
          }
        }
      });

      observer.observe(detailsEl.current, {
        attributes: true,
        childList: false,
        subtree: false,
        attributeFilter: ['open'],
      });

      return () => observer.disconnect();
    }

    return () => null;
  }, [detailsEl, onToggle]);

  return (
    <details
      aria-busy={loading}
      className={cn(
        styles.details,
        { [styles.expandable]: expandable, [styles.withBackground]: withBackground },
        className,
      )}
      onClick={(e) => disableToggle && e.preventDefault()}
      open={open}
      ref={detailsEl}
      {...props}
    >
      <summary className={cn(styles.summary, summaryClassName)}>
        {expandable && !disableToggle && (
          <Icon
            icon={DownArrow}
            size={16}
            className={cn(styles.icon, iconClassName, { [styles.disableToggle]: disableToggle })}
          />
        )}
        <Typography color="secondary" component="div" className={cn(styles.summary_content, summaryContentClassName)}>
          {summary}
        </Typography>
      </summary>
      {expandable && children && (
        <div className={cn(styles.details_content, detailsClassName)}>{loading ? <Loading size={24} /> : children}</div>
      )}
    </details>
  );
};
