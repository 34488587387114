import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import config from '../../config';
import { useProductContext } from '../../context';
import { OnboardingStatus } from '../../gql/graphql';
import { useUserProjects } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import { FlexContainer, Logo, Modal, Step, Steps, Typography } from '../../ui';
import { onboardingSteps } from '../../utils';
import { InviteModal } from '../invite-user';
import { ProductTourVideo } from '../product-tour';

import styles from './OnboardingModal.module.scss';

export const OnboardingModal = () => {
  const { isOnboardingEnabled, onboardingStatus, showTrialModal, closeTrialModal, updateOnboardingStatus } =
    useProductContext();

  const { quickstartProject, sampleProject } = useUserProjects();

  const [isVisible, setIsVisible] = useState(true);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showProductTourVideo, setShowProductTourVideo] = useState(false);

  if (!isOnboardingEnabled || !showTrialModal) return null;

  return (
    <>
      <Modal
        className={styles.modal}
        closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
        title={null}
        visible={isVisible}
        onClose={closeTrialModal}
      >
        <FlexContainer align="center" direction="column" gap={24}>
          <Logo size={48} />
          <Typography variant="heading2">
            {$t({ id: 'OnboardingModal.welcomeToVectice', defaultMessage: 'Welcome to Vectice' })}
          </Typography>
          <Typography align="justify" paragraph variant="callout">
            {$t({
              id: 'OnboardingModal.text1',
              defaultMessage:
                'Follow these steps to get started with Vectice and see how easy it is to auto-document your work.',
            })}
          </Typography>
          <FlexContainer>
            <Steps>
              {/* Step 1 */}
              <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step1)} stepNumber={1}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step1].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component="button"
                      link
                      type="button"
                      weight="semi-bold"
                      onClick={() => setShowProductTourVideo(true)}
                    >
                      {str}
                    </Typography>
                  ))}{' '}
                  {onboardingSteps[OnboardingStatus.Step1].getHint()}
                </Typography>
              </Step>

              {/* Step 2 */}
              <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step2)} stepNumber={2}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step2].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component={quickstartProject ? Link : undefined}
                      link
                      to={buildLink(VecticeRoutes.PROJECT, { projectId: quickstartProject?.vecticeId })}
                      weight="semi-bold"
                      onClick={
                        quickstartProject
                          ? () => {
                              closeTrialModal();
                              updateOnboardingStatus?.(OnboardingStatus.Step2);
                            }
                          : undefined
                      }
                    >
                      {str}
                    </Typography>
                  ))}{' '}
                  {onboardingSteps[OnboardingStatus.Step2].getHint()}
                </Typography>
              </Step>

              {/* Step 3 */}
              <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step3)} stepNumber={3}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step3].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component="button"
                      link
                      type="button"
                      weight="semi-bold"
                      onClick={() => {
                        setIsVisible(false);
                        setTimeout(() => setShowInviteModal(true), 50);
                      }}
                    >
                      {str}
                    </Typography>
                  ))}{' '}
                  {onboardingSteps[OnboardingStatus.Step3].getHint()}
                </Typography>
              </Step>

              {/* Step 4 */}
              <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step4)} stepNumber={4}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step4].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component={sampleProject ? Link : undefined}
                      link
                      to={buildLink(VecticeRoutes.PROJECT, { projectId: sampleProject?.vecticeId })}
                      weight="semi-bold"
                      onClick={
                        sampleProject
                          ? () => {
                              closeTrialModal();
                              updateOnboardingStatus?.(OnboardingStatus.Step4);
                            }
                          : undefined
                      }
                    >
                      {str}
                    </Typography>
                  ))}{' '}
                  {onboardingSteps[OnboardingStatus.Step4].getHint()}
                </Typography>
              </Step>
            </Steps>
          </FlexContainer>
          <Typography align="justify" className={styles.support} paragraph variant="callout">
            {$t({
              id: 'OnboardingModal.text2',
              defaultMessage: 'Need help? Contact us at <support>{supportEmail}</support>.',
              values: { supportEmail: config.SUPPORT_EMAIL },
            })}
          </Typography>
        </FlexContainer>
      </Modal>
      {showProductTourVideo && (
        <ProductTourVideo
          onClose={() => {
            setShowProductTourVideo(false);
            updateOnboardingStatus?.(OnboardingStatus.Step1);
          }}
        />
      )}
      {showInviteModal && (
        <InviteModal
          onClose={() => {
            updateOnboardingStatus?.(OnboardingStatus.Step3);
            setShowInviteModal(false);
            closeTrialModal();
          }}
        />
      )}
    </>
  );
};
