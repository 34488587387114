import React, { ElementType } from 'react';

import { ReactComponent as CheckIcon } from '../../assets/icons/interface/ic-check-tick.svg';
import { FlexContainer } from '../containers';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './Steps.module.scss';

interface StepProps extends VecticeHTMLProps<HTMLLIElement> {
  isCompleted?: boolean;
  stepNumber: number;
}

export const Step = <E extends ElementType>({
  isCompleted,
  stepNumber,
  children,
  ...props
}: StepProps & WithComponentProps<E>) => (
  <li className={styles.step} {...props}>
    <FlexContainer align="center" component="span" className={styles.badge} justify="center">
      {isCompleted ? <Icon icon={CheckIcon} size={12} /> : <Typography variant="callout">{stepNumber}</Typography>}
    </FlexContainer>
    {children}
  </li>
);
