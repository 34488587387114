import React from 'react';

import { ReactComponent as ModelIcon } from '../../../assets/icons/specials/ic-model.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { ModelTooltip } from './ModelTooltip';

interface ModelLinkProps {
  name?: string | null;
  resourceId?: string | null;
}

export const ModelLink = ({ name, resourceId }: ModelLinkProps) => (
  <ModelTooltip id={resourceId} placement="topLeft">
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.MODEL, { modelId: resourceId }) : undefined}
      icon={ModelIcon}
    />
  </ModelTooltip>
);
