import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';

import { ContactSupportModal, ContactSupportModalProps } from './ContactSupportModal';
import { CreateFindingModal, CreateFindingModalProps } from './CreateFindingModal';
import { CreateProjectModal, CreateProjectModalProps } from './CreateProjectModal';
import { CreateWorkspaceModal, CreateWorkspaceModalProps } from './CreateWorkspaceModal';

interface ModalsContextType {
  contactSupport: (args: Omit<ContactSupportModalProps, 'onClose'>) => void;
  createProject: (args: Omit<CreateProjectModalProps, 'onClose'>) => void;
  createWorkspace: (args: Omit<CreateWorkspaceModalProps, 'onClose'>) => void;
  createFinding: (args: Omit<CreateFindingModalProps, 'onClose'>) => void;
}

export const ModalsContext = createContext<ModalsContextType | null>(null);

export const useModals = () => {
  const modalsContext = React.useContext(ModalsContext);

  if (!modalsContext) {
    throw new Error('useModals must be used within ModalsProvider');
  }

  return modalsContext;
};

export const ModalsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [contactSupport, setContactSupport] = useState<Omit<ContactSupportModalProps, 'onClose'> | null>(null);
  const [createProject, setCreateProject] = useState<Omit<CreateProjectModalProps, 'onClose'> | null>(null);
  const [createWorkspace, setCreateWorkspace] = useState<Omit<CreateWorkspaceModalProps, 'onClose'> | null>(null);
  const [createFinding, setCreateFinding] = useState<Omit<CreateFindingModalProps, 'onClose'> | null>(null);

  const value = useMemo(
    () => ({
      contactSupport: setContactSupport,
      createProject: setCreateProject,
      createWorkspace: setCreateWorkspace,
      createFinding: setCreateFinding,
    }),
    [],
  );

  return (
    <>
      <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>
      {contactSupport && <ContactSupportModal {...contactSupport} onClose={() => setContactSupport(null)} />}
      {createProject && <CreateProjectModal {...createProject} onClose={() => setCreateProject(null)} />}
      {createWorkspace && <CreateWorkspaceModal {...createWorkspace} onClose={() => setCreateWorkspace(null)} />}
      {createFinding && <CreateFindingModal {...createFinding} onClose={() => setCreateFinding(null)} />}
    </>
  );
};
