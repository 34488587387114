import React from 'react';

import { generateArray } from '../../utils';
import { Skeleton } from '../loading-animation';

export const ListSkeleton = () => (
  <>
    {generateArray(3).map((uuid) => (
      <Skeleton.Shape key={uuid} height="60px" width="100%" />
    ))}
  </>
);
