import React from 'react';

import { OwnerFilter } from './OwnerFilter';

export const ProjectOwnerFilter = ({ workspaceIdList }: { workspaceIdList: string[] }) => (
  <OwnerFilter
    workspaceIdList={workspaceIdList}
    label={$t({ id: 'filters.project.owner.label', defaultMessage: 'Project owner' })}
  />
);
