import React from 'react';

import { useAuthentication } from '../../../context';
import { ProjectDeletedContent } from '../../../entities';
import { FormatUserName } from '../../formatters';
import { UserNotificationProjectDeleted } from '../types';

import { Notification } from './Notification';

interface ProjectDeletedNotificationProps {
  notification: UserNotificationProjectDeleted;
}

export const ProjectDeletedNotification = ({
  notification: { creator, content, createdDate },
}: ProjectDeletedNotificationProps) => {
  const { user } = useAuthentication();
  const parsedContent: ProjectDeletedContent = JSON.parse(content);
  const projectName = parsedContent?.projectName;

  return (
    <Notification creator={creator} createdDate={createdDate}>
      {user.id === creator?.id
        ? $t({
            id: 'notification.project.deleted.self',
            defaultMessage: '<bold>You</bold> deleted project <bold>{title}</bold>',
            values: {
              title: projectName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
            },
          })
        : $t({
            id: 'notification.project.deleted',
            defaultMessage: '<bold>{creator}</bold> deleted project <bold>{title}</bold>',
            values: {
              creator: <FormatUserName user={creator} />,
              title: projectName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
            },
          })}
    </Notification>
  );
};
