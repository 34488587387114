import React, { useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { FragmentType, useFragment } from '../../gql';
import {
  CheckboxGroup,
  FlexContainer,
  Menu,
  MenuDivider,
  MenuItem,
  MoreActions,
  Typography,
  ShowMoreText,
} from '../../ui';
import { DeletionPrompt } from '../modals';

import { DroppableIterationStep } from './DroppableIterationStep';
import { IterationStepFragment } from './IterationStepFragment';

import styles from './IterationStep.module.scss';

interface IterationStepProps {
  deleting?: boolean;
  isDragging: boolean;
  status: string;
  dividerIndex?: number;
  idsDragging: string[];
  iterationStep: FragmentType<typeof IterationStepFragment>;
  readOnly?: boolean;
  draggingId?: string;
  onDelete: (sectionId: string | number) => void;
  onEdit: () => void;
  onInternalCapture?: (dragId: string) => void;
}

export const IterationStep = ({
  deleting,
  iterationStep: iterationStepFragment,
  readOnly,
  onDelete,
  onEdit,
  ...props
}: IterationStepProps) => {
  const {
    id,
    name,
    description,
    paginatedArtifacts: { items: artifacts, total: artifactsCount },
  } = useFragment(IterationStepFragment, iterationStepFragment);

  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const stepActions = (
    <Menu>
      <MenuItem leftIcon={EditIcon} onClick={onEdit}>
        {$t({ id: 'iteration.section.editResource', defaultMessage: 'Edit Section' })}
      </MenuItem>
      <MenuDivider />
      <MenuItem leftIcon={DeleteIcon} color="danger" onClick={() => setShowDeletionModal(true)}>
        {$t({ id: 'iteration.section.deleteResource', defaultMessage: 'Delete Section' })}
      </MenuItem>
    </Menu>
  );

  const summary = (
    <FlexContainer direction="column" gap={4} style={{ flex: 1 }}>
      <FlexContainer className={styles.name}>
        <Typography variant="body" weight="semi-bold" color="primary">
          {name}
        </Typography>
        {!readOnly && (
          <MoreActions
            className={styles.menu_trigger}
            dropdownProps={{
              onOverlayClick: (e) => e.stopPropagation(),
            }}
            moreActionsMenu={stepActions}
          />
        )}
      </FlexContainer>
      {description && (
        <ShowMoreText lines={2} expanded={false}>
          {description}
        </ShowMoreText>
      )}
    </FlexContainer>
  );

  const dropIdData = { droppableDataId: (id ?? 'undefined').toString(), type: 'asset' };

  return (
    <>
      <DroppableIterationStep
        artifacts={artifacts}
        artifactsCount={artifactsCount}
        readOnly={readOnly}
        dropIdData={dropIdData}
        summary={summary}
        stepDragAllowed={true}
        {...props}
      />
      {showDeletionModal && (
        <CheckboxGroup name="section-delete-prompt">
          <DeletionPrompt
            processing={deleting}
            resourceName={name}
            title={$t({ id: 'IterationStep.deleteModal.title', defaultMessage: 'Delete Section' })}
            postHint={
              <>
                &nbsp;
                <Typography>
                  {$t({
                    id: 'IterationStep.deleteModal.postHint',
                    defaultMessage:
                      'If this section has assets, they will be moved to the default section for unassigned assets.',
                  })}
                </Typography>
              </>
            }
            onClose={() => setShowDeletionModal(false)}
            onConfirm={async () => {
              await onDelete(id);
              setShowDeletionModal(false);
            }}
          />
        </CheckboxGroup>
      )}
    </>
  );
};
