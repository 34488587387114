import { ApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserActivity } from '../../../gql/graphql';
import { RESTORE_PHASE } from '../../../graphql/queries';
import { buildLink, VecticeRoutes } from '../../../routes';
import { message } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';

export const useRestore = (activity: Pick<UserActivity, 'targetName' | 'targetVecticeId'>) => {
  const navigate = useNavigate();

  const errorSameName = 'The name already exists';

  const phaseLabel = getVecticeResourceTypeLabel(VecticeResourceType.PHASE);

  const onCompleted = (resourceType: string) => () => {
    if (activity) {
      message.success(
        $t({
          id: 'resourceSuccessfullyRestore',
          defaultMessage: 'The {resourceType} {name} has been successfully restored.',
          values: {
            resourceType,
            name: activity.targetName,
          },
        }),
      );
      navigate(buildLink(VecticeRoutes.PHASE_DOCUMENTATION, { phaseId: activity.targetVecticeId || '' }));
    }
  };

  const onError = (resourceType: string) => (error: ApolloError) => {
    if (activity) {
      if (error.message.includes(errorSameName)) {
        message.warning(
          $t({
            id: 'resourceNameAlreadyInUse',
            defaultMessage:
              'The {resourceType} {name} could not be restored. There is already a {resourceType} with the same name.',
            values: {
              resourceType,
              name: activity.targetName,
            },
          }),
        );
      } else {
        message.error(
          $t({
            id: 'restoreFailure',
            defaultMessage: 'The {resourceType} {name} could not be restored. It may have already been restored.',
            values: {
              resourceType,
              name: activity.targetName,
            },
          }),
        );
      }
    }
  };

  const [restorePhase, { loading: restoringPhase }] = useMutation(RESTORE_PHASE, {
    onCompleted: onCompleted(phaseLabel),
    onError: onError(phaseLabel),
  });

  const restoreActivity = useCallback(
    () =>
      restorePhase({
        variables: { id: activity.targetVecticeId || '' },
      }),
    [activity.targetVecticeId],
  );

  return { restoring: restoringPhase, restoreActivity };
};
