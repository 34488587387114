import { ColumnType as RcColumnType } from 'rc-table/lib/interface';
import { useCallback, useEffect, useRef, useState, MouseEvent } from 'react';

import { CheckboxCell, HeadCheckboxCell, RadioboxCell } from '../Cells';
import { ColumnType, DefaultRecordType } from '../types';

import {
  deepCountItems,
  deepSelectAllItems,
  deepSelectItem,
  deepUnselectItem,
  getSelectableHeadCellLabel,
  getSelectableHeadCellState,
} from './utils';

const usePrevious = (value: any) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export interface UseSelectionProps<RecordType> {
  data: readonly RecordType[] | undefined;
  initialSelection?: RecordType[];
  selectedRows?: RecordType[];
  singleSelect?: boolean;
  onSelectRow?: (item: RecordType, selected: boolean) => void;
  onSelectAll?: (selected: boolean, selection: RecordType[]) => void;
  onSelectionChange?: (selection: RecordType[]) => void;
}

export const useSelection = <RecordType extends DefaultRecordType>({
  data,
  initialSelection = [],
  selectedRows,
  singleSelect = false,
  onSelectRow,
  onSelectAll,
  onSelectionChange,
}: UseSelectionProps<RecordType>) => {
  const { current: headCellTitle } = useRef(HeadCheckboxCell());

  const previous = usePrevious(data);
  const [currentSelection, setCurrentSelection] = useState<RecordType[]>(initialSelection);

  useEffect(() => {
    if (selectedRows) {
      setCurrentSelection(selectedRows);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(previous)) {
      if (data) {
        setCurrentSelection((snapshot) => {
          const newSelection = snapshot.filter((record) => data.includes(record));
          onSelectionChange?.(newSelection);
          return newSelection;
        });
      } else {
        setCurrentSelection([]);
        onSelectionChange?.([]);
      }
    }
  }, [data, previous]);

  const injectSelectable = useCallback(
    (columns: ColumnType<RecordType>[]): RcColumnType<RecordType>[] => {
      if (onSelectionChange || onSelectAll || onSelectRow) {
        const nbOfElements = deepCountItems<RecordType>(data);
        const headCellState = getSelectableHeadCellState(currentSelection?.length, nbOfElements);
        const headCellLabel = getSelectableHeadCellLabel(headCellState);

        const onHeaderCell = () => ({
          'aria-checked': headCellState,
          'aria-label': headCellLabel,
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            if (headCellState === 'true') {
              setCurrentSelection([]);
              onSelectionChange?.([]);
              onSelectAll?.(false, []);
            } else {
              const newSelections = deepSelectAllItems<RecordType>(data);
              setCurrentSelection(newSelections);
              onSelectionChange?.(newSelections);
              onSelectAll?.(true, newSelections);
            }
          },
        });

        const onCell = (record: RecordType) => ({
          'aria-checked': currentSelection.some((selection) => selection === record),
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            setCurrentSelection((snapshot) => {
              if (singleSelect) {
                onSelectRow?.(record, true);
                onSelectionChange?.([record]);
                return [record];
              }
              let selectedItems = snapshot;
              if (selectedItems.some((selection) => selection === record)) {
                selectedItems = deepUnselectItem<RecordType>(record, selectedItems);
                onSelectRow?.(record, false);
              } else {
                selectedItems = deepSelectItem<RecordType>(record, snapshot);
                onSelectRow?.(record, true);
              }

              onSelectionChange?.(selectedItems);
              return selectedItems;
            });
          },
        });

        return [
          {
            key: '',
            width: '40px',
            title: singleSelect ? null : headCellTitle,
            render: singleSelect ? RadioboxCell : CheckboxCell,
            onHeaderCell,
            onCell,
            fixed: columns[0].fixed,
          },
          ...columns,
        ];
      }

      return columns;
    },
    [currentSelection, data, headCellTitle, onSelectionChange],
  );

  return { injectSelectable };
};
