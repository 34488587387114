import { graphql } from '../../gql';

export const SUBSCRIBE_TO_REVIEW = graphql(`
  subscription subscribeToReview($id: VecticeId!) {
    subscribeToReview(id: $id) {
      vecticeId
      ...Review
    }
  }
`);
