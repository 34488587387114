import cn from 'classnames';
import React, { CSSProperties } from 'react';

import { ReactComponent as CheckedIcon } from '../../assets/icons/interface/ic-check.svg';
import { ReactComponent as UncheckedIcon } from '../../assets/icons/interface/ic-checkbox.svg';
import { ReactComponent as DownIcon } from '../../assets/icons/interface/ic-chevron-down.svg';
import { ReactComponent as UpIcon } from '../../assets/icons/interface/ic-chevron-up.svg';
import { ReactComponent as RadioCheckedIcon } from '../../assets/icons/interface/ic-radio-checked.svg';
import { ReactComponent as RadioIcon } from '../../assets/icons/interface/ic-radio.svg';
import { ReactComponent as MixedCheckedIcon } from '../../assets/icons/interface/ic-subtract-square.svg';
import { Icon } from '../svg';
import { Tooltip } from '../tooltip';
import { Typography } from '../typography';

import styles from './Table.module.scss';

const getTooltipPlacement = (style?: CSSProperties) => {
  switch (style?.textAlign) {
    case 'center':
      return 'top';
    case 'right':
      return 'topRight';
    case 'left':
    default:
      return 'topLeft';
  }
};

interface CellProps {
  noBreak?: boolean;
}

export const CheckboxCell = () => (
  <>
    <Icon className={styles.checked_icon} icon={CheckedIcon} size={18} />
    <Icon className={styles.unchecked_icon} icon={UncheckedIcon} size={18} />
  </>
);

export const RadioboxCell = () => (
  <>
    <Icon className={styles.checked_icon} icon={RadioCheckedIcon} size={18} />
    <Icon className={styles.unchecked_icon} icon={RadioIcon} size={18} />
  </>
);

export const HeadCheckboxCell = () => (
  <>
    <Icon className={styles.checked_icon} icon={CheckedIcon} size={18} />
    <Icon className={styles.unchecked_icon} icon={UncheckedIcon} size={18} />
    <Icon className={styles.mixed_checked_icon} icon={MixedCheckedIcon} size={18} />
  </>
);

export const Cell = ({ title, children, ...props }: VecticeHTMLProps<any> & CellProps) => (
  <Tooltip text={title} placement={getTooltipPlacement(props.style)}>
    <Typography component="td" variant="callout" color="primary" {...props}>
      {children}
    </Typography>
  </Tooltip>
);

export const HeadCell = ({ title, className, prefix, children, ...props }: VecticeHTMLProps<any>) => (
  <Tooltip text={title} placement={getTooltipPlacement(props.style)}>
    <Typography
      component="th"
      variant="callout"
      weight="semi-bold"
      color="secondary"
      className={cn({ [styles.sortable_cell]: 'aria-sort' in props }, className)}
      {...props}
    >
      {prefix && <span className={styles.cell_prefix}>{prefix}</span>}
      <span
        className={cn(styles.head_cell, {
          [styles.with_prefix]: !!prefix,
        })}
      >
        <span className={styles.head_cell_label}>{children}</span>
        <Icon className={styles.up_icon} icon={UpIcon} size={18} />
        <Icon className={styles.down_icon} icon={DownIcon} size={18} />
      </span>
    </Typography>
  </Tooltip>
);
