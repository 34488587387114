import cn from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { FragmentType, useFragment } from '../../gql';
import { IterationStepArtifactType } from '../../gql/graphql';
import { EntityFileRowFragment } from '../../graphql/fragments';
import { DatasetVersionWidget, EntityFileWidget, EntityMetadataWidget, ModelVersionWidget } from '../asset-display';

import { IterationCommentCard } from './IterationCommentCard';
import { IterationStepArtifact } from './IterationStepArtifact';
import { IterationStepArtifactFragment } from './IterationStepArtifactFragment';

import styles from './DraggableIterationStepArtifact.module.scss';

interface IterationStepArtifactProps {
  index: number;
  dragId: string;
  readOnly?: boolean;
  artifactFragment: FragmentType<typeof IterationStepArtifactFragment>;
  idsDragging: string[];
  status: string;
  divider?: 'bottom' | 'top';
  draggingId?: string | null;
  onInternalCapture?: (dragId: string) => void;
}

export const DraggableIterationStepArtifact = ({
  dragId,
  status,
  readOnly,
  index,
  idsDragging,
  artifactFragment,
  draggingId,
  onInternalCapture,
  ...props
}: IterationStepArtifactProps) => {
  const {
    id,
    type,
    text,
    datasetVersion,
    entityFile: entityFileFragment,
    modelVersion,
    entityMetadata,
  } = useFragment(IterationStepArtifactFragment, artifactFragment);

  const entityFile = useFragment(EntityFileRowFragment, entityFileFragment);

  const checkboxRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const isStepArtifactDeleted: Record<IterationStepArtifactType, boolean> = useMemo(
    () => ({
      [IterationStepArtifactType.DataSetVersion]: !datasetVersion?.vecticeId,
      [IterationStepArtifactType.ModelVersion]: !modelVersion?.vecticeId,
      [IterationStepArtifactType.EntityFile]: !entityFile?.id,
      [IterationStepArtifactType.EntityMetadata]: !entityMetadata?.id,
      [IterationStepArtifactType.Comment]: !text,
    }),
    [modelVersion, datasetVersion, entityFile, entityMetadata, text],
  );

  const label = useMemo(() => {
    const isSelected = checkboxRef.current?.checked ? 'Selected - ' : '';

    if (type === IterationStepArtifactType.Comment && text) {
      return `${isSelected}Comment - ${text}`;
    }
    if (type === IterationStepArtifactType.DataSetVersion && datasetVersion) {
      return `${isSelected}Dataset version - ${datasetVersion.vecticeId}`;
    }
    if (type === IterationStepArtifactType.ModelVersion && modelVersion) {
      return `${isSelected}Model version - ${modelVersion.vecticeId}`;
    }
    if (type === IterationStepArtifactType.EntityFile && entityFile) {
      return `${isSelected}File - ${entityFile.fileName}`;
    }
    if (type === IterationStepArtifactType.EntityMetadata && entityMetadata) {
      return `${isSelected}Metadata - ${entityMetadata.name}`;
    }

    return `${isSelected}artifact`;
  }, [open, datasetVersion, entityFile, entityMetadata, modelVersion, text, checkboxRef.current?.checked]);

  const render = useMemo(() => {
    if (type === IterationStepArtifactType.Comment && text) {
      return (
        <IterationCommentCard
          readOnly={readOnly}
          iterationArtifact={{ id, text }}
          className={cn(styles.draggingItem)}
        />
      );
    }
    if (type === IterationStepArtifactType.DataSetVersion) {
      return (
        <DatasetVersionWidget
          id={datasetVersion?.vecticeId}
          open={open}
          onToggle={setOpen}
          className={styles.draggingItem}
        />
      );
    }
    if (type === IterationStepArtifactType.ModelVersion) {
      return (
        <ModelVersionWidget
          id={modelVersion?.vecticeId}
          open={open}
          onToggle={setOpen}
          className={styles.draggingItem}
        />
      );
    }
    if (type === IterationStepArtifactType.EntityFile) {
      return <EntityFileWidget id={entityFile?.id} open={open} onToggle={setOpen} className={styles.draggingItem} />;
    }
    if (type === IterationStepArtifactType.EntityMetadata && entityMetadata) {
      return (
        <EntityMetadataWidget
          entityMetadataId={entityMetadata.id}
          open={open}
          onToggle={setOpen}
          className={styles.draggingItem}
        />
      );
    }
    return null;
  }, [open, datasetVersion, entityFile, entityMetadata, modelVersion, text, readOnly]);

  const isSelectedForDragging = useCallback((): boolean => {
    if (status !== 'started') return false;
    return idsDragging.includes(dragId);
  }, [status, idsDragging, dragId]);

  const renderContainer = (
    <div className={cn(styles.container, { [styles.isSelectedForDragging]: isSelectedForDragging() })}>{render}</div>
  );

  const onCapture = useCallback(
    (dragDetected: boolean) => {
      if (dragDetected) {
        setOpen(false);
        onInternalCapture?.(dragId);
      }
    },
    [dragId],
  );

  return draggingId === dragId && status !== 'end' ? (
    <>
      <Draggable key={dragId} draggableId={dragId} index={index} isDragDisabled={true}>
        {(draggableProvided) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          />
        )}
      </Draggable>
      <IterationStepArtifact
        dragId={dragId}
        isDragStarted={status === 'started'}
        isDeleted={isStepArtifactDeleted[type]}
        readOnly={readOnly}
        render={renderContainer}
        label={label}
        type={type}
        onCapture={onCapture}
        {...props}
      />
    </>
  ) : (
    <Draggable key={dragId} draggableId={dragId} index={index} isDragDisabled={readOnly}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
        >
          <IterationStepArtifact
            dragId={dragId}
            isDeleted={isStepArtifactDeleted[type]}
            isDragStarted={false}
            readOnly={readOnly}
            render={renderContainer}
            label={label}
            type={type}
            onCapture={onCapture}
            {...props}
          />
        </div>
      )}
    </Draggable>
  );
};
