import React, { ChangeEvent, FocusEvent, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { getArray } from '../../../utils';
import { AutoComplete } from '../advanced-input';
import { Input } from '../input';

type ValidationError = {
  message: string;
  type: string;
};

interface WithAsyncValidationProps {
  validate: (value: string) => Promise<any> | any;
  onSuccess?: (value: string) => void;
  onError?: (error: ValidationError) => void;
}

export const WithAsyncValidation = ({
  validate,
  onError,
  onSuccess,
  children,
}: PropsWithChildren<WithAsyncValidationProps>) => {
  const [isValidating, setIsValidating] = useState(false);

  const handleValidation = useCallback(
    async (value: string) => {
      setIsValidating(true);
      try {
        await validate(value);
        onSuccess?.(value);
      } catch (e) {
        onError?.({
          message: e.message,
          type: 'asyncValidation',
        });
      } finally {
        setIsValidating(false);
      }
    },
    [validate],
  );

  return useMemo(() => {
    const [input] = getArray<any>(children);

    if (input?.type?.name !== Input.name && input?.type?.name !== AutoComplete.name) {
      throw new Error('WithAsyncValidation must have an Input or AutoComplete as its first child');
    }

    const isAdvancedInput = input?.type?.name !== Input.name;

    const { onChange, onDebouncedChange, onBlur, onSelectOption, ...restInputProps } = input.props;

    return {
      ...input,
      props: {
        ...restInputProps,
        loading: isValidating,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setIsValidating(true);
          onChange?.(e);
        },
        onBlur: async (e: FocusEvent<HTMLInputElement>) => {
          onBlur?.(e);
          if (!isValidating && !isAdvancedInput) {
            await handleValidation(e.currentTarget.value);
          }
        },
        onDebouncedChange: async (value: string) => {
          onDebouncedChange?.(value);
          await handleValidation(value);
        },
        onSelectOption: async (value: string | null) => {
          onSelectOption?.(value);
          await handleValidation(value ?? '');
        },
      },
    };
  }, [children, handleValidation, isValidating]);
};
