import React, { PropsWithChildren } from 'react';

import { Logo, Typography } from '../ui';

import styles from './PublicPagesLayout.module.scss';

export const PublicPagesLayout = ({ children }: PropsWithChildren<unknown>) => (
  <div className={styles.layout}>
    <div className={styles.content}>
      <h1 className={styles.title}>
        <Logo size={48} withName />
      </h1>
      <div className={styles.card}>{children}</div>
    </div>
    <div className={styles.bottom}>
      <Typography variant="callout" color="copyright" align="center" paragraph>
        {$t({
          id: 'layout.public.copyright',
          defaultMessage: '© Copyright Vectice, Inc. All rights reserved.',
        })}{' '}
        {$t({
          id: 'layout.public.terms_of_service',
          defaultMessage: '<tos_link>Privacy & Terms of Service</tos_link>.',
        })}
      </Typography>
    </div>
  </div>
);
