import React from 'react';

import type { LightboxProps } from '../../ui';

import { ReactComponent as TableIcon } from '../../assets/icons/editor/ic-table.svg';
import { EntityMetadata } from '../../gql/graphql';
import { FlexContainer, Icon, Lightbox, Typography } from '../../ui';

import { EntityMetadataPreview } from './entityMetadata';

interface LightboxEntityMetaPreviewProps extends Omit<LightboxProps, 'content' | 'title' | 'onDownload'> {
  entityMetadata: Pick<EntityMetadata, 'id' | 'name'>;
}

export const LightboxEntityMetaPreview = ({
  entityMetadata: { id, name },
  ...props
}: LightboxEntityMetaPreviewProps) => (
  <Lightbox
    aria-label="File Preview"
    content={<EntityMetadataPreview entityMetadataId={id} />}
    title={
      <FlexContainer gap={4}>
        <Icon icon={TableIcon} size={34} />
        <Typography ellipsis component="div" variant="heading2" color="white">
          {name}
        </Typography>
      </FlexContainer>
    }
    {...props}
  />
);
