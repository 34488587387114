import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { Workspace } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';
import { DeletionPrompt } from '../modals';

import { DELETE_WORKSPACE } from './deleteWorkspace.mutation';
import { DangerCard } from './internals';

interface Props {
  workspace: Workspace;
}

export const DeleteWorkspace = ({ workspace }: Props) => {
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);

  const [deleteWorkspace, { loading }] = useMutation(DELETE_WORKSPACE, {
    variables: { id: workspace.vecticeId },
    optimisticResponse: ({ id }) => ({
      deleteWorkspace: id.toString(),
    }),
    update: (cache, { data }) => {
      if (data?.deleteWorkspace) {
        flushFromGraphQLCache(cache, { vecticeId: data.deleteWorkspace, __typename: 'Workspace' });
        navigate({
          pathname: buildLink(VecticeRoutes.WORKSPACES),
        });
      }
    },
    onCompleted: (data) => {
      if (data.deleteWorkspace) {
        message.success(
          $t({
            id: 'component.action.deleteWorkspace.successMessage',
            defaultMessage: 'Workspace "{workspaceName}" was deleted.',
            values: {
              workspaceName: workspace.name,
            },
          }),
        );
      }
    },
    onError: (error) => {
      if (error.message === 'Tutorial and Sample workspaces can not be deleted.') {
        return message.error(
          $t({
            id: 'component.action.deleteWorkspace.errorMessage.CannotDeleteTutorialSample',
            defaultMessage: 'Tutorial and Sample workspaces can not be deleted.',
          }),
        );
      }
      return message.error(
        $t({
          id: 'component.action.deleteWorkspace.errorMessage.CannotDeleteWorkspace',
          defaultMessage: 'Can not delete workspace, please contact support',
        }),
        3,
      );
    },
  });

  const handleDeleteWorkspace = () => setShowPrompt(true);

  return (
    <>
      <DangerCard
        buttonIcon={DeleteIcon}
        buttonLabel={$t({
          id: 'component.action.deleteWorkspace.deleteWorkspaceLabel',
          defaultMessage: 'Delete Workspace',
        })}
        hint={$t({
          id: 'component.action.deleteWorkspace.errorMessage.deleteWorkspaceHint',
          defaultMessage: 'This will delete the workspace. This action is not reversible.',
        })}
        onClick={handleDeleteWorkspace}
      />
      {showPrompt && (
        <DeletionPrompt
          processing={loading}
          resourceName={workspace.name}
          title={$t({ id: 'component.action.deleteWorkspace.deletePromptTitle', defaultMessage: 'Delete Workspace' })}
          onClose={() => setShowPrompt(false)}
          onConfirm={deleteWorkspace}
        />
      )}
    </>
  );
};
