import { graphql } from '../../../gql';

export const ENTITY_METADATA = graphql(`
  query entityMetadata($id: Float!) {
    entityMetadata(id: $id) {
      id
      name
    }
  }
`);
