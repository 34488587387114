import { useMutation } from '@apollo/client';

import { UPDATE_ITERATION_STARRED_STATUS } from '../graphql/queries';
import { message } from '../ui';

export const useStarIteration = () => {
  const [updateStarIteration] = useMutation(UPDATE_ITERATION_STARRED_STATUS, {
    optimisticResponse: (variables) => ({
      updateIteration: {
        vecticeId: variables.id,
        starred: variables.starred,
      },
    }),
    onError: () => {
      message.error(
        $t({
          id: 'phase.hook.iteration.updateResourceFailure',
          defaultMessage: 'Oops, something went wrong! The Iteration could not be updated.',
        }),
      );
    },
  });

  return { updateStarIteration };
};
