import { Buffer } from 'buffer';

import config from '../config';
import { User } from '../gql/graphql';
import { UserIdentity } from '../graphql/fragments';

export const b64_to_utf8 = (str: string) => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch {
    return null;
  }
};

export const getJsonFromBase64 = (base64: string): any | null => {
  try {
    return JSON.parse(Buffer.from(base64, 'base64').toString());
  } catch (e) {
    logger.error(e);
    return null;
  }
};

export const formatCssSizes = (size?: string | number, unit = 'px') => {
  if (typeof size === 'number') {
    return `${size}${unit}`;
  }

  return size;
};

export const formatHyperlink = (hyperlink: string) =>
  hyperlink.startsWith('http') ? hyperlink : `https://${hyperlink}`;

export const generateArray = (size?: number) => new Array(size).fill(null).map((_, i) => `skeleton-${i}`);

export const getCopiedName = (name: string) => {
  if (!name.startsWith('Copy of Copy of')) {
    return `Copy of ${name}`;
  }
  const match = /^(.*)(\[\d+\])$/.exec(name);
  if (match && match[2]) {
    const newCopyCount = Number(match[2].substring(1, 2)) + 1;
    return `${match[1]}[${newCopyCount}]`;
  }
  return `${name}[1]`;
};

export const isNil = (value: unknown | null | undefined): value is null | undefined =>
  value === null || value === undefined;

export const isDefined = <T>(value: T): value is Exclude<T, null | undefined> => !isNil(value);

export const isNullOrWhiteSpace = (value: string | undefined) => (value ? !/\S/.test(value) : true);

export function round(value: number | null | undefined, precision: number = config.DEFAULT_DECIMAL_PRECISION) {
  if (typeof value !== 'number') {
    return null;
  }

  if (value) {
    const [mantissa, exponent] = value.toString().split('e');
    const tmp = Number(`${mantissa}e${precision}`);
    const roundedMantissa = Number(`${Math.round(tmp)}e-${precision}`);
    if (exponent) {
      return Number(`${roundedMantissa}e${exponent}`);
    }
    return roundedMantissa;
  }

  return 0;
}

/**
 * Move item inside array and reinitialize indexes from 0
 * @param items Items to reorder
 * @param oldIndex Old index of the element to move
 * @param newIndex
 * @returns
 */
export const getReorderedItems = <T extends { index: number }>(items: T[], oldIndex: number, newIndex: number): T[] => {
  const reorderedItems = items.map((item, index) => ({ ...item, index }));
  const [reorderedItem] = reorderedItems.splice(oldIndex, 1);
  reorderedItems.splice(newIndex, 0, reorderedItem);
  return reorderedItems.map((item, index) => ({ ...item, index }));
};

export const isNumber = (value: unknown | null | undefined) => {
  if (typeof value === 'number') return true;
  if (!value || typeof value !== 'string') return false;
  return !isNaN(Number(value)) && !isNaN(parseFloat(value));
};

export const getArray = <T>(value: T | T[]) => (Array.isArray(value) ? value : [value]);

export const isObjectEmpty = (obj: object) => Object.keys(obj).length === 0;

export const getValueFromPath = <T>(obj: object, path: string): T => {
  let value: any = obj;
  const pathKeys = path.split('.');

  for (const key of pathKeys) {
    if (!!value) {
      if (isNumber(key) && Array.isArray(value)) {
        value = value[parseInt(key)];
      } else if (typeof value === 'object' && key in value) {
        value = value[key];
      }
    }
  }

  return value;
};

export const reorderMemberList = (
  members: (Pick<User, 'id' | 'name'> & UserIdentity)[],
  connectedUser: Pick<User, 'id' | 'name'> & UserIdentity,
  isSearching: boolean,
) => {
  const membersArray = [...members];
  const memberIndex = membersArray.findIndex((member) => member.id === connectedUser.id);
  if (memberIndex === -1) {
    if (!isSearching) {
      membersArray.unshift(connectedUser);
    }
  }
  if (memberIndex !== -1) {
    membersArray.unshift(membersArray.splice(memberIndex, 1)[0]);
  }
  return membersArray;
};
