import React, { PropsWithChildren } from 'react';

import { getCategoryTypeLabel } from '../../entities/utils';
import { CategoryType } from '../../gql/graphql';
import { Card, DataList, FlexContainer, Icon, Typography } from '../../ui';
import { getDbTableTypeIcon, getMimeTypeIcon } from '../../utils';

type ResourcesType = {
  id: number;
  name: string;
  type?: string;
  mimeType?: string;
  rowsNumber?: number | null;
  columnsNumber?: number | null;
  columnsTypesCounts: { categoryType: CategoryType; count: number }[];
};

interface Props {
  resource: ResourcesType;
}

import styles from './ResourceCard.module.scss';

const HeadResourceCardCell = ({
  id,
  bold,
  className,
  ...props
}: PropsWithChildren<{ id: string; className?: string; bold?: boolean }>) => (
  <Typography
    id={id}
    component="dt"
    variant="callout"
    color="secondary"
    weight={bold ? 'semi-bold' : 'regular'}
    ellipsis
    className={className}
    {...props}
  />
);

const DataResourceCardCell = ({ bold, ...props }: PropsWithChildren<{ bold?: boolean }>) => (
  <Typography
    component="dd"
    variant="callout"
    color="tertiary"
    weight={bold ? 'semi-bold' : 'regular'}
    ellipsis
    {...props}
  />
);

export const ResourceCard = ({ resource }: Props) => {
  const resourceIcon = resource.mimeType ? getMimeTypeIcon(resource.mimeType) : getDbTableTypeIcon(resource.mimeType);
  return (
    <Card inert>
      <FlexContainer gap={4}>
        {resourceIcon && <Icon icon={resourceIcon} size={18} />}
        <Typography variant="callout" weight="semi-bold" ellipsis>
          {resource.name}
        </Typography>
      </FlexContainer>
      <DataList className={styles.content}>
        <HeadResourceCardCell bold id={`number-columns-${resource.id}`}>
          {$t({ id: 'resource.card.columnNumber', defaultMessage: 'Nb of Columns' })}
        </HeadResourceCardCell>
        <DataResourceCardCell aria-labelledby={`number-columns-${resource.id}`}>
          {resource.columnsNumber ?? $t({ id: 'resource.card.columnNumber.noValue', defaultMessage: 'N/A' })}
        </DataResourceCardCell>
        {resource.columnsTypesCounts.map((column) => (
          <React.Fragment key={`column-${column.categoryType}`}>
            <HeadResourceCardCell id={`number-${column.categoryType}-${resource.id}`} className={styles.subHeadCell}>
              {getCategoryTypeLabel(column.categoryType)}
            </HeadResourceCardCell>
            <DataResourceCardCell aria-labelledby={`number-${column.categoryType}-${resource.id}`}>
              {column.count ?? $t({ id: 'resource.card.column.count.noValue', defaultMessage: 'N/A' })}
            </DataResourceCardCell>
          </React.Fragment>
        ))}
        <HeadResourceCardCell id={`number-rows-${resource.id}`} bold>
          {$t({ id: 'resource.card.rowsNumber', defaultMessage: 'Nb of Rows' })}
        </HeadResourceCardCell>
        <DataResourceCardCell aria-labelledby={`number-rows-${resource.id}`}>
          {resource.rowsNumber ?? $t({ id: 'resource.card.rowsNumber.noValue', defaultMessage: 'N/A' })}
        </DataResourceCardCell>
      </DataList>
    </Card>
  );
};
