import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React from 'react';

import { ReactComponent as RemoveIcon } from '../../../assets/icons/interface/ic-remove.svg';
import { Badge } from '../../badge';
import { Icon } from '../../svg';

import styles from './RcSelect.module.scss';

export const Tag = ({ label, closable, onClose }: CustomTagProps) => (
  <Badge color="accent" className={styles.tag}>
    {label}
    {closable && (
      <button type="button" onClick={onClose} className={styles.tag_remove}>
        <Icon icon={RemoveIcon} size={12} />
      </button>
    )}
  </Badge>
);
