import { useQuery } from '@apollo/client';

import { parseJobArtifacts } from '../entities/utils';
import { GET_JOB_ARTIFACT_BY_JOB_RUN_ID } from '../graphql/queries';
import { VecticeResourceType } from '../utils';

import { useEntityFiles } from './useEntityFiles';

export const useAssetLineage = (lineageId?: number | null) => {
  const { data, loading, error } = useQuery(GET_JOB_ARTIFACT_BY_JOB_RUN_ID, {
    fetchPolicy: 'cache-and-network',
    skip: !lineageId,
    variables: {
      lineageId: lineageId!,
    },
  });

  const jobArtifacts = data?.getJobArtifactByJobRunId?.items;

  const { inputs, code, outputs } = parseJobArtifacts(jobArtifacts);

  const { attachments } = useEntityFiles(lineageId ?? undefined, VecticeResourceType.RUN);

  return {
    code,
    codeFile: attachments?.[0],
    error,
    inputs,
    isEmpty: (!inputs || inputs.length === 0) && !code && (!outputs || outputs.length === 0),
    loading: !jobArtifacts && loading,
    outputs,
  };
};
