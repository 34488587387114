import { ReactComponent as TextIcon } from '../../assets/icons/editor/ic-text.svg';
import { ReactComponent as BooleanIcon } from '../../assets/icons/interface/ic-boolean.svg';
import { ReactComponent as DateIcon } from '../../assets/icons/interface/ic-calendar.svg';
import { ReactComponent as NumericalIcon } from '../../assets/icons/interface/ic-hashtag.svg';
import { ReactComponent as OtherIcon } from '../../assets/icons/interface/ic-layout-columns.svg';
import { CategoryType } from '../../gql/graphql';

export const getCategoryTypeLabel = (categoryType?: CategoryType | string | null) => {
  switch (categoryType) {
    case CategoryType.Boolean:
      return $t({ id: 'datasetSource.column.dataType.boolean.label', defaultMessage: 'Boolean' });
    case CategoryType.Date:
      return $t({ id: 'datasetSource.column.dataType.date.label', defaultMessage: 'Date' });
    case CategoryType.Numerical:
      return $t({ id: 'datasetSource.column.dataType.numerical.label', defaultMessage: 'Numerical' });
    case CategoryType.Text:
      return $t({ id: 'datasetSource.column.dataType.text.label', defaultMessage: 'Text' });
    case CategoryType.Categorical:
      return $t({ id: 'datasetSource.column.dataType.categorical.label', defaultMessage: 'Categorical' });
    case CategoryType.Other:
    default:
      return $t({ id: 'datasetSource.column.dataType.other.label', defaultMessage: 'Other' });
  }
};

export const getCategoryTypeIcon = (categoryType?: CategoryType | string | null) => {
  switch (categoryType) {
    case CategoryType.Boolean:
      return BooleanIcon;
    case CategoryType.Date:
      return DateIcon;
    case CategoryType.Numerical:
      return NumericalIcon;
    case CategoryType.Text:
      return TextIcon;
    case CategoryType.Categorical:
    case CategoryType.Other:
    default:
      return OtherIcon;
  }
};
