import { RealmInformation } from '../gql/graphql';

import { LegacyTrialStep } from './onboarding';

export interface UserTrialSteps {
  [userId: string]: {
    completed: LegacyTrialStep[];
  };
}

export enum VecticeStoredItem {
  KC_REALM_CONF = 'kc_realm_conf',
  KC_REFERRER = 'kc_referrer',
  VECTICE_AI_REGULATION_ACKNOWLEDGEMENT = 'vect_airegulation_acknowledgement',
  VECTICE_AUTOLOG_ACKNOWLEDGEMENT = 'vect_autolog_acknowledgement',
  VECTICE_CDT_ACKNOWLEDGEMENT = 'vect_cdt_acknowledgement',
  VECTICE_CUSTOM_COLORS = 'vect_custom_colors',
  VECTICE_FREE_TRIAL_TRAILHEAD_ACKNOWLEDGMENT = 'vect_free_trial_trailhead_acknowledgment',
  VECTICE_TRIAL_STEPS = 'vect_trial_steps', // Deprecated, do not use
}

export interface VecticeStorage {
  [VecticeStoredItem.KC_REALM_CONF]: RealmInformation | null;
  [VecticeStoredItem.KC_REFERRER]?: string | null;
  [VecticeStoredItem.VECTICE_AI_REGULATION_ACKNOWLEDGEMENT]?: string | null;
  [VecticeStoredItem.VECTICE_AUTOLOG_ACKNOWLEDGEMENT]?: string | null;
  [VecticeStoredItem.VECTICE_CDT_ACKNOWLEDGEMENT]?: string | null;
  [VecticeStoredItem.VECTICE_CUSTOM_COLORS]?: string | null;
  [VecticeStoredItem.VECTICE_FREE_TRIAL_TRAILHEAD_ACKNOWLEDGMENT]?: string | null;
  [VecticeStoredItem.VECTICE_TRIAL_STEPS]?: UserTrialSteps | null; // Deprecated, do not use
}

export const getLocalStorageItem = <T extends VecticeStoredItem>(item: T): VecticeStorage[T] | null => {
  try {
    const data = window.localStorage.getItem(item);
    if (data) {
      return JSON.parse(data);
    }
  } catch (e) {
    logger.error(e);
  }

  return null;
};

export const saveLocalStorageItem = (item: VecticeStoredItem, value: any): void => {
  try {
    window.localStorage.setItem(item, JSON.stringify(value));
  } catch (e) {
    logger.error(e);
  }
};

export const clearLocalStorageItem = (item: VecticeStoredItem): void => {
  try {
    window.localStorage.removeItem(item);
  } catch (e) {
    logger.error(e);
  }
};
