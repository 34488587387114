import React from 'react';

import { Typography } from '../../ui';

import styles from './IterationStepArtifactMovingClone.module.scss';

export interface IterationStepArtifactMovingCloneProps {
  assetSelectedSize: number;
}

export const IterationStepArtifactMovingClone = ({ assetSelectedSize }: IterationStepArtifactMovingCloneProps) => {
  return (
    <Typography paragraph className={styles.clone}>
      {$t({
        id: 'iteration.movingClone.assetSelectedSize.label',
        defaultMessage: 'Moving {assetSelectedSize, plural, one {# asset} other {# assets}}',
        values: { assetSelectedSize },
      })}
    </Typography>
  );
};
