import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as PhaseIcon } from '../../../assets/icons/specials/ic-phase.svg';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { AssetRow, FlexContainer, Typography } from '../../../ui';
import { DocumentationPageStatusBadge } from '../../badges';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const PhaseRowFragment = graphql(`
  fragment phaseRow on Phase {
    vecticeId
    name
    status
    updatedDate
    owner {
      ...userFields
    }
  }
`);

interface PhaseRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  phase?: FragmentType<typeof PhaseRowFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const PhaseRow = (props: PhaseRowProps) => {
  const phase = useFragment(PhaseRowFragment, props.phase);

  if (!phase) {
    return null;
  }

  return (
    <AssetRow icon={PhaseIcon} label={phase.name} parents={props.project?.name} {...props}>
      <Typography variant="footnote" color="secondary">
        {phase.vecticeId}
      </Typography>
      {props.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace name={props.workspace.name} iconSize={14} type={props.workspace.type} ellipsis />
        </Typography>
      )}
      <DocumentationPageStatusBadge status={phase.status} />
      {!!phase.owner && (
        <FlexContainer gap={4}>
          <Typography variant="footnote" color="secondary">
            {$t({
              id: 'phase.row.owner',
              defaultMessage: 'Phase owner',
            })}
          </Typography>
          <UserAvatar user={phase.owner} size="xs" />
        </FlexContainer>
      )}
      {!!phase.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy date={<FormatDate date={phase.updatedDate} fromNow />} />
        </Typography>
      )}
    </AssetRow>
  );
};
