import { ReactComponent as DatasetVersionIcon } from '../assets/icons/specials/ic-dataset-version.svg';
import { ReactComponent as DatasetIcon } from '../assets/icons/specials/ic-dataset.svg';
import { ReactComponent as IterationIcon } from '../assets/icons/specials/ic-iteration.svg';
import { ReactComponent as RunIcon } from '../assets/icons/specials/ic-lineage.svg';
import { ReactComponent as ModelVersionIcon } from '../assets/icons/specials/ic-model-version.svg';
import { ReactComponent as ModelIcon } from '../assets/icons/specials/ic-model.svg';
import { ReactComponent as PhaseIcon } from '../assets/icons/specials/ic-phase.svg';
import { ReactComponent as ProjectIcon } from '../assets/icons/specials/ic-project.svg';
import { ReactComponent as ReportIcon } from '../assets/icons/specials/ic-report.svg';
import { ReactComponent as StepIcon } from '../assets/icons/specials/ic-step.svg';
import { ReactComponent as WorkspaceIcon } from '../assets/icons/specials/ic-workspace.svg';
import { ActivityTargetType, CdtTemplateAssetType, LlmPromptTypeEnum } from '../gql/graphql';

export enum VecticeResourceType {
  API_KEY = 'apikey',
  CDT_MACRO = 'cdtmacro',
  CDT_TEMPLATE = 'cdttemplate',
  CDT_REPORT = 'cdtreport',
  DATASET = 'dataSet',
  DATASET_VERSION = 'datasetVersion',
  DATASET_VERSION_ATTACHMENT = 'datasetVersionAttachment',
  DATASET_VERSION_RESOURCE = 'datasetVersionResource',
  ITERATION = 'iteration',
  ITERATION_SECTION = 'iterationSection',
  ITERATION_STEP = 'iterationStep',
  FINDING = 'finding',
  RUN = 'run',
  LLM_PROMPT = 'prompt',
  MODEL = 'model',
  MODEL_VERSION = 'modelVersion',
  MODEL_VERSION_ATTACHMENT = 'modelVersionAttachment',
  PHASE = 'phase',
  PROJECT = 'project',
  REQUIREMENT = 'requirement',
  SOURCE_COLUMN = 'sourceColumns',
  UNKNOWN = 'unknown',
  WORKSPACE = 'workspace',
}

export const getVecticeResourceFromLLMType = (promptType: LlmPromptTypeEnum): VecticeResourceType => {
  switch (promptType) {
    case LlmPromptTypeEnum.Iteration:
      return VecticeResourceType.ITERATION;
    case LlmPromptTypeEnum.ModelVersion:
      return VecticeResourceType.MODEL_VERSION;
    case LlmPromptTypeEnum.DatasetVersion:
      return VecticeResourceType.DATASET_VERSION;
    default:
      throw new Error(
        $t({
          id: 'genai.promptType.notSupported',
          defaultMessage: 'GenAi is not supported for the type: {promptType}',
          values: {
            promptType,
          },
        }),
      );
  }
};

export const getVecticeResourceTypeLabel = (resourceType: VecticeResourceType) => {
  switch (resourceType) {
    case VecticeResourceType.API_KEY:
      return $t({ id: 'resourcetype.apikey', defaultMessage: 'API Key' });
    case VecticeResourceType.CDT_MACRO:
      return $t({ id: 'resourcetype.cdtmacro', defaultMessage: 'Macro' });
    case VecticeResourceType.CDT_TEMPLATE:
      return $t({ id: 'resourcetype.cdttemplate', defaultMessage: 'Template' });
    case VecticeResourceType.CDT_REPORT:
      return $t({ id: 'resourcetype.cdtreport', defaultMessage: 'Report' });
    case VecticeResourceType.DATASET:
      return $t({ id: 'resourcetype.dataset', defaultMessage: 'Dataset' });
    case VecticeResourceType.DATASET_VERSION:
      return $t({ id: 'resourcetype.datasetVersion', defaultMessage: 'Dataset version' });
    case VecticeResourceType.DATASET_VERSION_RESOURCE:
      return $t({ id: 'resourcetype.datasetVersionResource', defaultMessage: 'Dataset version resource' });
    case VecticeResourceType.ITERATION:
      return $t({ id: 'resourcetype.iteration', defaultMessage: 'Iteration' });
    case VecticeResourceType.FINDING:
      return $t({ id: 'resourcetype.issue', defaultMessage: 'Issue' });
    case VecticeResourceType.RUN:
      return $t({ id: 'resourcetype.lineage', defaultMessage: 'Lineage' });
    case VecticeResourceType.MODEL:
      return $t({ id: 'resourcetype.model', defaultMessage: 'Model' });
    case VecticeResourceType.MODEL_VERSION:
      return $t({ id: 'resourcetype.modelVersion', defaultMessage: 'Model version' });
    case VecticeResourceType.MODEL_VERSION_ATTACHMENT:
      return $t({ id: 'resourcetype.modelVersionAttachment', defaultMessage: 'Model version attachment' });
    case VecticeResourceType.LLM_PROMPT:
      return $t({ id: 'resourcetype.llmPrompt', defaultMessage: 'Prompt' });
    case VecticeResourceType.PHASE:
      return $t({ id: 'resourcetype.phase', defaultMessage: 'Phase' });
    case VecticeResourceType.PROJECT:
      return $t({ id: 'resourcetype.project', defaultMessage: 'Project' });
    case VecticeResourceType.ITERATION_STEP:
      return $t({ id: 'resourcetype.stepDefinition', defaultMessage: 'Step' });
    case VecticeResourceType.ITERATION_SECTION:
      return $t({ id: 'resourcetype.iterationSection', defaultMessage: 'Section' });
    case VecticeResourceType.REQUIREMENT:
      return $t({ id: 'resourcetype.requirement', defaultMessage: 'Requirement' });
    case VecticeResourceType.WORKSPACE:
      return $t({ id: 'resourcetype.workspace', defaultMessage: 'Workspace' });
    default:
      throw new Error(
        $t({
          id: 'resourcetype.errorLabel',
          defaultMessage: 'Label is not supported yet for the following resource type: {resourceType}',
          values: {
            resourceType,
          },
        }),
      );
  }
};

export const getVecticeResourceTypeIcon = (resourceType?: VecticeResourceType) => {
  switch (resourceType) {
    case VecticeResourceType.WORKSPACE:
      return WorkspaceIcon;
    case VecticeResourceType.PROJECT:
      return ProjectIcon;
    case VecticeResourceType.PHASE:
      return PhaseIcon;
    case VecticeResourceType.ITERATION:
      return IterationIcon;
    case VecticeResourceType.REQUIREMENT:
      return StepIcon;
    case VecticeResourceType.ITERATION_STEP:
      return StepIcon;
    case VecticeResourceType.DATASET:
      return DatasetIcon;
    case VecticeResourceType.DATASET_VERSION:
      return DatasetVersionIcon;
    case VecticeResourceType.MODEL:
      return ModelIcon;
    case VecticeResourceType.MODEL_VERSION:
      return ModelVersionIcon;
    case VecticeResourceType.RUN:
      return RunIcon;
    case VecticeResourceType.CDT_REPORT:
      return ReportIcon;
    default:
      return undefined;
  }
};

export const getActivityTargetForUserActivities = () => [
  ActivityTargetType.Workspace,
  ActivityTargetType.WorkspaceUser,
  ActivityTargetType.DataSet,
  ActivityTargetType.DataSetVersion,
  ActivityTargetType.Finding,
  ActivityTargetType.Model,
  ActivityTargetType.ModelVersion,
  ActivityTargetType.Phase,
  ActivityTargetType.Project,
  ActivityTargetType.Review,
  ActivityTargetType.ReviewComment,
  ActivityTargetType.Iteration,
  ActivityTargetType.CdtReport,
  ActivityTargetType.Invite,
];

export const getActivityTargetByResourceType = (resourceType: VecticeResourceType) => {
  switch (resourceType) {
    case VecticeResourceType.CDT_REPORT:
      return [ActivityTargetType.CdtReport];
    case VecticeResourceType.DATASET:
      return [ActivityTargetType.DataSet];
    case VecticeResourceType.DATASET_VERSION:
      return [ActivityTargetType.DataSetVersion];
    case VecticeResourceType.MODEL:
      return [ActivityTargetType.Model];
    case VecticeResourceType.MODEL_VERSION:
      return [ActivityTargetType.ModelVersion];
    case VecticeResourceType.ITERATION:
      return [ActivityTargetType.Iteration, ActivityTargetType.IterationStep];
    case VecticeResourceType.PHASE:
      return [
        ActivityTargetType.Phase,
        ActivityTargetType.Review,
        ActivityTargetType.ReviewComment,
        ActivityTargetType.StepDefinition,
      ];
    case VecticeResourceType.PROJECT:
      return [
        ActivityTargetType.DataSet,
        ActivityTargetType.DataSetVersion,
        ActivityTargetType.Finding,
        ActivityTargetType.Model,
        ActivityTargetType.ModelVersion,
        ActivityTargetType.Phase,
        ActivityTargetType.Project,
        ActivityTargetType.Review,
        ActivityTargetType.ReviewComment,
        ActivityTargetType.Iteration,
        ActivityTargetType.CdtReport,
      ];
    case VecticeResourceType.WORKSPACE:
      return [
        ActivityTargetType.Workspace,
        ActivityTargetType.WorkspaceUser,
        ActivityTargetType.DataSet,
        ActivityTargetType.DataSetVersion,
        ActivityTargetType.Model,
        ActivityTargetType.ModelVersion,
        ActivityTargetType.Phase,
        ActivityTargetType.Project,
        ActivityTargetType.Review,
        ActivityTargetType.ReviewComment,
        ActivityTargetType.Iteration,
      ];
    default:
      return [];
  }
};

export const getActivityTargetFilterForModel = (modelIds?: string[]) => {
  return modelIds ? { targetType: ActivityTargetType.Model, targetIds: modelIds } : undefined;
};

export const getActivityTargetFilterForModelVersion = (modelVersionIds?: string[]) => {
  return modelVersionIds ? { targetType: ActivityTargetType.ModelVersion, targetIds: modelVersionIds } : undefined;
};

export const getActivityTargetFilterForDataset = (datasetIds?: string[]) => {
  return datasetIds ? { targetType: ActivityTargetType.DataSet, targetIds: datasetIds } : undefined;
};

export const getActivityTargetFilterForDatasetVersion = (datasetVersionIds?: string[]) => {
  return datasetVersionIds
    ? { targetType: ActivityTargetType.DataSetVersion, targetIds: datasetVersionIds }
    : undefined;
};
export const getActivityTargetFilterForFinding = (findingIds?: number[]) => {
  return findingIds ? { targetType: ActivityTargetType.Finding, targetIds: findingIds } : undefined;
};

export const getActivityTargetFilterForPhase = (phaseIds?: string[]) => {
  return phaseIds ? { targetType: ActivityTargetType.Phase, targetIds: phaseIds } : undefined;
};

export const getActivityTargetFilterForIteration = (iterationIds?: string[]) => {
  return iterationIds ? { targetType: ActivityTargetType.Iteration, targetIds: iterationIds } : undefined;
};

export const getActivityTargetFilterForReport = (reportIds?: string[]) => {
  return reportIds ? { targetType: ActivityTargetType.CdtReport, targetIds: reportIds } : undefined;
};

export const getActivityTargetFilter = (resourceType: VecticeResourceType, resourceIds: string[]) => {
  switch (resourceType) {
    case VecticeResourceType.DATASET:
      return getActivityTargetFilterForDataset(resourceIds);
    case VecticeResourceType.DATASET_VERSION:
      return getActivityTargetFilterForDatasetVersion(resourceIds);
    case VecticeResourceType.MODEL:
      return getActivityTargetFilterForModel(resourceIds);
    case VecticeResourceType.MODEL_VERSION:
      return getActivityTargetFilterForModelVersion(resourceIds);
    case VecticeResourceType.PHASE:
      return getActivityTargetFilterForPhase(resourceIds);
    case VecticeResourceType.ITERATION:
      return getActivityTargetFilterForIteration(resourceIds);
    default:
      return undefined;
  }
};

const cdtTemplateAssetType: Record<CdtTemplateAssetType, VecticeResourceType> = {
  [CdtTemplateAssetType.DatasetVersion]: VecticeResourceType.DATASET_VERSION,
  [CdtTemplateAssetType.ModelVersion]: VecticeResourceType.MODEL_VERSION,
  [CdtTemplateAssetType.Iteration]: VecticeResourceType.ITERATION,
  [CdtTemplateAssetType.Phase]: VecticeResourceType.PHASE,
  [CdtTemplateAssetType.Project]: VecticeResourceType.PROJECT,
};

export const getCDTTemplateAssetType = (assetType: CdtTemplateAssetType) => {
  return cdtTemplateAssetType[assetType];
};
