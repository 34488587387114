import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { UserRole } from '../../gql/graphql';
import { WithRoles } from '../../guards';
import { buildLink, VecticeRoutes } from '../../routes';
import { FlexContainer, Logo, Navbar as NavbarUI } from '../../ui';
import { listenForKonamiCode } from '../../utils';
import { Notifications } from '../notifications';

import { InviteButton } from './invite';
import { CreateLink, HomeLink, WorkspacesLink } from './links';
import { ApiKeyMenu, HelpMenu } from './menus';
import { ProfileMenu } from './profile';
import { SearchInput } from './search';

import styles from './Navbar.module.scss';

export const Navbar = () => {
  const [goldLogo, setGoldLogo] = useState(false);

  useEffect(() => {
    return listenForKonamiCode(() => setGoldLogo(true));
  }, []);

  return (
    <NavbarUI
      logo={
        <Link to={buildLink(VecticeRoutes.HOME)}>
          <Logo aria-label="Vectice" variant={goldLogo ? 'gold' : 'default'} />
        </Link>
      }
    >
      <FlexContainer align="center" gap={12} className={styles.left_nav}>
        <HomeLink />
        <WorkspacesLink />
        <CreateLink />
      </FlexContainer>
      <FlexContainer align="center" gap={12} className={cn(styles.right_nav, styles.max_width)} justify="end">
        <SearchInput />
        <WithRoles organizationRole={[UserRole.Member, UserRole.OrgAdmin]}>
          <InviteButton />
        </WithRoles>
        <Notifications />
        <ApiKeyMenu />
        <HelpMenu />
        <ProfileMenu />
      </FlexContainer>
    </NavbarUI>
  );
};
