import React, { useEffect } from 'react';

import { RegisteredPlugin } from '../types';

import { FilterComposerContext } from './FilterComposerContext';

export const useRegisterPlugin = (plugin: RegisteredPlugin) => {
  const context = React.useContext(FilterComposerContext);

  if (context === undefined) {
    throw new Error('useFilterComposerContext must be used within FilterComposer');
  }

  const { register } = context;

  useEffect(() => register(plugin), [register]);
};
