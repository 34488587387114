import { graphql } from '../../gql';

export const GET_DATASET_TYPE = graphql(`
  query getDatasetType($datasetId: VecticeId!) {
    getDataset(datasetId: $datasetId) {
      vecticeId
      type
    }
  }
`);
