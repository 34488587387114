import React from 'react';
import { Link } from 'react-router-dom';

import { ThreadCreatedContent } from '../../../entities';
import { getVecticeResourceTypeLabel } from '../../../utils';
import { FormatUserName } from '../../formatters';
import { ThreadNotificationProps, UserNotificationThreadCreated } from '../types';
import { getThreadNotificationInformation } from '../utils';

import { Notification } from './Notification';

export const ThreadCreatedNotification = ({
  notification: { content, creator, createdDate },
  onClose,
}: ThreadNotificationProps<UserNotificationThreadCreated>) => {
  const parsedContent: ThreadCreatedContent = JSON.parse(content);

  const { type, link, title } = getThreadNotificationInformation(parsedContent);

  return (
    <Notification component={Link} to={link} creator={creator} createdDate={createdDate} onClose={onClose}>
      {$t({
        id: 'notification.thread.created',
        defaultMessage: '<bold>{creator}</bold> created a comment in {type} <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title,
          type: getVecticeResourceTypeLabel(type).toLowerCase(),
        },
      })}
    </Notification>
  );
};
