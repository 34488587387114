import { stringify } from 'querystring';

import Config from '../config';
import { DatasetSourceType } from '../gql/graphql';
import { restClient } from '../services';

import { ExportOptionsType } from './export';
import { VecticeResourceType } from './vectice-resource-type';

export const getDocGenUrl = ({
  resourceType,
  resourceId,
  exportOption,
}: {
  resourceType?: VecticeResourceType | string;
  resourceId?: number | string;
  exportOption?: ExportOptionsType;
}) => {
  const resourceTypePath = resourceType ? `/${resourceType?.toLowerCase()}` : '';
  const resourceIdPath = resourceId ? `/${resourceId}` : '';
  const exportOptionPath = exportOption ? `?format=${exportOption}` : '';
  return `${Config.API_URL}/docgen${resourceTypePath}${resourceIdPath}${exportOptionPath}`;
};

export const getRawPdfUrl = (resourceType: 'report' | 'phase' | 'project' | 'finding', resourceId: number | string) => {
  return `${Config.API_URL}/docgen/export/${resourceType}/${resourceId}`;
};

export const getRawWordUrl = (
  resourceType: 'report' | 'phase' | 'project' | 'finding',
  resourceId: number | string,
) => {
  return `${Config.API_URL}/docgen/export-word/${resourceType}/${resourceId}`;
};

export const getEntityFileURL = ({
  resourceType,
  resourceId,
  fileId,
  hasStaticView,
  query,
}: {
  resourceType?: VecticeResourceType | string;
  resourceId?: number | string;
  fileId?: number;
  hasStaticView?: boolean;
  query?: Record<string, string>;
}) => {
  const resourceTypePath = resourceType ? `/${resourceType?.toLowerCase()}` : '';
  const resourceIdPath = resourceId ? `/${resourceId}` : '';
  const fileIdPath = fileId ? `/${fileId}` : '';
  const staticPath = hasStaticView ? '/staticview' : '';
  const urlQuery = query ? `?${stringify(query)}` : '';
  return `${Config.API_URL}/entityfiles${resourceTypePath}${resourceIdPath}${fileIdPath}${staticPath}${urlQuery}`;
};

export const getEntityFilePreviewURL = (args: {
  resourceType?: VecticeResourceType | string;
  resourceId?: number | string;
  fileId?: number;
}) => `${getEntityFileURL(args)}/preview`;

export const getExportedArchiveUrl = (archive?: string | null) => {
  return `${Config.API_URL}/super-admin/download-archive/${archive}`;
};

export const getSuperAdminActionsUrl = () => {
  return `${Config.API_URL}/super-admin/download-super-admin-actions`;
};

export const getFeatureFlags = () => {
  return `${Config.API_URL}/super-admin/download-feature-flags`;
};

export const getAIRegulationImpactUrl = () => {
  return `${Config.API_URL}/resources/AI-Regulation.pdf`;
};

export const getValidationNotebookUrl = () => {
  return `${Config.API_URL}/resources/Validation-Notebook.ipynb`;
};

export const getValidationArchiveUrl = (modelVersionId: string, list: string[]) => {
  return `${Config.API_URL}/resources/validation-archive/${modelVersionId}/${list.join('-')}`;
};

export const getValidationConfigUrl = (modelVersionId: string) => {
  return `${Config.API_URL}/resources/validation-config/${modelVersionId}`;
};

export const getDatasetSourceCsvUrl = (id?: number, sourceType?: DatasetSourceType) => {
  if (sourceType === DatasetSourceType.Files) {
    return `${Config.API_URL}/dataset-source/file/${id}`;
  }

  return `${Config.API_URL}/dataset-source/db/${id}`;
};

export const getFindingsCsvUrl = (projectId: string) => {
  return `${Config.API_URL}/findings/export/${projectId}`;
};

export const getEntityFile = async (url: string) => {
  try {
    const response = await restClient.get(url);

    const blob = await response.blob();

    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise<string>((resolve) => {
      reader.onerror = () => {
        reader.abort();
        return '';
      };

      reader.onload = () => {
        resolve(reader.result as string);
      };
    });
  } catch (error) {
    return '';
  }
};

export const downloadFile = (src: string, fileName?: string | null) => {
  const element = document.createElement('a');
  element.setAttribute('href', src);
  element.setAttribute('download', fileName || '');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  element.remove();
  window.URL.revokeObjectURL(element.href);
};

export const downloadEntityFile = (attachment: {
  id: number;
  fileName?: string | null;
  name?: string | null;
  hasStaticView?: boolean;
}) =>
  downloadFile(
    getEntityFileURL({ fileId: attachment.id, hasStaticView: attachment.hasStaticView }),
    attachment.fileName ?? attachment.name,
  );

export const downloadExportedArchive = (archive?: string | null) => downloadFile(getExportedArchiveUrl(archive));

export const downloadDatasetSourceCsv = (id?: number, sourceType?: DatasetSourceType) =>
  downloadFile(getDatasetSourceCsvUrl(id, sourceType));

export const downloadAIRegulation = () => downloadFile(getAIRegulationImpactUrl());

export const downloadFindings = (projectId: string) => downloadFile(getFindingsCsvUrl(projectId));

export const downloadValidationConfigurationFile = (modelVersionId: string) =>
  downloadFile(getValidationConfigUrl(modelVersionId));
