import { DatasetSourceOrigin } from '../../gql/graphql';
import { getDatasetSourceOriginIcon } from '../../utils';

export const getDatasetSourceOriginDisplayName = (source?: DatasetSourceOrigin | string | null) => {
  switch (source) {
    case DatasetSourceOrigin.Bigquery:
      return $t({ id: 'datasetSourceOrigin.bigQuery.label', defaultMessage: 'BigQuery' });
    case DatasetSourceOrigin.DatabricksTable:
      return $t({ id: 'datasetSourceOrigin.databricksTable.label', defaultMessage: 'Databricks' });
    case DatasetSourceOrigin.Dataframe:
      return $t({ id: 'datasetSourceOrigin.dataframe.label', defaultMessage: 'Dataframe' });
    case DatasetSourceOrigin.Gcs:
      return $t({ id: 'datasetSourceOrigin.gcs.label', defaultMessage: 'GCS' });
    case DatasetSourceOrigin.Local:
      return $t({ id: 'datasetSourceOrigin.local.label', defaultMessage: 'Local file' });
    case DatasetSourceOrigin.Other:
      return $t({ id: 'datasetSourceOrigin.other.label', defaultMessage: 'Other' });
    case DatasetSourceOrigin.Redshift:
      return $t({ id: 'datasetSourceOrigin.redshift.label', defaultMessage: 'Redshift' });
    case DatasetSourceOrigin.S3:
      return $t({ id: 'datasetSourceOrigin.s3.label', defaultMessage: 'S3' });
    case DatasetSourceOrigin.Snowflake:
      return $t({ id: 'datasetSourceOrigin.snowflake.label', defaultMessage: 'Snowflake' });
    case DatasetSourceOrigin.SparkTable:
      return $t({ id: 'datasetSourceOrigin.sparkTable.label', defaultMessage: 'Spark' });
    default:
      return source ?? $t({ id: 'datasetSourceOrigin.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getDatasetSourceOriginOptions = () => [
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Bigquery),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Bigquery),
    value: DatasetSourceOrigin.Bigquery,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.DatabricksTable),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.DatabricksTable),
    value: DatasetSourceOrigin.DatabricksTable,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.SparkTable),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.SparkTable),
    value: DatasetSourceOrigin.SparkTable,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Dataframe),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Dataframe),
    value: DatasetSourceOrigin.Dataframe,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Gcs),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Gcs),
    value: DatasetSourceOrigin.Gcs,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Local),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Local),
    value: DatasetSourceOrigin.Local,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Redshift),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Redshift),
    value: DatasetSourceOrigin.Redshift,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.S3),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.S3),
    value: DatasetSourceOrigin.S3,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Snowflake),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Snowflake),
    value: DatasetSourceOrigin.Snowflake,
  },
  {
    icon: getDatasetSourceOriginIcon(DatasetSourceOrigin.Other),
    label: getDatasetSourceOriginDisplayName(DatasetSourceOrigin.Other),
    value: DatasetSourceOrigin.Other,
  },
];
