import { graphql } from '../../gql';

export const ADD_TO_STARRED_VERSIONS = graphql(`
  mutation addToStarredModelVersions($resource: StarredResourceSettings!) {
    addToStarredModelVersions(resource: $resource) {
      vecticeId
      isStarred
    }
  }
`);
