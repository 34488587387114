import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { CodeLineageUpdateInput, CodeVersionType, LineageType } from '../../../gql/graphql';
import { GET_CODE_ARTIFACT_BY_JOB_RUN_ID } from '../../../graphql/queries';
import { useEditLineage, useVecticeForm } from '../../../hooks';
import { ModalForm, WithLoading } from '../../../ui';
import { VecticeResourceType } from '../../../utils';

import { CodeTab } from './tabs';
import { LineageFormData } from './type';
import { codeLineageDefaultValues } from './utils';

const getSecondAdditionalInformation = (codeType: CodeVersionType, info?: string | null) => {
  if (!info) return undefined;
  if (codeType === CodeVersionType.Databricks) {
    if (info === '') return undefined;
    return info;
  }
  return info.trim();
};

export interface EditLineageModalProps {
  lineageId?: number;
  type: LineageType;
  versionId: string;
  onClose: () => void;
}

export const EditLineageCodeModal = ({ lineageId, type, versionId, onClose }: EditLineageModalProps) => {
  const [codeInfo, setCodeInfo] = useState<CodeLineageUpdateInput | null>(null);
  const [codeType, setCodeType] = useState<CodeVersionType | undefined>();

  const { updating: isSubmitting, editLineage } = useEditLineage(
    type === LineageType.DatasetVersion ? VecticeResourceType.DATASET : VecticeResourceType.MODEL,
  );

  const { control, formState, preSubmit, registerWithErrors, resetField, setFocus } = useVecticeForm<LineageFormData>({
    mode: 'all',
  });
  const { hasErrors, isDirty } = formState;

  const { loading: loadingCode } = useQuery(GET_CODE_ARTIFACT_BY_JOB_RUN_ID, {
    skip: !lineageId,
    variables: {
      lineageId: lineageId!,
    },
    onCompleted: (data) => {
      const info = codeLineageDefaultValues(data);
      setCodeType(info?.type);
      setCodeInfo(info);
    },
  });

  const handleSubmit: SubmitHandler<LineageFormData> = async ({ codeLineageInput }) => {
    await editLineage({
      variables: {
        versionId,
        type,
        data: {
          codeLineageInput:
            codeLineageInput && !!codeType
              ? {
                  ...codeLineageInput,
                  url: codeLineageInput.url.trim(),
                  firstAdditionalInformation: codeLineageInput.firstAdditionalInformation?.trim(),
                  secondAdditionalInformation: getSecondAdditionalInformation(
                    codeType ?? codeLineageInput.type,
                    codeLineageInput.secondAdditionalInformation,
                  ),
                  thirdAdditionalInformation: codeLineageInput.thirdAdditionalInformation?.trim(),
                  type: codeType ?? codeLineageInput.type,
                }
              : null,
        },
      },
    });
    onClose();
  };

  useEffect(() => {
    if (codeType === undefined) resetField('codeLineageInput', { defaultValue: null, keepDirty: true });
    else if (codeInfo?.type === codeType) {
      resetField('codeLineageInput', { defaultValue: codeInfo });
    } else {
      resetField('codeLineageInput.url', { defaultValue: '' });
      resetField('codeLineageInput.firstAdditionalInformation', { defaultValue: '' });
      resetField('codeLineageInput.secondAdditionalInformation', { defaultValue: '' });
      resetField('codeLineageInput.thirdAdditionalInformation', { defaultValue: '' });
    }
  }, [codeType, codeInfo]);

  return (
    <ModalForm
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      disabled={hasErrors || (!isDirty && !!codeType)}
      isSubmitting={isSubmitting}
      submitLabel={$t({ id: 'modal.editLineage.submit', defaultMessage: 'Save' })}
      title={$t({
        id: 'modal.editCodeLineage.title',
        defaultMessage: 'Edit code source',
      })}
      onClose={onClose}
      onSubmit={preSubmit(handleSubmit)}
    >
      <WithLoading loading={loadingCode}>
        <CodeTab
          control={control}
          type={codeType}
          registerWithErrors={registerWithErrors}
          setFocus={setFocus}
          setType={setCodeType}
        />
      </WithLoading>
    </ModalForm>
  );
};
