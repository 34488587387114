import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback } from 'react';

import { graphql } from '../../gql';
import { DataSet } from '../../gql/graphql';
import { useValidateName, useVecticeForm } from '../../hooks';
import { Input, message, ModalForm, TextArea, WithAsyncValidation } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';

export const CHECK_DATASET_NAME_AVAILABILITY = graphql(`
  query checkDatasetNameAvailability($datasetId: VecticeId!, $projectId: VecticeId!, $name: String!) {
    checkDatasetNameAvailability(datasetId: $datasetId, projectId: $projectId, name: $name)
  }
`);

export const UPDATE_DATASET = graphql(`
  mutation updateDataset($datasetId: VecticeId!, $data: DatasetUpdateInput!) {
    updateDataset(datasetId: $datasetId, dataset: $data) {
      vecticeId
      name
      description
    }
  }
`);

export interface FormData {
  name: string;
  description?: string | null;
}

interface EditDatasetModalProps {
  dataset: Pick<DataSet, 'vecticeId' | 'name' | 'description'>;
  projectId?: string;
  onClose: () => void;
}

export const EditDatasetModal = ({ dataset, projectId, onClose }: EditDatasetModalProps) => {
  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: dataset.name,
      description: dataset.description,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updateDataset] = useMutation(UPDATE_DATASET, {
    optimisticResponse: ({ datasetId: vecticeId, data: { name, description } }) => ({
      updateDataset: {
        vecticeId: vecticeId.toString(),
        name: name ?? dataset!.name,
        description: description ?? dataset!.description,
      },
    }),
    update: () => onClose(),
    onCompleted: ({ updateDataset: { name } }) =>
      message.success(
        $t({
          id: 'dataset.modal.edit.success',
          defaultMessage: 'Dataset "{name}" has been updated',
          values: { name },
        }),
      ),
    onError: (error) => message.error(error.message),
  });

  const [checkNameAvailability] = useLazyQuery(CHECK_DATASET_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const handleNameAvailability = useCallback(
    async (value: string) => {
      const { data: { checkDatasetNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          projectId: projectId ?? '',
          datasetId: dataset.vecticeId,
          name: value,
        },
      });

      return !!checkDatasetNameAvailability;
    },
    [dataset, projectId, checkNameAvailability],
  );

  const { validateName } = useValidateName(handleNameAvailability, VecticeResourceType.DATASET);

  const handleSubmit = useCallback(
    async ({ name, description }: FormData) => {
      await updateDataset({
        variables: {
          datasetId: dataset.vecticeId,
          data: { name, description },
        },
      });
    },
    [dataset, updateDataset],
  );

  return (
    <ModalForm
      title={$t({ id: 'dataset.modal.edit.title', defaultMessage: 'Edit dataset' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          label={$t({
            id: 'form.datasetEdit.nameLabel',
            defaultMessage: 'Dataset name',
          })}
          required
          gutterBottom
          autoFocus
        />
      </WithAsyncValidation>
      <TextArea
        label={$t({
          id: 'form.datasetEdit.descriptionLabel',
          defaultMessage: 'Dataset description',
        })}
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        placeholder={$t({ id: 'asset.details.description.placeholder', defaultMessage: 'Add description' })}
        hint={$t({ id: 'modal.duplicateProject.description.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
