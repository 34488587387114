import { useEffect, useState } from 'react';

import { getEntityFile, getEntityFileURL } from '../utils';

export type FileProps = {
  entityFileId: number;
};

export const useFileContent = ({ entityFileId }: FileProps) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileContent, setFileContent] = useState<string>();

  const fetchEntityFile = async (fileId: number) => {
    setLoading(true);
    setFileContent(undefined);
    try {
      const entityFileURL = getEntityFileURL({ fileId });
      const content = await getEntityFile(entityFileURL);
      setFileContent(content);
    } catch (err) {
      setError(true);
      setFileContent(undefined);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (entityFileId) {
      fetchEntityFile(entityFileId);
    }
  }, [entityFileId]);

  return { error, loading, fileContent };
};
