import React from 'react';

import { ReactComponent as OfflineGraphic } from '../assets/graphics/splash/offline.svg';
import { Content, FlexContainer, Icon, Logo, Main, Navbar, Page, Section, Typography } from '../ui';

import styles from './OfflinePage.module.scss';

export const OfflinePage = () => (
  <div className={styles.page}>
    <Navbar logo={<Logo aria-label="Vectice" />} />
    <Page>
      <Content>
        <Main className={styles.horizontal_container}>
          <FlexContainer component={Section} direction="column" justify="center">
            <Typography variant="heading1" gutterBottom>
              {$t({ id: 'errorPage.offline.title', defaultMessage: 'No Internet Connection' })}
            </Typography>
            <Typography paragraph gutterBottom>
              {$t({
                id: 'errorPage.offline.description',
                defaultMessage: "Oops! It looks like you're offline. Please check your connection and try again later.",
              })}
            </Typography>
            <Typography paragraph gutterBottom>
              {$t({
                id: 'errorPage.online.description',
                defaultMessage: 'This page will automatically reload once your connection is restored.',
              })}
            </Typography>
          </FlexContainer>
          <div className={styles.graphic_container} aria-hidden>
            <Icon className={styles.graphic} icon={OfflineGraphic} />
          </div>
        </Main>
      </Content>
    </Page>
  </div>
);
