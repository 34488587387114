import RcTooltip from 'rc-tooltip';
import React, { ReactElement, ReactNode } from 'react';

import { Typography } from '../typography';

import 'rc-tooltip/assets/bootstrap.css';
import styles from './Tooltip.module.scss';

export type Placement = 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
type Trigger = 'hover' | 'click' | 'focus';

export interface TooltipProps {
  placement?: Placement;
  text?: ReactNode;
  subText?: ReactNode;
  withFormatting?: boolean;
  trigger?: Trigger | Trigger[];
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  overlay?: (() => React.ReactNode) | React.ReactNode;
  overlayStyle?: React.CSSProperties;
  id?: string;
  children: ReactElement;
}

const LIMIT_INPUT_LARGE_WINDOW = 1400;
const LIMIT_INPUT_MEDIUM_WINDOW = 1200;
const LIMIT_INPUT_SMALL_WINDOW = 1000;
const LIMIT_INPUT_SMALLER_WINDOW = 800;

const limitInput = (input: ReactNode, limit: number) => {
  if ((typeof input === 'string' || typeof input === 'number') && input) {
    const string = input.toString();
    if (string.length > limit) return string.substring(0, limit) + '...';
  }
  return input;
};

export const Tooltip = ({ text, subText, overlay, withFormatting, children, ...props }: TooltipProps) => {
  if (!text && !overlay) {
    return children;
  }

  const height = window.innerHeight;
  let limit = LIMIT_INPUT_SMALLER_WINDOW;
  if (height < 650) {
    limit = LIMIT_INPUT_SMALLER_WINDOW;
  } else if (height < 750) {
    limit = LIMIT_INPUT_SMALL_WINDOW;
  } else if (height < 900) {
    limit = LIMIT_INPUT_MEDIUM_WINDOW;
  } else {
    limit = LIMIT_INPUT_LARGE_WINDOW;
  }

  const safeText = limitInput(text, limit);

  const simpleOverlay = (
    <>
      <Typography
        component={withFormatting ? 'pre' : 'div'}
        variant="footnote"
        weight="semi-bold"
        color="white"
        paragraph
      >
        {safeText}
      </Typography>
      {subText && (
        <Typography component={withFormatting ? 'pre' : 'div'} variant="footnote" color="white" paragraph>
          {subText}
        </Typography>
      )}
    </>
  );

  return (
    <RcTooltip
      overlay={overlay || simpleOverlay}
      overlayClassName={styles.tooltip}
      mouseEnterDelay={0.25}
      mouseLeaveDelay={0}
      destroyTooltipOnHide
      showArrow={false}
      {...props}
    >
      {children}
    </RcTooltip>
  );
};
