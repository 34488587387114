import React from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as FindingIcon } from '../../../assets/icons/interface/ic-finding.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { FindingTooltip } from './FindingTooltip';

interface FindingLinkProps {
  name?: string | null;
  resourceId?: number | null;
  tooltipPlacement?: TooltipProps['placement'];
}

export const FindingLink = ({ name, resourceId, tooltipPlacement = 'topLeft' }: FindingLinkProps) => (
  <FindingTooltip id={resourceId} placement={tooltipPlacement}>
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.FINDING, { findingId: resourceId }) : undefined}
      icon={FindingIcon}
    />
  </FindingTooltip>
);
