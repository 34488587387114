import { graphql } from '../../gql';

export const IterationStepFragment = graphql(`
  fragment IterationStep on IterationStep {
    id
    name
    description
    updatedDate
    paginatedArtifacts(page: { index: 1, size: 100 }) {
      total
      items {
        id
        stepId
        type
        index
        ...IterationStepArtifact
      }
    }
  }
`);
