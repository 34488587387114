import { graphql } from '../../gql';

export const GET_DATASET_VERSIONS_LIST = graphql(`
  query getDatasetVersionsList(
    $datasetId: VecticeId!
    $search: String
    $createdBy: [ID!]
    $showStarred: Boolean
    $contributors: [ID!]
    $pageIndex: Int!
    $pageSize: Int!
    $advancedFilters: [GroupFilter!]
    $order: ListOrderInput
  ) {
    getDatasetVersionsList(
      order: $order
      datasetId: $datasetId
      filters: {
        createdBy: $createdBy
        search: $search
        contributors: $contributors
        showStarred: $showStarred
        advancedFilters: $advancedFilters
      }
      page: { size: $pageSize, index: $pageIndex }
    ) {
      items {
        vecticeId
        versionNumber
        isStarred
        origin {
          id
        }
        properties {
          ...propertiesFields
        }
        createdBy {
          ...userFields
        }
        datasetSources {
          type
          usage
          size
          itemsCount
          columnsCount
        }
        createdDate
      }
      total
    }
  }
`);
