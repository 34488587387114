import React, { PropsWithChildren } from 'react';

import { WithGuardedProps } from './interfaces';
import { getArray } from './utils';

export const Allowed = ({ children }: PropsWithChildren<unknown>) => <>{children}</>;
export const Fallback = ({ children }: PropsWithChildren<unknown>) => <>{children}</>;
export const Pending = ({ children }: PropsWithChildren<unknown>) => <>{children}</>;

export const GuardedContent = ({ allowed, error, loading, children }: PropsWithChildren<WithGuardedProps>) => {
  const childrenArray = getArray(children);

  const allowedChildren = childrenArray.filter((child) => child.type?.name === Allowed.name);
  const fallbackChildren = childrenArray.filter((child) => child.type?.name === Fallback.name);
  const pendingChildren = childrenArray.filter((child) => child.type?.name === Pending.name);

  if ((fallbackChildren?.length || pendingChildren?.length) && !allowedChildren?.length) {
    throw new Error('You cannot use <Fallback /> or <Pending /> without <Allowed />');
  }

  if (error) {
    if (fallbackChildren?.length) {
      return <>{fallbackChildren}</>;
    }
    return null;
  }

  if (loading) {
    if (pendingChildren?.length) {
      return <>{pendingChildren}</>;
    }

    return null;
  }

  if (allowed) {
    if (allowedChildren?.length) {
      return <>{allowedChildren}</>;
    }

    return <>{children}</>;
  }

  if (fallbackChildren?.length) {
    return <>{fallbackChildren}</>;
  }

  return null;
};
