import { graphql } from '../../gql';

export const GENERATE_API_KEY = graphql(`
  mutation generateApiKey($apiKey: ApiKeyCreateInput!) {
    generateApiKey(apiKey: $apiKey) {
      key
    }
  }
`);

export const CHECK_API_KEY_NAME_AVAILABILITY = graphql(`
  query checkIfApiKeyNameExists($name: String!) {
    checkIfApiKeyNameExists(apiKeyName: $name)
  }
`);
