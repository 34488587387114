import { ModelType } from '../../gql/graphql';
import { getModelTypeIcon } from '../../utils';

export const getModelTypeDisplayName = (type?: ModelType | string | null) => {
  switch (type) {
    case ModelType.AnomalyDetection:
      return $t({ id: 'modelType.anomalyDetection.label', defaultMessage: 'Anomaly detection' });
    case ModelType.Classification:
      return $t({ id: 'modelType.classification.label', defaultMessage: 'Classification' });
    case ModelType.Clustering:
      return $t({ id: 'modelType.clustering.label', defaultMessage: 'Clustering' });
    case ModelType.Other:
      return $t({ id: 'modelType.other.label', defaultMessage: 'Other' });
    case ModelType.RecommendationModels:
      return $t({ id: 'modelType.recommendationModels.label', defaultMessage: 'Recommendation models' });
    case ModelType.Regression:
      return $t({ id: 'modelType.regression.label', defaultMessage: 'Regression' });
    case ModelType.TimeSeries:
      return $t({ id: 'modelType.timeSeries.label', defaultMessage: 'Time Series' });
    default:
      return '';
  }
};

export const getModelTypeOptions = () => [
  {
    value: ModelType.AnomalyDetection,
    label: getModelTypeDisplayName(ModelType.AnomalyDetection),
    icon: getModelTypeIcon(ModelType.AnomalyDetection),
  },
  {
    value: ModelType.Classification,
    label: getModelTypeDisplayName(ModelType.Classification),
    icon: getModelTypeIcon(ModelType.Classification),
  },
  {
    value: ModelType.Clustering,
    label: getModelTypeDisplayName(ModelType.Clustering),
    icon: getModelTypeIcon(ModelType.Clustering),
  },
  {
    value: ModelType.RecommendationModels,
    label: getModelTypeDisplayName(ModelType.RecommendationModels),
    icon: getModelTypeIcon(ModelType.RecommendationModels),
  },
  {
    value: ModelType.Regression,
    label: getModelTypeDisplayName(ModelType.Regression),
    icon: getModelTypeIcon(ModelType.Regression),
  },
  {
    value: ModelType.TimeSeries,
    label: getModelTypeDisplayName(ModelType.TimeSeries),
    icon: getModelTypeIcon(ModelType.TimeSeries),
  },
  {
    value: ModelType.Other,
    label: getModelTypeDisplayName(ModelType.Other),
    icon: getModelTypeIcon(ModelType.Other),
  },
];
