import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { useCheckPhaseNameAvailability } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import { VecticeResourceType } from '../../utils';

import { ResourceModal } from './ResourceModal';

const CREATE_PHASE = graphql(`
  mutation createPhase($parentId: VecticeId!, $createModel: PhaseCreateInput!) {
    createPhase(parentId: $parentId, createModel: $createModel) {
      vecticeId
    }
  }
`);

interface PhaseCreationModalProps {
  showCreationModal: boolean;
  projectId: string;
  onClose: () => void;
}

export const PhaseCreationModal = ({ showCreationModal, projectId, onClose }: PhaseCreationModalProps) => {
  const navigate = useNavigate();
  const { checkPhaseNameAvailability } = useCheckPhaseNameAvailability(projectId);

  const [createPhase, { loading: creating }] = useMutation(CREATE_PHASE, {
    errorPolicy: 'ignore', // Error is handled in the creation modal
    refetchQueries: ['getPhaseList'],
    onCompleted: ({ createPhase: { vecticeId } }) => {
      navigate(
        buildLink(VecticeRoutes.PHASE, {
          phaseId: vecticeId,
        }),
      );
    },
  });

  if (showCreationModal) {
    return (
      <ResourceModal
        loading={creating}
        resourceType={VecticeResourceType.PHASE}
        withDescription
        checkNameAvailability={checkPhaseNameAvailability}
        onClose={onClose}
        onSubmit={({ name, description }) =>
          createPhase({
            variables: {
              parentId: projectId,
              createModel: { name, description },
            },
          })
        }
      />
    );
  }

  return null;
};
