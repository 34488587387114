import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType, DocumentationPageStatus } from '../../../gql/graphql';
import { PhaseLink, ProjectLink } from '../../asset-display';
import { DocumentationPageStatusBadge } from '../../badges';
import { FormatDate, FULL_FORMAT } from '../../formatters';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { AssignedUser } from './ActivityUser';
import { ActivityProps } from './interfaces';

export const PhaseActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const phase = (
    <PhaseLink
      name={activity.targetName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetVecticeId}
    />
  );

  const project = <ProjectLink name={activity.parentName} resourceId={activity.parentVecticeId} />;

  const phaseSource = (
    <PhaseLink
      name={activity.sourceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.sourceVecticeId}
    />
  );

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const fromUser = <AssignedUser user={activity.fromUser} />;
  const toUser = <AssignedUser user={activity.toUser} />;

  const oldStatus = <DocumentationPageStatusBadge status={activity.fromValue as DocumentationPageStatus} />;
  const newStatus = <DocumentationPageStatusBadge status={activity.toValue as DocumentationPageStatus} />;

  const oldName = (
    <PhaseLink
      name={activity.fromValue || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetVecticeId}
    />
  );

  const newName = (
    <PhaseLink
      name={activity.toValue || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetVecticeId}
    />
  );

  if (activity.type === ActivityType.Created) {
    if (activity.updateType === ActivityUpdateType.Documentation) {
      return (
        <>
          {$t({
            id: 'activities.phase.documentation.updated',
            defaultMessage: '{user} updated the documentation of {resource}',
            values: {
              user,
              resource: phase,
            },
          })}
        </>
      );
    }
    return (
      <>
        {$t({
          id: 'activities.phase.created',
          defaultMessage: '{user} created {resource} of {parent}',
          values: {
            user,
            resource: phase,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Restored) {
    if (activity.toValue) {
      const restorationDate = <FormatDate date={activity.toValue} format={FULL_FORMAT} />;
      return (
        <>
          {$t({
            id: 'activities.phase.restore.version',
            defaultMessage: '{user} restored {resource} of {parent} to version of {restorationDate}',
            values: {
              user,
              resource: phase,
              parent: project,
              restorationDate: restorationDate,
            },
          })}
        </>
      );
    }

    return (
      <>
        {$t({
          id: 'activities.phase.restore.last_version',
          defaultMessage: '{user} restored {resource} of {parent}',
          values: {
            user,
            resource: phase,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.phase.deleted',
          defaultMessage: '{user} deleted {resource} of {parent}',
          values: {
            user,
            resource: phase,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Updated) {
    return (
      <>
        {$t({
          id: 'activities.phase.updated',
          defaultMessage: '{user} updated {resource} of {parent}',
          values: {
            user,
            resource: phase,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.phase.update.description',
            defaultMessage: '{user} updated the description of {resource} of {parent}',
            values: {
              user,
              resource: phase,
              parent: project,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.phase.update.name',
            defaultMessage: '{user} renamed {oldName} of {parent} to {newName}',
            values: {
              user,
              oldName: oldName,
              newName: newName,
              parent: project,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Status) {
      return (
        <>
          {$t({
            id: 'activities.phase.update.status',
            defaultMessage: '{user} transitioned {resource} of {parent} from {oldStatus} to {newStatus}',
            values: {
              user,
              resource: phase,
              parent: project,
              oldStatus,
              newStatus,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Owner) {
      return (
        <>
          {$t({
            id: 'activities.phase.update.owner',
            defaultMessage: '{user} changed the owner of {phase} in {project} from {fromUser} to {toUser}',
            values: {
              user,
              phase,
              project,
              fromUser,
              toUser,
            },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.Duplicated) {
    return (
      <>
        {$t({
          id: 'activities.phase.duplicated',
          defaultMessage: '{user} duplicated {resource} of {parent} to create {resourceTarget}',
          values: {
            user,
            resource: phaseSource,
            parent: project,
            resourceTarget: phase,
          },
        })}
      </>
    );
  }

  return null;
};
