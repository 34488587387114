import { graphql } from '../../gql';

export const GET_ITERATION_PICKER_LIST = graphql(`
  query getIterationPickerList($filters: IterationFiltersInput!, $order: ListOrderInput) {
    getIterationList(filters: $filters, order: $order) {
      items {
        vecticeId
        name
        index
      }
    }
  }
`);
