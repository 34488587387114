import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/interface/ic-remove.svg';
import { graphql } from '../../gql';
import { ArtifactType, EntityPropertyType } from '../../gql/graphql';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { useAnalytics } from '../../hooks';
import { Button, FlexContainer, message, Section, Typography } from '../../ui';
import { VecticeResourceType } from '../../utils';
import { DatasetResources } from '../dataset-source';
import { Lineage } from '../lineage/Lineage';
import { VersionProperties } from '../version';
import { VersionAttachments } from '../version-attachments';

import styles from './DatasetVersionOverview.module.scss';

export const GET_DATASET_VERSION_OVERVIEW = graphql(`
  query getDatasetVersionWithOrigin($datasetVersionId: VecticeId!) {
    getDatasetVersion(datasetVersionId: $datasetVersionId) {
      vecticeId
      origin {
        id
        datasetInputs
      }
      dataSet {
        vecticeId
        project {
          vecticeId
        }
      }
    }
  }
`);

export const DatasetVersionOverview = ({ datasetVersionId }: { datasetVersionId: string }) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });
  const [editLineage, setEditLineage] = useState(false);
  const { logClick } = useAnalytics();

  const { data } = useQuery(GET_DATASET_VERSION_OVERVIEW, {
    fetchPolicy: 'cache-and-network',
    skip: !datasetVersionId,
    variables: { datasetVersionId: datasetVersionId! },
    onError: (error) => message.error(error.message),
  });

  const datasetVersion = data?.getDatasetVersion;

  if (!datasetVersion) return null;

  return (
    <>
      <Section
        label={(ariaId) => (
          <FlexContainer align="center" className={styles.header} justify="space-between">
            <Typography id={ariaId} component="h3" variant="callout" color="primary" weight="semi-bold">
              {$t({ id: 'lineage.title', defaultMessage: 'Lineage' })}
            </Typography>
            {canEdit && (
              <Button
                variant="white"
                leftIcon={!editLineage ? EditIcon : CloseIcon}
                iconSize={18}
                onClick={() => {
                  logClick('Lineage', 'edit-dataset', 'click');
                  setEditLineage((prev) => !prev);
                }}
              >
                {!editLineage
                  ? $t({ id: 'lineage.edit', defaultMessage: 'Edit lineage' })
                  : $t({ id: 'lineage.close', defaultMessage: 'Close' })}
              </Button>
            )}
          </FlexContainer>
        )}
      >
        <Lineage
          currentArtifact={{
            artifactType: ArtifactType.Dataset,
            dataSetVersionId: datasetVersionId,
          }}
          datasetInputs={datasetVersion.origin?.datasetInputs ?? []}
          editMode={editLineage}
          lineageContext={{
            artifactType: ArtifactType.Dataset,
            lineageId: datasetVersion?.origin?.id,
            parentId: datasetVersion.dataSet.vecticeId,
            projectId: datasetVersion.dataSet.project.vecticeId,
            versionId: datasetVersionId,
          }}
          lineageId={datasetVersion?.origin?.id}
        />
      </Section>
      <DatasetResources datasetVersionId={datasetVersionId} />
      <VersionProperties
        entityType={EntityPropertyType.DataSetVersion}
        readOnly={!canEdit}
        resourceId={datasetVersionId}
      />
      <VersionAttachments
        readOnly={!canEdit}
        resourceId={datasetVersionId}
        resourceType={VecticeResourceType.DATASET_VERSION}
      />
    </>
  );
};
