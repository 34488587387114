import { graphql } from '../../../gql';

export const UPDATE_TARGET_COLUMN = graphql(`
  mutation updateDatasetVersion($targetColumn: String, $datasetVersionId: VecticeId!) {
    updateDatasetVersion(data: { targetColumn: $targetColumn }, datasetVersionId: $datasetVersionId) {
      vecticeId
      targetColumn
    }
  }
`);
