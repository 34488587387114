import { useMutation, useQuery, useSubscription } from '@apollo/client';

import { DocumentationPageStatus, ReviewStatus, ReviewUpdateInput } from '../gql/graphql';
import {
  CANCEL_REVIEW,
  CLOSE_REVIEW,
  CREATE_REVIEW_COMMENT,
  GET_REVIEW_COMMENT_LIST,
  GET_REVIEW_LIST,
  SUBSCRIBE_TO_REVIEW,
  SUBSCRIBE_TO_REVIEW_COMMENT_LIST,
} from '../graphql/queries';

export const useReview = (id?: string, phaseId?: string) => {
  const { data, loading: loadingComments } = useQuery(GET_REVIEW_COMMENT_LIST, {
    skip: !id,
    variables: {
      filters: {
        reviewId: id!,
      },
    },
  });
  const comments = data?.getReviewCommentList.items;

  useSubscription(SUBSCRIBE_TO_REVIEW, {
    skip: !id,
    variables: {
      id: id!,
    },
    onData: ({ data: subscriptionData, client }) => {
      if (phaseId && subscriptionData.data?.subscribeToReview) {
        const { subscribeToReview } = subscriptionData.data;

        client.cache.updateQuery(
          {
            query: GET_REVIEW_LIST,
            variables: {
              filters: {
                phaseId,
              },
            },
          },
          (data) =>
            data
              ? {
                  getReviewList: {
                    ...data.getReviewList,
                    items: data.getReviewList.items.map((review) =>
                      review.vecticeId === subscribeToReview?.vecticeId
                        ? {
                            ...review,
                            ...subscribeToReview,
                          }
                        : { ...review },
                    ),
                  },
                }
              : undefined,
        );
      }
    },
  });

  useSubscription(SUBSCRIBE_TO_REVIEW_COMMENT_LIST, {
    skip: !id,
    variables: {
      filters: {
        reviewId: id!,
      },
    },
    onData: ({ data: subscriptionData, client }) => {
      if (subscriptionData.data) {
        const {
          subscribeToReviewCommentList: { items },
        } = subscriptionData.data;

        client.cache.updateQuery(
          {
            query: GET_REVIEW_COMMENT_LIST,
            variables: {
              filters: {
                reviewId: id!,
              },
            },
          },
          (data) => (data ? { getReviewCommentList: { items } } : undefined),
        );
      }
    },
  });

  const [commentReviewMutation, { loading: commenting }] = useMutation(CREATE_REVIEW_COMMENT);

  const commentReview = async (comment: string) => {
    if (id) {
      await commentReviewMutation({
        variables: {
          reviewId: id,
          content: comment,
        },
      });
    }
  };

  const [cancelReviewMutation] = useMutation(CANCEL_REVIEW, {
    refetchQueries: ['getPhaseById'],
  });

  const cancelReview = () => {
    if (id) {
      cancelReviewMutation({
        variables: { id },
      });
    }
  };

  const [closeReviewMutation] = useMutation(CLOSE_REVIEW, {
    update: (cache, { data: reviewData }) => {
      if (phaseId && reviewData?.closeReview) {
        cache.modify({
          id: cache.identify({ vecticeId: phaseId, __typename: 'Phase' }),
          fields: {
            status() {
              return reviewData.closeReview.status === ReviewStatus.Approved
                ? DocumentationPageStatus.Completed
                : DocumentationPageStatus.Draft;
            },
            activeReview() {
              return reviewData.closeReview;
            },
          },
        });
      }
    },
  });

  const closeReview = async (review: ReviewUpdateInput) => {
    if (id) {
      await closeReviewMutation({
        variables: { id, review },
      });
    }
  };

  return { comments, commenting, loadingComments, cancelReview, closeReview, commentReview };
};
