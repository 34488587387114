import React from 'react';

import { useFragment } from '../../../gql';
import { ColumnSourceType } from '../../../gql/graphql';
import { isDefined } from '../../../utils';
import { FormatNumber, FormatPercentage, getPercentageTooltip } from '../../formatters';
import { NumericalSourceColumn as NumericalSourceColumnType, NumericalSourceColumnFragment } from '../types';

import { DatasetSourceColumnWrapper } from './DatasetSourceColumnWrapper';
import { StatisticCell, StatisticRow, Statistics } from './internal';

interface NumericalSourceColumnProps {
  column: NumericalSourceColumnType;
  columnSourceType: ColumnSourceType;
}

export const NumericalSourceColumn = ({ column, columnSourceType }: NumericalSourceColumnProps) => {
  const statistics = useFragment(NumericalSourceColumnFragment, column.statistics);

  return (
    <DatasetSourceColumnWrapper column={column} columnSourceType={columnSourceType}>
      {!!statistics?.numerical && (
        <Statistics>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.numerical.mean', defaultMessage: 'Mean' })} />
            <StatisticCell label={<FormatNumber value={statistics?.numerical.mean} />} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell
              header
              label={$t({
                id: 'column.statistic.numerical.stdDeviation',
                defaultMessage: 'St. Deviation',
              })}
            />
            <StatisticCell label={<FormatNumber value={statistics?.numerical.stdDeviation} />} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell
              header
              label={$t({ id: 'column.statistic.numerical.quantiles', defaultMessage: 'Quantiles' })}
              rowSpan={5}
            />
            <StatisticCell label={<FormatNumber value={statistics.numerical.quantiles.qMin} />} />
            <StatisticCell label={$t({ id: 'column.statistic.numerical.quantiles.qMin', defaultMessage: 'min' })} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell label={<FormatNumber value={statistics.numerical.quantiles.q25} />} />
            <StatisticCell label={$t({ id: 'column.statistic.numerical.quantiles.q25', defaultMessage: '25%' })} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell label={<FormatNumber value={statistics.numerical.quantiles.q50} />} />
            <StatisticCell label={$t({ id: 'column.statistic.numerical.quantiles.q50', defaultMessage: '50%' })} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell label={<FormatNumber value={statistics.numerical.quantiles.q75} />} />
            <StatisticCell label={$t({ id: 'column.statistic.numerical.quantiles.q75', defaultMessage: '75%' })} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell label={<FormatNumber value={statistics.numerical.quantiles.qMax} />} />
            <StatisticCell label={$t({ id: 'column.statistic.numerical.quantiles.qMax', defaultMessage: 'max' })} />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.numerical.missing', defaultMessage: 'Missing' })} />
            <StatisticCell
              label={
                <FormatPercentage
                  value={isDefined(statistics.numerical.missing) ? statistics.numerical.missing * 100 : null}
                />
              }
              tooltip={getPercentageTooltip(
                isDefined(statistics.numerical.missing) ? statistics.numerical.missing * 100 : null,
              )}
            />
          </StatisticRow>
        </Statistics>
      )}
    </DatasetSourceColumnWrapper>
  );
};
