import cn from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { ReactComponent as DragHandleIcon } from '../../assets/icons/interface/ic-drag-handle.svg';
import { IterationStepArtifactType } from '../../gql/graphql';
import { Checkbox, FlexContainer, Icon } from '../../ui';

import styles from './IterationStepArtifact.module.scss';

interface IterationStepArtifactProps {
  dragId: string;
  readOnly?: boolean;
  divider?: 'bottom' | 'top';
  isDragStarted: boolean;
  render: ReactNode;
  label: string;
  isDeleted?: boolean;
  type: IterationStepArtifactType;
  onCapture?: (capture: boolean) => void;
}

export const IterationStepArtifact = ({
  dragId,
  readOnly,
  divider,
  isDragStarted,
  render,
  label,
  type,
  isDeleted,
  onCapture,
}: IterationStepArtifactProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [click, setClick] = useState(false);

  let maxHeight = '100%';

  if (type === IterationStepArtifactType.DataSetVersion || type === IterationStepArtifactType.ModelVersion) {
    maxHeight = '70px';
  }

  if (type === IterationStepArtifactType.EntityFile) {
    maxHeight = '64px';
  }

  if (isDeleted || type === IterationStepArtifactType.EntityMetadata) {
    maxHeight = '44px';
  }

  const onMouseDown = () => {
    setClick(true);
  };
  const onMouseUp = () => {
    setClick(false);
  };
  const onMouseMove = () => {
    if (click) {
      if (onCapture) onCapture(true);
    }
  };

  useEffect(() => {
    if (!click && onCapture) onCapture(false);
  }, [click]);

  if (!render) {
    return null;
  }

  return (
    <FlexContainer
      component="li"
      direction="row"
      gap={2}
      align="flex-start"
      className={cn(styles.item_wrapper, {
        [styles.readOnly]: readOnly,
      })}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <div className={cn(styles.item)}>
        <div className={styles.row} aria-label={label}>
          <div style={{ maxHeight: maxHeight }} className={styles.checkboxWrapper}>
            <div
              className={cn(styles.dragHandle, {
                [styles.readOnly]: readOnly,
                [styles.hidden]: isDragStarted,
              })}
            >
              <Icon icon={DragHandleIcon} size={18} />
            </div>
            {!readOnly && (
              <div
                onMouseDown={(e) => e.stopPropagation()}
                onMouseMove={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
              >
                <Checkbox aria-label={label} value={dragId} ref={checkboxRef} disabled={isDragStarted} />
              </div>
            )}
          </div>
          <div
            className={cn(styles.container, {
              [styles.topDivider]: divider === 'top',
              [styles.bottomDivider]: divider === 'bottom',
            })}
          >
            {render}
          </div>
        </div>
      </div>
    </FlexContainer>
  );
};
