import React from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { Menu, MenuItem } from '../../ui';

export type SelectedModelVersionModal = 'delete' | null;

interface ModelVersionMenuProps {
  onOpenModal: (modal: SelectedModelVersionModal) => void;
}

export const ModelVersionMenu = ({ onOpenModal }: ModelVersionMenuProps) => (
  <Menu>
    <MenuItem leftIcon={DeleteIcon} color="danger" onClick={() => onOpenModal('delete')}>
      {$t({ id: 'modelVersion.menu.delete', defaultMessage: 'Delete Version' })}
    </MenuItem>
  </Menu>
);
