import { HistoryDrawer } from './HistoryDrawer';
import { OverlayDrawer } from './OverlayDrawer';
import { PermanentDrawer } from './PermanentDrawer';
import { PersistentDrawer } from './PersistentDrawer';

export const Drawer = {
  Overlay: OverlayDrawer,
  Persistent: PersistentDrawer,
  History: HistoryDrawer,
  Permanent: PermanentDrawer,
};
