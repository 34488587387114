import React, { useState } from 'react';

import config from '../../../config';
import { useAnalytics } from '../../../hooks';
import { Grid, GridCell } from '../../../ui';
import { VecticeStoredItem, getLocalStorageItem, saveLocalStorageItem, generateArray } from '../../../utils';
import { AIRegulationImpactPreview, AutologVideo, CDTVideo, ProductTourVideo } from '../../product-tour';
// import { WalkthroughVideo } from '../../product-tour/WalkthroughVideo';

import { AccessDocumentationCard, AiRegulationCard, AutologCard, CDTCard, WalkThroughCard } from './Cards';
import { GetStartedCardSkeleton } from './GetStartedCardSkeleton';

import styles from './GetStartedCards.module.scss';

export const GetStartedSkeleton = () => (
  <Grid columns={4} gap={16}>
    {generateArray(4).map((uuid) => (
      <GridCell key={uuid}>
        <GetStartedCardSkeleton />
      </GridCell>
    ))}
  </Grid>
);

export const GetStartedCards = () => {
  const autologAcknowledgement = getLocalStorageItem(VecticeStoredItem.VECTICE_AUTOLOG_ACKNOWLEDGEMENT);
  const cdtAcknowledgement = getLocalStorageItem(VecticeStoredItem.VECTICE_CDT_ACKNOWLEDGEMENT);
  const aiRegulationAcknowledgement = getLocalStorageItem(VecticeStoredItem.VECTICE_AI_REGULATION_ACKNOWLEDGEMENT);

  const [showAIRegulationImpact, setShowAIRegulationImpact] = useState(false);
  const [showAutologVideo, setShowAutologVideo] = useState(false);
  const [showCDTVideo, setShowCDTVideo] = useState(false);
  const [showProductTourVideo, setShowProductTourVideo] = useState(false);
  // const [showWalkThroughVideo, setShowWalkThroughVideo] = useState(false);

  const { logClick } = useAnalytics();

  const showAutologCard = !!config.AUTOLOG_VIDEO_URL;
  const showCDTCard = !!config.CDT_VIDEO_URL;
  const showAIRegulationCard = !showCDTCard;

  return (
    <>
      <div className={styles.grid}>
        <WalkThroughCard
          duration={$t({ id: 'home.trial.introduction.duration', defaultMessage: '4 min' })}
          onClick={() => {
            logClick('Home', 'walkthrough-card', 'click');
            setShowProductTourVideo(true);
          }}
        />
        <AccessDocumentationCard className={styles.link} />
        {showAutologCard && (
          <AutologCard
            isNew={autologAcknowledgement !== 'true'}
            onClick={() => {
              logClick('Home', 'autolog-card', 'click');
              saveLocalStorageItem(VecticeStoredItem.VECTICE_AUTOLOG_ACKNOWLEDGEMENT, 'true');
              setShowAutologVideo(true);
            }}
          />
        )}
        {showCDTCard && (
          <CDTCard
            isNew={cdtAcknowledgement !== 'true'}
            onClick={() => {
              logClick('Home', 'cdt-card', 'click');
              saveLocalStorageItem(VecticeStoredItem.VECTICE_CDT_ACKNOWLEDGEMENT, 'true');
              setShowCDTVideo(true);
            }}
          />
        )}
        {showAIRegulationCard && (
          <AiRegulationCard
            isNew={aiRegulationAcknowledgement !== 'true'}
            onClick={() => {
              logClick('Home', 'airegulation-card', 'click');
              saveLocalStorageItem(VecticeStoredItem.VECTICE_AI_REGULATION_ACKNOWLEDGEMENT, 'true');
              setShowAIRegulationImpact(true);
            }}
          />
        )}
      </div>
      {showAIRegulationImpact && <AIRegulationImpactPreview onClose={() => setShowAIRegulationImpact(false)} />}
      {showAutologVideo && <AutologVideo onClose={() => setShowAutologVideo(false)} />}
      {showCDTVideo && <CDTVideo onClose={() => setShowCDTVideo(false)} />}
      {/* {showWalkThroughVideo && <WalkthroughVideo onClose={() => setShowWalkThroughVideo(false)} />} */}
      {showProductTourVideo && <ProductTourVideo onClose={() => setShowProductTourVideo(false)} />}
    </>
  );
};
