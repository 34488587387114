import React from 'react';

import { Typography } from '../typography';

import styles from './EmptySection.module.scss';

export const EmptySection = ({ message }: { message: string }) => (
  <div className={styles.container}>
    <Typography variant="callout" color="tertiary" align="center" paragraph>
      {message}
    </Typography>
  </div>
);
