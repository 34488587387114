import cn from 'classnames';
import React, { ChangeEvent } from 'react';

import { CheckboxGroupContextProvider } from './context';

import styles from './CheckboxGroup.module.scss';

interface CheckboxGroupProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'name' | 'onChange' | 'value'> {
  name: string;
  checkedValues?: any[] | null;
  onChange?: (values: any[], event: ChangeEvent<HTMLInputElement>) => void;
  gutterBottom?: boolean;
}

export const CheckboxGroup = ({
  name,
  checkedValues,
  disabled,
  gutterBottom,
  className,
  onChange,
  children,
  ...props
}: CheckboxGroupProps) => {
  const handleChange = (value: any, event: ChangeEvent<HTMLInputElement>) => {
    const newValues = [...(checkedValues || [])];
    const index = newValues.findIndex((v) => v === value);
    if (index > -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }
    onChange?.(newValues, event);
  };

  const contextValue = {
    name,
    checkedValues,
    disabled,
    onChange: handleChange,
  };

  return (
    <CheckboxGroupContextProvider value={contextValue}>
      <div className={cn(styles.checkbox_group, { [styles.gutterBottom]: gutterBottom }, className)} {...props}>
        {children}
      </div>
    </CheckboxGroupContextProvider>
  );
};
