import cn from 'classnames';
import React, { ReactNode } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/interface/ic-arrow-down.svg';
import { Button } from '../button';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './FilterTrigger.module.scss';

interface FilterTriggerProps {
  label?: ReactNode;
  className?: string;
  children: ReactNode;
  regular?: boolean;
}

export const FilterTrigger = ({ label, children, className, regular, ...props }: FilterTriggerProps) => (
  <div className={cn(styles.filterTrigger_container, className)}>
    {label && (
      <Typography component="div" variant="callout" weight="semi-bold" color="primary" className={styles.label}>
        {label}
      </Typography>
    )}
    <Button regular={regular} variant="white" size="sm" className={styles.filterTrigger} {...props}>
      {children}
      <Icon icon={ArrowIcon} size={12} className={styles.icon} />
    </Button>
  </div>
);
