import cn from 'classnames';
import React from 'react';

import { ReactComponent as HintIcon } from '../../assets/icons/interface/ic-question-circle.svg';
import { Icon, Placement, Tooltip } from '../../ui';

type HintTooltipProps = {
  hint?: string;
  placement?: Placement;
  onClick?: () => void;
  className?: string;
};
export const HintTooltip = ({ hint, placement = 'top', onClick, className }: HintTooltipProps) => {
  if (!hint) return null;
  return (
    <Tooltip text={hint} placement={placement}>
      <Icon color="dark-gray" icon={HintIcon} size={16} onClick={onClick} className={cn(className)} />
    </Tooltip>
  );
};
