import React, { ReactNode } from 'react';

import { Button } from '../button';

import styles from './FilterControls.module.scss';

export interface FilterControlsProps {
  applyLabel?: ReactNode;
  clearLabel?: ReactNode;
  disabled?: boolean;
  onApply?: () => void;
  onClear?: () => void;
}

export const FilterControls = ({ applyLabel, clearLabel, disabled, onApply, onClear }: FilterControlsProps) => (
  <div className={styles.filterControls}>
    {clearLabel && onClear && (
      <Button disabled={disabled} variant="phantom" size="sm" onClick={onClear}>
        {clearLabel}
      </Button>
    )}
    {applyLabel && onApply && (
      <Button disabled={disabled} variant="primary" size="sm" onClick={onApply}>
        {applyLabel}
      </Button>
    )}
  </div>
);
