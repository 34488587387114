export type CellSize = 'sm' | 'md' | 'lg';

export const tableBreakPoints = {
  small: 1100 as number,
  medium: 1500 as number,
};

export const columnsDefaultSizes = {
  avatar: 70 as number | string,
  createdDate: 155 as number | string,
  date: 110 as number | string,
  name: 320 as number | string,
  origin: 70 as number | string,
  starred: 50 as number | string,
  version: 150 as number | string,
};
