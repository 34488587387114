import { useQuery } from '@apollo/client';
import React, { HTMLProps, useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import config from '../../config';
import {
  getDatasetSourceUsageName,
  getDataSourceColumnCount,
  getDataSourceFileCount,
  getDataSourceSize,
} from '../../entities/utils';
import {
  DatasetSource,
  DatasetSourceUsage,
  EntityProperty,
  GetDatasetVersionsListQuery,
  ListOrderInput,
  OrderDirection,
} from '../../gql/graphql';
import {
  buildAdvancedDateFilter,
  buildAdvancedEntitiesFilter,
  buildAdvancedIntegerFilter,
  buildAdvancedNameFilter,
} from '../../graphql/utils';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { useRetrievePageContext, useStarDatasetVersion } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import {
  Column,
  FlexContainer,
  NestedKeyInput,
  Pagination,
  sanitizeColumnKey,
  SearchInput,
  Table,
  Typography,
} from '../../ui';
import { isNil, isNumber } from '../../utils';
import { StarringButton } from '../buttons';
import { CreatedByFilter, CreatedDateFilter, FilterComposer, PropertiesFilter, StarredFilter } from '../filters';
import { FormatFileSize } from '../formatters';
import { NoDatasetVersions, NoResults } from '../no-results';
import {
  CreatedCell,
  OriginCell,
  PropertyCell,
  TableWidthObserver,
  columnsDefaultSizes,
  useTableSizeObserver,
} from '../tables';

import { GET_DATASET_TYPE } from './getDatasetType.query';
import { GET_DATASET_VERSIONS_LIST } from './getDatasetVersionsList.query';
import { GET_PROPERTY_NAMES } from './getPropertyNames.query';

import styles from './DatasetVersionsTable.module.scss';

const defaultColumnsSizeConfig = {
  ...columnsDefaultSizes,
  datasetSources: 120 as string | number,
};

type ColumnsSizeConfig = typeof defaultColumnsSizeConfig;

interface DatasetVersionsTableProps {
  borderless?: boolean;
  columnsSizeConfig?: Partial<ColumnsSizeConfig>;
  datasetId?: string;
  initialFilters?: Record<string, any>;
  initialOrder?: ListOrderInput;
  lessPaginationItems?: boolean;
  linkTarget?: HTMLProps<HTMLLinkElement>['target'];
  nbOfVisibleFilterPills?: number;
  pageSize?: number;
  scrollable?: boolean;
  onFiltersChange?: (filters: Record<string, any>) => void;
  onOrderChange?: (order?: ListOrderInput) => void;
}

type DatasetType = GetDatasetVersionsListQuery['getDatasetVersionsList']['items'][0];

const defaultOrder = {
  field: 'createdDate',
  direction: OrderDirection.Desc,
};

export const DatasetVersionsTable = ({
  borderless,
  columnsSizeConfig,
  datasetId,
  initialFilters = {},
  initialOrder = defaultOrder,
  lessPaginationItems,
  nbOfVisibleFilterPills,
  pageSize = config.LIST_DEFAULT_SIZE,
  scrollable,
  linkTarget,
  onFiltersChange,
  onOrderChange,
}: DatasetVersionsTableProps) => {
  const { workspace } = useRetrievePageContext();
  const { allowed: canUpdate } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });

  const tableRef = useRef<HTMLDivElement>(null);
  const { applySizeFactor } = useTableSizeObserver(tableRef.current);

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, any>>(initialFilters);
  const [order, setOrder] = useState<ListOrderInput | undefined>(initialOrder);
  const [page, setPage] = useState(1);

  const { updateStarDatasetVersion } = useStarDatasetVersion();

  const handleStarChange = (datasetVersionId: string, newState: boolean) =>
    updateStarDatasetVersion({
      variables: {
        resource: {
          resourceId: datasetVersionId,
          isStarred: newState,
        },
      },
    });

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setPage(1);
  }, []);

  const handleFiltersChange = useCallback((value: Record<string, any>) => {
    setFilters(value);
    onFiltersChange?.(value);
    setPage(1);
  }, []);

  const onColumnSort = (field: string, direction: OrderDirection, nestedKey?: NestedKeyInput) => {
    setOrder({
      field,
      direction,
      nestedKey,
    });
    onOrderChange?.({
      field,
      direction,
      nestedKey,
    });
  };

  const { loading: loadingDatasetType } = useQuery(GET_DATASET_TYPE, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      datasetId: datasetId!,
    },
  });

  const { data: datasetVersionData, loading: loadingVersions } = useQuery(GET_DATASET_VERSIONS_LIST, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      datasetId: datasetId!,
      ...filters,
      advancedFilters: [
        ...buildAdvancedDateFilter(filters?.createdDate),
        ...buildAdvancedNameFilter(filters?.name),
        ...buildAdvancedEntitiesFilter(filters?.properties, 'properties'),
        ...(isNumber(search) ? buildAdvancedIntegerFilter('versionNumber', [+search]) : []),
      ],
      pageIndex: page,
      pageSize,
      order,
    },
  });

  const { data: propertyNamesData, loading: loadingProperties } = useQuery(GET_PROPERTY_NAMES, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      datasetId: datasetId!,
    },
  });

  const versions = datasetVersionData?.getDatasetVersionsList?.items;
  const totalItems = datasetVersionData?.getDatasetVersionsList?.total;
  const properties = propertyNamesData?.getPropertyKeysOfEntity;
  const sources = datasetVersionData?.getDatasetVersionsList?.items.flatMap((item) => item.datasetSources);
  const hasTraining = sources?.some((source) => source.usage === DatasetSourceUsage.Training);
  const hasTesting = sources?.some((source) => source.usage === DatasetSourceUsage.Testing);
  const hasValidation = sources?.some((source) => source.usage === DatasetSourceUsage.Validation);

  const loading = loadingProperties || loadingVersions || loadingDatasetType;

  const formattedVersions = useMemo(() => {
    const items = versions || [];
    return items.map((datasetVersion) => ({
      ...datasetVersion,
      properties: datasetVersion.properties.reduce<Record<string, string>>(
        // @ts-expect-error Issue with the current use of Fragments
        (acc, { key, value }) => ({
          ...acc,
          [sanitizeColumnKey(key)]: value,
        }),
        {},
      ),
    }));
  }, [versions]);

  const hasFilters = !!Object.keys(filters).length || !!search;

  const datasetSourcesColumnsConfig = [
    {
      title: $t({ id: 'dataset.versionsTable.header.itemsCount', defaultMessage: 'Nb of Files' }),
      render: (usage: DatasetSourceUsage | null) => (datasetSources: DatasetSource[]) =>
        (
          <span className={styles.filesCount}>
            {!isNil(getDataSourceFileCount(datasetSources, usage))
              ? $t({
                  id: 'dataset.versionsTable.itemsCount',
                  defaultMessage: '{itemCount, plural, one {# File} other {# Files}}',
                  values: { itemCount: getDataSourceFileCount(datasetSources, usage) },
                })
              : $t({ id: 'dataset.versionsTable.filesCountLabel.noValue', defaultMessage: 'N/A' })}
          </span>
        ),
    },
    {
      title: $t({ id: 'dataset.versionsTable.header.totalSize', defaultMessage: 'Size' }),
      render: (usage: DatasetSourceUsage | null) => (datasetSources: DatasetSource[]) =>
        (
          <span className={styles.totalSize}>
            <FormatFileSize size={getDataSourceSize(datasetSources, usage)} />
          </span>
        ),
    },
    {
      title: $t({ id: 'dataset.versionsTable.header.columnsCount', defaultMessage: 'Nb of Columns' }),
      render: (usage: DatasetSourceUsage | null) => (datasetSources: DatasetSource[]) =>
        (
          <span className={styles.columnsCount}>
            {!isNil(getDataSourceColumnCount(datasetSources, usage))
              ? $t({
                  id: 'dataset.versionsTable.columnsCount',
                  defaultMessage: '{itemCount, plural, one {# Column} other {# Columns}}',
                  values: { itemCount: getDataSourceColumnCount(datasetSources, usage) },
                })
              : $t({ id: 'dataset.versionsTable.columnsCount.noValue', defaultMessage: 'N/A' })}
          </span>
        ),
    },
  ];

  const sizeConfig = { ...defaultColumnsSizeConfig, ...columnsSizeConfig };

  return (
    <>
      <TableWidthObserver ref={tableRef}>
        <FlexContainer align="flex-end" className={styles.filters} gap={16}>
          <SearchInput
            aria-label="Search"
            className={styles.search}
            placeholder={$t({ id: 'datasetVersion.search.placeholder', defaultMessage: 'Search by version number' })}
            type="number"
            min={0}
            onDebouncedChange={handleSearch}
          />
          <FilterComposer
            filters={filters}
            nbOfVisiblePills={nbOfVisibleFilterPills}
            onFiltersUpdate={handleFiltersChange}
          >
            <CreatedDateFilter />
            {workspace && <CreatedByFilter workspaceIdList={[workspace.vecticeId]} />}
            <PropertiesFilter entitiesNames={properties || []} />
            <StarredFilter />
          </FilterComposer>
        </FlexContainer>
      </TableWidthObserver>
      <Table
        aria-label="Dataset version table"
        borderless={borderless}
        data={formattedVersions}
        emptyText={hasFilters ? <NoResults borderless /> : <NoDatasetVersions borderless />}
        initialSort={{ key: initialOrder.field, direction: initialOrder.direction }}
        loading={loading}
        rowKey="vecticeId"
        scroll={{ x: true, y: scrollable }}
        onSort={onColumnSort}
      >
        <Column<DatasetType, DatasetType['isStarred']> key="isStarred" title="" fixed="left" width={sizeConfig.starred}>
          {(isStarred, { vecticeId }) => (
            <StarringButton
              inert={!canUpdate}
              starred={isStarred}
              onChange={canUpdate ? (value) => handleStarChange(vecticeId, value) : undefined}
            />
          )}
        </Column>
        <Column<DatasetType, DatasetType['versionNumber']>
          key="versionNumber"
          title={$t({ id: 'datasetVersion.table.version.label', defaultMessage: 'Version' })}
          fixed="left"
          sortable
          width={applySizeFactor(sizeConfig.version)}
        >
          {(versionNumber, { vecticeId }) => (
            <Typography
              className={styles.version}
              color="primary"
              component={Link}
              ellipsis
              link
              target={linkTarget ?? '_self'}
              to={buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: vecticeId })}
              variant="callout"
              weight="semi-bold"
            >
              {$t({
                id: 'datasetVersion.table.version.value',
                defaultMessage: 'Version {versionNumber}',
                values: { versionNumber: versionNumber },
              })}
            </Typography>
          )}
        </Column>
        <Column<DatasetType, DatasetType['origin']>
          key="origin"
          align="center"
          ellipsis
          title={$t({ id: 'modelVersions.versionsTable.lineage', defaultMessage: 'Lineage' })}
          width={sizeConfig.origin}
        >
          {(origin) => <OriginCell origin={origin} />}
        </Column>
        {hasTraining &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(DatasetSourceUsage.Training),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={120}
              ellipsis
            >
              {columnConfig.render(DatasetSourceUsage.Training)}
            </Column>
          ))}
        {hasTesting &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(DatasetSourceUsage.Testing),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={sizeConfig.datasetSources}
              ellipsis
            >
              {columnConfig.render(DatasetSourceUsage.Testing)}
            </Column>
          ))}
        {hasValidation &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(DatasetSourceUsage.Validation),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={sizeConfig.datasetSources}
              ellipsis
            >
              {columnConfig.render(DatasetSourceUsage.Validation)}
            </Column>
          ))}
        {!hasTraining &&
          !hasTesting &&
          !hasValidation &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(null),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={sizeConfig.datasetSources}
              ellipsis
            >
              {columnConfig.render(null)}
            </Column>
          ))}
        {!!properties?.length &&
          properties?.map((key) => (
            <Column
              key={`properties.${sanitizeColumnKey(key)}`}
              ellipsis
              nestedKey={{ field: 'key', value: key }}
              sortable="properties.value"
              title={key}
              onHeaderCell={() => ({
                title: key,
                prefix: 'Property',
              })}
            >
              {(value?: EntityProperty['value']) => <PropertyCell value={value} />}
            </Column>
          ))}
        <Column<DatasetType, DatasetType['createdDate']>
          key="createdDate"
          ellipsis
          sortable
          title={$t({ id: 'tables.columns.created', defaultMessage: 'Created' })}
          width={sizeConfig.createdDate}
        >
          {(createdDate, { createdBy }) => <CreatedCell date={createdDate} user={createdBy} />}
        </Column>
      </Table>
      <Pagination
        total={totalItems}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        showLessItems={lessPaginationItems}
      />
    </>
  );
};
