import { $getSelection, $isRangeSelection, LexicalEditor, NodeKey } from 'lexical';

import { ReactComponent as BorderIconAll } from '../../assets/icons/editor/ic-border-position-all.svg';
import { ReactComponent as BorderIconBottom } from '../../assets/icons/editor/ic-border-position-bottom.svg';
import { ReactComponent as BorderIconInner } from '../../assets/icons/editor/ic-border-position-inner.svg';
import { ReactComponent as BorderIconLeft } from '../../assets/icons/editor/ic-border-position-left.svg';
import { ReactComponent as BorderIconNone } from '../../assets/icons/editor/ic-border-position-none.svg';
import { ReactComponent as BorderIconOuter } from '../../assets/icons/editor/ic-border-position-outer.svg';
import { ReactComponent as BorderIconRight } from '../../assets/icons/editor/ic-border-position-right.svg';
import { ReactComponent as BorderIconTop } from '../../assets/icons/editor/ic-border-position-top.svg';
import { getCommonDirectionalValue, patchDirectionalProperty, PropertyPositionValues } from '../../utils';
import { BorderPositionType } from '../config';
import {
  $getNodeTriplet,
  $isTableCellNode,
  $isTableSelection,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_STYLE,
  DEFAULT_BORDER_WIDTH,
  TableCellNode,
  TableCellNodeStyleObject,
  TableDOMCell,
  TableSelection,
  TableSelectionShape,
} from '../plugins/lexical-table';
import { $getCell } from '../plugins/table/lexical-overrides';

enum NeighborPosition {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left',
}

export const getBorderPositionIcon = (position: BorderPositionType) => {
  switch (position) {
    case 'bottom':
      return BorderIconBottom;
    case 'inner':
      return BorderIconInner;
    case 'left':
      return BorderIconLeft;
    case 'none':
      return BorderIconNone;
    case 'outer':
      return BorderIconOuter;
    case 'right':
      return BorderIconRight;
    case 'top':
      return BorderIconTop;
    case 'all':
    default:
      return BorderIconAll;
  }
};

export const getBorderPositionLabel = (position: BorderPositionType) => {
  switch (position) {
    case 'all':
      return $t({ id: 'editor.toolbar.border.all', defaultMessage: 'All' });
    case 'outer':
      return $t({ id: 'editor.toolbar.border.outer', defaultMessage: 'Outer' });
    case 'inner':
      return $t({ id: 'editor.toolbar.border.inner', defaultMessage: 'Inner' });
    case 'top':
      return $t({ id: 'editor.toolbar.border.top', defaultMessage: 'Top' });
    case 'right':
      return $t({ id: 'editor.toolbar.border.right', defaultMessage: 'Right' });
    case 'bottom':
      return $t({ id: 'editor.toolbar.border.bottom', defaultMessage: 'Bottom' });
    case 'left':
      return $t({ id: 'editor.toolbar.border.left', defaultMessage: 'Left' });
    case 'none':
      return $t({ id: 'editor.toolbar.border.none', defaultMessage: 'None' });
    default:
      return 'unknown';
  }
};

export const extractSelectionCells = (selection: TableSelection): TableCellNode[] => {
  return selection.getNodes().filter($isTableCellNode);
};

export const getCellSimplifiedStyle = (cell: TableCellNode): TableCellNodeStyleObject => {
  const style = cell.getStyle();

  return {
    backgroundColor: getCommonDirectionalValue(style.backgroundColor),
    borderColor: getCommonDirectionalValue(style.borderColor),
    borderStyle: getCommonDirectionalValue(style.borderStyle),
    borderWidth: getCommonDirectionalValue(style.borderWidth),
  };
};

export const getCellsCommonStyle = (selection: TableSelection): TableCellNodeStyleObject => {
  const cells = extractSelectionCells(selection);

  let backgroundColor: TableCellNodeStyleObject['backgroundColor'] | null;
  let borderColor: TableCellNodeStyleObject['borderColor'] | null;
  let borderStyle: TableCellNodeStyleObject['borderStyle'] | null;
  let borderWidth: TableCellNodeStyleObject['borderWidth'] | null;

  let isInit = false;
  for (const cell of cells) {
    const style = cell.getStyle();

    if (!isInit) {
      backgroundColor = getCommonDirectionalValue(style.backgroundColor);
      borderColor = getCommonDirectionalValue(style.borderColor);
      borderStyle = getCommonDirectionalValue(style.borderStyle);
      borderWidth = getCommonDirectionalValue(style.borderWidth);
      isInit = true;
      continue;
    }

    if (getCommonDirectionalValue(style.backgroundColor) !== backgroundColor) {
      backgroundColor = null;
    }
    if (getCommonDirectionalValue(style.borderColor) !== borderColor) {
      borderColor = null;
    }
    if (getCommonDirectionalValue(style.borderStyle) !== borderStyle) {
      borderStyle = null;
    }
    if (getCommonDirectionalValue(style.borderWidth) !== borderWidth) {
      borderWidth = null;
    }
  }

  return {
    backgroundColor: backgroundColor ?? undefined,
    borderColor: borderColor ?? undefined,
    borderStyle: borderStyle ?? undefined,
    borderWidth: borderWidth ?? undefined,
  };
};

export const setCellsStyle = (selection: TableSelection, style: Partial<TableCellNodeStyleObject>) => {
  const cells = extractSelectionCells(selection);

  for (const cell of cells) {
    cell.setStyle(style);
  }
};

export const patchBorderStyleObject = (cell: TableCellNode, style: TableCellNodeStyleObject, positionValue: number) => {
  const cellStyle = cell.getStyle();

  return {
    borderColor: patchDirectionalProperty(
      cellStyle.borderColor ?? DEFAULT_BORDER_COLOR,
      style?.borderColor,
      positionValue,
    ),
    borderStyle: patchDirectionalProperty(
      cellStyle.borderStyle ?? DEFAULT_BORDER_STYLE,
      style?.borderStyle,
      positionValue,
    ),
    borderWidth: patchDirectionalProperty(
      (cellStyle.borderWidth as string) ?? DEFAULT_BORDER_WIDTH,
      style?.borderWidth as string,
      positionValue,
    ),
  };
};

export const $updateSingleCellBorderStyle = (
  cell: TableCellNode,
  style: Partial<TableCellNodeStyleObject>,
  position?: BorderPositionType,
) => {
  if (position === 'inner') {
    // Do nothing
    return;
  }

  const cellsRecord: Record<NodeKey, TableCellNode> = {};
  const positionsRecord: Record<NodeKey, number> = {};

  cellsRecord[cell.__key] = cell;

  switch (position) {
    case 'top': {
      positionsRecord[cell.__key] = PropertyPositionValues.top;
      const topNeighbor = cell.getTopNeighborCell();
      if (topNeighbor) {
        cellsRecord[topNeighbor.__key] = topNeighbor;
        positionsRecord[topNeighbor.__key] = PropertyPositionValues.bottom;
      }
      break;
    }
    case 'right': {
      positionsRecord[cell.__key] = PropertyPositionValues.right;
      const rightNeighbor = cell.getRightNeighborCell();
      if (rightNeighbor) {
        cellsRecord[rightNeighbor.__key] = rightNeighbor;
        positionsRecord[rightNeighbor.__key] = PropertyPositionValues.left;
      }
      break;
    }
    case 'bottom': {
      positionsRecord[cell.__key] = PropertyPositionValues.bottom;
      const bottomNeighbor = cell.getBottomNeighborCell();
      if (bottomNeighbor) {
        cellsRecord[bottomNeighbor.__key] = bottomNeighbor;
        positionsRecord[bottomNeighbor.__key] = PropertyPositionValues.top;
      }
      break;
    }
    case 'left': {
      positionsRecord[cell.__key] = PropertyPositionValues.left;
      const leftNeighbor = cell.getLeftNeighborCell();
      if (leftNeighbor) {
        cellsRecord[leftNeighbor.__key] = leftNeighbor;
        positionsRecord[leftNeighbor.__key] = PropertyPositionValues.right;
      }
      break;
    }
    case 'all':
    default: {
      positionsRecord[cell.__key] = PropertyPositionValues.all;
      const neighbors = cell.getNeighbors();
      Object.entries(neighbors).forEach(([pos, neighbor]) => {
        if (neighbor) {
          cellsRecord[neighbor.__key] = neighbor;
          positionsRecord[neighbor.__key] = getNeighborBorderPosition(pos as NeighborPosition);
        }
      });
      break;
    }
  }

  for (const key in positionsRecord) {
    const cellStyle = patchBorderStyleObject(cellsRecord[key], style, positionsRecord[key]);
    cellsRecord[key].setStyle(cellStyle);
  }
};

const $updateCellsAllBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const cellsRecord: Record<NodeKey, TableCellNode> = {};
  const positionsRecord: Record<NodeKey, number> = {};

  for (const cell of selectionCells) {
    const cellDOM = $getCell(editor, cell.__key);

    if (!cellDOM) throw new Error('Cell DOM not found');

    cellsRecord[cell.__key] = cell;
    positionsRecord[cell.__key] = PropertyPositionValues.all;

    const neighbors = getSelectionNeighbors(cell, cellDOM, selectionShape);
    Object.entries(neighbors).forEach(([pos, neighbor]) => {
      if (neighbor) {
        cellsRecord[neighbor.__key] = neighbor;
        positionsRecord[neighbor.__key] = getNeighborBorderPosition(pos as NeighborPosition);
      }
    });
  }

  for (const key in positionsRecord) {
    const cellStyle = patchBorderStyleObject(cellsRecord[key], style, positionsRecord[key]);
    cellsRecord[key].setStyle(cellStyle);
  }
};

const $updateCellsOuterBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const cellsRecord: Record<NodeKey, TableCellNode> = {};
  const positionsRecord: Record<NodeKey, number> = {};

  for (const cell of selectionCells) {
    const cellDOM = $getCell(editor, cell.__key);

    if (!cellDOM) throw new Error('Cell DOM not found');

    cellsRecord[cell.__key] = cell;
    positionsRecord[cell.__key] = 0;

    if (cellDOM.y === selectionShape.fromY) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] + PropertyPositionValues.top;
    }
    if (cellDOM.x === selectionShape.toX) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] + PropertyPositionValues.right;
    }
    if (cellDOM.y === selectionShape.toY) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] + PropertyPositionValues.bottom;
    }
    if (cellDOM.x === selectionShape.fromX) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] + PropertyPositionValues.left;
    }

    const neighbors = getSelectionNeighbors(cell, cellDOM, selectionShape);
    Object.entries(neighbors).forEach(([pos, neighbor]) => {
      if (neighbor) {
        cellsRecord[neighbor.__key] = neighbor;
        positionsRecord[neighbor.__key] = getNeighborBorderPosition(pos as NeighborPosition);
      }
    });
  }

  for (const key in positionsRecord) {
    const cellStyle = patchBorderStyleObject(cellsRecord[key], style, positionsRecord[key]);
    cellsRecord[key].setStyle(cellStyle);
  }
};

const $updateCellsInnerBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const cellsRecord: Record<NodeKey, TableCellNode> = {};
  const positionsRecord: Record<NodeKey, number> = {};

  for (const cell of selectionCells) {
    const cellDOM = $getCell(editor, cell.__key);

    if (!cellDOM) throw new Error('Cell DOM not found');

    cellsRecord[cell.__key] = cell;
    positionsRecord[cell.__key] = PropertyPositionValues.all;

    if (cellDOM.y === selectionShape.fromY) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] - PropertyPositionValues.top;
    }
    if (cellDOM.x === selectionShape.toX) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] - PropertyPositionValues.right;
    }
    if (cellDOM.y === selectionShape.toY) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] - PropertyPositionValues.bottom;
    }
    if (cellDOM.x === selectionShape.fromX) {
      positionsRecord[cell.__key] = positionsRecord[cell.__key] - PropertyPositionValues.left;
    }
  }

  for (const key in positionsRecord) {
    const cellStyle = patchBorderStyleObject(cellsRecord[key], style, positionsRecord[key]);
    cellsRecord[key].setStyle(cellStyle);
  }
};

const $updateCellsTopBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const topCells = selectionCells.filter((cell) => {
    const cellDOM = $getCell(editor, cell.__key);
    return cellDOM?.y === selectionShape.fromY;
  });

  for (const cell of topCells) {
    $updateSingleCellBorderStyle(cell, style, 'top');
  }
};

const $updateCellsRightBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const rightCells = selectionCells.filter((cell) => {
    const cellDOM = $getCell(editor, cell.__key);
    return cellDOM?.x === selectionShape.toX;
  });

  for (const cell of rightCells) {
    $updateSingleCellBorderStyle(cell, style, 'right');
  }
};

const $updateCellsBottomBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const bottomCells = selectionCells.filter((cell) => {
    const cellDOM = $getCell(editor, cell.__key);
    return cellDOM?.y === selectionShape.toY;
  });

  for (const cell of bottomCells) {
    $updateSingleCellBorderStyle(cell, style, 'bottom');
  }
};

const $updateCellsLeftBorder = (
  editor: LexicalEditor,
  selection: TableSelection,
  style: Partial<TableCellNodeStyleObject>,
) => {
  const selectionCells = extractSelectionCells(selection);
  const selectionShape = selection.getShape();

  const leftCells = selectionCells.filter((cell) => {
    const cellDOM = $getCell(editor, cell.__key);
    return cellDOM?.x === selectionShape.fromX;
  });

  for (const cell of leftCells) {
    $updateSingleCellBorderStyle(cell, style, 'left');
  }
};

export const $updateCellsBorderStyle = (
  editor: LexicalEditor,
  style: Partial<TableCellNodeStyleObject>,
  position?: BorderPositionType,
) => {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const [cell] = $getNodeTriplet(selection.anchor);
    $updateSingleCellBorderStyle(cell, style, position);
    return;
  }

  if (!$isTableSelection(selection)) return;

  if (position === 'all') {
    $updateCellsAllBorder(editor, selection, style);
  } else if (position === 'outer') {
    $updateCellsOuterBorder(editor, selection, style);
  } else if (position === 'inner') {
    $updateCellsInnerBorder(editor, selection, style);
  } else if (position === 'top') {
    $updateCellsTopBorder(editor, selection, style);
  } else if (position === 'right') {
    $updateCellsRightBorder(editor, selection, style);
  } else if (position === 'bottom') {
    $updateCellsBottomBorder(editor, selection, style);
  } else if (position === 'left') {
    $updateCellsLeftBorder(editor, selection, style);
  }
};

const getSelectionNeighbors = (cell: TableCellNode, cellDOM: TableDOMCell, selectionShape: TableSelectionShape) => {
  const neighbors: Record<NeighborPosition, TableCellNode | null> = {
    [NeighborPosition.top]: null,
    [NeighborPosition.right]: null,
    [NeighborPosition.bottom]: null,
    [NeighborPosition.left]: null,
  };

  if (cellDOM.y === selectionShape.fromY) neighbors[NeighborPosition.top] = cell.getTopNeighborCell();
  if (cellDOM.x === selectionShape.toX) neighbors[NeighborPosition.right] = cell.getRightNeighborCell();
  if (cellDOM.y === selectionShape.toY) neighbors[NeighborPosition.bottom] = cell.getBottomNeighborCell();
  if (cellDOM.x === selectionShape.fromX) neighbors[NeighborPosition.left] = cell.getLeftNeighborCell();

  return neighbors;
};

const getNeighborBorderPosition = (neighborPosition: NeighborPosition): number => {
  switch (neighborPosition) {
    case NeighborPosition.top:
      return PropertyPositionValues.bottom;
    case NeighborPosition.right:
      return PropertyPositionValues.left;
    case NeighborPosition.bottom:
      return PropertyPositionValues.top;
    case NeighborPosition.left:
      return PropertyPositionValues.right;
    default:
      return 0;
  }
};
