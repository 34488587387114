import { useQuery } from '@apollo/client';
import { LineChart, max } from '@vectice/data-viz';
import React, { useMemo } from 'react';

import { graphql } from '../../../gql';
import { WorkspaceActivityTrendOutput } from '../../../gql/graphql';
import { FlexContainer, Typography } from '../../../ui';
import { FormatDate, formatDate } from '../../formatters';
import { ColorSwatch } from '../colors';
import { DashboardCard } from '../internals';

import styles from './ActivityTrend.module.scss';

export const GET_ACTIVITY_TREND = graphql(`
  query getWorkspaceActivityTrend($workspaceIdList: [VecticeId!]!, $userTimezone: String!) {
    getWorkspaceActivityTrend(workspaceIdList: $workspaceIdList, userTimezone: $userTimezone) {
      date
      count
    }
  }
`);

interface ActivityTrendProps {
  workspaceIdList: string[];
}

type NumberValue = { valueOf(): number };

const date = (datum: WorkspaceActivityTrendOutput) => new Date(datum.date);
const count = (datum: WorkspaceActivityTrendOutput) => datum.count;
const formatCount = (d: Date | NumberValue) => (!(d instanceof Date) ? d.valueOf().toFixed(0) : '');
const formatMondays = (d: Date | NumberValue) =>
  d instanceof Date && d.getDay() === 1 ? formatDate(d, { format: 'MM/DD' }) : '';

const tooltip = ({ data, date }: { data: WorkspaceActivityTrendOutput[]; date: Date }) => (
  <FlexContainer direction="column">
    <Typography component="strong" weight="semi-bold">
      <FormatDate date={date} />
    </Typography>
    <Typography>
      {$t({
        id: 'dashboard.widgetActivity.count',
        defaultMessage: '<bold>{count}</bold> Activities',
        values: { count: data[0].count },
      })}
    </Typography>
  </FlexContainer>
);

const margins = { top: 10, bottom: 25, left: 32, right: 10 };
const colors = [ColorSwatch.cornflowerBlue];

export const ActivityTrend = ({ workspaceIdList }: ActivityTrendProps) => {
  const { data, loading } = useQuery(GET_ACTIVITY_TREND, {
    fetchPolicy: 'network-only',
    variables: {
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      workspaceIdList,
    },
  });

  const { activities, maxValue } = useMemo(
    () => ({
      activities: [data?.getWorkspaceActivityTrend ?? []],
      maxValue: data?.getWorkspaceActivityTrend ? max(data?.getWorkspaceActivityTrend, count) : 0,
    }),
    [data],
  );

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.activityTrend.title', defaultMessage: 'Overall Activity' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.relativeTime', defaultMessage: 'In the last 30 days' })}
      hint={$t({
        id: 'dashboard.activityTrend.hint',
        defaultMessage: 'The overall count of action-driven productivity in Vectice per day.',
      })}
      extraLeftSpace={maxValue > 9999}
      loading={loading}
    >
      <figure className={styles.figure}>
        <LineChart
          data={activities}
          getX={date}
          getY={count}
          colors={colors}
          margins={margins}
          leftAxis={{ numTicks: Math.min(maxValue, 5), tickFormat: formatCount }}
          bottomAxis={{ numTicks: activities[0].length, tickFormat: formatMondays }}
          gridRows={{ numTicks: Math.min(maxValue, 5) }}
          tooltip={tooltip}
        />
      </figure>
    </DashboardCard>
  );
};
