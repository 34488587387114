import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { Phase } from '../../gql/graphql';
import { useCheckPhaseNameAvailability, useValidateName, useVecticeForm } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import { Input, message, ModalForm, TextArea, WithAsyncValidation } from '../../ui';
import {
  VecticeResourceType,
  defaultDescriptionFieldConfiguration,
  defaultNameFieldConfiguration,
  getCopiedName,
} from '../../utils';

export const DUPLICATE_PHASE = graphql(`
  mutation duplicatePhase($id: VecticeId!, $duplicateInput: PhaseDuplicateInput!) {
    duplicatePhase(id: $id, duplicateInput: $duplicateInput) {
      vecticeId
    }
  }
`);

interface FormData {
  name: string;
  description: string;
}

interface DuplicatePhaseModalProps {
  phase: Pick<Phase, 'vecticeId' | 'name' | 'description'>;
  projectId: string;
  onClose: () => void;
}

export const DuplicatePhaseModal = ({ phase, projectId, onClose }: DuplicatePhaseModalProps) => {
  const navigate = useNavigate();

  const { checkPhaseNameAvailability } = useCheckPhaseNameAvailability(projectId, phase.vecticeId);
  const { validateName } = useValidateName(checkPhaseNameAvailability, VecticeResourceType.PHASE);

  const duplicateLabel = $t({ id: 'modal.duplicatePhase.duplicateResource', defaultMessage: 'Duplicate Phase' });

  const { formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: phase.name ? getCopiedName(phase.name) : '',
      description: phase.description ?? '',
    },
  });
  const { hasErrors, isSubmitting } = formState;

  const [duplicatePhase] = useMutation(DUPLICATE_PHASE, {
    refetchQueries: ['getPhaseList'],
    onCompleted: ({ duplicatePhase: { vecticeId } }) => {
      onClose();
      navigate(buildLink(VecticeRoutes.PHASE, { phaseId: vecticeId }));
    },
    onError: (error) => message.error(error.message),
  });

  const onSubmit: SubmitHandler<FormData> = async ({ name, description }) =>
    await duplicatePhase({
      variables: {
        id: phase.vecticeId,
        duplicateInput: {
          name,
          description,
        },
      },
    });

  useEffect(() => {
    trigger('name');
  }, [phase.name, validateName]);

  return (
    <ModalForm
      disabled={hasErrors}
      isSubmitting={isSubmitting}
      title={duplicateLabel}
      submitLabel={duplicateLabel}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(onSubmit)}
      onClose={onClose}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          autoFocus
          gutterBottom
          label={$t({ id: 'modal.duplicatePhase.nameLabel', defaultMessage: 'Phase Name' })}
          placeholder={$t({ id: 'modal.duplicatePhase.untitled', defaultMessage: 'Untitled' })}
          required
        />
      </WithAsyncValidation>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        gutterBottom
        label={$t({ id: 'modal.createResource.description', defaultMessage: 'Description' })}
        hint={$t({ id: 'modal.createResource.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
