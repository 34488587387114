import { ReviewStatus } from '../../gql/graphql';
import { IconColors } from '../../ui';

type reviewStatusType = ReviewStatus | string | null;

export const getReviewStatusLabel = (status?: reviewStatusType) => {
  switch (status) {
    case ReviewStatus.Approved:
      return $t({ id: 'reviewStatus.approved.label', defaultMessage: 'Approved' });
    case ReviewStatus.Cancelled:
      return $t({ id: 'reviewStatus.cancelled.label', defaultMessage: 'Cancelled' });
    case ReviewStatus.Pending:
      return $t({ id: 'reviewStatus.pending.label', defaultMessage: 'Pending Review' });
    case ReviewStatus.Rejected:
      return $t({ id: 'reviewStatus.rejected.label', defaultMessage: 'Rejected' });
    default:
      return $t({ id: 'reviewStatus.notReviewed.label', defaultMessage: 'Not Reviewed' });
  }
};

export const getReviewStatusColor = (status?: reviewStatusType): IconColors => {
  switch (status) {
    case ReviewStatus.Approved:
      return 'success';
    case ReviewStatus.Pending:
      return 'on-track';
    case ReviewStatus.Rejected:
      return 'danger';
    case ReviewStatus.Cancelled:
      return 'abandoned';
    default:
      return 'default';
  }
};
