import React, { useState } from 'react';

import { useAnalytics } from '../../../hooks';
import { ProductTourVideo } from '../../product-tour';

import { AccessDocumentationCard, WalkThroughCard } from './Cards';

import styles from './GetStartedCards.module.scss';

export const TrialHomeCards = () => {
  const [showProductTourVideo, setShowProductTourVideo] = useState(false);

  const { logClick } = useAnalytics();

  return (
    <>
      <div className={styles.grid}>
        <WalkThroughCard
          duration={$t({ id: 'home.trial.introduction.duration', defaultMessage: '4 min' })}
          onClick={() => {
            logClick('Home', 'walkthrough-card', 'click');
            setShowProductTourVideo(true);
          }}
        />
        <AccessDocumentationCard className={styles.link} url="start_your_free_trial" />
      </div>
      {showProductTourVideo && <ProductTourVideo onClose={() => setShowProductTourVideo(false)} />}
    </>
  );
};
