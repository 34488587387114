import React from 'react';

import config from '../../config';
import { Lightbox } from '../../ui';
import { VideoPlayer } from '../player';

interface AutologVideoProps {
  onClose: () => void;
}

export const AutologVideo = ({ onClose }: AutologVideoProps) => (
  <Lightbox
    content={<VideoPlayer url={config.AUTOLOG_VIDEO_URL} />}
    title={$t({ id: 'autologVideo.title', defaultMessage: 'Autolog overview' })}
    onClose={onClose}
  />
);
