import React from 'react';

import { FilterLabel, Radio, RadioGroup } from '../../../ui';
import { CommonFilterProps } from '../types';

import styles from './Filters.module.scss';

export const RadioGroupFilterComponent = ({ filter, options, label, onFilterUpdate }: CommonFilterProps) => (
  <RadioGroup name={label || 'radio-group-filter'} value={filter} onChange={onFilterUpdate}>
    {options?.map(({ icon, label, value }) => (
      <Radio key={label} value={value} className={styles.option} gutterBottom>
        <FilterLabel addonBefore={icon}>{label}</FilterLabel>
      </Radio>
    ))}
  </RadioGroup>
);
