import cn from 'classnames';
import React from 'react';

import { formatCssSizes } from '../../../utils';

import { CommonSkeletonProps } from './interfaces';
import { SkeletonShape } from './Shape';

import styles from './Skeleton.module.scss';

interface SkeletonTitleProps
  extends Omit<VecticeHTMLProps<HTMLDivElement>, 'children' | 'shape' | 'width'>,
    CommonSkeletonProps {
  width?: number | string;
  variant?: 'heading1' | 'heading2' | 'heading3';
  gutterBottom?: boolean;
}

export const SkeletonTitle = ({
  variant = 'heading1',
  width = '60%',
  gutterBottom,
  dark,
  darker,
  className,
  ...props
}: SkeletonTitleProps) => (
  <SkeletonShape
    width={formatCssSizes(width)}
    className={cn(
      styles.title,
      styles[variant],
      {
        [styles.gutterBottom]: gutterBottom,
        [styles.dark]: dark,
        [styles.darker]: darker,
      },
      className,
    )}
    {...props}
  />
);
