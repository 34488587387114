import React from 'react';

import { UserIdentity } from '../../../graphql/fragments';
import { UserAvatar } from '../../asset-display';

import styles from './ActivityCreatorAvatar.module.scss';

export const ActivityCreatorAvatar = ({ user }: { user?: UserIdentity | null }) => (
  <UserAvatar user={user} size="sm" className={styles.avatar} />
);
