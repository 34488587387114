import cn from 'classnames';
import React, { PropsWithChildren, useRef } from 'react';

import { FlexContainer, Typography, WithLoading } from '../../../ui';
import { HintTooltip } from '../../tooltip';

import styles from './DashboardCard.module.scss';

interface DashboardCardProps {
  title: string;
  subtitle?: string;
  hint?: string;
  legend?: React.ReactNode;
  extraLeftSpace?: boolean;
  loading?: boolean;
}

let globalId = 0;

export const DashboardCard = ({
  title,
  subtitle,
  hint,
  legend,
  extraLeftSpace,
  loading,
  children,
}: PropsWithChildren<DashboardCardProps>) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`dashboard-card-${globalId++}`);

  return (
    <FlexContainer direction="column" className={styles.card} gap={0} aria-labelledby={id}>
      <div className={styles.title}>
        <FlexContainer align="center" gap={4}>
          <Typography id={id} variant="body" color="primary" weight="bold">
            {title}
          </Typography>
          <HintTooltip hint={hint} />
        </FlexContainer>
        {subtitle && (
          <Typography component="div" variant="footnote" color="tertiary">
            {subtitle}
          </Typography>
        )}
      </div>
      <FlexContainer direction="column" className={cn(styles.content, { [styles.extraLeftSpace]: extraLeftSpace })}>
        <WithLoading loading={loading}>{children}</WithLoading>
        {legend && (
          <FlexContainer gap={32} align="center" justify="center" className={styles.legend}>
            {legend}
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
