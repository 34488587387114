import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { PhaseRow } from './PhaseRow';

const PhaseListItemFragment = graphql(`
  fragment phaseListItem on Phase {
    vecticeId
    ...phaseRow
  }
`);

let globalId = 0;

interface PhaseListItemProps {
  phase: FragmentType<typeof PhaseListItemFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const PhaseListItem = ({ project, workspace, ...props }: PhaseListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`phase-list-item-${globalId++}`);
  const phase = useFragment(PhaseListItemFragment, props.phase);

  return (
    <AssetListItem to={buildLink(VecticeRoutes.PHASE, { phaseId: phase.vecticeId })} aria-labelledby={ariaId}>
      <PhaseRow id={ariaId} phase={phase} project={project} workspace={workspace} />
    </AssetListItem>
  );
};
