import { graphql } from '../../gql';

export const GET_STEP_DEFINITION_LIST = graphql(`
  query getStepDefinitionList($filters: BaseDocumentationListFiltersInput!, $order: ListOrderInput, $page: PageInput) {
    getStepDefinitionList(filters: $filters, order: $order, page: $page) {
      items {
        id
        index
        name
        slug
        description
        parent {
          vecticeId
        }
      }
      total
    }
  }
`);
