import React from 'react';

import { EmptyData } from '../../ui';

export const NoMetrics = () => (
  <EmptyData
    message={$t({ id: 'NoResults.metrics.message', defaultMessage: 'No metrics yet!' })}
    hint={$t({
      id: 'NoResults.metrics.hint',
      defaultMessage: "To use this filter, add metrics to your assets' version pages.",
    })}
  />
);
