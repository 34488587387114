import cn from 'classnames';
import React, { ElementType, PropsWithChildren, ReactNode } from 'react';

import type { TypographyWeights } from '../typography';

import { ReactComponent as FullscreenIcon } from '../../assets/icons/interface/ic-fullscreen.svg';
import { ReactComponent as NewTabIcon } from '../../assets/icons/interface/ic-link.svg';
import { ReactComponent as SparkleIcon } from '../../assets/icons/interface/ic-sparkle.svg';
import { ReactComponent as StarredIcon } from '../../assets/icons/interface/ic-star.svg';
import { useOrgConfig } from '../../context/Config';
import { getArray } from '../../utils';
import { Breadcrumb, Breadcrumbs } from '../breadcrumbs';
import { Button } from '../button';
import { FlexContainer } from '../containers';
import { RestrictedAccessTooltip } from '../restricted-access';
import { Icon } from '../svg';
import { Typography } from '../typography';

import { AssetInfos } from './AssetInfos';

import styles from './AssetRow.module.scss';

export interface AssetRowProps {
  avatar?: ReactNode;
  breadcrumbs?: ReactNode;
  className?: string;
  icon?: ElementType;
  id?: string;
  interaction?: 'link' | 'tab' | 'file' | false;
  isStarred?: boolean;
  label: ReactNode;
  labelWeight?: TypographyWeights;
  parents?: ReactNode | ReactNode[];
  versionBadge?: ReactNode;
  onAutoDoc?: () => void;
}

export const AssetRow = ({
  avatar,
  breadcrumbs,
  children,
  className,
  icon,
  id,
  interaction = 'link',
  isStarred,
  label,
  labelWeight = 'semi-bold',
  parents,
  versionBadge,
  onAutoDoc,
}: PropsWithChildren<AssetRowProps>) => {
  const {
    licenseOptions: { isAskAIEnabled },
  } = useOrgConfig();

  const labelComponent = (
    <FlexContainer id={id} className={styles.label}>
      <Typography
        className={cn({ 'asset-row-link': interaction === 'link' }, className)}
        color="primary"
        ellipsis
        variant="callout"
        weight={labelWeight}
      >
        {label}
      </Typography>
      {versionBadge}
    </FlexContainer>
  );

  const parentElements = parents ? getArray(parents) : null;

  return (
    <FlexContainer direction="column" gap={2} className={styles.row}>
      <FlexContainer gap={4}>
        {avatar}
        {icon && <Icon icon={icon} size={20} />}
        {!!parentElements?.length ? (
          <Breadcrumbs className={styles.breadcrumbs}>
            {parentElements.map((parent, index) => (
              <Breadcrumb key={`breadcrumb-${index}`} tooltip={parent} inert>
                {parent}
              </Breadcrumb>
            ))}
            <Breadcrumb className={styles.labelComponent} inert>
              {labelComponent}
            </Breadcrumb>
          </Breadcrumbs>
        ) : (
          <>{labelComponent}</>
        )}
        {isStarred && <Icon icon={StarredIcon} size={16} className={styles.starred} />}
        {interaction === 'file' && (
          <div className="asset-row-file">
            <Icon icon={FullscreenIcon} size={16} />
          </div>
        )}
        {interaction === 'tab' && (
          <div className="asset-row-tab">
            <Icon icon={NewTabIcon} size={16} />
          </div>
        )}
        {onAutoDoc && (
          <RestrictedAccessTooltip
            className={styles.generateDocTooltip}
            restricted={!isAskAIEnabled}
            message={$t({
              id: 'autodoc.tooltip',
              defaultMessage: 'AskAI disabled. Please reach out to Support for assistance.',
            })}
          >
            <Button
              leftIcon={SparkleIcon}
              variant="white"
              size="xxs"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onAutoDoc();
              }}
              className={styles.generateDoc}
              disabled={!isAskAIEnabled}
            >
              {$t({
                id: 'autodoc.title',
                defaultMessage: 'Generate Doc',
              })}
            </Button>
          </RestrictedAccessTooltip>
        )}
      </FlexContainer>
      {breadcrumbs}
      {!!children && <AssetInfos>{children}</AssetInfos>}
    </FlexContainer>
  );
};
