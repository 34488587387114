import React from 'react';

import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/interface/ic-link.svg';
import { getModelLibraryDisplayName } from '../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../gql';
import { FlexContainer, Grid, GridCell, Icon, Section, Tooltip, Typography } from '../../ui';
import { getModelLibraryIcon } from '../../utils';
import { WithCopyID } from '../assets';

import styles from './ModelVersionInformation.module.scss';

const ModelVersionInformationFragment = graphql(`
  fragment modelVersionInformation on ModelVersion {
    vecticeId
    context {
      library
      url
      run
      extraInfo {
        key
        value
        displayName
      }
    }
  }
`);

interface ModelVersionInformationProps {
  modelVersion?: FragmentType<typeof ModelVersionInformationFragment>;
}

export const ModelVersionInformation = (props: ModelVersionInformationProps) => {
  const modelVersion = useFragment(ModelVersionInformationFragment, props.modelVersion);

  if (!modelVersion?.context) {
    return null;
  }

  const modelLibraryIcon = getModelLibraryIcon(modelVersion.context.library);

  return (
    <Section label={$t({ id: 'modelVersion.information.sectionTitle', defaultMessage: 'Additional Information' })}>
      <div className={styles.container}>
        <FlexContainer className={styles.header_wrapper} gap={16}>
          {modelLibraryIcon && <Icon icon={modelLibraryIcon} size={32} />}
          <div className={styles.header}>
            <Typography variant="callout" color="primary" weight="semi-bold" ellipsis>
              {getModelLibraryDisplayName(modelVersion.context.library)}
            </Typography>
            {modelVersion.context.url && (
              <FlexContainer gap={4}>
                <Typography
                  href={modelVersion.context.url}
                  target="_blank"
                  rel="noreferrer"
                  variant="callout"
                  color="accent"
                  link
                  ellipsis
                >
                  {modelVersion.context.url}
                </Typography>
                <Icon icon={ExternalLinkIcon} color="dark-gray" size={16} />
              </FlexContainer>
            )}
            {modelVersion.context.run && (
              <FlexContainer gap={4}>
                <Typography variant="callout" color="secondary" className={styles.label}>
                  {$t({ id: 'modelVersion.information.runId', defaultMessage: 'Run ID' })}:
                </Typography>
                <Tooltip placement="left">
                  <Typography variant="callout" color="secondary" weight="semi-bold" ellipsis>
                    <WithCopyID
                      iconLabel={$t({ id: 'modelVersion.information.runId.copyLabel', defaultMessage: 'Copy Run ID' })}
                      idValue={modelVersion.context.run}
                    />
                  </Typography>
                </Tooltip>
              </FlexContainer>
            )}
          </div>
        </FlexContainer>
        {modelVersion.context.extraInfo && (
          <Grid columns={2} gap="4px 32px" className={styles.content}>
            {modelVersion.context.extraInfo.map(({ key, value, displayName }) => (
              <GridCell key={key}>
                <FlexContainer gap={4}>
                  <Typography variant="callout" color="secondary" className={styles.label}>
                    {displayName ?? key}:
                  </Typography>
                  <Tooltip placement="left">
                    <Typography variant="callout" color="secondary" weight="semi-bold" ellipsis>
                      {value}
                    </Typography>
                  </Tooltip>
                </FlexContainer>
              </GridCell>
            ))}
          </Grid>
        )}
      </div>
    </Section>
  );
};
