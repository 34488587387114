import { $createLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode, $isListItemNode, $isListNode, ListItemNode } from '@lexical/list';
import { $createHeadingNode, HeadingNode } from '@lexical/rich-text';
import { $createParagraphNode, $isTextNode, LexicalNode, TextNode, isHTMLAnchorElement, isHTMLElement } from 'lexical';

import type {
  DOMConversion,
  DOMConversionMap,
  DOMConversionOutput,
  ElementFormatType,
  NodeKey,
  SerializedTextNode,
} from 'lexical';

import { extractNodeStyle, isMicrosoftWordHeading, isMicrosoftWordListItem, isMicrosoftWordNestedList } from './utils';

export class ExtendedTextNode extends TextNode {
  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  static getType(): string {
    return 'extended-text';
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    const importers = TextNode.importDOM();
    const headingImporters = HeadingNode.importDOM();
    return {
      ...importers,
      a: () => ({
        conversion: pathLinkNodeConversion(),
        priority: 4,
      }),
      code: () => ({
        conversion: patchTextNodeConversion(importers?.code),
        priority: 1,
      }),
      em: () => ({
        conversion: patchTextNodeConversion(importers?.em),
        priority: 1,
      }),
      h1: () => ({
        conversion: patchHeadingNodeConversion(headingImporters?.h1),
        priority: 1,
      }),
      h2: () => ({
        conversion: patchHeadingNodeConversion(headingImporters?.h2),
        priority: 1,
      }),
      h3: () => ({
        conversion: patchHeadingNodeConversion(headingImporters?.h3),
        priority: 1,
      }),
      h4: () => ({
        conversion: patchHeadingNodeConversion(headingImporters?.h4),
        priority: 1,
      }),
      h5: () => ({
        conversion: patchHeadingNodeConversion(headingImporters?.h5),
        priority: 1,
      }),
      h6: () => ({
        conversion: patchHeadingNodeConversion(headingImporters?.h6),
        priority: 1,
      }),
      ol: () => ({
        conversion: patchListNodeConversion(importers?.ol),
        priority: 4,
      }),
      p: () => ({
        conversion: patchParagraphNodeConversion(importers?.p),
        priority: 1,
      }),
      span: () => ({
        conversion: patchTextNodeConversion(importers?.span),
        priority: 4,
      }),
      strong: () => ({
        conversion: patchTextNodeConversion(importers?.strong),
        priority: 1,
      }),
      i: () => ({
        conversion: patchTextNodeConversion(importers?.i),
        priority: 1,
      }),
      u: () => ({
        conversion: patchTextNodeConversion(importers?.u),
        priority: 1,
      }),
      sub: () => ({
        conversion: patchTextNodeConversion(importers?.sub),
        priority: 1,
      }),
      sup: () => ({
        conversion: patchTextNodeConversion(importers?.sup),
        priority: 1,
      }),
      ul: () => ({
        conversion: patchListNodeConversion(importers?.ul),
        priority: 4,
      }),
    };
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return TextNode.importJSON(serializedNode);
  }

  isSimpleText() {
    return (this.__type === 'text' || this.__type === 'extended-text') && this.__mode === 0;
  }

  exportJSON(): SerializedTextNode {
    return {
      ...super.exportJSON(),
      type: 'extended-text',
      version: 1,
    };
  }
}

export function $createExtendedTextNode(text: string): ExtendedTextNode {
  return new ExtendedTextNode(text);
}

export function $isExtendedTextNode(node: LexicalNode | null | undefined): node is ExtendedTextNode {
  return node instanceof ExtendedTextNode;
}

export function defaultParagraphConversion(element: HTMLElement): DOMConversionOutput {
  const node = $createParagraphNode();
  if (element.style) {
    node.setFormat(element.style.textAlign as ElementFormatType);
    const indent = parseInt(element.style.textIndent, 10) / 20;
    if (indent > 0) {
      node.setIndent(indent);
    }
  }
  return { node };
}

export function patchHeadingNodeConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }
    const originalOutput = original.conversion(node);
    if (!originalOutput) {
      return originalOutput;
    }

    // Only h1, h2, h3 are supported
    if (/^h[456]$/i.exec(node.tagName)) {
      return {
        ...originalOutput,
        node: $createParagraphNode(),
      };
    }

    return originalOutput;
  };
}

export function patchParagraphNodeConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    const original = originalDOMConverter?.(node);
    const originalOutput = original?.conversion(node) || defaultParagraphConversion(node);

    if (isMicrosoftWordListItem(node)) {
      return {
        ...originalOutput,
        node: $createListItemNode(),
        forChild: (child) => {
          const content = child.getTextContent()?.trim();
          // Remove MS Word list item markers
          if (!content || /^(·|o|§|\d+\.|[a-zA-Z]\.)$/.exec(content)) {
            return null;
          }
          return child;
        },
      };
    }

    const headingTag = isMicrosoftWordHeading(node);

    // Only h1, h2, h3 are supported
    if (headingTag && /^h[123]$/i.exec(headingTag)) {
      return {
        ...originalOutput,
        node: $createHeadingNode(headingTag),
      };
    }

    return originalOutput;
  };
}

export function pathLinkNodeConversion(): (node: HTMLElement) => DOMConversionOutput | null {
  return (domNode) => {
    let node = null;
    if (isHTMLAnchorElement(domNode)) {
      const content = domNode.textContent;
      if (
        ((content !== null && content !== '') || domNode.children.length > 0) &&
        domNode.getAttribute('href') !== null
      ) {
        node = $createLinkNode(domNode.getAttribute('href') || '', {
          rel: domNode.getAttribute('rel'),
          target: domNode.getAttribute('target'),
          title: domNode.getAttribute('title'),
        });
      }
    }
    return { node };
  };
}

export function patchTextNodeConversion(
  originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    // Remove empty MS Word EOP node
    if (node.classList.contains('EOP') && node.textContent?.trim() === '') {
      return {
        node: null,
        after: () => [],
      };
    }

    const original = originalDOMConverter?.(node);
    if (!original) {
      return null;
    }
    const originalOutput = original.conversion(node);
    if (!originalOutput) {
      return originalOutput;
    }

    const { isBold, isItalic, isUnderline, inlineStyle } = extractNodeStyle(node);

    return {
      ...originalOutput,
      after: (nodes) => {
        return nodes.map((node) => {
          if ($isTextNode(node) || $isExtendedTextNode(node)) {
            if (isBold && !node.hasFormat('bold')) node.toggleFormat('bold');
            if (isItalic && !node.hasFormat('italic')) node.toggleFormat('italic');
            if (isUnderline && !node.hasFormat('underline')) node.toggleFormat('underline');
            if (inlineStyle.length > 0) node.setStyle(inlineStyle);
          }
          return node;
        });
      },
    };
  };
}

/*
 * This function normalizes the children of a ListNode after the conversion from HTML,
 * ensuring that they are all ListItemNodes and contain either a single nested ListNode
 * or some other inline content.
 */
function $normalizeChildren(nodes: Array<LexicalNode>): Array<ListItemNode> {
  const normalizedListItems: Array<ListItemNode> = [];
  for (const node of nodes) {
    if ($isListItemNode(node)) {
      normalizedListItems.push(node);
      const children = node.getChildren();
      if (children.length > 1) {
        children.forEach((child) => {
          if ($isListNode(child)) {
            normalizedListItems.push($wrapInListItem(child));
          }
        });
      }
    } else {
      normalizedListItems.push($wrapInListItem(node));
    }
  }
  return normalizedListItems;
}

export function defaultListConversion(element: HTMLElement) {
  const nodeName = element.nodeName.toLowerCase();
  let node = null;
  if (nodeName === 'ol') {
    const start = (element as HTMLOListElement).start;
    node = $createListNode('number', start);
  } else if (nodeName === 'ul') {
    if (isHTMLElement(element) && element.getAttribute('__lexicallisttype') === 'check') {
      node = $createListNode('check');
    } else {
      node = $createListNode('bullet');
    }
  }

  return {
    after: $normalizeChildren,
    node,
  };
}

/**
 * Wraps a node into a ListItemNode.
 * @param node - The node to be wrapped into a ListItemNode
 * @returns The ListItemNode which the passed node is wrapped in.
 */
export function $wrapInListItem(node: LexicalNode): ListItemNode {
  const listItemWrapper = $createListItemNode();
  return listItemWrapper.append(node);
}

export function patchListNodeConversion(
  _originalDOMConverter?: (node: HTMLElement) => DOMConversion | null,
): (node: HTMLElement) => DOMConversionOutput | null {
  return (node) => {
    if (!['ol', 'ul'].includes(node.tagName.toLowerCase())) return null;

    const originalOutput = defaultListConversion(node);

    const isNestedList = isMicrosoftWordNestedList(node);

    if (isNestedList) {
      return {
        ...originalOutput,
        after: (nodes) => {
          const listNode = $createListNode(originalOutput.node?.__listType || 'bullet', originalOutput.node?.__start);
          if (!$isListNode(listNode)) {
            throw new Error('Expected ListNode');
          }
          listNode.append(...nodes);
          return $normalizeChildren([listNode]);
        },
      };
    }

    return originalOutput;
  };
}
