import { useCallback, useState } from 'react';

import { OrderDirection } from '../../../gql/graphql';
import { ColumnType, NestedKeyInput } from '../types';

import { getAriaSort, getNewDirection } from './utils';

export interface SortingState {
  key: string;
  nestedKey?: string;
  direction?: OrderDirection;
}

export interface UseSortingProps {
  initialSort?: SortingState;
  onSort?: (key: string, direction: OrderDirection, nestedKey?: NestedKeyInput) => void;
}

export const useSorting = <RecordType>({ initialSort, onSort }: UseSortingProps) => {
  const [sortingState, setSortingState] = useState<SortingState | undefined>(initialSort);

  const injectSorting = useCallback(
    (column: ColumnType<RecordType>): ColumnType<RecordType> => {
      if (column.sortable) {
        const sortableKey = column.sortable === true ? column.key : column.sortable;
        const { nestedKey } = column;

        return {
          ...column,
          onHeaderCell: (col) => {
            const cell = column.onHeaderCell?.(col) || {};
            let currentSortDirection: OrderDirection | undefined;
            if (sortingState && sortingState.key === sortableKey) {
              if (sortingState.nestedKey) {
                if (nestedKey && nestedKey.value === sortingState.nestedKey) {
                  currentSortDirection = sortingState.direction;
                }
              } else if (!nestedKey) {
                currentSortDirection = sortingState.direction;
              }
            }

            return {
              ...cell,
              'aria-sort': getAriaSort(currentSortDirection),
              onClick: (e) => {
                const newDirection = getNewDirection(currentSortDirection);
                onSort?.(sortableKey, newDirection as OrderDirection, nestedKey);
                setSortingState({
                  key: sortableKey,
                  nestedKey: nestedKey?.value,
                  direction: newDirection as OrderDirection,
                });
                cell.onClick?.(e);
              },
            };
          },
        };
      }

      return column;
    },
    [onSort, sortingState],
  );

  return { injectSorting };
};
