import { ReactComponent as AmazonRedshiftIcon } from '../assets/icons/external/ic-amazon-redshift.svg';
import { ReactComponent as AmazonS3Icon } from '../assets/icons/external/ic-amazon-s3.svg';
import { ReactComponent as DatabricksIcon } from '../assets/icons/external/ic-databricks.svg';
import { ReactComponent as GoogleBigQueryIcon } from '../assets/icons/external/ic-google-bigquery.svg';
import { ReactComponent as GoogleStorageIcon } from '../assets/icons/external/ic-google-storage.svg';
import { ReactComponent as MLFlowIcon } from '../assets/icons/external/ic-mlflow.svg';
import { ReactComponent as PandasIcon } from '../assets/icons/external/ic-pandas.svg';
import { ReactComponent as SnowflakeIcon } from '../assets/icons/external/ic-snowflake.svg';
import { ReactComponent as SparkIcon } from '../assets/icons/external/ic-spark.svg';
import { ReactComponent as FileCodeIcon } from '../assets/icons/files/ic-code.svg';
import { ReactComponent as FileCsvIcon } from '../assets/icons/files/ic-csv.svg';
import { ReactComponent as FileDatabaseIcon } from '../assets/icons/files/ic-database.svg';
import { ReactComponent as FilegColabIcon } from '../assets/icons/files/ic-gColab.svg';
import { ReactComponent as FileGDocIcon } from '../assets/icons/files/ic-gDoc.svg';
import { ReactComponent as FileGSheetIcon } from '../assets/icons/files/ic-gSheet.svg';
import { ReactComponent as FileGSlideIcon } from '../assets/icons/files/ic-gSlides.svg';
import { ReactComponent as FileImageIcon } from '../assets/icons/files/ic-image.svg';
import { ReactComponent as FileIpynbIcon } from '../assets/icons/files/ic-ipynb.svg';
import { ReactComponent as FileJsonIcon } from '../assets/icons/files/ic-json.svg';
import { ReactComponent as FileJupyterIcon } from '../assets/icons/files/ic-jupyter.svg';
import { ReactComponent as FileAudioIcon } from '../assets/icons/files/ic-mp3.svg';
import { ReactComponent as FileParquetIcon } from '../assets/icons/files/ic-parquet.svg';
import { ReactComponent as FilePdfIcon } from '../assets/icons/files/ic-pdf.svg';
import { ReactComponent as FileRIcon } from '../assets/icons/files/ic-r.svg';
import { ReactComponent as FileSqlIcon } from '../assets/icons/files/ic-sql.svg';
import { ReactComponent as FileTextIcon } from '../assets/icons/files/ic-txt.svg';
import { ReactComponent as FileUnknownIcon } from '../assets/icons/files/ic-unkown.svg';
import { ReactComponent as FileVideoIcon } from '../assets/icons/files/ic-video.svg';
import { ReactComponent as FileXlsIcon } from '../assets/icons/files/ic-xls.svg';
import { ReactComponent as FileXmlIcon } from '../assets/icons/files/ic-xml.svg';
import { ReactComponent as ApprovedIcon } from '../assets/icons/interface/ic-approved.svg';
import { ReactComponent as CancelledIcon } from '../assets/icons/interface/ic-cancel.svg';
import { ReactComponent as ValidationIcon } from '../assets/icons/interface/ic-check-circle.svg';
import { ReactComponent as FolderIcon } from '../assets/icons/interface/ic-folder-file.svg';
import { ReactComponent as InProgressIcon } from '../assets/icons/interface/ic-in-progress.svg';
import { ReactComponent as NotStartedIcon } from '../assets/icons/interface/ic-not-started.svg';
import { ReactComponent as CompletedIcon } from '../assets/icons/interface/ic-radio-checked.svg';
import { ReactComponent as RejectedIcon } from '../assets/icons/interface/ic-rejected.svg';
import { ReactComponent as PendingIcon } from '../assets/icons/interface/ic-request.svg';
import { ReactComponent as CleanIcon } from '../assets/icons/specials/flat/ic-broom.svg';
import { ReactComponent as ModelingIcon } from '../assets/icons/specials/flat/ic-model.svg';
import { ReactComponent as OriginIcon } from '../assets/icons/specials/flat/ic-origin.svg';
import { ReactComponent as DataframeIcon } from '../assets/icons/specials/ic-dataset-source-origin-dataframe.svg';
import { ReactComponent as LocalIcon } from '../assets/icons/specials/ic-dataset-source-origin-local.svg';
import { ReactComponent as OtherDatasetSourceIcon } from '../assets/icons/specials/ic-dataset-source-origin-other.svg';
import { ReactComponent as TestingIcon } from '../assets/icons/specials/ic-dataset-type-testing.svg';
import { ReactComponent as TrainingIcon } from '../assets/icons/specials/ic-dataset-type-training.svg';
import { ReactComponent as AnomalyIcon } from '../assets/icons/specials/model-types/ic-anomaly.svg';
import { ReactComponent as ClassificationIcon } from '../assets/icons/specials/model-types/ic-classification.svg';
import { ReactComponent as ClusterIcon } from '../assets/icons/specials/model-types/ic-cluster.svg';
import { ReactComponent as OtherModelTypeIcon } from '../assets/icons/specials/model-types/ic-other.svg';
import { ReactComponent as RecommendationIcon } from '../assets/icons/specials/model-types/ic-recommendation.svg';
import { ReactComponent as RegressionIcon } from '../assets/icons/specials/model-types/ic-regression.svg';
import { ReactComponent as TimeSeriesIcon } from '../assets/icons/specials/model-types/ic-time-series.svg';
import {
  DatasetSourceOrigin,
  DatasetType,
  DbTableType,
  DocumentationPageStatus,
  FindingStatus,
  IterationStatus,
  ModelType,
  ModelVersionContextLibrary,
  ProjectStatus,
  ReviewStatus,
} from '../gql/graphql';

import {
  isAudioFile,
  isCodeFile,
  isCsvFile,
  isDatabaseFile,
  isGoogleColabFile,
  isGoogleDocFile,
  isGoogleFolder,
  isGoogleSheetFile,
  isGoogleSlideFile,
  isImageFile,
  isJsonFile,
  isJupyterFile,
  isNotebookFile,
  isParquetFile,
  isPdfFile,
  isRFile,
  isSqlFile,
  isTextFile,
  isTsvFile,
  isVideoFile,
  isXlsFile,
  isXmlFile,
} from './mime-types';

export const getDatasetSourceOriginIcon = (source?: DatasetSourceOrigin | string | null) => {
  switch (source) {
    case DatasetSourceOrigin.Bigquery:
      return GoogleBigQueryIcon;
    case DatasetSourceOrigin.DatabricksTable:
      return DatabricksIcon;
    case DatasetSourceOrigin.Dataframe:
      return DataframeIcon;
    case DatasetSourceOrigin.Gcs:
      return GoogleStorageIcon;
    case DatasetSourceOrigin.Redshift:
      return AmazonRedshiftIcon;
    case DatasetSourceOrigin.S3:
      return AmazonS3Icon;
    case DatasetSourceOrigin.Snowflake:
      return SnowflakeIcon;
    case DatasetSourceOrigin.Local:
      return LocalIcon;
    case DatasetSourceOrigin.SparkTable:
      return SparkIcon;
    case DatasetSourceOrigin.Other:
    default:
      return OtherDatasetSourceIcon;
  }
};

export const getDatasetTypeIcon = (type?: DatasetType | string | null) => {
  switch (type) {
    case DatasetType.Clean:
      return CleanIcon;
    case DatasetType.Origin:
      return OriginIcon;
    case DatasetType.Modeling:
      return ModelingIcon;
    case DatasetType.Validation:
      return ValidationIcon;
    case DatasetType.Training:
      return TrainingIcon;
    case DatasetType.Testing:
      return TestingIcon;
    case DatasetType.Unknown:
      return FileUnknownIcon;
    default:
      return undefined;
  }
};

export const getModelLibraryIcon = (type?: ModelVersionContextLibrary | string | null) => {
  switch (type) {
    case ModelVersionContextLibrary.Mlflow:
      return MLFlowIcon;
    case ModelVersionContextLibrary.Other:
    default:
      return undefined;
  }
};

export const getDocumentationPageStatusIcon = (status?: DocumentationPageStatus | string | null) => {
  switch (status) {
    case DocumentationPageStatus.Draft:
    case DocumentationPageStatus.InReview:
      return InProgressIcon;
    case DocumentationPageStatus.Completed:
      return CompletedIcon;
    default:
      return NotStartedIcon;
  }
};

export const getFindingStatusIcon = (status?: FindingStatus | string | null) => {
  switch (status) {
    case FindingStatus.Cancelled:
      return CancelledIcon;
    case FindingStatus.Completed:
      return CompletedIcon;
    case FindingStatus.InProgress:
    case FindingStatus.RemediationPending:
      return InProgressIcon;
    default:
      return NotStartedIcon;
  }
};

export const getDbTableTypeIcon = (dbTableType?: DbTableType | string | null) => {
  switch (dbTableType) {
    case DbTableType.Pandas:
      return PandasIcon;
    case DbTableType.Spark:
      return SparkIcon;
    case DbTableType.Unknown:
    default:
      return FileDatabaseIcon;
  }
};

export const getMimeTypeIcon = (mimeType?: string | null) => {
  if (isImageFile(mimeType)) {
    return FileImageIcon;
  }

  if (isAudioFile(mimeType)) {
    return FileAudioIcon;
  }

  if (isVideoFile(mimeType)) {
    return FileVideoIcon;
  }

  if (isJsonFile(mimeType)) {
    return FileJsonIcon;
  }

  if (isPdfFile(mimeType)) {
    return FilePdfIcon;
  }

  if (isGoogleColabFile(mimeType)) {
    return FilegColabIcon;
  }

  if (isGoogleSheetFile(mimeType)) {
    return FileGSheetIcon;
  }

  if (isXlsFile(mimeType)) {
    return FileXlsIcon;
  }

  if (isDatabaseFile(mimeType)) {
    return FileDatabaseIcon;
  }

  if (isGoogleSlideFile(mimeType)) {
    return FileGSlideIcon;
  }

  if (isGoogleDocFile(mimeType)) {
    return FileGDocIcon;
  }

  if (isGoogleFolder(mimeType)) {
    return FolderIcon;
  }

  if (isNotebookFile(mimeType)) {
    return FileIpynbIcon;
  }

  if (isJupyterFile(mimeType)) {
    return FileJupyterIcon;
  }

  if (isParquetFile(mimeType)) {
    return FileParquetIcon;
  }

  if (isXmlFile(mimeType)) {
    return FileXmlIcon;
  }

  if (isSqlFile(mimeType)) {
    return FileSqlIcon;
  }

  if (isCsvFile(mimeType)) {
    return FileCsvIcon;
  }

  if (isTsvFile(mimeType)) {
    return FileCsvIcon;
  }

  if (isCodeFile(mimeType)) {
    return FileCodeIcon;
  }

  if (isTextFile(mimeType)) {
    return FileTextIcon;
  }

  if (isRFile(mimeType)) {
    return FileRIcon;
  }

  return FileUnknownIcon;
};

export const getIterationStatusIcon = (status?: IterationStatus | string | null) => {
  switch (status) {
    case IterationStatus.InProgress:
      return InProgressIcon;
    case IterationStatus.Abandoned:
      return CancelledIcon;
    case IterationStatus.Completed:
      return CompletedIcon;
    case IterationStatus.NotStarted:
    default:
      return NotStartedIcon;
  }
};

export const getModelTypeIcon = (type?: ModelType | string | null) => {
  switch (type) {
    case ModelType.AnomalyDetection:
      return AnomalyIcon;
    case ModelType.Classification:
      return ClassificationIcon;
    case ModelType.Clustering:
      return ClusterIcon;
    case ModelType.Other:
      return OtherModelTypeIcon;
    case ModelType.RecommendationModels:
      return RecommendationIcon;
    case ModelType.Regression:
      return RegressionIcon;
    case ModelType.TimeSeries:
      return TimeSeriesIcon;
    default:
      return undefined;
  }
};

export const getProjectStatusIcon = (status?: ProjectStatus | string | null) => {
  switch (status) {
    case ProjectStatus.InProgress:
      return InProgressIcon;
    case ProjectStatus.Completed:
      return CompletedIcon;
    default:
      return NotStartedIcon;
  }
};

export const getReviewStatusIcon = (status?: ReviewStatus | string | null) => {
  switch (status) {
    case ReviewStatus.Approved:
      return ApprovedIcon;
    case ReviewStatus.Pending:
      return PendingIcon;
    case ReviewStatus.Cancelled:
      return CancelledIcon;
    case ReviewStatus.Rejected:
      return RejectedIcon;
    default:
      return undefined;
  }
};
