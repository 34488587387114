import React, { useMemo } from 'react';

import EmptyReviewsIcon from '../../assets/graphics/documentation/empty-reviews.svg';
import { ReactComponent as CreateIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { Button, EmptyList, RestrictedAccessTooltip } from '../../ui';

interface NoReviewsProps {
  isAdmin?: boolean;
  isObserver?: boolean;
  isOwner?: boolean;
  isOwnerDefined?: boolean;
  isViewer?: boolean;
  onCreate: () => void;
}

export const NoReviews = ({ isAdmin, isObserver, isOwner, isOwnerDefined, isViewer, onCreate }: NoReviewsProps) => {
  const isAccessRestricted = isObserver || (!isOwner && !isAdmin) || !isOwnerDefined;

  const infoMessage = useMemo(() => {
    if (isObserver) {
      return $t({
        id: 'restrictedAccess.message',
        defaultMessage: 'Restricted Access',
      });
    }
    if (!isOwnerDefined)
      return $t({
        id: 'NoReviews.ownerUndefined',
        defaultMessage: 'The phase owner must be defined in order to request a review',
      });
    if (isAccessRestricted)
      return $t({
        id: 'NoReviews.disabledRequest',
        defaultMessage: 'Only the phase owner or the workspace manager can request a review',
      });
    return undefined;
  }, [isObserver, isOwnerDefined, isAccessRestricted]);

  return (
    <EmptyList
      borderless
      graphic={EmptyReviewsIcon}
      iconSize={200}
      message={
        <>
          {$t({
            id: 'NoReviews.message',
            defaultMessage: 'No Reviews yet!',
          })}
        </>
      }
      hint={
        <>
          {$t({
            id: 'NoReviews.hint_1',
            defaultMessage: 'Validate your work and complete this Phase by requesting a review from subject-',
          })}
          <br />
          {$t({
            id: 'NoReviews.hint_2',
            defaultMessage: 'matter experts and stakeholders.',
          })}
        </>
      }
      actionButton={
        !isViewer ? (
          <RestrictedAccessTooltip restricted={isAccessRestricted} message={infoMessage}>
            <Button leftIcon={CreateIcon} disabled={isAccessRestricted} onClick={onCreate}>
              {$t({ id: 'NoReviews.requestAReview', defaultMessage: 'Request a Review' })}
            </Button>
          </RestrictedAccessTooltip>
        ) : null
      }
    />
  );
};
