import cn from 'classnames';
import React, { ReactNode } from 'react';

import { FlexContainer } from '../containers';
import { WithLoading } from '../loading-animation';

import { ListSkeleton } from './ListSkeleton';

import styles from './List.module.scss';

interface ListProps extends VecticeHTMLProps<HTMLUListElement> {
  loading?: boolean;
  empty?: boolean;
  emptyText: ReactNode;
  collapsed?: boolean;
  gutterBottom?: boolean;
}

export const List = React.forwardRef<HTMLUListElement, ListProps>(
  ({ collapsed, gutterBottom, loading, empty, emptyText, className, ...props }, ref) => {
    if (empty && !loading) {
      return <div className={cn({ [styles.gutterBottom]: gutterBottom })}>{emptyText}</div>;
    }

    return (
      <WithLoading loading={loading} skeleton={<ListSkeleton />} className={styles.list}>
        <FlexContainer
          ref={ref}
          component="ul"
          direction="column"
          gap={collapsed ? 0 : 8}
          className={cn(
            styles.list,
            {
              [styles.collapsed]: collapsed,
              [styles.gutterBottom]: gutterBottom,
            },
            className,
          )}
          {...props}
        />
      </WithLoading>
    );
  },
);
