import cn from 'classnames';
import React from 'react';

import { getDocumentationPageStatusColor } from '../../entities/utils';
import { DocumentationPageStatus, DocumentationCounts } from '../../gql/graphql';
import { FlexContainer, Progress } from '../../ui';
import { getDocumentationPageStatusIcon, getPercentages } from '../../utils';
import { PhaseStatisticBlock } from '../phase/PhaseStatisticBlock';

import styles from './ProjectProgress.module.scss';

interface PhaseProgressProps {
  phasesCounts: DocumentationCounts;
}

export const ProjectProgress = ({ phasesCounts }: PhaseProgressProps) => {
  const done = phasesCounts?.done || 0;
  const ongoing = phasesCounts?.ongoing || 0;
  const toDo = phasesCounts?.toDo || 0;
  const total = phasesCounts?.total || 0;

  const percentages = getPercentages([done, ongoing, toDo], total);

  const completedClassName = styles[getDocumentationPageStatusColor(DocumentationPageStatus.Completed)];
  const draftClassName = styles[getDocumentationPageStatusColor(DocumentationPageStatus.Draft)];
  const notStartedClassName = styles[getDocumentationPageStatusColor(DocumentationPageStatus.NotStarted)];

  return (
    <FlexContainer direction="column">
      <Progress
        total={total}
        values={[
          { color: getDocumentationPageStatusColor(DocumentationPageStatus.Completed), value: done },
          { color: getDocumentationPageStatusColor(DocumentationPageStatus.Draft), value: ongoing },
        ]}
      />
      <FlexContainer className={styles.stats} gap={16}>
        <PhaseStatisticBlock
          aria-label="Completed"
          className={styles.statistic_bloc}
          count={done}
          icon={getDocumentationPageStatusIcon(DocumentationPageStatus.Completed)}
          iconClassName={cn(styles.icon, completedClassName)}
          percentage={percentages[0]}
          status={$t({ id: 'component.phase.phaseProgress.completed', defaultMessage: 'Completed' })}
          total={total}
        />
        <PhaseStatisticBlock
          aria-label="In Progress"
          className={styles.statistic_bloc}
          count={ongoing}
          icon={getDocumentationPageStatusIcon(DocumentationPageStatus.Draft)}
          iconClassName={cn(styles.icon, draftClassName)}
          percentage={percentages[1]}
          status={$t({ id: 'component.phase.phaseProgress.ongoing', defaultMessage: 'Ongoing' })}
          total={total}
        />
        <PhaseStatisticBlock
          aria-label="Not Started"
          className={styles.statistic_bloc}
          count={toDo}
          icon={getDocumentationPageStatusIcon(DocumentationPageStatus.NotStarted)}
          iconClassName={cn(styles.icon, notStartedClassName)}
          percentage={percentages[2]}
          status={$t({ id: 'component.phase.phaseProgress.notStarted', defaultMessage: 'Not Started' })}
          total={total}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
