import React from 'react';

import { FlexContainer, ModalPrompt, Typography } from '../../ui';

interface ConfirmFeatureFlagChangeModalProps {
  featureFlag: { code: string; enabled: boolean; description?: string | null };
  reset?: boolean;
  orgName?: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmFeatureFlagChangeModal = ({
  featureFlag,
  orgName,
  reset,
  onClose,
  onConfirm,
}: ConfirmFeatureFlagChangeModalProps) => (
  <ModalPrompt
    confirmLabel={
      reset
        ? $t({
            id: 'ConfirmFeatureFlagChangeModal.resetConfirmText',
            defaultMessage: 'Yes, reset',
          })
        : $t({
            id: 'ConfirmFeatureFlagChangeModal.confirmText',
            defaultMessage: 'Yes, {action}',
            values: {
              action: featureFlag.enabled ? 'disable' : 'enable',
            },
          })
    }
    cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
    title={
      reset
        ? $t({
            id: 'ConfirmFeatureFlagChangeModal.resetTitle',
            defaultMessage: 'Reset "{code}" flag',
            values: {
              code: featureFlag.code,
            },
          })
        : $t({
            id: 'ConfirmFeatureFlagChangeModal.confirmationTitle',
            defaultMessage: '{action} "{code}" flag',
            values: {
              action: featureFlag.enabled ? 'Disable' : 'Enable',
              code: featureFlag.code,
            },
          })
    }
    onClose={onClose}
    onConfirm={onConfirm}
  >
    <FlexContainer direction="column" gap={24}>
      {reset ? (
        <Typography paragraph>
          {$t({
            id: 'ConfirmFeatureFlagChangeModal.resetWarning',
            defaultMessage:
              'You are about to <bold>reset</bold> the <bold>"{code}"</bold> feature flag to the global value.',
            values: {
              code: featureFlag.code,
            },
          })}
        </Typography>
      ) : orgName ? (
        <Typography paragraph>
          {$t({
            id: 'ConfirmFeatureFlagChangeModal.orgWarning',
            defaultMessage:
              'You are about to <bold>{action}</bold> the <bold>"{code}"</bold> feature flag for the <bold>"{organization}"</bold> organization. This will override the global feature flag value.',
            values: {
              action: featureFlag.enabled ? 'disable' : 'enable',
              code: featureFlag.code,
              organization: orgName,
            },
          })}
        </Typography>
      ) : (
        <Typography paragraph>
          {$t({
            id: 'ConfirmFeatureFlagChangeModal.warning',
            defaultMessage:
              'You are about to <bold>{action}</bold> the <bold>"{code}"</bold> feature flag. The new feature flag value will apply to all organizations, apart from the organizations overriding this feature flag.',
            values: {
              action: featureFlag.enabled ? 'disable' : 'enable',
              code: featureFlag.code,
            },
          })}
        </Typography>
      )}
      <Typography paragraph>
        {$t({
          id: 'ConfirmFeatureFlagChangeModal.description',
          defaultMessage: '<bold>Feature flag description:</bold> {description}',
          values: {
            description: featureFlag.description,
          },
        })}
      </Typography>
    </FlexContainer>
  </ModalPrompt>
);
