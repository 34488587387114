import React from 'react';

import { Badge, BadgeProps } from '../../ui';

interface VersionBadgeProps extends BadgeProps {
  versionNumber?: number | null;
}

export const VersionBadge = ({ versionNumber, ...props }: VersionBadgeProps) => (
  <Badge color="accent" variant="square" size="sm" {...props}>
    v{versionNumber ?? '?'}
  </Badge>
);
