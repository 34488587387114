import { ArtifactType, LineageArtifact, LineageArtifactType } from '../../gql/graphql';

export const parseJobArtifacts = <T extends Pick<LineageArtifact, 'artifactType' | 'lineageArtifactType'>>(
  artifacts?: T[],
): {
  inputs?: T[];
  code?: T;
  outputs?: T[];
} => ({
  inputs: artifacts?.filter(
    (artifact: T) =>
      artifact.artifactType !== ArtifactType.Code && artifact.lineageArtifactType === LineageArtifactType.Input,
  ),
  code: artifacts?.find((artifact: T) => artifact.artifactType === ArtifactType.Code),
  outputs: artifacts?.filter(
    (artifact: T) =>
      artifact.artifactType !== ArtifactType.Code && artifact.lineageArtifactType === LineageArtifactType.Output,
  ),
});
