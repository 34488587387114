import React from 'react';

import { OwnUserTypeChangedNotificationContent } from '../../../entities';
import { getUserRoleDisplayName, userRolesRanked } from '../../../entities/utils';
import { FormatUserName } from '../../formatters';
import { OwnUserTypeChangedNotification as OwnUserTypeChangedNotificationType } from '../types';

import { Notification } from './Notification';

interface ProjectOwnerChangedNotificationProps {
  notification: OwnUserTypeChangedNotificationType;
  onClose: () => void;
}

export const OwnUserTypeChangedNotification = ({
  notification: { creator, content, createdDate },
  onClose,
}: ProjectOwnerChangedNotificationProps) => {
  const parsedContent: OwnUserTypeChangedNotificationContent = JSON.parse(content);

  const fromRoleRank = parsedContent.from ? userRolesRanked.indexOf(parsedContent.from) : -1;
  const toRoleRank = parsedContent.to ? userRolesRanked.indexOf(parsedContent.to) : -1;

  const isPromoted = toRoleRank >= fromRoleRank;

  return (
    <Notification creator={creator} createdDate={createdDate} onClose={onClose}>
      {isPromoted
        ? $t({
            id: 'notification.project.ownUserTypeChanged.promoted',
            defaultMessage: '<bold>{creator}</bold> upgraded you from {from} to {to}',
            values: {
              creator: <FormatUserName user={creator} />,
              from: getUserRoleDisplayName(parsedContent.from),
              to: getUserRoleDisplayName(parsedContent.to),
            },
          })
        : $t({
            id: 'notification.project.ownUserTypeChanged.demoted',
            defaultMessage: '<bold>{creator}</bold> downgraded you from {from} to {to}',
            values: {
              creator: <FormatUserName user={creator} />,
              from: getUserRoleDisplayName(parsedContent.from),
              to: getUserRoleDisplayName(parsedContent.to),
            },
          })}
    </Notification>
  );
};
