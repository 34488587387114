import { graphql } from '../../gql';

export const UPDATE_ITERATION_SECTION = graphql(`
  mutation updateSection($id: Float!, $updateModel: BaseStepUpdateInput!) {
    updateIterationStep(id: $id, updateModel: $updateModel) {
      id
      description
      index
      name
    }
  }
`);
