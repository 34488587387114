import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { ModelVersionRow } from './ModelVersionRow';

const ModelVersionListItemFragment = graphql(`
  fragment modelVersionListItem on ModelVersion {
    vecticeId
    ...modelVersionRow
  }
`);

let globalId = 0;

interface ModelVersionListItemProps {
  modelVersion: FragmentType<typeof ModelVersionListItemFragment>;
  breadcrumbs?: React.ReactNode;
}

export const ModelVersionListItem = ({ breadcrumbs, ...props }: ModelVersionListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`model-version-list-item-${globalId++}`);
  const modelVersion = useFragment(ModelVersionListItemFragment, props.modelVersion);

  return (
    <AssetListItem
      to={buildLink(VecticeRoutes.MODEL_VERSION, { modelVersionId: modelVersion.vecticeId })}
      aria-labelledby={ariaId}
    >
      <ModelVersionRow id={ariaId} modelVersion={modelVersion} breadcrumbs={breadcrumbs} />
    </AssetListItem>
  );
};
