import { useQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import config from '../../config';
import { ListOrderInput, OrderDirection, Workspace, WorkspaceType } from '../../gql/graphql';
import { useModals } from '../../modals';
import { VecticeRoutes, buildLink } from '../../routes';
import {
  Button,
  Column,
  EmptyData,
  FlexContainer,
  Pagination,
  SearchInput,
  Table,
  TableHeader,
  TableWrapper,
  Typography,
} from '../../ui';
import { FormatWorkspace } from '../asset-display';
import { FilterComposer, WorkspaceTypeFilter } from '../filters';
import { FormatDate } from '../formatters';

import { GET_ORGANIZATION_WORKSPACE_LIST } from './getOrganizationWorkspaceList.query';

import styles from './WorkspacesTable.module.scss';

const pageSize = config.LIST_DEFAULT_SIZE;

const initialSort = {
  field: 'name',
  direction: OrderDirection.Asc,
};

export const WorkspacesTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<Record<string, any>>({ type: [WorkspaceType.Public, WorkspaceType.Private] });
  const [sort, setSort] = useState<ListOrderInput>(initialSort);
  const { createWorkspace } = useModals();

  const { data, loading } = useQuery(GET_ORGANIZATION_WORKSPACE_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      pageIndex: currentPage,
      pageSize,
      order: sort,
      search: search,
      ...filters,
    },
  });

  const totalItems = data?.getOrganizationWorkspaceList?.total;
  const workspaces = data?.getOrganizationWorkspaceList?.items;

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setCurrentPage(1);
  }, []);

  const onFiltersUpdate = (updatedFilters: Record<string, any>) => {
    setCurrentPage(1);
    setFilters(updatedFilters);
  };

  return (
    <TableWrapper scrollable>
      <TableHeader>
        <FlexContainer gap={16} className={styles.table_header}>
          <SearchInput
            aria-label={$t({
              id: 'pages.organization.workspace.search',
              defaultMessage: 'Search',
            })}
            placeholder={$t({
              id: 'pages.organization.workspace.search.placeholder',
              defaultMessage: 'Search...',
            })}
            onDebouncedChange={handleSearch}
            className={styles.search}
          />
          <FilterComposer onFiltersUpdate={onFiltersUpdate} filters={filters}>
            <WorkspaceTypeFilter />
          </FilterComposer>
        </FlexContainer>
        <Button leftIcon={PlusIcon} onClick={createWorkspace}>
          {$t({ id: 'pages.organization.workspace.create', defaultMessage: 'Create workspace' })}
        </Button>
      </TableHeader>
      <Table
        data={workspaces}
        rowKey="vecticeId"
        emptyText={
          <EmptyData
            message={$t({
              id: 'component.WorkspaceTable.noWorkspaces',
              defaultMessage: 'No Workspaces',
            })}
          />
        }
        initialSort={sort ? { key: sort.field, direction: sort.direction } : undefined}
        loading={loading}
        onSort={(field, direction) => setSort({ field, direction })}
        scroll={{ x: true, y: true }}
      >
        <Column key="name" title="Name" sortable ellipsis>
          {(name: string, workspace: Workspace) => (
            <Link
              to={{
                pathname: buildLink(VecticeRoutes.WORKSPACE, { workspaceId: workspace.vecticeId }),
              }}
            >
              <div className={styles.name_cell}>
                <Typography variant="callout" weight="semi-bold" color="primary" ellipsis>
                  <FormatWorkspace name={name} iconSize={16} type={workspace.type} placement="left" ellipsis />
                </Typography>
                {workspace.description && (
                  <Typography variant="callout" color="tertiary" title={workspace.description} ellipsis>
                    {workspace.description}
                  </Typography>
                )}
              </div>
            </Link>
          )}
        </Column>
        <Column key="createdDate" title="Created" sortable width={120} ellipsis>
          {(createdDate: Date) => <FormatDate date={createdDate} />}
        </Column>
        <Column key="updatedDate" title="Updated" sortable width={120} ellipsis>
          {(updatedDate: Date) => <FormatDate date={updatedDate} fromNow />}
        </Column>
        <Column key="userCount" title="Members" align="right" width={90}>
          {(users: Workspace['userCount'], workspace: Workspace) => (
            <>{workspace.type === WorkspaceType.Public ? '-' : users}</>
          )}
        </Column>
        <Column key="projectCount" title="Projects" align="right" width={90}>
          {(projects: Workspace['projectCount']) => <>{projects}</>}
        </Column>
      </Table>
      <Pagination pageSize={pageSize} current={currentPage} total={totalItems} onChange={setCurrentPage} />
    </TableWrapper>
  );
};
