import React from 'react';

import { getModelTypeDisplayName } from '../../entities/utils';
import { ModelType as ModelTypeEnum } from '../../gql/graphql';
import { Icon, Typography } from '../../ui';
import { getModelTypeIcon } from '../../utils';

import styles from './Model.module.scss';

export const ModelType = ({ modelType }: { modelType?: ModelTypeEnum | string | null }) => {
  const icon = getModelTypeIcon(modelType);

  return (
    <Typography color="tertiary" ellipsis variant="callout">
      {icon && <Icon className={styles.icon} icon={icon} size={20} />}
      <Typography ellipsis>{getModelTypeDisplayName(modelType)}</Typography>
    </Typography>
  );
};
