import React from 'react';

import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/ic-dataset-version.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { DatasetVersionTooltip } from './DatasetVersionTooltip';

interface DatasetVersionLinkProps {
  name?: string | null;
  resourceId?: string | null;
  target?: string;
}

export const DatasetVersionLink = ({ name, resourceId, target }: DatasetVersionLinkProps) => (
  <DatasetVersionTooltip id={resourceId} placement="topLeft">
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: resourceId }) : undefined}
      icon={DatasetVersionIcon}
      target={target}
    />
  </DatasetVersionTooltip>
);
