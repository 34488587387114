import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType, IterationStatus as IterationStatusEnum } from '../../../gql/graphql';
import { IterationLink, PhaseLink, ProjectLink } from '../../asset-display';
import { IterationStatusBadge } from '../../badges';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const IterationActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const iteration = (
    <IterationLink
      name={activity.targetName || $t({ id: 'activities.iteration.title', defaultMessage: 'Iteration' })}
      resourceId={activity.targetVecticeId}
    />
  );

  const project = <ProjectLink name={activity.project?.name} resourceId={activity.project?.vecticeId} />;

  const phase = (
    <PhaseLink
      name={activity.sourceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.sourceVecticeId}
    />
  );

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.iteration.created',
          defaultMessage: '{user} created {iteration} in {phase} of {project}',
          values: {
            user,
            iteration,
            phase,
            project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.iteration.deleted',
          defaultMessage: '{user} deleted {iteration} in {phase} of {project}',
          values: {
            user,
            iteration,
            phase,
            project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Status) {
      return (
        <>
          {$t({
            id: 'activities.iteration.updated.status',
            defaultMessage: '{user} transitioned {iteration} from {oldStatus} to {newStatus}',
            values: {
              user,
              iteration,
              oldStatus: <IterationStatusBadge status={activity.fromValue as IterationStatusEnum} />,
              newStatus: <IterationStatusBadge status={activity.toValue as IterationStatusEnum} />,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.iteration.updated.description',
            defaultMessage: '{user} updated the description of {resource} in {parent} of {project}',
            values: {
              user,
              resource: iteration,
              parent: phase,
              project,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Name) {
      const oldNameLink = (
        <IterationLink name={activity.fromValue || undefined} resourceId={activity.targetVecticeId} />
      );
      const newNameLink = <IterationLink name={activity.toValue || undefined} resourceId={activity.targetVecticeId} />;
      return (
        <>
          {$t({
            id: 'activities.iteration.update.name',
            defaultMessage: '{user} renamed {oldName} to {newName}',
            values: {
              user,
              oldName: oldNameLink,
              newName: newNameLink,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Star) {
      if (activity.toValue === 'true') {
        return (
          <>
            {$t({
              id: 'activities.iteration.update.AddToStarredList',
              defaultMessage: '{user} starred {iteration} in {phase} of {project}',
              values: {
                user,
                iteration,
                phase,
                project,
              },
            })}
          </>
        );
      } else {
        return (
          <>
            {$t({
              id: 'activities.iteration.update.RemoveFromStarredList',
              defaultMessage: '{user} unstarred {iteration} in {phase} of {project}',
              values: {
                user,
                iteration,
                phase,
                project,
              },
            })}
          </>
        );
      }
    }
  }

  return null;
};
