import cn from 'classnames';
import { DropdownProps } from 'rc-dropdown/lib/Dropdown';
import React from 'react';

import { ReactComponent as CaretIcon } from '../../assets/icons/interface/ic-caret-down.svg';
import { Dropdown } from '../dropdown';
import { Icon } from '../svg';
import { Typography } from '../typography';

import { Button, ButtonProps } from './Button';

import styles from './ButtonWithDropdown.module.scss';

interface ButtonWithDropDownProps {
  dropdownContent: DropdownProps['overlay'];
  wrapperClassName?: string;
}

export const ButtonWithDropdown = ({
  className,
  color = 'accent',
  size = 'sm',
  variant = 'primary',
  dropdownContent,
  wrapperClassName,
  children,
  ...buttonProps
}: ButtonProps & ButtonWithDropDownProps) => {
  return (
    <Typography component="div" className={cn(styles.wrapper, wrapperClassName)}>
      <Button color={color} size={size} variant={variant} className={cn(styles.main, className)} {...buttonProps}>
        {children}
      </Button>
      <Dropdown
        overlay={dropdownContent}
        trigger={['click']}
        // trick to remove the spacing between the button and the dropdown
        // align the overlay 'top right' to the trigger 'bottom right' with no offset
        align={{ points: ['tr', 'br'], offset: [1, 2] }}
      >
        <Button aria-haspopup="menu" color={color} size={size} variant={variant} className={styles.trigger}>
          <Icon icon={CaretIcon} size={20} aria-label="See more options" />
        </Button>
      </Dropdown>
    </Typography>
  );
};
