import React from 'react';

import { AssetType } from '../../gql/graphql';
import { useWithFeatureFlags } from '../../guards';
import {
  AlgorithmFilter,
  ContributorsFilter,
  DatasetSourceOriginFilter,
  DatasetTypeFilter,
  FilterComposer,
  ModelHasVersionInFilter,
  ModelTypeFilter,
  ProjectStatusFilter,
  ProjectOwnerFilter,
  WorkspaceFilter,
  InventoryReferenceFilter,
} from '../filters';

interface GlobalSearchFiltersProps {
  assetType?: AssetType;
  filters?: Record<string, any>;
  workspaceIdList?: string[];
  onFiltersUpdate: (filters: Record<string, any>) => void;
}

export const GlobalSearchFilters = ({
  assetType,
  filters,
  workspaceIdList,
  onFiltersUpdate,
}: GlobalSearchFiltersProps) => {
  const { allowed: filterOnInventoryRef } = useWithFeatureFlags({
    featureFlag: 'global-search-filter-by-inventory-information',
  });

  return (
    <FilterComposer filters={filters} onFiltersUpdate={onFiltersUpdate}>
      <WorkspaceFilter />
      {workspaceIdList && <ContributorsFilter workspaceIdList={workspaceIdList} />}
      {assetType === AssetType.Project && (
        <>
          {workspaceIdList && <ProjectOwnerFilter workspaceIdList={workspaceIdList} />}
          <ProjectStatusFilter />
        </>
      )}
      {assetType === AssetType.Dataset && (
        <>
          <DatasetTypeFilter />
          <DatasetSourceOriginFilter />
        </>
      )}
      {assetType === AssetType.Model && (
        <>
          {!!workspaceIdList?.length && (
            <AlgorithmFilter workspaceIdList={filters?.workspace ? [filters?.workspace] : workspaceIdList} />
          )}
          {filterOnInventoryRef && !!workspaceIdList?.length && (
            <InventoryReferenceFilter workspaceIdList={filters?.workspace ? [filters?.workspace] : workspaceIdList} />
          )}
          <ModelHasVersionInFilter />
          <ModelTypeFilter />
        </>
      )}
    </FilterComposer>
  );
};
