import React, { useState } from 'react';

import { AttachmentOutput } from '../../gql/graphql';
import { useWithFeatureFlags } from '../../guards';
import { EmptyList, Grid, GridCell, Summary, Typography } from '../../ui';
import { downloadEntityFile, DROP_ATTACHMENT_FORMAT } from '../../utils';
import { LightboxPreview } from '../previews';
import { PreviewTile } from '../previewTile';

import styles from './SummaryAttachments.module.scss';

type SummaryAttachmentOutputType = AttachmentOutput;

interface SummaryAttachmentsProps {
  attachments: SummaryAttachmentOutputType[];
  nodeKey?: string;
  open?: boolean;
}

export const SummaryAttachments = ({ attachments, nodeKey, open }: SummaryAttachmentsProps) => {
  const { allowed: dragDropAttachment } = useWithFeatureFlags({
    featureFlag: 'allow-drag-and-drop-attachment-editor',
  });
  const [selectedAttachment, setSelectedAttachment] = useState<number | null>(null);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, attachment: SummaryAttachmentOutputType) => {
    if (!dragDropAttachment) return;
    const payload = {
      name: attachment.name,
      id: attachment.id,
      content: attachment.content,
      type: attachment.type,
      entityId: attachment.entityId,
      mimeType: attachment.mimeType,
      attachmentType: attachment.attachmentType,
      nodeKey: nodeKey,
    };
    event.dataTransfer.setData(DROP_ATTACHMENT_FORMAT, JSON.stringify(payload));
  };

  return (
    <>
      <Summary
        className={styles.section}
        open={open}
        withBackground={false}
        summary={
          <Typography component="h2" color="primary" variant="body" weight="semi-bold">
            {$t({ id: 'attachments.title', defaultMessage: 'Attachments' })}
          </Typography>
        }
      >
        {attachments.length === 0 && (
          <EmptyList
            className={styles.empty}
            message={
              <Typography variant="callout" color="tertiary" weight="regular">
                {$t({ id: 'SummaryAttachments.noAttachments', defaultMessage: 'No Attachments' })}
              </Typography>
            }
          />
        )}
        {attachments.length > 0 && (
          <Grid className={styles.grid}>
            {attachments.map((attachment, index) => (
              <GridCell
                key={attachment.id}
                draggable={dragDropAttachment ? 'true' : 'false'}
                onDragStart={(event) => handleDragStart(event, attachment)}
              >
                <PreviewTile
                  attachment={attachment}
                  onClick={() => setSelectedAttachment(index)}
                  onDownload={() => downloadEntityFile(attachment)}
                />
              </GridCell>
            ))}
          </Grid>
        )}
      </Summary>

      {selectedAttachment !== null && attachments?.[selectedAttachment] && (
        <LightboxPreview
          entityFile={attachments[selectedAttachment]}
          previousDisabled={selectedAttachment === 0}
          nextDisabled={selectedAttachment === attachments.length - 1}
          onPrevious={() => setSelectedAttachment(selectedAttachment - 1)}
          onNext={() => setSelectedAttachment(selectedAttachment + 1)}
          onClose={() => setSelectedAttachment(null)}
        />
      )}
    </>
  );
};
