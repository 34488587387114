import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { graphql } from '../../../gql';

export const GET_DATASET_VERSION_FILE_SOURCE_LIST = graphql(`
  query getDatasetVersionFileSourceList($datasetVersionId: VecticeId!) {
    fileSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {
      items {
        id
        filename
        mimeType
        rowsNumber
        columnsNumber
        columnsTypesCounts {
          categoryType
          count
        }
      }
      total
    }
  }
`);

export const GET_DATASET_VERSION_DB_SOURCE_LIST = graphql(`
  query getDatasetVersionDbSourceList($datasetVersionId: VecticeId!) {
    dbSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {
      items {
        id
        tablename
        type
        rowsNumber
        columnsNumber
        columnsTypesCounts {
          categoryType
          count
        }
      }
      total
    }
  }
`);

export const useFileDBSources = (datasetVersionId: string) => {
  const { data: fileData, loading: fileLoading } = useQuery(GET_DATASET_VERSION_FILE_SOURCE_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      datasetVersionId,
    },
  });

  const fileSources = useMemo(
    () =>
      fileData?.fileSources.items?.map((item) => ({
        ...item,
        name: item.filename,
      })),
    [fileData],
  );

  const { data: dbData, loading: dbLoading } = useQuery(GET_DATASET_VERSION_DB_SOURCE_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      datasetVersionId,
    },
  });

  const dbSources = useMemo(
    () =>
      dbData?.dbSources.items?.map((item) => ({
        ...item,
        name: item.tablename,
      })),
    [dbData],
  );

  return {
    loading: fileLoading || dbLoading,
    dbSources: dbSources,
    dbSourcesTotal: dbData?.dbSources.total ?? 0,
    fileSources: fileSources,
    fileSourcesTotal: fileData?.fileSources.total ?? 0,
  };
};
