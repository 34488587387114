import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';
import { DocumentationLink } from '../links';

interface NoCDTReportsProps {
  modelVersionId?: string;
}

export const NoCDTReports = ({ modelVersionId }: NoCDTReportsProps) => (
  <EmptyList
    icon={VecticeIcon}
    message={$t({ id: 'NoCDTReports.noReports', defaultMessage: 'No reports yet!' })}
    hint={
      <>
        {modelVersionId
          ? $t({
              id: 'NoCDTReports.modelVersion.hint',
              defaultMessage: 'Every registered report linked to this model version will be visible here.',
            })
          : $t({
              id: 'NoCDTReports.project.hint',
              defaultMessage: 'Every registered report created in this project will be visible here.',
            })}
        <br />
        <DocumentationLink asTypography url="vectice_for_banking">
          {$t({
            id: 'home.tabs.reviews.empty.readOurDocs',
            defaultMessage: 'Read our docs to learn more.',
          })}
        </DocumentationLink>
      </>
    }
  />
);
