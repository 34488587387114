import React, { useMemo } from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/ic-dataset-version.svg';
import { getDatasetTypeDisplayName } from '../../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { AssetRow, Icon, Typography } from '../../../ui';
import { getDatasetTypeIcon } from '../../../utils';
import { VersionBadge } from '../../badges';
import { FormatDate, FormatFileSize, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';

const DatasetVersionRowFragment = graphql(`
  fragment datasetVersionRow on DataSetVersion {
    vecticeId
    versionNumber
    isStarred
    dataSet {
      vecticeId
      name
      type
    }
    datasetSources {
      id
      size
      itemsCount
    }
    updatedDate
    lastUpdatedBy {
      ...userFields
    }
  }
`);

interface DatasetVersionRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  datasetVersion?: FragmentType<typeof DatasetVersionRowFragment>;
}

export const DatasetVersionRow = (props: DatasetVersionRowProps) => {
  const datasetVersion = useFragment(DatasetVersionRowFragment, props.datasetVersion);

  const files = useMemo(() => {
    if (datasetVersion?.datasetSources) {
      return datasetVersion.datasetSources.reduce(
        (acc, source) => ({
          size: acc.size + Number(source.size),
          count: acc.count + source.itemsCount,
        }),
        { size: 0, count: 0 },
      );
    }

    return null;
  }, [datasetVersion]);

  if (!datasetVersion) {
    return null;
  }

  const typeIcon = getDatasetTypeIcon(datasetVersion.dataSet.type);

  return (
    <AssetRow
      icon={DatasetVersionIcon}
      label={datasetVersion.dataSet.name}
      versionBadge={<VersionBadge versionNumber={datasetVersion.versionNumber} />}
      isStarred={datasetVersion.isStarred}
      {...props}
    >
      <Typography variant="footnote" color="secondary">
        {datasetVersion.vecticeId}
      </Typography>
      <Typography variant="footnote" color="secondary">
        {typeIcon && <Icon icon={typeIcon} size={14} />} {getDatasetTypeDisplayName(datasetVersion.dataSet.type)}
      </Typography>
      {!!files && (
        <Typography variant="footnote" color="secondary">
          <FormatFileSize size={files.size} />
        </Typography>
      )}
      {!!files && (
        <Typography variant="footnote" color="secondary">
          {$t({
            id: 'assetDisplay.datasetVersion.fileCount.label',
            defaultMessage: '{filesCount, plural, one {# File} other {# Files}}',
            values: { filesCount: files.count },
          })}
        </Typography>
      )}
      {!!datasetVersion.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy
            date={<FormatDate date={datasetVersion.updatedDate} fromNow />}
            user={<UserAvatar user={datasetVersion.lastUpdatedBy} size="xs" />}
          />
        </Typography>
      )}
    </AssetRow>
  );
};
