import React from 'react';

import { Skeleton } from '../../../ui';
import { generateArray } from '../../../utils';

import styles from './Activity.module.scss';

export const ActivityListSkeleton = () => (
  <>
    {generateArray(5).map((uuid) => (
      <div key={uuid} className={styles.activity}>
        <Skeleton.Shape shape="circle" height={30} width={30} className={styles.icon} />
        <div className={styles.wrapper} style={{ alignItems: 'initial', width: '100%' }}>
          <div className={styles.content}>
            <Skeleton.Paragraph />
            <div>
              <Skeleton.Paragraph width="100px" variant="footnote" />
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);
