import React, { useState } from 'react';

import type { TypographyProps } from '../../ui';

import { ArtifactType, LineageType } from '../../gql/graphql';
import { useEditLineage } from '../../hooks';
import { FlexContainer, Typography } from '../../ui';
import { VecticeResourceType } from '../../utils';
import { EditLineageCodeModal } from '../assets/edit-lineage';

import { LineageEditionProps } from './interfaces';
import { LineageAsset, LineageCode, LineageInput } from './internals';

import styles from './Lineage.module.scss';

export const LineageEdition = ({
  codeFile,
  codeSource: code,
  currentArtifact,
  datasetInputs,
  lineageContext,
  outputs,
}: LineageEditionProps) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const { artifactType, lineageId, parentId, projectId, versionId } = lineageContext ?? {};

  const { editLineage } = useEditLineage(
    artifactType === ArtifactType.Dataset ? VecticeResourceType.DATASET : VecticeResourceType.MODEL,
  );

  if (!lineageId || !parentId || !projectId || !versionId) {
    return null;
  }

  const artifactTypeProps: Partial<TypographyProps> = {
    align: 'center',
    className: styles.type,
    color: 'secondary',
    transform: 'uppercase',
    variant: 'footnote',
  };

  return (
    <>
      <FlexContainer direction="column">
        <div className={styles.edition}>
          <div className={styles.artifact}>
            <Typography {...artifactTypeProps}>{$t({ id: 'lineage.input', defaultMessage: 'input' })}</Typography>
            <LineageInput
              inputs={datasetInputs || []}
              parentId={parentId}
              projectId={projectId}
              versionId={versionId}
              onUpdate={(datasetVersionIds) =>
                editLineage({
                  variables: {
                    versionId,
                    type: artifactType === ArtifactType.Dataset ? LineageType.DatasetVersion : LineageType.ModelVersion,
                    data: {
                      datasetVersionIds,
                    },
                  },
                })
              }
            />
          </div>
          <div className={styles.artifact}>
            <Typography {...artifactTypeProps}>{$t({ id: 'lineage.code', defaultMessage: 'code' })}</Typography>
            <LineageCode
              codeFile={codeFile}
              codeSource={code}
              editMode={true}
              lineageId={lineageId}
              onEdit={() => setShowEditModal?.(true)}
            />
          </div>
          {outputs?.map((output, index) => (
            <div key={`artifact-${output.id}`} className={styles.artifact}>
              {index === 0 && (
                <Typography {...artifactTypeProps}>{$t({ id: 'lineage.output', defaultMessage: 'output' })}</Typography>
              )}
              <LineageAsset assetArtifact={output} currentArtifact={currentArtifact} />
            </div>
          ))}
        </div>
      </FlexContainer>
      {versionId && showEditModal && (
        <EditLineageCodeModal
          lineageId={lineageId}
          type={artifactType === ArtifactType.Dataset ? LineageType.DatasetVersion : LineageType.ModelVersion}
          versionId={versionId}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};
