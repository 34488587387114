import { useQuery } from '@apollo/client';
import React from 'react';

import NoPhasesGraphic from '../../../assets/graphics/documentation/empty-phases.svg';
import { graphql } from '../../../gql';
import { Button, Card, FlexContainer, Graphic, List, Typography } from '../../../ui';
import { PhaseListItem } from '../../asset-display';

import { pageSize } from './config';

import styles from './HomeTabs.module.scss';

export const GET_USER_PHASES = graphql(`
  query getUserPhases($afterCursor: String, $pageSize: Int!) {
    getPhaseList(
      filters: { status: [NotStarted, Draft, InReview], onlyMine: true }
      order: { field: "updatedDate", direction: DESC }
      page: { size: $pageSize, afterCursor: $afterCursor }
    ) {
      items {
        vecticeId
        ...phaseListItem
        parent {
          vecticeId
          name
          workspace {
            vecticeId
            type
            name
          }
        }
      }
      page {
        afterCursor
        hasNextPage
      }
    }
  }
`);

const EmptyPhasesTab = () => (
  <Card inert className={styles.emptyCard}>
    <FlexContainer direction="column" align="center" gap={8} className={styles.emptyContent}>
      <Graphic src={NoPhasesGraphic} size={200} />
      <Typography color="primary" weight="semi-bold">
        {$t({
          id: 'home.tabs.phases.empty.label',
          defaultMessage: 'No Phases yet!',
        })}
      </Typography>
      <Typography variant="callout" color="secondary" align="center" paragraph>
        {$t({
          id: 'home.tabs.phases.empty.description',
          defaultMessage:
            'Get started on your Project by creating a Phase. Phases enable you to organize Projects, enforce best practices, and capture knowledge from Iterations.',
        })}
      </Typography>
    </FlexContainer>
  </Card>
);

export const PhasesTab = () => {
  const { data, loading, fetchMore } = useQuery(GET_USER_PHASES, {
    fetchPolicy: 'network-only',
    variables: { pageSize },
  });

  const phases = data?.getPhaseList.items;
  const { hasNextPage, afterCursor } = data?.getPhaseList.page ?? {};

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  };

  return (
    <>
      <List collapsed empty={phases?.length === 0} emptyText={<EmptyPhasesTab />} loading={loading} gutterBottom>
        {phases?.map((phase) => (
          <PhaseListItem
            key={phase.vecticeId}
            phase={phase}
            project={phase.parent}
            workspace={phase.parent?.workspace}
          />
        ))}
      </List>
      {hasNextPage && (
        <Button variant="outlined" size="sm" onClick={handleShowMore}>
          {$t({ id: 'button.showMore', defaultMessage: 'Show More' })}
        </Button>
      )}
    </>
  );
};
