import React from 'react';

import { FormatUserName } from '../../formatters';
import { UserNotificationUserJoinedOrganization } from '../types';

import { Notification } from './Notification';

interface UserJoinedOrganizationNotificationProps {
  notification: UserNotificationUserJoinedOrganization;
}

export const UserJoinedOrganizationNotification = ({
  notification: { creator, createdDate },
}: UserJoinedOrganizationNotificationProps) => (
  <Notification creator={creator} createdDate={createdDate}>
    {$t({
      id: 'notification.userJoined',
      defaultMessage: '<bold>{creator}</bold> has joined Vectice',
      values: {
        creator: <FormatUserName user={creator} />,
      },
    })}
  </Notification>
);
