import React, { useState } from 'react';

import { ReactComponent as APIReferenceIcon } from '../../../assets/icons/interface/ic-common-file-code.svg';
import { ReactComponent as DocumentationIcon } from '../../../assets/icons/interface/ic-common-file-text.svg';
import { ReactComponent as InformationCircleIcon } from '../../../assets/icons/interface/ic-information-circle.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/icons/interface/ic-messages-bubble-square-information.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/icons/interface/ic-question-circle.svg';
import { useAuthentication, useOrgConfig } from '../../../context';
import { OrganizationLevel } from '../../../gql/graphql';
import { useAnalytics } from '../../../hooks';
import { useModals } from '../../../modals';
import { Dropdown, Icon, Menu, MenuItem, Tooltip } from '../../../ui';
import { DocumentationLink } from '../../links';
import { AboutVecticeModal } from '../../modals';

import styles from './Menus.module.scss';

export const HelpMenu = () => {
  const { user } = useAuthentication();
  const { contactSupport } = useModals();
  const { logClick } = useAnalytics();
  const { organization } = useOrgConfig();

  const [showAboutModal, setShowAboutModal] = useState(false);

  const menu = (
    <Menu className={styles.menu}>
      <MenuItem leftIcon={InformationCircleIcon} onClick={() => setShowAboutModal(true)}>
        {$t({ id: 'helpMenu.aboutVectice', defaultMessage: 'About Vectice' })}
      </MenuItem>
      <DocumentationLink>
        <MenuItem clickable leftIcon={DocumentationIcon}>
          {$t({ id: 'helpMenu.documentation', defaultMessage: 'Vectice Docs' })}
        </MenuItem>
      </DocumentationLink>
      <DocumentationLink url="api_reference">
        <MenuItem clickable leftIcon={APIReferenceIcon}>
          {$t({ id: 'helpMenu.apiReference', defaultMessage: 'API Reference Docs' })}
        </MenuItem>
      </DocumentationLink>
      <MenuItem
        leftIcon={FeedbackIcon}
        onClick={() => {
          contactSupport(true);
          const isTrialOrg = organization?.level === OrganizationLevel.Trial;
          if (isTrialOrg) logClick('Contact_Support', 'help-menu', 'click');
        }}
      >
        {$t({ id: 'helpMenu.contactSupport', defaultMessage: 'Contact Support' })}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {user && (
        <Tooltip text={$t({ id: 'helpMenu.tooltip', defaultMessage: 'Information' })} placement="bottom">
          <Dropdown overlay={menu} trigger={['click']}>
            <button aria-label="Help" className={styles.button} type="button">
              <span className={styles.button_label}>
                <Icon icon={QuestionMarkIcon} />
              </span>
            </button>
          </Dropdown>
        </Tooltip>
      )}
      <AboutVecticeModal visible={showAboutModal} onClose={() => setShowAboutModal(false)} />
    </>
  );
};
