import React, { useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { WorkspaceFilterComponent } from '../ui';

export const pluginField = 'workspace';

class WorkspaceFilterNode<T> extends FilterNode<T> {
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <WorkspaceFilterComponent
        key={pluginField}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const WorkspaceFilter = () => {
  const { current: node } = useRef(new WorkspaceFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.workspace.label', defaultMessage: 'Workspace' }),
    node,
  });

  return null;
};
