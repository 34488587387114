import { graphql } from '../../gql';

export const GET_ITERATION_BY_ID = graphql(`
  query getIterationById($id: VecticeId!) {
    getIterationById(id: $id) {
      vecticeId
      index
      name
      description
      status
      starred
      phaseId
      phase {
        vecticeId
        name
        parent {
          vecticeId
        }
      }
      paginatedArtifacts(filters: { stepStrategy: EXCLUDES }, page: { index: 1, size: 100 }) {
        total
        items {
          id
          type
          index
          stepId
          ...IterationStepArtifact
        }
      }
      ...IterationSidebar
    }
  }
`);
