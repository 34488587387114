import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationMention } from '../types';

import { Notification } from './Notification';

interface WorkspaceMentionNotificationReportProps {
  reportId: number;
  notification: UserNotificationMention;
  reportName?: string;
  onClose: () => void;
}

const GET_CDTREPORT_BY_ID = graphql(`
  query getCDTReportForMention($id: Float!) {
    CDTReport(id: $id) {
      name
    }
  }
`);

export const MentionNotificationReport = ({
  reportId,
  notification: { creator, createdDate },
  reportName,
  onClose,
}: WorkspaceMentionNotificationReportProps) => {
  const { data, loading, error } = useQuery(GET_CDTREPORT_BY_ID, {
    variables: {
      id: reportId,
    },
    skip: reportName !== undefined,
  });

  const fetchedReportName = data?.CDTReport.name || $t({ id: 'generic.unknown', defaultMessage: 'Unknown' });

  if (loading) {
    return null;
  }

  return (
    <Notification
      component={!error ? Link : undefined}
      to={buildLink(VecticeRoutes.CDT_REPORT, { reportId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.mention.report',
        defaultMessage: '<bold>{creator}</bold> mentioned <bold>you</bold> in report <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title: reportName || fetchedReportName,
        },
      })}
    </Notification>
  );
};
