import React from 'react';

import type { TypographyProps } from '../../ui';

import { Typography } from '../../ui';

import { LineagePreviewProps } from './interfaces';
import { LineageAsset, LineageCode } from './internals';

import styles from './Lineage.module.scss';

export const LineagePreview = ({
  codeFile,
  codeSource,
  currentArtifact,
  inputs,
  linkTarget = '_blank',
  outputs,
}: LineagePreviewProps) => {
  const artifactTypeProps: Partial<TypographyProps> = {
    align: 'center',
    className: styles.type,
    color: 'secondary',
    transform: 'uppercase',
    variant: 'footnote',
  };

  return (
    <div className={styles.preview}>
      {!!inputs && inputs.length > 0 && (
        <div className={styles.artifact}>
          <Typography {...artifactTypeProps}>{$t({ id: 'lineage.input', defaultMessage: 'input' })}</Typography>
          <div>
            {inputs.map((input) => (
              <LineageAsset
                key={`lineage-asset-${input.id}`}
                assetArtifact={input}
                currentArtifact={currentArtifact}
                linkTarget={linkTarget}
              />
            ))}
          </div>
        </div>
      )}
      {(codeSource || codeFile) && (
        <div className={styles.artifact}>
          <Typography {...artifactTypeProps}>{$t({ id: 'lineage.code', defaultMessage: 'code' })}</Typography>
          <LineageCode codeSource={codeSource} codeFile={codeFile} />
        </div>
      )}
      {!!outputs && outputs.length > 0 && (
        <div className={styles.artifact}>
          <Typography {...artifactTypeProps}>{$t({ id: 'lineage.output', defaultMessage: 'output' })}</Typography>
          <div>
            {outputs.map((output) => (
              <LineageAsset
                key={`lineage-asset-${output.id}`}
                assetArtifact={output}
                currentArtifact={currentArtifact}
                linkTarget={linkTarget}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
