import { BaseSyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';

import type {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  FormState,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  RegisterOptions,
  FieldPath,
  FieldError,
} from 'react-hook-form/dist/types';

import { getValueFromPath, isObjectEmpty } from '../utils';

interface UseVecticeFormReturn<TFieldValues extends FieldValues = FieldValues, TContext = any>
  extends Omit<UseFormReturn<TFieldValues, TContext>, 'register' | 'handleSubmit' | 'formState'> {
  registerWithErrors: UseFormRegister<TFieldValues>;
  formState: Omit<FormState<TFieldValues>, 'isValid'> & { hasErrors: boolean };
  preSubmit: UseFormHandleSubmit<TFieldValues>;
}

export const useVecticeForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseVecticeFormReturn<TFieldValues, TContext> => {
  const form = useForm<TFieldValues, TContext>(props);

  return Object.assign(form, {
    registerWithErrors: <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
      name: TFieldName,
      options?: RegisterOptions<TFieldValues, TFieldName>,
    ) => {
      const isRequired = options?.required && (typeof options.required !== 'object' || options.required.value);

      if (isRequired) {
        const message =
          typeof options.required === 'string'
            ? options.required
            : typeof options.required === 'object'
            ? options.required.message
            : undefined;
        const testForWhitespace = (value: TFieldValues[TFieldName]) =>
          typeof value !== 'string' || !!value.trim() || message;
        if (typeof options?.validate === 'object') {
          options.validate = {
            ...options.validate,
            testForWhitespace,
          };
        } else if (options?.validate) {
          options.validate = {
            validate: options.validate,
            testForWhitespace,
          };
        } else {
          options.validate = {
            testForWhitespace,
          };
        }
      }

      const fieldProps = form.register(name, options);
      const fieldErrors = getValueFromPath<FieldError>(form.formState.errors, name);

      if (fieldErrors) {
        Object.assign(fieldProps, {
          error: fieldErrors.message,
        });
      }

      if (!isRequired) {
        Object.assign(fieldProps, {
          hint: $t({ id: 'forms.genericWording.Optional', defaultMessage: 'Optional' }),
        });
      }

      return fieldProps;
    },
    preSubmit: (onValid: SubmitHandler<TFieldValues>, onInvalid?: SubmitErrorHandler<TFieldValues>) => {
      const cleanValues =
        (onValid: SubmitHandler<TFieldValues>) => (data: TFieldValues, event?: BaseSyntheticEvent) => {
          const clonedData = { ...data };
          for (const key in clonedData) {
            if (Object.hasOwn(clonedData, key) && typeof clonedData[key] === 'string') {
              clonedData[key] = clonedData[key].trim();
            }
          }
          return onValid(clonedData, event);
        };
      return form.handleSubmit(cleanValues(onValid), onInvalid);
    },
    formState: Object.assign(form.formState, {
      get hasErrors() {
        return !isObjectEmpty(form.formState.errors);
      },
    }),
  });
};
