import cn from 'classnames';
import React, { ElementType } from 'react';

import { Icon } from '../svg';
import { Typography } from '../typography';

import { BadgeColors, BadgeSizes, BadgeVariants } from './types';

import styles from './Badge.module.scss';

export interface BadgeProps extends Omit<VecticeHTMLProps<HTMLSpanElement>, 'size' | 'color'> {
  variant?: BadgeVariants;
  color?: BadgeColors;
  contrast?: boolean;
  size?: BadgeSizes;
  withDot?: boolean;
  leftIcon?: ElementType;
  rightIcon?: ElementType;
}

export const Badge = ({
  variant = 'round',
  color = 'default',
  contrast,
  size = 'md',
  withDot,
  leftIcon,
  rightIcon,
  className,
  children,
  ...props
}: BadgeProps) => (
  <Typography
    variant={size === 'sm' ? 'footnote' : 'callout'}
    color="inherit"
    weight={size === 'sm' ? 'semi-bold' : 'medium'}
    className={cn(
      styles.badge,
      styles[variant],
      styles[color],
      styles[size],
      {
        [styles.contrast]: contrast,
        [styles.withChildren]: children,
      },
      className,
    )}
    {...props}
  >
    {withDot && <span aria-hidden className={styles.dot} />}
    {!withDot && leftIcon && <Icon icon={leftIcon} size={12} className={styles.leftIcon} aria-hidden />}
    {children}
    {rightIcon && <Icon icon={rightIcon} size={12} className={styles.rightIcon} aria-hidden />}
  </Typography>
);
