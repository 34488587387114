import dayjs from 'dayjs';
import React, { PropsWithChildren } from 'react';

import { UserIdentity } from '../../graphql/fragments';
import { Section, Typography } from '../../ui';
import { FormatCreatedBy, FormatDate, FormatUpdatedBy, FormatUserName } from '../formatters';

interface CreationDetailsProps {
  createdDate: dayjs.ConfigType;
  createdBy?: UserIdentity | null;
  updatedDate: dayjs.ConfigType;
  updatedBy?: UserIdentity | null;
}

export const CreationDetails = ({
  createdDate,
  createdBy,
  updatedDate,
  updatedBy,
}: PropsWithChildren<CreationDetailsProps>) => (
  <Section>
    {createdDate && (
      <Typography variant="footnote" weight="semi-bold" color="tertiary" paragraph ellipsis>
        <FormatCreatedBy date={<FormatDate date={createdDate} fromNow />} user={<FormatUserName user={createdBy} />} />
      </Typography>
    )}
    {updatedDate && (
      <Typography variant="footnote" weight="semi-bold" color="tertiary" paragraph ellipsis>
        <FormatUpdatedBy date={<FormatDate date={updatedDate} fromNow />} user={<FormatUserName user={updatedBy} />} />
      </Typography>
    )}
  </Section>
);
