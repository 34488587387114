import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/ic-dataset-version.svg';
import { VecticeRoutes, buildLink } from '../../../routes';
import { Icon, Typography, EmptySection, Grid, GridCell, WithLoading } from '../../../ui';
import { VersionBadge } from '../../badges';
import { useFileDBSources } from '../../dataset-source/hooks';
import { ResourceCard } from '../../widgetComponents/ResourceCard';

import { DatasetVersionLink } from './DatasetVersionLink';

import styles from './DatasetVersionResourceWidget.module.scss';

interface IProps {
  resourceId: string;
  name?: string | null;
  versionNumber?: number;
}

export const DatasetVersionResourceWidget = ({ resourceId, name, versionNumber }: IProps) => {
  const { loading, dbSources, dbSourcesTotal, fileSources, fileSourcesTotal } = useFileDBSources(resourceId);

  return (
    <WithLoading loading={loading}>
      {dbSourcesTotal > 3 && (
        <Typography component="p" className={styles.hintTotal}>
          {$t({
            id: 'datasetVersion.widget.resourceSummary.total',
            defaultMessage: '3 out of {total} resources displayed. To see more go to {resource}',
            values: {
              total: dbSourcesTotal,
              resource: <DatasetVersionLink name={name} resourceId={resourceId} target="_blank" />,
            },
          })}
        </Typography>
      )}
      {dbSourcesTotal > 0 && (
        <Grid className={styles.grid}>
          {dbSources?.map((resource) => (
            <GridCell key={resource.id}>
              <ResourceCard resource={resource} />
            </GridCell>
          ))}
        </Grid>
      )}
      {fileSourcesTotal > 3 && (
        <Typography component="p" className={styles.hintTotal}>
          {$t({
            id: 'datasetVersion.widget.resourceSummary.total',
            defaultMessage: '3 out of {total} resources displayed. To see more go to {resource}',
            values: {
              total: fileSourcesTotal,
              resource: (
                <Typography
                  className={styles.hintLink}
                  component={Link}
                  to={buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: resourceId })}
                  variant="callout"
                  color={'primary'}
                  link
                >
                  <Icon icon={DatasetVersionIcon} size={16} className={styles.icon} />
                  <Typography className={styles.datasetVersionLink}>
                    {name} <VersionBadge versionNumber={versionNumber} />
                  </Typography>
                </Typography>
              ),
            },
          })}
        </Typography>
      )}
      {fileSourcesTotal > 0 && (
        <Grid className={styles.grid}>
          {fileSources?.map((resource) => (
            <GridCell key={resource.id}>
              <ResourceCard resource={resource} />
            </GridCell>
          ))}
        </Grid>
      )}
      {dbSourcesTotal === 0 && fileSourcesTotal === 0 && (
        <EmptySection
          message={$t({
            id: 'datasetResources.empty.message',
            defaultMessage: 'To attach a Resource, you will need to use the Vectice API.',
          })}
        />
      )}
    </WithLoading>
  );
};
