import { useQuery } from '@apollo/client';
import React from 'react';

import { graphql } from '../../../gql';
import { message, TypographyVariants } from '../../../ui';

import { ReportLink } from './ReportLink';

interface ReportLinkWithoutNameProps {
  resourceId: number;
  iconSize?: number;
  variant?: TypographyVariants;
}

const GET_REPORT = graphql(`
  query getReportLinkInfo($reportId: Float!) {
    CDTReport(id: $reportId) {
      name
    }
  }
`);

export const ReportLinkWithoutName = ({ resourceId }: ReportLinkWithoutNameProps) => {
  const { data } = useQuery(GET_REPORT, {
    fetchPolicy: 'cache-and-network',
    skip: !resourceId,
    variables: { reportId: resourceId! },
    onError: (error) => message.error(error.message),
  });

  const report = data?.CDTReport;

  if (!report) return null;
  return <ReportLink resourceId={resourceId} name={report.name} />;
};
