import React, { PropsWithChildren } from 'react';

import { GuardedContent } from './GuardedContent';
import { useWithPrivateWorkspace } from './useWithPrivateWorkspace';

export const WithPrivateWorkspace = ({ children }: PropsWithChildren<unknown>) => {
  const { allowed, loading } = useWithPrivateWorkspace();

  return (
    <GuardedContent allowed={allowed} loading={loading}>
      {children}
    </GuardedContent>
  );
};
