import React from 'react';

import { SearchInput } from '../form';

import styles from './FilterSearchInput.module.scss';

interface FilterSearchInputProps {
  placeholder?: string;
  value: string;
  onDebounceSearch?: (value: string) => void;
  onSearch?: (value: string) => void;
}

export const FilterSearchInput = ({ placeholder, value, onDebounceSearch, onSearch }: FilterSearchInputProps) => (
  <SearchInput
    aria-label="search"
    borderless
    className={styles.filterSearchInput}
    placeholder={placeholder}
    small
    value={value}
    onChange={onSearch ? (e) => onSearch(e.currentTarget.value) : undefined}
    onDebouncedChange={onDebounceSearch}
  />
);
