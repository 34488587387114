import React from 'react';

import { ReactComponent as TableIcon } from '../../assets/icons/editor/ic-table.svg';
import { ReactComponent as ExtractIcon } from '../../assets/icons/editor/ic-unmerge.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/interface/ic-download.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/interface/ic-edit.svg';
import { AttachmentOutput, AttachmentTypeEnum } from '../../gql/graphql';
import { useWithFeatureFlags } from '../../guards';
import { Button, FlexContainer, Icon, LazyImage, Menu, MenuItem, MoreActions, Typography } from '../../ui';
import { getEntityFilePreviewURL, getMimeTypeIcon } from '../../utils';

import styles from './PreviewTile.module.scss';

interface PreviewTileProps {
  attachment: Pick<AttachmentOutput, 'id' | 'name' | 'mimeType' | 'hasPreview' | 'attachmentType'>;
  onClick: () => void;
  onExtract?: () => void;
  onRename?: () => void;
  onDelete?: () => void;
  onDownload: () => void;
}

export const PreviewTile = ({
  attachment: { id, name, mimeType, hasPreview, attachmentType },
  onClick,
  onExtract,
  onRename,
  onDelete,
  onDownload,
}: PreviewTileProps) => {
  const { allowed: dragDropAttachment } = useWithFeatureFlags({
    featureFlag: 'allow-drag-and-drop-attachment-editor',
  });
  const icon = attachmentType === AttachmentTypeEnum.EntityFile ? getMimeTypeIcon(mimeType) : TableIcon;
  const fallback = <Icon icon={icon} size={50} />;

  return (
    <FlexContainer gap={0} direction="column" className={styles.previewTile} onClick={onClick}>
      <FlexContainer className={styles.previewImage} justify="center">
        <LazyImage
          errorFallback={fallback}
          fallback={fallback}
          draggable={dragDropAttachment ? 'false' : 'true'}
          src={hasPreview ? getEntityFilePreviewURL({ fileId: id }) : undefined}
        />
      </FlexContainer>

      <FlexContainer className={styles.controls} gap={4}>
        {attachmentType === AttachmentTypeEnum.EntityFile && (
          <Button
            className={styles.button}
            leftIcon={DownloadIcon}
            variant="white"
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              onDownload();
            }}
          />
        )}
        {(onExtract || onRename || onDelete) && (
          <MoreActions
            className={styles.button}
            variant="white"
            color="gray"
            size="xs"
            dropdownProps={{ onOverlayClick: (e: Event) => e.stopPropagation() }}
            moreActionsMenu={
              <Menu>
                {onExtract && (
                  <MenuItem
                    leftIcon={ExtractIcon}
                    onClick={() => {
                      onExtract();
                    }}
                  >
                    {$t({ id: 'attachment.extract', defaultMessage: 'Extract data' })}
                  </MenuItem>
                )}
                {onRename && (
                  <MenuItem
                    leftIcon={PencilIcon}
                    onClick={() => {
                      onRename();
                    }}
                  >
                    {$t({ id: 'attachment.rename', defaultMessage: 'Rename' })}
                  </MenuItem>
                )}
                {onDelete && (
                  <MenuItem
                    leftIcon={DeleteIcon}
                    onClick={() => {
                      onDelete();
                    }}
                  >
                    {$t({ id: 'menuItem.delete', defaultMessage: 'Delete' })}
                  </MenuItem>
                )}
              </Menu>
            }
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </FlexContainer>

      <FlexContainer gap={4} className={styles.fileInfo}>
        <Icon icon={icon} size={15} />
        <Typography variant="footnote" ellipsis title={name}>
          {name}
        </Typography>
      </FlexContainer>
    </FlexContainer>
  );
};
