import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MoveIcon } from '../../assets/icons/interface/ic-leave.svg';
import { useAuthentication } from '../../context';
import { Workspace } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { ModalPrompt, Typography, message } from '../../ui';

import { ActionCard } from './internals';
import { LEAVE_WORKSPACE } from './leaveWorkspace.mutation';

interface Props {
  workspace: Workspace;
}

export const LeaveWorkspace = ({ workspace }: Props) => {
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);

  const leaveWorkspaceWithNameLabel = $t({
    id: 'component.action.leaveWorkspace.leaveWorkspaceWithNameLabel',
    defaultMessage: 'Leave Workspace {workspaceName}',
    values: { workspaceName: workspace.name },
  });

  const { user } = useAuthentication();

  const [leaveWorkspace] = useMutation(LEAVE_WORKSPACE, {
    variables: { id: workspace.vecticeId },
    optimisticResponse: () => ({
      leaveWorkspace: true,
    }),
    update: (cache, { data }) => {
      if (data?.leaveWorkspace) {
        setShowPrompt(false);
        flushFromGraphQLCache(cache, { vecticeId: workspace.vecticeId, __typename: 'Workspace' });
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'getUserWorkspaceAccessibility',
          args: { userId: Number(user.id), workspaceId: workspace.vecticeId },
        });
      }
    },
    onCompleted: (data) => {
      if (data.leaveWorkspace) {
        message.success(
          $t({
            id: 'component.action.leaveWorkspace.successMessage',
            defaultMessage: 'You have left the workspace "{workspaceName}".',
            values: {
              workspaceName: workspace.name,
            },
          }),
        );
        navigate({
          pathname: buildLink(VecticeRoutes.WORKSPACES),
        });
      }
    },
    onError: (error) => {
      if (error.graphQLErrors?.[0]?.extensions && error.graphQLErrors[0].extensions.key === 'last_workspace_manager') {
        message.error(
          $t({
            id: 'component.action.leaveWorkspace.errorMessage',
            defaultMessage: `To leave this workspace, you must assign another member to the workspace manager role.`,
          }),
        );
      } else {
        message.error(error.message);
      }
    },
  });

  return (
    <>
      <ActionCard
        buttonIcon={MoveIcon}
        buttonLabel={$t({
          id: 'component.action.leaveWorkspace.leaveWorkspaceLabel',
          defaultMessage: 'Leave Workspace',
        })}
        hint={$t({
          id: 'component.action.leaveWorkspace.leaveWorkspaceHint',
          defaultMessage:
            'If you leave this workspace you will no longer be able to access it. The data you contributed to this workspace will still be available.',
        })}
        title={leaveWorkspaceWithNameLabel}
        onClick={() => setShowPrompt(true)}
      />
      {showPrompt && (
        <ModalPrompt
          title={leaveWorkspaceWithNameLabel}
          confirmLabel={$t({ id: 'component.action.leaveWorkspace.leave', defaultMessage: 'Leave' })}
          cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
          onConfirm={leaveWorkspace}
          onClose={() => setShowPrompt(false)}
        >
          <Typography paragraph color="primary">
            {$t({
              id: 'component.action.leaveWorkspace.leaveWorkspaceConfirmation',
              defaultMessage: 'Are you sure you want to leave the workspace <bold>{workspaceName}</bold>?',
              values: {
                workspaceName: workspace.name,
              },
            })}
          </Typography>
        </ModalPrompt>
      )}
    </>
  );
};
