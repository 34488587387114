import React, { ElementType } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import type { TypographyColors, TypographyVariants } from '../typography';

import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './AssetLink.module.scss';

export interface AssetLinkProps {
  name?: string | null;
  icon?: ElementType;
  variant?: TypographyVariants;
  iconSize?: number;
  color?: TypographyColors;
  ellipsis?: boolean;
  target?: string;
  to?: LinkProps['to'];
}

export const AssetLink = ({
  name = 'Unknown',
  to,
  icon,
  variant = 'callout',
  iconSize = 16,
  target,
  ...props
}: AssetLinkProps) => (
  <Typography
    component={to ? Link : 'span'}
    to={to}
    variant={variant}
    color={to ? 'accent' : 'tertiary'}
    link={!!to}
    target={target}
    {...props}
  >
    {icon && <Icon aria-hidden aria-label="" icon={icon} size={iconSize} className={styles.icon} />}
    &nbsp;
    {name}
  </Typography>
);
