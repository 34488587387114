import React, { Suspense } from 'react';

import type { LightboxProps } from '../../ui';

import { ReactComponent as FileImageIcon } from '../../assets/icons/files/ic-image.svg';
import { FlexContainer, Icon, Lightbox, Loading, Typography } from '../../ui';
import { downloadFile } from '../../utils';

import ImagePreview from './image';

interface LightboxPreviewProps extends Omit<LightboxProps, 'content' | 'title' | 'onDownload'> {
  alt?: string;
  src: string;
}

export const LightboxImage = ({ src, alt, ...props }: LightboxPreviewProps) => {
  const lightboxProps = {
    onDownload: () => downloadFile(src, alt),
    fullscreen: true,
  };

  return (
    <Lightbox
      aria-label="File preview"
      content={
        <Suspense fallback={<Loading color="white" />}>
          <ImagePreview url={src} />
        </Suspense>
      }
      title={
        <FlexContainer gap={4}>
          <Icon icon={FileImageIcon} size={34} />
          <Typography ellipsis component="div" variant="heading2" color="white">
            {alt ??
              $t({
                id: 'preview.image.placeholder',
                defaultMessage: 'Image',
              })}
          </Typography>
        </FlexContainer>
      }
      {...lightboxProps}
      {...props}
    />
  );
};
