import type { RegisterOptions } from 'react-hook-form';

import config from '../config';

export const defaultValueFieldConfiguration = (): Pick<RegisterOptions, 'required' | 'maxLength'> => ({
  required: $t({ id: 'common.requiredValue', defaultMessage: 'The value is required' }),
  maxLength: {
    value: config.MAXIMUM_VALUE_LENGTH,
    message: $t({
      id: 'common.fieldMaxLength',
      defaultMessage: 'This field cannot exceed {max} characters',
      values: {
        max: config.MAXIMUM_VALUE_LENGTH,
      },
    }),
  },
});

export const defaultDescriptionFieldConfiguration = (): Pick<RegisterOptions, 'maxLength'> => ({
  maxLength: {
    value: config.MAXIMUM_DESCRIPTION_LENGTH,
    message: $t({
      id: 'common.fieldMaxLength',
      defaultMessage: 'This field cannot exceed {max} characters',
      values: {
        max: config.MAXIMUM_DESCRIPTION_LENGTH,
      },
    }),
  },
});

export const defaultPromptFieldConfiguration = (): Pick<RegisterOptions, 'maxLength'> => ({
  maxLength: {
    value: config.MAXIMUM_PROMPT_LENGTH,
    message: $t({
      id: 'common.promptMaxLength',
      defaultMessage: 'The prompt cannot exceed {max} characters',
      values: {
        max: config.MAXIMUM_PROMPT_LENGTH,
      },
    }),
  },
});

export const defaultNameFieldConfiguration = (): Pick<RegisterOptions, 'required' | 'maxLength'> => ({
  required: $t({ id: 'common.requiredName', defaultMessage: 'The name is required' }),
  maxLength: {
    value: config.MAXIMUM_NAME_LENGTH,
    message: $t({
      id: 'common.fieldMaxLength',
      defaultMessage: 'This field cannot exceed {max} characters',
      values: {
        max: config.MAXIMUM_NAME_LENGTH,
      },
    }),
  },
});

export const defaultNumberFieldConfiguration = (): Pick<RegisterOptions, 'required' | 'maxLength'> => ({
  maxLength: {
    value: config.MAXIMUM_ID_LENGTH,
    message: $t({
      id: 'common.fieldMaxLength',
      defaultMessage: 'This field cannot exceed {max} characters',
      values: {
        max: config.MAXIMUM_ID_LENGTH,
      },
    }),
  },
});
