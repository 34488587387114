import React from 'react';

import EmptyPhasesIllustration from '../../assets/graphics/documentation/empty-phases.svg';
import { ReactComponent as CreateIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { Button, EmptyList } from '../../ui';

interface NoPhasesProps {
  borderless?: boolean;
  onCreate?: () => void;
}

export const NoPhases = ({ borderless, onCreate }: NoPhasesProps) => {
  const { allowed: canCreatePhase } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });

  return (
    <EmptyList
      graphic={EmptyPhasesIllustration}
      iconSize={200}
      borderless={borderless}
      message={$t({
        id: 'project.phases.empty.message',
        defaultMessage: 'No Phases yet!',
      })}
      hint={
        <>
          {$t({
            id: 'project.phases.empty.hint1',
            defaultMessage: 'Get started on your Project by creating a Phase. Phases enable you to organize ',
          })}
          <br />
          {$t({
            id: 'project.phases.empty.hint2',
            defaultMessage: 'Projects, enforce best practices, and capture knowledge from Iterations. ',
          })}
        </>
      }
      actionButton={
        canCreatePhase &&
        onCreate && (
          <Button leftIcon={CreateIcon} onClick={onCreate}>
            {$t({
              id: 'project.phases.empty.createPage',
              defaultMessage: 'Create Phase',
            })}
          </Button>
        )
      }
    />
  );
};
