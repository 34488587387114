import { graphql } from '../../gql';

export const UPDATE_ITERATION_STARRED_STATUS = graphql(`
  mutation updateIterationStarredStatus($id: VecticeId!, $starred: Boolean!) {
    updateIteration(id: $id, data: { starred: $starred }) {
      vecticeId
      starred
    }
  }
`);
