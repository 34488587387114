import React from 'react';

import { useFragment } from '../../../gql';
import { ModelVersionInformation, ModelVersionInformationFragment } from '../../../graphql/fragments';
import { TypographyVariants } from '../../../ui';

import { ModelVersionLink } from './ModelVersionLink';

interface ModelVersionInformationProps {
  modelVersion: ModelVersionInformation;
  variant?: TypographyVariants;
  iconSize?: number;
}

export const ModelVersionInformationLink = ({
  modelVersion: modelVersionFragment,
  variant,
  iconSize,
}: ModelVersionInformationProps) => {
  const modelVersion = useFragment(ModelVersionInformationFragment, modelVersionFragment);

  return (
    <ModelVersionLink
      resourceId={modelVersion.vecticeId}
      name={`${modelVersion.model.name} - v${modelVersion.versionNumber}`}
      variant={variant}
      iconSize={iconSize}
    />
  );
};
