import { ReactComponent as FindingIcon } from '../../assets/icons/interface/ic-finding.svg';
import { ReactComponent as InviteIcon } from '../../assets/icons/menu/ic-single-neutral-actions-add.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/menu/ic-single-neutral.svg';
import { ReactComponent as ReviewCommentIcon } from '../../assets/icons/specials/ic-comment.svg';
import { ReactComponent as DatasetVersionIcon } from '../../assets/icons/specials/ic-dataset-version.svg';
import { ReactComponent as DatasetIcon } from '../../assets/icons/specials/ic-dataset.svg';
import { ReactComponent as IterationIcon } from '../../assets/icons/specials/ic-iteration.svg';
import { ReactComponent as ModelVersionIcon } from '../../assets/icons/specials/ic-model-version.svg';
import { ReactComponent as ModelIcon } from '../../assets/icons/specials/ic-model.svg';
import { ReactComponent as PhaseIcon } from '../../assets/icons/specials/ic-phase.svg';
import { ReactComponent as ProjectIcon } from '../../assets/icons/specials/ic-project.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/specials/ic-report.svg';
import { ReactComponent as ReviewIcon } from '../../assets/icons/specials/ic-review.svg';
import { ReactComponent as WorkspaceIcon } from '../../assets/icons/specials/ic-workspace.svg';
import { ActivityTargetType } from '../../gql/graphql';

export const getActivityTargetTypeDisplayName = (type?: ActivityTargetType | string | null) => {
  switch (type) {
    case ActivityTargetType.Code:
      return $t({ id: 'activityType.code.label', defaultMessage: 'Code' });
    case ActivityTargetType.CodeVersion:
      return $t({ id: 'activityType.codeVersion.label', defaultMessage: 'Code Version' });
    case ActivityTargetType.DataResource:
      return $t({ id: 'activityType.dataResource.label', defaultMessage: 'Data Resource' });
    case ActivityTargetType.DataSet:
      return $t({ id: 'activityType.dataset.label', defaultMessage: 'Dataset' });
    case ActivityTargetType.DataSetVersion:
      return $t({ id: 'activityType.datasetVersion.label', defaultMessage: 'Dataset version' });
    case ActivityTargetType.Finding:
      return $t({ id: 'activityType.issue.label', defaultMessage: 'Issue' });
    case ActivityTargetType.Iteration:
      return $t({ id: 'activityType.iteration.label', defaultMessage: 'Iteration' });
    case ActivityTargetType.IterationStep:
      return $t({ id: 'activityType.iterationStep.label', defaultMessage: 'Iteration Step' });
    case ActivityTargetType.Model:
      return $t({ id: 'activityType.model.label', defaultMessage: 'Model' });
    case ActivityTargetType.ModelVersion:
      return $t({ id: 'activityType.modelVersion.label', defaultMessage: 'Model version' });
    case ActivityTargetType.Phase:
      return $t({ id: 'activityType.phase.label', defaultMessage: 'Phase' });
    case ActivityTargetType.Project:
      return $t({ id: 'activityType.project.label', defaultMessage: 'Project' });
    case ActivityTargetType.CdtReport:
      return $t({ id: 'activityType.report.label', defaultMessage: 'Report' });
    case ActivityTargetType.Invite:
      return $t({ id: 'activityType.invite.label', defaultMessage: 'Invite' });
    case ActivityTargetType.Review:
      return $t({ id: 'activityType.review.label', defaultMessage: 'Review' });
    case ActivityTargetType.ReviewComment:
      return $t({ id: 'activityType.reviewComment.label', defaultMessage: 'Review Comment' });
    case ActivityTargetType.StepDefinition:
      return $t({ id: 'activityType.stepDefinition.label', defaultMessage: 'Step Definition' });
    case ActivityTargetType.Workspace:
      return $t({ id: 'activityType.workspace.label', defaultMessage: 'Workspace' });
    case ActivityTargetType.WorkspaceUser:
      return $t({ id: 'activityType.workspaceUser.label', defaultMessage: 'Workspace Members' });
    default:
      return type ?? $t({ id: 'activityType.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getActivityTargetTypeOptions = () => [
  {
    icon: null,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Code),
    value: ActivityTargetType.Code,
  },
  {
    icon: null,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.CodeVersion),
    value: ActivityTargetType.CodeVersion,
  },
  {
    icon: null,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.DataResource),
    value: ActivityTargetType.DataResource,
  },
  {
    icon: DatasetIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.DataSet),
    value: ActivityTargetType.DataSet,
  },
  {
    icon: DatasetVersionIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.DataSetVersion),
    value: ActivityTargetType.DataSetVersion,
  },
  {
    icon: FindingIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Finding),
    value: ActivityTargetType.Finding,
  },
  {
    icon: IterationIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Iteration),
    value: ActivityTargetType.Iteration,
  },
  {
    icon: null,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.IterationStep),
    value: ActivityTargetType.IterationStep,
  },
  {
    icon: ModelIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Model),
    value: ActivityTargetType.Model,
  },
  {
    icon: ModelVersionIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.ModelVersion),
    value: ActivityTargetType.ModelVersion,
  },
  {
    icon: PhaseIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Phase),
    value: ActivityTargetType.Phase,
  },
  {
    icon: ProjectIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Project),
    value: ActivityTargetType.Project,
  },
  {
    icon: ReportIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.CdtReport),
    value: ActivityTargetType.CdtReport,
  },
  {
    icon: ReviewIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Review),
    value: ActivityTargetType.Review,
  },
  {
    icon: ReviewCommentIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.ReviewComment),
    value: ActivityTargetType.ReviewComment,
  },
  {
    icon: null,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.StepDefinition),
    value: ActivityTargetType.StepDefinition,
  },
  {
    icon: WorkspaceIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Workspace),
    value: ActivityTargetType.Workspace,
  },
  {
    icon: UserIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.WorkspaceUser),
    value: ActivityTargetType.WorkspaceUser,
  },
  {
    icon: InviteIcon,
    label: getActivityTargetTypeDisplayName(ActivityTargetType.Invite),
    value: ActivityTargetType.Invite,
  },
];
