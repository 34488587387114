import React, { useMemo } from 'react';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/ic-project.svg';
import { getWorkspaceIcon } from '../../../entities/utils';
import { useFragment } from '../../../gql';
import { ActivityTargetType, ActivityType, UserActivity } from '../../../gql/graphql';
import { useWithFeatureFlags } from '../../../guards';
import { buildLink, VecticeRoutes } from '../../../routes';
import { Breadcrumb, Breadcrumbs, Typography } from '../../../ui';
import { FormatDate } from '../../formatters';
import { UserActivityFragment } from '../fragments';
import { RestoreButton } from '../internals';

import { ActivityCreatorAvatar } from './ActivityCreatorAvatar';
import { DatasetActivity } from './DatasetActivity';
import { DatasetVersionActivity } from './DatasetVersionActivity';
import { FindingActivity } from './FindingActivity';
import { ActivityProps } from './interfaces';
import { InviteActivity } from './InviteActivity';
import { IterationActivity } from './IterationActivity';
import { ModelActivity } from './ModelActivity';
import { ModelVersionActivity } from './ModelVersionActivity';
import { PhaseActivity } from './PhaseActivity';
import { ProjectActivity } from './ProjectActivity';
import { ReportActivity } from './ReportActivity';
import { ReviewActivity } from './ReviewActivity';
import { ReviewCommentActivity } from './ReviewCommentActivity';
import { WorkspaceActivity } from './WorkspaceActivity';
import { WorkspaceUserActivity } from './WorkspaceUserActivity';

import styles from './Activity.module.scss';

export const Activity = ({
  activity: fragment,
  showBreadcrumbs,
  hideRestore,
}: ActivityProps & {
  showBreadcrumbs?: boolean;
  hideRestore?: boolean;
}) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const { allowed: findingActivities } = useWithFeatureFlags({ featureFlag: 'finding-activities' });

  const isRestorable = (activity: Pick<UserActivity, 'targetType' | 'type' | 'targetVecticeId'>) =>
    activity.targetType === ActivityTargetType.Phase &&
    activity.type === ActivityType.Deleted &&
    !!activity.targetVecticeId;

  const renderedActivity = useMemo(() => {
    switch (activity.targetType) {
      case ActivityTargetType.DataSet:
        return <DatasetActivity activity={fragment} />;
      case ActivityTargetType.DataSetVersion:
        return <DatasetVersionActivity activity={fragment} />;
      case ActivityTargetType.Model:
        return <ModelActivity activity={fragment} />;
      case ActivityTargetType.ModelVersion:
        return <ModelVersionActivity activity={fragment} />;
      case ActivityTargetType.Phase:
        return <PhaseActivity activity={fragment} />;
      case ActivityTargetType.Project:
        return <ProjectActivity activity={fragment} />;
      case ActivityTargetType.Iteration:
        return <IterationActivity activity={fragment} />;
      case ActivityTargetType.Review:
        return <ReviewActivity activity={fragment} />;
      case ActivityTargetType.ReviewComment:
        return <ReviewCommentActivity activity={fragment} />;
      case ActivityTargetType.Workspace:
        return <WorkspaceActivity activity={fragment} />;
      case ActivityTargetType.WorkspaceUser:
        return <WorkspaceUserActivity activity={fragment} />;
      case ActivityTargetType.CdtReport:
        return <ReportActivity activity={fragment} />;
      case ActivityTargetType.Invite:
        return <InviteActivity activity={fragment} />;
      case ActivityTargetType.Finding:
        return findingActivities ? <FindingActivity activity={fragment} /> : null;
      default:
        return null;
    }
  }, [activity]);
  const breadcrumb = useMemo(
    () =>
      activity.workspace ? (
        <Breadcrumbs>
          <Breadcrumb
            icon={getWorkspaceIcon(activity.workspace.type)}
            to={buildLink(VecticeRoutes.WORKSPACE, {
              workspaceId: activity.workspace.vecticeId,
            })}
          >
            {activity.workspace.name}
          </Breadcrumb>
          {activity.project && (
            <Breadcrumb
              icon={ProjectIcon}
              to={buildLink(VecticeRoutes.PROJECT, {
                projectId: activity.project.vecticeId,
              })}
            >
              {activity.project.name}
            </Breadcrumb>
          )}
        </Breadcrumbs>
      ) : null,
    [activity],
  );

  if (renderedActivity) {
    return (
      <li className={styles.activity}>
        <ActivityCreatorAvatar user={activity.isAutomated ? null : activity.actor} />
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <Typography paragraph variant="callout" color="secondary" className={styles.wording}>
              {renderedActivity}
            </Typography>
            {showBreadcrumbs && breadcrumb}
            <Typography variant="footnote" color="tertiary">
              <FormatDate fromNow date={activity.date} />
            </Typography>
          </div>
          {!hideRestore && isRestorable(activity) && <RestoreButton activity={fragment} />}
        </div>
      </li>
    );
  }

  return null;
};
