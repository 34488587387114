import { ElementType } from 'react';

import { IterationStepArtifact, IterationStepArtifactType } from '../gql/graphql';

import { getMimeTypeIcon } from './icons';
import { getVecticeResourceTypeLabel, VecticeResourceType, getVecticeResourceTypeIcon } from './vectice-resource-type';

const defaultInfo = {
  text: () => $t({ id: 'iterationStepArtifact.default.message', defaultMessage: 'Asset' }),
  icon: getMimeTypeIcon(),
};

export const assetInfoRecord: Record<IterationStepArtifactType, { text: () => string; icon: ElementType }> = {
  [IterationStepArtifactType.DataSetVersion]: {
    text: () => getVecticeResourceTypeLabel(VecticeResourceType.DATASET_VERSION),
    icon: getVecticeResourceTypeIcon(VecticeResourceType.DATASET_VERSION),
  },
  [IterationStepArtifactType.ModelVersion]: {
    text: () => getVecticeResourceTypeLabel(VecticeResourceType.MODEL_VERSION),
    icon: getVecticeResourceTypeIcon(VecticeResourceType.MODEL_VERSION),
  },
  [IterationStepArtifactType.EntityFile]: {
    text: () => $t({ id: 'iterationStepArtifact.file', defaultMessage: 'File' }),
    icon: getMimeTypeIcon(),
  },
  [IterationStepArtifactType.EntityMetadata]: defaultInfo,
  [IterationStepArtifactType.Comment]: defaultInfo,
};

type IterationStepArtifactPicked = Pick<
  IterationStepArtifact,
  'id' | 'datasetVersion' | 'modelVersion' | 'entityFile' | 'entityMetadata' | 'text' | 'type'
>;

export const isArtifactDeletedRecord: Record<
  IterationStepArtifactType,
  (artifact: IterationStepArtifactPicked) => boolean
> = {
  [IterationStepArtifactType.DataSetVersion]: (artifact) => !artifact.datasetVersion,
  [IterationStepArtifactType.ModelVersion]: (artifact) => !artifact.modelVersion,
  [IterationStepArtifactType.EntityFile]: (artifact) => !artifact.entityFile,
  [IterationStepArtifactType.EntityMetadata]: (artifact) => !artifact.entityMetadata,
  [IterationStepArtifactType.Comment]: (artifact) => !artifact.text,
};
