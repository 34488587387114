import cn from 'classnames';
import React from 'react';

import { Typography } from '../../ui';

import styles from './Banner.module.scss';

export const Banner = ({ children, className }: VecticeHTMLProps<any>) => (
  <Typography component="div" className={cn(styles.banner, className)}>
    {children}
  </Typography>
);
