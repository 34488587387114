import { useQuery } from '@apollo/client';
import React from 'react';

import NoIterationsGraphic from '../../../assets/graphics/documentation/empty-iterations.svg';
import { graphql } from '../../../gql';
import { Button, Card, FlexContainer, Graphic, List, Typography } from '../../../ui';
import { IterationListItem } from '../../asset-display';
import { DocumentationLink } from '../../links';

import { pageSize } from './config';

import styles from './HomeTabs.module.scss';

export const GET_USER_ITERATIONS = graphql(`
  query getUserIterations($afterCursor: String, $pageSize: Int!) {
    getIterationList(
      filters: { onlyMine: true }
      order: { field: "updatedDate", direction: DESC }
      page: { size: $pageSize, afterCursor: $afterCursor }
    ) {
      items {
        vecticeId
        ...iterationListItem
        phase {
          vecticeId
          parent {
            vecticeId
            name
            workspace {
              vecticeId
              type
              name
            }
          }
        }
      }
      page {
        afterCursor
        hasNextPage
      }
    }
  }
`);

const EmptyIterationsTab = () => (
  <Card inert className={styles.emptyCard}>
    <FlexContainer direction="column" align="center" gap={8} className={styles.emptyContent}>
      <Graphic src={NoIterationsGraphic} size={200} />
      <Typography color="primary" weight="semi-bold">
        {$t({
          id: 'home.tabs.iterations.empty.label',
          defaultMessage: 'No Iterations yet!',
        })}
      </Typography>
      <Typography variant="callout" color="secondary" align="center" paragraph>
        {$t({
          id: 'home.tabs.iterations.empty.description',
          defaultMessage:
            'Iterations help you to tune, develop, and test phases of your data science work until a fully functional algorithm is ready for deployment. Use the Vectice API to create your first Iteration.',
        })}
        <br />
        <DocumentationLink asTypography>
          {$t({
            id: 'home.tabs.iterations.empty.readOurDocs',
            defaultMessage: 'Read our docs to learn more.',
          })}
        </DocumentationLink>
      </Typography>
    </FlexContainer>
  </Card>
);

export const IterationsTab = () => {
  const { data, loading, fetchMore } = useQuery(GET_USER_ITERATIONS, {
    fetchPolicy: 'network-only',
    variables: { pageSize },
  });

  const iterations = data?.getIterationList.items;
  const { hasNextPage, afterCursor } = data?.getIterationList.page ?? {};

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  };

  return (
    <>
      <List
        collapsed
        empty={iterations?.length === 0}
        emptyText={<EmptyIterationsTab />}
        loading={loading}
        gutterBottom
      >
        {iterations?.map((iteration) => (
          <IterationListItem
            key={iteration.vecticeId}
            iteration={iteration}
            project={iteration.phase.parent}
            workspace={iteration.phase.parent.workspace}
          />
        ))}
      </List>
      {hasNextPage && (
        <Button variant="outlined" size="sm" onClick={handleShowMore}>
          {$t({ id: 'button.showMore', defaultMessage: 'Show More' })}
        </Button>
      )}
    </>
  );
};
