import { graphql } from '../../gql';

export const UPDATE_STEP_POSITION = graphql(`
  mutation updateStepPosition($id: Float!, $updateModel: BaseStepUpdateInput!) {
    updateStepDefinition(id: $id, updateModel: $updateModel) {
      id
      index
    }
  }
`);
