import { useCallback, useMemo } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

export const useQueryParam = <T = string>(
  key: string,
): [T | undefined, (value: T, options?: NavigateOptions) => void] => {
  const [params, setParams] = useSearchParams();

  const value = useMemo(() => {
    const param = params.get(key);
    return param ? (param as T) : undefined;
  }, [params]);

  const setValue = useCallback(
    (newValue: any, options?: NavigateOptions) => {
      const newParams = new URLSearchParams(params);
      if (newValue) {
        newParams.set(key, newValue);
      } else {
        newParams.delete(key);
      }
      setParams(newParams, options);
    },
    [params, setParams],
  );

  return [value, setValue];
};
