import { CodeLineageUpdateInput, CodeVersionType, LineageType } from '../../../gql/graphql';

export interface EditLineageModalProps {
  id: string;
  inputs: string[];
  lineageId?: number;
  parentId: string;
  projectId: string;
  type: LineageType;
  onClose: () => void;
}

export enum EditLineageTabs {
  inputs,
  code,
  file,
}

export interface LineageFormData {
  variables: { vecticeId: string }[];
  codeLineageInput?: CodeLineageUpdateInput | null;
}

export const inputsLabel: Record<
  CodeVersionType,
  {
    url: () => string;
    firstAdditionalInformation: () => string;
    secondAdditionalInformation: () => string;
    thirdAdditionalInformation: () => string;
  }
> = {
  [CodeVersionType.Databricks]: {
    url: () =>
      $t({
        id: 'editLineageModal.origin.edit.databricks.url',
        defaultMessage: 'URL notebook',
      }),
    firstAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.databricks.firstAdditionalInformation',
        defaultMessage: 'Relative path',
      }),
    secondAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.databricks.secondAdditionalInformation',
        defaultMessage: 'Timestamp',
      }),
    thirdAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.databricks.thirdAdditionalInformation',
        defaultMessage: 'Additional info',
      }),
  },
  [CodeVersionType.Git]: {
    url: () =>
      $t({
        id: 'editLineageModal.origin.edit.git.url',
        defaultMessage: 'Repository URL',
      }),
    firstAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.git.firstAdditionalInformation',
        defaultMessage: 'Branch name',
      }),
    secondAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.git.secondAdditionalInformation',
        defaultMessage: 'Commit hash',
      }),
    thirdAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.git.thirdAdditionalInformation',
        defaultMessage: 'Additional info',
      }),
  },
  [CodeVersionType.Other]: {
    url: () =>
      $t({
        id: 'editLineageModal.origin.edit.other.url',
        defaultMessage: 'URL name',
      }),
    firstAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.other.firstAdditionalInformation',
        defaultMessage: 'First additional info',
      }),
    secondAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.other.secondAdditionalInformation',
        defaultMessage: 'Second additional info',
      }),
    thirdAdditionalInformation: () =>
      $t({
        id: 'editLineageModal.origin.edit.other.thirdAdditionalInformation',
        defaultMessage: 'Third additional info',
      }),
  },
};
