import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { useAuthentication } from '../../context';
import { OrderDirection, User, Workspace, WorkspaceType } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { GET_USER_LIST, GET_WORKSPACE_MEMBER_LIST } from '../../graphql/queries';
import { useVecticeForm } from '../../hooks';
import { ComboBox, ModalForm, Typography } from '../../ui';
import { reorderMemberList } from '../../utils';
import { UserAvatar } from '../asset-display';
import { FormatUserName } from '../formatters';

import styles from './TransferProjectOwnershipModal.module.scss';

interface FormData {
  owner: (Pick<User, 'id'> & UserIdentity) | null;
}

interface TransferProjectOwnershipModalProps {
  currentOwnerId: number;
  workspace?: Pick<Workspace, 'vecticeId' | 'name' | 'type'> | null;
  onClose: () => void;
  onTransfer: (newOwner: Pick<User, 'id'> & UserIdentity) => Promise<void>;
}

export const TransferProjectOwnershipModal = ({
  currentOwnerId,
  workspace,
  onClose,
  onTransfer,
}: TransferProjectOwnershipModalProps) => {
  const [search, setSearch] = useState<string | null>(null);
  const { user: connectedUser } = useAuthentication();

  const { data: workspaceMembersData } = useQuery(GET_WORKSPACE_MEMBER_LIST, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    skip: !workspace?.vecticeId || workspace?.type === WorkspaceType.Public,
    variables: {
      workspaceId: workspace?.vecticeId!,
      order: { field: 'name', direction: OrderDirection.Asc },
      filters: {
        searchFilter: search
          ? {
              fields: ['name', 'email'],
              search: search,
            }
          : null,
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const { data: userListData } = useQuery(GET_USER_LIST, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    skip: workspace?.type !== WorkspaceType.Public,
    variables: {
      filters: {
        search,
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const members =
    workspaceMembersData?.getWorkspaceMemberList?.items ?? userListData?.getOrganizationUsersForUser ?? [];
  const membersArray = useMemo(() => reorderMemberList(members, connectedUser, !!search), [members]);

  const newOptions = useMemo(
    () =>
      membersArray.map((member) => ({
        label: member.name,
        user: member,
        disabled: +member.id === currentOwnerId,
      })),
    [membersArray],
  );

  const { formState, preSubmit, registerWithErrors, setValue } = useVecticeForm<FormData>({
    mode: 'onChange',
  });
  const { hasErrors, isSubmitting } = formState;

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    if (formData.owner) {
      await onTransfer(formData.owner);
    }
  };

  return (
    <ModalForm
      disabled={hasErrors}
      isSubmitting={isSubmitting}
      title={$t({ id: 'modal.transferProjectOwnership.title', defaultMessage: 'Transfer Ownership' })}
      submitLabel={$t({ id: 'modal.transferProjectOwnership.submit', defaultMessage: 'Transfer' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(onSubmit)}
      onClose={onClose}
      autoComplete="off"
    >
      <Typography paragraph variant="callout" gutterBottom>
        {workspace?.type === WorkspaceType.Public
          ? $t({
              id: 'modal.transferProjectOwnership.publicWorkspace.warning',
              defaultMessage:
                'Are you sure you want to transfer project ownership? You can not undo this action unless you are a admin.',
            })
          : $t({
              id: 'modal.transferProjectOwnership.privateWorkspace.warning',
              defaultMessage:
                'Are you sure you want to transfer project ownership? You can not undo this action unless you are a workspace manager.',
            })}
      </Typography>
      <ComboBox
        {...registerWithErrors(`owner`, {
          required: $t({ id: 'forms.transferProjectOwnership.required', defaultMessage: 'The value is required' }),
        })}
        label="New Owner"
        placeholder={$t({ id: 'modal.transferProjectOwnership.placeholder', defaultMessage: 'Select a new Owner' })}
        options={newOptions}
        onSearch={setSearch}
        onSelectOption={(option) =>
          setValue('owner', option?.user ?? null, { shouldValidate: true, shouldDirty: true })
        }
        renderFn={({ user, disabled }) => (
          <>
            <Typography variant="callout" ellipsis className={styles.option}>
              <UserAvatar hideTooltip size="xs" user={user} />
              <Typography variant="callout">
                <FormatUserName user={user} />
              </Typography>
            </Typography>
            {disabled && (
              <Typography variant="footnote" color="secondary" className={styles.disabledLabel}>
                {$t({ id: 'modal.transferProjectOwnership.currentOwner', defaultMessage: 'Current Owner' })}
              </Typography>
            )}
            {!disabled && user.id === connectedUser.id && (
              <Typography variant="footnote">
                {$t({ id: 'memberList.message.transferToMe', defaultMessage: '(Transfer to me)' })}
              </Typography>
            )}
          </>
        )}
      />
    </ModalForm>
  );
};
