import dayjs, { Dayjs } from 'dayjs';
import { PickerProps } from 'rc-picker';
import dayjsConfig from 'rc-picker/lib/generate/dayjs';
import React, { PropsWithChildren, useState } from 'react';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/interface/ic-calendar.svg';
import { ReactComponent as SuperNextIcon } from '../../../assets/icons/interface/ic-chevron-double-left.svg';
import { ReactComponent as SuperPrevIcon } from '../../../assets/icons/interface/ic-chevron-double-right.svg';
import { ReactComponent as PrevIcon } from '../../../assets/icons/interface/ic-chevron-left.svg';
import { ReactComponent as NextIcon } from '../../../assets/icons/interface/ic-chevron-right.svg';
import { Icon, Picker, Select, Typography } from '../../../ui';
import { CommonFilterProps } from '../types';

import styles from './CreatedDateFilterComponent.module.scss';

const modeOptions = [
  { id: 'createdOn', label: 'Created on', value: 'createdOn' },
  { id: 'dateRange', label: 'Date range', value: 'dateRange' },
];

export const commonDatePickerConfig = {
  generateConfig: dayjsConfig,
  picker: 'date' as PickerProps<Dayjs>['picker'],
  placeholder: 'Select date',
  showToday: true,
  suffixIcon: <Icon className={styles.picker_icon} icon={CalendarIcon} size={20} />,
  nextIcon: <Icon className={styles.picker_icon} icon={NextIcon} size={14} />,
  prevIcon: <Icon className={styles.picker_icon} icon={PrevIcon} size={14} />,
  superNextIcon: <Icon className={styles.picker_icon} icon={SuperPrevIcon} size={12} />,
  superPrevIcon: <Icon className={styles.picker_icon} icon={SuperNextIcon} size={12} />,
};

const DateLabel = ({ children }: PropsWithChildren<unknown>) => (
  <Typography variant="footnote" color="secondary" className={styles.date_label}>
    {children}
  </Typography>
);

// TODO should be renamed to DateFilterComponent
interface CreatedDateFilterProps extends CommonFilterProps {
  disableDateAfterToday?: boolean;
}

export const CreatedDateFilterComponent = ({
  filter,
  onFilterUpdate,
  options,
  disableDateAfterToday = true,
}: CreatedDateFilterProps) => {
  const [mode, setMode] = useState<'createdOn' | 'dateRange'>(() => {
    if (filter && dayjs(filter.start).isSame(filter.end, 'day')) return 'createdOn';
    return 'dateRange';
  });

  const onChangeDate = (date: Dayjs | null, type?: 'start' | 'end') => {
    const dayjsDate = dayjs(date);
    if (!type) {
      onFilterUpdate({ start: dayjsDate.startOf('day').toISOString(), end: dayjsDate.endOf('day').toISOString() });
    } else if (type === 'start') {
      onFilterUpdate({ ...filter, start: dayjsDate.startOf('day').toISOString() });
    } else if (type === 'end') {
      onFilterUpdate({ ...filter, end: dayjsDate.endOf('day').toISOString() });
    }
  };

  return (
    <>
      <Select
        aria-label="Mode selection"
        options={options ?? modeOptions}
        value={mode}
        onChange={(e) => setMode(e.currentTarget.value as 'createdOn' | 'dateRange')}
      />
      {mode === 'createdOn' && (
        <>
          <DateLabel>Date</DateLabel>
          <Picker<Dayjs>
            {...commonDatePickerConfig}
            disabledDate={(date) => date.isAfter(undefined)}
            value={filter?.start ? dayjs(filter.start) : undefined}
            onChange={(date) => onChangeDate(date)}
          />
        </>
      )}
      {mode === 'dateRange' && (
        <>
          <DateLabel>From</DateLabel>
          <Picker<Dayjs>
            {...commonDatePickerConfig}
            disabledDate={(date) =>
              (filter?.end && date.isAfter(filter.end)) || (disableDateAfterToday && date.isAfter(undefined))
            }
            value={filter?.start ? dayjs(filter.start) : undefined}
            onChange={(date) => onChangeDate(date, 'start')}
          />
          <DateLabel>To</DateLabel>
          <Picker<Dayjs>
            {...commonDatePickerConfig}
            disabledDate={(date) =>
              (filter?.start && date.isBefore(filter.start)) || (disableDateAfterToday && date.isAfter(undefined))
            }
            value={filter?.end ? dayjs(filter.end) : undefined}
            onChange={(date) => onChangeDate(date, 'end')}
          />
        </>
      )}
    </>
  );
};
