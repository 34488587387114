import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

export const NoIterationsForModel = () => (
  <EmptyList
    icon={VecticeIcon}
    message={$t({ id: 'NoIterationsForModel.noIterations', defaultMessage: 'No iterations yet' })}
    hint={
      <>
        {$t({
          id: 'NoIterationsForModel.noIterations.hint',
          defaultMessage: 'All iterations linked to this model version will be visible here.',
        })}
      </>
    }
  />
);
