import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as PhaseIcon } from '../../../assets/icons/specials/flat/ic-phase.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { DocumentationPageStatusBadge, ReviewBadge } from '../../badges';
import { FormatUserName } from '../../formatters';

export const GET_PHASE_TOOLTIP = graphql(`
  query getPhaseTooltip($id: VecticeId!) {
    getPhaseById(id: $id) {
      vecticeId
      name
      status
      activeReview {
        vecticeId
        status
      }
      iterationsCount {
        total
      }
      owner {
        ...userFields
      }
    }
  }
`);

interface PhaseTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const PhaseTooltip = ({ id, placement = 'top', children }: PhaseTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`phase-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_PHASE_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      id: id!,
    },
  });

  const phase = data?.getPhaseById;

  useEffect(() => {
    setDataLoaded(!!phase || !!error);
  }, [phase, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={PhaseIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.PHASE)}
      label={phase?.name}
      deleted={!!error}
    >
      {phase && (
        <DataList>
          <Typography component="dt" variant="callout" color="white">
            Status
          </Typography>
          <DocumentationPageStatusBadge contrast status={phase.status} />
          <Typography component="dt" variant="callout" color="white">
            Reviews
          </Typography>
          <ReviewBadge contrast status={phase.activeReview?.status} />
          <Typography id={`owner-${ariaId}`} component="dt" variant="callout" color="white">
            Owner
          </Typography>
          <Typography aria-labelledby={`owner-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            {phase.owner ? (
              <FormatUserName user={phase.owner} />
            ) : (
              $t({ id: 'phase.ownerName.unassigned', defaultMessage: 'Unassigned' })
            )}
          </Typography>
          <Typography id={`iteration-${ariaId}`} component="dt" variant="callout" color="white">
            Iterations
          </Typography>
          <Typography aria-labelledby={`iteration-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            {phase.iterationsCount.total}
          </Typography>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
