import { graphql } from '../../gql';

export const THREADS_UNRESOLVED_COUNT = graphql(`
  query threadsUnresolvedCount($id: Float!, $pageSize: Int!) {
    threads(
      filters: { fileId: $id, status: UNRESOLVED }
      page: { size: $pageSize }
      order: { field: "createdDate", direction: DESC }
    ) {
      items {
        id
      }
      total
    }
  }
`);
