import React from 'react';

import { useFragment } from '../../../gql';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const InviteActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const user = <ActivityCreator user={activity.actor} isAutomated={false} />;

  return (
    <>
      {$t({
        id: 'activities.invite.created',
        defaultMessage: '{user} invited <bold>{toValue}</bold>',
        values: {
          user,
          toValue: activity.toValue,
        },
      })}
    </>
  );
};
