import { useMutation } from '@apollo/client';
import React from 'react';

import config from '../../config';
import { User } from '../../gql/graphql';
import { UPDATE_USER } from '../../graphql/mutations';
import { useVecticeForm } from '../../hooks';
import { Button, Form, Input, message, TextArea } from '../../ui';
import { defaultNameFieldConfiguration } from '../../utils';

import styles from './ProfileForm.module.scss';

interface ProfileFormProps {
  user: Pick<User, 'name' | 'about'>;
  onSave?: () => void;
}

type ProfileFormData = {
  userName: string;
  about?: string | null;
  thumbnail?: string;
};

export const ProfileForm = ({ user, onSave }: ProfileFormProps) => {
  const {
    preSubmit,
    registerWithErrors,
    watch,
    reset,
    formState: { isDirty, hasErrors, isSubmitting },
  } = useVecticeForm({
    mode: 'onChange',
    defaultValues: {
      userName: user.name,
      about: user.about,
      thumbnail: undefined,
    },
  });

  const nbCharsLeft = config.MAXIMUM_ABOUT_LENGTH - (watch('about') || '').length;

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data: { updateUser: User }) => {
      message.success($t({ id: 'profile.updateSuccess', defaultMessage: 'Your profile has been updated.' }));
      reset(data.updateUser);
      onSave?.();
    },
  });
  const onSubmit = async (values: ProfileFormData) => {
    const { userName, about, thumbnail } = values;
    await updateUser({
      variables: {
        data: {
          name: userName.trim(),
          about: about ? about.trim() : '',
          thumbnail,
        },
      },
    });
  };

  return (
    <Form onSubmit={preSubmit(onSubmit)}>
      <Input
        {...registerWithErrors('userName', {
          ...defaultNameFieldConfiguration(),
          required: $t({ id: 'profile.fullName.required', defaultMessage: 'The full name is required' }),
        })}
        label={$t({ id: 'profile.fullName', defaultMessage: 'Full Name' })}
        placeholder={$t({
          id: 'profile.fullName.placeholder',
          defaultMessage: 'Please enter your full name',
        })}
        gutterBottom
      />
      <TextArea
        {...registerWithErrors('about', {
          maxLength: {
            value: config.MAXIMUM_ABOUT_LENGTH,
            message: $t({
              id: 'common.fieldMaxLength',
              defaultMessage: 'This field cannot exceed {max} characters',
              values: {
                max: config.MAXIMUM_ABOUT_LENGTH,
              },
            }),
          },
        })}
        label={$t({ id: 'profile.aboutMe', defaultMessage: 'About Me' })}
        placeholder={$t({
          id: 'profile.aboutMe.placeholder',
          defaultMessage: 'Please share something about yourself to the team',
        })}
        help={$t({
          id: 'common.charactersLeft',
          defaultMessage: '{count, plural, one {# character left} other {# characters left}}',
          values: { count: nbCharsLeft },
        })}
        gutterBottom
      />
      <div className={styles.saveButton}>
        <Button type="submit" size="sm" variant="primary" disabled={!isDirty || hasErrors} loading={isSubmitting}>
          {$t({ id: 'button.save', defaultMessage: 'Save' })}
        </Button>
      </div>
    </Form>
  );
};
