import React from 'react';

import { getDatasetTypeDisplayName } from '../../entities/utils';
import { DatasetType } from '../../gql/graphql';
import { Badge, Icon } from '../../ui';
import { getDatasetTypeIcon } from '../../utils';

import styles from './DatasetTypeBadge.module.scss';

interface DatasetTypeBadgeProps {
  contrast?: boolean;
  type: DatasetType;
}

export const DatasetTypeBadge = ({ type, contrast }: DatasetTypeBadgeProps) => {
  const typeIcon = getDatasetTypeIcon(type);

  return (
    <Badge size="sm" contrast={contrast}>
      {typeIcon && <Icon className={styles.icon} icon={typeIcon} size={12} />}
      {getDatasetTypeDisplayName(type)}
    </Badge>
  );
};
