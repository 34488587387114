import React, { useEffect, useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { ContributorsFilterComponent, ContributorsFilterComponentProps } from '../ui/ContributorsFilterComponent';

export const pluginField = 'owner';

class OwnerFilterNode<T extends ContributorsFilterComponentProps> extends FilterNode<T> {
  updateProps(props?: T) {
    super.updateProps(props);
  }
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    if (this.props) {
      return (
        <ContributorsFilterComponent
          key={pluginField}
          {...this.props}
          filter={filter}
          onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
        />
      );
    }

    return null;
  }
}

type OwnerFilterProps = {
  label?: string;
};

export const OwnerFilter = ({ label, ...filterProps }: ContributorsFilterComponentProps & OwnerFilterProps) => {
  const { current: node } = useRef(new OwnerFilterNode<ContributorsFilterComponentProps>(filterProps));

  useRegisterPlugin({
    field: pluginField,
    label: label ?? $t({ id: 'filters.owner.label', defaultMessage: 'Owner' }),
    node,
  });

  useEffect(() => {
    node.updateProps(filterProps);
  }, [...Object.values(filterProps)]);

  return null;
};
