import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { graphql } from '../../../gql';
import { DatasetSourceUsage } from '../../../gql/graphql';

import { useDatasetSourceList } from './useDatasetSourceList';

export const GET_DATASET_VERSION_RESOURCES = graphql(`
  query getDatasetVersionResources($datasetVersionId: VecticeId!) {
    getDatasetVersion(datasetVersionId: $datasetVersionId) {
      vecticeId
      targetColumn
      datasetSources {
        id
        type
        usage
        ...datasetSourceMenu
      }
    }
  }
`);

export const useDatasetSources = (datasetVersionId: string) => {
  const { data, loading } = useQuery(GET_DATASET_VERSION_RESOURCES, {
    variables: { datasetVersionId },
  });

  const rawDatasetSources = data?.getDatasetVersion?.datasetSources;
  const targetColumn = data?.getDatasetVersion.targetColumn;

  const datasetSources = useMemo(() => {
    if (!rawDatasetSources) {
      return {};
    }

    return {
      default: rawDatasetSources?.find((source) => source.usage === null),
      [DatasetSourceUsage.Testing]: rawDatasetSources?.find((source) => source.usage === DatasetSourceUsage.Testing),
      [DatasetSourceUsage.Training]: rawDatasetSources?.find((source) => source.usage === DatasetSourceUsage.Training),
      [DatasetSourceUsage.Validation]: rawDatasetSources?.find(
        (source) => source.usage === DatasetSourceUsage.Validation,
      ),
    };
  }, [rawDatasetSources]);

  const { loading: loadingDefaultResources, ...defaultResources } = useDatasetSourceList(datasetSources.default);
  const { loading: loadingTestingResources, ...testingResources } = useDatasetSourceList(
    datasetSources[DatasetSourceUsage.Testing],
  );
  const { loading: loadingTrainingResources, ...trainingResources } = useDatasetSourceList(
    datasetSources[DatasetSourceUsage.Training],
  );
  const { loading: loadingValidationResources, ...validationResources } = useDatasetSourceList(
    datasetSources[DatasetSourceUsage.Validation],
  );

  const loadingResources =
    loadingDefaultResources || loadingTestingResources || loadingTrainingResources || loadingValidationResources;
  const noResources =
    !defaultResources.sources &&
    !testingResources.sources &&
    !trainingResources.sources &&
    !validationResources.sources;

  return {
    loading: loading || loadingResources,
    targetColumn,
    noResources,
    datasetSources,
    defaultResources,
    testingResources,
    trainingResources,
    validationResources,
  };
};
