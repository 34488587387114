import { DatasetType } from '../../gql/graphql';
import { getDatasetTypeIcon } from '../../utils';

export const getDatasetTypeDisplayName = (type?: DatasetType | string | null) => {
  switch (type) {
    case DatasetType.Clean:
      return $t({ id: 'datasetType.clean.label', defaultMessage: 'Clean' });
    case DatasetType.Modeling:
      return $t({ id: 'datasetType.modeling.label', defaultMessage: 'Modeling' });
    case DatasetType.Origin:
      return $t({ id: 'datasetType.origin.label', defaultMessage: 'Origin' });
    case DatasetType.Validation:
      return $t({ id: 'datasetType.validation.label', defaultMessage: 'Validation' });
    case DatasetType.Training:
      return $t({ id: 'datasetType.training.label', defaultMessage: 'Training' });
    case DatasetType.Testing:
      return $t({ id: 'datasetType.testing.label', defaultMessage: 'Testing' });
    case DatasetType.Unknown:
      return $t({ id: 'datasetType.unknown.label', defaultMessage: 'Unknown' });
    default:
      return type ?? $t({ id: 'datasetType.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getDatasetTypeOptions = () => [
  {
    icon: getDatasetTypeIcon(DatasetType.Clean),
    label: getDatasetTypeDisplayName(DatasetType.Clean),
    value: DatasetType.Clean,
  },
  {
    icon: getDatasetTypeIcon(DatasetType.Modeling),
    label: getDatasetTypeDisplayName(DatasetType.Modeling),
    value: DatasetType.Modeling,
  },
  {
    icon: getDatasetTypeIcon(DatasetType.Origin),
    label: getDatasetTypeDisplayName(DatasetType.Origin),
    value: DatasetType.Origin,
  },
  {
    icon: getDatasetTypeIcon(DatasetType.Validation),
    label: getDatasetTypeDisplayName(DatasetType.Validation),
    value: DatasetType.Validation,
  },
  {
    icon: getDatasetTypeIcon(DatasetType.Training),
    label: getDatasetTypeDisplayName(DatasetType.Training),
    value: DatasetType.Training,
  },
  {
    icon: getDatasetTypeIcon(DatasetType.Testing),
    label: getDatasetTypeDisplayName(DatasetType.Testing),
    value: DatasetType.Testing,
  },
  {
    icon: getDatasetTypeIcon(DatasetType.Unknown),
    label: getDatasetTypeDisplayName(DatasetType.Unknown),
    value: DatasetType.Unknown,
  },
];
