import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ActivityType } from '../../gql/graphql';
import { defaultActivityTypes, useUserActivities } from '../../hooks';
import { Section } from '../../ui';
import {
  getActivityTargetByResourceType,
  getActivityTargetFilterForDatasetVersion,
  VecticeResourceType,
} from '../../utils';
import { ActivityList } from '../activities';

export const DatasetVersionActivities = () => {
  const { datasetVersionId } = useParams();
  const { current: datasetVersionIds } = useRef(datasetVersionId ? [datasetVersionId] : []);
  const { activities, hasNextPage, loading, handleShowMore } = useUserActivities({
    activityTypes: [
      ...defaultActivityTypes,
      ActivityType.Properties,
      ActivityType.Metrics,
      ActivityType.AttachmentFile,
    ],
    targetFilter: getActivityTargetFilterForDatasetVersion(datasetVersionIds),
    activityTargetTypes: getActivityTargetByResourceType(VecticeResourceType.DATASET_VERSION),
  });

  return (
    <Section label={$t({ id: 'DatasetVersionTabs.activity', defaultMessage: 'Dataset version activity' })}>
      <ActivityList activities={activities} hasMore={hasNextPage} loading={loading} onShowMore={handleShowMore} />
    </Section>
  );
};
