import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as DownChevronIcon } from '../../assets/icons/interface/ic-chevron-down.svg';
import { ReactComponent as UpChevronIcon } from '../../assets/icons/interface/ic-chevron-up.svg';
import { ReactComponent as FeedbackIcon } from '../../assets/icons/interface/ic-messages-bubble-square-information.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/interface/ic-remove.svg';
import { useProductContext } from '../../context';
import { OnboardingStatus } from '../../gql/graphql';
import { useUserProjects } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import { Button, FlexContainer, Icon, Progress, Step, Steps, Typography } from '../../ui';
import { getPercentage, isNil, isTrialOnboardingComplete, onboardingSteps } from '../../utils';
import { formatPercentage } from '../formatters';
import { InviteModal } from '../invite-user';
import { ProductTourVideo } from '../product-tour';

import styles from './OnboardingChecklist.module.scss';

export const OnboardingChecklist = () => {
  const { isOnboardingEnabled, showTrialModal, onboardingStatus, updateOnboardingStatus } = useProductContext();
  const { quickstartProject, sampleProject } = useUserProjects();

  const [showChecklist, setShowChecklist] = useState(() => {
    if (showTrialModal) {
      return false;
    }
    return !isTrialOnboardingComplete(onboardingStatus);
  });
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showProductTourVideo, setShowProductTourVideo] = useState(false);

  useEffect(() => {
    if (!showTrialModal && !isTrialOnboardingComplete(onboardingStatus)) {
      setShowChecklist(true);
    }
  }, [showTrialModal]);

  const progress = useMemo(() => {
    if (!onboardingStatus) return 0;
    return onboardingStatus.filter((status) => status !== OnboardingStatus.Skipped).length;
  }, [onboardingStatus]);

  if (isNil(isOnboardingEnabled) || isOnboardingEnabled === false) {
    return null;
  }

  return (
    <>
      <div className={styles.positioner}>
        <div className={cn(styles.stepsWrapper, { [styles.visible]: showChecklist })}>
          <FlexContainer className={styles.header} direction="column" gap={0}>
            <FlexContainer justify="space-between">
              <Typography color="accent" component="h3" variant="callout" weight="semi-bold">
                {$t({ id: 'OnboardingChecklist.getStarted', defaultMessage: 'Get started' })}
              </Typography>
              <Button
                aria-label="Close checklist"
                leftIcon={CloseIcon}
                size="xxs"
                variant="phantomContrast"
                onClick={() => setShowChecklist((prev) => !prev)}
              />
            </FlexContainer>
            <FlexContainer>
              <Typography color="accent" variant="callout">
                {formatPercentage(getPercentage(progress, 4))}
              </Typography>
              <Progress className={styles.progress} thin total={4} values={{ color: 'accent', value: progress }} />
            </FlexContainer>
          </FlexContainer>

          <Steps className={styles.steps}>
            {/* Step 1 */}
            <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step1)} stepNumber={1}>
              <FlexContainer direction="column" gap={0}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step1].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component="button"
                      link
                      type="button"
                      weight="semi-bold"
                      onClick={() => setShowProductTourVideo(true)}
                    >
                      {str}
                    </Typography>
                  ))}
                </Typography>
                <Typography color="tertiary" variant="footnote">
                  {onboardingSteps[OnboardingStatus.Step1].getHint()}
                </Typography>
              </FlexContainer>
            </Step>

            {/* Step 2 */}
            <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step2)} stepNumber={2}>
              <FlexContainer direction="column" gap={0}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step2].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component={quickstartProject ? Link : undefined}
                      link
                      to={buildLink(VecticeRoutes.PROJECT, { projectId: quickstartProject?.vecticeId })}
                      weight="semi-bold"
                      onClick={quickstartProject ? () => updateOnboardingStatus?.(OnboardingStatus.Step2) : undefined}
                    >
                      {str}
                    </Typography>
                  ))}
                </Typography>
                <Typography color="tertiary" variant="footnote">
                  {onboardingSteps[OnboardingStatus.Step2].getHint()}
                </Typography>
              </FlexContainer>
            </Step>

            {/* Step 3 */}
            <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step3)} stepNumber={3}>
              <FlexContainer direction="column" gap={0}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step3].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component="button"
                      link
                      type="button"
                      weight="semi-bold"
                      onClick={() => setShowInviteModal(true)}
                    >
                      {str}
                    </Typography>
                  ))}
                </Typography>
                <Typography color="tertiary" variant="footnote">
                  {onboardingSteps[OnboardingStatus.Step3].getHint()}
                </Typography>
              </FlexContainer>
            </Step>

            {/* Step 4 */}
            <Step isCompleted={onboardingStatus?.includes(OnboardingStatus.Step4)} stepNumber={4}>
              <FlexContainer direction="column" gap={0}>
                <Typography variant="callout">
                  {onboardingSteps[OnboardingStatus.Step4].getAction((str: string) => (
                    <Typography
                      className={styles.action}
                      color="accent"
                      component={sampleProject ? Link : undefined}
                      link
                      to={buildLink(VecticeRoutes.PROJECT, { projectId: sampleProject?.vecticeId })}
                      weight="semi-bold"
                      onClick={sampleProject ? () => updateOnboardingStatus?.(OnboardingStatus.Step4) : undefined}
                    >
                      {str}
                    </Typography>
                  ))}
                </Typography>
                <Typography color="tertiary" variant="footnote">
                  {onboardingSteps[OnboardingStatus.Step4].getHint()}
                </Typography>
              </FlexContainer>
            </Step>
          </Steps>
          <FlexContainer className={styles.help}>
            <Icon icon={FeedbackIcon} size={14} />
            <Typography variant="footnote">
              {$t({
                id: 'OnboardingChecklist.needHelp',
                defaultMessage: 'Need help? Contact <support>support@vectice.com</support>',
              })}
            </Typography>
          </FlexContainer>
          <Button
            className={styles.skip}
            size="xxs"
            variant="phantom"
            onClick={() => updateOnboardingStatus?.(OnboardingStatus.Skipped)}
          >
            {$t({ id: 'OnboardingChecklist.skip', defaultMessage: "Don't show this again" })}
          </Button>
        </div>
        <Button
          aria-label="Toggle checklist"
          className={styles.trigger}
          rightIcon={showChecklist ? DownChevronIcon : UpChevronIcon}
          onClick={() => setShowChecklist((prev) => !prev)}
        >
          {$t({ id: 'OnboardingChecklist.getStarted', defaultMessage: 'Get started' })}
        </Button>
      </div>
      {showProductTourVideo && (
        <ProductTourVideo
          onClose={() => {
            setShowProductTourVideo(false);
            updateOnboardingStatus?.(OnboardingStatus.Step1);
          }}
        />
      )}
      {showInviteModal && (
        <InviteModal
          onClose={() => {
            setShowInviteModal(false);
            updateOnboardingStatus?.(OnboardingStatus.Step3);
          }}
        />
      )}
    </>
  );
};
