import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { CHECK_CDTREPORT_NAME_AVAILABILITY } from '../graphql/queries';

export const useCheckReportNameAvailability = (parentId: string, resourceId?: number) => {
  const [checkNameAvailability] = useLazyQuery(CHECK_CDTREPORT_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
  });

  const checkCDTReportNameAvailability = useCallback(
    async (name: string) => {
      const { data: { checkCDTReportNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          name,
          parentId,
          resourceId,
        },
      });

      return !!checkCDTReportNameAvailability;
    },
    [parentId],
  );

  return { checkCDTReportNameAvailability };
};
