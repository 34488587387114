import React from 'react';

import { getWorkspaceIcon } from '../../../entities/utils';
import { WorkspaceType } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { WorkspaceTooltip } from './WorkspaceTooltip';

interface WorkspaceLinkProps {
  name?: string | null;
  resourceId?: string | null;
  type?: WorkspaceType | null;
  ellipsis?: boolean;
}

export const WorkspaceLink = ({ name, resourceId, type, ellipsis }: WorkspaceLinkProps) => (
  <WorkspaceTooltip id={resourceId} placement="topLeft">
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.WORKSPACE, { workspaceId: resourceId }) : undefined}
      icon={getWorkspaceIcon(type)}
      ellipsis={ellipsis}
    />
  </WorkspaceTooltip>
);
