import { useQuery } from '@apollo/client';
import React, { useCallback, useRef, useState } from 'react';

import { ReactComponent as LineageIcon } from '../../assets/icons/specials/ic-lineage.svg';
import config from '../../config';
import { graphql } from '../../gql';
import { IterationsQuery, ListOrderInput, MatchEnum, OrderDirection } from '../../gql/graphql';
import {
  buildAdvancedBooleanFilter,
  buildAdvancedDateFilter,
  buildAdvancedNameFilter,
  buildAdvancedOwnerFilter,
} from '../../graphql/utils';
import { useRetrievePageContext } from '../../hooks';
import { Column, FlexContainer, Icon, Pagination, SearchInput, Table, Tooltip, message } from '../../ui';
import { IterationLink, PhaseLink } from '../asset-display';
import { StarringButton } from '../buttons';
import {
  CreatedDateFilter,
  FilterComposer,
  IterationOwnerFilter,
  IterationStatusFilter,
  StarredFilter,
} from '../filters';
import { useModelVersionAssetsContext } from '../model-version/ModelVersionAssetsContext';
import { NoIterationsForModel } from '../no-results';
import {
  AvatarCell,
  CreatedCell,
  DateCell,
  LinkWrapperCell,
  TableWidthObserver,
  columnsDefaultSizes,
  useTableSizeObserver,
} from '../tables';

import styles from './IterationsTable.module.scss';

const ITERATIONS = graphql(`
  query iterations($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {
    getIterationList(filters: $filters, order: $order, page: $page) {
      items {
        vecticeId
        index
        name
        starred
        status
        createdDate
        updatedDate
        phaseId
        phase {
          vecticeId
          name
        }
        owner {
          ...userFields
        }
        createdBy {
          ...userFields
        }
      }
      total
    }
  }
`);

const defaultColumnsSizeConfig = {
  ...columnsDefaultSizes,
  phase: columnsDefaultSizes.name as string | number,
};

type ColumnsSizeConfig = typeof defaultColumnsSizeConfig;

interface IterationsTableProps {
  columnsSizeConfig?: Partial<ColumnsSizeConfig>;
  modelVersionId: string;
  originIterationId?: string;
  pageSize?: number;
}

type IterationType = IterationsQuery['getIterationList']['items'][0];

const defaultOrder = {
  field: 'updatedDate',
  direction: OrderDirection.Desc,
};

export const IterationsTable = ({
  columnsSizeConfig,
  modelVersionId,
  originIterationId,
  pageSize = config.LIST_DEFAULT_SIZE,
}: IterationsTableProps) => {
  const { category } = useModelVersionAssetsContext();
  const { workspace } = useRetrievePageContext();

  const tableRef = useRef<HTMLDivElement>(null);
  const { cellSize, applySizeFactor } = useTableSizeObserver(tableRef.current);

  const [filters, setFilters] = useState<Record<string, any>>({});
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<ListOrderInput>(defaultOrder);
  const [page, setPage] = useState(1);

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setPage(1);
  }, []);

  const onFiltersUpdate = (updatedFilters: Record<string, any>) => {
    setPage(1);
    setFilters(updatedFilters);
  };

  const onColumnSort = (field: string, direction: OrderDirection) => {
    setOrder({
      field,
      direction,
    });
  };

  const { data, loading } = useQuery(ITERATIONS, {
    variables: {
      filters: {
        includeModelVersionId: modelVersionId,
        status: filters.status,
        advancedFilters: [
          ...buildAdvancedOwnerFilter(((filters.owner || []) as string[]).map((id) => +id)),
          ...buildAdvancedBooleanFilter('starred', filters.showStarred),
          ...buildAdvancedDateFilter(filters.createdDate),
          ...(search ? buildAdvancedNameFilter([{ match: MatchEnum.Contains, value: search }]) : []),
        ],
        category,
      },
      order,
      page: { index: page, size: pageSize },
    },
    onError: (error) => message.error(error.message),
  });

  const iterations = data?.getIterationList?.items;
  const total = data?.getIterationList?.total;

  const sizeConfig = { ...defaultColumnsSizeConfig, ...columnsSizeConfig };

  return (
    <>
      <TableWidthObserver ref={tableRef}>
        <FlexContainer className={styles.header} gap={16} gutterBottom>
          <SearchInput
            aria-label="Search"
            className={styles.search}
            placeholder={$t({ id: 'search.placeholder', defaultMessage: 'Search...' })}
            onDebouncedChange={handleSearch}
          />
          <FilterComposer onFiltersUpdate={onFiltersUpdate}>
            <CreatedDateFilter />
            <IterationStatusFilter />
            {workspace?.vecticeId && <IterationOwnerFilter workspaceIdList={[workspace?.vecticeId]} />}
            <StarredFilter />
          </FilterComposer>
        </FlexContainer>
      </TableWidthObserver>
      <Table
        aria-label="Iterations table"
        data={iterations}
        emptyText={<NoIterationsForModel />}
        initialSort={{ key: defaultOrder.field, direction: defaultOrder.direction }}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        onSort={onColumnSort}
      >
        <Column<IterationType, IterationType['starred']>
          key="starred"
          align="center"
          fixed="left"
          sortable
          width={sizeConfig.starred}
        >
          {(starred) => starred && <StarringButton starred={starred} inert tooltip={{ visible: false }} />}
        </Column>
        <Column<IterationType, IterationType['name']>
          key="name"
          fixed="left"
          sortable
          title={$t({ id: 'MdvIterations.table.name', defaultMessage: 'Name' })}
          width={applySizeFactor(sizeConfig.name)}
        >
          {(name, { vecticeId }) => (
            <LinkWrapperCell size={cellSize}>
              {originIterationId === vecticeId && (
                <Tooltip
                  placement="top"
                  text={$t({ id: 'MdvIterations.table.originIteration', defaultMessage: 'Origin iteration' })}
                >
                  <Icon className={styles.originIteration} icon={LineageIcon} size={18} />
                </Tooltip>
              )}
              <IterationLink name={name} resourceId={vecticeId} />
            </LinkWrapperCell>
          )}
        </Column>
        <Column<IterationType, IterationType['phase']>
          key="phase"
          sortable="phase.name"
          title={$t({ id: 'MdvIterations.table.phase', defaultMessage: 'Phase' })}
          width={applySizeFactor(sizeConfig.phase)}
        >
          {(phase) =>
            phase && (
              <LinkWrapperCell size={cellSize}>
                <PhaseLink name={phase.name} resourceId={phase.vecticeId} />
              </LinkWrapperCell>
            )
          }
        </Column>
        <Column<IterationType, IterationType['owner']>
          key="owner"
          align="center"
          sortable="owner.name"
          title={$t({ id: 'MdvIterations.table.owner', defaultMessage: 'Owner' })}
          width={sizeConfig.avatar}
        >
          {(user) => <AvatarCell user={user} />}
        </Column>
        <Column<IterationType, IterationType['updatedDate']>
          key="updatedDate"
          ellipsis
          sortable
          title={$t({ id: 'tables.columns.updated', defaultMessage: 'Updated' })}
          width={sizeConfig.date}
        >
          {(updatedDate) => <DateCell date={updatedDate} />}
        </Column>
        <Column<IterationType, IterationType['createdDate']>
          key="createdDate"
          ellipsis
          sortable
          title={$t({ id: 'tables.columns.created', defaultMessage: 'Created' })}
          width={sizeConfig.createdDate}
        >
          {(createdDate, { createdBy }) => <CreatedCell date={createdDate} user={createdBy} />}
        </Column>
      </Table>
      <Pagination total={total} current={page} pageSize={pageSize} onChange={setPage} />
    </>
  );
};
