import React, { PropsWithChildren, ReactElement } from 'react';

import type { AssetRowProps } from '../../../ui';

import { FragmentType, useFragment } from '../../../gql';
import { EntityFileRowFragment } from '../../../graphql/fragments';
import { AssetRow } from '../../../ui';
import { getMimeTypeIcon } from '../../../utils';

interface EntityFileRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  icon?: ReactElement;
  entityFile?: FragmentType<typeof EntityFileRowFragment>;
}

export const EntityFileRow = (props: PropsWithChildren<EntityFileRowProps>) => {
  const entityFile = useFragment(EntityFileRowFragment, props.entityFile);

  if (!entityFile) {
    return null;
  }

  return (
    <AssetRow
      icon={getMimeTypeIcon(entityFile.mimeType)}
      label={entityFile.fileName ?? $t({ id: 'entityFileRow.file', defaultMessage: 'File' })}
      {...props}
    />
  );
};
