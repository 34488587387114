import React, { PropsWithChildren } from 'react';

import { GuardedContent } from './GuardedContent';
import { WithFeatureFlagsProps } from './interfaces';
import { useWithFeatureFlags } from './useWithFeatureFlags';

export const WithFeatureFlags = ({ featureFlag, children }: PropsWithChildren<WithFeatureFlagsProps>) => {
  const { loading, error, allowed } = useWithFeatureFlags({ featureFlag });

  return (
    <GuardedContent allowed={allowed} loading={loading} error={error}>
      {children}
    </GuardedContent>
  );
};
