import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { ReactComponent as RemoveIcon } from '../../../assets/icons/interface/ic-remove.svg';
import { Icon } from '../../svg';
import { Typography } from '../../typography';

import styles from './FilterPill.module.scss';

interface FilterPillProps extends VecticeHTMLProps<HTMLButtonElement> {
  onClear?: () => void;
}

export const FilterPill = ({ onClear, children, className, ...props }: PropsWithChildren<FilterPillProps>) => (
  <div className={cn(styles.wrapper, className)}>
    <Typography
      component="button"
      type="button"
      variant="callout"
      weight="semi-bold"
      color="accent"
      className={styles.pill}
      {...props}
    >
      {children}
    </Typography>
    {onClear && (
      <button
        type="button"
        className={styles.remove}
        onClick={(e) => {
          e.stopPropagation();
          onClear();
        }}
      >
        <Icon icon={RemoveIcon} size={12} />
      </button>
    )}
  </div>
);
