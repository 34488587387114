import React, { useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { RadioGroupFilterComponent } from '../ui';

export const pluginField = 'showStarred';

class StarredFilterNode<T> extends FilterNode<T> {
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    const options = [
      { value: true!, label: $t({ id: 'filters.plugin.option.starred', defaultMessage: 'Starred' }) },
      { value: false, label: $t({ id: 'filters.plugin.option.unstarred', defaultMessage: 'Unstarred' }) },
    ];
    return (
      <RadioGroupFilterComponent
        key={pluginField}
        label="starred-radio-group"
        options={options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const StarredFilter = () => {
  const { current: node } = useRef(new StarredFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.starred.label', defaultMessage: 'Starred' }),
    node,
  });

  return null;
};
