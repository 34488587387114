import React, { ElementType, PropsWithChildren, ReactNode } from 'react';

import { Badge } from '../badge';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './AssetTooltip.module.scss';

interface AssetTooltipProps {
  icon: ElementType;
  resourceTypeLabel?: string;
  label: ReactNode;
  versionNumber?: ReactNode;
  ariaId?: string;
  deleted?: boolean;
}

export const AssetTooltip = ({
  icon,
  resourceTypeLabel,
  label,
  versionNumber,
  ariaId,
  deleted,
  children,
}: PropsWithChildren<AssetTooltipProps>) => {
  if (deleted) {
    return (
      <Typography id={ariaId} variant="callout" weight="semi-bold" color="white" className={styles.header}>
        <Icon icon={icon} size={20} className={styles.icon} aria-hidden />
        <Typography id={ariaId} variant="callout" weight="semi-bold" color="white" className={styles.label}>
          {resourceTypeLabel} was deleted
        </Typography>
      </Typography>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <Icon icon={icon} size={20} className={styles.icon} aria-hidden />
        <div>
          {resourceTypeLabel && (
            <Typography color="white" transform="uppercase" variant="footnote">
              {resourceTypeLabel}
            </Typography>
          )}
          {label && (
            <Typography
              id={ariaId}
              paragraph
              variant="callout"
              weight="semi-bold"
              color="white"
              className={styles.label}
            >
              <span>{label}</span>
              {!!versionNumber && (
                <Badge color="accent" contrast variant="square" size="sm" className={styles.version}>
                  v{versionNumber}
                </Badge>
              )}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
