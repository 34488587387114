import cn from 'classnames';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';

import { ReactComponent as UnstarredIcon } from '../../assets/icons/interface/ic-rating-star.svg';
import { ReactComponent as StarredIcon } from '../../assets/icons/interface/ic-star.svg';
import { Button, ButtonProps } from '../../ui/button/Button';
import { Icon } from '../../ui/svg';
import { Placement, Tooltip } from '../../ui/tooltip';

import styles from './StarringButton.module.scss';

type TooltipProps = {
  visible?: boolean;
  tooltipPlacement?: Placement;
};
interface StarringButtonProps extends Omit<ButtonProps, 'onChange'> {
  inert?: boolean;
  starred: boolean;
  tooltip?: TooltipProps;
  onChange?: (isStarred: boolean) => void;
}
const DEFAULT_TOOLTIP_PROPS: TooltipProps = {
  visible: true,
  tooltipPlacement: 'top',
};
export const StarringButton = ({
  className,
  inert,
  starred,
  tooltip = DEFAULT_TOOLTIP_PROPS,
  onChange,
  onClick,
  ...props
}: StarringButtonProps) => {
  const [tooltipText, setTooltipText] = useState<string>('');

  useEffect(() => {
    if (starred) {
      setTooltipText($t({ id: 'component.StarringButton.unStarred', defaultMessage: 'Remove from starred' }));
    } else {
      setTooltipText($t({ id: 'component.StarringButton.starred', defaultMessage: 'Add to starred' }));
    }
  }, [starred]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange?.(!starred);
    onClick?.(e);
  };

  const component = useMemo(() => {
    return inert ? (
      <div className={cn(styles.iconContainer, className)}>
        <Icon
          className={cn(styles.icon, { [styles.starred]: starred })}
          icon={starred ? StarredIcon : UnstarredIcon}
          size={20}
        />
      </div>
    ) : (
      <Button
        role="switch"
        aria-checked={starred}
        className={cn({ [styles.starred]: starred }, className)}
        color="gray"
        leftIcon={starred ? StarredIcon : UnstarredIcon}
        size="sm"
        variant="phantom"
        onClick={handleClick}
        {...props}
      />
    );
  }, [inert, starred]);

  if (tooltip.visible) {
    return (
      <Tooltip text={tooltipText} trigger="hover" placement={tooltip.tooltipPlacement}>
        {component}
      </Tooltip>
    );
  }
  return component;
};
