import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

export const NoProjects = ({ borderless }: { borderless?: boolean }) => (
  <EmptyList
    borderless={borderless}
    hint={$t({
      id: 'NoProjects.hint',
      defaultMessage: 'You can view all the Projects that were created in your Workspace on this Page.',
    })}
    icon={VecticeIcon}
    message={$t({ id: 'NoProjects.message', defaultMessage: 'Create your first Project!' })}
  />
);
