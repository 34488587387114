import { graphql } from '../../gql/gql';

export const SOURCE_COLUMNS = graphql(`
  query sourceColumns(
    $id: Float!
    $columnSourceType: ColumnSourceType!
    $search: String
    $order: ListOrderInput
    $page: PageIndexInput
  ) {
    sourceColumns(
      id: $id
      columnSourceType: $columnSourceType
      filters: { search: $search }
      order: $order
      page: $page
    ) {
      items {
        id
        name
      }
    }
  }
`);

export const SOURCE_COLUMN = graphql(`
  query sourceColumn($id: Float!, $columnSourceType: ColumnSourceType!) {
    sourceColumn(id: $id, columnSourceType: $columnSourceType) {
      id
      name
    }
  }
`);
