import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Workspace } from '../../../gql/graphql';
import { AssetRow, Typography } from '../../../ui';
import { ReviewBadge } from '../../badges';
import { FormatDate, FormatUpdatedBy, FormatUserName } from '../../formatters';
import { FormatPhase } from '../phase';
import { FormatProject } from '../project';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const ReviewRowFragment = graphql(`
  fragment reviewRow on Review {
    id
    status
    createdDate
    createdBy {
      ...userFields
    }
    phase {
      vecticeId
      name
      parent {
        vecticeId
        name
      }
    }
  }
`);

interface ReviewRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  review?: FragmentType<typeof ReviewRowFragment>;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const ReviewRow = (props: ReviewRowProps) => {
  const review = useFragment(ReviewRowFragment, props.review);

  if (!review) {
    return null;
  }

  return (
    <AssetRow
      avatar={<UserAvatar user={review.createdBy} size="xs" />}
      label={$t({
        id: 'review.affectedToUser.message',
        defaultMessage: '<bold>{requester}</bold> requested review on {phase} in {project}',
        values: {
          requester: <FormatUserName user={review.createdBy} />,
          phase: <FormatPhase name={review.phase.name} />,
          project: <FormatProject name={review.phase.parent.name} />,
        },
      })}
      labelWeight="regular"
      {...props}
    >
      {props.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace name={props.workspace.name} iconSize={14} type={props.workspace.type} ellipsis />
        </Typography>
      )}
      <ReviewBadge status={review.status} />
      {!!review.createdDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy date={<FormatDate date={review.createdDate} fromNow />} />
        </Typography>
      )}
    </AssetRow>
  );
};
