import React, { Fragment } from 'react';
import { UAParser } from 'ua-parser-js';

import { useOrgConfig } from '../../context';
import { getArtifactVersionDisplayName } from '../../entities/utils';
import { ArtifactVersion } from '../../gql/graphql';
import { Button, Logo, Modal, Typography } from '../../ui';
import { WithCopyOnClick } from '../copy-on-click';

import styles from './AboutVecticeModal.module.scss';

interface AboutVecticeModalProps {
  visible?: boolean;
  onClose?: () => void;
}

export const AboutVecticeModal = ({ visible, onClose }: AboutVecticeModalProps) => {
  const parserInfo = UAParser(navigator.userAgent);
  const config = useOrgConfig();

  const globalVersion = config.versions.find(({ artifactName }) => artifactName === ArtifactVersion.Global);

  const buildInformation = `
Build Information
${config.versions
  ?.map(({ artifactName, version }) => ` - ${getArtifactVersionDisplayName(artifactName)}: ${version}`)
  .join('\n')}`;

  const userContext = `
User Context
 - Browser: ${parserInfo.browser.name} ${parserInfo.browser.version}
 - Engine: ${parserInfo.engine.name} ${parserInfo.engine.version}
 - OS: ${parserInfo.os.name} ${parserInfo.os.version}`;

  const adminSettings = `
Admin Settings
 - SSO Authentication: ${config.licenseOptions.isSingleSignOnEnabled ? 'Enabled' : 'Disabled'}
 - Password Authentication: ${config.configuration.passwordAuthenticationEnabled ? 'Enabled' : 'Disabled'}
 - Emails: ${config.sendEmail ? 'Enabled' : 'Disabled'}`;

  const experimentalFlags = `
Experimental Flags
${config.featureFlags?.map((flag) => ` - ${flag.code}: ${flag.enabled ? 'Enabled' : 'Disabled'}`).join('\n')}`;

  const info = `Vectice ${globalVersion?.version || '(Unknown Version)'}
${buildInformation}
${userContext}
${adminSettings}
${experimentalFlags}`;

  return (
    <Modal
      title={$t({ id: 'modal.aboutVecticeModal.About', defaultMessage: 'About Vectice' })}
      closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
      visible={visible}
      onClose={onClose}
      extraAction={
        <WithCopyOnClick value={info} tooltipPlacement="left">
          <Button variant="phantom">{$t({ id: 'modal.aboutVecticeModal.Copy', defaultMessage: 'Copy' })}</Button>
        </WithCopyOnClick>
      }
    >
      <div className={styles.about}>
        <Typography paragraph variant="heading1" color="primary" gutterBottom className={styles.about_title}>
          <Logo size={48} withName />
        </Typography>
        {config.versions && (
          <>
            <Typography variant="subtitle" weight="semi-bold" paragraph gutterBottom>
              {$t({
                id: 'modal.aboutVecticeModal.ReleaseVersion',
                defaultMessage: 'Release {version}',
                values: { version: globalVersion?.version },
              })}
            </Typography>
            <Typography variant="footnote" weight="semi-bold" align="center" paragraph gutterBottom>
              {config.versions
                .filter(({ artifactName }) => artifactName !== ArtifactVersion.Global)
                .map(({ version, artifactName }) => (
                  <Fragment key={artifactName}>
                    {getArtifactVersionDisplayName(artifactName)} v{version}
                    <br />
                  </Fragment>
                ))}
            </Typography>
          </>
        )}
        <Typography
          component="a"
          color="accent"
          align="center"
          link
          href="https://www.vectice.com/"
          target="_blank"
          rel="noopener noreferrer"
          gutterBottom
        >
          https://www.vectice.com/
        </Typography>
      </div>
    </Modal>
  );
};
