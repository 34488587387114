import type { BadgeColors } from '../../ui';

import { ModelVersionStatus } from '../../gql/graphql';

type excludedOptions = typeof ModelVersionStatus.Discarded | typeof ModelVersionStatus.Retired;
export type PhaseCategory = Exclude<ModelVersionStatus, excludedOptions>;

type PhaseCategoryType = PhaseCategory | string | undefined | null;

export const getPhaseCategoryDisplayName = (status: PhaseCategoryType) => {
  switch (status) {
    case ModelVersionStatus.Experimentation:
      return $t({ id: 'phaseCategory.experimentation.label', defaultMessage: 'Experimentation' });
    case ModelVersionStatus.Production:
      return $t({ id: 'phaseCategory.production.label', defaultMessage: 'Production' });
    case ModelVersionStatus.Staging:
      return $t({ id: 'phaseCategory.staging.label', defaultMessage: 'Staging' });
    case ModelVersionStatus.Validation:
      return $t({ id: 'phaseCategory.validation.label', defaultMessage: 'Validation' });
    default:
      return $t({ id: 'phaseCategory.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getPhaseCategoryColor = (status: PhaseCategoryType): BadgeColors => {
  switch (status) {
    case ModelVersionStatus.Experimentation:
      return 'info';
    case ModelVersionStatus.Production:
      return 'success';
    case ModelVersionStatus.Staging:
      return 'warning';
    case ModelVersionStatus.Validation:
      return 'on-track';
    default:
      return 'default';
  }
};

export const getPhaseCategoryOptions = () => [
  {
    label: getPhaseCategoryDisplayName(ModelVersionStatus.Experimentation),
    value: ModelVersionStatus.Experimentation,
  },
  {
    label: getPhaseCategoryDisplayName(ModelVersionStatus.Validation),
    value: ModelVersionStatus.Validation,
  },
  {
    label: getPhaseCategoryDisplayName(ModelVersionStatus.Staging),
    value: ModelVersionStatus.Staging,
  },
  {
    label: getPhaseCategoryDisplayName(ModelVersionStatus.Production),
    value: ModelVersionStatus.Production,
  },
];
