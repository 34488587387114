import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

export const NoAuthenticationMethod = () => (
  <EmptyList
    icon={VecticeIcon}
    message={$t({ id: 'NoAuthenticationMethod.message', defaultMessage: 'Set up an authentication method' })}
    hint={$t({
      id: 'NoAuthenticationMethod.hint',
      defaultMessage: 'Manage how people in the Organization can sign in',
    })}
  />
);
