import React from 'react';

import { useFragment } from '../../../gql';
import { ColumnSourceType } from '../../../gql/graphql';
import { isDefined } from '../../../utils';
import { FormatNumber, FormatPercentage, getPercentageTooltip } from '../../formatters';
import { TextSourceColumn as TextSourceColumnType, TextSourceColumnFragment } from '../types';

import { DatasetSourceColumnWrapper } from './DatasetSourceColumnWrapper';
import { StatisticCell, StatisticRow, Statistics } from './internal';

interface TextSourceColumnProps {
  column: TextSourceColumnType;
  columnSourceType: ColumnSourceType;
}

export const TextSourceColumn = ({ column, columnSourceType }: TextSourceColumnProps) => {
  const statistics = useFragment(TextSourceColumnFragment, column.statistics);

  return (
    <DatasetSourceColumnWrapper column={column} columnSourceType={columnSourceType}>
      {!!statistics?.text && (
        <Statistics>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.text.unique', defaultMessage: 'Unique' })} />
            <StatisticCell label={<FormatNumber value={statistics.text.unique} />} />
          </StatisticRow>
          {statistics.text.mostCommons?.map(({ value, frequency }, index, arr) => (
            <StatisticRow key={value}>
              {index === 0 && (
                <StatisticCell
                  header
                  label={$t({ id: 'column.statistic.text.mostCommon', defaultMessage: 'Most Common' })}
                  rowSpan={arr.length}
                />
              )}
              <StatisticCell label={value} />
              <StatisticCell
                label={<FormatPercentage value={frequency * 100} />}
                tooltip={getPercentageTooltip(frequency * 100)}
              />
            </StatisticRow>
          ))}
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.text.missing', defaultMessage: 'Missing' })} />
            <StatisticCell
              label={
                <FormatPercentage value={isDefined(statistics.text.missing) ? statistics.text.missing * 100 : null} />
              }
              tooltip={getPercentageTooltip(isDefined(statistics.text.missing) ? statistics.text.missing * 100 : null)}
            />
          </StatisticRow>
        </Statistics>
      )}
    </DatasetSourceColumnWrapper>
  );
};
