import { useMutation } from '@apollo/client';

import { ADD_TO_STARRED_VERSIONS } from '../components_v3/dataset-versions/addToStarredDatasetVersions.mutation';

export const useStarDatasetVersion = () => {
  const [updateStarDatasetVersion] = useMutation(ADD_TO_STARRED_VERSIONS, {
    optimisticResponse: (variables) => ({
      addToStarredDatasetVersions: {
        vecticeId: variables.resource.resourceId,
        isStarred: variables.resource.isStarred,
      },
    }),
    errorPolicy: 'ignore',
  });

  return { updateStarDatasetVersion };
};
