import { graphql } from '../../gql';

export const MOVE_ARTIFACTS = graphql(`
  mutation moveArtifacts(
    $iterationId: VecticeId!
    $artifactsIdList: [Float!]!
    $targetStepId: Float
    $targetStepIndex: Float
  ) {
    moveArtifacts(
      iterationId: $iterationId
      artifactsIdList: $artifactsIdList
      targetStepId: $targetStepId
      targetStepIndex: $targetStepIndex
    ) {
      id
      stepId
    }
  }
`);
