import cn from 'classnames';
import React, { ElementType } from 'react';

import styles from './List.module.scss';

export const ListItem = <E extends ElementType>({
  className,
  component: Component = 'li',
  ...props
}: VecticeHTMLProps<HTMLLIElement> & WithComponentProps<E>) => (
  <Component role="listitem" className={cn(styles.list_item, className)} {...props} />
);
