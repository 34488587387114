import { OrderDirection } from '../gql/graphql';

export interface SortConfig {
  direction: OrderDirection;
  field: string;
  label: string;
  value: SortValue;
}

export enum SortValue {
  LEAST_RECENTLY_UPDATED = 'LEAST_RECENTLY_UPDATED',
  MOST_RECENTLY_UPDATED = 'MOST_RECENTLY_UPDATED',
  NAME_A_TO_Z = 'NAME_A_TO_Z',
  NAME_Z_TO_A = 'NAME_Z_TO_A',
}

export const SortOptions: Record<SortValue, SortConfig> = {
  [SortValue.LEAST_RECENTLY_UPDATED]: {
    field: 'updatedDate',
    direction: OrderDirection.Asc,
    label: 'Least recently updated',
    value: SortValue.LEAST_RECENTLY_UPDATED,
  },
  [SortValue.MOST_RECENTLY_UPDATED]: {
    field: 'updatedDate',
    direction: OrderDirection.Desc,
    label: 'Most recently updated',
    value: SortValue.MOST_RECENTLY_UPDATED,
  },
  [SortValue.NAME_A_TO_Z]: {
    field: 'name',
    direction: OrderDirection.Asc,
    label: 'Name (a-z)',
    value: SortValue.NAME_A_TO_Z,
  },
  [SortValue.NAME_Z_TO_A]: {
    field: 'name',
    direction: OrderDirection.Desc,
    label: 'Name (z-a)',
    value: SortValue.NAME_Z_TO_A,
  },
};

export const getSortConfig = (value: SortValue): SortConfig => SortOptions[value];
