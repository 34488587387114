import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import BackgroundGraphic from '../../assets/graphics/animation/background.svg';
import FireL1Graphic from '../../assets/graphics/animation/fireL1.svg';
import FireL2Graphic from '../../assets/graphics/animation/fireL2.svg';
import FireMGraphic from '../../assets/graphics/animation/fireM.svg';
import FireR1Graphic from '../../assets/graphics/animation/fireR1.svg';
import FireR2Graphic from '../../assets/graphics/animation/fireR2.svg';
import MovingGraphic from '../../assets/graphics/animation/moving.svg';
import RocketGraphic from '../../assets/graphics/animation/rocket.svg';
import config from '../../config';
import { FlexContainer, Graphic, Modal, Typography } from '../../ui';

import styles from './LoadingModal.module.scss';

const INFO_MESSAGES = [
  () => $t({ id: 'modal.loadingModal.infoMessage.0', defaultMessage: 'Hang tight... getting ready to launch' }),
  () => $t({ id: 'modal.loadingModal.infoMessage.1', defaultMessage: 'Generating workspaces' }),
  () => $t({ id: 'modal.loadingModal.infoMessage.2', defaultMessage: 'Building templates' }),
  () => $t({ id: 'modal.loadingModal.infoMessage.3', defaultMessage: 'Generating documentation' }),
];

const TIME_INTERVAL = 3000;

export const LoadingModal = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % INFO_MESSAGES.length);
    }, TIME_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Modal
      title={$t({ id: 'modal.loadingModal.title', defaultMessage: 'Activating your account...' })}
      closeLabel={undefined}
      onClose={undefined}
    >
      <FlexContainer direction="column" align="center">
        <div className={styles.graphic_container} aria-hidden>
          <Graphic src={MovingGraphic} className={cn(styles.svg, styles.moving)} />
          <Graphic src={RocketGraphic} className={cn(styles.svg, styles.rocket)} />
          <Graphic src={BackgroundGraphic} className={cn(styles.svg, styles.background)} />
          <Graphic src={FireL1Graphic} className={cn(styles.svg, styles.fire, styles.fireL1)} />
          <Graphic src={FireL2Graphic} className={cn(styles.svg, styles.fire, styles.fireL2)} />
          <Graphic src={FireMGraphic} className={cn(styles.svg, styles.fire, styles.fireM)} />
          <Graphic src={FireR1Graphic} className={cn(styles.svg, styles.fire, styles.fireR1)} />
          <Graphic src={FireR2Graphic} className={cn(styles.svg, styles.fire, styles.fireR2)} />
        </div>
        <Typography>{INFO_MESSAGES[index]()}</Typography>
        <Typography variant="footnote">
          {$t({
            id: 'modal.loadingModal.support',
            defaultMessage: 'Please contact <support>{supportEmail}</support> for questions, or to send feedback.',
            values: { supportEmail: config.SUPPORT_EMAIL },
          })}
        </Typography>
      </FlexContainer>
    </Modal>
  );
};
