import React from 'react';

import EmptyIterationsIcon from '../../assets/graphics/documentation/empty-iterations.svg';
import { EmptyList } from '../../ui';
import { DocumentationLink } from '../links';

export const NoIterations = () => (
  <EmptyList
    graphic={EmptyIterationsIcon}
    iconSize={200}
    borderless
    message={$t({ id: 'NoIterations.message', defaultMessage: 'No iterations yet!' })}
    hint={$t({
      id: 'NoIterations.message_2',
      defaultMessage: 'Use the Vectice API to create your first Iteration. {link}',
      values: {
        link: (
          <DocumentationLink asTypography>
            {$t({ id: 'NoIterations.readOurDocs', defaultMessage: 'Read our docs to learn more.' })}
          </DocumentationLink>
        ),
      },
    })}
  />
);
