import { graphql } from '../../gql';

export const UPDATE_STEP = graphql(`
  mutation updateStepDefinition($id: Float!, $updateModel: BaseStepUpdateInput!) {
    updateStepDefinition(id: $id, updateModel: $updateModel) {
      id
      name
      slug
      description
    }
  }
`);
