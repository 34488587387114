import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType, ProjectStatus } from '../../../gql/graphql';
import { ProjectLink, WorkspaceLink } from '../../asset-display';
import { ProjectStatusBadge } from '../../badges';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { AssignedUser } from './ActivityUser';
import { ActivityProps } from './interfaces';

export const ProjectActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const project = <ProjectLink name={activity.targetName} resourceId={activity.targetVecticeId} />;

  const projectSource = <ProjectLink name={activity.sourceName} resourceId={activity.sourceVecticeId} />;

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const fromUser = <AssignedUser user={activity.fromUser} />;
  const toUser = <AssignedUser user={activity.toUser} />;

  const oldNameLink = <ProjectLink name={activity.fromValue} resourceId={activity.targetVecticeId} />;

  const newNameLink = <ProjectLink name={activity.toValue} resourceId={activity.targetVecticeId} />;

  const oldStatus = <ProjectStatusBadge status={activity.fromValue as ProjectStatus} />;
  const newStatus = <ProjectStatusBadge status={activity.toValue as ProjectStatus} />;

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.project.created',
          defaultMessage: '{user} created {resource}',
          values: {
            user,
            resource: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.project.deleted',
          defaultMessage: '{user} deleted {resource}',
          values: {
            user,
            resource: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Duplicated) {
    if (activity.targetVecticeId === activity.toValue) {
      return (
        <>
          {$t({
            id: 'activities.project.duplicateFrom',
            defaultMessage: '{user} duplicated {resource} from {resourceDuplicated}',
            values: {
              user,
              resource: project,
              resourceDuplicated: projectSource,
            },
          })}
        </>
      );
    }
    return (
      <>
        {$t({
          id: 'activities.project.duplicateTo',
          defaultMessage: '{user} duplicated {resource} to create {resourceTarget}',
          values: {
            user,
            resource: project,
            resourceTarget: projectSource,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Moved) {
    const workspace = <WorkspaceLink resourceId={activity.sourceWorkspaceVecticeId} name={activity.sourceName} />;
    return (
      <>
        {$t({
          id: 'activities.project.moved',
          defaultMessage: '{user} moved {resource} from {workspace}',
          values: {
            user,
            resource: project,
            workspace: workspace,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.project.update.description',
            defaultMessage: '{user} updated the description of {resource}',
            values: {
              user,
              resource: project,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.project.update.name',
            defaultMessage: '{user} renamed {oldName} to {newName}',
            values: {
              user,
              oldName: oldNameLink,
              newName: newNameLink,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Status) {
      return (
        <>
          {$t({
            id: 'activities.project.update.status',
            defaultMessage: '{user} transitioned {project} from {oldStatus} to {newStatus}',
            values: {
              user,
              project,
              oldStatus,
              newStatus,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Owner) {
      return (
        <>
          {$t({
            id: 'activities.project.update.owner',
            defaultMessage: '{user} changed the owner of {project} from {fromUser} to {toUser}',
            values: {
              user,
              project,
              fromUser,
              toUser,
            },
          })}
        </>
      );
    }
  }

  return null;
};
