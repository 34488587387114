import React, { useState } from 'react';

import { SearchInput } from '../form';

import styles from './FilterSearch.module.scss';

interface FilterSearchInputProps
  extends Omit<VecticeHTMLProps<HTMLInputElement>, 'label' | 'value' | 'className' | 'onChange'> {
  initialSearch?: string;
  onSearch?: (value: string) => void;
  loading?: boolean;
}

export const FilterSearch = ({
  initialSearch,
  placeholder = 'Search...',
  loading = false,
  type,
  onSearch,
  ...props
}: FilterSearchInputProps) => {
  const [search, setSearch] = useState(initialSearch);

  return (
    <SearchInput
      aria-label="search"
      borderless
      className={styles.filterSearchInput}
      placeholder={placeholder}
      type={type}
      value={search}
      onChange={(e) => setSearch(e.currentTarget.value)}
      onDebouncedChange={onSearch}
      loading={loading}
      {...props}
    />
  );
};
