import React from 'react';

import { UserInvite } from '../../gql/graphql';
import { getUserInviteLink } from '../../routes';
import { Modal, Typography } from '../../ui';
import { CopyInput } from '../copy-on-click';

interface UserInviteModalProps {
  invite: Pick<UserInvite, 'email' | 'inviteLinkToken'>;
  onClose: () => void;
}

export const UserInviteModal = ({ invite: { email, inviteLinkToken }, onClose }: UserInviteModalProps) => (
  <Modal
    title={$t({ id: 'modal.userInvite.invitationLink', defaultMessage: 'Invitation Link' })}
    closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
    onClose={onClose}
  >
    <Typography variant="callout" paragraph gutterBottom>
      {$t({ id: 'modal.userInvite.succesfulInvitationLink', defaultMessage: 'Successfully created invitation link' })}
    </Typography>
    <Typography variant="callout" paragraph gutterBottom>
      {$t({
        id: 'modal.userInvite.InvitationHint',
        defaultMessage:
          'You can copy this invitation link and personally send it to the users this link has been created for.',
      })}
    </Typography>
    <CopyInput
      key={inviteLinkToken}
      label={$t({
        id: 'modal.userInvite.invitationLinkForUser',
        defaultMessage: 'Invitation Link for {email}',
        values: { email },
      })}
      value={getUserInviteLink(inviteLinkToken)}
      gutterBottom
    />
  </Modal>
);
