import { useMutation, useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { ReactComponent as CreateIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as DragHandleIcon } from '../../assets/icons/interface/ic-drag-handle.svg';
import { ReactComponent as HelpIcon } from '../../assets/icons/interface/ic-question-circle.svg';
import { graphql } from '../../gql';
import { Phase } from '../../gql/graphql';
import { UPDATE_PHASE } from '../../graphql/queries';
import { useWithRoles, WithFeatureFlags } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { useSteps } from '../../hooks';
import {
  Button,
  Card,
  FlexContainer,
  Icon,
  Menu,
  message,
  MoreActions,
  Section,
  Toggle,
  Tooltip,
  Typography,
  WithLoading,
} from '../../ui';
import { VecticeResourceType } from '../../utils';
import { ComingSoonBadge } from '../badges';
import { EditButton } from '../buttons';
import { EditPhaseModal, ResourceModal } from '../modals';
import { Requirement } from '../requirement';

import styles from './PhaseOverview.module.scss';

export const GET_PHASE_OVERVIEW = graphql(`
  query getPhaseOverview($phaseId: VecticeId!) {
    getPhaseById(id: $phaseId) {
      name
      parent {
        vecticeId
      }
      vecticeId
      description
      enforceRequirementsAsSections
    }
  }
`);

export type PhaseOverviewModal = 'creation' | 'edit' | null;

type PhaseOverviewProps = {
  phaseId: string;
};

export const PhaseOverview = ({ phaseId }: PhaseOverviewProps) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });

  const [showModal, setShowModal] = useState<PhaseOverviewModal>(null);

  const closeModal = () => setShowModal(null);

  const { creating, createStep, checkStepNameAvailability, steps, reorderStep } = useSteps(phaseId);

  const onDragEnd = (result: DropResult) => {
    if (!steps || !result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    reorderStep(result.source.index, result.destination.index);
  };

  const { data: phaseData, loading } = useQuery(GET_PHASE_OVERVIEW, {
    fetchPolicy: 'cache-and-network',
    variables: { phaseId },
  });
  const phase = phaseData?.getPhaseById;

  const [updatePhase] = useMutation(UPDATE_PHASE, {
    onCompleted: (data: { updatePhase: Phase }) => {
      if (data.updatePhase) {
        message.success(
          $t({
            id: 'component.action.updatePhase.successMessage',
            defaultMessage: 'The settings have been saved',
          }),
        );
      }
    },
    onError: (error) => message.error(error.message),
  });

  const onEnforceRequirements = (enforceRequirementsAsSections: boolean) => {
    if (phase) {
      updatePhase({
        variables: {
          id: phase.vecticeId,
          updateModel: {
            enforceRequirementsAsSections,
          },
        },
      });
    }
  };

  const requirementsMenu = (
    <Menu className={styles.requirementsMenu} onClick={(e) => e.stopPropagation()}>
      <Toggle
        checked={phase?.enforceRequirementsAsSections}
        label={
          <>
            <Typography variant="callout" color="primary" paragraph>
              {$t({
                id: 'phase.requirements.enforceRequirements.label',
                defaultMessage: 'Use requirements to structure future iterations',
              })}
            </Typography>
            <Typography variant="footnote" color="tertiary" paragraph>
              {$t({
                id: 'phase.requirements.enforceRequirements.hint',
                defaultMessage:
                  'Requirements will be used as section titles and descriptions to guide future iterations of this phase.',
              })}
            </Typography>
          </>
        }
        onToggle={onEnforceRequirements}
      />
    </Menu>
  );

  return (
    <>
      <WithLoading loading={loading && !phase}>
        <Card className={styles.card} inert>
          <Section
            label={(ariaId) => (
              <FlexContainer align="center" justify="space-between">
                <Typography id={ariaId} component="h3" variant="callout" color="primary" weight="semi-bold">
                  {$t({ id: 'phase.name.edit', defaultMessage: 'Phase name' })}
                </Typography>
                {canEdit && (
                  <EditButton onClick={() => setShowModal('edit')} resourceType={VecticeResourceType.PHASE} />
                )}
              </FlexContainer>
            )}
          >
            <Typography variant="callout" color="primary" paragraph>
              {phase?.name}
            </Typography>
          </Section>

          <Section
            label={$t({ id: 'phase.description.edit', defaultMessage: 'Description' })}
            className={styles.sectionContainer}
          >
            {phase?.description ? (
              <Typography className={styles.description} variant="callout" color="primary" paragraph>
                {phase?.description}
              </Typography>
            ) : (
              <Typography variant="callout" color="disabled" paragraph>
                {$t({ id: 'asset.details.description.empty', defaultMessage: 'No description' })}
              </Typography>
            )}
          </Section>
        </Card>
        <WithFeatureFlags featureFlag="phase-characteristics-section">
          <Card className={cn(styles.card, styles.characteristics)} inert>
            <div className={styles.disabledOverlay} />
            <Section
              label={(ariaId) => (
                <FlexContainer>
                  <Typography id={ariaId} component="h3" variant="callout" color="primary" weight="semi-bold">
                    {$t({ id: 'phase.overview.characteristics', defaultMessage: 'Phase characteristics' })}
                  </Typography>
                  <ComingSoonBadge className={styles.comingSoon} />
                </FlexContainer>
              )}
            >
              <FlexContainer direction="column" gap={12}>
                <Toggle
                  checked={false}
                  className={styles.toggle}
                  label={
                    <>
                      <Typography variant="footnote" color="primary" paragraph weight="semi-bold">
                        {$t({
                          id: 'phase.characteristics1.label',
                          defaultMessage: 'Phase requires approval before completion',
                        })}
                      </Typography>
                      <Typography variant="footnote" color="tertiary" paragraph>
                        {$t({
                          id: 'phase.characteristics1.hint',
                          defaultMessage:
                            'This phase requires review and approval before it can be marked as complete, however progress to the next phase is not blocked.',
                        })}
                      </Typography>
                    </>
                  }
                  toggleSize="sm"
                  onToggle={() => null}
                />
                <Toggle
                  checked={false}
                  className={styles.toggle}
                  label={
                    <>
                      <Typography variant="footnote" color="primary" paragraph weight="semi-bold">
                        {$t({
                          id: 'phase.characteristics2.label',
                          defaultMessage: 'Next phases locked until this phase is completed',
                        })}
                      </Typography>
                      <Typography variant="footnote" color="tertiary" paragraph>
                        {$t({
                          id: 'phase.characteristics2.hint',
                          defaultMessage:
                            'The next phases will remain locked until this phase is approved and complete.',
                        })}
                      </Typography>
                    </>
                  }
                  toggleSize="sm"
                  onToggle={() => null}
                />
                <Toggle
                  checked={false}
                  className={styles.toggle}
                  label={
                    <>
                      <Typography variant="footnote" color="primary" paragraph weight="semi-bold">
                        {$t({ id: 'phase.characteristics3.label', defaultMessage: 'Phase locked after completion' })}
                      </Typography>
                      <Typography variant="footnote" color="tertiary" paragraph>
                        {$t({
                          id: 'phase.characteristics3.hint',
                          defaultMessage: 'Once complete, this phase is locked and can no longer be edited.',
                        })}
                      </Typography>
                    </>
                  }
                  toggleSize="sm"
                  onToggle={() => null}
                />
              </FlexContainer>
            </Section>
          </Card>
        </WithFeatureFlags>
        <Card className={styles.card} inert>
          <Section
            label={(ariaId) => (
              <FlexContainer justify="space-between">
                <FlexContainer>
                  <Typography id={ariaId} component="h3" variant="callout" color="primary" weight="semi-bold">
                    {$t({ id: 'phase.overview.section.requirements', defaultMessage: 'Requirements' })}
                  </Typography>
                  <Tooltip
                    placement="right"
                    text={$t({
                      id: 'phase.overview.section.requirements.tooltip',
                      defaultMessage: 'Requirements guide your teams by defining the criteria to complete each phase.',
                    })}
                  >
                    <Icon icon={HelpIcon} size={16} color="gray" />
                  </Tooltip>
                </FlexContainer>
                {canEdit && (
                  <FlexContainer>
                    <Button variant="white" leftIcon={CreateIcon} onClick={() => setShowModal('creation')}>
                      {$t({ id: 'phase.overview.section.requirements.create', defaultMessage: 'Create requirement' })}
                    </Button>
                    <MoreActions moreActionsMenu={requirementsMenu} className={styles.requirementsMenuTrigger} />
                  </FlexContainer>
                )}
              </FlexContainer>
            )}
          >
            {!canEdit && !steps?.length && (
              <Typography variant="footnote" color="disabled" paragraph>
                {$t({
                  id: 'phase.overview.section.requirements.empty',
                  defaultMessage: 'No requirements have been defined for this phase.',
                })}
              </Typography>
            )}
            {canEdit && !steps?.length && (
              <Card className={styles.action} inert role="button" onClick={() => setShowModal('creation')}>
                <Typography variant="callout" weight="semi-bold" color="primary" className={styles.action_title}>
                  {$t({
                    id: 'phase.overview.section.suggestedActions.requirements.title',
                    defaultMessage: 'Create phase requirements',
                  })}
                </Typography>
                <Typography variant="footnote" color="tertiary" paragraph>
                  {$t({
                    id: 'phase.overview.section.suggestedActions.requirements.description',
                    defaultMessage: 'Guide your teams to successfully complete this phase by defining requirements.',
                  })}
                </Typography>
              </Card>
            )}
            {!!steps?.length && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="phase-steps-list">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      aria-label="Step Definitions"
                      role="list"
                      className={styles.requirements}
                      {...droppableProvided.droppableProps}
                    >
                      {steps.map((step, index) => (
                        <Draggable key={step.id} draggableId={`${step.id}`} index={index}>
                          {(draggableProvided, snapshot) => (
                            <FlexContainer
                              ref={draggableProvided.innerRef}
                              className={cn(styles.requirementContainer, { [styles.isDragging]: snapshot.isDragging })}
                              {...draggableProvided.draggableProps}
                            >
                              {canEdit && (
                                <div
                                  className={cn(styles.dragHandle, { isDragging: snapshot.isDragging })}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  <Icon icon={DragHandleIcon} size={20} />
                                </div>
                              )}
                              <div className={styles.step} role="listitem">
                                <Requirement phaseId={phase?.vecticeId} readOnly={!canEdit} requirement={step} />
                              </div>
                            </FlexContainer>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Section>
        </Card>
      </WithLoading>
      {showModal === 'edit' && phase && (
        <EditPhaseModal phase={phase} projectId={phase.parent.vecticeId} onClose={closeModal} />
      )}
      {showModal === 'creation' && (
        <ResourceModal
          loading={creating}
          descriptionPlaceholder={$t({
            id: 'phase.requirement.placeholder',
            defaultMessage: 'Describe the outcome of this requirement',
          })}
          resourceType={VecticeResourceType.REQUIREMENT}
          withDescription
          checkNameAvailability={checkStepNameAvailability()}
          onClose={closeModal}
          onSubmit={(createModel) =>
            createStep({
              variables: {
                parentId: phaseId,
                createModel,
              },
            })
          }
        />
      )}
    </>
  );
};
