import { graphql } from '../../gql';

export const UPDATE_MODEL_TYPE = graphql(`
  mutation updateModelType($id: VecticeId!, $type: ModelType!) {
    updateModel(modelId: $id, model: { type: $type }) {
      vecticeId
      type
    }
  }
`);
