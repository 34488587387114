import Portal from '@rc-component/portal';
import cn from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { ReactComponent as DecreaseIcon } from '../../assets/icons/interface/ic-chevron-left.svg';
import { ReactComponent as IncrementIcon } from '../../assets/icons/interface/ic-chevron-right.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/interface/ic-download.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/interface/ic-remove.svg';
import { Button } from '../button';
import { FlexContainer } from '../containers';
import { Typography } from '../typography';

import Mask from './Mask';

import styles from './Lightbox.module.scss';

export interface LightboxProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'content' | 'title'> {
  content: ReactNode;
  fullscreen?: boolean;
  scrollable?: boolean;
  title?: ReactNode;
  onClose: () => void;
  onDownload?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  nextDisabled?: boolean;
  previousDisabled?: boolean;
}

export const Lightbox = ({
  className,
  content,
  fullscreen,
  scrollable,
  title,
  onClose,
  onDownload,
  onNext,
  onPrevious,
  nextDisabled,
  previousDisabled,
  ...props
}: LightboxProps) => {
  const [showActions, setShowActions] = useState(true);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function showActions() {
      setShowActions(true);
    }

    function hideActions() {
      setShowActions(false);
    }

    document.addEventListener('mouseenter', showActions);
    document.addEventListener('touchstart', showActions);
    document.addEventListener('mouseleave', hideActions);
    return () => {
      document.removeEventListener('mouseenter', showActions);
      document.removeEventListener('touchstart', showActions);
      document.removeEventListener('mouseleave', hideActions);
    };
  }, []);

  useEffect(() => {
    function onWrapperKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        e.stopPropagation();
        onClose();
      }
    }

    document.addEventListener('keydown', onWrapperKeyDown, true);
    return () => document.removeEventListener('keydown', onWrapperKeyDown, true);
  }, []);

  const onWrapperClick = (e: React.SyntheticEvent) => {
    if (wrapperRef.current === e.target) {
      onClose();
    }
  };

  return (
    <Portal open={true} autoDestroy>
      <Mask prefixCls="vectice" visible={true} />
      <div className={cn(styles.lightbox, className)} role="dialog" {...props}>
        <div
          ref={wrapperRef}
          onClick={onWrapperClick}
          tabIndex={-1}
          className={cn(styles.content, { [styles.fullscreen]: fullscreen, [styles.scrollable]: scrollable })}
        >
          {content}
        </div>
        <div
          className={cn(styles.actions, {
            [styles.hidden]: !showActions,
            [styles.visible]: showActions,
          })}
        >
          <FlexContainer className={styles.header} align="flex-start">
            {title && (
              <Typography className={styles.title} ellipsis component="div" variant="heading3" color="white">
                {title}
              </Typography>
            )}
            <div className={styles.header_actions}>
              {onDownload && (
                <Button aria-label="Download" leftIcon={DownloadIcon} variant="white" onClick={onDownload} />
              )}
              <Button variant="white" aria-label="Close" leftIcon={CloseIcon} onClick={onClose} />
            </div>
          </FlexContainer>
          <div className={styles.footnote} />
          {onPrevious && (
            <Button
              aria-label="Previous"
              className={cn(styles.control, styles.decrease)}
              color="gray"
              disabled={previousDisabled}
              leftIcon={DecreaseIcon}
              size="lg"
              variant="white"
              onClick={onPrevious}
            />
          )}
          {onNext && (
            <Button
              aria-label="Next"
              className={cn(styles.control, styles.increase)}
              color="gray"
              disabled={nextDisabled}
              leftIcon={IncrementIcon}
              size="lg"
              variant="white"
              onClick={onNext}
            />
          )}
        </div>
      </div>
    </Portal>
  );
};
