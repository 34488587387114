import React from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as PhaseIcon } from '../../../assets/icons/specials/ic-phase.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { PhaseTooltip } from './PhaseTooltip';

interface PhaseLinkProps {
  name?: string | null;
  resourceId?: string | null;
  tooltipPlacement?: TooltipProps['placement'];
}

export const PhaseLink = ({ name, resourceId, tooltipPlacement = 'topLeft' }: PhaseLinkProps) => (
  <PhaseTooltip id={resourceId} placement={tooltipPlacement}>
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.PHASE, { phaseId: resourceId }) : undefined}
      icon={PhaseIcon}
    />
  </PhaseTooltip>
);
