import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

interface NoFindingProps {
  modelVersionId?: string;
}

export const NoFindings = ({ modelVersionId }: NoFindingProps) => (
  <EmptyList
    icon={VecticeIcon}
    message={$t({ id: 'NoIssues.noIssues', defaultMessage: 'No issues yet!' })}
    hint={
      <>
        {modelVersionId
          ? $t({
              id: 'NoIssues.modelVersion.hint',
              defaultMessage: 'Every issue of this model version will be visible here.',
            })
          : $t({
              id: 'NoIssues.project.hint',
              defaultMessage: 'Every issue of this project will be visible here.',
            })}
      </>
    }
  />
);
