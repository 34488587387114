import React, { CSSProperties } from 'react';

interface GridCellProps extends VecticeHTMLProps<HTMLDivElement> {
  alignSelf?: CSSProperties['alignSelf'];
  rows?: number;
  columns?: number;
  justifySelf?: CSSProperties['justifySelf'];
}

export const GridCell = ({ alignSelf, rows = 1, columns = 1, justifySelf, style, ...props }: GridCellProps) => (
  <div
    style={{
      alignSelf: alignSelf,
      justifySelf: justifySelf,
      gridRow: `span ${rows} / auto`,
      gridColumn: `span ${columns} / auto`,
      ...style,
    }}
    {...props}
  />
);
