import { graphql } from '../../gql';

export const GET_IDENTITY_PROVIDER_LIST = graphql(`
  query getIdentityProviderList {
    getIdentityProviderList {
      alias
      ...authMethodCard
    }
  }
`);

export const GET_IDENTITY_PROVIDER = graphql(`
  query getIdentityProvider($alias: String!) {
    getIdentityProvider(alias: $alias) {
      alias
      displayName
      enabled
      config {
        ... on KeycloakSAMLConfig {
          entityId
          singleLogoutServiceUrl
          singleSignOnServiceUrl
          forceAuthn
        }
        ... on KeycloakSocialConfig {
          clientId
          clientSecret
          defaultScope
        }
      }
    }
  }
`);

export const CREATE_IDENTITY_PROVIDER = graphql(`
  mutation createIdentityProvider(
    $identityProvider: KeycloakIdentityProviderCreate!
    $socialConfig: KeycloakSocialConfigCreate
    $samlConfig: KeycloakSAMLConfigCreate
  ) {
    createIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {
      alias
      providerId
      displayName
      enabled
      config {
        ... on KeycloakSAMLConfig {
          entityId
          singleLogoutServiceUrl
          singleSignOnServiceUrl
          forceAuthn
        }
        ... on KeycloakSocialConfig {
          clientId
          clientSecret
          defaultScope
        }
      }
    }
  }
`);

export const UPDATE_IDENTITY_PROVIDER = graphql(`
  mutation updateIdentityProvider(
    $identityProvider: KeycloakIdentityProviderUpdate!
    $socialConfig: KeycloakSocialConfigUpdate
    $samlConfig: KeycloakSAMLConfigUpdate
  ) {
    updateIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {
      alias
      providerId
      displayName
      enabled
      config {
        ... on KeycloakSAMLConfig {
          entityId
          singleLogoutServiceUrl
          singleSignOnServiceUrl
          forceAuthn
        }
        ... on KeycloakSocialConfig {
          clientId
          clientSecret
          defaultScope
        }
      }
    }
  }
`);
