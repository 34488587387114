import { graphql } from '../../gql';

export const GET_ORGANIZATION_WORKSPACE_LIST = graphql(`
  query getOrganizationWorkspaceList(
    $pageIndex: Int!
    $pageSize: Int!
    $order: ListOrderInput
    $search: String!
    $type: [WorkspaceType!]
  ) {
    getOrganizationWorkspaceList(
      page: { size: $pageSize, index: $pageIndex }
      order: $order
      filters: { type: $type, searchFilter: { search: $search, fields: ["name"] } }
    ) {
      items {
        vecticeId
        type
        name
        description
        userCount(filters: { includeDisabledUsers: false })
        projectCount
        updatedDate
        createdDate
      }
      total
    }
  }
`);
