import React from 'react';
import { Link } from 'react-router-dom';

import { getRoutePath, VecticeRoutes } from '../../routes';
import { Logo, Navbar } from '../../ui';

import { ProfileMenu } from './profile';

import styles from './Navbar.module.scss';

export const AdministrationNavbar = () => (
  <Navbar
    logo={
      <Link to={getRoutePath(VecticeRoutes.ADMINISTRATION)}>
        <Logo aria-label="Navigate home" />
      </Link>
    }
  >
    <div className={styles.right_nav}>
      <ProfileMenu />
    </div>
  </Navbar>
);
