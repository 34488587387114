import cn from 'classnames';
import React, { ElementType } from 'react';

import styles from './Card.module.scss';

export interface CardProps extends VecticeHTMLProps<any> {
  inert?: boolean;
}

export const Card = <E extends ElementType>({
  component,
  inert,
  className,
  ...props
}: CardProps & WithComponentProps<E>) => {
  const Element: ElementType = component || 'div';

  return <Element className={cn(styles.card, { [styles.inert]: inert }, className)} {...props} />;
};
