import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Filters.module.scss';

interface FilterProps {
  children?: ReactNode;
}

export const Filters = ({ children }: FilterProps) => <div className={cn(styles.filters)}>{children}</div>;
