import React, { useMemo } from 'react';

import { round } from '../../utils';

export const formatPercentage = (value?: number | null) => {
  if (value === null || value === undefined) {
    return $t({ id: 'formatters.formatPercentage.noValue', defaultMessage: 'N/A' });
  }

  if (value > 0 && value < 0.1) {
    return '0.1%';
  }

  if (value > 99.9 && value < 100) {
    return '99.9%';
  }

  return `${round(value, 1)}%`;
};

export const getPercentageTooltip = (value?: number | null) => {
  if (value === null || value === undefined) {
    return null;
  }

  return `${round(value)}%`;
};

export interface FormatPercentageProps {
  value?: number | null;
}

export const FormatPercentage = ({ value: percentage }: FormatPercentageProps) => {
  const formattedPercentage = useMemo(() => formatPercentage(percentage), [percentage]);

  if (!formattedPercentage) {
    return null;
  }

  return <>{formattedPercentage}</>;
};
