import cn from 'classnames';
import React, { CSSProperties, ElementType } from 'react';

import styles from './FlexContainer.module.scss';

interface FlexContainerProps extends VecticeHTMLProps<any> {
  align?: CSSProperties['alignItems'];
  direction?: 'row' | 'column';
  gap?: number;
  gutterBottom?: boolean;
  inline?: boolean;
  justify?: CSSProperties['justifyContent'];
  wrap?: CSSProperties['flexWrap'];
}

export const FlexContainer = React.forwardRef(
  <E extends ElementType>(
    {
      align,
      className,
      component: Component = 'div',
      direction = 'row',
      gap = 8,
      gutterBottom,
      inline,
      justify,
      wrap,
      style,
      ...props
    }: FlexContainerProps & WithComponentProps<E>,
    ref?: React.LegacyRef<E>,
  ) => (
    <Component
      ref={ref}
      className={cn(
        styles.container,
        styles[direction],
        {
          [styles.gutterBottom]: gutterBottom,
          [styles.inline]: inline,
        },
        className,
      )}
      style={{ gap, alignItems: align, justifyContent: justify, flexWrap: wrap, ...style }}
      {...props}
    />
  ),
);
