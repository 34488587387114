import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';
import { DocumentationLink } from '../links';

export const NoWorkspaces = () => (
  <EmptyList
    borderless
    icon={VecticeIcon}
    message={$t({ id: 'NoWorkspaces.message', defaultMessage: 'No workspaces!' })}
    hint={
      <>
        {$t({
          id: 'NoWorkspaces.hint_1',
          defaultMessage:
            'You do not have access to any workspace. Admins can create workspaces and manage workspace members.',
        })}
        <br />
        {$t({
          id: 'NoWorkspaces.hint_2',
          defaultMessage: 'Reach out to a Vectice admin to add you to a team workspace.',
        })}
        <br />
        <DocumentationLink asTypography url="workspace">
          {$t({ id: 'NoWorkspaces.readOurDocs', defaultMessage: 'Read our docs to learn more.' })}
        </DocumentationLink>
      </>
    }
  />
);
