import React, { PropsWithChildren, useMemo, useState, MouseEvent } from 'react';

import { Placement, Tooltip } from '../../ui';
import { copyToClipboard } from '../../utils';

interface WithCopyOnClickProps {
  value?: string | null;
  tooltipPlacement?: Placement;
}

export const WithCopyOnClick = ({ value, tooltipPlacement, children }: PropsWithChildren<WithCopyOnClickProps>) => {
  const [tooltip, setTooltip] = useState(
    $t({ id: 'component.WithCopyOnClick.copyToClipboard', defaultMessage: 'Copy to clipboard' }),
  );

  const handleVisibleChange = (isVisible: boolean) => {
    if (isVisible) {
      setTooltip($t({ id: 'component.WithCopyOnClick.copyToClipboard', defaultMessage: 'Copy to clipboard' }));
    }
  };

  const component = useMemo(() => {
    const child = Array.isArray(children) ? children[0] : children;

    const { onClick, ...otherProps } = child.props;

    return {
      ...child,
      props: {
        ...otherProps,
        onClick: (e: MouseEvent) => {
          onClick?.(e);
          if (value) {
            copyToClipboard(value)
              .then(() => setTooltip($t({ id: 'component.WithCopyOnClick.successMessage', defaultMessage: 'Copied!' })))
              .catch(() =>
                setTooltip($t({ id: 'component.WithCopyOnClick.errorMessage', defaultMessage: 'Can not copy!' })),
              );
          }
        },
      },
    };
  }, [value, children]);

  return (
    <Tooltip text={tooltip} trigger="hover" placement={tooltipPlacement} onVisibleChange={handleVisibleChange}>
      {component}
    </Tooltip>
  );
};
