import React from 'react';

import { UserAddedToWorkspaceContent } from '../../../entities';
import { getAccessibilityLevelDisplayName } from '../../../entities/utils';
import { FormatUserName } from '../../formatters';
import { UserAddedToWorkspace } from '../types';

import { Notification } from './Notification';

interface UserAddedToWorkspaceNotificationProps {
  notification: UserAddedToWorkspace;
}

export const UserAddedToWorkspaceNotification = ({
  notification: { content, creator, createdDate, workspace },
}: UserAddedToWorkspaceNotificationProps) => {
  const parsedContent: UserAddedToWorkspaceContent = JSON.parse(content);

  return (
    <Notification creator={creator} createdDate={createdDate}>
      {$t({
        id: 'notification.userAddedToWorkspace',
        defaultMessage:
          '<bold>{creator}</bold> has been added to the <bold>{workspace}</bold> workspace as a <bold>{workspaceRole}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          workspace: workspace?.name,
          workspaceRole: getAccessibilityLevelDisplayName(parsedContent?.accessibility),
        },
      })}
    </Notification>
  );
};
