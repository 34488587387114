import React, { Component, ReactNode } from 'react';

import { ConnectionLostError, InternalServerError, UnauthenticatedError, VersionMismatchError } from '../errors';

import { ConnectionLostPage } from './ConnectionLostPage';
import { InternalServerErrorPage } from './InternalServerErrorPage';
import { LoggedOutErrorPage } from './LoggedOutErrorPage';
import { UnexpectedErrorPage } from './UnexpectedErrorPage';
import { VersionMismatchPage } from './VersionMismatchPage';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.resetErrorState = this.resetErrorState.bind(this);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  resetErrorState() {
    this.setState({
      hasError: false,
      error: null,
    });
  }

  public render() {
    if (this.state.hasError) {
      if (this.state.error instanceof UnauthenticatedError) {
        return <LoggedOutErrorPage onHandleError={this.resetErrorState} />;
      }
      if (this.state.error instanceof VersionMismatchError) {
        return <VersionMismatchPage />;
      }
      if (this.state.error instanceof ConnectionLostError) {
        return <ConnectionLostPage />;
      }
      if (this.state.error instanceof InternalServerError) {
        return <InternalServerErrorPage error={this.state.error} onHandleError={this.resetErrorState} />;
      }
      return <UnexpectedErrorPage error={this.state.error} onHandleError={this.resetErrorState} />;
    }

    return this.props.children;
  }
}
