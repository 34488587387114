import { graphql } from '../../gql';

export const GET_ITERATION_SECTIONS = graphql(`
  query getIterationStepList($parentId: VecticeId!, $order: ListOrderInput) {
    sections(parentId: $parentId, order: $order, page: { index: 1, size: 100 }) {
      items {
        id
        index
        name
        paginatedArtifacts(page: { index: 1, size: 100 }) {
          total
          items {
            id
            index
            stepId
            type
            ...IterationStepArtifact
          }
        }
        ...IterationStep
      }
    }
  }
`);
