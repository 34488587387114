import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { CHECK_PHASE_NAME_AVAILABILITY } from '../graphql/queries';
import { message } from '../ui';

export const useCheckPhaseNameAvailability = (parentId: string, resourceId?: string) => {
  const [checkNameAvailability] = useLazyQuery(CHECK_PHASE_NAME_AVAILABILITY, {
    fetchPolicy: 'network-only',
    onError: (error) => message.error(error.message),
  });

  const checkPhaseNameAvailability = useCallback(
    async (name: string) => {
      const { data: { checkPhaseNameAvailability } = {} } = await checkNameAvailability({
        variables: {
          name,
          parentId,
          resourceId,
        },
      });

      return !!checkPhaseNameAvailability;
    },
    [parentId],
  );

  return { checkPhaseNameAvailability };
};
