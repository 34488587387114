import classNames from 'classnames';
import React from 'react';

import { Placement, Tooltip, Typography } from '../../../ui';
import { CellSize } from '../config';

import styles from './TextCell.module.scss';

interface PropertyCellProps {
  tooltipPlacement?: Placement;
  value?: string | null;
  size?: CellSize;
}

export const TextCell = ({ tooltipPlacement = 'topLeft', value, size = 'lg' }: PropertyCellProps) => {
  if (!value) return null;

  return (
    <Tooltip placement={tooltipPlacement} text={value || undefined}>
      <Typography className={classNames(styles.text, styles[size])} ellipsis paragraph>
        {value}
      </Typography>
    </Tooltip>
  );
};
