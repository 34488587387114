import React, { PropsWithChildren } from 'react';

import { Loading } from './Loading';

import styles from './LoadingOverlay.module.scss';

interface LoadingOverlayProps {
  loading?: boolean;
  size?: number;
}

export const LoadingOverlay = ({ loading, size, children }: PropsWithChildren<LoadingOverlayProps>) => (
  <div className={styles.container} aria-busy={loading}>
    {children}
    {loading && (
      <div className={styles.overlay}>
        <Loading size={size} />
      </div>
    )}
  </div>
);
