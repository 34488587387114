import cn from 'classnames';
import React from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import { getProjectStatusColor, getProjectStatusDisplayName } from '../../entities/utils';
import { ProjectStatus as ProjectStatusEnum } from '../../gql/graphql';
import { DropdownTrigger } from '../../ui';
import { getProjectStatusIcon } from '../../utils';

import styles from './AssetStatus.module.scss';

interface ProjectStatusProps extends ButtonProps {
  status?: ProjectStatusEnum;
}

export const ProjectStatus = ({ status, className, ...props }: ProjectStatusProps) => {
  if (!status) {
    return null;
  }

  return (
    <DropdownTrigger
      variant="primary"
      leftIcon={getProjectStatusIcon(status)}
      className={cn(styles[getProjectStatusColor(status)], className)}
      disabled
      {...props}
    >
      {getProjectStatusDisplayName(status)}
    </DropdownTrigger>
  );
};
