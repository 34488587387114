import { graphql } from '../../gql';

export const GET_JOB_ARTIFACT_BY_JOB_RUN_ID = graphql(`
  query getJobArtifactByJobRunId($lineageId: Float!) {
    getJobArtifactByJobRunId(lineageId: $lineageId, order: { field: "createdDate", direction: ASC }) {
      items {
        id
        version
        lineageArtifactType
        lineageId
        artifactType
        ...lineageAssetFragment
        ...lineageCodeFragment
      }
    }
  }
`);
