import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';

import { ReactComponent as TransferIcon } from '../../assets/icons/interface/ic-users-actions-up-down.svg';
import { Project, User, Workspace, WorkspaceType } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { message } from '../../ui';
import { FormatUserName } from '../formatters';
import { TransferProjectOwnershipModal } from '../modals';

import { ActionCard } from './internals';
import { TRANSFER_PROJECT_OWNERSHIP } from './transferProjectOwnership.mutation';

interface TransferProjectOwnershipProps {
  project: Pick<Project, 'vecticeId' | 'name' | 'ownerId'>;
  workspace?: Pick<Workspace, 'vecticeId' | 'name' | 'type'> | null;
}

export const TransferProjectOwnership = ({ project, workspace }: TransferProjectOwnershipProps) => {
  const [showModal, setShowModal] = useState(false);

  const [updateProject] = useMutation(TRANSFER_PROJECT_OWNERSHIP);

  const handleTransfer = useCallback(
    async (newOwner: Pick<User, 'id'> & UserIdentity) => {
      const { errors } = await updateProject({
        variables: {
          projectId: project.vecticeId,
          data: {
            ownerId: +newOwner.id,
          },
        },
      });
      if (!errors) {
        message.success(
          $t({
            id: 'component.action.transferProjectOwnership.successMessage',
            defaultMessage: 'Ownership of {project} has been transferred to {user}',
            values: {
              project: project.name,
              user: <FormatUserName user={newOwner} />,
            },
          }),
        );
      } else {
        errors.forEach((error) => message.error(error.message));
      }
      setShowModal(false);
    },
    [project.vecticeId, updateProject],
  );

  return (
    <>
      <ActionCard
        buttonIcon={TransferIcon}
        buttonLabel={$t({
          id: 'component.action.transferProjectOwnership.button',
          defaultMessage: 'Transfer Ownership',
        })}
        hint={
          workspace?.type === WorkspaceType.Public
            ? $t({
                id: 'component.action.transferProjectOwnership.publicWorkspace.hint',
                defaultMessage:
                  'Project owners and organization admins can transfer ownership of project to other users.',
              })
            : $t({
                id: 'component.action.transferProjectOwnership.privateWorkspace.hint',
                defaultMessage:
                  'Project owners and workspace managers can transfer ownership of project to other workspace members.',
              })
        }
        title={$t({ id: 'component.action.transferProjectOwnership.title', defaultMessage: 'Transfer Ownership' })}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <TransferProjectOwnershipModal
          currentOwnerId={project.ownerId}
          workspace={workspace}
          onTransfer={handleTransfer}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
