import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export const useIsTabActive = () => {
  const location = useLocation();

  const isTabActive = useCallback(
    (pathname: string, subPathnames?: string[], end = false) => {
      if (matchPath({ path: pathname, end }, location.pathname)) {
        return true;
      }
      if (subPathnames) {
        if (subPathnames.some((path) => matchPath({ path, end }, location.pathname))) {
          return true;
        }
      }
      return false;
    },
    [location],
  );

  return { isTabActive };
};
