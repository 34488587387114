import cn from 'classnames';
import React, { ElementType } from 'react';

import styles from './Divider.module.scss';

interface DividerProps {
  vertical?: boolean;
}

export const Divider = <E extends ElementType>({
  className,
  component: Component = 'div',
  vertical,
  ...props
}: DividerProps & VecticeHTMLProps<HTMLLIElement> & WithComponentProps<E>) => (
  <Component className={cn(styles.divider, { [styles.vertical]: vertical }, className)} {...props} />
);
