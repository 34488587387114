import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { AccessRequestNotificationContent } from '../../../entities';
import { graphql } from '../../../gql';
import { WorkspaceAccessRequest, WorkspaceAccessRequestStatus } from '../../../gql/graphql';
import { useMount } from '../../../hooks';
import { Button, Loading, message, Typography } from '../../../ui';
import { FormatUserName } from '../../formatters';
import { UserNotificationNewWorkspaceAccessRequest } from '../types';

import { Notification } from './Notification';

import styles from './NewWorkspaceAccessRequestNotification.module.scss';

const GET_ACCESS_REQUEST = graphql(`
  query getWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!) {
    getWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId) {
      id
      status
    }
  }
`);

const RESOLVE_WORKSPACE_ACCESS_REQUEST = graphql(`
  mutation resolveWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!, $approve: Boolean!) {
    resolveWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId, approve: $approve) {
      id
      status
    }
  }
`);

interface InviteAcceptedNotificationProps {
  notification: UserNotificationNewWorkspaceAccessRequest;
}

export const NewWorkspaceAccessRequestNotification = ({
  notification: { creator, workspace, content, createdDate },
}: InviteAcceptedNotificationProps) => {
  const [requestId, setRequestId] = useState<number>();
  const [accessRequest, setAccessRequest] = useState<Pick<WorkspaceAccessRequest, 'id' | 'status'> | null>(null);
  const [resolveError, setResolveError] = useState<string>();

  const [fetchAccessRequest, { loading }] = useLazyQuery(GET_ACCESS_REQUEST, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getWorkspaceAccessRequest }) => {
      setAccessRequest(getWorkspaceAccessRequest);
    },
  });

  const [resolveAccessRequest] = useMutation(RESOLVE_WORKSPACE_ACCESS_REQUEST, {
    optimisticResponse: (variables) => ({
      resolveWorkspaceAccessRequest: {
        id: variables.requestId.toString(),
        status: variables.approve ? WorkspaceAccessRequestStatus.Approved : WorkspaceAccessRequestStatus.Rejected,
      },
    }),
    onCompleted: ({ resolveWorkspaceAccessRequest }) => {
      setAccessRequest(resolveWorkspaceAccessRequest);
      if (resolveWorkspaceAccessRequest.status === WorkspaceAccessRequestStatus.Approved) {
        message.success(
          $t({
            id: 'workspaceAccessRequest.notification.approval.toastMessage',
            defaultMessage: 'You approved {user}\'s access to Workspace "{workspace}"',
            values: {
              user: <FormatUserName user={creator} />,
              workspace: workspace?.name,
            },
          }),
        );
      } else {
        message.success(
          $t({
            id: 'workspaceAccessRequest.notification.denial.toastMessage',
            defaultMessage: 'You denied {user}\'s access to Workspace "{workspace}"',
            values: {
              user: <FormatUserName user={creator} />,
              workspace: workspace?.name,
            },
          }),
        );
      }
    },
    onError: (error) => {
      setAccessRequest(null);
      setResolveError(error.message);
      message.error(error.message);
    },
  });

  const resolveRequest = (approve: boolean) => {
    if (requestId && workspace) {
      resolveAccessRequest({
        variables: {
          workspaceId: workspace.vecticeId,
          requestId,
          approve,
        },
      });
    }
  };

  useMount(() => {
    try {
      if (workspace) {
        const parsedContent: AccessRequestNotificationContent = JSON.parse(content);
        setRequestId(parsedContent.requestId);
        fetchAccessRequest({
          variables: {
            workspaceId: workspace.vecticeId,
            requestId: parsedContent.requestId,
          },
        });
      }
    } catch (e) {
      logger.error(e);
    }
  });

  if (!workspace) return null;

  let extra = null;

  if (loading) {
    extra = <Loading size={24} />;
  } else if (resolveError) {
    extra = (
      <Typography variant="callout" component="div" color="disabled">
        {resolveError}
      </Typography>
    );
  } else if (accessRequest?.status === WorkspaceAccessRequestStatus.Pending) {
    extra = (
      <div className={styles.buttons}>
        <Button size="sm" onClick={() => resolveRequest(true)}>
          {$t({ id: 'notification.accessRequest.approve', defaultMessage: 'Approve' })}
        </Button>
        <Button variant="outlined" size="sm" onClick={() => resolveRequest(false)}>
          {$t({ id: 'notification.accessRequest.reject', defaultMessage: 'Reject' })}
        </Button>
      </div>
    );
  } else if (accessRequest?.status === WorkspaceAccessRequestStatus.Approved) {
    extra = (
      <Typography variant="callout" component="div" color="disabled">
        {$t({ id: 'notification.accessRequest.request.approve', defaultMessage: 'Request Approved' })}
      </Typography>
    );
  } else if (accessRequest?.status === WorkspaceAccessRequestStatus.Rejected) {
    extra = (
      <Typography variant="callout" component="div" color="disabled">
        {$t({ id: 'notification.accessRequest.request.reject', defaultMessage: 'Request Rejected' })}
      </Typography>
    );
  }

  return (
    <Notification creator={creator} createdDate={createdDate} extra={extra}>
      {$t({
        id: 'notification.accessRequest.new',
        defaultMessage: '<bold>{creator}</bold> is requesting access to Workspace <bold>{workspace}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          workspace: workspace.name,
        },
      })}
    </Notification>
  );
};
