import { useQuery } from '@apollo/client';
import { DonutChart, sum } from '@vectice/data-viz';
import React, { useMemo } from 'react';

import { getProjectStatusDisplayName } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { ProjectStatus, ProjectStatusWithCountOutput } from '../../../gql/graphql';
import { FlexContainer, Typography } from '../../../ui';
import { getPercentages } from '../../../utils';
import { ColorSwatch } from '../colors';
import { DashboardCard, Legend } from '../internals';

import styles from './ProjectStatusesBreakdown.module.scss';

export const GET_PROJECT_STATUSES_BREAKDOWN = graphql(`
  query getProjectStatusesBreakdown($workspaceIdList: [VecticeId!]!) {
    getProjectStatusCounts(workspaceIdList: $workspaceIdList) {
      items {
        status
        count
      }
      total
    }
  }
`);

const colors = {
  [ProjectStatus.NotStarted]: ColorSwatch.iron,
  [ProjectStatus.InProgress]: ColorSwatch.selectiveYellow,
  [ProjectStatus.Completed]: ColorSwatch.summerGreen,
};

const statusOrder = Object.keys(colors);

const getLabel = (datum: ProjectStatusWithCountOutput) => getProjectStatusDisplayName(datum.status);
const getCount = (datum: ProjectStatusWithCountOutput) => datum.count;
const getColor = (datum: ProjectStatusWithCountOutput) => colors[datum.status];
const sort = (a: ProjectStatusWithCountOutput, b: ProjectStatusWithCountOutput) =>
  statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
const size = { height: 150, width: 150 };

interface ProjectStatusesBreakdownProps {
  workspaceIdList: string[];
}

export const ProjectStatusesBreakdown = ({ workspaceIdList }: ProjectStatusesBreakdownProps) => {
  const { data, loading } = useQuery(GET_PROJECT_STATUSES_BREAKDOWN, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdList,
    },
  });

  const { items, percentages, total } = useMemo(() => {
    const items = (data?.getProjectStatusCounts.items ?? []).slice().sort(sort);
    const total = sum(items, getCount);
    const percentages = getPercentages(
      items.map(({ count }) => count),
      total,
    );

    return {
      items,
      percentages,
      total,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.ProjectStatusesBreakdown.title', defaultMessage: 'Project Status Breakdown' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.ProjectStatusesBreakdown.hint',
        defaultMessage: 'The percentage of projects for each project status.',
      })}
      loading={loading}
    >
      <FlexContainer gap={16} direction="column" align="center">
        <figure aria-describedby="project-status-breakdown-legend">
          <DonutChart
            data={items}
            getLabel={getLabel}
            getValue={getCount}
            getColor={getColor}
            sort={sort}
            centerContent={
              <>
                <Typography component="div" variant="callout" weight="semi-bold" color="primary">
                  {total}
                </Typography>
                <Typography component="div" variant="callout" color="primary">
                  {$t({ id: 'dashboard.ProjectStatusesBreakdown.total', defaultMessage: 'Total' })}
                </Typography>
              </>
            }
            fixed={size}
          />
        </figure>
        {!!items.length && (
          <table id="project-status-breakdown-legend" role="table" className={styles.table}>
            <tbody>
              {items.map((item, index) => (
                <tr role="row" key={item.status}>
                  <td>
                    <Legend color={getColor({ status: item.status, count: 0 })}>
                      {getProjectStatusDisplayName(item.status)}
                    </Legend>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <Typography variant="callout" color="primary" weight="semi-bold">
                      {percentages[index]}%
                    </Typography>
                  </td>
                  <td>
                    <Typography variant="callout" color="primary">
                      {$t({
                        id: 'dashboard.ProjectStatusesBreakdown.projectCount',
                        defaultMessage: `{count, plural, one {<bold>#</bold> Project} other {<bold>#</bold> Projects}}`,
                        values: { count: item.count },
                      })}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </FlexContainer>
    </DashboardCard>
  );
};
