import { graphql } from '../../gql';

export const UPDATE_PHASE = graphql(`
  mutation updatePhase($id: VecticeId!, $updateModel: PhaseUpdateInput!) {
    updatePhase(id: $id, updateModel: $updateModel) {
      vecticeId
      index
      name
      description
      status
      ownerId
      enforceRequirementsAsSections
      owner {
        ...userFields
      }
    }
  }
`);
