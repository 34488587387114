import { graphql } from '../../gql';

export const RENAME_ENTITY_FILE = graphql(`
  mutation renameEntityFile($id: Float!, $newName: String!) {
    renameEntityFile(id: $id, newName: $newName) {
      id
      fileName
    }
  }
`);
