import React from 'react';

import { UserIdentity } from '../../../graphql/fragments';
import { UserAvatar } from '../../asset-display';
import { FormatDate } from '../../formatters';

import styles from './CreatedCell.module.scss';

interface CreatedCellProps {
  date: Date;
  user: UserIdentity;
}

export const CreatedCell = ({ date, user }: CreatedCellProps) => (
  <div className={styles.created}>
    <FormatDate date={date} />
    {$t({ id: 'tables.cell.created.by', defaultMessage: 'by' })}
    <UserAvatar user={user} size="sm" />
  </div>
);
