import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Project, Workspace } from '../../gql/graphql';
import { GET_ALL_USER_WORKSPACES } from '../../graphql/queries';
import { VecticeRoutes, buildLink } from '../../routes';
import { message, ModalForm, Select, Typography } from '../../ui';

import { MOVE_PROJECT } from './moveProject.mutation';

interface Props {
  project: Pick<Project, 'vecticeId' | 'name'>;
  currentWorkspace?: Pick<Workspace, 'vecticeId' | 'name'> | null;
  onClose: () => void;
}

export const MoveProjectModal = ({ project, currentWorkspace, onClose }: Props) => {
  const navigate = useNavigate();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string>();

  const moveProjectLabel = $t({ id: 'modal.project.moveProject', defaultMessage: 'Move Project' });

  const { data, loading } = useQuery(GET_ALL_USER_WORKSPACES, {
    onError: (error) => message.error(error.message),
  });

  const workspaces = data?.getUserWorkspaceList.items ?? [];

  const workspaceOptions = useMemo(() => {
    const _workspaces = [
      ...(workspaces.find((ws) => ws.vecticeId === currentWorkspace?.vecticeId) || !currentWorkspace
        ? []
        : [currentWorkspace]),
      ...workspaces,
    ];
    return [
      {
        id: 'none',
        value: -1,
        label: $t({ id: 'modal.project.selectWorkspace', defaultMessage: 'Select a Workspace' }),
        disabled: true,
        hidden: true,
      } as any,
    ].concat(
      _workspaces.map(({ vecticeId, name }) => ({
        id: `workspace-${vecticeId}`,
        value: vecticeId,
        label: vecticeId === currentWorkspace?.vecticeId ? `${name} (Current)` : name,
        disabled: vecticeId === currentWorkspace?.vecticeId,
      })),
    );
  }, [currentWorkspace, workspaces, currentWorkspace?.vecticeId]);

  const [moveProject, { loading: submitting }] = useMutation(MOVE_PROJECT, {
    onCompleted: ({ moveProject: updatedProject }) => {
      onClose();
      if (updatedProject.vecticeId) {
        const selectedWorkspace = workspaces.find((workspace) => workspace.vecticeId === selectedWorkspaceId);
        message.success(
          $t({
            id: 'modal.project.moveToSelectedWorkspace',
            defaultMessage: '{projectName} project moved to {workspaceName} workspace',
            values: { projectName: project.name, workspaceName: selectedWorkspace?.name },
          }),
        );
        navigate(buildLink(VecticeRoutes.PROJECT_SETTINGS, { projectId: project.vecticeId }));
      }
    },
    onError: (error) => {
      onClose();
      message.error(error.message);
    },
  });

  const confirmMoveProject = async () => {
    if (selectedWorkspaceId) {
      await moveProject({
        variables: {
          projectId: project.vecticeId,
          projectName: project.name,
          targetWorkspaceId: selectedWorkspaceId,
        },
      });
    }
  };

  return (
    <ModalForm
      disabled={!selectedWorkspaceId}
      isSubmitting={submitting}
      title={moveProjectLabel}
      submitLabel={moveProjectLabel}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={confirmMoveProject}
      onClose={onClose}
    >
      <Typography variant="callout" paragraph gutterBottom>
        {$t({
          id: 'modal.project.moveProjectHint',
          defaultMessage:
            'Moving a project to another workspace moves all phases, models, and datasets, associated with it. You can only move projects to workspaces that you are a member of.',
        })}
      </Typography>
      <Typography paragraph gutterBottom>
        {$t({
          id: 'modal.project.fromWorkspace',
          defaultMessage: 'From Workspace: <bold>{name}</bold>',
          values: { name: currentWorkspace?.name },
        })}
      </Typography>
      <Typography paragraph>{$t({ id: 'modal.project.toWorkspace', defaultMessage: 'To Workspace:' })}</Typography>
      <Select
        aria-label="Target Workspace"
        defaultValue={-1}
        disabled={loading}
        gutterBottom
        options={workspaceOptions}
        autoFocus
        onChange={(e) => setSelectedWorkspaceId(e.currentTarget.value)}
      />
    </ModalForm>
  );
};
