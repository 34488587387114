import React, { createContext, ProviderProps } from 'react';

import type { Project, Workspace } from '../gql/graphql';

export interface PageContextContextType {
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'name' | 'type'> | null;
  loading?: boolean;
}

export const PageContextContext = createContext<PageContextContextType | null>(null);

export function usePageContext(nullable: boolean): PageContextContextType | null;
export function usePageContext(): PageContextContextType;
export function usePageContext(allowedToFail?: boolean): PageContextContextType | null {
  const context = React.useContext(PageContextContext);

  if (!context && !allowedToFail) {
    throw new Error('usePageContext must be used within PageContextProvider');
  }

  return context;
}

export const PageContextProvider = (props: ProviderProps<PageContextContextType | null>) => {
  return <PageContextContext.Provider {...props} />;
};
