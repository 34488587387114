import React, { ReactNode, useState } from 'react';

import { Checkbox, ModalPrompt, Typography } from '../../ui';

interface DeletionPromptProps {
  hint?: ReactNode;
  postHint?: ReactNode;
  processing?: boolean;
  resourceName: string;
  resourceType?: string;
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeletionPrompt = ({
  hint,
  postHint,
  processing,
  resourceName,
  resourceType,
  title,
  onClose,
  onConfirm,
}: DeletionPromptProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <ModalPrompt
      confirmLabel={$t({ id: 'modal.delete', defaultMessage: 'Yes, delete' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      destructive
      disabled={!checked}
      processing={processing}
      title={title}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      {hint}
      {resourceType
        ? $t({
            id: 'modal.deletionPrompt.deleteResourceWarning',
            defaultMessage: 'Are you sure you want to delete the {resourceType} <bold>{name}</bold>?',
            values: {
              resourceType,
              name: resourceName,
            },
          })
        : $t({
            id: 'modal.deletionPrompt.deleteResourceWithoutTypeWarning',
            defaultMessage: 'Are you sure you want to delete <bold>{name}</bold>?',
            values: {
              name: resourceName,
            },
          })}
      {postHint}
      <br />
      <Checkbox
        label={
          <Typography variant="body" weight="regular">
            {$t({
              id: 'modal.deletionPrompt.understandAction',
              defaultMessage: 'I understand that this action is <bold>irreversible.</bold>',
            })}
          </Typography>
        }
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
    </ModalPrompt>
  );
};
