export const DEFAULT_ASPECT = 1;
export const DEFAULT_SHAPE = 'round';
export const DEFAULT_QUALITY = 0.4;
export const DEFAULT_MIN_ZOOM = 1;
export const DEFAULT_MAX_ZOOM = 3;
export const DEFAULT_FILL_COLOR = 'white';

export const INIT_ZOOM = 1;
export const INIT_ROTATE = 0;
export const ZOOM_STEP = 0.1;
