import { WorkspaceType } from '../gql/graphql';
import { useRetrievePageContext } from '../hooks';

export const useWithPrivateWorkspace = () => {
  const { workspace, loading } = useRetrievePageContext();

  return {
    allowed: !!workspace && workspace.type !== WorkspaceType.Public,
    loading,
  };
};
