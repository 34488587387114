import React from 'react';

import { useOrgConfig } from '../../context';
import { OrganizationLevel } from '../../gql/graphql';
import { FlexContainer, Typography } from '../../ui';

import styles from './TrialBanner.module.scss';

export const TrialBanner = () => {
  const { loading: orgConfigLoading, organization } = useOrgConfig();

  if (orgConfigLoading || !organization || organization.level !== OrganizationLevel.Trial) return null;

  return (
    <FlexContainer className={styles.banner} justify="center">
      <Typography variant="callout">
        {$t({
          id: 'TrialBanner.banner',
          defaultMessage: 'Welcome to your Vectice free trial environment.',
        })}
      </Typography>
    </FlexContainer>
  );
};
