import cn from 'classnames';
import React, { ChangeEvent, ElementType, useEffect, useRef } from 'react';

import { validateMimeType } from '../../../utils';
import { ButtonVariants } from '../../button';
import { Loading } from '../../loading-animation';
import { Icon } from '../../svg';
import { Typography } from '../../typography';

import styles from './Upload.module.scss';

let globalId = 0;

type MultipleUploadProps =
  | { multiple: true; onUpload?: (files: File[], inputRef: HTMLInputElement | null) => void }
  | { multiple?: false; onUpload?: (file: File, inputRef: HTMLInputElement | null) => void };

interface UploadProps extends VecticeHTMLProps<HTMLInputElement> {
  borderless?: boolean;
  uploading?: boolean;
  icon?: ElementType;
  variant?: ButtonVariants;
}

export const Upload = ({
  accept = 'image/*',
  uploading,
  borderless,
  children,
  className,
  disabled,
  icon,
  multiple,
  name,
  variant,
  onUpload,
  ...inputProps
}: UploadProps & MultipleUploadProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: inputId } = useRef(`${name || 'upload'}-${globalId++}`);
  const labelRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadFiles = (files: File[] | null) => {
    if (files) {
      if (multiple) {
        onUpload?.(files, inputRef.current);
      } else if (files?.[0]) {
        onUpload?.(files[0], inputRef.current);
      }
    }
  };

  useEffect(() => {
    if (labelRef.current) {
      // Required for the drop event to register properly
      const dropShield = (e: DragEvent) => {
        e.preventDefault();
      };
      labelRef.current.addEventListener('dragover', dropShield);
      labelRef.current.addEventListener('dragenter', dropShield);

      const handleDataTransfer = (e: DragEvent) => {
        if (inputRef.current && e.dataTransfer) {
          e.preventDefault();
          const files = Array.from(e.dataTransfer.files).filter(validateMimeType(accept));
          uploadFiles(files);
        }
      };
      labelRef.current.addEventListener('drop', handleDataTransfer);

      return () => {
        if (labelRef.current) {
          labelRef.current.removeEventListener('dragover', dropShield);
          labelRef.current.removeEventListener('dragenter', dropShield);
          labelRef.current.removeEventListener('drop', handleDataTransfer);
        }
      };
    }

    return () => null;
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const files = Array.prototype.slice.call(input.files);
    uploadFiles(files);
    input.value = ''; // reset state of input so we can reupload the same file if needed
  };

  return (
    <Typography
      component="label"
      variant="callout"
      weight="semi-bold"
      color="primary"
      htmlFor={inputId}
      className={cn(
        styles.label,
        { [styles.disabled]: disabled, [styles.borderless]: borderless },
        variant && styles[variant],
        className,
      )}
      ref={labelRef}
      tabIndex={0}
    >
      {uploading && <Loading size={20} className={styles.loading} />}
      {!uploading && icon && <Icon icon={icon} size={20} className={styles.icon} />}
      {children}
      <input
        id={inputId}
        name={inputId}
        accept={accept}
        type="file"
        multiple={multiple}
        onChange={handleChange}
        className={styles.input}
        disabled={disabled}
        ref={inputRef}
        {...inputProps}
      />
    </Typography>
  );
};
