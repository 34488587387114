import { ApolloError, useQuery } from '@apollo/client';
import React, { createContext, PropsWithChildren, useEffect, useMemo } from 'react';

import { graphql } from '../gql';
import {
  FeatureFlagValue,
  OrganizationLevel,
  type ArtifactVersion,
  type ConfigDeployment,
  type EmailDomain,
  type Organization,
  type OrganizationConfigOutput,
  type OrganizationLicenseOptions,
} from '../gql/graphql';
import packageInfo from '../package-version.json';
import { message } from '../ui/message';

export const GET_PUBLIC_CONFIG = graphql(`
  query getPublicConfig {
    getPublicConfig {
      sendEmail
      isTrialEnabled
      versions {
        artifactName
        version
      }
      featureFlags {
        code
        enabled
      }
    }
  }
`);

export const GET_ORG_CONFIG = graphql(`
  query getOrgConfig {
    getOrgConfig {
      deployment
      sendEmail
      isTrialEnabled
      versions {
        artifactName
        version
      }
      featureFlags {
        code
        enabled
      }
      organization {
        id
        name
        level
        displayName
        emailDomains {
          domain
        }
        configuration {
          passwordAuthenticationEnabled
          autoProvisioning
          emailNotificationEnabled
          dfStatisticsRowThreshold
          dfStatisticsSampleRows
          dfStatisticsMaxColumns
          everyOneCanCreateWorkspace
          everyOneCanInviteUser
        }
        licenseOptions {
          isSingleSignOnEnabled
          isAskAIEnabled
        }
      }
    }
  }
`);

type AllArtifactVersion = ArtifactVersion | 'FRONTEND';

interface PublicConfigContextType {
  loading: boolean;
  error?: ApolloError;
  sendEmail: boolean;
  isTrialEnabled: boolean;
  versions: { version: string; artifactName: AllArtifactVersion }[];
  featureFlags: Pick<FeatureFlagValue, 'code' | 'enabled'>[];
}

export interface OrgConfigContextType extends PublicConfigContextType {
  organization: Pick<Organization, 'id' | 'name' | 'displayName' | 'level'>;
  emailDomains: Pick<EmailDomain, 'domain'>[];
  deployment: ConfigDeployment | 'Unknown';
  configuration: OrganizationConfigOutput;
  licenseOptions: OrganizationLicenseOptions;
}

const defaultPublicConfigContext: PublicConfigContextType = {
  loading: true,
  sendEmail: false,
  isTrialEnabled: false,
  featureFlags: [],
  versions: [
    {
      artifactName: 'FRONTEND',
      version: packageInfo.version,
    },
  ],
};

export const defaultOrgConfigContext: OrgConfigContextType = {
  ...defaultPublicConfigContext,
  organization: {
    id: '-1',
    name: 'Unknown',
    displayName: 'Unknown',
    level: OrganizationLevel.Other,
  },
  emailDomains: [],
  deployment: 'Unknown',
  configuration: {
    autoProvisioning: false,
    emailNotificationEnabled: false,
    passwordAuthenticationEnabled: false,
    dfStatisticsRowThreshold: 100,
    dfStatisticsSampleRows: 1000000,
    dfStatisticsMaxColumns: 400,
    everyOneCanCreateWorkspace: false,
    everyOneCanInviteUser: true,
  },
  licenseOptions: {
    isAskAIEnabled: false,
    isSingleSignOnEnabled: false,
  },
};

export const PublicConfigContext = createContext<PublicConfigContextType | null>(null);
export const OrgConfigContext = createContext<OrgConfigContextType | null>(null);

export const usePublicConfig = () => {
  const publicConfigContext = React.useContext(PublicConfigContext);

  if (!publicConfigContext) {
    throw new Error('useConfig must be used within PublicConfigProvider');
  }

  return publicConfigContext;
};

export const useOrgConfig = () => {
  const orgConfigContext = React.useContext(OrgConfigContext);

  if (!orgConfigContext) {
    throw new Error('useOrgConfig must be used within OrgConfigProvider');
  }

  return orgConfigContext;
};

export const useFeatureFlags = () => {
  const publicConfigContext = React.useContext(PublicConfigContext);
  const orgConfigContext = React.useContext(OrgConfigContext);

  if (!publicConfigContext && !orgConfigContext) {
    throw new Error('useFeatureFlags must be used within PublicConfigProvider or OrgConfigProvider');
  }

  return {
    loading: orgConfigContext?.loading ?? publicConfigContext?.loading ?? false,
    featureFlags: orgConfigContext?.featureFlags ?? publicConfigContext?.featureFlags ?? [],
    error: orgConfigContext?.error ?? publicConfigContext?.error,
  };
};

export const PublicConfigProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data, loading, error } = useQuery(GET_PUBLIC_CONFIG, {
    onError: (error) => message.error(error.message),
  });

  useEffect(() => {
    return logger.setTags({
      sendEmail: data?.getPublicConfig.sendEmail,
    });
  }, [data]);

  const value = useMemo(
    () => ({
      ...(data?.getPublicConfig ?? defaultPublicConfigContext),
      versions: [...defaultPublicConfigContext.versions, ...(data?.getPublicConfig.versions ?? [])],
      loading,
      error,
    }),
    [data?.getPublicConfig, loading],
  );

  return <PublicConfigContext.Provider value={value}>{children}</PublicConfigContext.Provider>;
};

export const OrgConfigProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { data, loading, error } = useQuery(GET_ORG_CONFIG, {
    onError: (error) => message.error(error.message),
  });

  useEffect(() => {
    return logger.setTags({
      sendEmail: data?.getOrgConfig.sendEmail,
      organization: data?.getOrgConfig.organization.name,
      'org.config.autoProvisioning': data?.getOrgConfig.organization.configuration.autoProvisioning,
      'org.config.emailNotificationEnabled': data?.getOrgConfig.organization.configuration.emailNotificationEnabled,
      'org.config.passwordAuthenticationEnabled':
        data?.getOrgConfig.organization.configuration.passwordAuthenticationEnabled,
      'org.config.dfStatisticsRowThreshold': data?.getOrgConfig.organization.configuration.dfStatisticsRowThreshold,
      'org.config.everyOneCanCreateWorkspace': data?.getOrgConfig.organization.configuration.everyOneCanCreateWorkspace,
      'org.config.everyOneCanInviteUser': data?.getOrgConfig.organization.configuration.everyOneCanInviteUser,
      'org.licenseOptions.isSingleSignOnEnabled': data?.getOrgConfig.organization.licenseOptions.isSingleSignOnEnabled,
      'org.licenseOptions.isAskAIEnabled': data?.getOrgConfig.organization.licenseOptions.isAskAIEnabled,
    });
  }, [data]);

  const value = useMemo(
    () => ({
      ...defaultOrgConfigContext,
      ...(data?.getOrgConfig ?? {}),
      ...(data?.getOrgConfig?.organization ?? {}),
      versions: [...defaultOrgConfigContext.versions, ...(data?.getOrgConfig.versions ?? [])],
      organization: data?.getOrgConfig?.organization ?? defaultOrgConfigContext.organization,
      id: undefined,
      loading,
      error,
    }),
    [data?.getOrgConfig, loading],
  );

  return <OrgConfigContext.Provider value={value}>{children}</OrgConfigContext.Provider>;
};
