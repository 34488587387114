import RcDrawer, { DrawerProps as RcDrawerProps } from 'rc-drawer';
import React from 'react';

import motionProps from './motion';

import 'rc-drawer/assets/index.css';
import './motion.scss';

import styles from './OverlayDrawer.module.scss';

export const OverlayDrawer = ({ children, ...props }: RcDrawerProps) => (
  <RcDrawer className={styles.drawer} destroyOnClose {...props} {...motionProps}>
    {children}
  </RcDrawer>
);
