import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

interface NoModelVersionsProps {
  borderless?: boolean;
}

export const NoModelVersions = ({ borderless }: NoModelVersionsProps) => (
  <EmptyList
    icon={VecticeIcon}
    borderless={borderless}
    message={$t({ id: 'NoModelVersions.noVersion', defaultMessage: 'No model versions!' })}
    hint={
      <>
        {$t({
          id: 'NoModelVersions.hint',
          defaultMessage:
            'The registered model version used in this Project, its type, and other information will be visible here.',
        })}
        <br />
        {$t({
          id: 'NoModelVersions.useCheatSheet',
          defaultMessage: 'Use the <api_cheat_sheet>Vectice API cheat sheet</api_cheat_sheet> to get started.',
        })}
      </>
    }
  />
);
