import { useMutation } from '@apollo/client';

import { UserInvite } from '../gql/graphql';
import { RESEND_INVITE } from '../graphql/queries';

export const useResendInvite = () => {
  const [resendUserInvite] = useMutation(RESEND_INVITE, {
    refetchQueries: ['getUserInvitesList', 'getUserAndInvitesList'],
  });

  const resend = async (invite: UserInvite) => resendUserInvite({ variables: { id: parseInt(invite.id, 10) } });

  return { resend };
};
