import React from 'react';

import { ReactComponent as CheckedIcon } from '../../assets/icons/interface/ic-check-circle.svg';
import { Dropdown, DropdownTrigger, Icon, Menu, MenuItem, Typography } from '../../ui';
import { SortConfig, SortValue } from '../../utils';

import styles from './SortBy.module.scss';

interface SortByProps {
  className?: string;
  value?: string;
  options: SortConfig[];
  onChange: (value: SortValue) => void;
}

export const SortBy = ({ className, value, options, onChange }: SortByProps) => {
  const menu = (
    <Menu className={styles.menu}>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          selected={value === option.value}
          onClick={() => onChange(option.value)}
          className={styles.option}
        >
          {value === option.value && <Icon className={styles.icon} icon={CheckedIcon} size={18} />}
          <Typography variant="callout" ellipsis className={styles.label}>
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger="click">
      <DropdownTrigger className={className}>
        {$t({ id: 'components.sortBy.sortBy', defaultMessage: 'Sort By' })}
      </DropdownTrigger>
    </Dropdown>
  );
};
