import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { EntityPropertyType } from '../../gql/graphql';
import { Column, EmptySection, Pagination, Table, message } from '../../ui';
import { GET_ENTITY_PROPERTIES } from '../version/getEntityProperties.query';

const PAGE_SIZE = 5;

interface IProps {
  resourceId: string;
  entityType: EntityPropertyType;
  emptyMessage?: string;
}

export const VersionPropertiesWidget = ({ resourceId, entityType, emptyMessage }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading: loadingFetch } = useQuery(GET_ENTITY_PROPERTIES, {
    fetchPolicy: 'no-cache',
    variables: {
      entityId: resourceId,
      entityType,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE,
    },
    onError: () =>
      message.error($t({ id: 'VersionProperties.fetchError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const total = data?.getResource.total || 0;
  const properties = data?.getResource.items || [];
  const loading = loadingFetch;

  return (
    <>
      <Table
        data={properties}
        size="md"
        rowKey="key"
        emptyText={
          <EmptySection
            message={
              emptyMessage ??
              $t({
                id: 'properties.empty.message',
                defaultMessage: 'To display properties, click the add button or use the Vectice API.',
              })
            }
          />
        }
        loading={loading}
      >
        <Column key="key" title="Property" ellipsis />
        <Column key="value" title="Value" ellipsis />
      </Table>
      <Pagination pageSize={PAGE_SIZE} current={currentPage} total={total} onChange={setCurrentPage} />
    </>
  );
};
