import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType } from '../../../gql/graphql';
import { DatasetLink, DatasetVersionLink } from '../../asset-display';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const DatasetVersionActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const datasetVersion = <DatasetVersionLink name={activity.targetName} resourceId={activity.targetVecticeId} />;
  const dataset = <DatasetLink name={activity.parentName} resourceId={activity.parentVecticeId} />;
  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.datasetVersion.created',
          defaultMessage: '{user} created {resource} of {parent}',
          values: {
            user,
            resource: datasetVersion,
            parent: dataset,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.datasetVersion.deleted',
          defaultMessage: '{user} deleted {resource} of {parent}',
          values: {
            user,
            resource: datasetVersion,
            parent: dataset,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.update.description',
            defaultMessage: '{user} updated the description of {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Star) {
      if (activity.toValue === 'true') {
        return (
          <>
            {$t({
              id: 'activities.datasetVersion.update.AddToStarredList',
              defaultMessage: '{user} starred {resource} of {parent}',
              values: {
                user,
                resource: datasetVersion,
                parent: dataset,
              },
            })}
          </>
        );
      } else {
        return (
          <>
            {$t({
              id: 'activities.datasetVersion.update.RemoveFromStarredList',
              defaultMessage: '{user} unstarred {resource} of {parent}',
              values: {
                user,
                resource: datasetVersion,
                parent: dataset,
              },
            })}
          </>
        );
      }
    }

    if (activity.updateType === ActivityUpdateType.LineageCodeUpdated) {
      return (
        <>
          {$t({
            id: 'activities.lineageCode.updated',
            defaultMessage: '{user} updated {target} code lineage',
            values: {
              user,
              target: datasetVersion,
            },
          })}
        </>
      );
    }

    const source = (
      <DatasetVersionLink
        name={activity.sourceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
        resourceId={activity.sourceVecticeId}
      />
    );

    if (activity.updateType === ActivityUpdateType.LineageAdded) {
      return (
        <>
          {$t({
            id: 'activities.lineage.created',
            defaultMessage: '{user} added {dataset} to the input of {target} lineage',
            values: {
              user,
              dataset: source,
              target: datasetVersion,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.LineageRemoved) {
      return (
        <>
          {$t({
            id: 'activities.lineage.removed',
            defaultMessage: '{user} removed {dataset} from the input of {target} lineage',
            values: {
              user,
              dataset: source,
              target: datasetVersion,
            },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.Properties) {
    if (activity.updateType === ActivityUpdateType.Add) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.propertyEntity.add',
            defaultMessage: '{user} added a property to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.MultipleAdd) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.propertyEntity.multiple.add',
            defaultMessage: '{user} added properties to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Delete) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.propertyEntity.delete',
            defaultMessage: '{user} deleted a property to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.MultipleDelete) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.propertyEntity.multiple.delete',
            defaultMessage: '{user} deleted properties to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.MultipleEdit) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.propertyEntity.multipleEdit',
            defaultMessage: '{user} edited properties to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Unknown) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.propertyEntity.edit',
            defaultMessage: '{user} edited a property to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.AttachmentFile) {
    if (activity.updateType === ActivityUpdateType.Add) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.attachmentFile.add',
            defaultMessage: '{user} uploaded an attachment to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.attachmentFile.rename',
            defaultMessage: '{user} renamed an attachment to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Delete) {
      return (
        <>
          {$t({
            id: 'activities.datasetVersion.attachmentFile.delete',
            defaultMessage: '{user} deleted an attachment to {resource} of {parent}',
            values: {
              user,
              resource: datasetVersion,
              parent: dataset,
            },
          })}
        </>
      );
    }
  }

  return null;
};
