import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { DatasetVersionRow } from './DatasetVersionRow';

const DatasetVersionListItemFragment = graphql(`
  fragment datasetVersionListItem on DataSetVersion {
    vecticeId
    ...datasetVersionRow
  }
`);

let globalId = 0;

interface DatasetVersionListItemProps {
  datasetVersion: FragmentType<typeof DatasetVersionListItemFragment>;
  breadcrumbs?: React.ReactNode;
}

export const DatasetVersionListItem = ({ breadcrumbs, ...props }: DatasetVersionListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`dataset-version-list-item-${globalId++}`);
  const datasetVersion = useFragment(DatasetVersionListItemFragment, props.datasetVersion);

  return (
    <AssetListItem
      to={buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: datasetVersion.vecticeId })}
      aria-labelledby={ariaId}
    >
      <DatasetVersionRow id={ariaId} datasetVersion={datasetVersion} breadcrumbs={breadcrumbs} />
    </AssetListItem>
  );
};
