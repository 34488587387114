import cn from 'classnames';
import React from 'react';

import styles from './TableWidthObserver.module.scss';

export const TableWidthObserver = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn(styles.observer, className)} {...props} />;
  },
);
