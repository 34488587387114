import React from 'react';

import { useAuthentication } from '../../../context';
import { User } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { Typography } from '../../../ui';
import { FormatUserName } from '../../formatters';

export const ActivityCreator = ({
  user,
  isAutomated,
}: {
  user?: (UserIdentity & Pick<User, 'id'>) | null;
  asSubject?: boolean;
  isAutomated: boolean;
}) => {
  const { user: connectedUser } = useAuthentication();
  const activityUser = isAutomated ? null : user;

  return (
    <Typography weight="semi-bold">
      {activityUser && activityUser.id === connectedUser.id ? (
        $t({ id: 'ActivityUser.connectedUser', defaultMessage: 'You' })
      ) : (
        <FormatUserName user={activityUser} />
      )}
    </Typography>
  );
};
