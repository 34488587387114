import { graphql } from '../../gql';

export const LineageAssetFragment = graphql(`
  fragment lineageAssetFragment on LineageArtifact {
    id
    artifactType
    dataSetVersion {
      vecticeId
      versionNumber
      dataSet {
        name
        vecticeId
      }
    }
    modelVersion {
      vecticeId
      versionNumber
      model {
        name
        vecticeId
      }
    }
  }
`);

export const LineageCodeFragment = graphql(`
  fragment lineageCodeFragment on LineageArtifact {
    codeVersion {
      id
      code {
        name
        description
      }
      gitVersion {
        branchName
        commitHash
        repositoryName
        uri
        entrypoint
        isDirty
        additionalInformation
      }
      databricksVersion {
        urlNotebook
        relativePath
        timestamp
        additionalInformation
      }
      otherCodeVersion {
        id
        url
        firstAdditionalInformation
        secondAdditionalInformation
        thirdAdditionalInformation
      }
    }
  }
`);
