import React from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/interface/ic-copy.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { Menu, MenuDivider, MenuItem } from '../../ui';

export type SelectedPhaseModal = 'edit' | 'duplicate' | 'delete' | null;

interface PhaseMenuProps {
  onOpenModal: (modal: SelectedPhaseModal) => void;
  showItems?: SelectedPhaseModal[];
}

export const PhaseMenu = ({ onOpenModal, showItems = ['edit', 'duplicate', 'delete'] }: PhaseMenuProps) => (
  <Menu>
    {showItems.includes('edit') && (
      <MenuItem leftIcon={EditIcon} onClick={() => onOpenModal('edit')}>
        {$t({ id: 'phase.menu.rename', defaultMessage: 'Edit Phase' })}
      </MenuItem>
    )}
    {showItems.includes('duplicate') && (
      <MenuItem leftIcon={CopyIcon} onClick={() => onOpenModal('duplicate')}>
        {$t({ id: 'phase.menu.duplicate', defaultMessage: 'Duplicate Phase' })}
      </MenuItem>
    )}
    <MenuDivider />
    {showItems.includes('delete') && (
      <MenuItem leftIcon={DeleteIcon} color="danger" onClick={() => onOpenModal('delete')}>
        {$t({ id: 'phase.menu.delete', defaultMessage: 'Delete Phase' })}
      </MenuItem>
    )}
  </Menu>
);
