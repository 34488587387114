import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { EntityFilesTypeEnum } from '../gql/graphql';
import { GET_ENTITY_FILE_LIST } from '../graphql/queries/getEntityFileList.query';
import { VecticeResourceType } from '../utils';

import { useEntityFileMethods } from './useEntityFileMethods';

export const useEntityFiles = (
  resourceId: number | string | undefined,
  resourceType: VecticeResourceType | undefined,
) => {
  const resourceTypeForQuery = useMemo(() => {
    switch (resourceType) {
      case VecticeResourceType.MODEL_VERSION:
        return EntityFilesTypeEnum.ModelVersion;
      case VecticeResourceType.RUN:
        return EntityFilesTypeEnum.Run;
      case VecticeResourceType.DATASET_VERSION:
      default:
        return EntityFilesTypeEnum.DatasetVersion;
    }
  }, [resourceType]);

  const { data, loading } = useQuery(GET_ENTITY_FILE_LIST, {
    fetchPolicy: 'network-only',
    skip: !resourceId || !resourceType,
    variables: {
      entityId: resourceId!,
      type: resourceTypeForQuery,
    },
  });

  const attachments = data?.getEntityFileList.items ?? [];
  const total = data?.getEntityFileList.total ?? 0;

  const entityFileMethods = useEntityFileMethods(resourceId, resourceType, 'getEntityFileList');

  return {
    ...entityFileMethods,
    attachments,
    total,
    loading,
  };
};
