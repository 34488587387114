import cn from 'classnames';
import React from 'react';

import styles from './Header.module.scss';

export type HeaderSizes = 'fullSize' | 'lg';

interface HeaderProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'size'> {
  size?: HeaderSizes;
}

export const Header = ({ className, ...props }: HeaderProps) => (
  <header className={cn(styles.header, className)} {...props} />
);
