import { useQuery } from '@apollo/client';

import { graphql } from '../../../gql';
import { DatasetSourceType } from '../../../gql/graphql';

export const GET_DATASET_VERSION_FILE_SOURCE = graphql(`
  query getDatasetVersionFileSource($fileSourceId: Float!) {
    getDatasetFilesSource(fileSourceId: $fileSourceId) {
      id
      name
      size
      columnsNumber
      rowsNumber
      uri
      mimeType
      fileCreatedDate
      fileUpdatedDate
      skippedStatistics
      extraMetadata {
        key
        value
        displayName
      }
    }
  }
`);

export const GET_DATASET_VERSION_DB_SOURCE = graphql(`
  query getDatasetVersionDbSource($dbSourceId: Float!) {
    getDBSource(dbSourceId: $dbSourceId) {
      id
      name
      size
      type
      columnsNumber
      rowsNumber
      uri
      tableCreatedDate
      tableUpdatedDate
      skippedStatistics
      extraMetadata {
        key
        value
        displayName
      }
    }
  }
`);

export const useDatasetSource = (sourceId?: number, sourceType?: DatasetSourceType) => {
  const { data: dbData, loading: dbLoading } = useQuery(GET_DATASET_VERSION_DB_SOURCE, {
    fetchPolicy: 'no-cache',
    skip: !sourceId || sourceType !== DatasetSourceType.Db,
    variables: {
      dbSourceId: sourceId!,
    },
  });

  const { data: fileData, loading: fileLoading } = useQuery(GET_DATASET_VERSION_FILE_SOURCE, {
    fetchPolicy: 'no-cache',
    skip: !sourceId || sourceType !== DatasetSourceType.Files,
    variables: {
      fileSourceId: sourceId!,
    },
  });

  return {
    loading: dbLoading || fileLoading,
    dbSourceDetails: dbData?.getDBSource
      ? {
          ...dbData.getDBSource,
          createdDate: dbData.getDBSource.tableCreatedDate,
          updatedDate: dbData.getDBSource.tableUpdatedDate,
        }
      : undefined,
    fileSourceDetails: fileData?.getDatasetFilesSource
      ? {
          ...fileData.getDatasetFilesSource,
          createdDate: fileData.getDatasetFilesSource.fileCreatedDate,
          updatedDate: fileData.getDatasetFilesSource.fileUpdatedDate,
        }
      : undefined,
  };
};
