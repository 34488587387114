import React from 'react';

import type { GetStartedCardProps } from './GetStartedCard';

import Autolog from '../../../assets/graphics/home/autolog.svg';
import CheatSheet from '../../../assets/graphics/home/cheat-sheet.svg';
import Documentation from '../../../assets/graphics/home/documentation.svg';
import InteractiveTourPart1 from '../../../assets/graphics/home/interactive-tour-part-1.svg';
import InteractiveTour from '../../../assets/graphics/project-templates/tutorial.svg';
import { DocumentationLink, type Url } from '../../links';

import { GetStartedCard } from './GetStartedCard';

type CardProps = Omit<GetStartedCardProps, 'backgroundColor' | 'label' | 'linkLabel' | 'visual'> & { url?: Url };

export const AutologCard = ({ isNew, onClick }: CardProps) => (
  <GetStartedCard
    backgroundColor="linear-gradient(273deg, #0750B5 0%, #017DA4 100%)"
    duration={$t({ id: 'home.getStarted.autolog.duration', defaultMessage: '2 min' })}
    isNew={isNew}
    isVideo
    label={$t({ id: 'home.getStarted.autolog.label', defaultMessage: 'Autolog overview video' })}
    linkLabel={$t({ id: 'home.getStarted.autolog.link', defaultMessage: 'View' })}
    tag={$t({ id: 'home.getStarted.autolog.tag', defaultMessage: 'Autolog' })}
    visual={Autolog}
    onClick={onClick}
  />
);

export const CDTCard = ({ isNew, onClick }: CardProps) => (
  <GetStartedCard
    backgroundColor="linear-gradient(273deg, #0750B5 0%, #017DA4 100%)"
    duration={$t({ id: 'home.getStarted.CDT.duration', defaultMessage: '2 min' })}
    isNew={isNew}
    isVideo
    label={$t({ id: 'home.getStarted.CDT.label', defaultMessage: 'Custom documentation templates' })}
    linkLabel={$t({ id: 'home.getStarted.CDT.link', defaultMessage: 'View' })}
    tag={$t({ id: 'home.getStarted.CDT.tag', defaultMessage: 'Documentation templates' })}
    visual={InteractiveTourPart1}
    onClick={onClick}
  />
);

export const AccessDocumentationCard = ({ className, url = 'getting_started' }: CardProps) => (
  <DocumentationLink url={url} className={className}>
    <GetStartedCard
      label={$t({ id: 'home.getStarted.documentation.label', defaultMessage: 'Vectice docs' })}
      linkLabel={$t({ id: 'home.getStarted.documentation.link', defaultMessage: 'Access now' })}
      tag={$t({ id: 'home.getStarted.documentation.tag', defaultMessage: 'Documentation' })}
      visual={Documentation}
      backgroundColor="linear-gradient(90deg, #038FA8 0%, #00B669 100%)"
      external
    />
  </DocumentationLink>
);

export const WalkThroughCard = ({ onClick, ...props }: CardProps) => (
  <GetStartedCard
    duration={$t({ id: 'home.getStarted.walkthrough.duration', defaultMessage: '1 min' })}
    {...props}
    backgroundColor="linear-gradient(273deg, #0750B5 0%, #017DA4 100%)"
    isVideo
    label={$t({ id: 'home.getStarted.walkthrough.label', defaultMessage: 'Vectice walk-through' })}
    linkLabel={$t({ id: 'home.getStarted.walkthrough.link', defaultMessage: 'View' })}
    tag={$t({ id: 'home.getStarted.walkthrough.tag', defaultMessage: 'Vectice tour' })}
    visual={InteractiveTour}
    onClick={onClick}
  />
);

export const AiRegulationCard = ({ isNew, onClick }: CardProps) => (
  <GetStartedCard
    label={$t({ id: 'home.getStarted.airegulation.label', defaultMessage: 'AI regulation impact' })}
    linkLabel={$t({ id: 'home.getStarted.airegulation.link', defaultMessage: 'View' })}
    isNew={isNew}
    tag={$t({ id: 'home.getStarted.airegulation.tag', defaultMessage: 'Vectice expertise' })}
    visual={CheatSheet}
    backgroundColor="linear-gradient(90deg, #038FA8 0%, #00B669 100%)"
    onClick={onClick}
  />
);
