import React from 'react';
import { Link } from 'react-router-dom';

import CrashGraphic from '../assets/graphics/splash/crash.svg';
import config from '../config';
import { buildLink, VecticeRoutes } from '../routes';
import { Button, Card, Crash, LinkAsButton, Typography } from '../ui';

import styles from './Errors.module.scss';

interface InternalServerErrorProps {
  error: Error | null;
  onHandleError?: () => void;
}

const reloadPage = () => window.location.reload();

export const InternalServerErrorPage = ({ error, onHandleError }: InternalServerErrorProps) => {
  const debug = ['development'].includes(config.ENVIRONMENT) && error;

  return (
    <Crash graphic={CrashGraphic}>
      <Typography variant="heading1" gutterBottom>
        {$t({ id: 'errorPage.internalServerError.title', defaultMessage: 'Internal Server Error' })}
      </Typography>
      <Typography paragraph gutterBottom>
        {$t({
          id: 'errorPage.internalServerError.description',
          defaultMessage:
            "We will have things fixed soon. If you need assistance during the downtime, please don't hesitate to contact <support>Support</support>.",
        })}
      </Typography>
      {debug && (
        <Card className={styles.stackTrace} inert>
          <Typography component="div" variant="footnote" weight="semi-bold">
            Stack Trace
          </Typography>
          <Typography variant="callout" weight="semi-bold" color="danger">
            {error.name}: {error.message}
          </Typography>
          <Typography component="pre" variant="callout" color="danger" paragraph>
            {error.stack}
          </Typography>
        </Card>
      )}
      <div className={styles.actions}>
        <LinkAsButton variant="phantom" component={Link} onClick={onHandleError} to={buildLink(VecticeRoutes.HOME)}>
          {$t({ id: 'errorPage.internalServerError.homeLink', defaultMessage: 'Go to Home' })}
        </LinkAsButton>
        {!!window.location && (
          <Button variant="phantom" onClick={reloadPage}>
            {$t({ id: 'errorPage.internalServerError.reloadPage', defaultMessage: 'Reload Page' })}
          </Button>
        )}
      </div>
    </Crash>
  );
};
