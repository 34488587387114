import cn from 'classnames';
import React, { forwardRef, ImgHTMLAttributes, MouseEventHandler } from 'react';

import styles from './Svg.module.scss';

interface GraphicProps extends ImgHTMLAttributes<HTMLImageElement> {
  size?: number | 'auto';
  onClick?: MouseEventHandler<HTMLImageElement> | undefined;
}

export const Graphic = forwardRef(
  (
    { className, role, size = 'auto', alt = '', height, width, ...props }: GraphicProps,
    ref: React.LegacyRef<HTMLImageElement>,
  ) => (
    <img
      alt={alt}
      className={cn(styles.svg, { [styles.button]: role === 'button' }, className)}
      height={height ?? size}
      role={role ?? undefined}
      width={width ?? size}
      ref={ref}
      {...props}
    />
  ),
);
