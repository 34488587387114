import { createContext } from 'react';

import { RegisteredPlugin } from '../types';

export interface FilterComposerContextType {
  register: (plugin: RegisteredPlugin) => () => void;
}

export const FilterComposerContext = createContext<FilterComposerContextType>({
  register: () => () => null,
});
