import cn from 'classnames';
import React, { useMemo } from 'react';

import { User } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { Skeleton, Thumbnail, ThumbnailSizes } from '../../../ui';
import { generateArray } from '../../../utils';

import { UserAvatar } from './UserAvatar';

import styles from './UserAvatars.module.scss';

interface UserAvatarsProps extends Omit<VecticeHTMLProps<HTMLUListElement>, 'children'> {
  total?: number;
  maxAvatarNumber?: number;
  avatarSize?: ThumbnailSizes;
  users: (Pick<User, 'id'> & UserIdentity)[];
}

export const UserAvatars = ({
  users,
  total,
  maxAvatarNumber = 5,
  avatarSize = 'sm',
  className,
  ...props
}: UserAvatarsProps) => {
  const overflow = total != undefined ? total - maxAvatarNumber : 0;
  const visibleUsers = useMemo(() => users.slice(0, maxAvatarNumber), [users]);

  return (
    <ul className={cn(styles.avatars, className)} {...props}>
      {visibleUsers.map((user) => (
        <li key={user.id} className={styles.avatar}>
          <UserAvatar user={user} size={avatarSize} />
        </li>
      ))}
      {overflow > 0 && (
        <li className={styles.avatar}>
          <Thumbnail
            className={styles.overflow}
            initials={`+${overflow}`}
            name={`+${overflow} other users`}
            size={avatarSize}
          />
        </li>
      )}
    </ul>
  );
};

export const UserAvatarsSkeleton = () => (
  <div className={styles.avatars}>
    {generateArray(5).map((uuid) => (
      <Skeleton.Shape key={uuid} shape="circle" height={32} width={32} className={styles.avatar} />
    ))}
  </div>
);
