import { graphql } from '../../gql';

export const GET_ENTITY_PROPERTIES = graphql(`
  query getEntityPropertiesByParentId(
    $entityType: EntityPropertyType!
    $entityId: VecticeId!
    $pageSize: Int!
    $pageIndex: Int!
    $category: ModelVersionStatus
  ) {
    getResource: getEntityPropertiesByParentId(
      entityType: $entityType
      entityId: $entityId
      page: { size: $pageSize, index: $pageIndex }
      order: { field: "key", direction: ASC }
      category: $category
    ) {
      total
      items {
        ...propertiesFields
      }
      page {
        ...pageIndexFields
      }
    }
  }
`);
