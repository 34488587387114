import React from 'react';

import { ReactComponent as LineageIcon } from '../../../assets/icons/specials/ic-lineage.svg';
import { Lineage } from '../../../gql/graphql';
import { Icon } from '../../../ui';
import { LineageTooltip } from '../../asset-display';

import styles from './OriginCell.module.scss';

interface OriginCellProps {
  origin?: Pick<Lineage, 'id'> | null;
}

export const OriginCell = ({ origin }: OriginCellProps) => {
  if (!origin) {
    return null;
  }

  return (
    <span className={styles.origin}>
      <LineageTooltip id={origin.id}>
        <Icon icon={LineageIcon} size={24} />
      </LineageTooltip>
    </span>
  );
};
