import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType } from '../../../gql/graphql';
import { ProjectLink, ReportLink } from '../../asset-display';
import { FULL_FORMAT, FormatDate } from '../../formatters';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const ReportActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const report = (
    <ReportLink
      name={activity.targetName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetId}
    />
  );

  const reportSource = (
    <ReportLink
      name={activity.sourceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.sourceId}
    />
  );

  const project = <ProjectLink name={activity.project?.name} resourceId={activity.project?.vecticeId} />;

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const oldName = (
    <ReportLink
      name={activity.fromValue || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetId}
    />
  );

  const newName = (
    <ReportLink
      name={activity.toValue || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetId}
    />
  );

  if (activity.type === ActivityType.Created) {
    if (activity.updateType === ActivityUpdateType.Documentation) {
      return (
        <>
          {$t({
            id: 'activities.report.documentation.updated',
            defaultMessage: '{user} updated the report {resource} in {parent}',
            values: {
              user,
              resource: report,
              parent: project,
            },
          })}
        </>
      );
    }
    return (
      <>
        {$t({
          id: 'activities.report.created',
          defaultMessage: '{user} created the report {resource} in {parent}',
          values: {
            user,
            resource: report,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Restored) {
    if (activity.toValue) {
      const restorationDate = <FormatDate date={activity.toValue} format={FULL_FORMAT} />;
      return (
        <>
          {$t({
            id: 'activities.report.restore.version',
            defaultMessage: '{user} restored the report {resource} in {parent} to version of {restorationDate}',
            values: {
              user,
              resource: report,
              parent: project,
              restorationDate: restorationDate,
            },
          })}
        </>
      );
    }

    return (
      <>
        {$t({
          id: 'activities.report.restore.last_version',
          defaultMessage: '{user} restored the report {resource} in {parent}',
          values: {
            user,
            resource: report,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.report.deleted',
          defaultMessage: '{user} deleted the report {resource} in {parent}',
          values: {
            user,
            resource: report,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Updated) {
    return (
      <>
        {$t({
          id: 'activities.report.updated',
          defaultMessage: '{user} updated the report {resource} in {parent}',
          values: {
            user,
            resource: report,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.report.update.name',
            defaultMessage: '{user} renamed the report {oldName} in {parent} to {newName}',
            values: {
              user,
              oldName: oldName,
              newName: newName,
              parent: project,
            },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.Duplicated) {
    return (
      <>
        {$t({
          id: 'activities.report.duplicated',
          defaultMessage: '{user} duplicated the report {resource} in {parent} to create {resourceTarget}',
          values: {
            user,
            resource: reportSource,
            parent: project,
            resourceTarget: report,
          },
        })}
      </>
    );
  }

  return null;
};
