import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GithubIcon } from '../../assets/icons/code/GitHubIcon.svg';
import { ReactComponent as JSONIcon } from '../../assets/icons/files/ic-json.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/interface/ic-download.svg';
import { ReactComponent as NewTabIcon } from '../../assets/icons/interface/ic-link.svg';
import config from '../../config';
import { Button, FlexContainer, Grid, GridCell, LinkAsButton, Modal, Section, Typography } from '../../ui';
import { downloadFile, downloadValidationConfigurationFile, getValidationNotebookUrl } from '../../utils';
import { HintTooltip } from '../tooltip';

import styles from './ValidationNotebookModal.module.scss';

export interface ValidationNotebookModalProps {
  modelVersionId: string;
  onClose: () => void;
}

export const ValidationNotebookModal = ({ modelVersionId, onClose }: ValidationNotebookModalProps) => {
  const downloadDefaultNotebook = () => downloadFile(getValidationNotebookUrl());

  return (
    <Modal
      className={styles.modal}
      closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
      title={$t({ id: 'ValidationNotebookModal.title', defaultMessage: 'Validation workflow setup' })}
      onClose={onClose}
    >
      <FlexContainer direction="column">
        <Section
          className={styles.section}
          label={$t({ id: 'OnboardingModal.downloadANotebook', defaultMessage: 'Download a notebook' })}
        >
          <Grid className={cn(styles.section, styles.grid)}>
            <FlexContainer component={GridCell} direction="column">
              <Button
                className={styles.button}
                leftIcon={DownloadIcon}
                variant="white"
                onClick={downloadDefaultNotebook}
              >
                {$t({ id: 'ValidationNotebookModal.downloadDefaultNotebook', defaultMessage: 'Default Notebook' })}
              </Button>
              <Typography align="center" color="secondary" paragraph variant="footnote">
                {$t({
                  id: 'OnboardingModal.defaultNotebookHint',
                  defaultMessage: 'Get a preconfigured notebook with basic setup. Add your own validation tests.',
                })}
              </Typography>
            </FlexContainer>
            <FlexContainer component={GridCell} justify="center">
              <Typography align="center" color="disabled" transform="lowercase" variant="callout" weight="semi-bold">
                {$t({ id: 'global.or', defaultMessage: 'Or' })}
              </Typography>
            </FlexContainer>
            <FlexContainer component={GridCell} direction="column">
              <LinkAsButton
                className={styles.button}
                component={Link}
                leftIcon={GithubIcon}
                rightIcon={NewTabIcon}
                target="_blank"
                to={config.VALIDATION_NOTEBOOK_URL}
                variant="white"
              >
                {$t({ id: 'ValidationNotebookModal.githubLink', defaultMessage: 'Explore notebooks' })}
              </LinkAsButton>
              <Typography align="center" color="secondary" paragraph variant="footnote">
                {$t({
                  id: 'OnboardingModal.exploreGithubHint',
                  defaultMessage: 'Use a predefined notebook including a suite of tests. The README will guide you.',
                })}
              </Typography>
            </FlexContainer>
          </Grid>
        </Section>
        <Section
          className={styles.section}
          label={(ariaId) => (
            <FlexContainer>
              <Typography id={ariaId} component="h3" variant="callout" color="primary" weight="semi-bold">
                {$t({
                  id: 'OnboardingModal.downloadConfigurationFile',
                  defaultMessage: 'Download the configuration file',
                })}
              </Typography>
              <HintTooltip
                hint={$t({
                  id: 'OnboardingModal.downloadConfigurationFileHint',
                  defaultMessage:
                    'Download a config file with data, model references, and project details for proper execution. Before usage, update the file with your own API credentials and if needed, modify the default phase where to save the results.',
                })}
              />
            </FlexContainer>
          )}
        >
          <Button
            className={styles.config}
            leftIcon={JSONIcon}
            variant="white"
            onClick={() => downloadValidationConfigurationFile(modelVersionId)}
          >
            {$t({ id: 'ValidationNotebookModal.downloadConfigurationFile', defaultMessage: 'Configuration file' })}
          </Button>
        </Section>
        <Typography className={styles.section} paragraph variant="callout" weight="semi-bold">
          {$t({
            id: 'OnboardingModal.runTheNotebook',
            defaultMessage: 'Run your notebook with the configuration file to ensure correct setup and save results.',
          })}
        </Typography>
      </FlexContainer>
    </Modal>
  );
};
