import { $insertFirst } from '@lexical/utils';
import { $applyNodeReplacement, $createParagraphNode, $getNodeByKey, $getSelection, $isRangeSelection } from 'lexical';

import type {
  HTMLTableElementWithWithTableSelectionState,
  TableCellHeaderState,
  TableCellNodeStyleObject,
  TableDOMCell,
} from '../lexical-table';
import type { LexicalEditor } from 'lexical';

import {
  $computeTableMap,
  $createTableCellNode,
  $createTableRowNode,
  $getNodeTriplet,
  $isTableCellNode,
  $isTableNode,
  $isTableRowNode,
  $isTableSelection,
  TableCellHeaderStates,
  TableCellNode,
  TableRowNode,
  getDOMCellFromTarget,
  getTableObserverFromTableElement,
  invariant,
} from '../lexical-table';

const getHeaderState = (
  currentState: TableCellHeaderState,
  possibleState: TableCellHeaderState,
): TableCellHeaderState => {
  if (currentState === TableCellHeaderStates.BOTH || currentState === possibleState) {
    return possibleState;
  }
  return TableCellHeaderStates.NO_STATUS;
};

function $moveSelectionToCell(cell: TableCellNode): void {
  const firstDescendant = cell.getFirstDescendant();
  if (firstDescendant == null) {
    cell.selectStart();
  } else {
    firstDescendant.getParentOrThrow().selectStart();
  }
}

export function createTableRowNode(height?: number): TableRowNode {
  return $applyNodeReplacement(new TableRowNode(height));
}

export function $getCell(editor: LexicalEditor, nodeKey: string) {
  const cellNode = editor.getElementByKey(nodeKey);

  if (cellNode) {
    return getDOMCellFromTarget(cellNode);
  }

  return null;
}

export function $getCellContext(editor: LexicalEditor, nodeKey: string) {
  let cell: TableDOMCell | null = null;

  const cellNode = editor.getElementByKey(nodeKey);
  if (cellNode) {
    cell = $getCell(editor, nodeKey);
  }

  const lexicalCellNode = $getNodeByKey(nodeKey);
  if ($isTableCellNode(lexicalCellNode)) {
    const rowNode = lexicalCellNode.getParent();

    if ($isTableRowNode(rowNode)) {
      const tableNode = rowNode.getParent();

      if ($isTableNode(tableNode)) {
        const tableElement = editor.getElementByKey(tableNode.getKey()) as HTMLTableElementWithWithTableSelectionState;
        const tableSelection = getTableObserverFromTableElement(tableElement);

        if (tableSelection) {
          const table = tableSelection.getTable();
          return { cell, table, tableNode };
        }
      }
    }
  }

  return { cell: null, table: null, tableNode: null };
}

export function $insertTableColumn__EXPERIMENTAL(insertAfter = true): void {
  const selection = $getSelection();
  invariant($isRangeSelection(selection) || $isTableSelection(selection), 'Expected a RangeSelection or GridSelection');
  const anchor = selection.anchor.getNode();
  const focus = selection.focus.getNode();
  const [anchorCell] = $getNodeTriplet(anchor);
  const [focusCell, , grid] = $getNodeTriplet(focus);
  const [gridMap, focusCellMap, anchorCellMap] = $computeTableMap(grid, focusCell, anchorCell);
  const rowCount = gridMap.length;
  const startColumn = insertAfter
    ? Math.max(focusCellMap.startColumn, anchorCellMap.startColumn)
    : Math.min(focusCellMap.startColumn, anchorCellMap.startColumn);
  const insertAfterColumn = insertAfter ? startColumn + focusCell.__colSpan - 1 : startColumn - 1;
  const sourceCellIndex = startColumn + focusCell.__colSpan - 1;
  const gridFirstChild = grid.getFirstChild();
  invariant($isTableRowNode(gridFirstChild), 'Expected firstTable child to be a row');
  let firstInsertedCell: null | TableCellNode = null;
  function $createTableCellNodeForInsertTableColumn(
    headerState: TableCellHeaderState = TableCellHeaderStates.NO_STATUS,
    style: TableCellNodeStyleObject = {},
    numberOfSiblings: number,
  ) {
    const cell = $createTableCellNode(headerState).append($createParagraphNode());
    cell.setStyle(style);
    if (firstInsertedCell === null) {
      firstInsertedCell = cell;
    }
    cell.setPercentWidth(100 / (numberOfSiblings + 1));
    return cell;
  }
  let loopRow: TableRowNode = gridFirstChild;
  rowLoop: for (let i = 0; i < rowCount; i++) {
    if (i !== 0) {
      const currentRow = loopRow.getNextSibling();
      invariant($isTableRowNode(currentRow), 'Expected row nextSibling to be a row');
      loopRow = currentRow;
    }
    const rowMap = gridMap[i];

    const currentCellHeaderState = (rowMap[insertAfterColumn < 0 ? 0 : insertAfterColumn].cell as TableCellNode)
      .__headerState;

    const sourceCellStyle = (rowMap[sourceCellIndex].cell as TableCellNode).__style;

    const headerState = getHeaderState(currentCellHeaderState, TableCellHeaderStates.ROW);

    if (insertAfterColumn < 0) {
      $insertFirst(
        loopRow,
        $createTableCellNodeForInsertTableColumn(headerState, sourceCellStyle, loopRow.getChildrenSize()),
      );
      continue;
    }
    const { cell: currentCell, startColumn: currentStartColumn, startRow: currentStartRow } = rowMap[insertAfterColumn];
    if (currentStartColumn + currentCell.__colSpan - 1 <= insertAfterColumn) {
      let insertAfterCell: TableCellNode = currentCell;
      let insertAfterCellRowStart = currentStartRow;
      let prevCellIndex = insertAfterColumn;
      while (insertAfterCellRowStart !== i && insertAfterCell.__rowSpan > 1) {
        prevCellIndex -= currentCell.__colSpan;
        if (prevCellIndex >= 0) {
          const { cell: cell_, startRow: startRow_ } = rowMap[prevCellIndex];
          insertAfterCell = cell_;
          insertAfterCellRowStart = startRow_;
        } else {
          loopRow.append(
            $createTableCellNodeForInsertTableColumn(headerState, sourceCellStyle, loopRow.getChildrenSize()),
          );
          continue rowLoop;
        }
      }
      insertAfterCell.insertAfter(
        $createTableCellNodeForInsertTableColumn(
          headerState,
          sourceCellStyle,
          insertAfterCell.getParent()!.getChildrenSize(),
        ),
      );
    } else {
      currentCell.setColSpan(currentCell.__colSpan + 1);
    }
  }
  if (firstInsertedCell !== null) {
    $moveSelectionToCell(firstInsertedCell);
  }
}

export function $insertTableRow__EXPERIMENTAL(insertAfter = true): void {
  const selection = $getSelection();
  invariant($isRangeSelection(selection) || $isTableSelection(selection), 'Expected a RangeSelection or GridSelection');
  const focus = selection.focus.getNode();
  const [focusCell, , grid] = $getNodeTriplet(focus);
  const [gridMap, focusCellMap] = $computeTableMap(grid, focusCell, focusCell);
  const columnCount = gridMap[0].length;
  const { startRow: focusStartRow } = focusCellMap;
  if (insertAfter) {
    const focusEndRow = focusStartRow + focusCell.__rowSpan - 1;
    const focusEndRowMap = gridMap[focusEndRow];
    const newRow = $createTableRowNode();
    for (let i = 0; i < columnCount; i++) {
      const { cell, startRow } = focusEndRowMap[i];
      if (startRow + cell.__rowSpan - 1 <= focusEndRow) {
        const currentCell = focusEndRowMap[i].cell as TableCellNode;
        const currentCellHeaderState = currentCell.__headerState;

        const headerState = getHeaderState(currentCellHeaderState, TableCellHeaderStates.COLUMN);

        const newCell = $createTableCellNode(headerState).append($createParagraphNode());
        newCell.setStyle(currentCell.__style);
        newRow.append(newCell);
      } else {
        cell.setRowSpan(cell.__rowSpan + 1);
      }
    }
    const focusEndRowNode = grid.getChildAtIndex(focusEndRow);
    invariant($isTableRowNode(focusEndRowNode), 'focusEndRow is not a TableRowNode');
    focusEndRowNode.insertAfter(newRow);
  } else {
    const focusStartRowMap = gridMap[focusStartRow];
    const newRow = $createTableRowNode();
    for (let i = 0; i < columnCount; i++) {
      const { cell, startRow } = focusStartRowMap[i];
      if (startRow === focusStartRow) {
        const currentCell = focusStartRowMap[i].cell as TableCellNode;
        const currentCellHeaderState = currentCell.__headerState;

        const headerState = getHeaderState(currentCellHeaderState, TableCellHeaderStates.COLUMN);

        const newCell = $createTableCellNode(headerState).append($createParagraphNode());
        newCell.setStyle(currentCell.__style);
        newRow.append(newCell);
      } else {
        cell.setRowSpan(cell.__rowSpan + 1);
      }
    }
    const focusStartRowNode = grid.getChildAtIndex(focusStartRow);
    invariant($isTableRowNode(focusStartRowNode), 'focusEndRow is not a TableRowNode');
    focusStartRowNode.insertBefore(newRow);
  }
}
