import cn from 'classnames';
import React, { ElementType } from 'react';

import styles from './Breadcrumbs.module.scss';

export const Breadcrumbs = <E extends ElementType>({
  className,
  component: Component = 'div',
  ...props
}: WithComponentProps<E>) => <Component className={cn(styles.breadcrumbs, className)} {...props} />;
