import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/ic-project.svg';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetInfos, Card, FlexContainer, Icon, Tooltip, Typography } from '../../../ui';
import { FormatWorkspace } from '../workspace';

import styles from './ProjectCard.module.scss';

const ProjectCardFragment = graphql(`
  fragment projectCard on Project {
    vecticeId
    name
    workspace {
      vecticeId
      type
      name
    }
  }
`);

let globalId = 0;

interface ProjectCardProps {
  project: FragmentType<typeof ProjectCardFragment>;
}

export const ProjectCard = (props: ProjectCardProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`project-card-${globalId++}`);
  const project = useFragment(ProjectCardFragment, props.project);

  return (
    <Link to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })} aria-labelledby={ariaId}>
      <Card className={styles.card} inert>
        <FlexContainer>
          <Icon icon={ProjectIcon} size={20} />
          <FlexContainer direction="column" align="stretch" gap={0} className={styles.title_wrapper}>
            <Tooltip text={project.name} placement="topLeft">
              <Typography
                id={ariaId}
                component="div"
                variant="callout"
                weight="semi-bold"
                color="primary"
                ellipsis
                className={styles.title}
              >
                {project.name}
              </Typography>
            </Tooltip>
            <AssetInfos wrap="nowrap">
              <Typography variant="footnote" color="tertiary" noBreak>
                {project.vecticeId}
              </Typography>
              <Typography variant="footnote" color="tertiary" ellipsis>
                <FormatWorkspace name={project.workspace?.name} iconSize={14} type={project.workspace?.type} ellipsis />
              </Typography>
            </AssetInfos>
          </FlexContainer>
        </FlexContainer>
      </Card>
    </Link>
  );
};
