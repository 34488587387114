import cn from 'classnames';
import React, { useMemo } from 'react';

import { ReactComponent as AddIcon } from '../../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/interface/ic-remove.svg';
import { MatchEnum, ValueMatchMap } from '../../../gql/graphql';
import { Button, Filter, Input, Select, Typography } from '../../../ui';
import { CommonFilterProps } from '../types';

import styles from './EntitiesFilterComponent.module.scss';

interface EntitiesPropsPrivate {
  inputType?: HTMLInputElement['type'];
  matchOptions: { id: string; label: string; value: MatchEnum }[];
  defaultMatch: MatchEnum;
  defaultKey?: string;
}

export interface EntitiesProps {
  entitiesNames?: string[];
}

type Props = CommonFilterProps & EntitiesProps & EntitiesPropsPrivate;

export const EntitiesFilterComponent = ({
  filter,
  onFilterUpdate,
  entitiesNames,
  inputType = 'text',
  matchOptions,
  defaultMatch,
  defaultKey,
}: Props) => {
  const blankEntity = {
    key: entitiesNames?.[0] || defaultKey,
    match: defaultMatch,
    value: '',
  };
  const entityFilter: (ValueMatchMap & { key?: string })[] = filter || [blankEntity];

  const onEntityUpdate = (index: number, row: ValueMatchMap & { key?: string }) => {
    if (index >= entityFilter.length) {
      entityFilter.push(row);
    } else {
      entityFilter[index] = row;
    }

    onFilterUpdate(entityFilter);
  };

  const removeEntityFilter = (index: number) => {
    if (entityFilter.length === 1) {
      entityFilter[0] = blankEntity;
    } else {
      entityFilter.splice(index, 1);
    }

    onFilterUpdate(entityFilter);
  };

  const options = useMemo(
    () =>
      entitiesNames?.map((entityName, id) => ({
        id,
        label: entityName,
        value: entityName,
      })),
    [entitiesNames],
  );

  if (!options?.length) {
    return (
      <Typography paragraph variant="callout" color="disabled" align="center">
        {$t({ id: 'filters.noFiltersAvailable', defaultMessage: 'No filters available' })}
      </Typography>
    );
  }

  return (
    <div className={styles.column}>
      {entityFilter.map((element, index) => (
        <div key={`entity-filter-option:${index}`} className={cn(styles.row, { [styles.withOptions]: !!options })}>
          {options && (
            <Filter
              withFilter
              options={options}
              triggerContent={element.key}
              regular
              onSelect={([keySelected]) => {
                onEntityUpdate(index, { key: keySelected, match: element.match, value: element.value });
              }}
            />
          )}
          <Select
            aria-label="Compare Option"
            options={matchOptions}
            value={element.match}
            onChange={(e) => {
              onEntityUpdate(index, {
                key: element.key,
                match: e.currentTarget.value as MatchEnum,
                value: element.value,
              });
            }}
          />
          <Input
            aria-label="Filter value"
            type={inputType}
            value={element.value.toString()}
            onChange={(e) =>
              onEntityUpdate(index, {
                key: element.key,
                match: element.match,
                value: inputType === 'number' ? Number(e.currentTarget.value) : e.currentTarget.value,
              })
            }
            placeholder="Value"
          />
          <Button variant="phantom" color="gray" leftIcon={CloseIcon} onClick={() => removeEntityFilter(index)} />
        </div>
      ))}
      <Button
        className={styles.add_entity}
        variant="phantom"
        color="accent"
        leftIcon={AddIcon}
        onClick={() => onEntityUpdate(entityFilter.length + 1, blankEntity)}
      >
        Add Filter
      </Button>
    </div>
  );
};
