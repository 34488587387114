import React, { useEffect, useRef, useState } from 'react';

import { useOrgConfig } from '../../../context';
import { getWorkspaceTypeOptions } from '../../../entities/utils';
import { OrganizationLevel, WorkspaceType } from '../../../gql/graphql';
import { Icon } from '../../../ui';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'type';

const sizeIcon = 18;
class WorkspaceTypeFilterNode<T extends { visibleOptions: WorkspaceType[] }> extends FilterNode<T> {
  private options: { label: string; icon?: React.ReactNode; value: any }[] | undefined;

  setOptions(visibleOptions: WorkspaceType[]) {
    this.options = getWorkspaceTypeOptions()
      .filter(({ value }) => visibleOptions.includes(value))
      .map(({ icon, ...option }) => ({
        ...option,
        icon: <Icon icon={icon} size={sizeIcon} />,
      }));
  }

  updateProps(props?: T) {
    super.updateProps(props);
    this.setOptions(props?.visibleOptions as WorkspaceType[]);
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="workspace-type-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const WorkspaceTypeFilter = () => {
  const { loading: orgConfigLoading, organization } = useOrgConfig();
  const [visibleOptions, setVisibleOptions] = useState<WorkspaceType[]>([
    WorkspaceType.Private,
    WorkspaceType.Tutorial,
  ]);

  const { current: node } = useRef(new WorkspaceTypeFilterNode({ visibleOptions }));

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.workspaceType.label', defaultMessage: 'Workspace Type' }),
    node,
  });

  useEffect(() => {
    if (!orgConfigLoading) {
      if (organization.level !== OrganizationLevel.Trial) {
        setVisibleOptions((prev) => [...prev, WorkspaceType.Public]);
      }
    }
  }, [orgConfigLoading, organization]);

  useEffect(() => {
    node.updateProps({ visibleOptions });
  }, [visibleOptions]);

  return null;
};
