import React from 'react';

import { getDocumentationPageStatusColor } from '../../entities/utils';
import { DocumentationCounts, DocumentationPageStatus } from '../../gql/graphql';
import { Progress } from '../../ui';

interface PhasesProgressProps extends VecticeHTMLProps<HTMLDivElement> {
  phasesCounts: DocumentationCounts;
  width?: string | number;
}

export const PhasesProgress = ({ phasesCounts, width, ...props }: PhasesProgressProps) => (
  <Progress
    width={width ?? 128}
    thin
    total={phasesCounts.total}
    values={[
      {
        color: getDocumentationPageStatusColor(DocumentationPageStatus.Completed),
        value: phasesCounts.done,
        tooltip: $t({
          id: 'project.phasesProgress.tooltip.completed',
          defaultMessage: 'Completed: {value} of {total}',
          values: {
            value: phasesCounts.done,
            total: phasesCounts.total,
          },
        }),
      },
      {
        color: getDocumentationPageStatusColor(DocumentationPageStatus.Draft),
        value: phasesCounts.ongoing,
        tooltip: $t({
          id: 'project.phasesProgress.tooltip.ongoing',
          defaultMessage: 'Ongoing: {value} of {total}',
          values: {
            value: phasesCounts.ongoing,
            total: phasesCounts.total,
          },
        }),
      },
      {
        color: getDocumentationPageStatusColor(DocumentationPageStatus.NotStarted),
        value: phasesCounts.toDo,
        tooltip: $t({
          id: 'project.phasesProgress.tooltip.notStarted',
          defaultMessage: 'Not Started: {value} of {total}',
          values: {
            value: phasesCounts.toDo,
            total: phasesCounts.total,
          },
        }),
      },
    ]}
    {...props}
  />
);
