import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType, FindingSeverity } from '../../../gql/graphql';
import { FindingLink, ModelVersionLinkWithoutName, ProjectLink, ReportLinkWithoutName } from '../../asset-display';
import { FindingStatusBadge, SeverityBadge } from '../../badges';
import { FormatDate } from '../../formatters';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { AssignedUser } from './ActivityUser';
import { ActivityProps } from './interfaces';

export const FindingActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const finding = (
    <FindingLink
      name={activity.targetName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetId}
    />
  );

  const project = <ProjectLink name={activity.parentName} resourceId={activity.parentVecticeId} />;

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const fromUser = <AssignedUser user={activity.fromUser} />;
  const toUser = <AssignedUser user={activity.toUser} />;

  const oldStatus = <FindingStatusBadge status={activity.fromValue} />;
  const newStatus = <FindingStatusBadge status={activity.toValue} />;

  const oldName = (
    <FindingLink
      name={activity.fromValue || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetId}
    />
  );

  const newName = (
    <FindingLink
      name={activity.toValue || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.targetId}
    />
  );

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.issue.created',
          defaultMessage: '{user} created {resource} in {parent}',
          values: {
            user,
            resource: finding,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.issue.deleted',
          defaultMessage: '{user} deleted {resource} of {parent}',
          values: {
            user,
            resource: finding,
            parent: project,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.issue.update.description',
            defaultMessage: '{user} updated the description of {resource} of {parent}',
            values: {
              user,
              resource: finding,
              parent: project,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.DueDate) {
      const toDueDate = <FormatDate date={activity.toValue} />;
      if (activity.fromValue && activity.toValue)
        return (
          <>
            {$t({
              id: 'activities.issue.update.dueDate',
              defaultMessage: '{user} updated the due date to {toDueDate} in {resource} in {parent}',
              values: {
                user,
                resource: finding,
                parent: project,
                toDueDate,
              },
            })}
          </>
        );

      if (activity.toValue)
        return (
          <>
            {$t({
              id: 'activities.issue.update.dueDate.added',
              defaultMessage: '{user} added the due date {toDueDate} in {resource} in {parent}',
              values: {
                user,
                resource: finding,
                parent: project,
                toDueDate,
              },
            })}
          </>
        );

      if (activity.fromValue)
        return (
          <>
            {$t({
              id: 'activities.issue.update.dueDate.removed',
              defaultMessage: '{user} removed the due date in {resource} in {parent}',
              values: {
                user,
                resource: finding,
                parent: project,
              },
            })}
          </>
        );
    }

    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.issue.update.name',
            defaultMessage: '{user} renamed {oldName} of {parent} to {newName}',
            values: {
              user,
              oldName: oldName,
              newName: newName,
              parent: project,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Status) {
      return (
        <>
          {$t({
            id: 'activities.issue.update.status',
            defaultMessage: '{user} transitioned {resource} in {parent} from {oldStatus} to {newStatus}',
            values: {
              user,
              resource: finding,
              parent: project,
              oldStatus,
              newStatus,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Severity) {
      const oldSeverity = <SeverityBadge severity={activity.fromValue as FindingSeverity} />;
      const newSeverity = <SeverityBadge severity={activity.toValue as FindingSeverity} />;
      return (
        <>
          {$t({
            id: 'activities.issue.update.severity',
            defaultMessage: '{user} updated the severity of {resource} in {parent} from {oldSeverity} to {newSeverity}',
            values: {
              user,
              resource: finding,
              parent: project,
              oldSeverity,
              newSeverity,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Owner) {
      return (
        <>
          {$t({
            id: 'activities.issue.update.owner',
            defaultMessage: '{user} changed the owner of {finding} in {project} from {fromUser} to {toUser}',
            values: {
              user,
              finding,
              project,
              fromUser,
              toUser,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Reviewer) {
      return (
        <>
          {$t({
            id: 'activities.issue.update.assignee',
            defaultMessage: '{user} changed the assignee of {finding} in {project} from {fromUser} to {toUser}',
            values: {
              user,
              finding,
              project,
              fromUser,
              toUser,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.ModelVersion && activity.fromValue && activity.toValue) {
      const fromModelVersion = <ModelVersionLinkWithoutName resourceId={activity.fromValue} />;
      const toModelVersion = <ModelVersionLinkWithoutName resourceId={activity.toValue} />;
      return (
        <>
          {$t({
            id: 'activities.issue.update.modelVersion',
            defaultMessage:
              '{user} changed the model version of {finding} in {project} from {fromModelVersion} to {toModelVersion}',
            values: {
              user,
              finding,
              project,
              fromModelVersion,
              toModelVersion,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.ModelVersion && activity.fromValue) {
      const fromModelVersion = <ModelVersionLinkWithoutName resourceId={activity.fromValue} />;
      return (
        <>
          {$t({
            id: 'activities.issue.update.modelVersion.removed',
            defaultMessage: '{user} removed the model version {fromModelVersion} from {finding} in {project}',
            values: {
              user,
              finding,
              project,
              fromModelVersion,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.ModelVersion && activity.toValue) {
      const toModelVersion = <ModelVersionLinkWithoutName resourceId={activity.toValue} />;
      return (
        <>
          {$t({
            id: 'activities.issue.update.modelVersion.added',
            defaultMessage: '{user} added the model version {toModelVersion} to {finding} in {project}',
            values: {
              user,
              finding,
              project,
              toModelVersion,
            },
          })}
        </>
      );
    }

    if (
      activity.updateType === ActivityUpdateType.Report &&
      activity.fromValue &&
      activity.toValue &&
      !isNaN(Number(activity.fromValue)) &&
      !isNaN(Number(activity.toValue))
    ) {
      const fromReport = <ReportLinkWithoutName resourceId={Number(activity.fromValue)} />;
      const toReport = <ReportLinkWithoutName resourceId={Number(activity.toValue)} />;
      return (
        <>
          {$t({
            id: 'activities.issue.update.report',
            defaultMessage: '{user} changed the report of {finding} in {project} from {fromReport} to {toReport}',
            values: {
              user,
              finding,
              project,
              fromReport,
              toReport,
            },
          })}
        </>
      );
    }
  }

  if (activity.updateType === ActivityUpdateType.Report && activity.fromValue && !isNaN(Number(activity.fromValue))) {
    const fromReport = <ReportLinkWithoutName resourceId={Number(activity.fromValue)} />;
    return (
      <>
        {$t({
          id: 'activities.issue.update.report.removed',
          defaultMessage: '{user} removed the report {fromReport} from {finding} in {project}',
          values: {
            user,
            finding,
            project,
            fromReport,
          },
        })}
      </>
    );
  }

  if (activity.updateType === ActivityUpdateType.Report && activity.toValue && !isNaN(Number(activity.toValue))) {
    const toReport = <ReportLinkWithoutName resourceId={Number(activity.toValue)} />;
    return (
      <>
        {$t({
          id: 'activities.issue.update.report.added',
          defaultMessage: '{user} added the report {toReport} to {finding} in {project}',
          values: {
            user,
            finding,
            project,
            toReport,
          },
        })}
      </>
    );
  }

  return null;
};
