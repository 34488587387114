import { graphql } from '../../gql';

export const CREATE_PROPERTY = graphql(`
  mutation createEntityProperty(
    $entityId: VecticeId!
    $entityType: EntityPropertyType!
    $createProperty: CreateEntityPropertyInput!
  ) {
    createEntityProperty(entityId: $entityId, entityType: $entityType, createProperty: $createProperty) {
      ...propertiesFields
    }
  }
`);
