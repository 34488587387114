import React, { useEffect, useRef } from 'react';

import type { InventoryReferenceFilterComponentProps } from '../ui';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { InventoryReferenceFilterComponent } from '../ui';

export const pluginField = 'inventoryReferences';

class InventoryReferenceFilterNode<T extends { workspaceIdList: string[] }> extends FilterNode<T> {
  updateProps(props?: T) {
    super.updateProps(props);
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    if (this.props) {
      return (
        <InventoryReferenceFilterComponent
          key={pluginField}
          {...this.props}
          filter={filter}
          onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
        />
      );
    }

    return null;
  }
}

export const InventoryReferenceFilter = ({ ...filterProps }: InventoryReferenceFilterComponentProps) => {
  const { current: node } = useRef(
    new InventoryReferenceFilterNode<InventoryReferenceFilterComponentProps>(filterProps),
  );

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.inventoryReference.label', defaultMessage: 'Model inventory ID' }),
    node,
  });

  useEffect(() => {
    node.updateProps(filterProps);
  }, [...Object.values(filterProps)]);

  return null;
};
