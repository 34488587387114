import React from 'react';

import { WithLoading } from '../../../loading-animation';
import { Menu, MenuItem } from '../../../menu';
import { Option, RenderOptionsProps } from '../interfaces';
import { getOptionLabel, isOptionDisabled } from '../utils';

interface OptionMenuProps<T extends Option | string> extends RenderOptionsProps<T> {
  id: string;
  beforeOptions?: React.ReactNode;
  currentValue: string | null;
  highlightedIndex: number;
  options: T[];
  searching?: boolean;
  onSelect: (option: T) => () => void;
}

export const OptionMenu = React.forwardRef(
  <T extends Option | string>(
    {
      id,
      beforeOptions,
      currentValue,
      highlightedIndex,
      noData,
      options,
      searching,
      onSelect,
      renderFn,
    }: OptionMenuProps<T>,
    ref: React.Ref<HTMLUListElement>,
  ) => (
    <Menu role="listbox" tabIndex={-1} id={id} ref={ref}>
      <WithLoading loading={searching} loadingSize={18}>
        {!options.length ? (
          <MenuItem role="option" disabled>
            {noData || 'No Options'}
          </MenuItem>
        ) : (
          <>
            {beforeOptions}
            {options.map((option, index) => (
              <MenuItem
                role="option"
                key={getOptionLabel(option)}
                highlighted={index === highlightedIndex}
                selected={getOptionLabel(option) === currentValue}
                onClick={onSelect(option)}
                disabled={isOptionDisabled(option)}
              >
                {renderFn ? renderFn(option) : getOptionLabel(option)}
              </MenuItem>
            ))}
          </>
        )}
      </WithLoading>
    </Menu>
  ),
);
