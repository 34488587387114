import { ReactComponent as FlatPersonalWorkspaceIcon } from '../../assets/icons/specials/flat/ic-workspace-personal.svg';
import { ReactComponent as FlatPrivateWorkspaceIcon } from '../../assets/icons/specials/flat/ic-workspace-private.svg';
import { ReactComponent as FlatWorkspaceIcon } from '../../assets/icons/specials/flat/ic-workspace.svg';
import { ReactComponent as PersonalWorkspaceIcon } from '../../assets/icons/specials/ic-workspace-personal.svg';
import { ReactComponent as PrivateWorkspaceIcon } from '../../assets/icons/specials/ic-workspace-private.svg';
import { ReactComponent as WorkspaceIcon } from '../../assets/icons/specials/ic-workspace.svg';
import { WorkspaceType } from '../../gql/graphql';

export const getWorkspaceIcon = (type?: WorkspaceType | string | null, flat?: boolean) => {
  switch (type) {
    case WorkspaceType.Private:
      return flat ? FlatPrivateWorkspaceIcon : PrivateWorkspaceIcon;
    case WorkspaceType.Tutorial:
      return flat ? FlatPersonalWorkspaceIcon : PersonalWorkspaceIcon;
    case WorkspaceType.Public:
    case WorkspaceType.Sample:
    default:
      return flat ? FlatWorkspaceIcon : WorkspaceIcon;
  }
};

export const getWorkspaceTypeDisplayName = (type?: WorkspaceType | string | null) => {
  switch (type) {
    case WorkspaceType.Private:
      return $t({ id: 'workspaceType.private.label', defaultMessage: 'Private Workspace' });
    case WorkspaceType.Public:
      return $t({ id: 'workspaceType.public.label', defaultMessage: 'Organization Shared Workspace' });
    case WorkspaceType.Sample:
      return $t({ id: 'workspaceType.sample.label', defaultMessage: 'Sample Workspace' });
    case WorkspaceType.Tutorial:
      return $t({ id: 'workspaceType.tutorial.label', defaultMessage: 'Personal Workspace' });
    default:
      return type || $t({ id: 'workspaceType.unknown.label', defaultMessage: 'Workspace' });
  }
};

export const getWorkspaceTypeOptions = () => [
  {
    label: getWorkspaceTypeDisplayName(WorkspaceType.Private),
    value: WorkspaceType.Private,
    icon: getWorkspaceIcon(WorkspaceType.Private, true),
  },
  {
    label: getWorkspaceTypeDisplayName(WorkspaceType.Public),
    value: WorkspaceType.Public,
    icon: getWorkspaceIcon(WorkspaceType.Public, true),
  },
  {
    label: getWorkspaceTypeDisplayName(WorkspaceType.Sample),
    value: WorkspaceType.Sample,
    icon: getWorkspaceIcon(WorkspaceType.Sample, true),
  },
  {
    label: getWorkspaceTypeDisplayName(WorkspaceType.Tutorial),
    value: WorkspaceType.Tutorial,
    icon: getWorkspaceIcon(WorkspaceType.Tutorial, true),
  },
];
