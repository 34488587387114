import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper, { CropperProps, Point, Size, Area } from 'react-easy-crop';

import { Button, ModalForm } from '../../ui';

import {
  DEFAULT_ASPECT,
  DEFAULT_FILL_COLOR,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  DEFAULT_QUALITY,
  DEFAULT_SHAPE,
  INIT_ROTATE,
  INIT_ZOOM,
  ZOOM_STEP,
} from './constants';

import styles from './ImgCrop.module.scss';

const MEDIA_CLASS_SELECTOR = 'vectice-image-crop-media';

interface ImgCropProps extends Partial<CropperProps> {
  file: File;
  quality?: number;
  onSubmit?: (file: File) => void;
  onClose?: () => void;
}

export const ImgCrop = ({
  file,
  quality = DEFAULT_QUALITY,
  onSubmit,
  onClose,
  aspect = DEFAULT_ASPECT,
  cropShape = DEFAULT_SHAPE,
  minZoom = DEFAULT_MIN_ZOOM,
  maxZoom = DEFAULT_MAX_ZOOM,
  ...cropperProps
}: ImgCropProps) => {
  const [image, setImage] = useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState<Size>({ width: 0, height: 0 });
  const [zoomVal, setZoomVal] = useState(INIT_ZOOM);
  const [rotateVal, setRotateVal] = useState(INIT_ROTATE);
  const cropPixelsRef = useRef<Area>({ width: 0, height: 0, x: 0, y: 0 });

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          setImage(reader.result);
        }
      });
      reader.readAsDataURL(file);
    } else {
      setImage('');
    }
  }, [file]);

  const onMediaLoaded = useCallback(
    (mediaSize) => {
      const { width, height } = mediaSize;
      const ratioWidth = height * aspect;

      if (width > ratioWidth) {
        setCropSize({ width: ratioWidth, height });
      } else {
        setCropSize({ width, height: width / aspect });
      }
    },
    [aspect],
  );

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    cropPixelsRef.current = croppedAreaPixels;
  }, []);

  const handleSubmit = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      onClose?.();
      return;
    }

    const imgSource = document.querySelector(`.${MEDIA_CLASS_SELECTOR}`) as CanvasImageSource & {
      naturalWidth: number;
      naturalHeight: number;
    };

    const { width: cropWidth, height: cropHeight, x: cropX, y: cropY } = cropPixelsRef.current;

    if (rotateVal !== INIT_ROTATE) {
      const { naturalWidth: imgWidth, naturalHeight: imgHeight } = imgSource;
      const angle = rotateVal * (Math.PI / 180);

      // get container for rotated image
      const sine = Math.abs(Math.sin(angle));
      const cosine = Math.abs(Math.cos(angle));
      const squareWidth = imgWidth * cosine + imgHeight * sine;
      const squareHeight = imgHeight * cosine + imgWidth * sine;

      canvas.width = squareWidth;
      canvas.height = squareHeight;
      ctx.fillStyle = DEFAULT_FILL_COLOR;
      ctx.fillRect(0, 0, squareWidth, squareHeight);

      // rotate container
      const squareHalfWidth = squareWidth / 2;
      const squareHalfHeight = squareHeight / 2;
      ctx.translate(squareHalfWidth, squareHalfHeight);
      ctx.rotate(angle);
      ctx.translate(-squareHalfWidth, -squareHalfHeight);

      // draw rotated image
      const imgX = (squareWidth - imgWidth) / 2;
      const imgY = (squareHeight - imgHeight) / 2;
      ctx.drawImage(imgSource, 0, 0, imgWidth, imgHeight, imgX, imgY, imgWidth, imgHeight);

      // crop rotated image
      const imgData = ctx.getImageData(0, 0, squareWidth, squareHeight);
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      ctx.putImageData(imgData, -cropX, -cropY);
    } else {
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      ctx.fillStyle = DEFAULT_FILL_COLOR;
      ctx.fillRect(0, 0, cropWidth, cropHeight);

      ctx.drawImage(imgSource, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    }

    const { type, name } = file;
    canvas.toBlob(
      async (blob: Blob | null) => {
        if (!blob) {
          onClose?.();
          return;
        }

        const newFile = new File([blob], name, { type });
        onSubmit?.(newFile);
      },
      type,
      quality,
    );

    onClose?.();
  };

  return (
    <ModalForm
      title="Edit Image"
      submitLabel={$t({ id: 'modal.submit', defaultMessage: 'Submit' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={handleSubmit}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <Cropper
          {...cropperProps}
          image={image}
          crop={crop}
          cropSize={cropSize}
          onCropChange={setCrop}
          aspect={aspect}
          cropShape={cropShape}
          rotation={rotateVal}
          onRotationChange={setRotateVal}
          zoom={zoomVal}
          zoomWithScroll
          onZoomChange={setZoomVal}
          minZoom={minZoom}
          maxZoom={maxZoom}
          onMediaLoaded={onMediaLoaded}
          onCropComplete={onCropComplete}
          classes={{ containerClassName: styles.container, mediaClassName: MEDIA_CLASS_SELECTOR }}
        />
      </div>
      <div className={styles.controls}>
        <Button onClick={() => setZoomVal(zoomVal - ZOOM_STEP)} disabled={zoomVal - ZOOM_STEP < minZoom}>
          -
        </Button>
        <Button onClick={() => setZoomVal(zoomVal + ZOOM_STEP)} disabled={zoomVal + ZOOM_STEP > maxZoom}>
          +
        </Button>
      </div>
    </ModalForm>
  );
};
