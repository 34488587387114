import cn from 'classnames';
import Notification from 'rc-notification';
import { NoticeContent, NotificationInstance } from 'rc-notification/es/Notification';
import React from 'react';

import 'rc-notification/assets/index.css';

import { Icon } from '../svg';
import { Typography } from '../typography';

import { DEFAULT_MESSAGE_DURATION } from './constants';
import { MessageVariant } from './interfaces';
import { getMessageIcon } from './utils';

import styles from './Message.module.scss';

interface Props extends VecticeHTMLProps<HTMLDivElement> {
  variant: MessageVariant;
  gutterBottom?: boolean;
}

export const Message = ({ children, variant, gutterBottom, ...props }: React.PropsWithChildren<Props>) => {
  const MessageIcon = getMessageIcon(variant);

  return (
    <div className={cn(styles.message, { [styles[variant]]: variant, [styles.gutterBottom]: gutterBottom })}>
      {MessageIcon && <Icon icon={MessageIcon} className={styles.icon} size={20} />}
      <Typography component="div" color="secondary" paragraph {...props}>
        {children}
      </Typography>
    </div>
  );
};

let messageInstance: NotificationInstance;

const getMessageInstance = () =>
  new Promise<NotificationInstance>((resolve) => {
    if (messageInstance) {
      resolve(messageInstance);
    } else {
      Notification.newInstance(
        {
          prefixCls: 'vectice-notification',
          maxCount: 5,
          style: { width: 370, top: 65, left: 0, right: 0, margin: 'auto' },
        },
        (instance) => {
          messageInstance = instance;
          resolve(instance);
        },
      );
    }
  });

const commonMessage =
  (variant: MessageVariant) =>
  (content: React.ReactNode, duration = DEFAULT_MESSAGE_DURATION / 1000, options?: NoticeContent) => {
    const key = Date.now();
    getMessageInstance().then((instance) => {
      instance.notice({
        duration,
        ...options,
        key,
        onClick: () => messageInstance.removeNotice(key),
        content: <Message variant={variant}>{content}</Message>,
        props: {
          ...options?.props,
          ...(variant === 'error'
            ? { role: 'alert', 'aria-live': 'assertive' }
            : { role: 'status', 'aria-live': 'polite' }),
        },
      });
    });
  };

export const message = {
  error: commonMessage('error'),
  info: commonMessage('info'),
  success: commonMessage('success'),
  warning: commonMessage('warning'),
};
