import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { useAuthentication } from '../../../context';
import { GET_ORGANIZATION_USER } from '../../../editor/plugins/mention/getOrganizationUser.query';
import { ProjectOwnershipTransferContent } from '../../../entities';
import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationProjectOwnershipTransferred } from '../types';

import { Notification } from './Notification';

interface ProjectOwnershipTransferredNotificationProps {
  notification: UserNotificationProjectOwnershipTransferred;
  onClose: () => void;
}

const GET_PROJECT_BY_ID = graphql(`
  query getProjectForProjectOwnershipTransfer($id: VecticeId!) {
    getProjectById(projectId: $id) {
      vecticeId
      name
    }
  }
`);

export const ProjectOwnershipTransferredNotification = ({
  notification: { creator, content, createdDate },
  onClose,
}: ProjectOwnershipTransferredNotificationProps) => {
  const { user } = useAuthentication();
  const parsedContent: ProjectOwnershipTransferContent = JSON.parse(content);
  const newOwnerId = parsedContent?.newOwnerId;
  const projectId = parsedContent?.projectId;

  const {
    data: userData,
    loading: loadingUser,
    error,
  } = useQuery(GET_ORGANIZATION_USER, {
    skip: !newOwnerId,
    variables: {
      userId: newOwnerId!,
    },
  });
  const { data: projectData, loading: loadingProject } = useQuery(GET_PROJECT_BY_ID, {
    skip: !projectId,
    variables: {
      id: projectId!,
    },
  });
  const owner = userData?.getOrganizationUser;
  const project = projectData?.getProjectById;
  const loading = loadingUser || loadingProject;

  if (!project || !owner || loading) {
    return null;
  }

  return (
    <Notification
      component={!error ? Link : undefined}
      to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {user.id === creator?.id
        ? $t({
            id: 'notification.project.ownershipTransferred.self',
            defaultMessage:
              '<bold>You</bold> transferred ownership of project <bold>{title}</bold> to <bold>{owner}</bold>',
            values: {
              title: project.name || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
              owner: <FormatUserName user={owner} />,
            },
          })
        : $t({
            id: 'notification.project.ownershipTransferred',
            defaultMessage:
              '<bold>{creator}</bold> transferred ownership of project <bold>{title}</bold> to <bold>{owner}</bold>',
            values: {
              creator: <FormatUserName user={creator} />,
              title: project.name || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
              owner: <FormatUserName user={owner} />,
            },
          })}
    </Notification>
  );
};
