import cn from 'classnames';
import React from 'react';

import { ReactComponent as VecticeIcon } from '../../../assets/brand/logo-solid.svg';
import { useExportFormatContext } from '../../../context';
import { useFragment } from '../../../gql';
import { UserFragment, UserIdentity } from '../../../graphql/fragments';
import { Icon, Thumbnail, ThumbnailSizes, Tooltip } from '../../../ui';
import { getUserAvatarUrl, getUserInitial } from '../../../utils';
import { formatUserName } from '../../formatters';

import { UserTooltip } from './UserTooltip';

import styles from './UserAvatar.module.scss';

const AutomatedAvatarSizes: Record<ThumbnailSizes, number> = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
  xl: 56,
  xxl: 64,
  xxxl: 300,
};

interface UserAvatarProps {
  user?: UserIdentity | null;
  src?: string;
  size?: ThumbnailSizes;
  hideTooltip?: boolean;
  className?: string;
}

export const UserAvatar = ({
  user: userFragment,
  src,
  size = 'md',
  hideTooltip,
  className,
  ...props
}: UserAvatarProps) => {
  const { hideAvatar } = useExportFormatContext();
  const user = useFragment(UserFragment, userFragment);
  const url = getUserAvatarUrl(user?.avatarId);
  const name = formatUserName(user);
  const initials = getUserInitial(name);

  if (user === null) {
    return (
      <Tooltip text={<>{$t({ id: 'ActivityUserAvatar.automationUser', defaultMessage: 'Automation for Vectice' })}</>}>
        <span className={cn(styles.automation_avatar, className)}>
          <Icon icon={VecticeIcon} size={AutomatedAvatarSizes[size]} />
        </span>
      </Tooltip>
    );
  }

  if (hideAvatar) return <span className={className}>{name}</span>;

  return (
    <UserTooltip hideTooltip={hideTooltip} id={user?.id}>
      <Thumbnail
        color={user?.color}
        src={src || url}
        initials={initials}
        name={name}
        size={size}
        className={className}
        {...props}
      />
    </UserTooltip>
  );
};
