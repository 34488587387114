import { ReactComponent as GoogleIcon } from '../../assets/icons/external/ic-google.svg';
import { ReactComponent as SAMLIcon } from '../../assets/icons/external/ic-saml.svg';
import { Provider } from '../../gql/graphql';

export const getAuthenticationProviderInfo = (provider?: Provider) => {
  switch (provider) {
    case Provider.Google:
      return {
        name: $t({ id: 'AuthenticationMethodSettings.provider.google', defaultMessage: 'Google' }),
        icon: GoogleIcon,
      };
    case Provider.Saml:
      return {
        name: $t({
          id: 'AuthenticationMethodSettings.provider.saml',
          defaultMessage: 'Security Assertion Markup Language (SAML)',
        }),
        icon: SAMLIcon,
      };
    default:
      return {};
  }
};
