import { ArtifactVersion } from '../../gql/graphql';

export const getArtifactVersionDisplayName = (artifactName: ArtifactVersion | 'FRONTEND'): string => {
  switch (artifactName.toString()) {
    case ArtifactVersion.Backend:
      return $t({ id: 'config.ArtifactVersionDisplayName.Backend', defaultMessage: 'Backend' });
    case ArtifactVersion.Global:
      return $t({ id: 'config.ArtifactVersionDisplayName.Global', defaultMessage: 'Global' });
    default:
      return $t({ id: 'config.ArtifactVersionDisplayName.Frontend', defaultMessage: 'Frontend' });
  }
};
