import React from 'react';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as CompleteIcon } from '../../assets/icons/interface/ic-check-circle.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/interface/ic-remove-circle.svg';
import { Menu, MenuDivider, MenuItem, Typography } from '../../ui';

import styles from './IterationMenu.module.scss';

interface IterationMenuProps {
  onComplete?: () => void;
  onCancel?: () => void;
  onEdit?: () => void;
  onDelete: () => void;
}

export const IterationMenu = ({ onComplete, onCancel, onDelete, onEdit }: IterationMenuProps) => (
  <Menu>
    {onEdit && (
      <MenuItem
        aria-labelledby="iteration-menu-rename"
        className={styles.menu_item}
        leftIcon={EditIcon}
        onClick={onEdit}
      >
        <Typography id="iteration-menu-rename" component="div" color="secondary" variant="callout">
          {$t({ id: 'IterationPage.renameIteration', defaultMessage: 'Rename iteration' })}
        </Typography>
      </MenuItem>
    )}
    {onComplete && (
      <MenuItem
        aria-labelledby="iteration-menu-complete"
        className={styles.menu_item}
        leftIcon={CompleteIcon}
        onClick={onComplete}
      >
        <Typography id="iteration-menu-complete" component="div" color="secondary" variant="callout">
          {$t({ id: 'IterationPage.completeIteration', defaultMessage: 'Complete iteration' })}
        </Typography>
      </MenuItem>
    )}
    {onCancel && (
      <MenuItem
        aria-labelledby="iteration-menu-cancel"
        className={styles.menu_item}
        leftIcon={CancelIcon}
        onClick={onCancel}
      >
        <Typography id="iteration-menu-cancel" component="div" color="secondary" variant="callout">
          {$t({ id: 'IterationPage.cancelIteration', defaultMessage: 'Cancel iteration' })}
        </Typography>
      </MenuItem>
    )}
    {(onComplete || onCancel) && <MenuDivider />}
    <MenuItem
      aria-labelledby="iteration-menu-delete"
      className={styles.menu_item}
      leftIcon={RemoveIcon}
      onClick={onDelete}
      color="danger"
    >
      <Typography id="iteration-menu-delete" component="div" color="danger" variant="callout">
        {$t({ id: 'IterationPage.deleteIteration', defaultMessage: 'Delete iteration' })}
      </Typography>
    </MenuItem>
  </Menu>
);
