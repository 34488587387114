import { graphql } from '../../gql';

export const COMPLETE_ITERATION = graphql(`
  mutation completeIteration($id: VecticeId!) {
    updateIteration(id: $id, data: { status: Completed }) {
      vecticeId
      status
    }
  }
`);
