import { ChangeEvent, createContext } from 'react';

interface RadioGroupContextType {
  name: string;
  value?: any;
  disabled?: boolean;
  onChange?: (value: any, event: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroupContext = createContext<RadioGroupContextType | null>(null);

export const RadioGroupContextProvider = RadioGroupContext.Provider;
