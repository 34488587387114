import cn from 'classnames';
import React from 'react';

import { Card, FlexContainer, Skeleton } from '../../ui';
import { UserAvatarsSkeleton } from '../asset-display';

import styles from './WorkspaceCard.module.scss';

interface WorkspaceCardSkeletonProps {
  variant?: 'default' | 'inline';
}

export const WorkspaceCardSkeleton = ({ variant = 'default' }: WorkspaceCardSkeletonProps) => {
  if (variant === 'inline') {
    return (
      <Card className={cn(styles.workspaceCard, styles.inline)} inert>
        <Skeleton.Paragraph className={styles.name} height="24px" width="150px" />
        <Skeleton.Paragraph className={styles.description} height="20px" variant="callout" width="70%" />
        <FlexContainer className={styles.insights} gap={48} style={{ minHeight: 34 }}>
          <Skeleton.Paragraph variant="callout" width="124px" />
          <Skeleton.Paragraph variant="callout" width="100px" />
          <FlexContainer gap={48}>
            <Skeleton.Paragraph variant="callout" width="80px" />
            <UserAvatarsSkeleton />
          </FlexContainer>
        </FlexContainer>
      </Card>
    );
  }

  return (
    <Card inert>
      <FlexContainer direction="column">
        <Skeleton.Paragraph height="24px" width="150px" />
        <FlexContainer direction="column">
          <Skeleton.Paragraph height="16px" variant="callout" width="100%" />
          <Skeleton.Paragraph height="16px" variant="callout" width="70%" />
        </FlexContainer>
        <Skeleton.Paragraph height="20px" variant="callout" width="120px" />
        <Skeleton.Paragraph height="20px" variant="callout" width="100px" />
        <FlexContainer className={styles.members} gap={16}>
          <Skeleton.Paragraph variant="callout" width="80px" />
          <UserAvatarsSkeleton />
        </FlexContainer>
      </FlexContainer>
    </Card>
  );
};
