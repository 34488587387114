import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import type { Placement, TypographyVariants } from '../../ui';

import { ReactComponent as ArrowIcon } from '../../assets/icons/interface/ic-chevron-down.svg';
import { ReactComponent as IterationIcon } from '../../assets/icons/specials/ic-iteration.svg';
import { MatchEnum, OrderDirection } from '../../gql/graphql';
import { GET_ITERATION_PICKER_LIST } from '../../graphql/queries';
import { buildAdvancedExcludedVecticeIdFilter, buildAdvancedNameFilter } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import {
  Divider,
  Dropdown,
  EmptySelect,
  FilterSearch,
  FlexContainer,
  Icon,
  Loading,
  Menu,
  MenuItem,
  Typography,
} from '../../ui';

import styles from './IterationPicker.module.scss';

interface IterationPickerProps {
  dropdownClassName?: string;
  dropdownPlacement?: Placement;
  iterationId: string;
  iterationName?: string | null;
  phaseId: string;
  triggerVariant?: TypographyVariants;
  unavailableAssetIds?: string[];
  onSelect?: (iterationId: string) => void;
}

export const IterationPicker = ({
  dropdownClassName,
  dropdownPlacement,
  iterationId,
  iterationName,
  phaseId,
  triggerVariant = 'heading2',
  unavailableAssetIds = [],
  onSelect,
}: IterationPickerProps) => {
  const [search, setSearch] = useState('');
  const [showNavigation, setShowNavigation] = useState(false);

  const { data, loading } = useQuery(GET_ITERATION_PICKER_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        phaseId,
        advancedFilters: [
          ...buildAdvancedExcludedVecticeIdFilter([iterationId, ...unavailableAssetIds]),
          ...(search ? buildAdvancedNameFilter([{ match: MatchEnum.Contains, value: search }]) : []),
        ],
      },
      order: {
        field: 'createdDate',
        direction: OrderDirection.Desc,
      },
    },
  });
  const iterations = data?.getIterationList.items;

  const menuItemProps = useMemo(
    () =>
      onSelect
        ? (iterationId: string) => ({ component: undefined, to: '', onClick: () => onSelect(iterationId) })
        : (iterationId: string) => ({
            component: Link,
            to: buildLink(VecticeRoutes.ITERATION, { iterationId }),
          }),
    [onSelect],
  );

  const pickerMenu = (
    <div className={cn(styles.menu, dropdownClassName)}>
      <FilterSearch
        initialSearch={search}
        placeholder={$t({ id: 'IterationPicker.searchPlaceholder', defaultMessage: 'Search by name' })}
        onSearch={setSearch}
        autoFocus
      />
      <Divider className={styles.divider} />
      {(loading || !iterations) && <Loading />}
      {!loading && iterations && (
        <Menu aria-label="Iteration Picker" className={styles.menu_content}>
          {!iterations.length && (
            <EmptySelect text={$t({ id: 'IterationPicker.noIterations', defaultMessage: 'No iterations yet!' })} />
          )}
          {iterations.length > 0 &&
            iterations.map(({ vecticeId, name }) => (
              <MenuItem
                key={vecticeId}
                clickable
                color="secondary"
                leftIcon={IterationIcon}
                {...menuItemProps(vecticeId)}
              >
                {name}
              </MenuItem>
            ))}
        </Menu>
      )}
    </div>
  );

  return (
    <Dropdown
      overlay={pickerMenu}
      trigger="click"
      placement={dropdownPlacement}
      visible={showNavigation}
      onVisibleChange={setShowNavigation}
    >
      <FlexContainer className={styles.trigger} role="button">
        <Typography variant={triggerVariant} color="primary" weight="semi-bold">
          {iterationName}
        </Typography>
        <Icon className={styles.icon} icon={ArrowIcon} size={20} />
      </FlexContainer>
    </Dropdown>
  );
};
