import React from 'react';

import { User } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { ModalPrompt } from '../../ui';
import { FormatUserName } from '../formatters';

interface ToggleUserActivationModalProps {
  user: Pick<User, 'disabled'> & UserIdentity;
  onActivate: () => void;
  onDeactivate: () => void;
  onClose: () => void;
}

export const ToggleUserActivationModal = ({
  user,
  onActivate,
  onDeactivate,
  onClose,
}: ToggleUserActivationModalProps) => (
  <ModalPrompt
    title={user.disabled ? 'Activate' : 'Deactivate'}
    confirmLabel={$t({ id: 'modal.confirm', defaultMessage: 'Confirm' })}
    cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
    onConfirm={user.disabled ? onActivate : onDeactivate}
    onClose={onClose}
  >
    {user.disabled
      ? $t({
          id: 'modal.toggleUserActivation.activateUser',
          defaultMessage: "Are you sure to activate <bold>{user}</bold>'s account?",
          values: {
            user: <FormatUserName user={user} />,
          },
        })
      : $t({
          id: 'modal.toggleUserActivation.deactivateUser',
          defaultMessage:
            "If you deactivate this user, <bold>{user}</bold> will no longer be able to access this organization until an admin reactivates this account. All the user's integrations in this organization will also be removed. The data of the user in Vectice will still be available.",
          values: { user: <FormatUserName user={user} /> },
        })}
  </ModalPrompt>
);
