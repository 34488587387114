import * as Prism from 'prismjs';

export const DEFAULT_CODE_LANGUAGE = 'python';

export const CODE_LANGUAGE_FRIENDLY_NAME_MAP: Record<string, string> = {
  bash: 'bash',
  c: 'C',
  cpp: 'C++',
  csharp: 'CSharp',
  css: 'CSS',
  go: 'Go',
  java: 'Java',
  js: 'JavaScript',
  json: 'JSON',
  julia: 'Julia',
  markdown: 'Markdown',
  matlab: 'MATLAB',
  perl: 'Perl',
  py: 'Python',
  r: 'R',
  rust: 'Rust',
  sas: 'SAS',
  scala: 'Scala',
  'shell-session': 'Shell',
  sql: 'SQL',
  swift: 'Swift',
  xml: 'XML',
  yaml: 'Yaml',
};

export const CODE_LANGUAGE_MAP: Record<string, string> = {
  cs: 'csharp',
  dotnet: 'csharp',
  javascript: 'js',
  md: 'markdown',
  plaintext: 'plain',
  python: 'py',
  text: 'plain',
  shell: 'bash',
  'sh-session': 'shell-session',
  shellsession: 'shell-session',
  webmanifest: 'json',
  yml: 'yaml',
};

export const getCodeLanguages = (): Array<string> =>
  Object.keys(Prism.languages)
    .filter(
      // Prism has several language helpers mixed into languages object
      // so filtering them out here to get langs list
      (language) => typeof Prism.languages[language] !== 'function',
    )
    .sort();

export const isLanguageLoaded = (lang: string) => {
  const languages = getCodeLanguages();
  return languages.indexOf(lang) > -1;
};

export const normalizeCodeLanguage = (lang: string | null | undefined) =>
  (lang && CODE_LANGUAGE_MAP[lang]) ?? lang ?? DEFAULT_CODE_LANGUAGE;

export const getLanguageFriendlyName = (lang: string) => {
  const normalizedLang = normalizeCodeLanguage(lang);
  return CODE_LANGUAGE_FRIENDLY_NAME_MAP[normalizedLang] || normalizedLang;
};
