import { graphql } from '../../../gql';

export const ENTITY_METADATA_CONTENT = graphql(`
  query entityMetadataContent($id: Float!) {
    entityMetadata(id: $id) {
      id
      name
      content
    }
  }
`);
