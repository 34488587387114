import cn from 'classnames';
import React from 'react';

import type { CellSize } from '../config';
import type { PropsWithChildren, ReactNode } from 'react';

import { Typography } from '../../../ui';

import styles from './LinkWrapperCell.module.scss';

interface LinkWrapperCellProps {
  size?: CellSize;
}

export const LinkWrapperCell = ({ children, size = 'lg' }: PropsWithChildren<LinkWrapperCellProps>) => (
  <Typography className={cn(styles.wrapper, styles[size])} ellipsis>
    {children}
  </Typography>
);
