import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ReactComponent as RequestPendingIcon } from '../../assets/icons/specials/badges/ic-request.svg';
import config from '../../config';
import { useAuthentication } from '../../context';
import { OrderDirection, User, Workspace, WorkspaceType } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { GET_USER_LIST, GET_WORKSPACE_MEMBER_LIST } from '../../graphql/queries';
import { ComboBox, FlexContainer, Icon, ModalForm, TextArea, Typography } from '../../ui';
import { reorderMemberList } from '../../utils';
import { UserAvatar } from '../asset-display';
import { FormatUserName } from '../formatters';

import styles from './RequestReviewModal.module.scss';

interface FormData {
  reviewer: (Pick<User, 'id'> & UserIdentity) | null;
  message?: string;
}

interface RequestReviewModalProps {
  workspace?: Pick<Workspace, 'vecticeId' | 'type'> | null;
  onClose: () => void;
  onCreate: SubmitHandler<any>;
}

export const RequestReviewModal = ({ workspace, onClose, onCreate }: RequestReviewModalProps) => {
  const [search, setSearch] = useState<string | null>(null);
  const { user: connectedUser } = useAuthentication();

  const { data: workspaceMembersData } = useQuery(GET_WORKSPACE_MEMBER_LIST, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    skip: !workspace?.vecticeId || workspace?.type === WorkspaceType.Public,
    variables: {
      workspaceId: workspace?.vecticeId!,
      order: { field: 'name', direction: OrderDirection.Asc },
      filters: {
        search,
        includeDisabledUsers: false,
      },
    },
  });

  const { data: userListData } = useQuery(GET_USER_LIST, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    skip: workspace?.type !== WorkspaceType.Public,
    variables: {
      filters: {
        search,
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const members =
    workspaceMembersData?.getWorkspaceMemberList?.items ?? userListData?.getOrganizationUsersForUser ?? [];

  const membersArray = useMemo(() => reorderMemberList(members, connectedUser, !!search), [members]);

  const options = useMemo(() => membersArray.map((member) => ({ member, label: member.name })), [membersArray]);

  const { formState, handleSubmit, register, setValue } = useForm<FormData>({
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    const reviewerId = formData.reviewer?.id;
    if (reviewerId) {
      await onCreate({ reviewerId: +reviewerId, message: formData.message });
      onClose();
    }
  };

  return (
    <ModalForm
      disabled={!isValid}
      isSubmitting={isSubmitting}
      title={
        <FlexContainer gap={9}>
          <Icon icon={RequestPendingIcon} size={30} />
          {$t({ id: 'modal.requestReview.requestReview', defaultMessage: 'Request a Review' })}
        </FlexContainer>
      }
      submitLabel={$t({ id: 'modal.requestReview.sendRequest', defaultMessage: 'Send Request' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      autoComplete="off"
    >
      <Typography paragraph gutterBottom>
        {$t({
          id: 'modal.requestReview.requestReviewHint',
          defaultMessage:
            'Once you send a Review Request, your Phase’s Status will be marked as “In Review” until the Review has been approved or cancelled.',
        })}
      </Typography>
      <ComboBox
        {...register(`reviewer`, {
          required: $t({ id: 'forms.requestReviewForm.required', defaultMessage: 'The value is required' }),
        })}
        label="Reviewer"
        placeholder={$t({ id: 'modal.requestReview.selectUser', defaultMessage: 'Select a User' })}
        options={options}
        onSearch={setSearch}
        onSelectOption={(option) =>
          setValue('reviewer', option?.member ?? null, { shouldValidate: true, shouldDirty: true })
        }
        renderFn={({ member }) => (
          <Typography variant="callout" ellipsis className={styles.option}>
            <UserAvatar hideTooltip size="xs" user={member} />
            <Typography variant="callout">
              <FormatUserName user={member} />{' '}
              {member.id === connectedUser.id && (
                <Typography variant="footnote">
                  {$t({ id: 'memberList.message.assignToMe', defaultMessage: '(Assign to me)' })}
                </Typography>
              )}
            </Typography>
          </Typography>
        )}
        error={errors.reviewer && errors.reviewer.message}
        gutterBottom
      />

      <TextArea
        {...register('message', {
          maxLength: {
            value: config.MAXIMUM_REVIEW_LENGTH,
            message: $t({
              id: 'common.fieldMaxLength',
              defaultMessage: 'This field cannot exceed {max} characters',
              values: {
                max: config.MAXIMUM_REVIEW_LENGTH,
              },
            }),
          },
        })}
        placeholder={$t({ id: 'modal.requestReview.messagePlaceholder', defaultMessage: 'Type your message here...' })}
        label={$t({ id: 'modal.requestReview.messageLabel', defaultMessage: 'Message' })}
        hint={$t({ id: 'modal.requestReview.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
