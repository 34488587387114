import { graphql } from '../../gql';

export const GET_ENTITY_FILE_LIST = graphql(`
  query getEntityFileList($entityId: VecticeId!, $type: EntityFilesTypeEnum!) {
    getEntityFileList(entityId: $entityId, type: $type, page: { size: 100, index: 1 }) {
      items {
        id
        fileName
        hasPreview
        mimeType
        ...entityFileFragment
      }
      total
    }
  }
`);
