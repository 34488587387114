import React from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { EmptyList } from '../../ui';

interface NoResultsProps extends VecticeHTMLProps<HTMLDivElement> {
  borderless?: boolean;
}

export const NoResults = ({ borderless, ...props }: NoResultsProps) => (
  <EmptyList
    icon={VecticeIcon}
    message={$t({ id: 'NoResults.message', defaultMessage: 'No results found' })}
    hint={$t({
      id: 'NoResults.hint',
      defaultMessage: "Try adjusting your search or filter to find what you're looking for.",
    })}
    borderless={borderless}
    {...props}
  />
);
