import React from 'react';

import { getFindingStatusDisplayName, getFindingStatusColor } from '../../entities/utils';
import { FindingStatus } from '../../gql/graphql';
import { Badge, BadgeColors, BadgeProps, Typography } from '../../ui';
import { getFindingStatusIcon } from '../../utils';

interface FindingStatusBadgeProps extends BadgeProps {
  iconOnly?: boolean;
  status?: FindingStatus | string | null;
  contrast?: boolean;
}

export const FindingStatusBadge = ({
  contrast = false,
  iconOnly = false,
  size = 'sm',
  status,
  ...props
}: FindingStatusBadgeProps) => (
  <Badge
    size={size}
    contrast={contrast}
    color={getFindingStatusColor(status) as BadgeColors}
    leftIcon={getFindingStatusIcon(status)}
    {...props}
  >
    {!iconOnly && <Typography ellipsis>{getFindingStatusDisplayName(status)}</Typography>}
  </Badge>
);
