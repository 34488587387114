import { graphql } from '../../gql';

export const WHO_AM_I = graphql(`
  query whoAmI {
    whoAmI {
      user {
        id
        avatarId
        ...userFields
        name
        email
        emailVerificationStatus
        about
        organizationId
        role
        preferences {
          ...userPreferences
          onboardingStatus
        }
      }
      defaultWorkspace {
        vecticeId
        name
        type
      }
    }
  }
`);
