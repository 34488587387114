import { CategoryType } from '../../../gql/graphql';
import {
  BooleanSourceColumn,
  DateSourceColumn,
  NumericalSourceColumn,
  SourceColumnWithFragment,
  TextSourceColumn,
} from '../types';

export const isBooleanSourceColumn = (column: SourceColumnWithFragment): column is BooleanSourceColumn =>
  column.categoryType === CategoryType.Boolean;

export const isDateSourceColumn = (column: SourceColumnWithFragment): column is DateSourceColumn =>
  column.categoryType === CategoryType.Date;

export const isNumericalSourceColumn = (column: SourceColumnWithFragment): column is NumericalSourceColumn =>
  column.categoryType === CategoryType.Numerical;

export const isTextSourceColumn = (column: SourceColumnWithFragment): column is TextSourceColumn =>
  column.categoryType === CategoryType.Text;
