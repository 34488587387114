import { useMutation } from '@apollo/client';
import cn from 'classnames';
import React, { useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import { getPhaseCategoryColor, getPhaseCategoryDisplayName, getPhaseCategoryOptions } from '../../entities/utils';
import { graphql } from '../../gql';
import { ModelVersionStatus as ModelVersionStatusEnum, Phase } from '../../gql/graphql';
import { Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';
import { ModelVersionStatusBadge } from '../badges';

import styles from './RevampedAssetStatus.module.scss';

export const UPDATE_PHASE_CATEGORY = graphql(`
  mutation updatePhaseCategory($phaseId: VecticeId!, $category: ModelVersionStatus!) {
    updatePhase(id: $phaseId, updateModel: { category: $category }) {
      vecticeId
      category
    }
  }
`);

interface PhaseCategoryProps extends ButtonProps {
  category?: ModelVersionStatusEnum;
  phaseId?: string;
  readOnly?: boolean;
}

export const PhaseCategory = ({ category, className, phaseId, readOnly, ...props }: PhaseCategoryProps) => {
  const { current: statusOptions } = useRef(getPhaseCategoryOptions());

  const [updatePhase] = useMutation(UPDATE_PHASE_CATEGORY, {
    optimisticResponse: ({ phaseId, category }) => ({
      updatePhase: {
        vecticeId: phaseId.toString(),
        category,
        __typename: 'Phase' as Phase['__typename'],
      },
    }),
  });

  const handleStatusUpdate = (updatedCategory: ModelVersionStatusEnum) => async () => {
    if (phaseId) {
      await updatePhase({
        variables: {
          phaseId,
          category: updatedCategory,
        },
      });
    }
  };

  const menu = (
    <Menu aria-label="Phase Type Menu">
      {statusOptions.map((option) => (
        <MenuItem key={option.value} selected={category === option.value} onClick={handleStatusUpdate(option.value)}>
          <ModelVersionStatusBadge status={option.value} />
        </MenuItem>
      ))}
    </Menu>
  );

  if (!category) {
    return null;
  }

  if (readOnly) {
    return <ModelVersionStatusBadge status={category} />;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        aria-label="Phase type select"
        className={cn(styles[getPhaseCategoryColor(category)], className)}
        {...props}
      >
        {getPhaseCategoryDisplayName(category)}
      </DropdownTrigger>
    </Dropdown>
  );
};
