import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { graphql } from '../../../gql';
import { Phase, Project } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { buildLink, VecticeRoutes } from '../../../routes';
import { Column, EmptyData, Table, Typography } from '../../../ui';
import { PhaseTooltip, ProjectTooltip, UserAvatar } from '../../asset-display';
import { FormatDate } from '../../formatters';
import { DashboardCard } from '../internals';

export const GET_COMPLETED_PHASES = graphql(`
  query getWorkspaceCompletedPhases($workspaceIdList: [VecticeId!]!) {
    getMostRecentlyCompletedPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        entity {
          vecticeId
          name
          createdDate
          parent {
            vecticeId
            name
          }
          owner {
            ...userFields
          }
        }
        completionDate
      }
    }
  }
`);

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.completedPhases.empty',
      defaultMessage: 'No Completed Phases',
    })}
  />
);

interface CompletedPhasesProps {
  workspaceIdList: string[];
}

export const CompletedPhases = ({ workspaceIdList }: CompletedPhasesProps) => {
  const { data, loading } = useQuery(GET_COMPLETED_PHASES, {
    variables: {
      workspaceIdList,
    },
  });

  const rows = data?.getMostRecentlyCompletedPhases.items ?? [];

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.completedPhases.title', defaultMessage: 'Latest Completed Phases' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.completedPhases.hint',
        defaultMessage: 'The last 10 completed phases ordered by date of completion.',
      })}
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => row.entity.vecticeId}
        emptyText={<NoData />}
      >
        <Column
          key="entity.parent"
          title={$t({ id: 'dashboard.completedPhases.column.project', defaultMessage: 'Project' })}
          ellipsis
        >
          {(project: Pick<Project, 'vecticeId' | 'name'>) => (
            <ProjectTooltip id={project.vecticeId}>
              <Typography
                component={Link}
                to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })}
                color="primary"
                link
              >
                {project.name}
              </Typography>
            </ProjectTooltip>
          )}
        </Column>
        <Column
          key="entity"
          title={$t({ id: 'dashboard.completedPhases.column.phase', defaultMessage: 'Phase' })}
          ellipsis
        >
          {(phase: Pick<Phase, 'vecticeId' | 'name'>) => (
            <PhaseTooltip id={phase.vecticeId}>
              <Typography
                component={Link}
                to={buildLink(VecticeRoutes.PHASE, { phaseId: phase.vecticeId })}
                color="primary"
                link
              >
                {phase.name}
              </Typography>
            </PhaseTooltip>
          )}
        </Column>
        <Column
          key="entity"
          title={$t({ id: 'dashboard.completedPhases.column.creationDate', defaultMessage: 'Creation Date' })}
          width="120px"
          ellipsis
        >
          {(phase: Pick<Phase, 'createdDate'>) => <FormatDate date={phase.createdDate} />}
        </Column>
        <Column
          key="completionDate"
          title={$t({ id: 'dashboard.completedPhases.column.completionDate', defaultMessage: 'Completion Date' })}
          width="140px"
          ellipsis
        >
          {(completionDate: Date) => <FormatDate date={completionDate} />}
        </Column>
        <Column
          key="entity.owner"
          title={$t({ id: 'dashboard.completedPhases.column.phaseOwner', defaultMessage: 'Owner' })}
          align="center"
          width="55px"
        >
          {(owner: UserIdentity) => !!owner && <UserAvatar user={owner} size="xs" />}
        </Column>
      </Table>
    </DashboardCard>
  );
};
