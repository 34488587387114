import cn from 'classnames';
import React from 'react';

import { Checkbox } from '../../ui';

import styles from './SelectAllCheckbox.module.scss';

interface SelectAllCheckboxProps {
  nbOfOptions?: number;
  nbOfSelectedOptions: number;
  className?: string;
  onSelectAll?: () => void;
  onClearAll?: () => void;
}

export const SelectAllCheckbox = ({
  nbOfOptions,
  nbOfSelectedOptions,
  className,
  onSelectAll,
  onClearAll,
}: SelectAllCheckboxProps) => {
  const handleChange = () => {
    if (nbOfSelectedOptions === nbOfOptions) {
      onClearAll?.();
    } else {
      onSelectAll?.();
    }
  };

  if (!nbOfOptions) {
    return null;
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      <Checkbox
        label={$t({
          id: 'lexical.assetSelection.selection.count',
          defaultMessage: '{count, plural, one {# selected} other {# selected}}',
          values: { count: nbOfSelectedOptions },
        })}
        indeterminate={nbOfSelectedOptions > 0 && nbOfSelectedOptions !== nbOfOptions}
        checked={nbOfSelectedOptions === nbOfOptions}
        onChange={handleChange}
      />
    </div>
  );
};
