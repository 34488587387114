import cn from 'classnames';
import React from 'react';

import { Typography } from '../typography';

import styles from './SideNav.module.scss';

export const SideNavTitle = ({ className, children, ...props }: VecticeHTMLProps<HTMLDivElement>) => (
  <Typography
    component="div"
    variant="callout"
    weight="semi-bold"
    color="primary"
    className={cn(styles.side_nav_title, className)}
    {...props}
  >
    {children}
  </Typography>
);
