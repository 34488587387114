import { useQuery } from '@apollo/client';
import { max, StackedBar } from '@vectice/data-viz';
import React, { ReactElement, useMemo } from 'react';

import { getReviewStatusLabel } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { ReviewStatus, User } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { Column, EmptyData, FlexContainer, Table, Tooltip, Typography } from '../../../ui';
import { UserAvatar, UserTooltip } from '../../asset-display';
import { ReviewBadge } from '../../badges';
import { FormatUserName } from '../../formatters';
import { ColorSwatch } from '../colors';
import { DashboardCard, Legend } from '../internals';

export const GET_PENDING_REVIEWS = graphql(`
  query getPendingReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {
    getPendingReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        user {
          ...userFields
        }
        count
      }
    }
  }
`);

interface Datum {
  user: UserIdentity;
  count: number;
}

const getValue = (datum: Datum) => datum.count;
const getColor = () => ColorSwatch.pineGlade;
const margins = { top: 8, left: 0, right: 48, bottom: 8 };

interface PendingReviewsTooltipProps {
  user: UserIdentity;
  count: number;
  children: ReactElement;
}

const PendingReviewsTooltip = ({ user, count, children }: PendingReviewsTooltipProps) => {
  return (
    <Tooltip
      overlay={
        <FlexContainer component={Typography} direction="column" gap={8} variant="callout" color="white">
          <FlexContainer align="center" gap={4}>
            <UserAvatar user={user} size="xs" />
            <Typography weight="semi-bold" ellipsis>
              <FormatUserName user={user} />
            </Typography>
          </FlexContainer>
          <FlexContainer align="center" gap={4}>
            <Typography>
              {$t({
                id: 'dashboard.pendingReviews.tooltip.count',
                defaultMessage: '{count, plural, one {<bold>#</bold> review} other {<bold>#</bold> reviews}}',
                values: { count },
              })}
            </Typography>
            <ReviewBadge status={ReviewStatus.Pending} contrast />
          </FlexContainer>
        </FlexContainer>
      }
      placement="top"
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.pendingReviews.empty',
      defaultMessage: 'No Pending Reviews',
    })}
  />
);

interface PendingReviewsProps {
  workspaceIdList: string[];
}

export const PendingReviews = ({ workspaceIdList }: PendingReviewsProps) => {
  const { data, loading } = useQuery(GET_PENDING_REVIEWS, {
    variables: {
      workspaceIdList,
    },
  });

  const { rows, maxValue } = useMemo(() => {
    const rows = data?.getPendingReviewsCountPerUser.items ?? [];
    const maxValue = max(rows, getValue) || 1;

    return {
      rows,
      maxValue,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.pendingReviews.title', defaultMessage: 'Pending Reviews by Approver' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.pendingReviews.hint',
        defaultMessage: 'The total count of pending reviews by approver.',
      })}
      legend={
        <Legend color={getColor()}>
          <Typography variant="callout">{getReviewStatusLabel(ReviewStatus.Pending)}</Typography>
        </Legend>
      }
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => JSON.stringify(row.user)}
        emptyText={<NoData />}
      >
        <Column
          key="user"
          title={$t({ id: 'dashboard.pendingReviews.column.approver', defaultMessage: 'Approver' })}
          ellipsis
        >
          {(user: Pick<User, 'id'> & UserIdentity) => (
            <UserTooltip id={user.id}>
              <Typography variant="callout" color="secondary" ellipsis>
                <FormatUserName user={user} />
              </Typography>
            </UserTooltip>
          )}
        </Column>
        <Column
          key="count"
          title={$t({ id: 'dashboard.pendingReviews.column.pendingReviews', defaultMessage: 'Nb of Pending Reviews' })}
          width="65%"
        >
          {(_, record: Datum) => (
            <PendingReviewsTooltip user={record.user} count={record.count}>
              <StackedBar
                data={[record]}
                getValue={getValue}
                getColor={getColor}
                maxValue={maxValue}
                rightContent={
                  <Typography variant="callout" color="primary">
                    {getValue(record)}
                  </Typography>
                }
                margins={margins}
              />
            </PendingReviewsTooltip>
          )}
        </Column>
      </Table>
    </DashboardCard>
  );
};
