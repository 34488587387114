import React, { useRef } from 'react';

import { getFindingStatusOptions } from '../../../entities/utils';
import { FindingStatusBadge } from '../../badges';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'status';

class FindingStatusFilterNode<T> extends FilterNode<T> {
  private options = getFindingStatusOptions().map((option) => ({
    ...option,
    labelComponent: <FindingStatusBadge status={option.value} />,
  }));

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="issue-status-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const FindingStatusFilter = () => {
  const { current: node } = useRef(new FindingStatusFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.issueStatus.label', defaultMessage: 'Status' }),
    node,
  });

  return null;
};
