import { useQuery } from '@apollo/client';
import { max, StackedBar, sum } from '@vectice/data-viz';
import React, { Fragment, ReactElement, useMemo } from 'react';

import { getDocumentationPageStatusDisplayName } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { DocumentationPageStatus, User } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { Column, DataList, EmptyData, FlexContainer, Table, Tooltip, Typography } from '../../../ui';
import { UserAvatar, UserTooltip } from '../../asset-display';
import { DocumentationPageStatusBadge } from '../../badges';
import { FormatUserName } from '../../formatters';
import { ColorSwatch } from '../colors';
import { DashboardCard, Legend } from '../internals';

export const GET_PHASE_STATUS_COUNT_PER_OWNER = graphql(`
  query getPhaseStatusCountsPerOwner($workspaceIdList: [VecticeId!]!) {
    getPhaseStatusCountsPerOwner(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        items {
          status
          count
        }
        user {
          ...userFields
        }
      }
    }
  }
`);

interface Datum {
  status: DocumentationPageStatus;
  count: number;
}

const getCount = (datum: Datum) => datum.count;
const getStatus = (datum: Datum) => datum.status;
const getColor = (datum: Datum) => {
  switch (datum.status) {
    case DocumentationPageStatus.Draft:
      return ColorSwatch.selectiveYellow;
    case DocumentationPageStatus.InReview:
      return ColorSwatch.pineGlade;
    default:
      return ColorSwatch.iron;
  }
};
const order = [
  DocumentationPageStatus.Completed,
  DocumentationPageStatus.InReview,
  DocumentationPageStatus.Draft,
  DocumentationPageStatus.NotStarted,
];
const margins = { top: 8, left: 0, right: 48, bottom: 8 };

interface IncompletePhaseTooltipProps {
  user: UserIdentity;
  data: Datum[];
  children: ReactElement;
}

const IncompletePhaseTooltip = ({ user, data, children }: IncompletePhaseTooltipProps) => {
  return (
    <Tooltip
      overlay={
        <FlexContainer component={Typography} direction="column" gap={8} variant="callout" color="white">
          <FlexContainer align="center" gap={4}>
            <UserAvatar user={user} size="xs" />
            <Typography weight="semi-bold" ellipsis>
              <FormatUserName user={user} />
            </Typography>
          </FlexContainer>
          <DataList>
            {data.map((d) => (
              <Fragment key={d.status}>
                <Typography>
                  {$t({
                    id: 'dashboard.incompletePhases.tooltip.phaseCount',
                    defaultMessage: '{count, plural, one {<bold>#</bold> Phase} other {<bold>#</bold> Phases}}',
                    values: { count: getCount(d) },
                  })}
                </Typography>
                <DocumentationPageStatusBadge contrast status={getStatus(d)} />
              </Fragment>
            ))}
          </DataList>
        </FlexContainer>
      }
      placement="top"
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const PhaseStatusLegend = ({ status }: { status: DocumentationPageStatus }) => (
  <Legend color={getColor({ status, count: 0 })}>
    <Typography variant="callout">{getDocumentationPageStatusDisplayName(status)}</Typography>
  </Legend>
);

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.incompletePhases.empty',
      defaultMessage: 'No Ongoing Phases',
    })}
  />
);

interface IncompletePhasesProps {
  workspaceIdList: string[];
}

export const IncompletePhases = ({ workspaceIdList }: IncompletePhasesProps) => {
  const { data, loading } = useQuery(GET_PHASE_STATUS_COUNT_PER_OWNER, {
    variables: {
      workspaceIdList,
    },
  });

  const { rows, maxValue } = useMemo(() => {
    const rows =
      data?.getPhaseStatusCountsPerOwner.items?.map(({ user, items }) => ({
        user,
        data: items
          .map((i) => ({ status: i.status, count: i.count }))
          .sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status))
          .filter((i) => i.status !== DocumentationPageStatus.Completed),
      })) ?? [];
    const maxValue = max(rows, (row) => sum(row.data, getCount)) || 1;

    return {
      rows,
      maxValue,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.incompletePhasesByOwner.title', defaultMessage: 'Incomplete Phases by Owners' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.incompletePhasesByOwner.hint',
        defaultMessage: 'The total count of incomplete phases by each phase owner.',
      })}
      legend={
        <>
          <PhaseStatusLegend status={DocumentationPageStatus.InReview} />
          <PhaseStatusLegend status={DocumentationPageStatus.Draft} />
          <PhaseStatusLegend status={DocumentationPageStatus.NotStarted} />
        </>
      }
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => JSON.stringify(row.user)}
        emptyText={<NoData />}
      >
        <Column
          key="user"
          title={$t({ id: 'dashboard.incompletePhasesByOwner.column.owner', defaultMessage: 'Owner' })}
          ellipsis
        >
          {(user: Pick<User, 'id'> & UserIdentity) => (
            <UserTooltip id={user.id}>
              <Typography variant="callout" color="secondary" ellipsis>
                <FormatUserName user={user} />
              </Typography>
            </UserTooltip>
          )}
        </Column>
        <Column
          key="data"
          title={$t({ id: 'dashboard.incompletePhasesByOwner.column.assetsCreated', defaultMessage: 'Nb of Phases' })}
          width="65%"
        >
          {(data: Datum[], record) => (
            <IncompletePhaseTooltip user={record.user} data={data}>
              <StackedBar
                data={data}
                getValue={getCount}
                getColor={getColor}
                maxValue={maxValue}
                rightContent={
                  <Typography variant="callout" color="primary">
                    {sum(data, getCount)}
                  </Typography>
                }
                margins={margins}
              />
            </IncompletePhaseTooltip>
          )}
        </Column>
      </Table>
    </DashboardCard>
  );
};
