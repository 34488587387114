import React from 'react';

import { ReactComponent as NoPreviewIcon } from '../../assets/brand/logo-solid.svg';
import { Typography, Icon } from '../../ui';

import styles from './NoPreview.module.scss';

interface NoPreviewProps {
  hint?: string;
  variant?: 'default' | 'white';
}

export const NoPreview = ({ hint, variant = 'default' }: NoPreviewProps) => (
  <div className={styles.no_preview_container}>
    <Icon color={variant === 'white' ? 'white' : 'gray'} icon={NoPreviewIcon} size={60} className={styles.icon} />
    <Typography variant="callout" color={variant === 'white' ? 'white' : 'disabled'}>
      {hint || 'No Preview Available'}
    </Typography>
  </div>
);
