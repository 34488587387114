import { graphql } from '../../gql';

export const ADD_TO_STARRED_VERSIONS = graphql(`
  mutation addToStarredDatasetVersions($resource: StarredResourceSettings!) {
    addToStarredDatasetVersions(resource: $resource) {
      vecticeId
      isStarred
    }
  }
`);
