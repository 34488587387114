import cn from 'classnames';
import React, { useRef } from 'react';

import { ReactComponent as CaretIcon } from '../../assets/icons/interface/ic-caret-down.svg';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './Status.module.scss';

interface StatusProps extends VecticeHTMLProps<HTMLButtonElement> {
  contentClassName?: string;
  label: string;
  inert?: boolean;
  withCaret?: boolean;
}

let globalId = 0;

export const Status = ({ children, className, contentClassName, inert, label, withCaret, ...props }: StatusProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`status-${globalId++}`);

  return (
    <Typography
      component={inert ? 'span' : 'button'}
      type={inert ? undefined : 'button'}
      variant="footnote"
      className={cn(styles.status, { [styles.inert]: inert }, className)}
      aria-labelledby={id}
      {...props}
    >
      <Typography id={id} color="secondary">
        {label} :
      </Typography>
      <Typography
        color="primary"
        variant="footnote"
        weight="semi-bold"
        className={cn(styles.content, contentClassName)}
      >
        {children}
      </Typography>
      {withCaret && <Icon icon={CaretIcon} size={20} className={styles.caret} />}
    </Typography>
  );
};
