import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReviewNotificationContent } from '../../../entities';
import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationPhaseOwnerAssigned } from '../types';

import { Notification } from './Notification';

interface PhaseOwnerChangedNotificationProps {
  notification: UserNotificationPhaseOwnerAssigned;
  onClose: () => void;
}

const GET_PHASE_BY_ID = graphql(`
  query getPhaseForPhaseOwnerChange($id: VecticeId!) {
    getPhaseById(id: $id) {
      vecticeId
      name
    }
  }
`);

export const PhaseOwnerChangedNotification = ({
  notification: { creator, content, createdDate },
  onClose,
}: PhaseOwnerChangedNotificationProps) => {
  const parsedContent: ReviewNotificationContent = JSON.parse(content);
  const phaseId = parsedContent?.phaseId;

  const { data, loading, error } = useQuery(GET_PHASE_BY_ID, {
    skip: !phaseId,
    variables: {
      id: phaseId!,
    },
  });
  const phase = data?.getPhaseById;

  if (!phase || loading) {
    return null;
  }

  return (
    <Notification
      component={!error ? Link : undefined}
      to={buildLink(VecticeRoutes.PHASE, { phaseId: phase.vecticeId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.phase.ownerChanged',
        defaultMessage: '<bold>{creator}</bold> assigned <bold>you</bold> as owner of phase <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title: phase.name || $t({ id: 'notification.untitled', defaultMessage: 'Untitled' }),
        },
      })}
    </Notification>
  );
};
