import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { AssetOption, assetOptions, clearOptions, setOptions, showOption, toggleOption } from '../../editor/utils';
import { useVecticeForm } from '../../hooks';
import { Checkbox, FlexContainer, ModalForm } from '../../ui';
import { VecticeResourceType } from '../../utils';

import styles from './CustomizedAssetModal.module.scss';

interface FormData {
  options: number;
}

const GlobalCheckbox = ({
  control,
  assetOptionType,
}: {
  control: Control<FormData>;
  assetOptionType: AssetOption[];
}) => {
  const countSelected = (value: number) => {
    const binary = value.toString(2);
    return binary.split('1').length - 1;
  };

  if (assetOptionType) {
    return (
      <Controller
        control={control}
        name="options"
        render={({ field: { value, onChange } }) => {
          const selectedCount = countSelected(value);
          const isChecked = selectedCount === assetOptionType.length;
          const isIndeterminate = !!selectedCount && !isChecked;
          return (
            <Checkbox
              label={$t({
                id: 'editor.asset.customize.selectedCount',
                defaultMessage: '{count} selected',
                values: { count: selectedCount },
              })}
              indeterminate={isIndeterminate}
              checked={isChecked}
              onChange={() => {
                if (isChecked) {
                  onChange(clearOptions(assetOptionType, value));
                } else {
                  onChange(setOptions(assetOptionType, value));
                }
              }}
              className={styles.global_checkbox}
            />
          );
        }}
      />
    );
  }

  return null;
};

const OptionCheckbox = ({
  control,
  assetOptionType,
  label,
  option,
}: {
  control: Control<FormData>;
  assetOptionType: AssetOption[];
  label: string;
  option: AssetOption;
}) => {
  if (assetOptionType.includes(option)) {
    return (
      <Controller
        control={control}
        name="options"
        render={({ field: { value, onChange } }) => (
          <Checkbox
            label={label}
            checked={showOption(option, value)}
            onChange={() => {
              onChange(toggleOption(option, value));
            }}
          />
        )}
      />
    );
  }

  return null;
};

interface CustomizeAssetModalProps {
  assetType: VecticeResourceType;
  options?: number;
  onSave?: (options: number) => void;
  onClose: () => void;
}

export const CustomizeAssetModal = ({ assetType, options, onSave, onClose }: CustomizeAssetModalProps) => {
  const assetOptionType = assetOptions[assetType];

  const { preSubmit, control } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: { options },
  });

  const handleSubmit = (data: FormData) => onSave?.(data.options);

  return (
    <ModalForm
      title={$t({ id: 'editor.asset.customize.title', defaultMessage: 'Customize Widget' })}
      submitLabel={$t({ id: 'button.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      className={styles.modal}
    >
      <FlexContainer direction="column">
        <GlobalCheckbox assetOptionType={assetOptionType} control={control} />
        <OptionCheckbox
          assetOptionType={assetOptionType}
          control={control}
          option={AssetOption.lineage}
          label={$t({ id: 'editor.asset.customize.lineage', defaultMessage: 'Lineage' })}
        />
        <OptionCheckbox
          assetOptionType={assetOptionType}
          control={control}
          option={AssetOption.resources}
          label={$t({ id: 'editor.asset.customize.resources', defaultMessage: 'Resource summary' })}
        />
        <OptionCheckbox
          assetOptionType={assetOptionType}
          control={control}
          option={AssetOption.metrics}
          label={$t({ id: 'editor.asset.customize.metrics', defaultMessage: 'Metrics' })}
        />
        <OptionCheckbox
          assetOptionType={assetOptionType}
          control={control}
          option={AssetOption.properties}
          label={$t({ id: 'editor.asset.customize.properties', defaultMessage: 'Properties' })}
        />
        <OptionCheckbox
          assetOptionType={assetOptionType}
          control={control}
          option={AssetOption.attachments}
          label={$t({ id: 'editor.asset.customize.attachments', defaultMessage: 'Attachments' })}
        />
      </FlexContainer>
    </ModalForm>
  );
};
