/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment commentFragment on Thread {\n    id\n    fileId\n    editorId\n    createdBy {\n      id\n      ...userFields\n    }\n    repliesInformation {\n      repliesCount\n    }\n    latestReply {\n      id\n      content\n      ...threadReplyFragment\n    }\n    status\n    createdDate\n    content\n    isEditedContent\n    updatedDateContent\n    highlightedText\n  }\n':
    types.CommentFragmentFragmentDoc,
  '\n  mutation createThread($data: ThreadCreateInput!) {\n    createThread(data: $data) {\n      ...commentFragment\n    }\n  }\n':
    types.CreateThreadDocument,
  '\n  mutation createThreadReply($data: ThreadReplyCreateInput!) {\n    createThreadReply(data: $data) {\n      id\n      createdBy {\n        ...userFields\n      }\n      createdDate\n      content\n    }\n  }\n':
    types.CreateThreadReplyDocument,
  '\n  mutation deleteThread($id: Float!) {\n    deleteThread(id: $id)\n  }\n': types.DeleteThreadDocument,
  '\n  mutation deleteThreadReply($id: Float!) {\n    deleteThreadReply(id: $id)\n  }\n':
    types.DeleteThreadReplyDocument,
  '\n  mutation resolveThread($id: Float!) {\n    updateThread(data: { id: $id, status: RESOLVED }) {\n      id\n      status\n    }\n  }\n':
    types.ResolveThreadDocument,
  '\n  subscription subscribeToFileComments($fileId: Float!) {\n    subscribeToFileComments(fileId: $fileId) {\n      fileId\n      threadId\n      content\n      status\n      isDeleted\n      replyId\n      thread {\n        ...commentFragment\n      }\n      reply {\n        ...threadReplyFragment\n      }\n    }\n  }\n':
    types.SubscribeToFileCommentsDocument,
  '\n  query threadReplies($threadId: Float!, $pageSize: Int!) {\n    threadReplies(\n      filters: { threadId: $threadId }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: ASC }\n    ) {\n      items {\n        id\n        content\n        ...threadReplyFragment\n      }\n    }\n  }\n':
    types.ThreadRepliesDocument,
  '\n  query threads($id: Float!, $pageSize: Int!, $editorIds: [String!], $status: ThreadStatus) {\n    threads(\n      filters: { fileId: $id, status: $status, editorIds: $editorIds }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: DESC }\n    ) {\n      items {\n        id\n        editorId\n        status\n        content\n        ...commentFragment\n      }\n    }\n  }\n':
    types.ThreadsDocument,
  '\n  mutation updateThread($data: ThreadUpdateInput!) {\n    updateThread(data: $data) {\n      id\n      content\n      updatedDateContent\n      isEditedContent\n    }\n  }\n':
    types.UpdateThreadDocument,
  '\n  mutation updateThreadReply($data: CommentUpdateInput!) {\n    updateThreadReply(data: $data) {\n      id\n      content\n      updatedDateContent\n      isEditedContent\n    }\n  }\n':
    types.UpdateThreadReplyDocument,
  '\n  fragment threadReplyFragment on ThreadReply {\n    id\n    createdBy {\n      id\n      ...userFields\n    }\n    createdDate\n    content\n    isEditedContent\n    updatedDateContent\n  }\n':
    types.ThreadReplyFragmentFragmentDoc,
  '\n  mutation deleteProject($projectId: VecticeId!) {\n    deleteProject(projectId: $projectId)\n  }\n':
    types.DeleteProjectDocument,
  '\n  mutation deleteWorkspace($id: VecticeId!) {\n    deleteWorkspace(id: $id)\n  }\n': types.DeleteWorkspaceDocument,
  '\n  mutation leaveWorkspace($id: VecticeId!) {\n    leaveWorkspace(id: $id)\n  }\n': types.LeaveWorkspaceDocument,
  '\n  mutation transferProjectOwnership($projectId: VecticeId!, $data: ProjectUpdateInput!) {\n    updateProject(projectId: $projectId, data: $data) {\n      vecticeId\n      ownerId\n      owner {\n        ...userFields\n      }\n    }\n  }\n':
    types.TransferProjectOwnershipDocument,
  '\n  fragment userActivities on PaginatedUserActivityResponse {\n    items {\n      id\n      ...userActivity\n    }\n  }\n':
    types.UserActivitiesFragmentDoc,
  '\n  fragment userActivity on UserActivity {\n    id\n    fromValue\n    fromUser {\n      id\n      ...userFields\n    }\n    toValue\n    toUser {\n      id\n      ...userFields\n    }\n    date\n    isAutomated\n    type\n    targetId\n    targetVecticeId\n    targetName\n    targetType\n    updateType\n    workspace {\n      vecticeId\n      name\n      type\n    }\n    project {\n      vecticeId\n      name\n    }\n    actor {\n      id\n      ...userFields\n    }\n    sourceId\n    sourceVecticeId\n    sourceName\n    sourceWorkspaceVecticeId\n    parentVecticeId\n    parentName\n  }\n':
    types.UserActivityFragmentDoc,
  '\n  query getReportLinkInfo($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      name\n    }\n  }\n':
    types.GetReportLinkInfoDocument,
  '\n  fragment reportListItem on CDTReport {\n    id\n    ...reportRow\n  }\n': types.ReportListItemFragmentDoc,
  '\n  fragment reportRow on CDTReport {\n    id\n    name\n    updatedDate\n    createdBy {\n      ...userFields\n    }\n    project {\n      id\n      name\n      workspace {\n        vecticeId\n        type\n        name\n        type\n      }\n    }\n  }\n':
    types.ReportRowFragmentDoc,
  '\n  query reportTooltip($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n      createdDate\n      createdBy {\n        ...userFields\n      }\n    }\n  }\n':
    types.ReportTooltipDocument,
  '\n  fragment datasetVersionListItem on DataSetVersion {\n    vecticeId\n    ...datasetVersionRow\n  }\n':
    types.DatasetVersionListItemFragmentDoc,
  '\n  fragment datasetVersionRow on DataSetVersion {\n    vecticeId\n    versionNumber\n    isStarred\n    dataSet {\n      vecticeId\n      name\n      type\n    }\n    datasetSources {\n      id\n      size\n      itemsCount\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n':
    types.DatasetVersionRowFragmentDoc,
  '\n  query getDataSetVersionTooltip($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      dataSet {\n        vecticeId\n        name\n        type\n      }\n      versionNumber\n      datasetSources {\n        id\n        usage\n        size\n        itemsCount\n      }\n      properties {\n        id\n      }\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n':
    types.GetDataSetVersionTooltipDocument,
  '\n  query getDatasetVersionWidget($id: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $id) {\n      vecticeId\n      name\n      versionNumber\n      ...datasetVersionRow\n      properties {\n        id\n      }\n      dataSet {\n        vecticeId\n        name\n      }\n      datasetSources {\n        id\n        itemsCount\n      }\n      origin {\n        id\n        datasetInputs\n      }\n    }\n  }\n':
    types.GetDatasetVersionWidgetDocument,
  '\n  fragment datasetListItem on DataSet {\n    vecticeId\n    ...datasetRow\n  }\n':
    types.DatasetListItemFragmentDoc,
  '\n  fragment datasetRow on DataSet {\n    vecticeId\n    name\n    type\n    versionCount\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n':
    types.DatasetRowFragmentDoc,
  '\n  query getDataSetTooltip($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      name\n      type\n      versionCount\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n':
    types.GetDataSetTooltipDocument,
  '\n  query getDatasetWidget($id: VecticeId!) {\n    getDataset(datasetId: $id) {\n      vecticeId\n      ...datasetRow\n    }\n  }\n':
    types.GetDatasetWidgetDocument,
  '\n  query getEntityFileWidget($id: Float!) {\n    getEntityFileById(id: $id) {\n      id\n      fileName\n      mimeType\n      entityId\n      type\n      hasPreview\n      hasStaticView\n      ...entityFileFragment\n    }\n  }\n':
    types.GetEntityFileWidgetDocument,
  '\n  query getEntityFileIterationOrigin($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetEntityFileIterationOriginDocument,
  '\n  query getEntityFileDatasetVersionOrigin($id: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $id) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n':
    types.GetEntityFileDatasetVersionOriginDocument,
  '\n  query getEntityFileModelVersionOrigin($id: VecticeId!) {\n    getModelVersion(modelVersionId: $id) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n':
    types.GetEntityFileModelVersionOriginDocument,
  '\n  query entityMetadata($id: Float!) {\n    entityMetadata(id: $id) {\n      id\n      name\n    }\n  }\n':
    types.EntityMetadataDocument,
  '\n  query getFindingTooltip($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      status\n      owner {\n        ...userFields\n      }\n    }\n  }\n':
    types.GetFindingTooltipDocument,
  '\n  fragment iterationListItem on Iteration {\n    vecticeId\n    ...iterationRow\n  }\n':
    types.IterationListItemFragmentDoc,
  '\n  fragment iterationRow on Iteration {\n    vecticeId\n    index\n    description\n    name\n    status\n    starred\n    updatedDate\n    phase {\n      vecticeId\n      name\n    }\n    owner {\n      ...userFields\n    }\n  }\n':
    types.IterationRowFragmentDoc,
  '\n  query getIterationTooltip($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      index\n      name\n      status\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n':
    types.GetIterationTooltipDocument,
  '\n  fragment artifact on IterationStepArtifact {\n    id\n    text\n    type\n    datasetVersion {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n    }\n    entityFile {\n      id\n      fileName\n      mimeType\n      contentType\n      entityId\n    }\n    modelVersion {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n    }\n    entityMetadata {\n      id\n      name\n    }\n  }\n':
    types.ArtifactFragmentDoc,
  '\n  query getIterationWidget($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      description\n      ...iterationRow\n    }\n  }\n':
    types.GetIterationWidgetDocument,
  '\n  query getIterationArtifactsForWidget($id: VecticeId!) {\n    getIterationById(id: $id) {\n      paginatedArtifacts(page: { index: 1, size: 100 }) {\n        items {\n          id\n          type\n          ...artifact\n        }\n      }\n    }\n  }\n':
    types.GetIterationArtifactsForWidgetDocument,
  '\n  query getIterationStepListForWidget($id: VecticeId!) {\n    sections(parentId: $id, page: { index: 1, size: 100 }, order: { field: "index", direction: ASC }) {\n      items {\n        id\n        name\n        description\n        paginatedArtifacts(page: { index: 1, size: 100 }) {\n          total\n          items {\n            id\n            type\n            ...artifact\n          }\n        }\n      }\n      total\n    }\n  }\n':
    types.GetIterationStepListForWidgetDocument,
  '\n  query getLineageTooltip($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId) {\n      items {\n        id\n        lineageArtifactType\n        artifactType\n        dataSetVersion {\n          vecticeId\n          name\n          versionNumber\n          dataSet {\n            vecticeId\n            name\n          }\n        }\n        modelVersion {\n          vecticeId\n          name\n          versionNumber\n          model {\n            vecticeId\n            name\n          }\n        }\n        codeVersion {\n          id\n          code {\n            name\n          }\n          gitVersion {\n            commitHash\n            uri\n            entrypoint\n          }\n          databricksVersion {\n            urlNotebook\n            relativePath\n          }\n          otherCodeVersion {\n            id\n            url\n            firstAdditionalInformation\n            secondAdditionalInformation\n          }\n        }\n      }\n    }\n  }\n':
    types.GetLineageTooltipDocument,
  '\n  query getLineageWidget($id: Float!) {\n    getJobRunById(lineageId: $id) {\n      id\n    }\n  }\n':
    types.GetLineageWidgetDocument,
  '\n  query getModelVersionLinkInfo($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      versionNumber\n      model {\n        name\n      }\n    }\n  }\n':
    types.GetModelVersionLinkInfoDocument,
  '\n  fragment modelVersionListItem on ModelVersion {\n    vecticeId\n    ...modelVersionRow\n  }\n':
    types.ModelVersionListItemFragmentDoc,
  '\n  fragment modelVersionRow on ModelVersion {\n    vecticeId\n    versionNumber\n    status\n    algorithmName\n    isStarred\n    model {\n      vecticeId\n      name\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n':
    types.ModelVersionRowFragmentDoc,
  '\n  query getModelVersionTooltip($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      model {\n        vecticeId\n        name\n      }\n      versionNumber\n      status\n      approval\n      risk\n      algorithmName\n      createdBy {\n        ...userFields\n      }\n      properties {\n        id\n      }\n      metrics {\n        id\n      }\n      createdDate\n    }\n  }\n':
    types.GetModelVersionTooltipDocument,
  '\n  query getModelVersionWidget($id: VecticeId!) {\n    getModelVersion(modelVersionId: $id) {\n      vecticeId\n      ...modelVersionRow\n      properties {\n        id\n      }\n      metrics {\n        id\n      }\n      model {\n        vecticeId\n      }\n      origin {\n        id\n\n        datasetInputs\n      }\n    }\n  }\n':
    types.GetModelVersionWidgetDocument,
  '\n  fragment modelListItem on Model {\n    vecticeId\n    ...modelRow\n  }\n': types.ModelListItemFragmentDoc,
  '\n  fragment modelRow on Model {\n    vecticeId\n    name\n    type\n    versionStats {\n      experimentationCount\n      validationCount\n      productionCount\n      stagingCount\n      retiredCount\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n':
    types.ModelRowFragmentDoc,
  '\n  query getModelTooltip($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      vecticeId\n      name\n      type\n      versionCount\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n':
    types.GetModelTooltipDocument,
  '\n  query getModelWidget($id: VecticeId!) {\n    getModelById(modelId: $id) {\n      vecticeId\n      ...modelRow\n    }\n  }\n':
    types.GetModelWidgetDocument,
  '\n  fragment phaseListItem on Phase {\n    vecticeId\n    ...phaseRow\n  }\n': types.PhaseListItemFragmentDoc,
  '\n  fragment phaseRow on Phase {\n    vecticeId\n    name\n    status\n    updatedDate\n    owner {\n      ...userFields\n    }\n  }\n':
    types.PhaseRowFragmentDoc,
  '\n  query getPhaseTooltip($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      status\n      activeReview {\n        vecticeId\n        status\n      }\n      iterationsCount {\n        total\n      }\n      owner {\n        ...userFields\n      }\n    }\n  }\n':
    types.GetPhaseTooltipDocument,
  '\n  fragment projectCard on Project {\n    vecticeId\n    name\n    workspace {\n      vecticeId\n      type\n      name\n    }\n  }\n':
    types.ProjectCardFragmentDoc,
  '\n  fragment projectListItem on Project {\n    vecticeId\n    ...projectRow\n  }\n':
    types.ProjectListItemFragmentDoc,
  '\n  fragment projectRow on Project {\n    vecticeId\n    name\n    status\n    phasesCounts {\n      done\n      ongoing\n      toDo\n      total\n    }\n    updatedDate\n    owner {\n      ...userFields\n    }\n  }\n':
    types.ProjectRowFragmentDoc,
  '\n  query getProjectTooltip($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      status\n      phasesCounts {\n        done\n        ongoing\n        toDo\n        total\n      }\n    }\n  }\n':
    types.GetProjectTooltipDocument,
  '\n  fragment reviewListItem on Review {\n    vecticeId\n    ...reviewRow\n    phase {\n      vecticeId\n    }\n  }\n':
    types.ReviewListItemFragmentDoc,
  '\n  fragment reviewRow on Review {\n    id\n    status\n    createdDate\n    createdBy {\n      ...userFields\n    }\n    phase {\n      vecticeId\n      name\n      parent {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.ReviewRowFragmentDoc,
  '\n  query getUserTooltip($userId: Float!) {\n    getOrganizationUser(userId: $userId) {\n      id\n      name\n      avatarId\n      email\n      color\n      role\n      disabled\n    }\n  }\n':
    types.GetUserTooltipDocument,
  '\n  query getWorkspaceTooltip($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      type\n      projectCount\n      userCount(filters: { includeDisabledUsers: false })\n    }\n  }\n':
    types.GetWorkspaceTooltipDocument,
  '\n  query CDTReportsSelector(\n    $type: CDTReportQueryType\n    $filters: CDTReportFiltersInput!\n    $order: ListOrderInput\n    $page: PageInput\n  ) {\n    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.CdtReportsSelectorDocument,
  '\n  query getDatasetVersionPickerList(\n    $datasetId: VecticeId!\n    $filters: DataSetVersionFilterInput\n    $order: ListOrderInput\n  ) {\n    getDatasetVersionsList(datasetId: $datasetId, filters: $filters, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n      }\n    }\n  }\n':
    types.GetDatasetVersionPickerListDocument,
  '\n  query getModelVersionPickerList(\n    $modelId: VecticeId!\n    $filters: ModelVersionListFiltersInput\n    $order: ListOrderInput\n  ) {\n    getModelVersionsList(modelId: $modelId, filters: $filters, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n      }\n    }\n  }\n':
    types.GetModelVersionPickerListDocument,
  '\n  query getProjectsSelector(\n    $workspaceId: VecticeId!\n    $filters: ProjectListFiltersInput!\n    $order: ListOrderInput\n    $page: PageIndexInput\n  ) {\n    getProjectList(workspaceId: $workspaceId, filters: $filters, order: $order, page: $page) {\n      items {\n        name\n        vecticeId\n      }\n    }\n  }\n':
    types.GetProjectsSelectorDocument,
  '\n  query datasetsToSelect($projectId: VecticeId!, $search: String, $versionId: VecticeId) {\n    getDatasetList(\n      projectId: $projectId\n      filters: { search: $search, versionId: $versionId }\n      page: { index: 1, size: 10 }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n      total\n    }\n  }\n':
    types.DatasetsToSelectDocument,
  '\n  query datasetVersionsToSelect($datasetId: VecticeId!, $order: ListOrderInput) {\n    getDatasetVersionsList(datasetId: $datasetId, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        dataSet {\n          vecticeId\n          name\n          project {\n            vecticeId\n          }\n        }\n      }\n      total\n    }\n  }\n':
    types.DatasetVersionsToSelectDocument,
  '\n  query modelsToSelect($projectId: VecticeId!, $search: String) {\n    getModelList(projectId: $projectId, filters: { search: $search }, page: { index: 1, size: 10 }) {\n      items {\n        vecticeId\n        name\n      }\n      total\n    }\n  }\n':
    types.ModelsToSelectDocument,
  '\n  query modelVersionsToSelect($modelId: VecticeId!, $order: ListOrderInput) {\n    getModelVersionsList(modelId: $modelId, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        model {\n          vecticeId\n          name\n          project {\n            vecticeId\n          }\n        }\n      }\n      total\n    }\n  }\n':
    types.ModelVersionsToSelectDocument,
  '\n  query getIterations($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        index\n        name\n        phase {\n          vecticeId\n          name\n        }\n      }\n      total\n    }\n  }\n':
    types.GetIterationsDocument,
  '\n  query getSelectedCDTReport($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n    }\n  }\n':
    types.GetSelectedCdtReportDocument,
  '\n  query getSelectedProject($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetSelectedProjectDocument,
  '\n  query getSelectedWorkspace($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetSelectedWorkspaceDocument,
  '\n  query sourceColumns(\n    $id: Float!\n    $columnSourceType: ColumnSourceType!\n    $search: String\n    $order: ListOrderInput\n    $page: PageIndexInput\n  ) {\n    sourceColumns(\n      id: $id\n      columnSourceType: $columnSourceType\n      filters: { search: $search }\n      order: $order\n      page: $page\n    ) {\n      items {\n        id\n        name\n      }\n    }\n  }\n':
    types.SourceColumnsDocument,
  '\n  query sourceColumn($id: Float!, $columnSourceType: ColumnSourceType!) {\n    sourceColumn(id: $id, columnSourceType: $columnSourceType) {\n      id\n      name\n    }\n  }\n':
    types.SourceColumnDocument,
  '\n  fragment authMethodCard on KeycloakIdentityProvider {\n    alias\n    displayName\n    enabled\n    providerId\n  }\n':
    types.AuthMethodCardFragmentDoc,
  '\n  query getContributorList(\n    $workspaceIdList: [VecticeId!]!\n    $order: ListOrderInput\n    $filters: ContributorListFiltersInput\n    $page: PageInput = { size: 30, index: 1 }\n  ) {\n    getContributorList(workspaceIdList: $workspaceIdList, order: $order, filters: $filters, page: $page) {\n      items {\n        id\n        name\n        ...userFields\n      }\n    }\n  }\n':
    types.GetContributorListDocument,
  '\n  query getActivityInsights($workspaceIdList: [VecticeId!]!) {\n    getActivityInsights(workspaceIdList: $workspaceIdList, numberOfDays: 30) {\n      completedProjectsCount\n      completedPhasesCount\n      approvedPhasesCount\n      modelVersionsCount\n      datasetVersionsCount\n    }\n  }\n':
    types.GetActivityInsightsDocument,
  '\n  query getWorkspaceActivityTrend($workspaceIdList: [VecticeId!]!, $userTimezone: String!) {\n    getWorkspaceActivityTrend(workspaceIdList: $workspaceIdList, userTimezone: $userTimezone) {\n      date\n      count\n    }\n  }\n':
    types.GetWorkspaceActivityTrendDocument,
  '\n  query getWorkspaceCompletedPhases($workspaceIdList: [VecticeId!]!) {\n    getMostRecentlyCompletedPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          createdDate\n          parent {\n            vecticeId\n            name\n          }\n          owner {\n            ...userFields\n          }\n        }\n        completionDate\n      }\n    }\n  }\n':
    types.GetWorkspaceCompletedPhasesDocument,
  '\n  query getWorkspaceCompletedProjects($workspaceIdList: [VecticeId!]!) {\n    getMostRecentlyCompletedProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          createdDate\n          owner {\n            ...userFields\n          }\n        }\n        completionDate\n      }\n    }\n  }\n':
    types.GetWorkspaceCompletedProjectsDocument,
  '\n  query getCompletedReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getCompletedReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        user {\n          ...userFields\n        }\n        total\n        items {\n          status\n          count\n        }\n      }\n    }\n  }\n':
    types.GetCompletedReviewsCountPerUserDocument,
  '\n  query getPhaseStatusCountsPerOwner($workspaceIdList: [VecticeId!]!) {\n    getPhaseStatusCountsPerOwner(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        items {\n          status\n          count\n        }\n        user {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.GetPhaseStatusCountsPerOwnerDocument,
  '\n  query getWorkspaceCompletedIterationsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceCompletedIterationsCountPerUser(\n      workspaceIdList: $workspaceIdList\n      page: { index: 1, size: 10 }\n      numberOfDays: 30\n    ) {\n      items {\n        user {\n          id\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n':
    types.GetWorkspaceCompletedIterationsCountPerUserDocument,
  '\n  query getWorkspaceOngoingPhases($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceOngoingPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          status\n          parent {\n            vecticeId\n            name\n          }\n          owner {\n            ...userFields\n          }\n        }\n        days\n      }\n    }\n  }\n':
    types.GetWorkspaceOngoingPhasesDocument,
  '\n  query getWorkspaceOngoingProjects($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceOngoingProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          owner {\n            ...userFields\n          }\n          phasesCounts {\n            toDo\n            ongoing\n            done\n            total\n          }\n        }\n        days\n      }\n    }\n  }\n':
    types.GetWorkspaceOngoingProjectsDocument,
  '\n  query getPendingReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getPendingReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        user {\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n':
    types.GetPendingReviewsCountPerUserDocument,
  '\n  query getPhaseStatusesBreakdown($workspaceIdList: [VecticeId!]!) {\n    getPhaseStatusCounts(workspaceIdList: $workspaceIdList) {\n      items {\n        status\n        count\n      }\n      total\n    }\n  }\n':
    types.GetPhaseStatusesBreakdownDocument,
  '\n  query getWorkspaceCompletedPhasesCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceCompletedPhasesCountPerUser(\n      workspaceIdList: $workspaceIdList\n      page: { index: 1, size: 10 }\n      numberOfDays: 30\n    ) {\n      items {\n        user {\n          id\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n':
    types.GetWorkspaceCompletedPhasesCountPerUserDocument,
  '\n  query getProjectStatusesBreakdown($workspaceIdList: [VecticeId!]!) {\n    getProjectStatusCounts(workspaceIdList: $workspaceIdList) {\n      items {\n        status\n        count\n      }\n      total\n    }\n  }\n':
    types.GetProjectStatusesBreakdownDocument,
  '\n  query getTopAssetsCreators($workspaceIdList: [VecticeId!]!) {\n    getTopAssetsCreators(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }, numberOfDays: 30) {\n      items {\n        user {\n          ...userFields\n        }\n        datasetVersionCount\n        modelVersionCount\n      }\n    }\n  }\n':
    types.GetTopAssetsCreatorsDocument,
  '\n  query getDatasetVersionFileSource($fileSourceId: Float!) {\n    getDatasetFilesSource(fileSourceId: $fileSourceId) {\n      id\n      name\n      size\n      columnsNumber\n      rowsNumber\n      uri\n      mimeType\n      fileCreatedDate\n      fileUpdatedDate\n      skippedStatistics\n      extraMetadata {\n        key\n        value\n        displayName\n      }\n    }\n  }\n':
    types.GetDatasetVersionFileSourceDocument,
  '\n  query getDatasetVersionDbSource($dbSourceId: Float!) {\n    getDBSource(dbSourceId: $dbSourceId) {\n      id\n      name\n      size\n      type\n      columnsNumber\n      rowsNumber\n      uri\n      tableCreatedDate\n      tableUpdatedDate\n      skippedStatistics\n      extraMetadata {\n        key\n        value\n        displayName\n      }\n    }\n  }\n':
    types.GetDatasetVersionDbSourceDocument,
  '\n  query getDataSourceFileSourceList($datasetSourceId: Float!) {\n    fileSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {\n      items {\n        id\n        filename\n        mimeType\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n':
    types.GetDataSourceFileSourceListDocument,
  '\n  query getDataSourceDbSourceList($datasetSourceId: Float!) {\n    dbSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {\n      items {\n        id\n        tablename\n        type\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n':
    types.GetDataSourceDbSourceListDocument,
  '\n  query getDatasetVersionResources($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      targetColumn\n      datasetSources {\n        id\n        type\n        usage\n        ...datasetSourceMenu\n      }\n    }\n  }\n':
    types.GetDatasetVersionResourcesDocument,
  '\n  query getDatasetVersionFileSourceList($datasetVersionId: VecticeId!) {\n    fileSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {\n      items {\n        id\n        filename\n        mimeType\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n':
    types.GetDatasetVersionFileSourceListDocument,
  '\n  query getDatasetVersionDbSourceList($datasetVersionId: VecticeId!) {\n    dbSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {\n      items {\n        id\n        tablename\n        type\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n':
    types.GetDatasetVersionDbSourceListDocument,
  '\n  fragment datasetSourceMenu on DatasetSource {\n    id\n    itemsCount\n    columnsCount\n    size\n    type\n    usage\n  }\n':
    types.DatasetSourceMenuFragmentDoc,
  '\n  mutation updateDatasetVersion($targetColumn: String, $datasetVersionId: VecticeId!) {\n    updateDatasetVersion(data: { targetColumn: $targetColumn }, datasetVersionId: $datasetVersionId) {\n      vecticeId\n      targetColumn\n    }\n  }\n':
    types.UpdateDatasetVersionDocument,
  '\n  mutation updateColumnSourceDescription($id: Float!, $columnSourceType: ColumnSourceType!, $description: String) {\n    updateColumnSourceDescription(id: $id, columnSourceType: $columnSourceType, description: $description)\n  }\n':
    types.UpdateColumnSourceDescriptionDocument,
  '\n  query getDatasetVersionFileColumns($fileSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {\n    getFileSourceColumnsList(\n      fileSourceId: $fileSourceId\n      page: { index: $pageIndex, size: $pageSize }\n      filters: { searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        id\n        name\n        description\n        categoryType\n        statistics {\n          ...BooleanSourceColumnStatistics\n          ...DateSourceColumnStatistics\n          ...NumericalSourceColumnStatistics\n          ...TextSourceColumnStatistics\n        }\n      }\n      total\n    }\n  }\n':
    types.GetDatasetVersionFileColumnsDocument,
  '\n  query getDatasetVersionDbColumns($dbSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {\n    getDBSourceColumnsList(\n      dbSourceId: $dbSourceId\n      page: { index: $pageIndex, size: $pageSize }\n      filters: { searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        id\n        name\n        description\n        categoryType\n        statistics {\n          ...BooleanSourceColumnStatistics\n          ...DateSourceColumnStatistics\n          ...NumericalSourceColumnStatistics\n          ...TextSourceColumnStatistics\n        }\n      }\n      total\n    }\n  }\n':
    types.GetDatasetVersionDbColumnsDocument,
  '\n  fragment BooleanSourceColumnStatistics on DataframeStatistics {\n    boolean {\n      false\n      missing\n      true\n    }\n  }\n':
    types.BooleanSourceColumnStatisticsFragmentDoc,
  '\n  fragment DateSourceColumnStatistics on DataframeStatistics {\n    date {\n      maximum\n      mean\n      median\n      minimum\n      missing\n    }\n  }\n':
    types.DateSourceColumnStatisticsFragmentDoc,
  '\n  fragment NumericalSourceColumnStatistics on DataframeStatistics {\n    numerical {\n      mean\n      missing\n      quantiles {\n        qMin\n        q25\n        q50\n        q75\n        qMax\n      }\n      stdDeviation\n    }\n  }\n':
    types.NumericalSourceColumnStatisticsFragmentDoc,
  '\n  fragment TextSourceColumnStatistics on DataframeStatistics {\n    text {\n      missing\n      mostCommons {\n        frequency\n        value\n      }\n      unique\n    }\n  }\n':
    types.TextSourceColumnStatisticsFragmentDoc,
  '\n  query getDatasetVersionWithOrigin($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      origin {\n        id\n        datasetInputs\n      }\n      dataSet {\n        vecticeId\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n':
    types.GetDatasetVersionWithOriginDocument,
  '\n  fragment DatasetVersionSidebar on DataSetVersion {\n    vecticeId\n    description\n    sourceOrigin\n    dataSet {\n      vecticeId\n      type\n      project {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n    origins {\n      phase {\n        vecticeId\n        name\n      }\n      iteration {\n        vecticeId\n        name\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n':
    types.DatasetVersionSidebarFragmentDoc,
  '\n  mutation updateDatasetVersionDescription($datasetVersionId: VecticeId!, $data: DatasetVersionUpdateInput!) {\n    updateDatasetVersion(datasetVersionId: $datasetVersionId, data: $data) {\n      vecticeId\n      description\n    }\n  }\n':
    types.UpdateDatasetVersionDescriptionDocument,
  '\n  mutation addToStarredDatasetVersions($resource: StarredResourceSettings!) {\n    addToStarredDatasetVersions(resource: $resource) {\n      vecticeId\n      isStarred\n    }\n  }\n':
    types.AddToStarredDatasetVersionsDocument,
  '\n  query getDatasetType($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      type\n    }\n  }\n':
    types.GetDatasetTypeDocument,
  '\n  query getDatasetVersionsList(\n    $datasetId: VecticeId!\n    $search: String\n    $createdBy: [ID!]\n    $showStarred: Boolean\n    $contributors: [ID!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n    $order: ListOrderInput\n  ) {\n    getDatasetVersionsList(\n      order: $order\n      datasetId: $datasetId\n      filters: {\n        createdBy: $createdBy\n        search: $search\n        contributors: $contributors\n        showStarred: $showStarred\n        advancedFilters: $advancedFilters\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n        origin {\n          id\n        }\n        properties {\n          ...propertiesFields\n        }\n        createdBy {\n          ...userFields\n        }\n        datasetSources {\n          type\n          usage\n          size\n          itemsCount\n          columnsCount\n        }\n        createdDate\n      }\n      total\n    }\n  }\n':
    types.GetDatasetVersionsListDocument,
  '\n  query getPropertyKeysOfDataset($datasetId: VecticeId!) {\n    getPropertyKeysOfEntity(entityId: $datasetId, entityType: DATASET)\n  }\n':
    types.GetPropertyKeysOfDatasetDocument,
  '\n  query getUserWorkspaceFiltersList($search: String!) {\n    getUserWorkspaceList(filters: { searchFilter: { search: $search, fields: ["name"] } }) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.GetUserWorkspaceFiltersListDocument,
  '\n  query getUniqueAlgorithmNames(\n    $workspaceIdList: [VecticeId!]!\n    $modelIdList: [VecticeId!]\n    $search: String!\n    $projectIdList: [VecticeId!]\n  ) {\n    getUniqueAlgorithmNames(\n      workspaceIdList: $workspaceIdList\n      modelIdList: $modelIdList\n      projectIdList: $projectIdList\n      filters: { search: $search }\n    ) {\n      items {\n        algorithmName\n      }\n    }\n  }\n':
    types.GetUniqueAlgorithmNamesDocument,
  '\n  query getUniqueInventoryReferences(\n    $workspaceIdList: [VecticeId!]!\n    $modelIdList: [VecticeId!]\n    $search: String!\n    $projectIdList: [VecticeId!]\n  ) {\n    getUniqueInventoryReferences(\n      workspaceIdList: $workspaceIdList\n      modelIdList: $modelIdList\n      projectIdList: $projectIdList\n      filters: { search: $search }\n    ) {\n      items {\n        inventoryReference\n      }\n    }\n  }\n':
    types.GetUniqueInventoryReferencesDocument,
  '\n  mutation removeFinding($id: Float!) {\n    removeFinding(id: $id)\n  }\n': types.RemoveFindingDocument,
  '\n  query getRecentProjects {\n    getProjectList(\n      filters: { myRecent: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: 6, index: 1 }\n    ) {\n      items {\n        vecticeId\n        templateUsed\n        ...projectCard\n      }\n    }\n  }\n':
    types.GetRecentProjectsDocument,
  '\n  query getUserIterations($afterCursor: String, $pageSize: Int!) {\n    getIterationList(\n      filters: { onlyMine: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        vecticeId\n        ...iterationListItem\n        phase {\n          vecticeId\n          parent {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              type\n              name\n            }\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.GetUserIterationsDocument,
  '\n  query getAffectedReviews($afterCursor: String, $pageSize: Int!) {\n    getReviewList(\n      filters: { status: [Pending], affectedToMe: true }\n      order: { field: "createdDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        id\n        ...reviewListItem\n        phase {\n          vecticeId\n          parent {\n            vecticeId\n            workspace {\n              vecticeId\n              type\n              name\n            }\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.GetAffectedReviewsDocument,
  '\n  query getUserPhases($afterCursor: String, $pageSize: Int!) {\n    getPhaseList(\n      filters: { status: [NotStarted, Draft, InReview], onlyMine: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        vecticeId\n        ...phaseListItem\n        parent {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            type\n            name\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.GetUserPhasesDocument,
  '\n  query getUserReports($contributors: [ID!], $afterCursor: String, $pageSize: Int!, $advancedFilters: [GroupFilter!]) {\n    CDTReports(\n      filters: { contributors: $contributors, advancedFilters: $advancedFilters }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        id\n        ...reportListItem\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n':
    types.GetUserReportsDocument,
  '\n  fragment IterationSidebar on Iteration {\n    vecticeId\n    status\n    description\n    phase {\n      vecticeId\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n          type\n        }\n      }\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n':
    types.IterationSidebarFragmentDoc,
  '\n  fragment IterationStepArtifact on IterationStepArtifact {\n    id\n    text\n    type\n    index\n    datasetVersion {\n      vecticeId\n      attachments(page: { size: 100, index: 1 }) {\n        items {\n          ...entityFileFragment\n        }\n      }\n    }\n    entityFileId\n    entityFile {\n      ...entityFileFragment\n    }\n    modelVersion {\n      vecticeId\n    }\n    entityMetadata {\n      id\n      name\n    }\n  }\n':
    types.IterationStepArtifactFragmentDoc,
  '\n  fragment IterationStep on IterationStep {\n    id\n    name\n    description\n    updatedDate\n    paginatedArtifacts(page: { index: 1, size: 100 }) {\n      total\n      items {\n        id\n        stepId\n        type\n        index\n        ...IterationStepArtifact\n      }\n    }\n  }\n':
    types.IterationStepFragmentDoc,
  '\n  query iterations($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getIterationList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        starred\n        status\n        createdDate\n        updatedDate\n        phaseId\n        phase {\n          vecticeId\n          name\n        }\n        owner {\n          ...userFields\n        }\n        createdBy {\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n':
    types.IterationsDocument,
  '\n  fragment lineageAssetFragment on LineageArtifact {\n    id\n    artifactType\n    dataSetVersion {\n      vecticeId\n      versionNumber\n      dataSet {\n        name\n        vecticeId\n      }\n    }\n    modelVersion {\n      vecticeId\n      versionNumber\n      model {\n        name\n        vecticeId\n      }\n    }\n  }\n':
    types.LineageAssetFragmentFragmentDoc,
  '\n  fragment lineageCodeFragment on LineageArtifact {\n    codeVersion {\n      id\n      code {\n        name\n        description\n      }\n      gitVersion {\n        branchName\n        commitHash\n        repositoryName\n        uri\n        entrypoint\n        isDirty\n        additionalInformation\n      }\n      databricksVersion {\n        urlNotebook\n        relativePath\n        timestamp\n        additionalInformation\n      }\n      otherCodeVersion {\n        id\n        url\n        firstAdditionalInformation\n        secondAdditionalInformation\n        thirdAdditionalInformation\n      }\n    }\n  }\n':
    types.LineageCodeFragmentFragmentDoc,
  '\n  mutation removeCDTReport($id: Float!) {\n    removeCDTReport(id: $id)\n  }\n': types.RemoveCdtReportDocument,
  '\n  mutation deleteDataset($datasetId: VecticeId!) {\n    deleteDataset(datasetId: $datasetId)\n  }\n':
    types.DeleteDatasetDocument,
  '\n  mutation deleteDatasetVersion($datasetVersionId: VecticeId!) {\n    deleteDatasetVersion(datasetVersionId: $datasetVersionId)\n  }\n':
    types.DeleteDatasetVersionDocument,
  '\n  mutation removeEmailDomainFromOrganization($organizationId: Float!, $emailDomain: String!) {\n    removeEmailDomainFromOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {\n      id\n      emailDomains {\n        domain\n      }\n    }\n  }\n':
    types.RemoveEmailDomainFromOrganizationDocument,
  '\n  mutation removeIteration($iterationId: VecticeId!) {\n    removeIteration(id: $iterationId)\n  }\n':
    types.RemoveIterationDocument,
  '\n  mutation deleteModel($modelId: VecticeId!) {\n    deleteModel(modelId: $modelId)\n  }\n':
    types.DeleteModelDocument,
  '\n  mutation deleteModelVersion($modelVersionId: VecticeId!) {\n    deleteModelVersion(modelVersionId: $modelVersionId)\n  }\n':
    types.DeleteModelVersionDocument,
  '\n  mutation removePhase($phaseId: VecticeId!) {\n    removePhase(id: $phaseId)\n  }\n': types.RemovePhaseDocument,
  '\n  mutation duplicateCDTReport($id: Float!, $duplicateInput: BaseDocumentationDuplicateInput!) {\n    duplicateCDTReport(id: $id, duplicateInput: $duplicateInput) {\n      id\n    }\n  }\n':
    types.DuplicateCdtReportDocument,
  '\n  mutation duplicatePhase($id: VecticeId!, $duplicateInput: PhaseDuplicateInput!) {\n    duplicatePhase(id: $id, duplicateInput: $duplicateInput) {\n      vecticeId\n    }\n  }\n':
    types.DuplicatePhaseDocument,
  '\n  query checkDatasetNameAvailability($datasetId: VecticeId!, $projectId: VecticeId!, $name: String!) {\n    checkDatasetNameAvailability(datasetId: $datasetId, projectId: $projectId, name: $name)\n  }\n':
    types.CheckDatasetNameAvailabilityDocument,
  '\n  mutation updateDataset($datasetId: VecticeId!, $data: DatasetUpdateInput!) {\n    updateDataset(datasetId: $datasetId, dataset: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.UpdateDatasetDocument,
  '\n  query checkIterationNameAvailability($parentId: VecticeId!, $name: String!, $resourceId: VecticeId) {\n    checkIterationNameAvailability(parentId: $parentId, name: $name, resourceId: $resourceId)\n  }\n':
    types.CheckIterationNameAvailabilityDocument,
  '\n  mutation updateIteration($id: VecticeId!, $data: IterationUpdateInput!) {\n    updateIteration(id: $id, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.UpdateIterationDocument,
  '\n  query checkModelNameAvailability($modelId: VecticeId!, $projectId: VecticeId!, $name: String!) {\n    checkModelNameAvailability(modelId: $modelId, projectId: $projectId, name: $name)\n  }\n':
    types.CheckModelNameAvailabilityDocument,
  '\n  mutation updateModel($modelId: VecticeId!, $data: ModelUpdateInput!) {\n    updateModel(modelId: $modelId, model: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.UpdateModelDocument,
  '\n  mutation updateCDTReport($id: Float!, $data: CDTReportUpdateInput!) {\n    updateCDTReport(id: $id, data: $data) {\n      id\n      name\n      modelVersion {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.UpdateCdtReportDocument,
  '\n  mutation createPhase($parentId: VecticeId!, $createModel: PhaseCreateInput!) {\n    createPhase(parentId: $parentId, createModel: $createModel) {\n      vecticeId\n    }\n  }\n':
    types.CreatePhaseDocument,
  '\n  mutation moveProject($projectId: VecticeId!, $projectName: String!, $targetWorkspaceId: VecticeId!) {\n    moveProject(projectMoveInput: { id: $projectId, name: $projectName, targetWorkspaceId: $targetWorkspaceId }) {\n      vecticeId\n      workspace {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.MoveProjectDocument,
  '\n  fragment modelVersionInformation on ModelVersion {\n    vecticeId\n    context {\n      library\n      url\n      run\n      extraInfo {\n        key\n        value\n        displayName\n      }\n    }\n  }\n':
    types.ModelVersionInformationFragmentDoc,
  '\n  query getModelVersionOverview($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      origin {\n        id\n        datasetInputs\n        iteration {\n          vecticeId\n        }\n      }\n      model {\n        vecticeId\n        project {\n          vecticeId\n        }\n      }\n      ...modelVersionInformation\n    }\n  }\n':
    types.GetModelVersionOverviewDocument,
  '\n  fragment ModelVersionSidebar on ModelVersion {\n    vecticeId\n    description\n    algorithmName\n    status\n    risk\n    approval\n    inventoryReference\n    model {\n      vecticeId\n      type\n      project {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n    origins {\n      phase {\n        vecticeId\n        name\n      }\n      iteration {\n        vecticeId\n        name\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n':
    types.ModelVersionSidebarFragmentDoc,
  '\n  mutation updateModelVersionDescription($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      description\n    }\n  }\n':
    types.UpdateModelVersionDescriptionDocument,
  '\n  mutation updateModelVersionTechnique($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      algorithmName\n    }\n  }\n':
    types.UpdateModelVersionTechniqueDocument,
  '\n  mutation updateModelVersionInventory($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      inventoryReference\n    }\n  }\n':
    types.UpdateModelVersionInventoryDocument,
  '\n  mutation updateModelVersionRisk($modelVersionId: VecticeId!, $risk: ModelVersionRisk!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { risk: $risk }) {\n      vecticeId\n      risk\n    }\n  }\n':
    types.UpdateModelVersionRiskDocument,
  '\n  mutation updateModelVersionApproval($modelVersionId: VecticeId!, $approval: ModelVersionApproval!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { approval: $approval }) {\n      vecticeId\n      approval\n    }\n  }\n':
    types.UpdateModelVersionApprovalDocument,
  '\n  mutation addToStarredModelVersions($resource: StarredResourceSettings!) {\n    addToStarredModelVersions(resource: $resource) {\n      vecticeId\n      isStarred\n    }\n  }\n':
    types.AddToStarredModelVersionsDocument,
  '\n  query getMetricKeysOfEntity($modelId: VecticeId!) {\n    getMetricKeysOfEntity(entityId: $modelId, entityType: MODEL)\n  }\n':
    types.GetMetricKeysOfEntityDocument,
  '\n  query getModelVersionsList(\n    $modelId: VecticeId!\n    $search: String\n    $order: ListOrderInput\n    $pageIndex: Int!\n    $pageSize: Int!\n    $showStarred: Boolean\n    $createdBy: [ID!]\n    $contributors: [ID!]\n    $algorithmNames: [String!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $risks: [ModelVersionRisk!]\n    $approvals: [ModelVersionApproval!]\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getModelVersionsList(\n      modelId: $modelId\n      filters: {\n        createdBy: $createdBy\n        contributors: $contributors\n        modelVersionStatus: $modelVersionStatus\n        risks: $risks\n        approvals: $approvals\n        search: $search\n        showStarred: $showStarred\n        algorithmNames: $algorithmNames\n        advancedFilters: $advancedFilters\n      }\n      order: $order\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n        status\n        risk\n        approval\n        algorithmName\n        inventoryReference\n        origin {\n          id\n        }\n        properties {\n          ...propertiesFields\n        }\n        metrics {\n          ...metricsFields\n        }\n        createdBy {\n          ...userFields\n        }\n        createdDate\n      }\n      total\n    }\n  }\n':
    types.GetModelVersionsListDocument,
  '\n  query getPropertyKeysOfModel($modelId: VecticeId!) {\n    getPropertyKeysOfEntity(entityId: $modelId, entityType: MODEL)\n  }\n':
    types.GetPropertyKeysOfModelDocument,
  '\n                    fragment NewUserNotification on UserNotification {\n                      id\n                      type\n                      content\n                      createdDate\n                      creator {\n                        id\n                        ...userFields\n                      }\n                      workspace {\n                        vecticeId\n                        name\n                      }\n                    }\n                  ':
    types.NewUserNotificationFragmentDoc,
  '\n  query countUnseenUserNotifications {\n    countUnseenUserNotifications\n  }\n':
    types.CountUnseenUserNotificationsDocument,
  '\n  query getUserNotificationList(\n    $order: ListOrderInput\n    $filters: UserNotificationListFiltersInput\n    $afterCursor: String\n    $pageSize: Int!\n  ) {\n    getUserNotificationList(order: $order, page: { size: $pageSize, afterCursor: $afterCursor }, filters: $filters) {\n      items {\n        id\n        type\n        content\n        createdDate\n        creator {\n          id\n          ...userFields\n        }\n        workspace {\n          vecticeId\n          name\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n      total\n    }\n  }\n':
    types.GetUserNotificationListDocument,
  '\n  mutation markAllUserNotificationsAsSeen {\n    markAllUserNotificationsAsSeen\n  }\n':
    types.MarkAllUserNotificationsAsSeenDocument,
  '\n  query getFindingById($id: Float!) {\n    Finding(id: $id) {\n      name\n    }\n  }\n':
    types.GetFindingByIdDocument,
  '\n  query getPhaseForMention($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetPhaseForMentionDocument,
  '\n  query getCDTReportForMention($id: Float!) {\n    CDTReport(id: $id) {\n      name\n    }\n  }\n':
    types.GetCdtReportForMentionDocument,
  '\n  query getWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!) {\n    getWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId) {\n      id\n      status\n    }\n  }\n':
    types.GetWorkspaceAccessRequestDocument,
  '\n  mutation resolveWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!, $approve: Boolean!) {\n    resolveWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId, approve: $approve) {\n      id\n      status\n    }\n  }\n':
    types.ResolveWorkspaceAccessRequestDocument,
  '\n  query getPhaseForPhaseOwnerChange($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetPhaseForPhaseOwnerChangeDocument,
  '\n  query getProjectForProjectOwnerChange($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetProjectForProjectOwnerChangeDocument,
  '\n  query getProjectForProjectOwnershipTransfer($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetProjectForProjectOwnershipTransferDocument,
  '\n  query getProjectForProjectStatusChange($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n':
    types.GetProjectForProjectStatusChangeDocument,
  '\n  query getPhaseForRequestMention($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n  }\n':
    types.GetPhaseForRequestMentionDocument,
  '\n  subscription unseenUserNotificationCountChanged {\n    userNotificationChanged {\n      unseenCount\n    }\n  }\n':
    types.UnseenUserNotificationCountChangedDocument,
  '\n  subscription userNotificationCreatedUnseen {\n    userNotificationCreatedUnseen {\n      payload {\n        id\n        type\n        content\n        createdDate\n        status\n        creator {\n          ...userFields\n        }\n        workspace {\n          vecticeId\n          name\n        }\n      }\n    }\n  }\n':
    types.UserNotificationCreatedUnseenDocument,
  '\n  query getPhaseOverview($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      name\n      parent {\n        vecticeId\n      }\n      vecticeId\n      description\n      enforceRequirementsAsSections\n    }\n  }\n':
    types.GetPhaseOverviewDocument,
  '\n  fragment PhaseSidebar on Phase {\n    vecticeId\n    status\n    category\n    parent {\n      vecticeId\n      workspace {\n        vecticeId\n        type\n      }\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n':
    types.PhaseSidebarFragmentDoc,
  '\n  query entityMetadataContent($id: Float!) {\n    entityMetadata(id: $id) {\n      id\n      name\n      content\n    }\n  }\n':
    types.EntityMetadataContentDocument,
  '\n  query getInitialAssetCount(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n      }\n    }\n  }\n':
    types.GetInitialAssetCountDocument,
  '\n  query searchProjectAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $advancedFilters: [GroupFilter!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n        items {\n          vecticeId\n          ...projectListItem\n          workspace {\n            vecticeId\n            name\n            type\n          }\n        }\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n      }\n    }\n  }\n':
    types.SearchProjectAssetsDocument,
  '\n  query searchDatasetAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $dsTypes: [DatasetType!]\n    $advancedFilters: [GroupFilter!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n        items {\n          vecticeId\n          ...datasetListItem\n          project {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              name\n              type\n            }\n          }\n        }\n      }\n      model {\n        total\n      }\n    }\n  }\n':
    types.SearchDatasetAssetsDocument,
  '\n  query searchModelAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $types: [ModelType!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $algorithmNames: [String!]\n    $inventoryReferences: [String!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: {\n        search: $search\n        contributors: $contributors\n        types: $types\n        modelVersionStatus: $modelVersionStatus\n        algorithmNames: $algorithmNames\n        inventoryReferences: $inventoryReferences\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n        items {\n          vecticeId\n          ...modelListItem\n          project {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              name\n              type\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.SearchModelAssetsDocument,
  '\n  mutation updatePhaseStatus($phaseId: VecticeId!, $status: DocumentationPageStatus!) {\n    updatePhase(id: $phaseId, updateModel: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n':
    types.UpdatePhaseStatusDocument,
  '\n  mutation updateIterationStatus($id: VecticeId!, $status: IterationStatus!) {\n    updateIteration(id: $id, data: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n':
    types.UpdateIterationStatusDocument,
  '\n  mutation updateModelVersionStatus($modelVersionId: VecticeId!, $status: ModelVersionStatus!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n':
    types.UpdateModelVersionStatusDocument,
  '\n  mutation updatePhaseCategory($phaseId: VecticeId!, $category: ModelVersionStatus!) {\n    updatePhase(id: $phaseId, updateModel: { category: $category }) {\n      vecticeId\n      category\n    }\n  }\n':
    types.UpdatePhaseCategoryDocument,
  '\n  mutation updatePhaseOwner($phaseId: VecticeId!, $ownerId: Float) {\n    updatePhase(id: $phaseId, updateModel: { ownerId: $ownerId }) {\n      vecticeId\n      ownerId\n      owner {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n':
    types.UpdatePhaseOwnerDocument,
  '\n  subscription subscribeToUnresolvedFileComments($fileId: Float!) {\n    subscribeToFileComments(fileId: $fileId) {\n      fileId\n      threadId\n      status\n      isDeleted\n      replyId\n    }\n  }\n':
    types.SubscribeToUnresolvedFileCommentsDocument,
  '\n  query threadsUnresolvedCount($id: Float!, $pageSize: Int!) {\n    threads(\n      filters: { fileId: $id, status: UNRESOLVED }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: DESC }\n    ) {\n      items {\n        id\n      }\n      total\n    }\n  }\n':
    types.ThreadsUnresolvedCountDocument,
  '\n  mutation createEntityMetric($entityId: VecticeId!, $createMetric: CreateEntityMetricInput!) {\n    createEntityMetric(entityId: $entityId, entityType: modelVersion, createMetric: $createMetric) {\n      ...metricsFields\n    }\n  }\n':
    types.CreateEntityMetricDocument,
  '\n  mutation createEntityProperty(\n    $entityId: VecticeId!\n    $entityType: EntityPropertyType!\n    $createProperty: CreateEntityPropertyInput!\n  ) {\n    createEntityProperty(entityId: $entityId, entityType: $entityType, createProperty: $createProperty) {\n      ...propertiesFields\n    }\n  }\n':
    types.CreateEntityPropertyDocument,
  '\n  mutation deleteEntityMetrics($metricIds: [Float!]!) {\n    deleteEntityMetrics(entityType: modelVersion, metricIds: $metricIds)\n  }\n':
    types.DeleteEntityMetricsDocument,
  '\n  mutation deleteEntityProperties($entityType: EntityPropertyType!, $propertyIds: [Float!]!) {\n    deleteEntityProperties(entityType: $entityType, propertyIds: $propertyIds)\n  }\n':
    types.DeleteEntityPropertiesDocument,
  '\n  query getEntityMetricsByParentId(\n    $entityId: VecticeId!\n    $pageSize: Int!\n    $pageIndex: Int!\n    $category: ModelVersionStatus\n  ) {\n    getResource: getEntityMetricsByParentId(\n      entityId: $entityId\n      entityType: modelVersion\n      page: { size: $pageSize, index: $pageIndex }\n      order: { field: "key", direction: ASC }\n      category: $category\n    ) {\n      items {\n        ...metricsFields\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.GetEntityMetricsByParentIdDocument,
  '\n  query getEntityPropertiesByParentId(\n    $entityType: EntityPropertyType!\n    $entityId: VecticeId!\n    $pageSize: Int!\n    $pageIndex: Int!\n    $category: ModelVersionStatus\n  ) {\n    getResource: getEntityPropertiesByParentId(\n      entityType: $entityType\n      entityId: $entityId\n      page: { size: $pageSize, index: $pageIndex }\n      order: { field: "key", direction: ASC }\n      category: $category\n    ) {\n      total\n      items {\n        ...propertiesFields\n      }\n      page {\n        ...pageIndexFields\n      }\n    }\n  }\n':
    types.GetEntityPropertiesByParentIdDocument,
  '\n  mutation updateEntityMetric($updateMetric: UpdateEntityMetricInput!) {\n    updateEntityMetric(entityType: modelVersion, updateMetric: $updateMetric) {\n      ...metricsFields\n    }\n  }\n':
    types.UpdateEntityMetricDocument,
  '\n  mutation updateEntityProperty($entityType: EntityPropertyType!, $updateProperty: UpdateEntityPropertyInput!) {\n    updateEntityProperty(entityType: $entityType, updateProperty: $updateProperty) {\n      ...propertiesFields\n    }\n  }\n':
    types.UpdateEntityPropertyDocument,
  '\n  mutation askUserTypeUpgrade {\n    askUserTypeUpgrade\n  }\n': types.AskUserTypeUpgradeDocument,
  '\n  fragment WorkspaceCard on Workspace {\n    vecticeId\n    name\n    type\n    description\n    userList(filters: { includeDisabledUsers: false }) {\n      total\n      items {\n        id\n        ...userFields\n      }\n    }\n    projectCount\n    updatedDate\n    userContext {\n      accessibility\n    }\n  }\n':
    types.WorkspaceCardFragmentDoc,
  '\n  fragment WorkspaceGrid on PaginatedWorkspaceResponseOutput {\n    items {\n      vecticeId\n      ...WorkspaceCard\n    }\n  }\n':
    types.WorkspaceGridFragmentDoc,
  '\n  query getOrganizationWorkspaceList(\n    $pageIndex: Int!\n    $pageSize: Int!\n    $order: ListOrderInput\n    $search: String!\n    $type: [WorkspaceType!]\n  ) {\n    getOrganizationWorkspaceList(\n      page: { size: $pageSize, index: $pageIndex }\n      order: $order\n      filters: { type: $type, searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        vecticeId\n        type\n        name\n        description\n        userCount(filters: { includeDisabledUsers: false })\n        projectCount\n        updatedDate\n        createdDate\n      }\n      total\n    }\n  }\n':
    types.GetOrganizationWorkspaceListDocument,
  '\n  query getPublicConfig {\n    getPublicConfig {\n      sendEmail\n      isTrialEnabled\n      versions {\n        artifactName\n        version\n      }\n      featureFlags {\n        code\n        enabled\n      }\n    }\n  }\n':
    types.GetPublicConfigDocument,
  '\n  query getOrgConfig {\n    getOrgConfig {\n      deployment\n      sendEmail\n      isTrialEnabled\n      versions {\n        artifactName\n        version\n      }\n      featureFlags {\n        code\n        enabled\n      }\n      organization {\n        id\n        name\n        level\n        displayName\n        emailDomains {\n          domain\n        }\n        configuration {\n          passwordAuthenticationEnabled\n          autoProvisioning\n          emailNotificationEnabled\n          dfStatisticsRowThreshold\n          dfStatisticsSampleRows\n          dfStatisticsMaxColumns\n          everyOneCanCreateWorkspace\n          everyOneCanInviteUser\n        }\n        licenseOptions {\n          isSingleSignOnEnabled\n          isAskAIEnabled\n        }\n      }\n    }\n  }\n':
    types.GetOrgConfigDocument,
  '\n  fragment fileHistoryEntry on FileHistoryWithSessionOutput {\n    sessionUuid\n    fileHistoryId\n    user {\n      ...userFields\n    }\n    numberOfHistoriesInSession\n    createdDate\n  }\n':
    types.FileHistoryEntryFragmentDoc,
  '\n  query getFileHistoryList($fileId: Float!, $afterCursor: String, $filters: FileHistoryFilter) {\n    getFileHistoryList(\n      fileId: $fileId\n      filters: $filters\n      order: { field: "createdDate", direction: DESC }\n      page: { size: 20, afterCursor: $afterCursor }\n    ) {\n      page {\n        hasNextPage\n        afterCursor\n      }\n      items {\n        ...fileHistoryEntry\n      }\n    }\n  }\n':
    types.GetFileHistoryListDocument,
  '\n  query getFileHistory($fileHistoryId: String!) {\n    getFileHistory(fileHistoryId: $fileHistoryId) {\n      id\n      createdDate\n      content\n    }\n  }\n':
    types.GetFileHistoryDocument,
  '\n  mutation restoreHistory($fileHistoryId: String!) {\n    restoreHistory(fileHistoryId: $fileHistoryId)\n  }\n':
    types.RestoreHistoryDocument,
  '\n  query getCDTReportFile($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      id\n      name\n      file {\n        id\n        content\n        headerContent\n        footerContent\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.GetCdtReportFileDocument,
  '\n  query getFindingFile($findingId: Float!) {\n    Finding(id: $findingId) {\n      id\n      name\n      file {\n        id\n        content\n        headerContent\n        footerContent\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.GetFindingFileDocument,
  '\n  subscription fileUpdated($fileId: Float!, $source: String) {\n    fileUpdated(fileId: $fileId, source: $source) {\n      id\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.FileUpdatedDocument,
  '\n  query getDocumentationFile($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      parent {\n        id\n        name\n      }\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.GetDocumentationFileDocument,
  '\n  mutation unlockFile($fileId: Float!) {\n    unlockFile(fileId: $fileId)\n  }\n': types.UnlockFileDocument,
  '\n  mutation updateFileContent($fileId: Float!, $data: FileContentUpdateInput!) {\n    updateFileContent(fileId: $fileId, data: $data) {\n      id\n      updatedDate\n      isFileLocked\n      fingerprint\n      lastUpdatedBy {\n        ...userFields\n      }\n    }\n  }\n':
    types.UpdateFileContentDocument,
  '\n  query getAttachmentsToInsert($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $search: String) {\n    attachments(entityId: $entityId, entityType: $type, search: $search) {\n      id\n      name\n      attachmentType\n      entityId\n      mimeType\n      type\n      content\n      ...attachmentFragment\n    }\n  }\n':
    types.GetAttachmentsToInsertDocument,
  '\n  query getDatasetVersionsToInsert($datasetId: VecticeId!, $search: String) {\n    getDatasetVersionsList(datasetId: $datasetId, filters: { search: $search }) {\n      items {\n        vecticeId\n        name\n        versionNumber\n        attachments {\n          total\n        }\n        ...datasetVersionRow\n      }\n      total\n    }\n  }\n':
    types.GetDatasetVersionsToInsertDocument,
  '\n  query getDatasetsToInsert($projectId: VecticeId!, $search: String) {\n    getDatasetList(projectId: $projectId, filters: { search: $search }) {\n      items {\n        vecticeId\n        versionCount\n        name\n        ...datasetRow\n      }\n      total\n    }\n  }\n':
    types.GetDatasetsToInsertDocument,
  '\n  query getIterationArtifactsCount($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      id\n      paginatedArtifacts(filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n        total\n      }\n    }\n  }\n':
    types.GetIterationArtifactsCountDocument,
  '\n  query getIterationArtifactsToInsert($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      id\n      paginatedArtifacts(page: { index: 1, size: 100 }, filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n        items {\n          id\n          datasetVersion {\n            vecticeId\n            versionNumber\n            dataSet {\n              vecticeId\n              name\n            }\n            attachments {\n              total\n            }\n            ...datasetVersionRow\n          }\n          modelVersion {\n            vecticeId\n            versionNumber\n            model {\n              vecticeId\n              name\n            }\n            attachments {\n              total\n            }\n            ...modelVersionRow\n          }\n          entityFile {\n            id\n            type\n            mimeType\n            entityId\n            ...entityFileFragment\n          }\n          entityMetadata {\n            id\n            name\n            content\n          }\n          text\n          type\n        }\n        total\n      }\n    }\n  }\n':
    types.GetIterationArtifactsToInsertDocument,
  '\n  query getIterationStepsToInsert($iterationId: VecticeId!) {\n    sections(parentId: $iterationId, order: { field: "index", direction: ASC }) {\n      items {\n        id\n        name\n        paginatedArtifacts(filters: { withAssetDeleted: false }) {\n          total\n        }\n      }\n      total\n    }\n  }\n':
    types.GetIterationStepsToInsertDocument,
  '\n  query getIterationStepArtifactsToInsert($stepId: Float!) {\n    getStepById(id: $stepId) {\n      id\n      paginatedArtifacts(page: { index: 1, size: 100 }, filters: { withAssetDeleted: false }) {\n        items {\n          id\n          datasetVersion {\n            vecticeId\n            versionNumber\n            dataSet {\n              vecticeId\n              name\n              type\n            }\n            attachments {\n              total\n            }\n            ...datasetVersionRow\n          }\n          modelVersion {\n            vecticeId\n            versionNumber\n            model {\n              vecticeId\n              type\n              name\n            }\n            attachments {\n              total\n            }\n            ...modelVersionRow\n          }\n          entityFile {\n            id\n            type\n            fileName\n            mimeType\n            entityId\n            ...entityFileFragment\n          }\n          entityMetadata {\n            id\n            name\n            content\n          }\n          text\n          type\n        }\n        total\n      }\n    }\n  }\n':
    types.GetIterationStepArtifactsToInsertDocument,
  '\n  query getIterationsToInsert($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        index\n        name\n        sections {\n          total\n        }\n        paginatedArtifacts(filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n          total\n        }\n        ...iterationRow\n      }\n      total\n    }\n  }\n':
    types.GetIterationsToInsertDocument,
  '\n  query getModelVersionsToInsert($modelId: VecticeId!, $search: String) {\n    getModelVersionsList(modelId: $modelId, filters: { search: $search }) {\n      items {\n        vecticeId\n        name\n        versionNumber\n        attachments {\n          total\n        }\n        ...modelVersionRow\n      }\n      total\n    }\n  }\n':
    types.GetModelVersionsToInsertDocument,
  '\n  query getModelsToInsert($projectId: VecticeId!, $search: String) {\n    getModelList(projectId: $projectId, filters: { search: $search }) {\n      items {\n        vecticeId\n        versionCount\n        name\n        ...modelRow\n      }\n      total\n    }\n  }\n':
    types.GetModelsToInsertDocument,
  '\n  query getPhasesToInsert($projectId: VecticeId!, $search: String) {\n    getPhaseList(filters: { search: $search, parentId: $projectId }, order: { field: "index", direction: ASC }) {\n      items {\n        vecticeId\n        name\n        iterationsCount {\n          total\n        }\n        ...phaseRow\n      }\n      total\n    }\n  }\n':
    types.GetPhasesToInsertDocument,
  '\n  mutation generateDocumentation($data: GenerateDocumentationInput!) {\n    generateDocumentation(data: $data)\n  }\n':
    types.GenerateDocumentationDocument,
  '\n  query SearchAllAskAIAssets(\n    $filters: AskAIAssetListFiltersInput!\n    $assetType: AskAISearchAssetType!\n    $page: PageIndexInput\n  ) {\n    searchAllAskAIAssets(filters: $filters, assetType: $assetType, page: $page) {\n      all {\n        items {\n          name\n          vecticeId\n          versionNumber\n          isStarred\n          type\n        }\n        total\n      }\n      phase {\n        items {\n          name\n          vecticeId\n          version\n        }\n        total\n      }\n      datasetVersion {\n        items {\n          dataSet {\n            name\n            type\n          }\n          vecticeId\n          isStarred\n          versionNumber\n        }\n        total\n      }\n      modelVersion {\n        items {\n          model {\n            name\n            type\n          }\n          vecticeId\n          isStarred\n          versionNumber\n        }\n        total\n      }\n    }\n  }\n':
    types.SearchAllAskAiAssetsDocument,
  '\n  query getIterationName($id: VecticeId!) {\n    getIterationById(id: $id) {\n      name\n      phase {\n        name\n        vecticeId\n      }\n    }\n  }\n':
    types.GetIterationNameDocument,
  '\n  query getBreadcrumbModelVersion($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      name\n      vecticeId\n      versionNumber\n      model {\n        name\n        vecticeId\n      }\n    }\n  }\n':
    types.GetBreadcrumbModelVersionDocument,
  '\n  query getBreadcrumbDatasetVersion($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      name\n      vecticeId\n      versionNumber\n      dataSet {\n        name\n        vecticeId\n      }\n    }\n  }\n':
    types.GetBreadcrumbDatasetVersionDocument,
  '\n  query getIterationIndex($id: VecticeId!) {\n    getIterationById(id: $id) {\n      index\n      name\n    }\n  }\n':
    types.GetIterationIndexDocument,
  '\n  query checkLLMPromptNameAvailability($type: LLMPromptTypeEnum!, $resourceId: Float, $name: String!) {\n    checkLLMPromptNameAvailability(type: $type, resourceId: $resourceId, name: $name)\n  }\n':
    types.CheckLlmPromptNameAvailabilityDocument,
  '\n  mutation createLLMPrompt($data: LLMPromptCreateInput!) {\n    createLLMPrompt(data: $data) {\n      id\n      type\n      name\n      content\n      updatedDate\n      createdBy {\n        ...userFields\n      }\n    }\n  }\n':
    types.CreateLlmPromptDocument,
  '\n  mutation generateAssetDocumentation($data: GenerateLLMDocumentationInput!) {\n    generateAssetDocumentation(data: $data) {\n      input\n      outputs\n    }\n  }\n':
    types.GenerateAssetDocumentationDocument,
  '\n  query llmPrompts($order: ListOrderInput, $filters: LLMPromptFiltersInput!, $page: PageIndexInput) {\n    llmPrompts(filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n        content\n        createdBy {\n          ...userFields\n        }\n        updatedDate\n      }\n      total\n    }\n  }\n':
    types.LlmPromptsDocument,
  '\n  mutation removeLLMPrompt($id: Float!) {\n    removeLLMPrompt(id: $id)\n  }\n': types.RemoveLlmPromptDocument,
  '\n  mutation updateLLMPrompt($data: LLMPromptUpdateInput!, $id: Float!) {\n    updateLLMPrompt(data: $data, id: $id) {\n      id\n      type\n      name\n      content\n    }\n  }\n':
    types.UpdateLlmPromptDocument,
  '\n  query datasetImageOrigin($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.DatasetImageOriginDocument,
  '\n  query iterationImageOrigin($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      name\n    }\n  }\n':
    types.IterationImageOriginDocument,
  '\n  query modelImageOrigin($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.ModelImageOriginDocument,
  '\n  mutation createUserMentionNotification(\n    $workspaceId: VecticeId!\n    $userId: Float!\n    $content: UserMentionNotificationContentInput!\n  ) {\n    createUserMentionNotification(workspaceId: $workspaceId, userId: $userId, content: $content) {\n      id\n    }\n  }\n':
    types.CreateUserMentionNotificationDocument,
  '\n  query getMentionedUser($userId: Float!) {\n    getOrganizationUser(userId: $userId) {\n      ...userFields\n    }\n  }\n':
    types.GetMentionedUserDocument,
  '\n  mutation generateText($data: GenerateDocumentationInput!) {\n    generateDocumentation(data: $data)\n  }\n':
    types.GenerateTextDocument,
  '\n  fragment userFields on User {\n    id\n    avatarId\n    name\n    email\n    color\n  }\n':
    types.UserFieldsFragmentDoc,
  '\n  fragment userPreferences on UserPreferencesOutput {\n    onboardingStatus\n  }\n':
    types.UserPreferencesFragmentDoc,
  '\n  fragment modelVersionInformationFields on ModelVersion {\n    vecticeId\n    versionNumber\n    model {\n      name\n    }\n  }\n':
    types.ModelVersionInformationFieldsFragmentDoc,
  '\n  fragment metricsFields on EntityMetric {\n    id\n    key\n    value\n  }\n': types.MetricsFieldsFragmentDoc,
  '\n  fragment propertiesFields on EntityProperty {\n    id\n    key\n    value\n  }\n':
    types.PropertiesFieldsFragmentDoc,
  '\n  fragment pageAfterCursorFields on PageOutput {\n    afterCursor\n    hasNextPage\n  }\n':
    types.PageAfterCursorFieldsFragmentDoc,
  '\n  fragment pageIndexFields on PageOutput {\n    index\n    size\n  }\n': types.PageIndexFieldsFragmentDoc,
  '\n  fragment entityFileFragment on EntityFile {\n    id\n    fileName\n    mimeType\n    hasPreview\n  }\n':
    types.EntityFileFragmentFragmentDoc,
  '\n  fragment attachmentFragment on AttachmentOutput {\n    id\n    attachmentType\n    name\n    entityId\n    type\n    content\n    mimeType\n    hasPreview\n  }\n':
    types.AttachmentFragmentFragmentDoc,
  '\n  mutation createProject($workspaceId: VecticeId!, $data: ProjectCreateInput!) {\n    createProject(workspaceId: $workspaceId, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.CreateProjectDocument,
  '\n  mutation updateLineage($versionId: VecticeId!, $data: LineageUpdateInput!, $type: LineageType!) {\n    updateLineage(data: $data, type: $type, id: $versionId)\n  }\n':
    types.UpdateLineageDocument,
  '\n  mutation updateUser($data: UserUpdateInput!) {\n    updateUser(data: $data) {\n      ...userFields\n      about\n    }\n  }\n':
    types.UpdateUserDocument,
  '\n  mutation updateUserPreferences($data: UserPreferencesInput!) {\n    updateUser(data: { preferences: $data }) {\n      preferences {\n        ...userPreferences\n        onboardingStatus\n      }\n    }\n  }\n':
    types.UpdateUserPreferencesDocument,
  '\n  mutation addUsersToWorkspace($data: WorkspaceUserInput!) {\n    addUsersToWorkspace(data: $data) {\n      userId\n    }\n  }\n':
    types.AddUsersToWorkspaceDocument,
  '\n  mutation addMyselfToWorkspace($workspaceId: VecticeId!) {\n    addMyselfToWorkspace(workspaceId: $workspaceId) {\n      userId\n    }\n  }\n':
    types.AddMyselfToWorkspaceDocument,
  '\n  mutation generateApiKey($apiKey: ApiKeyCreateInput!) {\n    generateApiKey(apiKey: $apiKey) {\n      key\n    }\n  }\n':
    types.GenerateApiKeyDocument,
  '\n  query checkIfApiKeyNameExists($name: String!) {\n    checkIfApiKeyNameExists(apiKeyName: $name)\n  }\n':
    types.CheckIfApiKeyNameExistsDocument,
  '\n  query getExportedArchivesList {\n    getExportedArchivesList {\n      name\n      path\n      itemCreatedDate\n    }\n  }\n':
    types.GetExportedArchivesListDocument,
  '\n  query authenticateWithPassword($email: String!, $password: String!) {\n    authenticateWithPassword(email: $email, password: $password) {\n      realm\n      clientId\n    }\n  }\n':
    types.AuthenticateWithPasswordDocument,
  '\n  mutation cancelIteration($id: VecticeId!) {\n    updateIteration(id: $id, data: { status: Abandoned }) {\n      vecticeId\n      status\n    }\n  }\n':
    types.CancelIterationDocument,
  '\n  mutation cancelReview($id: VecticeId!) {\n    cancelReview(id: $id) {\n      vecticeId\n      status\n    }\n  }\n':
    types.CancelReviewDocument,
  '\n  query CDTTemplates($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {\n    CDTTemplates(order: $order, page: $page, filters: $filters) {\n      items {\n        id\n        exportId\n        name\n        description\n        isMacro\n        isRichText\n        enabled\n        groupName\n        organizationId\n        createdBy {\n          ...userFields\n        }\n        updatedDate\n      }\n      page {\n        afterCursor\n      }\n      total\n    }\n  }\n':
    types.CdtTemplatesDocument,
  '\n  query CDTTemplatesVariables($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {\n    CDTTemplates(order: $order, page: $page, filters: $filters) {\n      items {\n        id\n        exportId\n        assets {\n          name\n          type\n        }\n      }\n      total\n    }\n  }\n':
    types.CdtTemplatesVariablesDocument,
  '\n  query CDTTemplateContent($id: Float, $globalId: String) {\n    CDTTemplate(id: $id, globalId: $globalId) {\n      id\n      exportId\n      name\n      enabled\n      description\n      groupName\n      content\n      isMacro\n      headerContent\n      footerContent\n      organizationId\n      assets {\n        id\n        name\n        type\n      }\n    }\n  }\n':
    types.CdtTemplateContentDocument,
  '\n  mutation createCDTReport($projectId: VecticeId!, $data: CDTReportCreateInput!) {\n    createCDTReport(projectId: $projectId, data: $data) {\n      id\n      name\n    }\n  }\n':
    types.CreateCdtReportDocument,
  '\n  mutation generateCDTMarkdown($data: CDTGenerateMarkdownInput!) {\n    generateCDTMarkdown(data: $data)\n  }\n':
    types.GenerateCdtMarkdownDocument,
  '\n  mutation previewMacro($assets: [CDTReportAssetInput!]!, $content: String!) {\n    previewMacro(assets: $assets, content: $content)\n  }\n':
    types.PreviewMacroDocument,
  '\n  mutation removeCDTTemplate($id: Float!) {\n    removeCDTTemplate(id: $id)\n  }\n':
    types.RemoveCdtTemplateDocument,
  '\n  mutation createCDTTemplate($data: CDTTemplateCreateInput!) {\n    createCDTTemplate(data: $data) {\n      id\n      name\n      enabled\n    }\n  }\n':
    types.CreateCdtTemplateDocument,
  '\n  mutation updateCDTTemplate($id: Float!, $data: CDTTemplateUpdateInput!) {\n    updateCDTTemplate(id: $id, data: $data) {\n      id\n      name\n      enabled\n      description\n    }\n  }\n':
    types.UpdateCdtTemplateDocument,
  '\n  query GlobalCDTTemplate($globalId: String) {\n    CDTTemplate(globalId: $globalId) {\n      id\n      exportId\n      name\n      content\n      headerContent\n      footerContent\n    }\n  }\n':
    types.GlobalCdtTemplateDocument,
  '\n  query checkIterationStepNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: Float) {\n    checkIterationStepNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n':
    types.CheckIterationStepNameAvailabilityDocument,
  '\n  query checkPhaseNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: VecticeId) {\n    checkPhaseNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n':
    types.CheckPhaseNameAvailabilityDocument,
  '\n  query checkStepDefinitionNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: Float) {\n    checkStepDefinitionNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n':
    types.CheckStepDefinitionNameAvailabilityDocument,
  '\n  mutation closeReview($review: ReviewUpdateInput!, $id: VecticeId!) {\n    closeReview(review: $review, id: $id) {\n      vecticeId\n      status\n      ...Review\n    }\n  }\n':
    types.CloseReviewDocument,
  '\n  query getCodeArtifactByJobRunId($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId) {\n      items {\n        id\n        version\n        lineageArtifactType\n        lineageId\n        artifactType\n        codeVersion {\n          id\n          code {\n            name\n            description\n          }\n          gitVersion {\n            branchName\n            commitHash\n            repositoryName\n            uri\n            entrypoint\n            isDirty\n            additionalInformation\n          }\n          databricksVersion {\n            urlNotebook\n            relativePath\n            timestamp\n            additionalInformation\n          }\n          otherCodeVersion {\n            id\n            url\n            firstAdditionalInformation\n            secondAdditionalInformation\n            thirdAdditionalInformation\n          }\n        }\n      }\n    }\n  }\n':
    types.GetCodeArtifactByJobRunIdDocument,
  '\n  mutation completeIteration($id: VecticeId!) {\n    updateIteration(id: $id, data: { status: Completed }) {\n      vecticeId\n      status\n    }\n  }\n':
    types.CompleteIterationDocument,
  '\n  mutation createFinding($projectId: VecticeId!, $data: FindingCreateInput!) {\n    createFinding(projectId: $projectId, data: $data) {\n      id\n      name\n    }\n  }\n':
    types.CreateFindingDocument,
  '\n  mutation createIterationStep($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {\n    createIterationStep(parentId: $parentId, createModel: $createModel) {\n      id\n      name\n      description\n      index\n    }\n  }\n':
    types.CreateIterationStepDocument,
  '\n  mutation createIterationStepArtifact($id: VecticeId!, $data: IterationStepArtifactInput!) {\n    createIterationStepArtifact(id: $id, data: $data) {\n      id\n      index\n      type\n      text\n      iterationId\n      stepId\n    }\n  }\n':
    types.CreateIterationStepArtifactDocument,
  '\n  mutation createReviewComment($reviewId: VecticeId!, $content: String!) {\n    createReviewComment(reviewComment: { reviewId: $reviewId, content: $content }) {\n      id\n      createdBy {\n        ...userFields\n      }\n      content\n      createdDate\n    }\n  }\n':
    types.CreateReviewCommentDocument,
  '\n  mutation createStepDefinition($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {\n    createStepDefinition(parentId: $parentId, createModel: $createModel) {\n      id\n      name\n      description\n      index\n    }\n  }\n':
    types.CreateStepDefinitionDocument,
  '\n  mutation deleteEntityFile($id: Float!) {\n    deleteEntityFile(id: $id)\n  }\n': types.DeleteEntityFileDocument,
  '\n  mutation deleteEntityMetadata($id: Float!) {\n    deleteEntityMetadata(id: $id)\n  }\n':
    types.DeleteEntityMetadataDocument,
  '\n  mutation extractEntityFile($id: Float!) {\n    extractEntityFile(id: $id) {\n      images\n      tables\n    }\n  }\n':
    types.ExtractEntityFileDocument,
  '\n  query getAssetAttachments($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $category: ModelVersionStatus) {\n    attachments(entityId: $entityId, entityType: $type, category: $category) {\n      id\n      type\n      name\n      hasPreview\n      mimeType\n      attachmentType\n      entityId\n      ...attachmentFragment\n    }\n  }\n':
    types.GetAssetAttachmentsDocument,
  '\n  query getDatasetVersion($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n':
    types.GetDatasetVersionDocument,
  '\n  query getEntityFileList($entityId: VecticeId!, $type: EntityFilesTypeEnum!) {\n    getEntityFileList(entityId: $entityId, type: $type, page: { size: 100, index: 1 }) {\n      items {\n        id\n        fileName\n        hasPreview\n        mimeType\n        ...entityFileFragment\n      }\n      total\n    }\n  }\n':
    types.GetEntityFileListDocument,
  '\n  query getIterationById($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      index\n      name\n      description\n      status\n      starred\n      phaseId\n      phase {\n        vecticeId\n        name\n        parent {\n          vecticeId\n        }\n      }\n      paginatedArtifacts(filters: { stepStrategy: EXCLUDES }, page: { index: 1, size: 100 }) {\n        total\n        items {\n          id\n          type\n          index\n          stepId\n          ...IterationStepArtifact\n        }\n      }\n      ...IterationSidebar\n    }\n  }\n':
    types.GetIterationByIdDocument,
  '\n  subscription subscribeToIterationCount($filters: IterationFiltersInput!) {\n    subscribeToIterationList(filters: $filters) {\n      total\n    }\n  }\n':
    types.SubscribeToIterationCountDocument,
  '\n  query getIterationList($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getIterationList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        starred\n        status\n        createdDate\n        updatedDate\n        phaseId\n        createdBy {\n          ...userFields\n        }\n        owner {\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n':
    types.GetIterationListDocument,
  '\n  query getIterationPickerList($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        name\n        index\n      }\n    }\n  }\n':
    types.GetIterationPickerListDocument,
  '\n  query getIterationStepList($parentId: VecticeId!, $order: ListOrderInput) {\n    sections(parentId: $parentId, order: $order, page: { index: 1, size: 100 }) {\n      items {\n        id\n        index\n        name\n        paginatedArtifacts(page: { index: 1, size: 100 }) {\n          total\n          items {\n            id\n            index\n            stepId\n            type\n            ...IterationStepArtifact\n          }\n        }\n        ...IterationStep\n      }\n    }\n  }\n':
    types.GetIterationStepListDocument,
  '\n  query getModelVersionName($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n':
    types.GetModelVersionNameDocument,
  '\n  query getPhaseById($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      ownerId\n      enforceRequirementsAsSections\n      activeReview {\n        status\n      }\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n          name\n          type\n        }\n      }\n      ...PhaseSidebar\n    }\n  }\n':
    types.GetPhaseByIdDocument,
  '\n  query getPhaseList($filters: PhaseFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getPhaseList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        description\n        status\n        iterationsCount {\n          total\n        }\n        reviewsCount {\n          totalReviews\n        }\n        parent {\n          vecticeId\n        }\n      }\n    }\n  }\n':
    types.GetPhaseListDocument,
  '\n  subscription subscribeToReview($id: VecticeId!) {\n    subscribeToReview(id: $id) {\n      vecticeId\n      ...Review\n    }\n  }\n':
    types.SubscribeToReviewDocument,
  '\n  query getReviewCommentList($filters: ReviewCommentFiltersInput!, $page: PageInput) {\n    getReviewCommentList(filters: $filters, order: { field: "createdDate", direction: ASC }, page: $page) {\n      items {\n        id\n        createdBy {\n          ...userFields\n        }\n        createdDate\n        content\n      }\n    }\n  }\n':
    types.GetReviewCommentListDocument,
  '\n  subscription subscribeToReviewCommentList($filters: ReviewCommentFiltersInput!) {\n    subscribeToReviewCommentList(filters: $filters) {\n      items {\n        id\n        createdBy {\n          ...userFields\n        }\n        createdDate\n        content\n      }\n    }\n  }\n':
    types.SubscribeToReviewCommentListDocument,
  '\n  query getReviewList($filters: ReviewFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getReviewList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        status\n        ...Review\n      }\n      total\n    }\n  }\n':
    types.GetReviewListDocument,
  '\n  query getStepDefinitionList($filters: BaseDocumentationListFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getStepDefinitionList(filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        index\n        name\n        slug\n        description\n        parent {\n          vecticeId\n        }\n      }\n      total\n    }\n  }\n':
    types.GetStepDefinitionListDocument,
  '\n  query getOrganizationUsersOfOrganization($filters: UserListFiltersInput) {\n    getOrganizationUsersForUser(filters: $filters) {\n      id\n      role\n      name\n      email\n      ...userFields\n    }\n  }\n':
    types.GetOrganizationUsersOfOrganizationDocument,
  '\n  query TemplateHeaderFooterContent($id: Float) {\n    CDTTemplate(id: $id) {\n      id\n      name\n      headerContent\n      footerContent\n    }\n  }\n':
    types.TemplateHeaderFooterContentDocument,
  '\n  query ReportHeaderFooterContent($id: Float!) {\n    CDTReport(id: $id) {\n      fileId\n      name\n      file {\n        headerContent\n        footerContent\n      }\n    }\n  }\n':
    types.ReportHeaderFooterContentDocument,
  '\n  query FindingHeaderFooterContent($id: Float!) {\n    Finding(id: $id) {\n      fileId\n      name\n      file {\n        headerContent\n        footerContent\n      }\n    }\n  }\n':
    types.FindingHeaderFooterContentDocument,
  '\n  mutation updateCDTHeaderFooterContent($id: Float!, $data: CDTTemplateUpdateInput!) {\n    updateCDTTemplate(id: $id, data: $data) {\n      id\n      content\n      headerContent\n      footerContent\n    }\n  }\n':
    types.UpdateCdtHeaderFooterContentDocument,
  '\n  mutation updateDocFileHeaderFooterContent($fileId: Float!, $data: FileHeaderFooterUpdateInput!) {\n    updateFileHeaderFooter(fileId: $fileId, data: $data) {\n      id\n      headerContent\n      footerContent\n    }\n  }\n':
    types.UpdateDocFileHeaderFooterContentDocument,
  '\n  query getIdentityProviderList {\n    getIdentityProviderList {\n      alias\n      ...authMethodCard\n    }\n  }\n':
    types.GetIdentityProviderListDocument,
  '\n  query getIdentityProvider($alias: String!) {\n    getIdentityProvider(alias: $alias) {\n      alias\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n':
    types.GetIdentityProviderDocument,
  '\n  mutation createIdentityProvider(\n    $identityProvider: KeycloakIdentityProviderCreate!\n    $socialConfig: KeycloakSocialConfigCreate\n    $samlConfig: KeycloakSAMLConfigCreate\n  ) {\n    createIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {\n      alias\n      providerId\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n':
    types.CreateIdentityProviderDocument,
  '\n  mutation updateIdentityProvider(\n    $identityProvider: KeycloakIdentityProviderUpdate!\n    $socialConfig: KeycloakSocialConfigUpdate\n    $samlConfig: KeycloakSAMLConfigUpdate\n  ) {\n    updateIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {\n      alias\n      providerId\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n':
    types.UpdateIdentityProviderDocument,
  '\n  query getJobArtifactByJobRunId($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId, order: { field: "createdDate", direction: ASC }) {\n      items {\n        id\n        version\n        lineageArtifactType\n        lineageId\n        artifactType\n        ...lineageAssetFragment\n        ...lineageCodeFragment\n      }\n    }\n  }\n':
    types.GetJobArtifactByJobRunIdDocument,
  '\n  query logout {\n    logout\n  }\n': types.LogoutDocument,
  '\n  mutation moveArtifacts(\n    $iterationId: VecticeId!\n    $artifactsIdList: [Float!]!\n    $targetStepId: Float\n    $targetStepIndex: Float\n  ) {\n    moveArtifacts(\n      iterationId: $iterationId\n      artifactsIdList: $artifactsIdList\n      targetStepId: $targetStepId\n      targetStepIndex: $targetStepIndex\n    ) {\n      id\n      stepId\n    }\n  }\n':
    types.MoveArtifactsDocument,
  '\n  query checkWorkspaceNameAvailability($name: String!, $exceptId: VecticeId) {\n    checkWorkspaceNameAvailability(name: $name, exceptId: $exceptId)\n  }\n':
    types.CheckWorkspaceNameAvailabilityDocument,
  '\n  query checkProjectNameAvailability($workspaceId: VecticeId!, $projectId: VecticeId, $name: String!) {\n    checkProjectNameAvailability(workspaceId: $workspaceId, projectId: $projectId, name: $name)\n  }\n':
    types.CheckProjectNameAvailabilityDocument,
  '\n  query checkCDTReportNameAvailability($parentId: VecticeId!, $resourceId: Float, $name: String!) {\n    checkCDTReportNameAvailability(parentId: $parentId, resourceId: $resourceId, name: $name)\n  }\n':
    types.CheckCdtReportNameAvailabilityDocument,
  '\n  query checkCDTTemplateNameAvailability($resourceId: Float, $name: String!, $isRichText: Boolean) {\n    checkCDTTemplateNameAvailability(resourceId: $resourceId, name: $name, isRichText: $isRichText)\n  }\n':
    types.CheckCdtTemplateNameAvailabilityDocument,
  '\n  query getAllOrganizations($order: ListOrderInput, $search: String, $levels: [OrganizationLevel!], $page: PageInput) {\n    getAllOrganizations(filters: { search: $search, levels: $levels }, order: $order, page: $page) {\n      items {\n        id\n        name\n        level\n        description\n        createdDate\n        emailDomains {\n          domain\n        }\n      }\n      total\n    }\n  }\n':
    types.GetAllOrganizationsDocument,
  '\n  query getAccessibilityLevels {\n    getAccessibilityLevels\n  }\n': types.GetAccessibilityLevelsDocument,
  '\n  mutation setUserRole($role: UserRole!, $userId: Float!) {\n    setUserRole(role: $role, userId: $userId) {\n      id\n      role\n    }\n  }\n':
    types.SetUserRoleDocument,
  '\n  mutation disableUser($userId: Float!) {\n    disableUser(userId: $userId) {\n      id\n      disabled\n    }\n  }\n':
    types.DisableUserDocument,
  '\n  mutation enableUser($userId: Float!) {\n    enableUser(userId: $userId) {\n      id\n      disabled\n    }\n  }\n':
    types.EnableUserDocument,
  '\n  mutation createOrganization($data: OrganizationCreateInput!) {\n    createOrganization(data: $data) {\n      id\n      name\n      description\n    }\n  }\n':
    types.CreateOrganizationDocument,
  '\n  mutation updateOrganizationDescription($organizationId: ID!, $data: OrganizationUpdateInput!) {\n    updateOrganization(data: $data, organizationId: $organizationId) {\n      id\n      description\n      level\n    }\n  }\n':
    types.UpdateOrganizationDescriptionDocument,
  '\n  mutation deleteOrganization($organizationId: Float!) {\n    deleteOrganization(organizationId: $organizationId)\n  }\n':
    types.DeleteOrganizationDocument,
  '\n  query checkOrganizationEmailDomainAvailability($userEmail: String!) {\n    checkOrganizationEmailDomainAvailability(userEmail: $userEmail)\n  }\n':
    types.CheckOrganizationEmailDomainAvailabilityDocument,
  '\n  query getRealmInformationFromEmail($email: String!) {\n    getRealmInformationFromEmail(email: $email) {\n      realm\n      clientId\n    }\n  }\n':
    types.GetRealmInformationFromEmailDocument,
  '\n  mutation inviteTrialUser($email: String!) {\n    inviteTrialUser(email: $email)\n  }\n':
    types.InviteTrialUserDocument,
  '\n  mutation removeIterationStep($id: Float!) {\n    removeIterationStep(id: $id)\n  }\n':
    types.RemoveIterationStepDocument,
  '\n  mutation removeStepDefinition($id: Float!) {\n    removeStepDefinition(id: $id)\n  }\n':
    types.RemoveStepDefinitionDocument,
  '\n  mutation renameEntityFile($id: Float!, $newName: String!) {\n    renameEntityFile(id: $id, newName: $newName) {\n      id\n      fileName\n    }\n  }\n':
    types.RenameEntityFileDocument,
  '\n  mutation renameEntityMetadata($id: Float!, $name: String!) {\n    renameEntityMetadata(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n':
    types.RenameEntityMetadataDocument,
  '\n  mutation resetOrganizationFeatureFlag($organizationId: Float!, $code: String!) {\n    resetOrganizationFeatureFlag(organizationId: $organizationId, code: $code) {\n      code\n      enabled\n      organizationId\n      overridden\n    }\n  }\n':
    types.ResetOrganizationFeatureFlagDocument,
  '\n  mutation restorePhase($id: VecticeId!) {\n    restorePhase(id: $id)\n  }\n': types.RestorePhaseDocument,
  '\n  mutation updateDatasetType($id: VecticeId!, $type: DatasetType!) {\n    updateDataset(datasetId: $id, dataset: { type: $type }) {\n      vecticeId\n      type\n    }\n  }\n':
    types.UpdateDatasetTypeDocument,
  '\n  mutation updateFinding($id: Float!, $data: FindingUpdateInput!) {\n    updateFinding(id: $id, data: $data) {\n      id\n      name\n    }\n  }\n':
    types.UpdateFindingDocument,
  '\n  mutation updateFindingOwner($id: Float!, $ownerId: Float!) {\n    updateFinding(id: $id, data: { ownerId: $ownerId }) {\n      id\n      ownerId\n      owner {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n':
    types.UpdateFindingOwnerDocument,
  '\n  mutation updateFindingAssignee($id: Float!, $assigneeId: Float) {\n    updateFinding(id: $id, data: { reviewerId: $assigneeId }) {\n      id\n      ownerId\n      reviewer {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n':
    types.UpdateFindingAssigneeDocument,
  '\n  mutation updateFindingStatus($id: Float!, $status: FindingStatus!) {\n    updateFinding(id: $id, data: { status: $status }) {\n      id\n      status\n    }\n  }\n':
    types.UpdateFindingStatusDocument,
  '\n  mutation updateFindingSeverity($id: Float!, $severity: FindingSeverity!) {\n    updateFinding(id: $id, data: { severity: $severity }) {\n      id\n      severity\n    }\n  }\n':
    types.UpdateFindingSeverityDocument,
  '\n  mutation updateFindingModelVersion($id: Float!, $modelVersionId: VecticeId) {\n    updateFinding(id: $id, data: { modelVersionId: $modelVersionId }) {\n      id\n      modelVersion {\n        vecticeId\n        ...modelVersionInformationFields\n      }\n    }\n  }\n':
    types.UpdateFindingModelVersionDocument,
  '\n  mutation updateFindingReport($id: Float!, $reportId: Float) {\n    updateFinding(id: $id, data: { reportId: $reportId }) {\n      id\n      report {\n        id\n        name\n      }\n    }\n  }\n':
    types.UpdateFindingReportDocument,
  '\n  mutation updateFindingDueDate($id: Float!, $date: DateTime) {\n    updateFinding(id: $id, data: { dueDate: $date }) {\n      id\n      dueDate\n    }\n  }\n':
    types.UpdateFindingDueDateDocument,
  '\n  mutation updateSection($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateIterationStep(id: $id, updateModel: $updateModel) {\n      id\n      description\n      index\n      name\n    }\n  }\n':
    types.UpdateSectionDocument,
  '\n  mutation updateIterationStarredStatus($id: VecticeId!, $starred: Boolean!) {\n    updateIteration(id: $id, data: { starred: $starred }) {\n      vecticeId\n      starred\n    }\n  }\n':
    types.UpdateIterationStarredStatusDocument,
  '\n  mutation updateIterationStepArtifact($id: Float!, $data: UpdateIterationStepArtifactInput!) {\n    updateIterationStepArtifact(id: $id, data: $data) {\n      id\n      text\n    }\n  }\n':
    types.UpdateIterationStepArtifactDocument,
  '\n  mutation updateModelType($id: VecticeId!, $type: ModelType!) {\n    updateModel(modelId: $id, model: { type: $type }) {\n      vecticeId\n      type\n    }\n  }\n':
    types.UpdateModelTypeDocument,
  '\n  mutation updatePhase($id: VecticeId!, $updateModel: PhaseUpdateInput!) {\n    updatePhase(id: $id, updateModel: $updateModel) {\n      vecticeId\n      index\n      name\n      description\n      status\n      ownerId\n      enforceRequirementsAsSections\n      owner {\n        ...userFields\n      }\n    }\n  }\n':
    types.UpdatePhaseDocument,
  '\n  mutation updateStepDefinition($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateStepDefinition(id: $id, updateModel: $updateModel) {\n      id\n      name\n      slug\n      description\n    }\n  }\n':
    types.UpdateStepDefinitionDocument,
  '\n  mutation updateStepPosition($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateStepDefinition(id: $id, updateModel: $updateModel) {\n      id\n      index\n    }\n  }\n':
    types.UpdateStepPositionDocument,
  '\n  mutation changeUserPassword($userId: Float, $newPassword: String!) {\n    changeUserPassword(userId: $userId, newPassword: $newPassword)\n  }\n':
    types.ChangeUserPasswordDocument,
  '\n  query getUserActivityList(\n    $workspaceIdList: [VecticeId!]\n    $projectIds: [VecticeId!]\n    $contributors: [ID!]\n    $targetFilter: ActivityTargetFilter\n    $activityTargetTypes: [ActivityTargetType!]\n    $activityTypes: [ActivityType!]\n    $afterCursor: String\n    $pageSize: Int!\n    $onlyMine: Boolean\n  ) {\n    getUserActivityList(\n      workspaceIdList: $workspaceIdList\n      filters: {\n        projectIds: $projectIds\n        contributors: $contributors\n        activityTypes: $activityTypes\n        activityTargetTypes: $activityTargetTypes\n        targetFilter: $targetFilter\n        onlyMine: $onlyMine\n      }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      ...userActivities\n      page {\n        hasNextPage\n        afterCursor\n      }\n      total\n    }\n  }\n':
    types.GetUserActivityListDocument,
  '\n  mutation createUserInvite($data: UserInviteInput!) {\n    createUserInvite(data: $data) {\n      email\n      status\n      invite {\n        inviteLinkToken\n        email\n      }\n    }\n  }\n':
    types.CreateUserInviteDocument,
  '\n  mutation cancelUserInvite($id: Float!) {\n    cancelUserInvite(id: $id)\n  }\n': types.CancelUserInviteDocument,
  '\n  mutation resendUserInvite($id: Float!) {\n    resendUserInvite(id: $id)\n  }\n': types.ResendUserInviteDocument,
  '\n  mutation createUserViewActivity($userViewActivity: UserViewActivityCreateInput!) {\n    createUserViewActivity(userViewActivity: $userViewActivity) {\n      id\n    }\n  }\n':
    types.CreateUserViewActivityDocument,
  '\n  query getUserWorkspaceAccessibility($workspaceId: VecticeId!, $userId: Float!) {\n    accessLevel: getUserWorkspaceAccessibility(workspaceId: $workspaceId, userId: $userId)\n  }\n':
    types.GetUserWorkspaceAccessibilityDocument,
  '\n  query whoAmI {\n    whoAmI {\n      user {\n        id\n        avatarId\n        ...userFields\n        name\n        email\n        emailVerificationStatus\n        about\n        organizationId\n        role\n        preferences {\n          ...userPreferences\n          onboardingStatus\n        }\n      }\n      defaultWorkspace {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n':
    types.WhoAmIDocument,
  '\n  query getUserWorkspaceListToInvite($search: String!) {\n    getUserWorkspaceListToInvite: getUserWorkspaceList(\n      filters: { searchFilter: { search: $search, fields: ["name"] }, type: [PRIVATE, SAMPLE, TUTORIAL] }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.GetUserWorkspaceListToInviteDocument,
  '\n  query getAllUserWorkspaces {\n    getUserWorkspaceList {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.GetAllUserWorkspacesDocument,
  '\n  query getWorkspaceMemberList($workspaceId: VecticeId!, $order: ListOrderInput, $filters: UserListFiltersInput) {\n    getWorkspaceMemberList(workspaceId: $workspaceId, order: $order, filters: $filters) {\n      items {\n        id\n        role\n        name\n        email\n        ...userFields\n      }\n    }\n  }\n':
    types.GetWorkspaceMemberListDocument,
  '\n  query getWorkspaceTargetList($filters: WorkspaceListFiltersInput!) {\n    getUserWorkspaceList(page: { size: 10 }, filters: $filters, order: { field: "name", direction: ASC }) {\n      items {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n':
    types.GetWorkspaceTargetListDocument,
  '\n  query getDatasetContext($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n':
    types.GetDatasetContextDocument,
  '\n  query getDatasetVersionContext($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      dataSet {\n        vecticeId\n        project {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetDatasetVersionContextDocument,
  '\n  query getIterationContext($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      phase {\n        vecticeId\n        name\n        index\n        parent {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetIterationContextDocument,
  '\n  query getModelContext($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      vecticeId\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n':
    types.GetModelContextDocument,
  '\n  query getModelVersionContext($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      model {\n        vecticeId\n        project {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetModelVersionContextDocument,
  '\n  query getPhaseContext($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      index\n      parent {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPhaseContextDocument,
  '\n  query getProjectContext($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      workspace {\n        vecticeId\n        name\n        type\n        userContext {\n          accessibility\n        }\n      }\n    }\n  }\n':
    types.GetProjectContextDocument,
  '\n  query getReportContext($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      id\n      name\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n':
    types.GetReportContextDocument,
  '\n  query getFindingContext($findingId: Float!) {\n    Finding(id: $findingId) {\n      id\n      name\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n':
    types.GetFindingContextDocument,
  '\n  query getWorkspaceContext($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      type\n      userContext {\n        accessibility\n      }\n    }\n  }\n':
    types.GetWorkspaceContextDocument,
  '\n  query getUserProjects($workspaceId: VecticeId, $filters: ProjectListFiltersInput) {\n    getProjectList(workspaceId: $workspaceId, filters: $filters, order: { field: "createdDate", direction: ASC }) {\n      items {\n        vecticeId\n        templateUsed\n      }\n    }\n  }\n':
    types.GetUserProjectsDocument,
  '\n  mutation createWorkspace($data: WorkspaceCreateInput!) {\n    createWorkspace(data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.CreateWorkspaceDocument,
  '\n  query CDTReportInfo($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      modelVersion {\n        id\n        vecticeId\n        inventoryReference\n      }\n    }\n  }\n':
    types.CdtReportInfoDocument,
  '\n  query getModelVersionInfo($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      id\n      vecticeId\n      inventoryReference\n    }\n  }\n':
    types.GetModelVersionInfoDocument,
  '\n  mutation deleteApiKey($apiKeyId: Float!) {\n    deleteApiKey(apiKeyId: $apiKeyId)\n  }\n':
    types.DeleteApiKeyDocument,
  '\n  query getApiKeyList($order: ListOrderInput, $page: PageInput = { size: 30, index: 1 }, $userId: Float!) {\n    getApiKeyList(order: $order, page: $page, userId: $userId) {\n      items {\n        id\n        name\n        createdDate\n        status\n      }\n      total\n      page {\n        ...pageIndexFields\n      }\n    }\n  }\n':
    types.GetApiKeyListDocument,
  '\n  mutation updateApiKey($apiKey: ApiKeyUpdateInput!, $apiKeyId: Float!) {\n    updateApiKey(apiKey: $apiKey, apiKeyId: $apiKeyId) {\n      id\n      status\n      name\n    }\n  }\n':
    types.UpdateApiKeyDocument,
  '\n  mutation changeOldPassword($newPassword: String!, $oldPassword: String) {\n    changeUserPassword(newPassword: $newPassword, oldPassword: $oldPassword)\n  }\n':
    types.ChangeOldPasswordDocument,
  '\n  mutation sendEmailVerification {\n    sendEmailVerification\n  }\n': types.SendEmailVerificationDocument,
  '\n  mutation updateFeatureFlagAvailability($code: String!, $enabled: Boolean!) {\n    updateFeatureFlagAvailability(code: $code, enabled: $enabled) {\n      code\n      enabled\n    }\n  }\n':
    types.UpdateFeatureFlagAvailabilityDocument,
  '\n  mutation updateOrgFeatureFlagAvailability($organizationId: Float!, $code: String!, $enabled: Boolean!) {\n    updateOrganizationFeatureFlagAvailability(organizationId: $organizationId, code: $code, enabled: $enabled) {\n      code\n      enabled\n      organizationId\n      overridden\n    }\n  }\n':
    types.UpdateOrgFeatureFlagAvailabilityDocument,
  '\n  query getEpicFeatureFlags {\n    getEpicFeatureFlags {\n      code\n      description\n      maturity\n      enabled\n      ticket\n      date\n      overrides\n      stories {\n        code\n        description\n        status\n        enabled\n        ticket\n        date\n        overrides\n      }\n    }\n  }\n':
    types.GetEpicFeatureFlagsDocument,
  '\n  query getOrganizationFeatureFlags($organizationId: Float!) {\n    getOrganizationFeatureFlags(organizationId: $organizationId) {\n      organizationId\n      code\n      enabled\n      overridden\n    }\n  }\n':
    types.GetOrganizationFeatureFlagsDocument,
  '\n  query getFeatureFlagOrgOverrides($code: String!) {\n    getFeatureFlagOrgOverrides(code: $code) {\n      enabled\n      organization {\n        id\n        name\n      }\n    }\n  }\n':
    types.GetFeatureFlagOrgOverridesDocument,
  '\n  query getOrganization($id: Float!) {\n    getOrganization(id: $id) {\n      id\n      name\n      ...OrganizationSettings\n    }\n  }\n':
    types.GetOrganizationDocument,
  '\n  fragment OrganizationSettings on Organization {\n    id\n    ...OrganizationDescriptionAndLevel\n    ...organizationDomains\n    ...organizationConfiguration\n    ...organizationLicenseOptions\n  }\n':
    types.OrganizationSettingsFragmentDoc,
  '\n  fragment organizationConfiguration on Organization {\n    id\n    name\n    emailsWhitelisted\n  }\n':
    types.OrganizationConfigurationFragmentDoc,
  '\n  mutation updateEmailAvailabilityForOrganization($organizationId: Float!, $emailsEnabled: Boolean!) {\n    updateEmailAvailabilityForOrganization(organizationId: $organizationId, emailsEnabled: $emailsEnabled)\n  }\n':
    types.UpdateEmailAvailabilityForOrganizationDocument,
  '\n  fragment OrganizationDescriptionAndLevel on Organization {\n    id\n    name\n    description\n    level\n  }\n':
    types.OrganizationDescriptionAndLevelFragmentDoc,
  '\n  fragment organizationDomains on Organization {\n    id\n    emailDomains {\n      domain\n    }\n  }\n':
    types.OrganizationDomainsFragmentDoc,
  '\n  mutation addEmailDomainToOrganization($organizationId: Float!, $emailDomain: String!) {\n    addEmailDomainToOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {\n      id\n      emailDomains {\n        domain\n      }\n    }\n  }\n':
    types.AddEmailDomainToOrganizationDocument,
  '\n  fragment organizationLicenseOptions on Organization {\n    id\n    name\n    licenseOptions {\n      isSingleSignOnEnabled\n      isAskAIEnabled\n    }\n  }\n':
    types.OrganizationLicenseOptionsFragmentDoc,
  '\n  mutation setLicenseOptions($organizationId: Float!, $licenseOptions: OrganizationLicenseOptionsInput!) {\n    setLicenseOptions(organizationId: $organizationId, licenseOptions: $licenseOptions) {\n      isSingleSignOnEnabled\n      isAskAIEnabled\n    }\n  }\n':
    types.SetLicenseOptionsDocument,
  '\n  query getOrganizationUserAndInvitesList(\n    $filters: UserAndInvitesFiltersInput\n    $order: ListOrderUserAndInvitesInput\n    $organizationId: Float\n    $page: PageIndexInput\n  ) {\n    getUserAndInvitesList(filters: $filters, order: $order, organizationId: $organizationId, page: $page) {\n      items {\n        status\n        type\n        user {\n          ...userFields\n          disabled\n          role\n          createdDate\n        }\n        userInvite {\n          id\n          email\n          inviteLinkToken\n          createdDate\n          role\n        }\n      }\n      total\n    }\n  }\n':
    types.GetOrganizationUserAndInvitesListDocument,
  '\n  query exportMacros($organizationId: Float, $onlyIds: [Float!], $email: String) {\n    exportMacros(organizationId: $organizationId, onlyIds: $onlyIds, email: $email)\n  }\n':
    types.ExportMacrosDocument,
  '\n  query exportProject($projectId: VecticeId!, $organizationId: Float, $email: String) {\n    exportProject(id: $projectId, organizationId: $organizationId, email: $email)\n  }\n':
    types.ExportProjectDocument,
  '\n  mutation updateBigQueryUserViewActivity {\n    updateBigQueryUserViewActivity\n  }\n':
    types.UpdateBigQueryUserViewActivityDocument,
  '\n  query exportWorkspace($workspaceId: VecticeId!, $organizationId: Float, $email: String) {\n    exportWorkspace(id: $workspaceId, organizationId: $organizationId, email: $email)\n  }\n':
    types.ExportWorkspaceDocument,
  '\n  mutation createSuperAdminUser($email: String!, $password: String!) {\n    createSuperAdminUser(data: { email: $email, password: $password }) {\n      id\n      name\n      ...userFields\n    }\n  }\n':
    types.CreateSuperAdminUserDocument,
  '\n  query listSuperAdminUsers {\n    listSuperAdminUsers {\n      id\n      name\n      createdDate\n      disabled\n      ...userFields\n    }\n  }\n':
    types.ListSuperAdminUsersDocument,
  '\n  query authenticateWithUniqueCode($clientId: String!, $realm: String!, $code: String!, $redirectUri: String) {\n    authenticateWithUniqueCode(clientId: $clientId, realm: $realm, code: $code, redirectUri: $redirectUri) {\n      realm\n      clientId\n    }\n  }\n':
    types.AuthenticateWithUniqueCodeDocument,
  '\n  query getStepForRedirection($stepId: Float!) {\n    getStepById(id: $stepId) {\n      id\n      parent {\n        vecticeId\n      }\n    }\n  }\n':
    types.GetStepForRedirectionDocument,
  '\n  query CDTReport($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n      project {\n        vecticeId\n      }\n      createdBy {\n        ...userFields\n      }\n      modelVersion {\n        ...modelVersionInformationFields\n      }\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.CdtReportDocument,
  '\n  query getDatasetVersionDetails($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      name\n      isStarred\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n      ...DatasetVersionSidebar\n    }\n  }\n':
    types.GetDatasetVersionDetailsDocument,
  '\n  query getDatasetDetails($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      ...DatasetSidebar\n      vecticeId\n      name\n      project {\n        vecticeId\n      }\n    }\n  }\n':
    types.GetDatasetDetailsDocument,
  '\n  fragment DatasetSidebar on DataSet {\n    vecticeId\n    description\n    type\n    sourceOrigin\n    project {\n      vecticeId\n      workspace {\n        vecticeId\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n':
    types.DatasetSidebarFragmentDoc,
  '\n  query getWorkspaceName($workspaceIds: [VecticeId!]) {\n    getOrganizationWorkspaceList(filters: { workspaceIds: $workspaceIds }) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.GetWorkspaceNameDocument,
  '\n  mutation requestWorkspaceAccess($workspaceId: VecticeId!) {\n    requestWorkspaceAccess(workspaceId: $workspaceId) {\n      id\n    }\n  }\n':
    types.RequestWorkspaceAccessDocument,
  '\n  query getFinding($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      fileId\n      file {\n        id\n        content\n      }\n      project {\n        name\n      }\n    }\n  }\n':
    types.GetFindingDocument,
  '\n  query getFindingDetails($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      severity\n      status\n      dueDate\n      createdDate\n      updatedDate\n      report {\n        id\n        name\n      }\n      modelVersion {\n        vecticeId\n        inventoryReference\n        ...modelVersionInformationFields\n      }\n      project {\n        vecticeId\n      }\n      owner {\n        id\n        ...userFields\n      }\n      reviewer {\n        id\n        ...userFields\n      }\n      createdBy {\n        id\n        ...userFields\n      }\n      lastUpdatedBy {\n        id\n        ...userFields\n      }\n    }\n  }\n':
    types.GetFindingDetailsDocument,
  '\n  mutation createPasswordRecoveryRequest($email: String!) {\n    createPasswordRecoveryRequest(email: $email)\n  }\n':
    types.CreatePasswordRecoveryRequestDocument,
  '\n  mutation removeAssetsFromIteration($iterationId: VecticeId!, $artifactsIdList: [Float!]!) {\n    removeAssetsFromIteration(iterationId: $iterationId, artifactsIdList: $artifactsIdList)\n  }\n':
    types.RemoveAssetsFromIterationDocument,
  '\n  query getModelVersionDetails($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      name\n      isStarred\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n      ...ModelVersionSidebar\n    }\n  }\n':
    types.GetModelVersionDetailsDocument,
  '\n  query getModelDetails($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      ...ModelSidebar\n      vecticeId\n      name\n      project {\n        vecticeId\n      }\n    }\n  }\n':
    types.GetModelDetailsDocument,
  '\n  fragment ModelSidebar on Model {\n    vecticeId\n    name\n    description\n    type\n    versionStats {\n      discardedCount\n      experimentationCount\n      productionCount\n      retiredCount\n      stagingCount\n      validationCount\n    }\n    project {\n      vecticeId\n      workspace {\n        vecticeId\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n':
    types.ModelSidebarFragmentDoc,
  '\n  mutation changeOrgDisplayName($displayName: String!) {\n    changeOrgDisplayName(displayName: $displayName)\n  }\n':
    types.ChangeOrgDisplayNameDocument,
  '\n  mutation setOrganizationSettings($configuration: OrganizationConfigInput!) {\n    setOrganizationSettings(configuration: $configuration) {\n      passwordAuthenticationEnabled\n      autoProvisioning\n      emailNotificationEnabled\n      dfStatisticsRowThreshold\n      dfStatisticsSampleRows\n      dfStatisticsMaxColumns\n      everyOneCanInviteUser\n    }\n  }\n':
    types.SetOrganizationSettingsDocument,
  '\n  query getUserAndInvitesList(\n    $filters: UserAndInvitesFiltersInput\n    $order: ListOrderUserAndInvitesInput\n    $page: PageIndexInput\n  ) {\n    getUserAndInvitesList(filters: $filters, order: $order, page: $page) {\n      items {\n        status\n        type\n        user {\n          ...userFields\n          id\n          createdDate\n          disabled\n          role\n        }\n        userInvite {\n          id\n          email\n          role\n          inviteLinkToken\n          updatedDate\n        }\n      }\n      total\n    }\n  }\n':
    types.GetUserAndInvitesListDocument,
  '\n  query checkRecoverPasswordRequest($uuid: String!) {\n    checkRecoverPasswordRequest(uuid: $uuid)\n  }\n':
    types.CheckRecoverPasswordRequestDocument,
  '\n  mutation recoverPasswordAfterRequest($newPassword: String!, $uuid: String!) {\n    recoverPasswordAfterRequest(newPassword: $newPassword, uuid: $uuid) {\n      id\n      email\n    }\n  }\n':
    types.RecoverPasswordAfterRequestDocument,
  '\n  query getStarredIterationList($phaseId: VecticeId!) {\n    getIterationList(\n      filters: { phaseId: $phaseId, starred: true }\n      order: { field: "index", direction: DESC }\n      page: { size: 30, index: 1 }\n    ) {\n      items {\n        vecticeId\n        ...iterationListItem\n      }\n    }\n  }\n':
    types.GetStarredIterationListDocument,
  '\n  query getPhaseSummary($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      description\n      parent {\n        vecticeId\n      }\n      ...PhaseSidebar\n    }\n  }\n':
    types.GetPhaseSummaryDocument,
  '\n  mutation requestReview($review: ReviewCreateInput!) {\n    requestReview(review: $review) {\n      vecticeId\n      status\n      ...Review\n    }\n  }\n':
    types.RequestReviewDocument,
  '\n  fragment Review on Review {\n    vecticeId\n    createdDate\n    updatedDate\n    message\n    status\n    feedback\n    createdById\n    createdBy {\n      ...userFields\n    }\n    reviewerId\n    reviewer {\n      ...userFields\n    }\n  }\n':
    types.ReviewFragmentDoc,
  '\n  query getActiveReview($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      status\n      activeReview {\n        vecticeId\n        status\n      }\n    }\n  }\n':
    types.GetActiveReviewDocument,
  '\n  query getDatasetList(\n    $projectId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $dsTypes: [DatasetType!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getDatasetList(\n      projectId: $projectId\n      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        ...datasetListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.GetDatasetListDocument,
  '\n  query Findings($filters: FindingFiltersInput!, $order: ListOrderInput, $page: PageInput, $type: FindingQueryType) {\n    Findings(filters: $filters, order: $order, page: $page, type: $type) {\n      items {\n        id\n        name\n        status\n        severity\n        createdDate\n        updatedDate\n        closedDate\n        dueDate\n        reviewer {\n          ...userFields\n        }\n        owner {\n          ...userFields\n        }\n        modelVersion {\n          inventoryReference\n          ...modelVersionInformationFields\n        }\n        report {\n          id\n          name\n        }\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.FindingsDocument,
  '\n  query getModelList(\n    $projectId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $types: [ModelType!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $algorithmNames: [String!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    getModelList(\n      projectId: $projectId\n      filters: {\n        search: $search\n        contributors: $contributors\n        types: $types\n        modelVersionStatus: $modelVersionStatus\n        algorithmNames: $algorithmNames\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        ...modelListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.GetModelListDocument,
  '\n  query getPhaseListForProjectOverview($parentId: VecticeId!, $order: ListOrderInput, $page: PageInput) {\n    getPhaseList(filters: { parentId: $parentId }, order: $order, page: $page) {\n      items {\n        vecticeId\n        ...phaseListItem\n      }\n    }\n  }\n':
    types.GetPhaseListForProjectOverviewDocument,
  '\n  query getProjectOverview($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      templateUsed\n      description\n      phasesCounts {\n        done\n        ongoing\n        toDo\n        total\n      }\n      ...ProjectSidebar\n    }\n  }\n':
    types.GetProjectOverviewDocument,
  '\n  fragment ProjectSidebar on Project {\n    vecticeId\n    status\n    createdDate\n    phasesCounts {\n      total\n    }\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    workspace {\n      vecticeId\n    }\n  }\n':
    types.ProjectSidebarFragmentDoc,
  '\n  query getLastPhase($projectId: VecticeId!) {\n    getPhaseList(\n      filters: { parentId: $projectId, status: [Draft, InReview] }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: 1 }\n    ) {\n      items {\n        vecticeId\n      }\n    }\n  }\n':
    types.GetLastPhaseDocument,
  '\n  query CDTReports(\n    $type: CDTReportQueryType\n    $filters: CDTReportFiltersInput!\n    $order: ListOrderInput\n    $page: PageInput\n  ) {\n    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n        createdDate\n        updatedDate\n        createdBy {\n          ...userFields\n        }\n        modelVersion {\n          ...modelVersionInformationFields\n          inventoryReference\n        }\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.CdtReportsDocument,
  '\n  query getProjectSettings($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      description\n      workspace {\n        vecticeId\n        name\n        type\n      }\n      ownerId\n    }\n  }\n':
    types.GetProjectSettingsDocument,
  '\n  mutation updateProject($projectId: VecticeId!, $data: ProjectUpdateInput!) {\n    updateProject(projectId: $projectId, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.UpdateProjectDocument,
  '\n  mutation createUserFromInvite($data: UserCreateInput!) {\n    createUserFromInvite(data: $data) {\n      name\n      email\n    }\n  }\n':
    types.CreateUserFromInviteDocument,
  '\n  query getUserInviteWithAuthInfo($inviteToken: String!) {\n    getUserInviteWithAuthInfo(inviteToken: $inviteToken) {\n      passwordAuthenticationEnabled\n      userInvite {\n        email\n        isTrial\n        invitingUser {\n          ...userFields\n        }\n      }\n    }\n  }\n':
    types.GetUserInviteWithAuthInfoDocument,
  '\n  mutation checkEmailVerificationToken($emailVerificationToken: String!) {\n    checkEmailVerificationToken(emailVerificationToken: $emailVerificationToken)\n  }\n':
    types.CheckEmailVerificationTokenDocument,
  '\n  query getWorkspaceUserListForInvite($order: ListOrderInput, $page: PageInput, $workspaceId: VecticeId!) {\n    getWorkspaceUserList(\n      order: $order\n      page: $page\n      workspaceId: $workspaceId\n      filters: { includeDisabledUsers: false }\n    ) {\n      items {\n        user {\n          id\n        }\n      }\n    }\n  }\n':
    types.GetWorkspaceUserListForInviteDocument,
  '\n  query getWorkspaceUserList(\n    $order: ListOrderInput\n    $page: PageInput\n    $workspaceId: VecticeId!\n    $filters: UserListFiltersInput\n  ) {\n    getWorkspaceUserList(order: $order, page: $page, workspaceId: $workspaceId, filters: $filters) {\n      items {\n        userId\n        accessibility\n        createdDate\n        workspace {\n          vecticeId\n          name\n        }\n        user {\n          createdDate\n          role\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n':
    types.GetWorkspaceUserListDocument,
  '\n  mutation removeUserFromWorkspace($workspaceId: VecticeId!, $userId: Float!) {\n    removeUserFromWorkspace(workspaceId: $workspaceId, userId: $userId)\n  }\n':
    types.RemoveUserFromWorkspaceDocument,
  '\n  mutation setUserWorkspaceAccessibility(\n    $accessibility: AccessibilityLevel!\n    $userId: Float!\n    $workspaceId: VecticeId!\n  ) {\n    setUserWorkspaceAccessibility(accessibility: $accessibility, userId: $userId, workspaceId: $workspaceId) {\n      userId\n      workspace {\n        vecticeId\n      }\n      accessibility\n    }\n  }\n':
    types.SetUserWorkspaceAccessibilityDocument,
  '\n  query getProjectList(\n    $workspaceId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n    $order: ListOrderInput\n  ) {\n    getProjectList(\n      workspaceId: $workspaceId\n      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n      order: $order\n    ) {\n      items {\n        vecticeId\n        ...projectListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n':
    types.GetProjectListDocument,
  '\n  query getProjectSelectorList($workspaceId: VecticeId!, $filters: ProjectListFiltersInput) {\n    getProjectList(\n      workspaceId: $workspaceId\n      page: { size: 10, index: 1 }\n      filters: $filters\n      order: { field: "name", direction: ASC }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n':
    types.GetProjectSelectorListDocument,
  '\n  query getWorkspaceSettings($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      description\n      type\n    }\n  }\n':
    types.GetWorkspaceSettingsDocument,
  '\n  mutation updateWorkspace($id: VecticeId!, $data: WorkspaceUpdateInput!) {\n    updateWorkspace(id: $id, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n':
    types.UpdateWorkspaceDocument,
  '\n  query getUserDefaultWorkspace($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      ...WorkspaceCard\n    }\n  }\n':
    types.GetUserDefaultWorkspaceDocument,
  '\n  query getUserWorkspaceList(\n    $pageIndex: Int!\n    $pageSize: Int!\n    $order: ListOrderInput\n    $search: String!\n    $type: [WorkspaceType!]\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getUserWorkspaceList(\n      page: { size: $pageSize, index: $pageIndex }\n      filters: { advancedFilters: $advancedFilters, type: $type, searchFilter: { search: $search, fields: ["name"] } }\n      order: $order\n    ) {\n      ...WorkspaceGrid\n      total\n    }\n  }\n':
    types.GetUserWorkspaceListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment commentFragment on Thread {\n    id\n    fileId\n    editorId\n    createdBy {\n      id\n      ...userFields\n    }\n    repliesInformation {\n      repliesCount\n    }\n    latestReply {\n      id\n      content\n      ...threadReplyFragment\n    }\n    status\n    createdDate\n    content\n    isEditedContent\n    updatedDateContent\n    highlightedText\n  }\n',
): (typeof documents)['\n  fragment commentFragment on Thread {\n    id\n    fileId\n    editorId\n    createdBy {\n      id\n      ...userFields\n    }\n    repliesInformation {\n      repliesCount\n    }\n    latestReply {\n      id\n      content\n      ...threadReplyFragment\n    }\n    status\n    createdDate\n    content\n    isEditedContent\n    updatedDateContent\n    highlightedText\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createThread($data: ThreadCreateInput!) {\n    createThread(data: $data) {\n      ...commentFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation createThread($data: ThreadCreateInput!) {\n    createThread(data: $data) {\n      ...commentFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createThreadReply($data: ThreadReplyCreateInput!) {\n    createThreadReply(data: $data) {\n      id\n      createdBy {\n        ...userFields\n      }\n      createdDate\n      content\n    }\n  }\n',
): (typeof documents)['\n  mutation createThreadReply($data: ThreadReplyCreateInput!) {\n    createThreadReply(data: $data) {\n      id\n      createdBy {\n        ...userFields\n      }\n      createdDate\n      content\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteThread($id: Float!) {\n    deleteThread(id: $id)\n  }\n',
): (typeof documents)['\n  mutation deleteThread($id: Float!) {\n    deleteThread(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteThreadReply($id: Float!) {\n    deleteThreadReply(id: $id)\n  }\n',
): (typeof documents)['\n  mutation deleteThreadReply($id: Float!) {\n    deleteThreadReply(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resolveThread($id: Float!) {\n    updateThread(data: { id: $id, status: RESOLVED }) {\n      id\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation resolveThread($id: Float!) {\n    updateThread(data: { id: $id, status: RESOLVED }) {\n      id\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription subscribeToFileComments($fileId: Float!) {\n    subscribeToFileComments(fileId: $fileId) {\n      fileId\n      threadId\n      content\n      status\n      isDeleted\n      replyId\n      thread {\n        ...commentFragment\n      }\n      reply {\n        ...threadReplyFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  subscription subscribeToFileComments($fileId: Float!) {\n    subscribeToFileComments(fileId: $fileId) {\n      fileId\n      threadId\n      content\n      status\n      isDeleted\n      replyId\n      thread {\n        ...commentFragment\n      }\n      reply {\n        ...threadReplyFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query threadReplies($threadId: Float!, $pageSize: Int!) {\n    threadReplies(\n      filters: { threadId: $threadId }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: ASC }\n    ) {\n      items {\n        id\n        content\n        ...threadReplyFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query threadReplies($threadId: Float!, $pageSize: Int!) {\n    threadReplies(\n      filters: { threadId: $threadId }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: ASC }\n    ) {\n      items {\n        id\n        content\n        ...threadReplyFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query threads($id: Float!, $pageSize: Int!, $editorIds: [String!], $status: ThreadStatus) {\n    threads(\n      filters: { fileId: $id, status: $status, editorIds: $editorIds }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: DESC }\n    ) {\n      items {\n        id\n        editorId\n        status\n        content\n        ...commentFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query threads($id: Float!, $pageSize: Int!, $editorIds: [String!], $status: ThreadStatus) {\n    threads(\n      filters: { fileId: $id, status: $status, editorIds: $editorIds }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: DESC }\n    ) {\n      items {\n        id\n        editorId\n        status\n        content\n        ...commentFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateThread($data: ThreadUpdateInput!) {\n    updateThread(data: $data) {\n      id\n      content\n      updatedDateContent\n      isEditedContent\n    }\n  }\n',
): (typeof documents)['\n  mutation updateThread($data: ThreadUpdateInput!) {\n    updateThread(data: $data) {\n      id\n      content\n      updatedDateContent\n      isEditedContent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateThreadReply($data: CommentUpdateInput!) {\n    updateThreadReply(data: $data) {\n      id\n      content\n      updatedDateContent\n      isEditedContent\n    }\n  }\n',
): (typeof documents)['\n  mutation updateThreadReply($data: CommentUpdateInput!) {\n    updateThreadReply(data: $data) {\n      id\n      content\n      updatedDateContent\n      isEditedContent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment threadReplyFragment on ThreadReply {\n    id\n    createdBy {\n      id\n      ...userFields\n    }\n    createdDate\n    content\n    isEditedContent\n    updatedDateContent\n  }\n',
): (typeof documents)['\n  fragment threadReplyFragment on ThreadReply {\n    id\n    createdBy {\n      id\n      ...userFields\n    }\n    createdDate\n    content\n    isEditedContent\n    updatedDateContent\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteProject($projectId: VecticeId!) {\n    deleteProject(projectId: $projectId)\n  }\n',
): (typeof documents)['\n  mutation deleteProject($projectId: VecticeId!) {\n    deleteProject(projectId: $projectId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteWorkspace($id: VecticeId!) {\n    deleteWorkspace(id: $id)\n  }\n',
): (typeof documents)['\n  mutation deleteWorkspace($id: VecticeId!) {\n    deleteWorkspace(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation leaveWorkspace($id: VecticeId!) {\n    leaveWorkspace(id: $id)\n  }\n',
): (typeof documents)['\n  mutation leaveWorkspace($id: VecticeId!) {\n    leaveWorkspace(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation transferProjectOwnership($projectId: VecticeId!, $data: ProjectUpdateInput!) {\n    updateProject(projectId: $projectId, data: $data) {\n      vecticeId\n      ownerId\n      owner {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation transferProjectOwnership($projectId: VecticeId!, $data: ProjectUpdateInput!) {\n    updateProject(projectId: $projectId, data: $data) {\n      vecticeId\n      ownerId\n      owner {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment userActivities on PaginatedUserActivityResponse {\n    items {\n      id\n      ...userActivity\n    }\n  }\n',
): (typeof documents)['\n  fragment userActivities on PaginatedUserActivityResponse {\n    items {\n      id\n      ...userActivity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment userActivity on UserActivity {\n    id\n    fromValue\n    fromUser {\n      id\n      ...userFields\n    }\n    toValue\n    toUser {\n      id\n      ...userFields\n    }\n    date\n    isAutomated\n    type\n    targetId\n    targetVecticeId\n    targetName\n    targetType\n    updateType\n    workspace {\n      vecticeId\n      name\n      type\n    }\n    project {\n      vecticeId\n      name\n    }\n    actor {\n      id\n      ...userFields\n    }\n    sourceId\n    sourceVecticeId\n    sourceName\n    sourceWorkspaceVecticeId\n    parentVecticeId\n    parentName\n  }\n',
): (typeof documents)['\n  fragment userActivity on UserActivity {\n    id\n    fromValue\n    fromUser {\n      id\n      ...userFields\n    }\n    toValue\n    toUser {\n      id\n      ...userFields\n    }\n    date\n    isAutomated\n    type\n    targetId\n    targetVecticeId\n    targetName\n    targetType\n    updateType\n    workspace {\n      vecticeId\n      name\n      type\n    }\n    project {\n      vecticeId\n      name\n    }\n    actor {\n      id\n      ...userFields\n    }\n    sourceId\n    sourceVecticeId\n    sourceName\n    sourceWorkspaceVecticeId\n    parentVecticeId\n    parentName\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReportLinkInfo($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getReportLinkInfo($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment reportListItem on CDTReport {\n    id\n    ...reportRow\n  }\n',
): (typeof documents)['\n  fragment reportListItem on CDTReport {\n    id\n    ...reportRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment reportRow on CDTReport {\n    id\n    name\n    updatedDate\n    createdBy {\n      ...userFields\n    }\n    project {\n      id\n      name\n      workspace {\n        vecticeId\n        type\n        name\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment reportRow on CDTReport {\n    id\n    name\n    updatedDate\n    createdBy {\n      ...userFields\n    }\n    project {\n      id\n      name\n      workspace {\n        vecticeId\n        type\n        name\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query reportTooltip($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n      createdDate\n      createdBy {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query reportTooltip($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n      createdDate\n      createdBy {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment datasetVersionListItem on DataSetVersion {\n    vecticeId\n    ...datasetVersionRow\n  }\n',
): (typeof documents)['\n  fragment datasetVersionListItem on DataSetVersion {\n    vecticeId\n    ...datasetVersionRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment datasetVersionRow on DataSetVersion {\n    vecticeId\n    versionNumber\n    isStarred\n    dataSet {\n      vecticeId\n      name\n      type\n    }\n    datasetSources {\n      id\n      size\n      itemsCount\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment datasetVersionRow on DataSetVersion {\n    vecticeId\n    versionNumber\n    isStarred\n    dataSet {\n      vecticeId\n      name\n      type\n    }\n    datasetSources {\n      id\n      size\n      itemsCount\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDataSetVersionTooltip($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      dataSet {\n        vecticeId\n        name\n        type\n      }\n      versionNumber\n      datasetSources {\n        id\n        usage\n        size\n        itemsCount\n      }\n      properties {\n        id\n      }\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  query getDataSetVersionTooltip($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      dataSet {\n        vecticeId\n        name\n        type\n      }\n      versionNumber\n      datasetSources {\n        id\n        usage\n        size\n        itemsCount\n      }\n      properties {\n        id\n      }\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionWidget($id: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $id) {\n      vecticeId\n      name\n      versionNumber\n      ...datasetVersionRow\n      properties {\n        id\n      }\n      dataSet {\n        vecticeId\n        name\n      }\n      datasetSources {\n        id\n        itemsCount\n      }\n      origin {\n        id\n        datasetInputs\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionWidget($id: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $id) {\n      vecticeId\n      name\n      versionNumber\n      ...datasetVersionRow\n      properties {\n        id\n      }\n      dataSet {\n        vecticeId\n        name\n      }\n      datasetSources {\n        id\n        itemsCount\n      }\n      origin {\n        id\n        datasetInputs\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment datasetListItem on DataSet {\n    vecticeId\n    ...datasetRow\n  }\n',
): (typeof documents)['\n  fragment datasetListItem on DataSet {\n    vecticeId\n    ...datasetRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment datasetRow on DataSet {\n    vecticeId\n    name\n    type\n    versionCount\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment datasetRow on DataSet {\n    vecticeId\n    name\n    type\n    versionCount\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDataSetTooltip($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      name\n      type\n      versionCount\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  query getDataSetTooltip($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      name\n      type\n      versionCount\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetWidget($id: VecticeId!) {\n    getDataset(datasetId: $id) {\n      vecticeId\n      ...datasetRow\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetWidget($id: VecticeId!) {\n    getDataset(datasetId: $id) {\n      vecticeId\n      ...datasetRow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityFileWidget($id: Float!) {\n    getEntityFileById(id: $id) {\n      id\n      fileName\n      mimeType\n      entityId\n      type\n      hasPreview\n      hasStaticView\n      ...entityFileFragment\n    }\n  }\n',
): (typeof documents)['\n  query getEntityFileWidget($id: Float!) {\n    getEntityFileById(id: $id) {\n      id\n      fileName\n      mimeType\n      entityId\n      type\n      hasPreview\n      hasStaticView\n      ...entityFileFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityFileIterationOrigin($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getEntityFileIterationOrigin($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityFileDatasetVersionOrigin($id: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $id) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getEntityFileDatasetVersionOrigin($id: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $id) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityFileModelVersionOrigin($id: VecticeId!) {\n    getModelVersion(modelVersionId: $id) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getEntityFileModelVersionOrigin($id: VecticeId!) {\n    getModelVersion(modelVersionId: $id) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query entityMetadata($id: Float!) {\n    entityMetadata(id: $id) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query entityMetadata($id: Float!) {\n    entityMetadata(id: $id) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFindingTooltip($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      status\n      owner {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFindingTooltip($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      status\n      owner {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment iterationListItem on Iteration {\n    vecticeId\n    ...iterationRow\n  }\n',
): (typeof documents)['\n  fragment iterationListItem on Iteration {\n    vecticeId\n    ...iterationRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment iterationRow on Iteration {\n    vecticeId\n    index\n    description\n    name\n    status\n    starred\n    updatedDate\n    phase {\n      vecticeId\n      name\n    }\n    owner {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment iterationRow on Iteration {\n    vecticeId\n    index\n    description\n    name\n    status\n    starred\n    updatedDate\n    phase {\n      vecticeId\n      name\n    }\n    owner {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationTooltip($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      index\n      name\n      status\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  query getIterationTooltip($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      index\n      name\n      status\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment artifact on IterationStepArtifact {\n    id\n    text\n    type\n    datasetVersion {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n    }\n    entityFile {\n      id\n      fileName\n      mimeType\n      contentType\n      entityId\n    }\n    modelVersion {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n    }\n    entityMetadata {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment artifact on IterationStepArtifact {\n    id\n    text\n    type\n    datasetVersion {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n    }\n    entityFile {\n      id\n      fileName\n      mimeType\n      contentType\n      entityId\n    }\n    modelVersion {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n    }\n    entityMetadata {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationWidget($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      description\n      ...iterationRow\n    }\n  }\n',
): (typeof documents)['\n  query getIterationWidget($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      description\n      ...iterationRow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationArtifactsForWidget($id: VecticeId!) {\n    getIterationById(id: $id) {\n      paginatedArtifacts(page: { index: 1, size: 100 }) {\n        items {\n          id\n          type\n          ...artifact\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationArtifactsForWidget($id: VecticeId!) {\n    getIterationById(id: $id) {\n      paginatedArtifacts(page: { index: 1, size: 100 }) {\n        items {\n          id\n          type\n          ...artifact\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationStepListForWidget($id: VecticeId!) {\n    sections(parentId: $id, page: { index: 1, size: 100 }, order: { field: "index", direction: ASC }) {\n      items {\n        id\n        name\n        description\n        paginatedArtifacts(page: { index: 1, size: 100 }) {\n          total\n          items {\n            id\n            type\n            ...artifact\n          }\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getIterationStepListForWidget($id: VecticeId!) {\n    sections(parentId: $id, page: { index: 1, size: 100 }, order: { field: "index", direction: ASC }) {\n      items {\n        id\n        name\n        description\n        paginatedArtifacts(page: { index: 1, size: 100 }) {\n          total\n          items {\n            id\n            type\n            ...artifact\n          }\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLineageTooltip($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId) {\n      items {\n        id\n        lineageArtifactType\n        artifactType\n        dataSetVersion {\n          vecticeId\n          name\n          versionNumber\n          dataSet {\n            vecticeId\n            name\n          }\n        }\n        modelVersion {\n          vecticeId\n          name\n          versionNumber\n          model {\n            vecticeId\n            name\n          }\n        }\n        codeVersion {\n          id\n          code {\n            name\n          }\n          gitVersion {\n            commitHash\n            uri\n            entrypoint\n          }\n          databricksVersion {\n            urlNotebook\n            relativePath\n          }\n          otherCodeVersion {\n            id\n            url\n            firstAdditionalInformation\n            secondAdditionalInformation\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getLineageTooltip($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId) {\n      items {\n        id\n        lineageArtifactType\n        artifactType\n        dataSetVersion {\n          vecticeId\n          name\n          versionNumber\n          dataSet {\n            vecticeId\n            name\n          }\n        }\n        modelVersion {\n          vecticeId\n          name\n          versionNumber\n          model {\n            vecticeId\n            name\n          }\n        }\n        codeVersion {\n          id\n          code {\n            name\n          }\n          gitVersion {\n            commitHash\n            uri\n            entrypoint\n          }\n          databricksVersion {\n            urlNotebook\n            relativePath\n          }\n          otherCodeVersion {\n            id\n            url\n            firstAdditionalInformation\n            secondAdditionalInformation\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLineageWidget($id: Float!) {\n    getJobRunById(lineageId: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  query getLineageWidget($id: Float!) {\n    getJobRunById(lineageId: $id) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionLinkInfo($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      versionNumber\n      model {\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionLinkInfo($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      versionNumber\n      model {\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment modelVersionListItem on ModelVersion {\n    vecticeId\n    ...modelVersionRow\n  }\n',
): (typeof documents)['\n  fragment modelVersionListItem on ModelVersion {\n    vecticeId\n    ...modelVersionRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment modelVersionRow on ModelVersion {\n    vecticeId\n    versionNumber\n    status\n    algorithmName\n    isStarred\n    model {\n      vecticeId\n      name\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment modelVersionRow on ModelVersion {\n    vecticeId\n    versionNumber\n    status\n    algorithmName\n    isStarred\n    model {\n      vecticeId\n      name\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionTooltip($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      model {\n        vecticeId\n        name\n      }\n      versionNumber\n      status\n      approval\n      risk\n      algorithmName\n      createdBy {\n        ...userFields\n      }\n      properties {\n        id\n      }\n      metrics {\n        id\n      }\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionTooltip($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      model {\n        vecticeId\n        name\n      }\n      versionNumber\n      status\n      approval\n      risk\n      algorithmName\n      createdBy {\n        ...userFields\n      }\n      properties {\n        id\n      }\n      metrics {\n        id\n      }\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionWidget($id: VecticeId!) {\n    getModelVersion(modelVersionId: $id) {\n      vecticeId\n      ...modelVersionRow\n      properties {\n        id\n      }\n      metrics {\n        id\n      }\n      model {\n        vecticeId\n      }\n      origin {\n        id\n\n        datasetInputs\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionWidget($id: VecticeId!) {\n    getModelVersion(modelVersionId: $id) {\n      vecticeId\n      ...modelVersionRow\n      properties {\n        id\n      }\n      metrics {\n        id\n      }\n      model {\n        vecticeId\n      }\n      origin {\n        id\n\n        datasetInputs\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment modelListItem on Model {\n    vecticeId\n    ...modelRow\n  }\n',
): (typeof documents)['\n  fragment modelListItem on Model {\n    vecticeId\n    ...modelRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment modelRow on Model {\n    vecticeId\n    name\n    type\n    versionStats {\n      experimentationCount\n      validationCount\n      productionCount\n      stagingCount\n      retiredCount\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment modelRow on Model {\n    vecticeId\n    name\n    type\n    versionStats {\n      experimentationCount\n      validationCount\n      productionCount\n      stagingCount\n      retiredCount\n    }\n    updatedDate\n    lastUpdatedBy {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelTooltip($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      vecticeId\n      name\n      type\n      versionCount\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  query getModelTooltip($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      vecticeId\n      name\n      type\n      versionCount\n      createdBy {\n        ...userFields\n      }\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelWidget($id: VecticeId!) {\n    getModelById(modelId: $id) {\n      vecticeId\n      ...modelRow\n    }\n  }\n',
): (typeof documents)['\n  query getModelWidget($id: VecticeId!) {\n    getModelById(modelId: $id) {\n      vecticeId\n      ...modelRow\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment phaseListItem on Phase {\n    vecticeId\n    ...phaseRow\n  }\n',
): (typeof documents)['\n  fragment phaseListItem on Phase {\n    vecticeId\n    ...phaseRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment phaseRow on Phase {\n    vecticeId\n    name\n    status\n    updatedDate\n    owner {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment phaseRow on Phase {\n    vecticeId\n    name\n    status\n    updatedDate\n    owner {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseTooltip($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      status\n      activeReview {\n        vecticeId\n        status\n      }\n      iterationsCount {\n        total\n      }\n      owner {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseTooltip($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      status\n      activeReview {\n        vecticeId\n        status\n      }\n      iterationsCount {\n        total\n      }\n      owner {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment projectCard on Project {\n    vecticeId\n    name\n    workspace {\n      vecticeId\n      type\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment projectCard on Project {\n    vecticeId\n    name\n    workspace {\n      vecticeId\n      type\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment projectListItem on Project {\n    vecticeId\n    ...projectRow\n  }\n',
): (typeof documents)['\n  fragment projectListItem on Project {\n    vecticeId\n    ...projectRow\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment projectRow on Project {\n    vecticeId\n    name\n    status\n    phasesCounts {\n      done\n      ongoing\n      toDo\n      total\n    }\n    updatedDate\n    owner {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment projectRow on Project {\n    vecticeId\n    name\n    status\n    phasesCounts {\n      done\n      ongoing\n      toDo\n      total\n    }\n    updatedDate\n    owner {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectTooltip($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      status\n      phasesCounts {\n        done\n        ongoing\n        toDo\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getProjectTooltip($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      status\n      phasesCounts {\n        done\n        ongoing\n        toDo\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment reviewListItem on Review {\n    vecticeId\n    ...reviewRow\n    phase {\n      vecticeId\n    }\n  }\n',
): (typeof documents)['\n  fragment reviewListItem on Review {\n    vecticeId\n    ...reviewRow\n    phase {\n      vecticeId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment reviewRow on Review {\n    id\n    status\n    createdDate\n    createdBy {\n      ...userFields\n    }\n    phase {\n      vecticeId\n      name\n      parent {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment reviewRow on Review {\n    id\n    status\n    createdDate\n    createdBy {\n      ...userFields\n    }\n    phase {\n      vecticeId\n      name\n      parent {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserTooltip($userId: Float!) {\n    getOrganizationUser(userId: $userId) {\n      id\n      name\n      avatarId\n      email\n      color\n      role\n      disabled\n    }\n  }\n',
): (typeof documents)['\n  query getUserTooltip($userId: Float!) {\n    getOrganizationUser(userId: $userId) {\n      id\n      name\n      avatarId\n      email\n      color\n      role\n      disabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceTooltip($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      type\n      projectCount\n      userCount(filters: { includeDisabledUsers: false })\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceTooltip($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      type\n      projectCount\n      userCount(filters: { includeDisabledUsers: false })\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTReportsSelector(\n    $type: CDTReportQueryType\n    $filters: CDTReportFiltersInput!\n    $order: ListOrderInput\n    $page: PageInput\n  ) {\n    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query CDTReportsSelector(\n    $type: CDTReportQueryType\n    $filters: CDTReportFiltersInput!\n    $order: ListOrderInput\n    $page: PageInput\n  ) {\n    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionPickerList(\n    $datasetId: VecticeId!\n    $filters: DataSetVersionFilterInput\n    $order: ListOrderInput\n  ) {\n    getDatasetVersionsList(datasetId: $datasetId, filters: $filters, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionPickerList(\n    $datasetId: VecticeId!\n    $filters: DataSetVersionFilterInput\n    $order: ListOrderInput\n  ) {\n    getDatasetVersionsList(datasetId: $datasetId, filters: $filters, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionPickerList(\n    $modelId: VecticeId!\n    $filters: ModelVersionListFiltersInput\n    $order: ListOrderInput\n  ) {\n    getModelVersionsList(modelId: $modelId, filters: $filters, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionPickerList(\n    $modelId: VecticeId!\n    $filters: ModelVersionListFiltersInput\n    $order: ListOrderInput\n  ) {\n    getModelVersionsList(modelId: $modelId, filters: $filters, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectsSelector(\n    $workspaceId: VecticeId!\n    $filters: ProjectListFiltersInput!\n    $order: ListOrderInput\n    $page: PageIndexInput\n  ) {\n    getProjectList(workspaceId: $workspaceId, filters: $filters, order: $order, page: $page) {\n      items {\n        name\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getProjectsSelector(\n    $workspaceId: VecticeId!\n    $filters: ProjectListFiltersInput!\n    $order: ListOrderInput\n    $page: PageIndexInput\n  ) {\n    getProjectList(workspaceId: $workspaceId, filters: $filters, order: $order, page: $page) {\n      items {\n        name\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query datasetsToSelect($projectId: VecticeId!, $search: String, $versionId: VecticeId) {\n    getDatasetList(\n      projectId: $projectId\n      filters: { search: $search, versionId: $versionId }\n      page: { index: 1, size: 10 }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query datasetsToSelect($projectId: VecticeId!, $search: String, $versionId: VecticeId) {\n    getDatasetList(\n      projectId: $projectId\n      filters: { search: $search, versionId: $versionId }\n      page: { index: 1, size: 10 }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query datasetVersionsToSelect($datasetId: VecticeId!, $order: ListOrderInput) {\n    getDatasetVersionsList(datasetId: $datasetId, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        dataSet {\n          vecticeId\n          name\n          project {\n            vecticeId\n          }\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query datasetVersionsToSelect($datasetId: VecticeId!, $order: ListOrderInput) {\n    getDatasetVersionsList(datasetId: $datasetId, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        dataSet {\n          vecticeId\n          name\n          project {\n            vecticeId\n          }\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query modelsToSelect($projectId: VecticeId!, $search: String) {\n    getModelList(projectId: $projectId, filters: { search: $search }, page: { index: 1, size: 10 }) {\n      items {\n        vecticeId\n        name\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query modelsToSelect($projectId: VecticeId!, $search: String) {\n    getModelList(projectId: $projectId, filters: { search: $search }, page: { index: 1, size: 10 }) {\n      items {\n        vecticeId\n        name\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query modelVersionsToSelect($modelId: VecticeId!, $order: ListOrderInput) {\n    getModelVersionsList(modelId: $modelId, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        model {\n          vecticeId\n          name\n          project {\n            vecticeId\n          }\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query modelVersionsToSelect($modelId: VecticeId!, $order: ListOrderInput) {\n    getModelVersionsList(modelId: $modelId, order: $order) {\n      items {\n        vecticeId\n        versionNumber\n        model {\n          vecticeId\n          name\n          project {\n            vecticeId\n          }\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterations($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        index\n        name\n        phase {\n          vecticeId\n          name\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getIterations($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        index\n        name\n        phase {\n          vecticeId\n          name\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getSelectedCDTReport($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getSelectedCDTReport($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getSelectedProject($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getSelectedProject($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getSelectedWorkspace($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getSelectedWorkspace($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query sourceColumns(\n    $id: Float!\n    $columnSourceType: ColumnSourceType!\n    $search: String\n    $order: ListOrderInput\n    $page: PageIndexInput\n  ) {\n    sourceColumns(\n      id: $id\n      columnSourceType: $columnSourceType\n      filters: { search: $search }\n      order: $order\n      page: $page\n    ) {\n      items {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query sourceColumns(\n    $id: Float!\n    $columnSourceType: ColumnSourceType!\n    $search: String\n    $order: ListOrderInput\n    $page: PageIndexInput\n  ) {\n    sourceColumns(\n      id: $id\n      columnSourceType: $columnSourceType\n      filters: { search: $search }\n      order: $order\n      page: $page\n    ) {\n      items {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query sourceColumn($id: Float!, $columnSourceType: ColumnSourceType!) {\n    sourceColumn(id: $id, columnSourceType: $columnSourceType) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  query sourceColumn($id: Float!, $columnSourceType: ColumnSourceType!) {\n    sourceColumn(id: $id, columnSourceType: $columnSourceType) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment authMethodCard on KeycloakIdentityProvider {\n    alias\n    displayName\n    enabled\n    providerId\n  }\n',
): (typeof documents)['\n  fragment authMethodCard on KeycloakIdentityProvider {\n    alias\n    displayName\n    enabled\n    providerId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getContributorList(\n    $workspaceIdList: [VecticeId!]!\n    $order: ListOrderInput\n    $filters: ContributorListFiltersInput\n    $page: PageInput = { size: 30, index: 1 }\n  ) {\n    getContributorList(workspaceIdList: $workspaceIdList, order: $order, filters: $filters, page: $page) {\n      items {\n        id\n        name\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getContributorList(\n    $workspaceIdList: [VecticeId!]!\n    $order: ListOrderInput\n    $filters: ContributorListFiltersInput\n    $page: PageInput = { size: 30, index: 1 }\n  ) {\n    getContributorList(workspaceIdList: $workspaceIdList, order: $order, filters: $filters, page: $page) {\n      items {\n        id\n        name\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getActivityInsights($workspaceIdList: [VecticeId!]!) {\n    getActivityInsights(workspaceIdList: $workspaceIdList, numberOfDays: 30) {\n      completedProjectsCount\n      completedPhasesCount\n      approvedPhasesCount\n      modelVersionsCount\n      datasetVersionsCount\n    }\n  }\n',
): (typeof documents)['\n  query getActivityInsights($workspaceIdList: [VecticeId!]!) {\n    getActivityInsights(workspaceIdList: $workspaceIdList, numberOfDays: 30) {\n      completedProjectsCount\n      completedPhasesCount\n      approvedPhasesCount\n      modelVersionsCount\n      datasetVersionsCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceActivityTrend($workspaceIdList: [VecticeId!]!, $userTimezone: String!) {\n    getWorkspaceActivityTrend(workspaceIdList: $workspaceIdList, userTimezone: $userTimezone) {\n      date\n      count\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceActivityTrend($workspaceIdList: [VecticeId!]!, $userTimezone: String!) {\n    getWorkspaceActivityTrend(workspaceIdList: $workspaceIdList, userTimezone: $userTimezone) {\n      date\n      count\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceCompletedPhases($workspaceIdList: [VecticeId!]!) {\n    getMostRecentlyCompletedPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          createdDate\n          parent {\n            vecticeId\n            name\n          }\n          owner {\n            ...userFields\n          }\n        }\n        completionDate\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceCompletedPhases($workspaceIdList: [VecticeId!]!) {\n    getMostRecentlyCompletedPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          createdDate\n          parent {\n            vecticeId\n            name\n          }\n          owner {\n            ...userFields\n          }\n        }\n        completionDate\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceCompletedProjects($workspaceIdList: [VecticeId!]!) {\n    getMostRecentlyCompletedProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          createdDate\n          owner {\n            ...userFields\n          }\n        }\n        completionDate\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceCompletedProjects($workspaceIdList: [VecticeId!]!) {\n    getMostRecentlyCompletedProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          createdDate\n          owner {\n            ...userFields\n          }\n        }\n        completionDate\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCompletedReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getCompletedReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        user {\n          ...userFields\n        }\n        total\n        items {\n          status\n          count\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getCompletedReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getCompletedReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        user {\n          ...userFields\n        }\n        total\n        items {\n          status\n          count\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseStatusCountsPerOwner($workspaceIdList: [VecticeId!]!) {\n    getPhaseStatusCountsPerOwner(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        items {\n          status\n          count\n        }\n        user {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseStatusCountsPerOwner($workspaceIdList: [VecticeId!]!) {\n    getPhaseStatusCountsPerOwner(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        items {\n          status\n          count\n        }\n        user {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceCompletedIterationsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceCompletedIterationsCountPerUser(\n      workspaceIdList: $workspaceIdList\n      page: { index: 1, size: 10 }\n      numberOfDays: 30\n    ) {\n      items {\n        user {\n          id\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceCompletedIterationsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceCompletedIterationsCountPerUser(\n      workspaceIdList: $workspaceIdList\n      page: { index: 1, size: 10 }\n      numberOfDays: 30\n    ) {\n      items {\n        user {\n          id\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceOngoingPhases($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceOngoingPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          status\n          parent {\n            vecticeId\n            name\n          }\n          owner {\n            ...userFields\n          }\n        }\n        days\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceOngoingPhases($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceOngoingPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          status\n          parent {\n            vecticeId\n            name\n          }\n          owner {\n            ...userFields\n          }\n        }\n        days\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceOngoingProjects($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceOngoingProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          owner {\n            ...userFields\n          }\n          phasesCounts {\n            toDo\n            ongoing\n            done\n            total\n          }\n        }\n        days\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceOngoingProjects($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceOngoingProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        entity {\n          vecticeId\n          name\n          owner {\n            ...userFields\n          }\n          phasesCounts {\n            toDo\n            ongoing\n            done\n            total\n          }\n        }\n        days\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPendingReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getPendingReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        user {\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPendingReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getPendingReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {\n      items {\n        user {\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseStatusesBreakdown($workspaceIdList: [VecticeId!]!) {\n    getPhaseStatusCounts(workspaceIdList: $workspaceIdList) {\n      items {\n        status\n        count\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseStatusesBreakdown($workspaceIdList: [VecticeId!]!) {\n    getPhaseStatusCounts(workspaceIdList: $workspaceIdList) {\n      items {\n        status\n        count\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceCompletedPhasesCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceCompletedPhasesCountPerUser(\n      workspaceIdList: $workspaceIdList\n      page: { index: 1, size: 10 }\n      numberOfDays: 30\n    ) {\n      items {\n        user {\n          id\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceCompletedPhasesCountPerUser($workspaceIdList: [VecticeId!]!) {\n    getWorkspaceCompletedPhasesCountPerUser(\n      workspaceIdList: $workspaceIdList\n      page: { index: 1, size: 10 }\n      numberOfDays: 30\n    ) {\n      items {\n        user {\n          id\n          ...userFields\n        }\n        count\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectStatusesBreakdown($workspaceIdList: [VecticeId!]!) {\n    getProjectStatusCounts(workspaceIdList: $workspaceIdList) {\n      items {\n        status\n        count\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getProjectStatusesBreakdown($workspaceIdList: [VecticeId!]!) {\n    getProjectStatusCounts(workspaceIdList: $workspaceIdList) {\n      items {\n        status\n        count\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getTopAssetsCreators($workspaceIdList: [VecticeId!]!) {\n    getTopAssetsCreators(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }, numberOfDays: 30) {\n      items {\n        user {\n          ...userFields\n        }\n        datasetVersionCount\n        modelVersionCount\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getTopAssetsCreators($workspaceIdList: [VecticeId!]!) {\n    getTopAssetsCreators(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }, numberOfDays: 30) {\n      items {\n        user {\n          ...userFields\n        }\n        datasetVersionCount\n        modelVersionCount\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionFileSource($fileSourceId: Float!) {\n    getDatasetFilesSource(fileSourceId: $fileSourceId) {\n      id\n      name\n      size\n      columnsNumber\n      rowsNumber\n      uri\n      mimeType\n      fileCreatedDate\n      fileUpdatedDate\n      skippedStatistics\n      extraMetadata {\n        key\n        value\n        displayName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionFileSource($fileSourceId: Float!) {\n    getDatasetFilesSource(fileSourceId: $fileSourceId) {\n      id\n      name\n      size\n      columnsNumber\n      rowsNumber\n      uri\n      mimeType\n      fileCreatedDate\n      fileUpdatedDate\n      skippedStatistics\n      extraMetadata {\n        key\n        value\n        displayName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionDbSource($dbSourceId: Float!) {\n    getDBSource(dbSourceId: $dbSourceId) {\n      id\n      name\n      size\n      type\n      columnsNumber\n      rowsNumber\n      uri\n      tableCreatedDate\n      tableUpdatedDate\n      skippedStatistics\n      extraMetadata {\n        key\n        value\n        displayName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionDbSource($dbSourceId: Float!) {\n    getDBSource(dbSourceId: $dbSourceId) {\n      id\n      name\n      size\n      type\n      columnsNumber\n      rowsNumber\n      uri\n      tableCreatedDate\n      tableUpdatedDate\n      skippedStatistics\n      extraMetadata {\n        key\n        value\n        displayName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDataSourceFileSourceList($datasetSourceId: Float!) {\n    fileSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {\n      items {\n        id\n        filename\n        mimeType\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDataSourceFileSourceList($datasetSourceId: Float!) {\n    fileSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {\n      items {\n        id\n        filename\n        mimeType\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDataSourceDbSourceList($datasetSourceId: Float!) {\n    dbSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {\n      items {\n        id\n        tablename\n        type\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDataSourceDbSourceList($datasetSourceId: Float!) {\n    dbSources(filters: { datasetSourceId: $datasetSourceId }, page: { size: 15, index: 1 }) {\n      items {\n        id\n        tablename\n        type\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionResources($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      targetColumn\n      datasetSources {\n        id\n        type\n        usage\n        ...datasetSourceMenu\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionResources($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      targetColumn\n      datasetSources {\n        id\n        type\n        usage\n        ...datasetSourceMenu\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionFileSourceList($datasetVersionId: VecticeId!) {\n    fileSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {\n      items {\n        id\n        filename\n        mimeType\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionFileSourceList($datasetVersionId: VecticeId!) {\n    fileSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {\n      items {\n        id\n        filename\n        mimeType\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionDbSourceList($datasetVersionId: VecticeId!) {\n    dbSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {\n      items {\n        id\n        tablename\n        type\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionDbSourceList($datasetVersionId: VecticeId!) {\n    dbSources(filters: { datasetVersionId: $datasetVersionId }, page: { size: 3, index: 1 }) {\n      items {\n        id\n        tablename\n        type\n        rowsNumber\n        columnsNumber\n        columnsTypesCounts {\n          categoryType\n          count\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment datasetSourceMenu on DatasetSource {\n    id\n    itemsCount\n    columnsCount\n    size\n    type\n    usage\n  }\n',
): (typeof documents)['\n  fragment datasetSourceMenu on DatasetSource {\n    id\n    itemsCount\n    columnsCount\n    size\n    type\n    usage\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDatasetVersion($targetColumn: String, $datasetVersionId: VecticeId!) {\n    updateDatasetVersion(data: { targetColumn: $targetColumn }, datasetVersionId: $datasetVersionId) {\n      vecticeId\n      targetColumn\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDatasetVersion($targetColumn: String, $datasetVersionId: VecticeId!) {\n    updateDatasetVersion(data: { targetColumn: $targetColumn }, datasetVersionId: $datasetVersionId) {\n      vecticeId\n      targetColumn\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateColumnSourceDescription($id: Float!, $columnSourceType: ColumnSourceType!, $description: String) {\n    updateColumnSourceDescription(id: $id, columnSourceType: $columnSourceType, description: $description)\n  }\n',
): (typeof documents)['\n  mutation updateColumnSourceDescription($id: Float!, $columnSourceType: ColumnSourceType!, $description: String) {\n    updateColumnSourceDescription(id: $id, columnSourceType: $columnSourceType, description: $description)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionFileColumns($fileSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {\n    getFileSourceColumnsList(\n      fileSourceId: $fileSourceId\n      page: { index: $pageIndex, size: $pageSize }\n      filters: { searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        id\n        name\n        description\n        categoryType\n        statistics {\n          ...BooleanSourceColumnStatistics\n          ...DateSourceColumnStatistics\n          ...NumericalSourceColumnStatistics\n          ...TextSourceColumnStatistics\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionFileColumns($fileSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {\n    getFileSourceColumnsList(\n      fileSourceId: $fileSourceId\n      page: { index: $pageIndex, size: $pageSize }\n      filters: { searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        id\n        name\n        description\n        categoryType\n        statistics {\n          ...BooleanSourceColumnStatistics\n          ...DateSourceColumnStatistics\n          ...NumericalSourceColumnStatistics\n          ...TextSourceColumnStatistics\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionDbColumns($dbSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {\n    getDBSourceColumnsList(\n      dbSourceId: $dbSourceId\n      page: { index: $pageIndex, size: $pageSize }\n      filters: { searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        id\n        name\n        description\n        categoryType\n        statistics {\n          ...BooleanSourceColumnStatistics\n          ...DateSourceColumnStatistics\n          ...NumericalSourceColumnStatistics\n          ...TextSourceColumnStatistics\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionDbColumns($dbSourceId: Float!, $pageIndex: Int, $pageSize: Int!, $search: String!) {\n    getDBSourceColumnsList(\n      dbSourceId: $dbSourceId\n      page: { index: $pageIndex, size: $pageSize }\n      filters: { searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        id\n        name\n        description\n        categoryType\n        statistics {\n          ...BooleanSourceColumnStatistics\n          ...DateSourceColumnStatistics\n          ...NumericalSourceColumnStatistics\n          ...TextSourceColumnStatistics\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BooleanSourceColumnStatistics on DataframeStatistics {\n    boolean {\n      false\n      missing\n      true\n    }\n  }\n',
): (typeof documents)['\n  fragment BooleanSourceColumnStatistics on DataframeStatistics {\n    boolean {\n      false\n      missing\n      true\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DateSourceColumnStatistics on DataframeStatistics {\n    date {\n      maximum\n      mean\n      median\n      minimum\n      missing\n    }\n  }\n',
): (typeof documents)['\n  fragment DateSourceColumnStatistics on DataframeStatistics {\n    date {\n      maximum\n      mean\n      median\n      minimum\n      missing\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment NumericalSourceColumnStatistics on DataframeStatistics {\n    numerical {\n      mean\n      missing\n      quantiles {\n        qMin\n        q25\n        q50\n        q75\n        qMax\n      }\n      stdDeviation\n    }\n  }\n',
): (typeof documents)['\n  fragment NumericalSourceColumnStatistics on DataframeStatistics {\n    numerical {\n      mean\n      missing\n      quantiles {\n        qMin\n        q25\n        q50\n        q75\n        qMax\n      }\n      stdDeviation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TextSourceColumnStatistics on DataframeStatistics {\n    text {\n      missing\n      mostCommons {\n        frequency\n        value\n      }\n      unique\n    }\n  }\n',
): (typeof documents)['\n  fragment TextSourceColumnStatistics on DataframeStatistics {\n    text {\n      missing\n      mostCommons {\n        frequency\n        value\n      }\n      unique\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionWithOrigin($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      origin {\n        id\n        datasetInputs\n      }\n      dataSet {\n        vecticeId\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionWithOrigin($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      origin {\n        id\n        datasetInputs\n      }\n      dataSet {\n        vecticeId\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DatasetVersionSidebar on DataSetVersion {\n    vecticeId\n    description\n    sourceOrigin\n    dataSet {\n      vecticeId\n      type\n      project {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n    origins {\n      phase {\n        vecticeId\n        name\n      }\n      iteration {\n        vecticeId\n        name\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment DatasetVersionSidebar on DataSetVersion {\n    vecticeId\n    description\n    sourceOrigin\n    dataSet {\n      vecticeId\n      type\n      project {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n    origins {\n      phase {\n        vecticeId\n        name\n      }\n      iteration {\n        vecticeId\n        name\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDatasetVersionDescription($datasetVersionId: VecticeId!, $data: DatasetVersionUpdateInput!) {\n    updateDatasetVersion(datasetVersionId: $datasetVersionId, data: $data) {\n      vecticeId\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDatasetVersionDescription($datasetVersionId: VecticeId!, $data: DatasetVersionUpdateInput!) {\n    updateDatasetVersion(datasetVersionId: $datasetVersionId, data: $data) {\n      vecticeId\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addToStarredDatasetVersions($resource: StarredResourceSettings!) {\n    addToStarredDatasetVersions(resource: $resource) {\n      vecticeId\n      isStarred\n    }\n  }\n',
): (typeof documents)['\n  mutation addToStarredDatasetVersions($resource: StarredResourceSettings!) {\n    addToStarredDatasetVersions(resource: $resource) {\n      vecticeId\n      isStarred\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetType($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      type\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetType($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionsList(\n    $datasetId: VecticeId!\n    $search: String\n    $createdBy: [ID!]\n    $showStarred: Boolean\n    $contributors: [ID!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n    $order: ListOrderInput\n  ) {\n    getDatasetVersionsList(\n      order: $order\n      datasetId: $datasetId\n      filters: {\n        createdBy: $createdBy\n        search: $search\n        contributors: $contributors\n        showStarred: $showStarred\n        advancedFilters: $advancedFilters\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n        origin {\n          id\n        }\n        properties {\n          ...propertiesFields\n        }\n        createdBy {\n          ...userFields\n        }\n        datasetSources {\n          type\n          usage\n          size\n          itemsCount\n          columnsCount\n        }\n        createdDate\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionsList(\n    $datasetId: VecticeId!\n    $search: String\n    $createdBy: [ID!]\n    $showStarred: Boolean\n    $contributors: [ID!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n    $order: ListOrderInput\n  ) {\n    getDatasetVersionsList(\n      order: $order\n      datasetId: $datasetId\n      filters: {\n        createdBy: $createdBy\n        search: $search\n        contributors: $contributors\n        showStarred: $showStarred\n        advancedFilters: $advancedFilters\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n        origin {\n          id\n        }\n        properties {\n          ...propertiesFields\n        }\n        createdBy {\n          ...userFields\n        }\n        datasetSources {\n          type\n          usage\n          size\n          itemsCount\n          columnsCount\n        }\n        createdDate\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPropertyKeysOfDataset($datasetId: VecticeId!) {\n    getPropertyKeysOfEntity(entityId: $datasetId, entityType: DATASET)\n  }\n',
): (typeof documents)['\n  query getPropertyKeysOfDataset($datasetId: VecticeId!) {\n    getPropertyKeysOfEntity(entityId: $datasetId, entityType: DATASET)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserWorkspaceFiltersList($search: String!) {\n    getUserWorkspaceList(filters: { searchFilter: { search: $search, fields: ["name"] } }) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserWorkspaceFiltersList($search: String!) {\n    getUserWorkspaceList(filters: { searchFilter: { search: $search, fields: ["name"] } }) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUniqueAlgorithmNames(\n    $workspaceIdList: [VecticeId!]!\n    $modelIdList: [VecticeId!]\n    $search: String!\n    $projectIdList: [VecticeId!]\n  ) {\n    getUniqueAlgorithmNames(\n      workspaceIdList: $workspaceIdList\n      modelIdList: $modelIdList\n      projectIdList: $projectIdList\n      filters: { search: $search }\n    ) {\n      items {\n        algorithmName\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUniqueAlgorithmNames(\n    $workspaceIdList: [VecticeId!]!\n    $modelIdList: [VecticeId!]\n    $search: String!\n    $projectIdList: [VecticeId!]\n  ) {\n    getUniqueAlgorithmNames(\n      workspaceIdList: $workspaceIdList\n      modelIdList: $modelIdList\n      projectIdList: $projectIdList\n      filters: { search: $search }\n    ) {\n      items {\n        algorithmName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUniqueInventoryReferences(\n    $workspaceIdList: [VecticeId!]!\n    $modelIdList: [VecticeId!]\n    $search: String!\n    $projectIdList: [VecticeId!]\n  ) {\n    getUniqueInventoryReferences(\n      workspaceIdList: $workspaceIdList\n      modelIdList: $modelIdList\n      projectIdList: $projectIdList\n      filters: { search: $search }\n    ) {\n      items {\n        inventoryReference\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUniqueInventoryReferences(\n    $workspaceIdList: [VecticeId!]!\n    $modelIdList: [VecticeId!]\n    $search: String!\n    $projectIdList: [VecticeId!]\n  ) {\n    getUniqueInventoryReferences(\n      workspaceIdList: $workspaceIdList\n      modelIdList: $modelIdList\n      projectIdList: $projectIdList\n      filters: { search: $search }\n    ) {\n      items {\n        inventoryReference\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeFinding($id: Float!) {\n    removeFinding(id: $id)\n  }\n',
): (typeof documents)['\n  mutation removeFinding($id: Float!) {\n    removeFinding(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRecentProjects {\n    getProjectList(\n      filters: { myRecent: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: 6, index: 1 }\n    ) {\n      items {\n        vecticeId\n        templateUsed\n        ...projectCard\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getRecentProjects {\n    getProjectList(\n      filters: { myRecent: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: 6, index: 1 }\n    ) {\n      items {\n        vecticeId\n        templateUsed\n        ...projectCard\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserIterations($afterCursor: String, $pageSize: Int!) {\n    getIterationList(\n      filters: { onlyMine: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        vecticeId\n        ...iterationListItem\n        phase {\n          vecticeId\n          parent {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              type\n              name\n            }\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserIterations($afterCursor: String, $pageSize: Int!) {\n    getIterationList(\n      filters: { onlyMine: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        vecticeId\n        ...iterationListItem\n        phase {\n          vecticeId\n          parent {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              type\n              name\n            }\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAffectedReviews($afterCursor: String, $pageSize: Int!) {\n    getReviewList(\n      filters: { status: [Pending], affectedToMe: true }\n      order: { field: "createdDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        id\n        ...reviewListItem\n        phase {\n          vecticeId\n          parent {\n            vecticeId\n            workspace {\n              vecticeId\n              type\n              name\n            }\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getAffectedReviews($afterCursor: String, $pageSize: Int!) {\n    getReviewList(\n      filters: { status: [Pending], affectedToMe: true }\n      order: { field: "createdDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        id\n        ...reviewListItem\n        phase {\n          vecticeId\n          parent {\n            vecticeId\n            workspace {\n              vecticeId\n              type\n              name\n            }\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserPhases($afterCursor: String, $pageSize: Int!) {\n    getPhaseList(\n      filters: { status: [NotStarted, Draft, InReview], onlyMine: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        vecticeId\n        ...phaseListItem\n        parent {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            type\n            name\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserPhases($afterCursor: String, $pageSize: Int!) {\n    getPhaseList(\n      filters: { status: [NotStarted, Draft, InReview], onlyMine: true }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        vecticeId\n        ...phaseListItem\n        parent {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            type\n            name\n          }\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserReports($contributors: [ID!], $afterCursor: String, $pageSize: Int!, $advancedFilters: [GroupFilter!]) {\n    CDTReports(\n      filters: { contributors: $contributors, advancedFilters: $advancedFilters }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        id\n        ...reportListItem\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserReports($contributors: [ID!], $afterCursor: String, $pageSize: Int!, $advancedFilters: [GroupFilter!]) {\n    CDTReports(\n      filters: { contributors: $contributors, advancedFilters: $advancedFilters }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      items {\n        id\n        ...reportListItem\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment IterationSidebar on Iteration {\n    vecticeId\n    status\n    description\n    phase {\n      vecticeId\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n          type\n        }\n      }\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment IterationSidebar on Iteration {\n    vecticeId\n    status\n    description\n    phase {\n      vecticeId\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n          type\n        }\n      }\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment IterationStepArtifact on IterationStepArtifact {\n    id\n    text\n    type\n    index\n    datasetVersion {\n      vecticeId\n      attachments(page: { size: 100, index: 1 }) {\n        items {\n          ...entityFileFragment\n        }\n      }\n    }\n    entityFileId\n    entityFile {\n      ...entityFileFragment\n    }\n    modelVersion {\n      vecticeId\n    }\n    entityMetadata {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment IterationStepArtifact on IterationStepArtifact {\n    id\n    text\n    type\n    index\n    datasetVersion {\n      vecticeId\n      attachments(page: { size: 100, index: 1 }) {\n        items {\n          ...entityFileFragment\n        }\n      }\n    }\n    entityFileId\n    entityFile {\n      ...entityFileFragment\n    }\n    modelVersion {\n      vecticeId\n    }\n    entityMetadata {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment IterationStep on IterationStep {\n    id\n    name\n    description\n    updatedDate\n    paginatedArtifacts(page: { index: 1, size: 100 }) {\n      total\n      items {\n        id\n        stepId\n        type\n        index\n        ...IterationStepArtifact\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment IterationStep on IterationStep {\n    id\n    name\n    description\n    updatedDate\n    paginatedArtifacts(page: { index: 1, size: 100 }) {\n      total\n      items {\n        id\n        stepId\n        type\n        index\n        ...IterationStepArtifact\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query iterations($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getIterationList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        starred\n        status\n        createdDate\n        updatedDate\n        phaseId\n        phase {\n          vecticeId\n          name\n        }\n        owner {\n          ...userFields\n        }\n        createdBy {\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query iterations($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getIterationList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        starred\n        status\n        createdDate\n        updatedDate\n        phaseId\n        phase {\n          vecticeId\n          name\n        }\n        owner {\n          ...userFields\n        }\n        createdBy {\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment lineageAssetFragment on LineageArtifact {\n    id\n    artifactType\n    dataSetVersion {\n      vecticeId\n      versionNumber\n      dataSet {\n        name\n        vecticeId\n      }\n    }\n    modelVersion {\n      vecticeId\n      versionNumber\n      model {\n        name\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment lineageAssetFragment on LineageArtifact {\n    id\n    artifactType\n    dataSetVersion {\n      vecticeId\n      versionNumber\n      dataSet {\n        name\n        vecticeId\n      }\n    }\n    modelVersion {\n      vecticeId\n      versionNumber\n      model {\n        name\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment lineageCodeFragment on LineageArtifact {\n    codeVersion {\n      id\n      code {\n        name\n        description\n      }\n      gitVersion {\n        branchName\n        commitHash\n        repositoryName\n        uri\n        entrypoint\n        isDirty\n        additionalInformation\n      }\n      databricksVersion {\n        urlNotebook\n        relativePath\n        timestamp\n        additionalInformation\n      }\n      otherCodeVersion {\n        id\n        url\n        firstAdditionalInformation\n        secondAdditionalInformation\n        thirdAdditionalInformation\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment lineageCodeFragment on LineageArtifact {\n    codeVersion {\n      id\n      code {\n        name\n        description\n      }\n      gitVersion {\n        branchName\n        commitHash\n        repositoryName\n        uri\n        entrypoint\n        isDirty\n        additionalInformation\n      }\n      databricksVersion {\n        urlNotebook\n        relativePath\n        timestamp\n        additionalInformation\n      }\n      otherCodeVersion {\n        id\n        url\n        firstAdditionalInformation\n        secondAdditionalInformation\n        thirdAdditionalInformation\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeCDTReport($id: Float!) {\n    removeCDTReport(id: $id)\n  }\n',
): (typeof documents)['\n  mutation removeCDTReport($id: Float!) {\n    removeCDTReport(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteDataset($datasetId: VecticeId!) {\n    deleteDataset(datasetId: $datasetId)\n  }\n',
): (typeof documents)['\n  mutation deleteDataset($datasetId: VecticeId!) {\n    deleteDataset(datasetId: $datasetId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteDatasetVersion($datasetVersionId: VecticeId!) {\n    deleteDatasetVersion(datasetVersionId: $datasetVersionId)\n  }\n',
): (typeof documents)['\n  mutation deleteDatasetVersion($datasetVersionId: VecticeId!) {\n    deleteDatasetVersion(datasetVersionId: $datasetVersionId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeEmailDomainFromOrganization($organizationId: Float!, $emailDomain: String!) {\n    removeEmailDomainFromOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {\n      id\n      emailDomains {\n        domain\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation removeEmailDomainFromOrganization($organizationId: Float!, $emailDomain: String!) {\n    removeEmailDomainFromOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {\n      id\n      emailDomains {\n        domain\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeIteration($iterationId: VecticeId!) {\n    removeIteration(id: $iterationId)\n  }\n',
): (typeof documents)['\n  mutation removeIteration($iterationId: VecticeId!) {\n    removeIteration(id: $iterationId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteModel($modelId: VecticeId!) {\n    deleteModel(modelId: $modelId)\n  }\n',
): (typeof documents)['\n  mutation deleteModel($modelId: VecticeId!) {\n    deleteModel(modelId: $modelId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteModelVersion($modelVersionId: VecticeId!) {\n    deleteModelVersion(modelVersionId: $modelVersionId)\n  }\n',
): (typeof documents)['\n  mutation deleteModelVersion($modelVersionId: VecticeId!) {\n    deleteModelVersion(modelVersionId: $modelVersionId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removePhase($phaseId: VecticeId!) {\n    removePhase(id: $phaseId)\n  }\n',
): (typeof documents)['\n  mutation removePhase($phaseId: VecticeId!) {\n    removePhase(id: $phaseId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation duplicateCDTReport($id: Float!, $duplicateInput: BaseDocumentationDuplicateInput!) {\n    duplicateCDTReport(id: $id, duplicateInput: $duplicateInput) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation duplicateCDTReport($id: Float!, $duplicateInput: BaseDocumentationDuplicateInput!) {\n    duplicateCDTReport(id: $id, duplicateInput: $duplicateInput) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation duplicatePhase($id: VecticeId!, $duplicateInput: PhaseDuplicateInput!) {\n    duplicatePhase(id: $id, duplicateInput: $duplicateInput) {\n      vecticeId\n    }\n  }\n',
): (typeof documents)['\n  mutation duplicatePhase($id: VecticeId!, $duplicateInput: PhaseDuplicateInput!) {\n    duplicatePhase(id: $id, duplicateInput: $duplicateInput) {\n      vecticeId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkDatasetNameAvailability($datasetId: VecticeId!, $projectId: VecticeId!, $name: String!) {\n    checkDatasetNameAvailability(datasetId: $datasetId, projectId: $projectId, name: $name)\n  }\n',
): (typeof documents)['\n  query checkDatasetNameAvailability($datasetId: VecticeId!, $projectId: VecticeId!, $name: String!) {\n    checkDatasetNameAvailability(datasetId: $datasetId, projectId: $projectId, name: $name)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDataset($datasetId: VecticeId!, $data: DatasetUpdateInput!) {\n    updateDataset(datasetId: $datasetId, dataset: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDataset($datasetId: VecticeId!, $data: DatasetUpdateInput!) {\n    updateDataset(datasetId: $datasetId, dataset: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkIterationNameAvailability($parentId: VecticeId!, $name: String!, $resourceId: VecticeId) {\n    checkIterationNameAvailability(parentId: $parentId, name: $name, resourceId: $resourceId)\n  }\n',
): (typeof documents)['\n  query checkIterationNameAvailability($parentId: VecticeId!, $name: String!, $resourceId: VecticeId) {\n    checkIterationNameAvailability(parentId: $parentId, name: $name, resourceId: $resourceId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateIteration($id: VecticeId!, $data: IterationUpdateInput!) {\n    updateIteration(id: $id, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateIteration($id: VecticeId!, $data: IterationUpdateInput!) {\n    updateIteration(id: $id, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkModelNameAvailability($modelId: VecticeId!, $projectId: VecticeId!, $name: String!) {\n    checkModelNameAvailability(modelId: $modelId, projectId: $projectId, name: $name)\n  }\n',
): (typeof documents)['\n  query checkModelNameAvailability($modelId: VecticeId!, $projectId: VecticeId!, $name: String!) {\n    checkModelNameAvailability(modelId: $modelId, projectId: $projectId, name: $name)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModel($modelId: VecticeId!, $data: ModelUpdateInput!) {\n    updateModel(modelId: $modelId, model: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModel($modelId: VecticeId!, $data: ModelUpdateInput!) {\n    updateModel(modelId: $modelId, model: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCDTReport($id: Float!, $data: CDTReportUpdateInput!) {\n    updateCDTReport(id: $id, data: $data) {\n      id\n      name\n      modelVersion {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateCDTReport($id: Float!, $data: CDTReportUpdateInput!) {\n    updateCDTReport(id: $id, data: $data) {\n      id\n      name\n      modelVersion {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPhase($parentId: VecticeId!, $createModel: PhaseCreateInput!) {\n    createPhase(parentId: $parentId, createModel: $createModel) {\n      vecticeId\n    }\n  }\n',
): (typeof documents)['\n  mutation createPhase($parentId: VecticeId!, $createModel: PhaseCreateInput!) {\n    createPhase(parentId: $parentId, createModel: $createModel) {\n      vecticeId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation moveProject($projectId: VecticeId!, $projectName: String!, $targetWorkspaceId: VecticeId!) {\n    moveProject(projectMoveInput: { id: $projectId, name: $projectName, targetWorkspaceId: $targetWorkspaceId }) {\n      vecticeId\n      workspace {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation moveProject($projectId: VecticeId!, $projectName: String!, $targetWorkspaceId: VecticeId!) {\n    moveProject(projectMoveInput: { id: $projectId, name: $projectName, targetWorkspaceId: $targetWorkspaceId }) {\n      vecticeId\n      workspace {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment modelVersionInformation on ModelVersion {\n    vecticeId\n    context {\n      library\n      url\n      run\n      extraInfo {\n        key\n        value\n        displayName\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment modelVersionInformation on ModelVersion {\n    vecticeId\n    context {\n      library\n      url\n      run\n      extraInfo {\n        key\n        value\n        displayName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionOverview($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      origin {\n        id\n        datasetInputs\n        iteration {\n          vecticeId\n        }\n      }\n      model {\n        vecticeId\n        project {\n          vecticeId\n        }\n      }\n      ...modelVersionInformation\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionOverview($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      origin {\n        id\n        datasetInputs\n        iteration {\n          vecticeId\n        }\n      }\n      model {\n        vecticeId\n        project {\n          vecticeId\n        }\n      }\n      ...modelVersionInformation\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ModelVersionSidebar on ModelVersion {\n    vecticeId\n    description\n    algorithmName\n    status\n    risk\n    approval\n    inventoryReference\n    model {\n      vecticeId\n      type\n      project {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n    origins {\n      phase {\n        vecticeId\n        name\n      }\n      iteration {\n        vecticeId\n        name\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment ModelVersionSidebar on ModelVersion {\n    vecticeId\n    description\n    algorithmName\n    status\n    risk\n    approval\n    inventoryReference\n    model {\n      vecticeId\n      type\n      project {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n    origins {\n      phase {\n        vecticeId\n        name\n      }\n      iteration {\n        vecticeId\n        name\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelVersionDescription($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelVersionDescription($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelVersionTechnique($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      algorithmName\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelVersionTechnique($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      algorithmName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelVersionInventory($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      inventoryReference\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelVersionInventory($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {\n      vecticeId\n      inventoryReference\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelVersionRisk($modelVersionId: VecticeId!, $risk: ModelVersionRisk!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { risk: $risk }) {\n      vecticeId\n      risk\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelVersionRisk($modelVersionId: VecticeId!, $risk: ModelVersionRisk!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { risk: $risk }) {\n      vecticeId\n      risk\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelVersionApproval($modelVersionId: VecticeId!, $approval: ModelVersionApproval!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { approval: $approval }) {\n      vecticeId\n      approval\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelVersionApproval($modelVersionId: VecticeId!, $approval: ModelVersionApproval!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { approval: $approval }) {\n      vecticeId\n      approval\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addToStarredModelVersions($resource: StarredResourceSettings!) {\n    addToStarredModelVersions(resource: $resource) {\n      vecticeId\n      isStarred\n    }\n  }\n',
): (typeof documents)['\n  mutation addToStarredModelVersions($resource: StarredResourceSettings!) {\n    addToStarredModelVersions(resource: $resource) {\n      vecticeId\n      isStarred\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getMetricKeysOfEntity($modelId: VecticeId!) {\n    getMetricKeysOfEntity(entityId: $modelId, entityType: MODEL)\n  }\n',
): (typeof documents)['\n  query getMetricKeysOfEntity($modelId: VecticeId!) {\n    getMetricKeysOfEntity(entityId: $modelId, entityType: MODEL)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionsList(\n    $modelId: VecticeId!\n    $search: String\n    $order: ListOrderInput\n    $pageIndex: Int!\n    $pageSize: Int!\n    $showStarred: Boolean\n    $createdBy: [ID!]\n    $contributors: [ID!]\n    $algorithmNames: [String!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $risks: [ModelVersionRisk!]\n    $approvals: [ModelVersionApproval!]\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getModelVersionsList(\n      modelId: $modelId\n      filters: {\n        createdBy: $createdBy\n        contributors: $contributors\n        modelVersionStatus: $modelVersionStatus\n        risks: $risks\n        approvals: $approvals\n        search: $search\n        showStarred: $showStarred\n        algorithmNames: $algorithmNames\n        advancedFilters: $advancedFilters\n      }\n      order: $order\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n        status\n        risk\n        approval\n        algorithmName\n        inventoryReference\n        origin {\n          id\n        }\n        properties {\n          ...propertiesFields\n        }\n        metrics {\n          ...metricsFields\n        }\n        createdBy {\n          ...userFields\n        }\n        createdDate\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionsList(\n    $modelId: VecticeId!\n    $search: String\n    $order: ListOrderInput\n    $pageIndex: Int!\n    $pageSize: Int!\n    $showStarred: Boolean\n    $createdBy: [ID!]\n    $contributors: [ID!]\n    $algorithmNames: [String!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $risks: [ModelVersionRisk!]\n    $approvals: [ModelVersionApproval!]\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getModelVersionsList(\n      modelId: $modelId\n      filters: {\n        createdBy: $createdBy\n        contributors: $contributors\n        modelVersionStatus: $modelVersionStatus\n        risks: $risks\n        approvals: $approvals\n        search: $search\n        showStarred: $showStarred\n        algorithmNames: $algorithmNames\n        advancedFilters: $advancedFilters\n      }\n      order: $order\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        versionNumber\n        isStarred\n        status\n        risk\n        approval\n        algorithmName\n        inventoryReference\n        origin {\n          id\n        }\n        properties {\n          ...propertiesFields\n        }\n        metrics {\n          ...metricsFields\n        }\n        createdBy {\n          ...userFields\n        }\n        createdDate\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPropertyKeysOfModel($modelId: VecticeId!) {\n    getPropertyKeysOfEntity(entityId: $modelId, entityType: MODEL)\n  }\n',
): (typeof documents)['\n  query getPropertyKeysOfModel($modelId: VecticeId!) {\n    getPropertyKeysOfEntity(entityId: $modelId, entityType: MODEL)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n                    fragment NewUserNotification on UserNotification {\n                      id\n                      type\n                      content\n                      createdDate\n                      creator {\n                        id\n                        ...userFields\n                      }\n                      workspace {\n                        vecticeId\n                        name\n                      }\n                    }\n                  ',
): (typeof documents)['\n                    fragment NewUserNotification on UserNotification {\n                      id\n                      type\n                      content\n                      createdDate\n                      creator {\n                        id\n                        ...userFields\n                      }\n                      workspace {\n                        vecticeId\n                        name\n                      }\n                    }\n                  '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query countUnseenUserNotifications {\n    countUnseenUserNotifications\n  }\n',
): (typeof documents)['\n  query countUnseenUserNotifications {\n    countUnseenUserNotifications\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserNotificationList(\n    $order: ListOrderInput\n    $filters: UserNotificationListFiltersInput\n    $afterCursor: String\n    $pageSize: Int!\n  ) {\n    getUserNotificationList(order: $order, page: { size: $pageSize, afterCursor: $afterCursor }, filters: $filters) {\n      items {\n        id\n        type\n        content\n        createdDate\n        creator {\n          id\n          ...userFields\n        }\n        workspace {\n          vecticeId\n          name\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getUserNotificationList(\n    $order: ListOrderInput\n    $filters: UserNotificationListFiltersInput\n    $afterCursor: String\n    $pageSize: Int!\n  ) {\n    getUserNotificationList(order: $order, page: { size: $pageSize, afterCursor: $afterCursor }, filters: $filters) {\n      items {\n        id\n        type\n        content\n        createdDate\n        creator {\n          id\n          ...userFields\n        }\n        workspace {\n          vecticeId\n          name\n        }\n      }\n      page {\n        afterCursor\n        hasNextPage\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation markAllUserNotificationsAsSeen {\n    markAllUserNotificationsAsSeen\n  }\n',
): (typeof documents)['\n  mutation markAllUserNotificationsAsSeen {\n    markAllUserNotificationsAsSeen\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFindingById($id: Float!) {\n    Finding(id: $id) {\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getFindingById($id: Float!) {\n    Finding(id: $id) {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseForMention($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseForMention($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCDTReportForMention($id: Float!) {\n    CDTReport(id: $id) {\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getCDTReportForMention($id: Float!) {\n    CDTReport(id: $id) {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!) {\n    getWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId) {\n      id\n      status\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!) {\n    getWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId) {\n      id\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resolveWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!, $approve: Boolean!) {\n    resolveWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId, approve: $approve) {\n      id\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation resolveWorkspaceAccessRequest($requestId: Float!, $workspaceId: VecticeId!, $approve: Boolean!) {\n    resolveWorkspaceAccessRequest(requestId: $requestId, workspaceId: $workspaceId, approve: $approve) {\n      id\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseForPhaseOwnerChange($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseForPhaseOwnerChange($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectForProjectOwnerChange($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getProjectForProjectOwnerChange($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectForProjectOwnershipTransfer($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getProjectForProjectOwnershipTransfer($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectForProjectStatusChange($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getProjectForProjectStatusChange($id: VecticeId!) {\n    getProjectById(projectId: $id) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseForRequestMention($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseForRequestMention($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription unseenUserNotificationCountChanged {\n    userNotificationChanged {\n      unseenCount\n    }\n  }\n',
): (typeof documents)['\n  subscription unseenUserNotificationCountChanged {\n    userNotificationChanged {\n      unseenCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription userNotificationCreatedUnseen {\n    userNotificationCreatedUnseen {\n      payload {\n        id\n        type\n        content\n        createdDate\n        status\n        creator {\n          ...userFields\n        }\n        workspace {\n          vecticeId\n          name\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  subscription userNotificationCreatedUnseen {\n    userNotificationCreatedUnseen {\n      payload {\n        id\n        type\n        content\n        createdDate\n        status\n        creator {\n          ...userFields\n        }\n        workspace {\n          vecticeId\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseOverview($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      name\n      parent {\n        vecticeId\n      }\n      vecticeId\n      description\n      enforceRequirementsAsSections\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseOverview($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      name\n      parent {\n        vecticeId\n      }\n      vecticeId\n      description\n      enforceRequirementsAsSections\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment PhaseSidebar on Phase {\n    vecticeId\n    status\n    category\n    parent {\n      vecticeId\n      workspace {\n        vecticeId\n        type\n      }\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment PhaseSidebar on Phase {\n    vecticeId\n    status\n    category\n    parent {\n      vecticeId\n      workspace {\n        vecticeId\n        type\n      }\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query entityMetadataContent($id: Float!) {\n    entityMetadata(id: $id) {\n      id\n      name\n      content\n    }\n  }\n',
): (typeof documents)['\n  query entityMetadataContent($id: Float!) {\n    entityMetadata(id: $id) {\n      id\n      name\n      content\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getInitialAssetCount(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getInitialAssetCount(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query searchProjectAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $advancedFilters: [GroupFilter!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n        items {\n          vecticeId\n          ...projectListItem\n          workspace {\n            vecticeId\n            name\n            type\n          }\n        }\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query searchProjectAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $advancedFilters: [GroupFilter!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n        items {\n          vecticeId\n          ...projectListItem\n          workspace {\n            vecticeId\n            name\n            type\n          }\n        }\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query searchDatasetAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $dsTypes: [DatasetType!]\n    $advancedFilters: [GroupFilter!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n        items {\n          vecticeId\n          ...datasetListItem\n          project {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              name\n              type\n            }\n          }\n        }\n      }\n      model {\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query searchDatasetAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $dsTypes: [DatasetType!]\n    $advancedFilters: [GroupFilter!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n        items {\n          vecticeId\n          ...datasetListItem\n          project {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              name\n              type\n            }\n          }\n        }\n      }\n      model {\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query searchModelAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $types: [ModelType!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $algorithmNames: [String!]\n    $inventoryReferences: [String!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: {\n        search: $search\n        contributors: $contributors\n        types: $types\n        modelVersionStatus: $modelVersionStatus\n        algorithmNames: $algorithmNames\n        inventoryReferences: $inventoryReferences\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n        items {\n          vecticeId\n          ...modelListItem\n          project {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              name\n              type\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query searchModelAssets(\n    $workspaceIdList: [VecticeId!]\n    $assetType: AssetType!\n    $search: String\n    $contributors: [ID!]\n    $types: [ModelType!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $algorithmNames: [String!]\n    $inventoryReferences: [String!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    searchAssets(\n      workspaceIdList: $workspaceIdList\n      assetType: $assetType\n      filters: {\n        search: $search\n        contributors: $contributors\n        types: $types\n        modelVersionStatus: $modelVersionStatus\n        algorithmNames: $algorithmNames\n        inventoryReferences: $inventoryReferences\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      project {\n        total\n      }\n      dataset {\n        total\n      }\n      model {\n        total\n        items {\n          vecticeId\n          ...modelListItem\n          project {\n            vecticeId\n            name\n            workspace {\n              vecticeId\n              name\n              type\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePhaseStatus($phaseId: VecticeId!, $status: DocumentationPageStatus!) {\n    updatePhase(id: $phaseId, updateModel: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePhaseStatus($phaseId: VecticeId!, $status: DocumentationPageStatus!) {\n    updatePhase(id: $phaseId, updateModel: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateIterationStatus($id: VecticeId!, $status: IterationStatus!) {\n    updateIteration(id: $id, data: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation updateIterationStatus($id: VecticeId!, $status: IterationStatus!) {\n    updateIteration(id: $id, data: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelVersionStatus($modelVersionId: VecticeId!, $status: ModelVersionStatus!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelVersionStatus($modelVersionId: VecticeId!, $status: ModelVersionStatus!) {\n    updateModelVersion(modelVersionId: $modelVersionId, data: { status: $status }) {\n      vecticeId\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePhaseCategory($phaseId: VecticeId!, $category: ModelVersionStatus!) {\n    updatePhase(id: $phaseId, updateModel: { category: $category }) {\n      vecticeId\n      category\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePhaseCategory($phaseId: VecticeId!, $category: ModelVersionStatus!) {\n    updatePhase(id: $phaseId, updateModel: { category: $category }) {\n      vecticeId\n      category\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePhaseOwner($phaseId: VecticeId!, $ownerId: Float) {\n    updatePhase(id: $phaseId, updateModel: { ownerId: $ownerId }) {\n      vecticeId\n      ownerId\n      owner {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePhaseOwner($phaseId: VecticeId!, $ownerId: Float) {\n    updatePhase(id: $phaseId, updateModel: { ownerId: $ownerId }) {\n      vecticeId\n      ownerId\n      owner {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription subscribeToUnresolvedFileComments($fileId: Float!) {\n    subscribeToFileComments(fileId: $fileId) {\n      fileId\n      threadId\n      status\n      isDeleted\n      replyId\n    }\n  }\n',
): (typeof documents)['\n  subscription subscribeToUnresolvedFileComments($fileId: Float!) {\n    subscribeToFileComments(fileId: $fileId) {\n      fileId\n      threadId\n      status\n      isDeleted\n      replyId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query threadsUnresolvedCount($id: Float!, $pageSize: Int!) {\n    threads(\n      filters: { fileId: $id, status: UNRESOLVED }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: DESC }\n    ) {\n      items {\n        id\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query threadsUnresolvedCount($id: Float!, $pageSize: Int!) {\n    threads(\n      filters: { fileId: $id, status: UNRESOLVED }\n      page: { size: $pageSize }\n      order: { field: "createdDate", direction: DESC }\n    ) {\n      items {\n        id\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEntityMetric($entityId: VecticeId!, $createMetric: CreateEntityMetricInput!) {\n    createEntityMetric(entityId: $entityId, entityType: modelVersion, createMetric: $createMetric) {\n      ...metricsFields\n    }\n  }\n',
): (typeof documents)['\n  mutation createEntityMetric($entityId: VecticeId!, $createMetric: CreateEntityMetricInput!) {\n    createEntityMetric(entityId: $entityId, entityType: modelVersion, createMetric: $createMetric) {\n      ...metricsFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createEntityProperty(\n    $entityId: VecticeId!\n    $entityType: EntityPropertyType!\n    $createProperty: CreateEntityPropertyInput!\n  ) {\n    createEntityProperty(entityId: $entityId, entityType: $entityType, createProperty: $createProperty) {\n      ...propertiesFields\n    }\n  }\n',
): (typeof documents)['\n  mutation createEntityProperty(\n    $entityId: VecticeId!\n    $entityType: EntityPropertyType!\n    $createProperty: CreateEntityPropertyInput!\n  ) {\n    createEntityProperty(entityId: $entityId, entityType: $entityType, createProperty: $createProperty) {\n      ...propertiesFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEntityMetrics($metricIds: [Float!]!) {\n    deleteEntityMetrics(entityType: modelVersion, metricIds: $metricIds)\n  }\n',
): (typeof documents)['\n  mutation deleteEntityMetrics($metricIds: [Float!]!) {\n    deleteEntityMetrics(entityType: modelVersion, metricIds: $metricIds)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEntityProperties($entityType: EntityPropertyType!, $propertyIds: [Float!]!) {\n    deleteEntityProperties(entityType: $entityType, propertyIds: $propertyIds)\n  }\n',
): (typeof documents)['\n  mutation deleteEntityProperties($entityType: EntityPropertyType!, $propertyIds: [Float!]!) {\n    deleteEntityProperties(entityType: $entityType, propertyIds: $propertyIds)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityMetricsByParentId(\n    $entityId: VecticeId!\n    $pageSize: Int!\n    $pageIndex: Int!\n    $category: ModelVersionStatus\n  ) {\n    getResource: getEntityMetricsByParentId(\n      entityId: $entityId\n      entityType: modelVersion\n      page: { size: $pageSize, index: $pageIndex }\n      order: { field: "key", direction: ASC }\n      category: $category\n    ) {\n      items {\n        ...metricsFields\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getEntityMetricsByParentId(\n    $entityId: VecticeId!\n    $pageSize: Int!\n    $pageIndex: Int!\n    $category: ModelVersionStatus\n  ) {\n    getResource: getEntityMetricsByParentId(\n      entityId: $entityId\n      entityType: modelVersion\n      page: { size: $pageSize, index: $pageIndex }\n      order: { field: "key", direction: ASC }\n      category: $category\n    ) {\n      items {\n        ...metricsFields\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityPropertiesByParentId(\n    $entityType: EntityPropertyType!\n    $entityId: VecticeId!\n    $pageSize: Int!\n    $pageIndex: Int!\n    $category: ModelVersionStatus\n  ) {\n    getResource: getEntityPropertiesByParentId(\n      entityType: $entityType\n      entityId: $entityId\n      page: { size: $pageSize, index: $pageIndex }\n      order: { field: "key", direction: ASC }\n      category: $category\n    ) {\n      total\n      items {\n        ...propertiesFields\n      }\n      page {\n        ...pageIndexFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getEntityPropertiesByParentId(\n    $entityType: EntityPropertyType!\n    $entityId: VecticeId!\n    $pageSize: Int!\n    $pageIndex: Int!\n    $category: ModelVersionStatus\n  ) {\n    getResource: getEntityPropertiesByParentId(\n      entityType: $entityType\n      entityId: $entityId\n      page: { size: $pageSize, index: $pageIndex }\n      order: { field: "key", direction: ASC }\n      category: $category\n    ) {\n      total\n      items {\n        ...propertiesFields\n      }\n      page {\n        ...pageIndexFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEntityMetric($updateMetric: UpdateEntityMetricInput!) {\n    updateEntityMetric(entityType: modelVersion, updateMetric: $updateMetric) {\n      ...metricsFields\n    }\n  }\n',
): (typeof documents)['\n  mutation updateEntityMetric($updateMetric: UpdateEntityMetricInput!) {\n    updateEntityMetric(entityType: modelVersion, updateMetric: $updateMetric) {\n      ...metricsFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEntityProperty($entityType: EntityPropertyType!, $updateProperty: UpdateEntityPropertyInput!) {\n    updateEntityProperty(entityType: $entityType, updateProperty: $updateProperty) {\n      ...propertiesFields\n    }\n  }\n',
): (typeof documents)['\n  mutation updateEntityProperty($entityType: EntityPropertyType!, $updateProperty: UpdateEntityPropertyInput!) {\n    updateEntityProperty(entityType: $entityType, updateProperty: $updateProperty) {\n      ...propertiesFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation askUserTypeUpgrade {\n    askUserTypeUpgrade\n  }\n',
): (typeof documents)['\n  mutation askUserTypeUpgrade {\n    askUserTypeUpgrade\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspaceCard on Workspace {\n    vecticeId\n    name\n    type\n    description\n    userList(filters: { includeDisabledUsers: false }) {\n      total\n      items {\n        id\n        ...userFields\n      }\n    }\n    projectCount\n    updatedDate\n    userContext {\n      accessibility\n    }\n  }\n',
): (typeof documents)['\n  fragment WorkspaceCard on Workspace {\n    vecticeId\n    name\n    type\n    description\n    userList(filters: { includeDisabledUsers: false }) {\n      total\n      items {\n        id\n        ...userFields\n      }\n    }\n    projectCount\n    updatedDate\n    userContext {\n      accessibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment WorkspaceGrid on PaginatedWorkspaceResponseOutput {\n    items {\n      vecticeId\n      ...WorkspaceCard\n    }\n  }\n',
): (typeof documents)['\n  fragment WorkspaceGrid on PaginatedWorkspaceResponseOutput {\n    items {\n      vecticeId\n      ...WorkspaceCard\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrganizationWorkspaceList(\n    $pageIndex: Int!\n    $pageSize: Int!\n    $order: ListOrderInput\n    $search: String!\n    $type: [WorkspaceType!]\n  ) {\n    getOrganizationWorkspaceList(\n      page: { size: $pageSize, index: $pageIndex }\n      order: $order\n      filters: { type: $type, searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        vecticeId\n        type\n        name\n        description\n        userCount(filters: { includeDisabledUsers: false })\n        projectCount\n        updatedDate\n        createdDate\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getOrganizationWorkspaceList(\n    $pageIndex: Int!\n    $pageSize: Int!\n    $order: ListOrderInput\n    $search: String!\n    $type: [WorkspaceType!]\n  ) {\n    getOrganizationWorkspaceList(\n      page: { size: $pageSize, index: $pageIndex }\n      order: $order\n      filters: { type: $type, searchFilter: { search: $search, fields: ["name"] } }\n    ) {\n      items {\n        vecticeId\n        type\n        name\n        description\n        userCount(filters: { includeDisabledUsers: false })\n        projectCount\n        updatedDate\n        createdDate\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPublicConfig {\n    getPublicConfig {\n      sendEmail\n      isTrialEnabled\n      versions {\n        artifactName\n        version\n      }\n      featureFlags {\n        code\n        enabled\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPublicConfig {\n    getPublicConfig {\n      sendEmail\n      isTrialEnabled\n      versions {\n        artifactName\n        version\n      }\n      featureFlags {\n        code\n        enabled\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrgConfig {\n    getOrgConfig {\n      deployment\n      sendEmail\n      isTrialEnabled\n      versions {\n        artifactName\n        version\n      }\n      featureFlags {\n        code\n        enabled\n      }\n      organization {\n        id\n        name\n        level\n        displayName\n        emailDomains {\n          domain\n        }\n        configuration {\n          passwordAuthenticationEnabled\n          autoProvisioning\n          emailNotificationEnabled\n          dfStatisticsRowThreshold\n          dfStatisticsSampleRows\n          dfStatisticsMaxColumns\n          everyOneCanCreateWorkspace\n          everyOneCanInviteUser\n        }\n        licenseOptions {\n          isSingleSignOnEnabled\n          isAskAIEnabled\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getOrgConfig {\n    getOrgConfig {\n      deployment\n      sendEmail\n      isTrialEnabled\n      versions {\n        artifactName\n        version\n      }\n      featureFlags {\n        code\n        enabled\n      }\n      organization {\n        id\n        name\n        level\n        displayName\n        emailDomains {\n          domain\n        }\n        configuration {\n          passwordAuthenticationEnabled\n          autoProvisioning\n          emailNotificationEnabled\n          dfStatisticsRowThreshold\n          dfStatisticsSampleRows\n          dfStatisticsMaxColumns\n          everyOneCanCreateWorkspace\n          everyOneCanInviteUser\n        }\n        licenseOptions {\n          isSingleSignOnEnabled\n          isAskAIEnabled\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment fileHistoryEntry on FileHistoryWithSessionOutput {\n    sessionUuid\n    fileHistoryId\n    user {\n      ...userFields\n    }\n    numberOfHistoriesInSession\n    createdDate\n  }\n',
): (typeof documents)['\n  fragment fileHistoryEntry on FileHistoryWithSessionOutput {\n    sessionUuid\n    fileHistoryId\n    user {\n      ...userFields\n    }\n    numberOfHistoriesInSession\n    createdDate\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFileHistoryList($fileId: Float!, $afterCursor: String, $filters: FileHistoryFilter) {\n    getFileHistoryList(\n      fileId: $fileId\n      filters: $filters\n      order: { field: "createdDate", direction: DESC }\n      page: { size: 20, afterCursor: $afterCursor }\n    ) {\n      page {\n        hasNextPage\n        afterCursor\n      }\n      items {\n        ...fileHistoryEntry\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFileHistoryList($fileId: Float!, $afterCursor: String, $filters: FileHistoryFilter) {\n    getFileHistoryList(\n      fileId: $fileId\n      filters: $filters\n      order: { field: "createdDate", direction: DESC }\n      page: { size: 20, afterCursor: $afterCursor }\n    ) {\n      page {\n        hasNextPage\n        afterCursor\n      }\n      items {\n        ...fileHistoryEntry\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFileHistory($fileHistoryId: String!) {\n    getFileHistory(fileHistoryId: $fileHistoryId) {\n      id\n      createdDate\n      content\n    }\n  }\n',
): (typeof documents)['\n  query getFileHistory($fileHistoryId: String!) {\n    getFileHistory(fileHistoryId: $fileHistoryId) {\n      id\n      createdDate\n      content\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation restoreHistory($fileHistoryId: String!) {\n    restoreHistory(fileHistoryId: $fileHistoryId)\n  }\n',
): (typeof documents)['\n  mutation restoreHistory($fileHistoryId: String!) {\n    restoreHistory(fileHistoryId: $fileHistoryId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCDTReportFile($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      id\n      name\n      file {\n        id\n        content\n        headerContent\n        footerContent\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getCDTReportFile($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      id\n      name\n      file {\n        id\n        content\n        headerContent\n        footerContent\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFindingFile($findingId: Float!) {\n    Finding(id: $findingId) {\n      id\n      name\n      file {\n        id\n        content\n        headerContent\n        footerContent\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFindingFile($findingId: Float!) {\n    Finding(id: $findingId) {\n      id\n      name\n      file {\n        id\n        content\n        headerContent\n        footerContent\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription fileUpdated($fileId: Float!, $source: String) {\n    fileUpdated(fileId: $fileId, source: $source) {\n      id\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  subscription fileUpdated($fileId: Float!, $source: String) {\n    fileUpdated(fileId: $fileId, source: $source) {\n      id\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDocumentationFile($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      parent {\n        id\n        name\n      }\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDocumentationFile($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      parent {\n        id\n        name\n      }\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation unlockFile($fileId: Float!) {\n    unlockFile(fileId: $fileId)\n  }\n',
): (typeof documents)['\n  mutation unlockFile($fileId: Float!) {\n    unlockFile(fileId: $fileId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFileContent($fileId: Float!, $data: FileContentUpdateInput!) {\n    updateFileContent(fileId: $fileId, data: $data) {\n      id\n      updatedDate\n      isFileLocked\n      fingerprint\n      lastUpdatedBy {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFileContent($fileId: Float!, $data: FileContentUpdateInput!) {\n    updateFileContent(fileId: $fileId, data: $data) {\n      id\n      updatedDate\n      isFileLocked\n      fingerprint\n      lastUpdatedBy {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAttachmentsToInsert($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $search: String) {\n    attachments(entityId: $entityId, entityType: $type, search: $search) {\n      id\n      name\n      attachmentType\n      entityId\n      mimeType\n      type\n      content\n      ...attachmentFragment\n    }\n  }\n',
): (typeof documents)['\n  query getAttachmentsToInsert($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $search: String) {\n    attachments(entityId: $entityId, entityType: $type, search: $search) {\n      id\n      name\n      attachmentType\n      entityId\n      mimeType\n      type\n      content\n      ...attachmentFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionsToInsert($datasetId: VecticeId!, $search: String) {\n    getDatasetVersionsList(datasetId: $datasetId, filters: { search: $search }) {\n      items {\n        vecticeId\n        name\n        versionNumber\n        attachments {\n          total\n        }\n        ...datasetVersionRow\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionsToInsert($datasetId: VecticeId!, $search: String) {\n    getDatasetVersionsList(datasetId: $datasetId, filters: { search: $search }) {\n      items {\n        vecticeId\n        name\n        versionNumber\n        attachments {\n          total\n        }\n        ...datasetVersionRow\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetsToInsert($projectId: VecticeId!, $search: String) {\n    getDatasetList(projectId: $projectId, filters: { search: $search }) {\n      items {\n        vecticeId\n        versionCount\n        name\n        ...datasetRow\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetsToInsert($projectId: VecticeId!, $search: String) {\n    getDatasetList(projectId: $projectId, filters: { search: $search }) {\n      items {\n        vecticeId\n        versionCount\n        name\n        ...datasetRow\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationArtifactsCount($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      id\n      paginatedArtifacts(filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationArtifactsCount($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      id\n      paginatedArtifacts(filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationArtifactsToInsert($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      id\n      paginatedArtifacts(page: { index: 1, size: 100 }, filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n        items {\n          id\n          datasetVersion {\n            vecticeId\n            versionNumber\n            dataSet {\n              vecticeId\n              name\n            }\n            attachments {\n              total\n            }\n            ...datasetVersionRow\n          }\n          modelVersion {\n            vecticeId\n            versionNumber\n            model {\n              vecticeId\n              name\n            }\n            attachments {\n              total\n            }\n            ...modelVersionRow\n          }\n          entityFile {\n            id\n            type\n            mimeType\n            entityId\n            ...entityFileFragment\n          }\n          entityMetadata {\n            id\n            name\n            content\n          }\n          text\n          type\n        }\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationArtifactsToInsert($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      id\n      paginatedArtifacts(page: { index: 1, size: 100 }, filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n        items {\n          id\n          datasetVersion {\n            vecticeId\n            versionNumber\n            dataSet {\n              vecticeId\n              name\n            }\n            attachments {\n              total\n            }\n            ...datasetVersionRow\n          }\n          modelVersion {\n            vecticeId\n            versionNumber\n            model {\n              vecticeId\n              name\n            }\n            attachments {\n              total\n            }\n            ...modelVersionRow\n          }\n          entityFile {\n            id\n            type\n            mimeType\n            entityId\n            ...entityFileFragment\n          }\n          entityMetadata {\n            id\n            name\n            content\n          }\n          text\n          type\n        }\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationStepsToInsert($iterationId: VecticeId!) {\n    sections(parentId: $iterationId, order: { field: "index", direction: ASC }) {\n      items {\n        id\n        name\n        paginatedArtifacts(filters: { withAssetDeleted: false }) {\n          total\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getIterationStepsToInsert($iterationId: VecticeId!) {\n    sections(parentId: $iterationId, order: { field: "index", direction: ASC }) {\n      items {\n        id\n        name\n        paginatedArtifacts(filters: { withAssetDeleted: false }) {\n          total\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationStepArtifactsToInsert($stepId: Float!) {\n    getStepById(id: $stepId) {\n      id\n      paginatedArtifacts(page: { index: 1, size: 100 }, filters: { withAssetDeleted: false }) {\n        items {\n          id\n          datasetVersion {\n            vecticeId\n            versionNumber\n            dataSet {\n              vecticeId\n              name\n              type\n            }\n            attachments {\n              total\n            }\n            ...datasetVersionRow\n          }\n          modelVersion {\n            vecticeId\n            versionNumber\n            model {\n              vecticeId\n              type\n              name\n            }\n            attachments {\n              total\n            }\n            ...modelVersionRow\n          }\n          entityFile {\n            id\n            type\n            fileName\n            mimeType\n            entityId\n            ...entityFileFragment\n          }\n          entityMetadata {\n            id\n            name\n            content\n          }\n          text\n          type\n        }\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationStepArtifactsToInsert($stepId: Float!) {\n    getStepById(id: $stepId) {\n      id\n      paginatedArtifacts(page: { index: 1, size: 100 }, filters: { withAssetDeleted: false }) {\n        items {\n          id\n          datasetVersion {\n            vecticeId\n            versionNumber\n            dataSet {\n              vecticeId\n              name\n              type\n            }\n            attachments {\n              total\n            }\n            ...datasetVersionRow\n          }\n          modelVersion {\n            vecticeId\n            versionNumber\n            model {\n              vecticeId\n              type\n              name\n            }\n            attachments {\n              total\n            }\n            ...modelVersionRow\n          }\n          entityFile {\n            id\n            type\n            fileName\n            mimeType\n            entityId\n            ...entityFileFragment\n          }\n          entityMetadata {\n            id\n            name\n            content\n          }\n          text\n          type\n        }\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationsToInsert($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        index\n        name\n        sections {\n          total\n        }\n        paginatedArtifacts(filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n          total\n        }\n        ...iterationRow\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getIterationsToInsert($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        index\n        name\n        sections {\n          total\n        }\n        paginatedArtifacts(filters: { stepStrategy: EXCLUDES, withAssetDeleted: false }) {\n          total\n        }\n        ...iterationRow\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionsToInsert($modelId: VecticeId!, $search: String) {\n    getModelVersionsList(modelId: $modelId, filters: { search: $search }) {\n      items {\n        vecticeId\n        name\n        versionNumber\n        attachments {\n          total\n        }\n        ...modelVersionRow\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionsToInsert($modelId: VecticeId!, $search: String) {\n    getModelVersionsList(modelId: $modelId, filters: { search: $search }) {\n      items {\n        vecticeId\n        name\n        versionNumber\n        attachments {\n          total\n        }\n        ...modelVersionRow\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelsToInsert($projectId: VecticeId!, $search: String) {\n    getModelList(projectId: $projectId, filters: { search: $search }) {\n      items {\n        vecticeId\n        versionCount\n        name\n        ...modelRow\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getModelsToInsert($projectId: VecticeId!, $search: String) {\n    getModelList(projectId: $projectId, filters: { search: $search }) {\n      items {\n        vecticeId\n        versionCount\n        name\n        ...modelRow\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhasesToInsert($projectId: VecticeId!, $search: String) {\n    getPhaseList(filters: { search: $search, parentId: $projectId }, order: { field: "index", direction: ASC }) {\n      items {\n        vecticeId\n        name\n        iterationsCount {\n          total\n        }\n        ...phaseRow\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getPhasesToInsert($projectId: VecticeId!, $search: String) {\n    getPhaseList(filters: { search: $search, parentId: $projectId }, order: { field: "index", direction: ASC }) {\n      items {\n        vecticeId\n        name\n        iterationsCount {\n          total\n        }\n        ...phaseRow\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateDocumentation($data: GenerateDocumentationInput!) {\n    generateDocumentation(data: $data)\n  }\n',
): (typeof documents)['\n  mutation generateDocumentation($data: GenerateDocumentationInput!) {\n    generateDocumentation(data: $data)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SearchAllAskAIAssets(\n    $filters: AskAIAssetListFiltersInput!\n    $assetType: AskAISearchAssetType!\n    $page: PageIndexInput\n  ) {\n    searchAllAskAIAssets(filters: $filters, assetType: $assetType, page: $page) {\n      all {\n        items {\n          name\n          vecticeId\n          versionNumber\n          isStarred\n          type\n        }\n        total\n      }\n      phase {\n        items {\n          name\n          vecticeId\n          version\n        }\n        total\n      }\n      datasetVersion {\n        items {\n          dataSet {\n            name\n            type\n          }\n          vecticeId\n          isStarred\n          versionNumber\n        }\n        total\n      }\n      modelVersion {\n        items {\n          model {\n            name\n            type\n          }\n          vecticeId\n          isStarred\n          versionNumber\n        }\n        total\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SearchAllAskAIAssets(\n    $filters: AskAIAssetListFiltersInput!\n    $assetType: AskAISearchAssetType!\n    $page: PageIndexInput\n  ) {\n    searchAllAskAIAssets(filters: $filters, assetType: $assetType, page: $page) {\n      all {\n        items {\n          name\n          vecticeId\n          versionNumber\n          isStarred\n          type\n        }\n        total\n      }\n      phase {\n        items {\n          name\n          vecticeId\n          version\n        }\n        total\n      }\n      datasetVersion {\n        items {\n          dataSet {\n            name\n            type\n          }\n          vecticeId\n          isStarred\n          versionNumber\n        }\n        total\n      }\n      modelVersion {\n        items {\n          model {\n            name\n            type\n          }\n          vecticeId\n          isStarred\n          versionNumber\n        }\n        total\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationName($id: VecticeId!) {\n    getIterationById(id: $id) {\n      name\n      phase {\n        name\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationName($id: VecticeId!) {\n    getIterationById(id: $id) {\n      name\n      phase {\n        name\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBreadcrumbModelVersion($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      name\n      vecticeId\n      versionNumber\n      model {\n        name\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getBreadcrumbModelVersion($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      name\n      vecticeId\n      versionNumber\n      model {\n        name\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getBreadcrumbDatasetVersion($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      name\n      vecticeId\n      versionNumber\n      dataSet {\n        name\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getBreadcrumbDatasetVersion($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      name\n      vecticeId\n      versionNumber\n      dataSet {\n        name\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationIndex($id: VecticeId!) {\n    getIterationById(id: $id) {\n      index\n      name\n    }\n  }\n',
): (typeof documents)['\n  query getIterationIndex($id: VecticeId!) {\n    getIterationById(id: $id) {\n      index\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkLLMPromptNameAvailability($type: LLMPromptTypeEnum!, $resourceId: Float, $name: String!) {\n    checkLLMPromptNameAvailability(type: $type, resourceId: $resourceId, name: $name)\n  }\n',
): (typeof documents)['\n  query checkLLMPromptNameAvailability($type: LLMPromptTypeEnum!, $resourceId: Float, $name: String!) {\n    checkLLMPromptNameAvailability(type: $type, resourceId: $resourceId, name: $name)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createLLMPrompt($data: LLMPromptCreateInput!) {\n    createLLMPrompt(data: $data) {\n      id\n      type\n      name\n      content\n      updatedDate\n      createdBy {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation createLLMPrompt($data: LLMPromptCreateInput!) {\n    createLLMPrompt(data: $data) {\n      id\n      type\n      name\n      content\n      updatedDate\n      createdBy {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateAssetDocumentation($data: GenerateLLMDocumentationInput!) {\n    generateAssetDocumentation(data: $data) {\n      input\n      outputs\n    }\n  }\n',
): (typeof documents)['\n  mutation generateAssetDocumentation($data: GenerateLLMDocumentationInput!) {\n    generateAssetDocumentation(data: $data) {\n      input\n      outputs\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query llmPrompts($order: ListOrderInput, $filters: LLMPromptFiltersInput!, $page: PageIndexInput) {\n    llmPrompts(filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n        content\n        createdBy {\n          ...userFields\n        }\n        updatedDate\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query llmPrompts($order: ListOrderInput, $filters: LLMPromptFiltersInput!, $page: PageIndexInput) {\n    llmPrompts(filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n        content\n        createdBy {\n          ...userFields\n        }\n        updatedDate\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeLLMPrompt($id: Float!) {\n    removeLLMPrompt(id: $id)\n  }\n',
): (typeof documents)['\n  mutation removeLLMPrompt($id: Float!) {\n    removeLLMPrompt(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateLLMPrompt($data: LLMPromptUpdateInput!, $id: Float!) {\n    updateLLMPrompt(data: $data, id: $id) {\n      id\n      type\n      name\n      content\n    }\n  }\n',
): (typeof documents)['\n  mutation updateLLMPrompt($data: LLMPromptUpdateInput!, $id: Float!) {\n    updateLLMPrompt(data: $data, id: $id) {\n      id\n      type\n      name\n      content\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query datasetImageOrigin($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query datasetImageOrigin($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query iterationImageOrigin($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      name\n    }\n  }\n',
): (typeof documents)['\n  query iterationImageOrigin($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query modelImageOrigin($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query modelImageOrigin($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createUserMentionNotification(\n    $workspaceId: VecticeId!\n    $userId: Float!\n    $content: UserMentionNotificationContentInput!\n  ) {\n    createUserMentionNotification(workspaceId: $workspaceId, userId: $userId, content: $content) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createUserMentionNotification(\n    $workspaceId: VecticeId!\n    $userId: Float!\n    $content: UserMentionNotificationContentInput!\n  ) {\n    createUserMentionNotification(workspaceId: $workspaceId, userId: $userId, content: $content) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getMentionedUser($userId: Float!) {\n    getOrganizationUser(userId: $userId) {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  query getMentionedUser($userId: Float!) {\n    getOrganizationUser(userId: $userId) {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateText($data: GenerateDocumentationInput!) {\n    generateDocumentation(data: $data)\n  }\n',
): (typeof documents)['\n  mutation generateText($data: GenerateDocumentationInput!) {\n    generateDocumentation(data: $data)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment userFields on User {\n    id\n    avatarId\n    name\n    email\n    color\n  }\n',
): (typeof documents)['\n  fragment userFields on User {\n    id\n    avatarId\n    name\n    email\n    color\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment userPreferences on UserPreferencesOutput {\n    onboardingStatus\n  }\n',
): (typeof documents)['\n  fragment userPreferences on UserPreferencesOutput {\n    onboardingStatus\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment modelVersionInformationFields on ModelVersion {\n    vecticeId\n    versionNumber\n    model {\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment modelVersionInformationFields on ModelVersion {\n    vecticeId\n    versionNumber\n    model {\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment metricsFields on EntityMetric {\n    id\n    key\n    value\n  }\n',
): (typeof documents)['\n  fragment metricsFields on EntityMetric {\n    id\n    key\n    value\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment propertiesFields on EntityProperty {\n    id\n    key\n    value\n  }\n',
): (typeof documents)['\n  fragment propertiesFields on EntityProperty {\n    id\n    key\n    value\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment pageAfterCursorFields on PageOutput {\n    afterCursor\n    hasNextPage\n  }\n',
): (typeof documents)['\n  fragment pageAfterCursorFields on PageOutput {\n    afterCursor\n    hasNextPage\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment pageIndexFields on PageOutput {\n    index\n    size\n  }\n',
): (typeof documents)['\n  fragment pageIndexFields on PageOutput {\n    index\n    size\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment entityFileFragment on EntityFile {\n    id\n    fileName\n    mimeType\n    hasPreview\n  }\n',
): (typeof documents)['\n  fragment entityFileFragment on EntityFile {\n    id\n    fileName\n    mimeType\n    hasPreview\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment attachmentFragment on AttachmentOutput {\n    id\n    attachmentType\n    name\n    entityId\n    type\n    content\n    mimeType\n    hasPreview\n  }\n',
): (typeof documents)['\n  fragment attachmentFragment on AttachmentOutput {\n    id\n    attachmentType\n    name\n    entityId\n    type\n    content\n    mimeType\n    hasPreview\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createProject($workspaceId: VecticeId!, $data: ProjectCreateInput!) {\n    createProject(workspaceId: $workspaceId, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation createProject($workspaceId: VecticeId!, $data: ProjectCreateInput!) {\n    createProject(workspaceId: $workspaceId, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateLineage($versionId: VecticeId!, $data: LineageUpdateInput!, $type: LineageType!) {\n    updateLineage(data: $data, type: $type, id: $versionId)\n  }\n',
): (typeof documents)['\n  mutation updateLineage($versionId: VecticeId!, $data: LineageUpdateInput!, $type: LineageType!) {\n    updateLineage(data: $data, type: $type, id: $versionId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateUser($data: UserUpdateInput!) {\n    updateUser(data: $data) {\n      ...userFields\n      about\n    }\n  }\n',
): (typeof documents)['\n  mutation updateUser($data: UserUpdateInput!) {\n    updateUser(data: $data) {\n      ...userFields\n      about\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateUserPreferences($data: UserPreferencesInput!) {\n    updateUser(data: { preferences: $data }) {\n      preferences {\n        ...userPreferences\n        onboardingStatus\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateUserPreferences($data: UserPreferencesInput!) {\n    updateUser(data: { preferences: $data }) {\n      preferences {\n        ...userPreferences\n        onboardingStatus\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addUsersToWorkspace($data: WorkspaceUserInput!) {\n    addUsersToWorkspace(data: $data) {\n      userId\n    }\n  }\n',
): (typeof documents)['\n  mutation addUsersToWorkspace($data: WorkspaceUserInput!) {\n    addUsersToWorkspace(data: $data) {\n      userId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addMyselfToWorkspace($workspaceId: VecticeId!) {\n    addMyselfToWorkspace(workspaceId: $workspaceId) {\n      userId\n    }\n  }\n',
): (typeof documents)['\n  mutation addMyselfToWorkspace($workspaceId: VecticeId!) {\n    addMyselfToWorkspace(workspaceId: $workspaceId) {\n      userId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateApiKey($apiKey: ApiKeyCreateInput!) {\n    generateApiKey(apiKey: $apiKey) {\n      key\n    }\n  }\n',
): (typeof documents)['\n  mutation generateApiKey($apiKey: ApiKeyCreateInput!) {\n    generateApiKey(apiKey: $apiKey) {\n      key\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkIfApiKeyNameExists($name: String!) {\n    checkIfApiKeyNameExists(apiKeyName: $name)\n  }\n',
): (typeof documents)['\n  query checkIfApiKeyNameExists($name: String!) {\n    checkIfApiKeyNameExists(apiKeyName: $name)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getExportedArchivesList {\n    getExportedArchivesList {\n      name\n      path\n      itemCreatedDate\n    }\n  }\n',
): (typeof documents)['\n  query getExportedArchivesList {\n    getExportedArchivesList {\n      name\n      path\n      itemCreatedDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query authenticateWithPassword($email: String!, $password: String!) {\n    authenticateWithPassword(email: $email, password: $password) {\n      realm\n      clientId\n    }\n  }\n',
): (typeof documents)['\n  query authenticateWithPassword($email: String!, $password: String!) {\n    authenticateWithPassword(email: $email, password: $password) {\n      realm\n      clientId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cancelIteration($id: VecticeId!) {\n    updateIteration(id: $id, data: { status: Abandoned }) {\n      vecticeId\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation cancelIteration($id: VecticeId!) {\n    updateIteration(id: $id, data: { status: Abandoned }) {\n      vecticeId\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cancelReview($id: VecticeId!) {\n    cancelReview(id: $id) {\n      vecticeId\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation cancelReview($id: VecticeId!) {\n    cancelReview(id: $id) {\n      vecticeId\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTTemplates($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {\n    CDTTemplates(order: $order, page: $page, filters: $filters) {\n      items {\n        id\n        exportId\n        name\n        description\n        isMacro\n        isRichText\n        enabled\n        groupName\n        organizationId\n        createdBy {\n          ...userFields\n        }\n        updatedDate\n      }\n      page {\n        afterCursor\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query CDTTemplates($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {\n    CDTTemplates(order: $order, page: $page, filters: $filters) {\n      items {\n        id\n        exportId\n        name\n        description\n        isMacro\n        isRichText\n        enabled\n        groupName\n        organizationId\n        createdBy {\n          ...userFields\n        }\n        updatedDate\n      }\n      page {\n        afterCursor\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTTemplatesVariables($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {\n    CDTTemplates(order: $order, page: $page, filters: $filters) {\n      items {\n        id\n        exportId\n        assets {\n          name\n          type\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query CDTTemplatesVariables($order: ListOrderInput, $page: PageInput, $filters: CDTTemplateFilterInput) {\n    CDTTemplates(order: $order, page: $page, filters: $filters) {\n      items {\n        id\n        exportId\n        assets {\n          name\n          type\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTTemplateContent($id: Float, $globalId: String) {\n    CDTTemplate(id: $id, globalId: $globalId) {\n      id\n      exportId\n      name\n      enabled\n      description\n      groupName\n      content\n      isMacro\n      headerContent\n      footerContent\n      organizationId\n      assets {\n        id\n        name\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CDTTemplateContent($id: Float, $globalId: String) {\n    CDTTemplate(id: $id, globalId: $globalId) {\n      id\n      exportId\n      name\n      enabled\n      description\n      groupName\n      content\n      isMacro\n      headerContent\n      footerContent\n      organizationId\n      assets {\n        id\n        name\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCDTReport($projectId: VecticeId!, $data: CDTReportCreateInput!) {\n    createCDTReport(projectId: $projectId, data: $data) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  mutation createCDTReport($projectId: VecticeId!, $data: CDTReportCreateInput!) {\n    createCDTReport(projectId: $projectId, data: $data) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation generateCDTMarkdown($data: CDTGenerateMarkdownInput!) {\n    generateCDTMarkdown(data: $data)\n  }\n',
): (typeof documents)['\n  mutation generateCDTMarkdown($data: CDTGenerateMarkdownInput!) {\n    generateCDTMarkdown(data: $data)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation previewMacro($assets: [CDTReportAssetInput!]!, $content: String!) {\n    previewMacro(assets: $assets, content: $content)\n  }\n',
): (typeof documents)['\n  mutation previewMacro($assets: [CDTReportAssetInput!]!, $content: String!) {\n    previewMacro(assets: $assets, content: $content)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeCDTTemplate($id: Float!) {\n    removeCDTTemplate(id: $id)\n  }\n',
): (typeof documents)['\n  mutation removeCDTTemplate($id: Float!) {\n    removeCDTTemplate(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createCDTTemplate($data: CDTTemplateCreateInput!) {\n    createCDTTemplate(data: $data) {\n      id\n      name\n      enabled\n    }\n  }\n',
): (typeof documents)['\n  mutation createCDTTemplate($data: CDTTemplateCreateInput!) {\n    createCDTTemplate(data: $data) {\n      id\n      name\n      enabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCDTTemplate($id: Float!, $data: CDTTemplateUpdateInput!) {\n    updateCDTTemplate(id: $id, data: $data) {\n      id\n      name\n      enabled\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateCDTTemplate($id: Float!, $data: CDTTemplateUpdateInput!) {\n    updateCDTTemplate(id: $id, data: $data) {\n      id\n      name\n      enabled\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GlobalCDTTemplate($globalId: String) {\n    CDTTemplate(globalId: $globalId) {\n      id\n      exportId\n      name\n      content\n      headerContent\n      footerContent\n    }\n  }\n',
): (typeof documents)['\n  query GlobalCDTTemplate($globalId: String) {\n    CDTTemplate(globalId: $globalId) {\n      id\n      exportId\n      name\n      content\n      headerContent\n      footerContent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkIterationStepNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: Float) {\n    checkIterationStepNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n',
): (typeof documents)['\n  query checkIterationStepNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: Float) {\n    checkIterationStepNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkPhaseNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: VecticeId) {\n    checkPhaseNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n',
): (typeof documents)['\n  query checkPhaseNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: VecticeId) {\n    checkPhaseNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkStepDefinitionNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: Float) {\n    checkStepDefinitionNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n',
): (typeof documents)['\n  query checkStepDefinitionNameAvailability($name: String!, $parentId: VecticeId!, $resourceId: Float) {\n    checkStepDefinitionNameAvailability(name: $name, parentId: $parentId, resourceId: $resourceId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation closeReview($review: ReviewUpdateInput!, $id: VecticeId!) {\n    closeReview(review: $review, id: $id) {\n      vecticeId\n      status\n      ...Review\n    }\n  }\n',
): (typeof documents)['\n  mutation closeReview($review: ReviewUpdateInput!, $id: VecticeId!) {\n    closeReview(review: $review, id: $id) {\n      vecticeId\n      status\n      ...Review\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCodeArtifactByJobRunId($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId) {\n      items {\n        id\n        version\n        lineageArtifactType\n        lineageId\n        artifactType\n        codeVersion {\n          id\n          code {\n            name\n            description\n          }\n          gitVersion {\n            branchName\n            commitHash\n            repositoryName\n            uri\n            entrypoint\n            isDirty\n            additionalInformation\n          }\n          databricksVersion {\n            urlNotebook\n            relativePath\n            timestamp\n            additionalInformation\n          }\n          otherCodeVersion {\n            id\n            url\n            firstAdditionalInformation\n            secondAdditionalInformation\n            thirdAdditionalInformation\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getCodeArtifactByJobRunId($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId) {\n      items {\n        id\n        version\n        lineageArtifactType\n        lineageId\n        artifactType\n        codeVersion {\n          id\n          code {\n            name\n            description\n          }\n          gitVersion {\n            branchName\n            commitHash\n            repositoryName\n            uri\n            entrypoint\n            isDirty\n            additionalInformation\n          }\n          databricksVersion {\n            urlNotebook\n            relativePath\n            timestamp\n            additionalInformation\n          }\n          otherCodeVersion {\n            id\n            url\n            firstAdditionalInformation\n            secondAdditionalInformation\n            thirdAdditionalInformation\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation completeIteration($id: VecticeId!) {\n    updateIteration(id: $id, data: { status: Completed }) {\n      vecticeId\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation completeIteration($id: VecticeId!) {\n    updateIteration(id: $id, data: { status: Completed }) {\n      vecticeId\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createFinding($projectId: VecticeId!, $data: FindingCreateInput!) {\n    createFinding(projectId: $projectId, data: $data) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  mutation createFinding($projectId: VecticeId!, $data: FindingCreateInput!) {\n    createFinding(projectId: $projectId, data: $data) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createIterationStep($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {\n    createIterationStep(parentId: $parentId, createModel: $createModel) {\n      id\n      name\n      description\n      index\n    }\n  }\n',
): (typeof documents)['\n  mutation createIterationStep($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {\n    createIterationStep(parentId: $parentId, createModel: $createModel) {\n      id\n      name\n      description\n      index\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createIterationStepArtifact($id: VecticeId!, $data: IterationStepArtifactInput!) {\n    createIterationStepArtifact(id: $id, data: $data) {\n      id\n      index\n      type\n      text\n      iterationId\n      stepId\n    }\n  }\n',
): (typeof documents)['\n  mutation createIterationStepArtifact($id: VecticeId!, $data: IterationStepArtifactInput!) {\n    createIterationStepArtifact(id: $id, data: $data) {\n      id\n      index\n      type\n      text\n      iterationId\n      stepId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createReviewComment($reviewId: VecticeId!, $content: String!) {\n    createReviewComment(reviewComment: { reviewId: $reviewId, content: $content }) {\n      id\n      createdBy {\n        ...userFields\n      }\n      content\n      createdDate\n    }\n  }\n',
): (typeof documents)['\n  mutation createReviewComment($reviewId: VecticeId!, $content: String!) {\n    createReviewComment(reviewComment: { reviewId: $reviewId, content: $content }) {\n      id\n      createdBy {\n        ...userFields\n      }\n      content\n      createdDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createStepDefinition($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {\n    createStepDefinition(parentId: $parentId, createModel: $createModel) {\n      id\n      name\n      description\n      index\n    }\n  }\n',
): (typeof documents)['\n  mutation createStepDefinition($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {\n    createStepDefinition(parentId: $parentId, createModel: $createModel) {\n      id\n      name\n      description\n      index\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEntityFile($id: Float!) {\n    deleteEntityFile(id: $id)\n  }\n',
): (typeof documents)['\n  mutation deleteEntityFile($id: Float!) {\n    deleteEntityFile(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteEntityMetadata($id: Float!) {\n    deleteEntityMetadata(id: $id)\n  }\n',
): (typeof documents)['\n  mutation deleteEntityMetadata($id: Float!) {\n    deleteEntityMetadata(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation extractEntityFile($id: Float!) {\n    extractEntityFile(id: $id) {\n      images\n      tables\n    }\n  }\n',
): (typeof documents)['\n  mutation extractEntityFile($id: Float!) {\n    extractEntityFile(id: $id) {\n      images\n      tables\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAssetAttachments($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $category: ModelVersionStatus) {\n    attachments(entityId: $entityId, entityType: $type, category: $category) {\n      id\n      type\n      name\n      hasPreview\n      mimeType\n      attachmentType\n      entityId\n      ...attachmentFragment\n    }\n  }\n',
): (typeof documents)['\n  query getAssetAttachments($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $category: ModelVersionStatus) {\n    attachments(entityId: $entityId, entityType: $type, category: $category) {\n      id\n      type\n      name\n      hasPreview\n      mimeType\n      attachmentType\n      entityId\n      ...attachmentFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersion($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersion($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEntityFileList($entityId: VecticeId!, $type: EntityFilesTypeEnum!) {\n    getEntityFileList(entityId: $entityId, type: $type, page: { size: 100, index: 1 }) {\n      items {\n        id\n        fileName\n        hasPreview\n        mimeType\n        ...entityFileFragment\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getEntityFileList($entityId: VecticeId!, $type: EntityFilesTypeEnum!) {\n    getEntityFileList(entityId: $entityId, type: $type, page: { size: 100, index: 1 }) {\n      items {\n        id\n        fileName\n        hasPreview\n        mimeType\n        ...entityFileFragment\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationById($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      index\n      name\n      description\n      status\n      starred\n      phaseId\n      phase {\n        vecticeId\n        name\n        parent {\n          vecticeId\n        }\n      }\n      paginatedArtifacts(filters: { stepStrategy: EXCLUDES }, page: { index: 1, size: 100 }) {\n        total\n        items {\n          id\n          type\n          index\n          stepId\n          ...IterationStepArtifact\n        }\n      }\n      ...IterationSidebar\n    }\n  }\n',
): (typeof documents)['\n  query getIterationById($id: VecticeId!) {\n    getIterationById(id: $id) {\n      vecticeId\n      index\n      name\n      description\n      status\n      starred\n      phaseId\n      phase {\n        vecticeId\n        name\n        parent {\n          vecticeId\n        }\n      }\n      paginatedArtifacts(filters: { stepStrategy: EXCLUDES }, page: { index: 1, size: 100 }) {\n        total\n        items {\n          id\n          type\n          index\n          stepId\n          ...IterationStepArtifact\n        }\n      }\n      ...IterationSidebar\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription subscribeToIterationCount($filters: IterationFiltersInput!) {\n    subscribeToIterationList(filters: $filters) {\n      total\n    }\n  }\n',
): (typeof documents)['\n  subscription subscribeToIterationCount($filters: IterationFiltersInput!) {\n    subscribeToIterationList(filters: $filters) {\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationList($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getIterationList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        starred\n        status\n        createdDate\n        updatedDate\n        phaseId\n        createdBy {\n          ...userFields\n        }\n        owner {\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getIterationList($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getIterationList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        starred\n        status\n        createdDate\n        updatedDate\n        phaseId\n        createdBy {\n          ...userFields\n        }\n        owner {\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationPickerList($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        name\n        index\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationPickerList($filters: IterationFiltersInput!, $order: ListOrderInput) {\n    getIterationList(filters: $filters, order: $order) {\n      items {\n        vecticeId\n        name\n        index\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationStepList($parentId: VecticeId!, $order: ListOrderInput) {\n    sections(parentId: $parentId, order: $order, page: { index: 1, size: 100 }) {\n      items {\n        id\n        index\n        name\n        paginatedArtifacts(page: { index: 1, size: 100 }) {\n          total\n          items {\n            id\n            index\n            stepId\n            type\n            ...IterationStepArtifact\n          }\n        }\n        ...IterationStep\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationStepList($parentId: VecticeId!, $order: ListOrderInput) {\n    sections(parentId: $parentId, order: $order, page: { index: 1, size: 100 }) {\n      items {\n        id\n        index\n        name\n        paginatedArtifacts(page: { index: 1, size: 100 }) {\n          total\n          items {\n            id\n            index\n            stepId\n            type\n            ...IterationStepArtifact\n          }\n        }\n        ...IterationStep\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionName($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionName($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      versionNumber\n      model {\n        vecticeId\n        name\n        project {\n          vecticeId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseById($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      ownerId\n      enforceRequirementsAsSections\n      activeReview {\n        status\n      }\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n          name\n          type\n        }\n      }\n      ...PhaseSidebar\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseById($id: VecticeId!) {\n    getPhaseById(id: $id) {\n      vecticeId\n      name\n      ownerId\n      enforceRequirementsAsSections\n      activeReview {\n        status\n      }\n      parent {\n        vecticeId\n        workspace {\n          vecticeId\n          name\n          type\n        }\n      }\n      ...PhaseSidebar\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseList($filters: PhaseFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getPhaseList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        description\n        status\n        iterationsCount {\n          total\n        }\n        reviewsCount {\n          totalReviews\n        }\n        parent {\n          vecticeId\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseList($filters: PhaseFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getPhaseList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        index\n        name\n        description\n        status\n        iterationsCount {\n          total\n        }\n        reviewsCount {\n          totalReviews\n        }\n        parent {\n          vecticeId\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription subscribeToReview($id: VecticeId!) {\n    subscribeToReview(id: $id) {\n      vecticeId\n      ...Review\n    }\n  }\n',
): (typeof documents)['\n  subscription subscribeToReview($id: VecticeId!) {\n    subscribeToReview(id: $id) {\n      vecticeId\n      ...Review\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReviewCommentList($filters: ReviewCommentFiltersInput!, $page: PageInput) {\n    getReviewCommentList(filters: $filters, order: { field: "createdDate", direction: ASC }, page: $page) {\n      items {\n        id\n        createdBy {\n          ...userFields\n        }\n        createdDate\n        content\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getReviewCommentList($filters: ReviewCommentFiltersInput!, $page: PageInput) {\n    getReviewCommentList(filters: $filters, order: { field: "createdDate", direction: ASC }, page: $page) {\n      items {\n        id\n        createdBy {\n          ...userFields\n        }\n        createdDate\n        content\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  subscription subscribeToReviewCommentList($filters: ReviewCommentFiltersInput!) {\n    subscribeToReviewCommentList(filters: $filters) {\n      items {\n        id\n        createdBy {\n          ...userFields\n        }\n        createdDate\n        content\n      }\n    }\n  }\n',
): (typeof documents)['\n  subscription subscribeToReviewCommentList($filters: ReviewCommentFiltersInput!) {\n    subscribeToReviewCommentList(filters: $filters) {\n      items {\n        id\n        createdBy {\n          ...userFields\n        }\n        createdDate\n        content\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReviewList($filters: ReviewFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getReviewList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        status\n        ...Review\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getReviewList($filters: ReviewFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getReviewList(filters: $filters, order: $order, page: $page) {\n      items {\n        vecticeId\n        status\n        ...Review\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStepDefinitionList($filters: BaseDocumentationListFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getStepDefinitionList(filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        index\n        name\n        slug\n        description\n        parent {\n          vecticeId\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getStepDefinitionList($filters: BaseDocumentationListFiltersInput!, $order: ListOrderInput, $page: PageInput) {\n    getStepDefinitionList(filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        index\n        name\n        slug\n        description\n        parent {\n          vecticeId\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrganizationUsersOfOrganization($filters: UserListFiltersInput) {\n    getOrganizationUsersForUser(filters: $filters) {\n      id\n      role\n      name\n      email\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  query getOrganizationUsersOfOrganization($filters: UserListFiltersInput) {\n    getOrganizationUsersForUser(filters: $filters) {\n      id\n      role\n      name\n      email\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TemplateHeaderFooterContent($id: Float) {\n    CDTTemplate(id: $id) {\n      id\n      name\n      headerContent\n      footerContent\n    }\n  }\n',
): (typeof documents)['\n  query TemplateHeaderFooterContent($id: Float) {\n    CDTTemplate(id: $id) {\n      id\n      name\n      headerContent\n      footerContent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReportHeaderFooterContent($id: Float!) {\n    CDTReport(id: $id) {\n      fileId\n      name\n      file {\n        headerContent\n        footerContent\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ReportHeaderFooterContent($id: Float!) {\n    CDTReport(id: $id) {\n      fileId\n      name\n      file {\n        headerContent\n        footerContent\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FindingHeaderFooterContent($id: Float!) {\n    Finding(id: $id) {\n      fileId\n      name\n      file {\n        headerContent\n        footerContent\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FindingHeaderFooterContent($id: Float!) {\n    Finding(id: $id) {\n      fileId\n      name\n      file {\n        headerContent\n        footerContent\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateCDTHeaderFooterContent($id: Float!, $data: CDTTemplateUpdateInput!) {\n    updateCDTTemplate(id: $id, data: $data) {\n      id\n      content\n      headerContent\n      footerContent\n    }\n  }\n',
): (typeof documents)['\n  mutation updateCDTHeaderFooterContent($id: Float!, $data: CDTTemplateUpdateInput!) {\n    updateCDTTemplate(id: $id, data: $data) {\n      id\n      content\n      headerContent\n      footerContent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDocFileHeaderFooterContent($fileId: Float!, $data: FileHeaderFooterUpdateInput!) {\n    updateFileHeaderFooter(fileId: $fileId, data: $data) {\n      id\n      headerContent\n      footerContent\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDocFileHeaderFooterContent($fileId: Float!, $data: FileHeaderFooterUpdateInput!) {\n    updateFileHeaderFooter(fileId: $fileId, data: $data) {\n      id\n      headerContent\n      footerContent\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIdentityProviderList {\n    getIdentityProviderList {\n      alias\n      ...authMethodCard\n    }\n  }\n',
): (typeof documents)['\n  query getIdentityProviderList {\n    getIdentityProviderList {\n      alias\n      ...authMethodCard\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIdentityProvider($alias: String!) {\n    getIdentityProvider(alias: $alias) {\n      alias\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIdentityProvider($alias: String!) {\n    getIdentityProvider(alias: $alias) {\n      alias\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createIdentityProvider(\n    $identityProvider: KeycloakIdentityProviderCreate!\n    $socialConfig: KeycloakSocialConfigCreate\n    $samlConfig: KeycloakSAMLConfigCreate\n  ) {\n    createIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {\n      alias\n      providerId\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation createIdentityProvider(\n    $identityProvider: KeycloakIdentityProviderCreate!\n    $socialConfig: KeycloakSocialConfigCreate\n    $samlConfig: KeycloakSAMLConfigCreate\n  ) {\n    createIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {\n      alias\n      providerId\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateIdentityProvider(\n    $identityProvider: KeycloakIdentityProviderUpdate!\n    $socialConfig: KeycloakSocialConfigUpdate\n    $samlConfig: KeycloakSAMLConfigUpdate\n  ) {\n    updateIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {\n      alias\n      providerId\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateIdentityProvider(\n    $identityProvider: KeycloakIdentityProviderUpdate!\n    $socialConfig: KeycloakSocialConfigUpdate\n    $samlConfig: KeycloakSAMLConfigUpdate\n  ) {\n    updateIdentityProvider(identityProvider: $identityProvider, socialConfig: $socialConfig, samlConfig: $samlConfig) {\n      alias\n      providerId\n      displayName\n      enabled\n      config {\n        ... on KeycloakSAMLConfig {\n          entityId\n          singleLogoutServiceUrl\n          singleSignOnServiceUrl\n          forceAuthn\n        }\n        ... on KeycloakSocialConfig {\n          clientId\n          clientSecret\n          defaultScope\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getJobArtifactByJobRunId($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId, order: { field: "createdDate", direction: ASC }) {\n      items {\n        id\n        version\n        lineageArtifactType\n        lineageId\n        artifactType\n        ...lineageAssetFragment\n        ...lineageCodeFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getJobArtifactByJobRunId($lineageId: Float!) {\n    getJobArtifactByJobRunId(lineageId: $lineageId, order: { field: "createdDate", direction: ASC }) {\n      items {\n        id\n        version\n        lineageArtifactType\n        lineageId\n        artifactType\n        ...lineageAssetFragment\n        ...lineageCodeFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query logout {\n    logout\n  }\n',
): (typeof documents)['\n  query logout {\n    logout\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation moveArtifacts(\n    $iterationId: VecticeId!\n    $artifactsIdList: [Float!]!\n    $targetStepId: Float\n    $targetStepIndex: Float\n  ) {\n    moveArtifacts(\n      iterationId: $iterationId\n      artifactsIdList: $artifactsIdList\n      targetStepId: $targetStepId\n      targetStepIndex: $targetStepIndex\n    ) {\n      id\n      stepId\n    }\n  }\n',
): (typeof documents)['\n  mutation moveArtifacts(\n    $iterationId: VecticeId!\n    $artifactsIdList: [Float!]!\n    $targetStepId: Float\n    $targetStepIndex: Float\n  ) {\n    moveArtifacts(\n      iterationId: $iterationId\n      artifactsIdList: $artifactsIdList\n      targetStepId: $targetStepId\n      targetStepIndex: $targetStepIndex\n    ) {\n      id\n      stepId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkWorkspaceNameAvailability($name: String!, $exceptId: VecticeId) {\n    checkWorkspaceNameAvailability(name: $name, exceptId: $exceptId)\n  }\n',
): (typeof documents)['\n  query checkWorkspaceNameAvailability($name: String!, $exceptId: VecticeId) {\n    checkWorkspaceNameAvailability(name: $name, exceptId: $exceptId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkProjectNameAvailability($workspaceId: VecticeId!, $projectId: VecticeId, $name: String!) {\n    checkProjectNameAvailability(workspaceId: $workspaceId, projectId: $projectId, name: $name)\n  }\n',
): (typeof documents)['\n  query checkProjectNameAvailability($workspaceId: VecticeId!, $projectId: VecticeId, $name: String!) {\n    checkProjectNameAvailability(workspaceId: $workspaceId, projectId: $projectId, name: $name)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkCDTReportNameAvailability($parentId: VecticeId!, $resourceId: Float, $name: String!) {\n    checkCDTReportNameAvailability(parentId: $parentId, resourceId: $resourceId, name: $name)\n  }\n',
): (typeof documents)['\n  query checkCDTReportNameAvailability($parentId: VecticeId!, $resourceId: Float, $name: String!) {\n    checkCDTReportNameAvailability(parentId: $parentId, resourceId: $resourceId, name: $name)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkCDTTemplateNameAvailability($resourceId: Float, $name: String!, $isRichText: Boolean) {\n    checkCDTTemplateNameAvailability(resourceId: $resourceId, name: $name, isRichText: $isRichText)\n  }\n',
): (typeof documents)['\n  query checkCDTTemplateNameAvailability($resourceId: Float, $name: String!, $isRichText: Boolean) {\n    checkCDTTemplateNameAvailability(resourceId: $resourceId, name: $name, isRichText: $isRichText)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllOrganizations($order: ListOrderInput, $search: String, $levels: [OrganizationLevel!], $page: PageInput) {\n    getAllOrganizations(filters: { search: $search, levels: $levels }, order: $order, page: $page) {\n      items {\n        id\n        name\n        level\n        description\n        createdDate\n        emailDomains {\n          domain\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getAllOrganizations($order: ListOrderInput, $search: String, $levels: [OrganizationLevel!], $page: PageInput) {\n    getAllOrganizations(filters: { search: $search, levels: $levels }, order: $order, page: $page) {\n      items {\n        id\n        name\n        level\n        description\n        createdDate\n        emailDomains {\n          domain\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAccessibilityLevels {\n    getAccessibilityLevels\n  }\n',
): (typeof documents)['\n  query getAccessibilityLevels {\n    getAccessibilityLevels\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation setUserRole($role: UserRole!, $userId: Float!) {\n    setUserRole(role: $role, userId: $userId) {\n      id\n      role\n    }\n  }\n',
): (typeof documents)['\n  mutation setUserRole($role: UserRole!, $userId: Float!) {\n    setUserRole(role: $role, userId: $userId) {\n      id\n      role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation disableUser($userId: Float!) {\n    disableUser(userId: $userId) {\n      id\n      disabled\n    }\n  }\n',
): (typeof documents)['\n  mutation disableUser($userId: Float!) {\n    disableUser(userId: $userId) {\n      id\n      disabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation enableUser($userId: Float!) {\n    enableUser(userId: $userId) {\n      id\n      disabled\n    }\n  }\n',
): (typeof documents)['\n  mutation enableUser($userId: Float!) {\n    enableUser(userId: $userId) {\n      id\n      disabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createOrganization($data: OrganizationCreateInput!) {\n    createOrganization(data: $data) {\n      id\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation createOrganization($data: OrganizationCreateInput!) {\n    createOrganization(data: $data) {\n      id\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateOrganizationDescription($organizationId: ID!, $data: OrganizationUpdateInput!) {\n    updateOrganization(data: $data, organizationId: $organizationId) {\n      id\n      description\n      level\n    }\n  }\n',
): (typeof documents)['\n  mutation updateOrganizationDescription($organizationId: ID!, $data: OrganizationUpdateInput!) {\n    updateOrganization(data: $data, organizationId: $organizationId) {\n      id\n      description\n      level\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteOrganization($organizationId: Float!) {\n    deleteOrganization(organizationId: $organizationId)\n  }\n',
): (typeof documents)['\n  mutation deleteOrganization($organizationId: Float!) {\n    deleteOrganization(organizationId: $organizationId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkOrganizationEmailDomainAvailability($userEmail: String!) {\n    checkOrganizationEmailDomainAvailability(userEmail: $userEmail)\n  }\n',
): (typeof documents)['\n  query checkOrganizationEmailDomainAvailability($userEmail: String!) {\n    checkOrganizationEmailDomainAvailability(userEmail: $userEmail)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRealmInformationFromEmail($email: String!) {\n    getRealmInformationFromEmail(email: $email) {\n      realm\n      clientId\n    }\n  }\n',
): (typeof documents)['\n  query getRealmInformationFromEmail($email: String!) {\n    getRealmInformationFromEmail(email: $email) {\n      realm\n      clientId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation inviteTrialUser($email: String!) {\n    inviteTrialUser(email: $email)\n  }\n',
): (typeof documents)['\n  mutation inviteTrialUser($email: String!) {\n    inviteTrialUser(email: $email)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeIterationStep($id: Float!) {\n    removeIterationStep(id: $id)\n  }\n',
): (typeof documents)['\n  mutation removeIterationStep($id: Float!) {\n    removeIterationStep(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeStepDefinition($id: Float!) {\n    removeStepDefinition(id: $id)\n  }\n',
): (typeof documents)['\n  mutation removeStepDefinition($id: Float!) {\n    removeStepDefinition(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation renameEntityFile($id: Float!, $newName: String!) {\n    renameEntityFile(id: $id, newName: $newName) {\n      id\n      fileName\n    }\n  }\n',
): (typeof documents)['\n  mutation renameEntityFile($id: Float!, $newName: String!) {\n    renameEntityFile(id: $id, newName: $newName) {\n      id\n      fileName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation renameEntityMetadata($id: Float!, $name: String!) {\n    renameEntityMetadata(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  mutation renameEntityMetadata($id: Float!, $name: String!) {\n    renameEntityMetadata(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resetOrganizationFeatureFlag($organizationId: Float!, $code: String!) {\n    resetOrganizationFeatureFlag(organizationId: $organizationId, code: $code) {\n      code\n      enabled\n      organizationId\n      overridden\n    }\n  }\n',
): (typeof documents)['\n  mutation resetOrganizationFeatureFlag($organizationId: Float!, $code: String!) {\n    resetOrganizationFeatureFlag(organizationId: $organizationId, code: $code) {\n      code\n      enabled\n      organizationId\n      overridden\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation restorePhase($id: VecticeId!) {\n    restorePhase(id: $id)\n  }\n',
): (typeof documents)['\n  mutation restorePhase($id: VecticeId!) {\n    restorePhase(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateDatasetType($id: VecticeId!, $type: DatasetType!) {\n    updateDataset(datasetId: $id, dataset: { type: $type }) {\n      vecticeId\n      type\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDatasetType($id: VecticeId!, $type: DatasetType!) {\n    updateDataset(datasetId: $id, dataset: { type: $type }) {\n      vecticeId\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFinding($id: Float!, $data: FindingUpdateInput!) {\n    updateFinding(id: $id, data: $data) {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFinding($id: Float!, $data: FindingUpdateInput!) {\n    updateFinding(id: $id, data: $data) {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingOwner($id: Float!, $ownerId: Float!) {\n    updateFinding(id: $id, data: { ownerId: $ownerId }) {\n      id\n      ownerId\n      owner {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingOwner($id: Float!, $ownerId: Float!) {\n    updateFinding(id: $id, data: { ownerId: $ownerId }) {\n      id\n      ownerId\n      owner {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingAssignee($id: Float!, $assigneeId: Float) {\n    updateFinding(id: $id, data: { reviewerId: $assigneeId }) {\n      id\n      ownerId\n      reviewer {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingAssignee($id: Float!, $assigneeId: Float) {\n    updateFinding(id: $id, data: { reviewerId: $assigneeId }) {\n      id\n      ownerId\n      reviewer {\n        id\n        ...userFields\n        disabled\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingStatus($id: Float!, $status: FindingStatus!) {\n    updateFinding(id: $id, data: { status: $status }) {\n      id\n      status\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingStatus($id: Float!, $status: FindingStatus!) {\n    updateFinding(id: $id, data: { status: $status }) {\n      id\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingSeverity($id: Float!, $severity: FindingSeverity!) {\n    updateFinding(id: $id, data: { severity: $severity }) {\n      id\n      severity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingSeverity($id: Float!, $severity: FindingSeverity!) {\n    updateFinding(id: $id, data: { severity: $severity }) {\n      id\n      severity\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingModelVersion($id: Float!, $modelVersionId: VecticeId) {\n    updateFinding(id: $id, data: { modelVersionId: $modelVersionId }) {\n      id\n      modelVersion {\n        vecticeId\n        ...modelVersionInformationFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingModelVersion($id: Float!, $modelVersionId: VecticeId) {\n    updateFinding(id: $id, data: { modelVersionId: $modelVersionId }) {\n      id\n      modelVersion {\n        vecticeId\n        ...modelVersionInformationFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingReport($id: Float!, $reportId: Float) {\n    updateFinding(id: $id, data: { reportId: $reportId }) {\n      id\n      report {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingReport($id: Float!, $reportId: Float) {\n    updateFinding(id: $id, data: { reportId: $reportId }) {\n      id\n      report {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFindingDueDate($id: Float!, $date: DateTime) {\n    updateFinding(id: $id, data: { dueDate: $date }) {\n      id\n      dueDate\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFindingDueDate($id: Float!, $date: DateTime) {\n    updateFinding(id: $id, data: { dueDate: $date }) {\n      id\n      dueDate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateSection($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateIterationStep(id: $id, updateModel: $updateModel) {\n      id\n      description\n      index\n      name\n    }\n  }\n',
): (typeof documents)['\n  mutation updateSection($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateIterationStep(id: $id, updateModel: $updateModel) {\n      id\n      description\n      index\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateIterationStarredStatus($id: VecticeId!, $starred: Boolean!) {\n    updateIteration(id: $id, data: { starred: $starred }) {\n      vecticeId\n      starred\n    }\n  }\n',
): (typeof documents)['\n  mutation updateIterationStarredStatus($id: VecticeId!, $starred: Boolean!) {\n    updateIteration(id: $id, data: { starred: $starred }) {\n      vecticeId\n      starred\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateIterationStepArtifact($id: Float!, $data: UpdateIterationStepArtifactInput!) {\n    updateIterationStepArtifact(id: $id, data: $data) {\n      id\n      text\n    }\n  }\n',
): (typeof documents)['\n  mutation updateIterationStepArtifact($id: Float!, $data: UpdateIterationStepArtifactInput!) {\n    updateIterationStepArtifact(id: $id, data: $data) {\n      id\n      text\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateModelType($id: VecticeId!, $type: ModelType!) {\n    updateModel(modelId: $id, model: { type: $type }) {\n      vecticeId\n      type\n    }\n  }\n',
): (typeof documents)['\n  mutation updateModelType($id: VecticeId!, $type: ModelType!) {\n    updateModel(modelId: $id, model: { type: $type }) {\n      vecticeId\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updatePhase($id: VecticeId!, $updateModel: PhaseUpdateInput!) {\n    updatePhase(id: $id, updateModel: $updateModel) {\n      vecticeId\n      index\n      name\n      description\n      status\n      ownerId\n      enforceRequirementsAsSections\n      owner {\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePhase($id: VecticeId!, $updateModel: PhaseUpdateInput!) {\n    updatePhase(id: $id, updateModel: $updateModel) {\n      vecticeId\n      index\n      name\n      description\n      status\n      ownerId\n      enforceRequirementsAsSections\n      owner {\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStepDefinition($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateStepDefinition(id: $id, updateModel: $updateModel) {\n      id\n      name\n      slug\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStepDefinition($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateStepDefinition(id: $id, updateModel: $updateModel) {\n      id\n      name\n      slug\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateStepPosition($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateStepDefinition(id: $id, updateModel: $updateModel) {\n      id\n      index\n    }\n  }\n',
): (typeof documents)['\n  mutation updateStepPosition($id: Float!, $updateModel: BaseStepUpdateInput!) {\n    updateStepDefinition(id: $id, updateModel: $updateModel) {\n      id\n      index\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation changeUserPassword($userId: Float, $newPassword: String!) {\n    changeUserPassword(userId: $userId, newPassword: $newPassword)\n  }\n',
): (typeof documents)['\n  mutation changeUserPassword($userId: Float, $newPassword: String!) {\n    changeUserPassword(userId: $userId, newPassword: $newPassword)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserActivityList(\n    $workspaceIdList: [VecticeId!]\n    $projectIds: [VecticeId!]\n    $contributors: [ID!]\n    $targetFilter: ActivityTargetFilter\n    $activityTargetTypes: [ActivityTargetType!]\n    $activityTypes: [ActivityType!]\n    $afterCursor: String\n    $pageSize: Int!\n    $onlyMine: Boolean\n  ) {\n    getUserActivityList(\n      workspaceIdList: $workspaceIdList\n      filters: {\n        projectIds: $projectIds\n        contributors: $contributors\n        activityTypes: $activityTypes\n        activityTargetTypes: $activityTargetTypes\n        targetFilter: $targetFilter\n        onlyMine: $onlyMine\n      }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      ...userActivities\n      page {\n        hasNextPage\n        afterCursor\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getUserActivityList(\n    $workspaceIdList: [VecticeId!]\n    $projectIds: [VecticeId!]\n    $contributors: [ID!]\n    $targetFilter: ActivityTargetFilter\n    $activityTargetTypes: [ActivityTargetType!]\n    $activityTypes: [ActivityType!]\n    $afterCursor: String\n    $pageSize: Int!\n    $onlyMine: Boolean\n  ) {\n    getUserActivityList(\n      workspaceIdList: $workspaceIdList\n      filters: {\n        projectIds: $projectIds\n        contributors: $contributors\n        activityTypes: $activityTypes\n        activityTargetTypes: $activityTargetTypes\n        targetFilter: $targetFilter\n        onlyMine: $onlyMine\n      }\n      page: { size: $pageSize, afterCursor: $afterCursor }\n    ) {\n      ...userActivities\n      page {\n        hasNextPage\n        afterCursor\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createUserInvite($data: UserInviteInput!) {\n    createUserInvite(data: $data) {\n      email\n      status\n      invite {\n        inviteLinkToken\n        email\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation createUserInvite($data: UserInviteInput!) {\n    createUserInvite(data: $data) {\n      email\n      status\n      invite {\n        inviteLinkToken\n        email\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation cancelUserInvite($id: Float!) {\n    cancelUserInvite(id: $id)\n  }\n',
): (typeof documents)['\n  mutation cancelUserInvite($id: Float!) {\n    cancelUserInvite(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resendUserInvite($id: Float!) {\n    resendUserInvite(id: $id)\n  }\n',
): (typeof documents)['\n  mutation resendUserInvite($id: Float!) {\n    resendUserInvite(id: $id)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createUserViewActivity($userViewActivity: UserViewActivityCreateInput!) {\n    createUserViewActivity(userViewActivity: $userViewActivity) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createUserViewActivity($userViewActivity: UserViewActivityCreateInput!) {\n    createUserViewActivity(userViewActivity: $userViewActivity) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserWorkspaceAccessibility($workspaceId: VecticeId!, $userId: Float!) {\n    accessLevel: getUserWorkspaceAccessibility(workspaceId: $workspaceId, userId: $userId)\n  }\n',
): (typeof documents)['\n  query getUserWorkspaceAccessibility($workspaceId: VecticeId!, $userId: Float!) {\n    accessLevel: getUserWorkspaceAccessibility(workspaceId: $workspaceId, userId: $userId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query whoAmI {\n    whoAmI {\n      user {\n        id\n        avatarId\n        ...userFields\n        name\n        email\n        emailVerificationStatus\n        about\n        organizationId\n        role\n        preferences {\n          ...userPreferences\n          onboardingStatus\n        }\n      }\n      defaultWorkspace {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  query whoAmI {\n    whoAmI {\n      user {\n        id\n        avatarId\n        ...userFields\n        name\n        email\n        emailVerificationStatus\n        about\n        organizationId\n        role\n        preferences {\n          ...userPreferences\n          onboardingStatus\n        }\n      }\n      defaultWorkspace {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserWorkspaceListToInvite($search: String!) {\n    getUserWorkspaceListToInvite: getUserWorkspaceList(\n      filters: { searchFilter: { search: $search, fields: ["name"] }, type: [PRIVATE, SAMPLE, TUTORIAL] }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserWorkspaceListToInvite($search: String!) {\n    getUserWorkspaceListToInvite: getUserWorkspaceList(\n      filters: { searchFilter: { search: $search, fields: ["name"] }, type: [PRIVATE, SAMPLE, TUTORIAL] }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getAllUserWorkspaces {\n    getUserWorkspaceList {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getAllUserWorkspaces {\n    getUserWorkspaceList {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceMemberList($workspaceId: VecticeId!, $order: ListOrderInput, $filters: UserListFiltersInput) {\n    getWorkspaceMemberList(workspaceId: $workspaceId, order: $order, filters: $filters) {\n      items {\n        id\n        role\n        name\n        email\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceMemberList($workspaceId: VecticeId!, $order: ListOrderInput, $filters: UserListFiltersInput) {\n    getWorkspaceMemberList(workspaceId: $workspaceId, order: $order, filters: $filters) {\n      items {\n        id\n        role\n        name\n        email\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceTargetList($filters: WorkspaceListFiltersInput!) {\n    getUserWorkspaceList(page: { size: 10 }, filters: $filters, order: { field: "name", direction: ASC }) {\n      items {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceTargetList($filters: WorkspaceListFiltersInput!) {\n    getUserWorkspaceList(page: { size: 10 }, filters: $filters, order: { field: "name", direction: ASC }) {\n      items {\n        vecticeId\n        name\n        type\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetContext($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetContext($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      vecticeId\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionContext($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      dataSet {\n        vecticeId\n        project {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionContext($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      dataSet {\n        vecticeId\n        project {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getIterationContext($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      phase {\n        vecticeId\n        name\n        index\n        parent {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getIterationContext($iterationId: VecticeId!) {\n    getIterationById(id: $iterationId) {\n      vecticeId\n      phase {\n        vecticeId\n        name\n        index\n        parent {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelContext($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      vecticeId\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelContext($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      vecticeId\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionContext($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      model {\n        vecticeId\n        project {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionContext($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      model {\n        vecticeId\n        project {\n          vecticeId\n          name\n          workspace {\n            vecticeId\n            name\n            type\n            userContext {\n              accessibility\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseContext($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      index\n      parent {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseContext($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      index\n      parent {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectContext($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      workspace {\n        vecticeId\n        name\n        type\n        userContext {\n          accessibility\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getProjectContext($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      workspace {\n        vecticeId\n        name\n        type\n        userContext {\n          accessibility\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReportContext($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      id\n      name\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getReportContext($reportId: Float!) {\n    CDTReport(id: $reportId) {\n      id\n      name\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFindingContext($findingId: Float!) {\n    Finding(id: $findingId) {\n      id\n      name\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFindingContext($findingId: Float!) {\n    Finding(id: $findingId) {\n      id\n      name\n      project {\n        vecticeId\n        name\n        workspace {\n          vecticeId\n          name\n          type\n          userContext {\n            accessibility\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceContext($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      type\n      userContext {\n        accessibility\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceContext($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      type\n      userContext {\n        accessibility\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserProjects($workspaceId: VecticeId, $filters: ProjectListFiltersInput) {\n    getProjectList(workspaceId: $workspaceId, filters: $filters, order: { field: "createdDate", direction: ASC }) {\n      items {\n        vecticeId\n        templateUsed\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserProjects($workspaceId: VecticeId, $filters: ProjectListFiltersInput) {\n    getProjectList(workspaceId: $workspaceId, filters: $filters, order: { field: "createdDate", direction: ASC }) {\n      items {\n        vecticeId\n        templateUsed\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createWorkspace($data: WorkspaceCreateInput!) {\n    createWorkspace(data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation createWorkspace($data: WorkspaceCreateInput!) {\n    createWorkspace(data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTReportInfo($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      modelVersion {\n        id\n        vecticeId\n        inventoryReference\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CDTReportInfo($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      modelVersion {\n        id\n        vecticeId\n        inventoryReference\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionInfo($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      id\n      vecticeId\n      inventoryReference\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionInfo($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      id\n      vecticeId\n      inventoryReference\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteApiKey($apiKeyId: Float!) {\n    deleteApiKey(apiKeyId: $apiKeyId)\n  }\n',
): (typeof documents)['\n  mutation deleteApiKey($apiKeyId: Float!) {\n    deleteApiKey(apiKeyId: $apiKeyId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getApiKeyList($order: ListOrderInput, $page: PageInput = { size: 30, index: 1 }, $userId: Float!) {\n    getApiKeyList(order: $order, page: $page, userId: $userId) {\n      items {\n        id\n        name\n        createdDate\n        status\n      }\n      total\n      page {\n        ...pageIndexFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getApiKeyList($order: ListOrderInput, $page: PageInput = { size: 30, index: 1 }, $userId: Float!) {\n    getApiKeyList(order: $order, page: $page, userId: $userId) {\n      items {\n        id\n        name\n        createdDate\n        status\n      }\n      total\n      page {\n        ...pageIndexFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateApiKey($apiKey: ApiKeyUpdateInput!, $apiKeyId: Float!) {\n    updateApiKey(apiKey: $apiKey, apiKeyId: $apiKeyId) {\n      id\n      status\n      name\n    }\n  }\n',
): (typeof documents)['\n  mutation updateApiKey($apiKey: ApiKeyUpdateInput!, $apiKeyId: Float!) {\n    updateApiKey(apiKey: $apiKey, apiKeyId: $apiKeyId) {\n      id\n      status\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation changeOldPassword($newPassword: String!, $oldPassword: String) {\n    changeUserPassword(newPassword: $newPassword, oldPassword: $oldPassword)\n  }\n',
): (typeof documents)['\n  mutation changeOldPassword($newPassword: String!, $oldPassword: String) {\n    changeUserPassword(newPassword: $newPassword, oldPassword: $oldPassword)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation sendEmailVerification {\n    sendEmailVerification\n  }\n',
): (typeof documents)['\n  mutation sendEmailVerification {\n    sendEmailVerification\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateFeatureFlagAvailability($code: String!, $enabled: Boolean!) {\n    updateFeatureFlagAvailability(code: $code, enabled: $enabled) {\n      code\n      enabled\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFeatureFlagAvailability($code: String!, $enabled: Boolean!) {\n    updateFeatureFlagAvailability(code: $code, enabled: $enabled) {\n      code\n      enabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateOrgFeatureFlagAvailability($organizationId: Float!, $code: String!, $enabled: Boolean!) {\n    updateOrganizationFeatureFlagAvailability(organizationId: $organizationId, code: $code, enabled: $enabled) {\n      code\n      enabled\n      organizationId\n      overridden\n    }\n  }\n',
): (typeof documents)['\n  mutation updateOrgFeatureFlagAvailability($organizationId: Float!, $code: String!, $enabled: Boolean!) {\n    updateOrganizationFeatureFlagAvailability(organizationId: $organizationId, code: $code, enabled: $enabled) {\n      code\n      enabled\n      organizationId\n      overridden\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getEpicFeatureFlags {\n    getEpicFeatureFlags {\n      code\n      description\n      maturity\n      enabled\n      ticket\n      date\n      overrides\n      stories {\n        code\n        description\n        status\n        enabled\n        ticket\n        date\n        overrides\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getEpicFeatureFlags {\n    getEpicFeatureFlags {\n      code\n      description\n      maturity\n      enabled\n      ticket\n      date\n      overrides\n      stories {\n        code\n        description\n        status\n        enabled\n        ticket\n        date\n        overrides\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrganizationFeatureFlags($organizationId: Float!) {\n    getOrganizationFeatureFlags(organizationId: $organizationId) {\n      organizationId\n      code\n      enabled\n      overridden\n    }\n  }\n',
): (typeof documents)['\n  query getOrganizationFeatureFlags($organizationId: Float!) {\n    getOrganizationFeatureFlags(organizationId: $organizationId) {\n      organizationId\n      code\n      enabled\n      overridden\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFeatureFlagOrgOverrides($code: String!) {\n    getFeatureFlagOrgOverrides(code: $code) {\n      enabled\n      organization {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFeatureFlagOrgOverrides($code: String!) {\n    getFeatureFlagOrgOverrides(code: $code) {\n      enabled\n      organization {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrganization($id: Float!) {\n    getOrganization(id: $id) {\n      id\n      name\n      ...OrganizationSettings\n    }\n  }\n',
): (typeof documents)['\n  query getOrganization($id: Float!) {\n    getOrganization(id: $id) {\n      id\n      name\n      ...OrganizationSettings\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OrganizationSettings on Organization {\n    id\n    ...OrganizationDescriptionAndLevel\n    ...organizationDomains\n    ...organizationConfiguration\n    ...organizationLicenseOptions\n  }\n',
): (typeof documents)['\n  fragment OrganizationSettings on Organization {\n    id\n    ...OrganizationDescriptionAndLevel\n    ...organizationDomains\n    ...organizationConfiguration\n    ...organizationLicenseOptions\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment organizationConfiguration on Organization {\n    id\n    name\n    emailsWhitelisted\n  }\n',
): (typeof documents)['\n  fragment organizationConfiguration on Organization {\n    id\n    name\n    emailsWhitelisted\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateEmailAvailabilityForOrganization($organizationId: Float!, $emailsEnabled: Boolean!) {\n    updateEmailAvailabilityForOrganization(organizationId: $organizationId, emailsEnabled: $emailsEnabled)\n  }\n',
): (typeof documents)['\n  mutation updateEmailAvailabilityForOrganization($organizationId: Float!, $emailsEnabled: Boolean!) {\n    updateEmailAvailabilityForOrganization(organizationId: $organizationId, emailsEnabled: $emailsEnabled)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment OrganizationDescriptionAndLevel on Organization {\n    id\n    name\n    description\n    level\n  }\n',
): (typeof documents)['\n  fragment OrganizationDescriptionAndLevel on Organization {\n    id\n    name\n    description\n    level\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment organizationDomains on Organization {\n    id\n    emailDomains {\n      domain\n    }\n  }\n',
): (typeof documents)['\n  fragment organizationDomains on Organization {\n    id\n    emailDomains {\n      domain\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addEmailDomainToOrganization($organizationId: Float!, $emailDomain: String!) {\n    addEmailDomainToOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {\n      id\n      emailDomains {\n        domain\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation addEmailDomainToOrganization($organizationId: Float!, $emailDomain: String!) {\n    addEmailDomainToOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {\n      id\n      emailDomains {\n        domain\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment organizationLicenseOptions on Organization {\n    id\n    name\n    licenseOptions {\n      isSingleSignOnEnabled\n      isAskAIEnabled\n    }\n  }\n',
): (typeof documents)['\n  fragment organizationLicenseOptions on Organization {\n    id\n    name\n    licenseOptions {\n      isSingleSignOnEnabled\n      isAskAIEnabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation setLicenseOptions($organizationId: Float!, $licenseOptions: OrganizationLicenseOptionsInput!) {\n    setLicenseOptions(organizationId: $organizationId, licenseOptions: $licenseOptions) {\n      isSingleSignOnEnabled\n      isAskAIEnabled\n    }\n  }\n',
): (typeof documents)['\n  mutation setLicenseOptions($organizationId: Float!, $licenseOptions: OrganizationLicenseOptionsInput!) {\n    setLicenseOptions(organizationId: $organizationId, licenseOptions: $licenseOptions) {\n      isSingleSignOnEnabled\n      isAskAIEnabled\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getOrganizationUserAndInvitesList(\n    $filters: UserAndInvitesFiltersInput\n    $order: ListOrderUserAndInvitesInput\n    $organizationId: Float\n    $page: PageIndexInput\n  ) {\n    getUserAndInvitesList(filters: $filters, order: $order, organizationId: $organizationId, page: $page) {\n      items {\n        status\n        type\n        user {\n          ...userFields\n          disabled\n          role\n          createdDate\n        }\n        userInvite {\n          id\n          email\n          inviteLinkToken\n          createdDate\n          role\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getOrganizationUserAndInvitesList(\n    $filters: UserAndInvitesFiltersInput\n    $order: ListOrderUserAndInvitesInput\n    $organizationId: Float\n    $page: PageIndexInput\n  ) {\n    getUserAndInvitesList(filters: $filters, order: $order, organizationId: $organizationId, page: $page) {\n      items {\n        status\n        type\n        user {\n          ...userFields\n          disabled\n          role\n          createdDate\n        }\n        userInvite {\n          id\n          email\n          inviteLinkToken\n          createdDate\n          role\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query exportMacros($organizationId: Float, $onlyIds: [Float!], $email: String) {\n    exportMacros(organizationId: $organizationId, onlyIds: $onlyIds, email: $email)\n  }\n',
): (typeof documents)['\n  query exportMacros($organizationId: Float, $onlyIds: [Float!], $email: String) {\n    exportMacros(organizationId: $organizationId, onlyIds: $onlyIds, email: $email)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query exportProject($projectId: VecticeId!, $organizationId: Float, $email: String) {\n    exportProject(id: $projectId, organizationId: $organizationId, email: $email)\n  }\n',
): (typeof documents)['\n  query exportProject($projectId: VecticeId!, $organizationId: Float, $email: String) {\n    exportProject(id: $projectId, organizationId: $organizationId, email: $email)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateBigQueryUserViewActivity {\n    updateBigQueryUserViewActivity\n  }\n',
): (typeof documents)['\n  mutation updateBigQueryUserViewActivity {\n    updateBigQueryUserViewActivity\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query exportWorkspace($workspaceId: VecticeId!, $organizationId: Float, $email: String) {\n    exportWorkspace(id: $workspaceId, organizationId: $organizationId, email: $email)\n  }\n',
): (typeof documents)['\n  query exportWorkspace($workspaceId: VecticeId!, $organizationId: Float, $email: String) {\n    exportWorkspace(id: $workspaceId, organizationId: $organizationId, email: $email)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createSuperAdminUser($email: String!, $password: String!) {\n    createSuperAdminUser(data: { email: $email, password: $password }) {\n      id\n      name\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  mutation createSuperAdminUser($email: String!, $password: String!) {\n    createSuperAdminUser(data: { email: $email, password: $password }) {\n      id\n      name\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query listSuperAdminUsers {\n    listSuperAdminUsers {\n      id\n      name\n      createdDate\n      disabled\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  query listSuperAdminUsers {\n    listSuperAdminUsers {\n      id\n      name\n      createdDate\n      disabled\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query authenticateWithUniqueCode($clientId: String!, $realm: String!, $code: String!, $redirectUri: String) {\n    authenticateWithUniqueCode(clientId: $clientId, realm: $realm, code: $code, redirectUri: $redirectUri) {\n      realm\n      clientId\n    }\n  }\n',
): (typeof documents)['\n  query authenticateWithUniqueCode($clientId: String!, $realm: String!, $code: String!, $redirectUri: String) {\n    authenticateWithUniqueCode(clientId: $clientId, realm: $realm, code: $code, redirectUri: $redirectUri) {\n      realm\n      clientId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStepForRedirection($stepId: Float!) {\n    getStepById(id: $stepId) {\n      id\n      parent {\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getStepForRedirection($stepId: Float!) {\n    getStepById(id: $stepId) {\n      id\n      parent {\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTReport($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n      project {\n        vecticeId\n      }\n      createdBy {\n        ...userFields\n      }\n      modelVersion {\n        ...modelVersionInformationFields\n      }\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query CDTReport($id: Float!) {\n    CDTReport(id: $id) {\n      id\n      name\n      project {\n        vecticeId\n      }\n      createdBy {\n        ...userFields\n      }\n      modelVersion {\n        ...modelVersionInformationFields\n      }\n      file {\n        id\n        content\n        isFileLocked\n        lockoutDuration\n        fingerprint\n        lockedUser {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetVersionDetails($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      name\n      isStarred\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n      ...DatasetVersionSidebar\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetVersionDetails($datasetVersionId: VecticeId!) {\n    getDatasetVersion(datasetVersionId: $datasetVersionId) {\n      vecticeId\n      name\n      isStarred\n      versionNumber\n      dataSet {\n        vecticeId\n        name\n      }\n      ...DatasetVersionSidebar\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetDetails($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      ...DatasetSidebar\n      vecticeId\n      name\n      project {\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetDetails($datasetId: VecticeId!) {\n    getDataset(datasetId: $datasetId) {\n      ...DatasetSidebar\n      vecticeId\n      name\n      project {\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment DatasetSidebar on DataSet {\n    vecticeId\n    description\n    type\n    sourceOrigin\n    project {\n      vecticeId\n      workspace {\n        vecticeId\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment DatasetSidebar on DataSet {\n    vecticeId\n    description\n    type\n    sourceOrigin\n    project {\n      vecticeId\n      workspace {\n        vecticeId\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceName($workspaceIds: [VecticeId!]) {\n    getOrganizationWorkspaceList(filters: { workspaceIds: $workspaceIds }) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceName($workspaceIds: [VecticeId!]) {\n    getOrganizationWorkspaceList(filters: { workspaceIds: $workspaceIds }) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation requestWorkspaceAccess($workspaceId: VecticeId!) {\n    requestWorkspaceAccess(workspaceId: $workspaceId) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation requestWorkspaceAccess($workspaceId: VecticeId!) {\n    requestWorkspaceAccess(workspaceId: $workspaceId) {\n      id\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFinding($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      fileId\n      file {\n        id\n        content\n      }\n      project {\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFinding($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      fileId\n      file {\n        id\n        content\n      }\n      project {\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getFindingDetails($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      severity\n      status\n      dueDate\n      createdDate\n      updatedDate\n      report {\n        id\n        name\n      }\n      modelVersion {\n        vecticeId\n        inventoryReference\n        ...modelVersionInformationFields\n      }\n      project {\n        vecticeId\n      }\n      owner {\n        id\n        ...userFields\n      }\n      reviewer {\n        id\n        ...userFields\n      }\n      createdBy {\n        id\n        ...userFields\n      }\n      lastUpdatedBy {\n        id\n        ...userFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getFindingDetails($id: Float!) {\n    Finding(id: $id) {\n      id\n      name\n      severity\n      status\n      dueDate\n      createdDate\n      updatedDate\n      report {\n        id\n        name\n      }\n      modelVersion {\n        vecticeId\n        inventoryReference\n        ...modelVersionInformationFields\n      }\n      project {\n        vecticeId\n      }\n      owner {\n        id\n        ...userFields\n      }\n      reviewer {\n        id\n        ...userFields\n      }\n      createdBy {\n        id\n        ...userFields\n      }\n      lastUpdatedBy {\n        id\n        ...userFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createPasswordRecoveryRequest($email: String!) {\n    createPasswordRecoveryRequest(email: $email)\n  }\n',
): (typeof documents)['\n  mutation createPasswordRecoveryRequest($email: String!) {\n    createPasswordRecoveryRequest(email: $email)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeAssetsFromIteration($iterationId: VecticeId!, $artifactsIdList: [Float!]!) {\n    removeAssetsFromIteration(iterationId: $iterationId, artifactsIdList: $artifactsIdList)\n  }\n',
): (typeof documents)['\n  mutation removeAssetsFromIteration($iterationId: VecticeId!, $artifactsIdList: [Float!]!) {\n    removeAssetsFromIteration(iterationId: $iterationId, artifactsIdList: $artifactsIdList)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelVersionDetails($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      name\n      isStarred\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n      ...ModelVersionSidebar\n    }\n  }\n',
): (typeof documents)['\n  query getModelVersionDetails($modelVersionId: VecticeId!) {\n    getModelVersion(modelVersionId: $modelVersionId) {\n      vecticeId\n      name\n      isStarred\n      versionNumber\n      model {\n        vecticeId\n        name\n      }\n      ...ModelVersionSidebar\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelDetails($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      ...ModelSidebar\n      vecticeId\n      name\n      project {\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getModelDetails($modelId: VecticeId!) {\n    getModelById(modelId: $modelId) {\n      ...ModelSidebar\n      vecticeId\n      name\n      project {\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ModelSidebar on Model {\n    vecticeId\n    name\n    description\n    type\n    versionStats {\n      discardedCount\n      experimentationCount\n      productionCount\n      retiredCount\n      stagingCount\n      validationCount\n    }\n    project {\n      vecticeId\n      workspace {\n        vecticeId\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment ModelSidebar on Model {\n    vecticeId\n    name\n    description\n    type\n    versionStats {\n      discardedCount\n      experimentationCount\n      productionCount\n      retiredCount\n      stagingCount\n      validationCount\n    }\n    project {\n      vecticeId\n      workspace {\n        vecticeId\n      }\n    }\n    createdDate\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation changeOrgDisplayName($displayName: String!) {\n    changeOrgDisplayName(displayName: $displayName)\n  }\n',
): (typeof documents)['\n  mutation changeOrgDisplayName($displayName: String!) {\n    changeOrgDisplayName(displayName: $displayName)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation setOrganizationSettings($configuration: OrganizationConfigInput!) {\n    setOrganizationSettings(configuration: $configuration) {\n      passwordAuthenticationEnabled\n      autoProvisioning\n      emailNotificationEnabled\n      dfStatisticsRowThreshold\n      dfStatisticsSampleRows\n      dfStatisticsMaxColumns\n      everyOneCanInviteUser\n    }\n  }\n',
): (typeof documents)['\n  mutation setOrganizationSettings($configuration: OrganizationConfigInput!) {\n    setOrganizationSettings(configuration: $configuration) {\n      passwordAuthenticationEnabled\n      autoProvisioning\n      emailNotificationEnabled\n      dfStatisticsRowThreshold\n      dfStatisticsSampleRows\n      dfStatisticsMaxColumns\n      everyOneCanInviteUser\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserAndInvitesList(\n    $filters: UserAndInvitesFiltersInput\n    $order: ListOrderUserAndInvitesInput\n    $page: PageIndexInput\n  ) {\n    getUserAndInvitesList(filters: $filters, order: $order, page: $page) {\n      items {\n        status\n        type\n        user {\n          ...userFields\n          id\n          createdDate\n          disabled\n          role\n        }\n        userInvite {\n          id\n          email\n          role\n          inviteLinkToken\n          updatedDate\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getUserAndInvitesList(\n    $filters: UserAndInvitesFiltersInput\n    $order: ListOrderUserAndInvitesInput\n    $page: PageIndexInput\n  ) {\n    getUserAndInvitesList(filters: $filters, order: $order, page: $page) {\n      items {\n        status\n        type\n        user {\n          ...userFields\n          id\n          createdDate\n          disabled\n          role\n        }\n        userInvite {\n          id\n          email\n          role\n          inviteLinkToken\n          updatedDate\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query checkRecoverPasswordRequest($uuid: String!) {\n    checkRecoverPasswordRequest(uuid: $uuid)\n  }\n',
): (typeof documents)['\n  query checkRecoverPasswordRequest($uuid: String!) {\n    checkRecoverPasswordRequest(uuid: $uuid)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation recoverPasswordAfterRequest($newPassword: String!, $uuid: String!) {\n    recoverPasswordAfterRequest(newPassword: $newPassword, uuid: $uuid) {\n      id\n      email\n    }\n  }\n',
): (typeof documents)['\n  mutation recoverPasswordAfterRequest($newPassword: String!, $uuid: String!) {\n    recoverPasswordAfterRequest(newPassword: $newPassword, uuid: $uuid) {\n      id\n      email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getStarredIterationList($phaseId: VecticeId!) {\n    getIterationList(\n      filters: { phaseId: $phaseId, starred: true }\n      order: { field: "index", direction: DESC }\n      page: { size: 30, index: 1 }\n    ) {\n      items {\n        vecticeId\n        ...iterationListItem\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getStarredIterationList($phaseId: VecticeId!) {\n    getIterationList(\n      filters: { phaseId: $phaseId, starred: true }\n      order: { field: "index", direction: DESC }\n      page: { size: 30, index: 1 }\n    ) {\n      items {\n        vecticeId\n        ...iterationListItem\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseSummary($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      description\n      parent {\n        vecticeId\n      }\n      ...PhaseSidebar\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseSummary($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      name\n      description\n      parent {\n        vecticeId\n      }\n      ...PhaseSidebar\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation requestReview($review: ReviewCreateInput!) {\n    requestReview(review: $review) {\n      vecticeId\n      status\n      ...Review\n    }\n  }\n',
): (typeof documents)['\n  mutation requestReview($review: ReviewCreateInput!) {\n    requestReview(review: $review) {\n      vecticeId\n      status\n      ...Review\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment Review on Review {\n    vecticeId\n    createdDate\n    updatedDate\n    message\n    status\n    feedback\n    createdById\n    createdBy {\n      ...userFields\n    }\n    reviewerId\n    reviewer {\n      ...userFields\n    }\n  }\n',
): (typeof documents)['\n  fragment Review on Review {\n    vecticeId\n    createdDate\n    updatedDate\n    message\n    status\n    feedback\n    createdById\n    createdBy {\n      ...userFields\n    }\n    reviewerId\n    reviewer {\n      ...userFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getActiveReview($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      status\n      activeReview {\n        vecticeId\n        status\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getActiveReview($phaseId: VecticeId!) {\n    getPhaseById(id: $phaseId) {\n      vecticeId\n      status\n      activeReview {\n        vecticeId\n        status\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getDatasetList(\n    $projectId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $dsTypes: [DatasetType!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getDatasetList(\n      projectId: $projectId\n      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        ...datasetListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getDatasetList(\n    $projectId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $dsTypes: [DatasetType!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getDatasetList(\n      projectId: $projectId\n      filters: { search: $search, contributors: $contributors, dsTypes: $dsTypes, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        ...datasetListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Findings($filters: FindingFiltersInput!, $order: ListOrderInput, $page: PageInput, $type: FindingQueryType) {\n    Findings(filters: $filters, order: $order, page: $page, type: $type) {\n      items {\n        id\n        name\n        status\n        severity\n        createdDate\n        updatedDate\n        closedDate\n        dueDate\n        reviewer {\n          ...userFields\n        }\n        owner {\n          ...userFields\n        }\n        modelVersion {\n          inventoryReference\n          ...modelVersionInformationFields\n        }\n        report {\n          id\n          name\n        }\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query Findings($filters: FindingFiltersInput!, $order: ListOrderInput, $page: PageInput, $type: FindingQueryType) {\n    Findings(filters: $filters, order: $order, page: $page, type: $type) {\n      items {\n        id\n        name\n        status\n        severity\n        createdDate\n        updatedDate\n        closedDate\n        dueDate\n        reviewer {\n          ...userFields\n        }\n        owner {\n          ...userFields\n        }\n        modelVersion {\n          inventoryReference\n          ...modelVersionInformationFields\n        }\n        report {\n          id\n          name\n        }\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getModelList(\n    $projectId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $types: [ModelType!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $algorithmNames: [String!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    getModelList(\n      projectId: $projectId\n      filters: {\n        search: $search\n        contributors: $contributors\n        types: $types\n        modelVersionStatus: $modelVersionStatus\n        algorithmNames: $algorithmNames\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        ...modelListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getModelList(\n    $projectId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $types: [ModelType!]\n    $modelVersionStatus: [ModelVersionStatus!]\n    $algorithmNames: [String!]\n    $pageIndex: Int!\n    $pageSize: Int!\n  ) {\n    getModelList(\n      projectId: $projectId\n      filters: {\n        search: $search\n        contributors: $contributors\n        types: $types\n        modelVersionStatus: $modelVersionStatus\n        algorithmNames: $algorithmNames\n      }\n      page: { size: $pageSize, index: $pageIndex }\n    ) {\n      items {\n        vecticeId\n        ...modelListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getPhaseListForProjectOverview($parentId: VecticeId!, $order: ListOrderInput, $page: PageInput) {\n    getPhaseList(filters: { parentId: $parentId }, order: $order, page: $page) {\n      items {\n        vecticeId\n        ...phaseListItem\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getPhaseListForProjectOverview($parentId: VecticeId!, $order: ListOrderInput, $page: PageInput) {\n    getPhaseList(filters: { parentId: $parentId }, order: $order, page: $page) {\n      items {\n        vecticeId\n        ...phaseListItem\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectOverview($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      templateUsed\n      description\n      phasesCounts {\n        done\n        ongoing\n        toDo\n        total\n      }\n      ...ProjectSidebar\n    }\n  }\n',
): (typeof documents)['\n  query getProjectOverview($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      templateUsed\n      description\n      phasesCounts {\n        done\n        ongoing\n        toDo\n        total\n      }\n      ...ProjectSidebar\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ProjectSidebar on Project {\n    vecticeId\n    status\n    createdDate\n    phasesCounts {\n      total\n    }\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    workspace {\n      vecticeId\n    }\n  }\n',
): (typeof documents)['\n  fragment ProjectSidebar on Project {\n    vecticeId\n    status\n    createdDate\n    phasesCounts {\n      total\n    }\n    createdBy {\n      id\n      ...userFields\n    }\n    updatedDate\n    lastUpdatedBy {\n      id\n      ...userFields\n    }\n    owner {\n      id\n      ...userFields\n      disabled\n    }\n    workspace {\n      vecticeId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLastPhase($projectId: VecticeId!) {\n    getPhaseList(\n      filters: { parentId: $projectId, status: [Draft, InReview] }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: 1 }\n    ) {\n      items {\n        vecticeId\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getLastPhase($projectId: VecticeId!) {\n    getPhaseList(\n      filters: { parentId: $projectId, status: [Draft, InReview] }\n      order: { field: "updatedDate", direction: DESC }\n      page: { size: 1 }\n    ) {\n      items {\n        vecticeId\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CDTReports(\n    $type: CDTReportQueryType\n    $filters: CDTReportFiltersInput!\n    $order: ListOrderInput\n    $page: PageInput\n  ) {\n    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n        createdDate\n        updatedDate\n        createdBy {\n          ...userFields\n        }\n        modelVersion {\n          ...modelVersionInformationFields\n          inventoryReference\n        }\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query CDTReports(\n    $type: CDTReportQueryType\n    $filters: CDTReportFiltersInput!\n    $order: ListOrderInput\n    $page: PageInput\n  ) {\n    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {\n      items {\n        id\n        name\n        createdDate\n        updatedDate\n        createdBy {\n          ...userFields\n        }\n        modelVersion {\n          ...modelVersionInformationFields\n          inventoryReference\n        }\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectSettings($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      description\n      workspace {\n        vecticeId\n        name\n        type\n      }\n      ownerId\n    }\n  }\n',
): (typeof documents)['\n  query getProjectSettings($projectId: VecticeId!) {\n    getProjectById(projectId: $projectId) {\n      vecticeId\n      name\n      description\n      workspace {\n        vecticeId\n        name\n        type\n      }\n      ownerId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateProject($projectId: VecticeId!, $data: ProjectUpdateInput!) {\n    updateProject(projectId: $projectId, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateProject($projectId: VecticeId!, $data: ProjectUpdateInput!) {\n    updateProject(projectId: $projectId, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation createUserFromInvite($data: UserCreateInput!) {\n    createUserFromInvite(data: $data) {\n      name\n      email\n    }\n  }\n',
): (typeof documents)['\n  mutation createUserFromInvite($data: UserCreateInput!) {\n    createUserFromInvite(data: $data) {\n      name\n      email\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserInviteWithAuthInfo($inviteToken: String!) {\n    getUserInviteWithAuthInfo(inviteToken: $inviteToken) {\n      passwordAuthenticationEnabled\n      userInvite {\n        email\n        isTrial\n        invitingUser {\n          ...userFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getUserInviteWithAuthInfo($inviteToken: String!) {\n    getUserInviteWithAuthInfo(inviteToken: $inviteToken) {\n      passwordAuthenticationEnabled\n      userInvite {\n        email\n        isTrial\n        invitingUser {\n          ...userFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation checkEmailVerificationToken($emailVerificationToken: String!) {\n    checkEmailVerificationToken(emailVerificationToken: $emailVerificationToken)\n  }\n',
): (typeof documents)['\n  mutation checkEmailVerificationToken($emailVerificationToken: String!) {\n    checkEmailVerificationToken(emailVerificationToken: $emailVerificationToken)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceUserListForInvite($order: ListOrderInput, $page: PageInput, $workspaceId: VecticeId!) {\n    getWorkspaceUserList(\n      order: $order\n      page: $page\n      workspaceId: $workspaceId\n      filters: { includeDisabledUsers: false }\n    ) {\n      items {\n        user {\n          id\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceUserListForInvite($order: ListOrderInput, $page: PageInput, $workspaceId: VecticeId!) {\n    getWorkspaceUserList(\n      order: $order\n      page: $page\n      workspaceId: $workspaceId\n      filters: { includeDisabledUsers: false }\n    ) {\n      items {\n        user {\n          id\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceUserList(\n    $order: ListOrderInput\n    $page: PageInput\n    $workspaceId: VecticeId!\n    $filters: UserListFiltersInput\n  ) {\n    getWorkspaceUserList(order: $order, page: $page, workspaceId: $workspaceId, filters: $filters) {\n      items {\n        userId\n        accessibility\n        createdDate\n        workspace {\n          vecticeId\n          name\n        }\n        user {\n          createdDate\n          role\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceUserList(\n    $order: ListOrderInput\n    $page: PageInput\n    $workspaceId: VecticeId!\n    $filters: UserListFiltersInput\n  ) {\n    getWorkspaceUserList(order: $order, page: $page, workspaceId: $workspaceId, filters: $filters) {\n      items {\n        userId\n        accessibility\n        createdDate\n        workspace {\n          vecticeId\n          name\n        }\n        user {\n          createdDate\n          role\n          ...userFields\n        }\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeUserFromWorkspace($workspaceId: VecticeId!, $userId: Float!) {\n    removeUserFromWorkspace(workspaceId: $workspaceId, userId: $userId)\n  }\n',
): (typeof documents)['\n  mutation removeUserFromWorkspace($workspaceId: VecticeId!, $userId: Float!) {\n    removeUserFromWorkspace(workspaceId: $workspaceId, userId: $userId)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation setUserWorkspaceAccessibility(\n    $accessibility: AccessibilityLevel!\n    $userId: Float!\n    $workspaceId: VecticeId!\n  ) {\n    setUserWorkspaceAccessibility(accessibility: $accessibility, userId: $userId, workspaceId: $workspaceId) {\n      userId\n      workspace {\n        vecticeId\n      }\n      accessibility\n    }\n  }\n',
): (typeof documents)['\n  mutation setUserWorkspaceAccessibility(\n    $accessibility: AccessibilityLevel!\n    $userId: Float!\n    $workspaceId: VecticeId!\n  ) {\n    setUserWorkspaceAccessibility(accessibility: $accessibility, userId: $userId, workspaceId: $workspaceId) {\n      userId\n      workspace {\n        vecticeId\n      }\n      accessibility\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectList(\n    $workspaceId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n    $order: ListOrderInput\n  ) {\n    getProjectList(\n      workspaceId: $workspaceId\n      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n      order: $order\n    ) {\n      items {\n        vecticeId\n        ...projectListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getProjectList(\n    $workspaceId: VecticeId!\n    $search: String\n    $contributors: [ID!]\n    $pageIndex: Int!\n    $pageSize: Int!\n    $advancedFilters: [GroupFilter!]\n    $order: ListOrderInput\n  ) {\n    getProjectList(\n      workspaceId: $workspaceId\n      filters: { search: $search, contributors: $contributors, advancedFilters: $advancedFilters }\n      page: { size: $pageSize, index: $pageIndex }\n      order: $order\n    ) {\n      items {\n        vecticeId\n        ...projectListItem\n      }\n      page {\n        ...pageIndexFields\n      }\n      total\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getProjectSelectorList($workspaceId: VecticeId!, $filters: ProjectListFiltersInput) {\n    getProjectList(\n      workspaceId: $workspaceId\n      page: { size: 10, index: 1 }\n      filters: $filters\n      order: { field: "name", direction: ASC }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query getProjectSelectorList($workspaceId: VecticeId!, $filters: ProjectListFiltersInput) {\n    getProjectList(\n      workspaceId: $workspaceId\n      page: { size: 10, index: 1 }\n      filters: $filters\n      order: { field: "name", direction: ASC }\n    ) {\n      items {\n        vecticeId\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getWorkspaceSettings($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      description\n      type\n    }\n  }\n',
): (typeof documents)['\n  query getWorkspaceSettings($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      vecticeId\n      name\n      description\n      type\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateWorkspace($id: VecticeId!, $data: WorkspaceUpdateInput!) {\n    updateWorkspace(id: $id, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n',
): (typeof documents)['\n  mutation updateWorkspace($id: VecticeId!, $data: WorkspaceUpdateInput!) {\n    updateWorkspace(id: $id, data: $data) {\n      vecticeId\n      name\n      description\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserDefaultWorkspace($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      ...WorkspaceCard\n    }\n  }\n',
): (typeof documents)['\n  query getUserDefaultWorkspace($workspaceId: VecticeId!) {\n    getWorkspaceById(workspaceId: $workspaceId) {\n      ...WorkspaceCard\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserWorkspaceList(\n    $pageIndex: Int!\n    $pageSize: Int!\n    $order: ListOrderInput\n    $search: String!\n    $type: [WorkspaceType!]\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getUserWorkspaceList(\n      page: { size: $pageSize, index: $pageIndex }\n      filters: { advancedFilters: $advancedFilters, type: $type, searchFilter: { search: $search, fields: ["name"] } }\n      order: $order\n    ) {\n      ...WorkspaceGrid\n      total\n    }\n  }\n',
): (typeof documents)['\n  query getUserWorkspaceList(\n    $pageIndex: Int!\n    $pageSize: Int!\n    $order: ListOrderInput\n    $search: String!\n    $type: [WorkspaceType!]\n    $advancedFilters: [GroupFilter!]\n  ) {\n    getUserWorkspaceList(\n      page: { size: $pageSize, index: $pageIndex }\n      filters: { advancedFilters: $advancedFilters, type: $type, searchFilter: { search: $search, fields: ["name"] } }\n      order: $order\n    ) {\n      ...WorkspaceGrid\n      total\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never;
