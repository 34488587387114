import { Option } from './interfaces';

export const getOptionLabel = <T extends Option | string = string>(option: T) =>
  typeof option === 'string' ? option : option.label;

export const isOptionDisabled = <T extends Option | string = string>(option: T) =>
  typeof option === 'string' ? false : !!option.disabled;

export const naiveFilter =
  (search: string) =>
  <T extends Option | string = string>(option: T) =>
    getOptionLabel(option).match(new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i'));
