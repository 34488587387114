import React from 'react';
import ReactPlayer from 'react-player';

import { Loading } from '../../ui';

import styles from './VideoPlayer.module.scss';

interface VideoPlayerProps {
  url: string;
}

export const VideoPlayer = ({ url }: VideoPlayerProps) => {
  return (
    <ReactPlayer
      fallback={<Loading className={styles.loader} color="white" />}
      height="auto"
      style={{ aspectRatio: 1280 / 720, maxWidth: 1280 }}
      url={url}
      width="100%"
      controls
    />
  );
};
