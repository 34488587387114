import { graphql } from '../../gql';

export const GET_ENTITY_METRICS = graphql(`
  query getEntityMetricsByParentId(
    $entityId: VecticeId!
    $pageSize: Int!
    $pageIndex: Int!
    $category: ModelVersionStatus
  ) {
    getResource: getEntityMetricsByParentId(
      entityId: $entityId
      entityType: modelVersion
      page: { size: $pageSize, index: $pageIndex }
      order: { field: "key", direction: ASC }
      category: $category
    ) {
      items {
        ...metricsFields
      }
      page {
        ...pageIndexFields
      }
      total
    }
  }
`);
