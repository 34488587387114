import { useQuery } from '@apollo/client';
import React, { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/flat/ic-dataset-version.svg';
import { ReactComponent as LineageIcon } from '../../../assets/icons/specials/flat/ic-lineage.svg';
import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/flat/ic-model-version.svg';
import { parseJobArtifacts } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { ArtifactType } from '../../../gql/graphql';
import { AssetTooltip, Badge, DataList, Icon, Tooltip, Typography } from '../../../ui';
import { getDataBricksHostIcon, getGitHostIcon, getOtherIcon } from '../../../utils';

import styles from './LineageTooltip.module.scss';

export const GET_LINEAGE_TOOLTIP = graphql(`
  query getLineageTooltip($lineageId: Float!) {
    getJobArtifactByJobRunId(lineageId: $lineageId) {
      items {
        id
        lineageArtifactType
        artifactType
        dataSetVersion {
          vecticeId
          name
          versionNumber
          dataSet {
            vecticeId
            name
          }
        }
        modelVersion {
          vecticeId
          name
          versionNumber
          model {
            vecticeId
            name
          }
        }
        codeVersion {
          id
          code {
            name
          }
          gitVersion {
            commitHash
            uri
            entrypoint
          }
          databricksVersion {
            urlNotebook
            relativePath
          }
          otherCodeVersion {
            id
            url
            firstAdditionalInformation
            secondAdditionalInformation
          }
        }
      }
    }
  }
`);

interface LineageTooltipProps {
  id?: number | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const LineageTooltip = ({ id, placement = 'top', children }: LineageTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`lineage-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data: artifactData } = useQuery(GET_LINEAGE_TOOLTIP, {
    skip: skipQuery || !id,
    errorPolicy: 'ignore',
    variables: {
      lineageId: id!,
    },
  });

  const jobArtifacts = artifactData?.getJobArtifactByJobRunId.items;

  const { inputs, code, outputs } = parseJobArtifacts(jobArtifacts);
  const databricks = code?.codeVersion?.databricksVersion;
  const githubVersion = code?.codeVersion?.gitVersion;
  const otherCodeVersion = code?.codeVersion?.otherCodeVersion;
  const hostIcon = databricks
    ? getDataBricksHostIcon()
    : otherCodeVersion
    ? getOtherIcon()
    : getGitHostIcon(githubVersion, true);
  const hostWording = databricks
    ? databricks.relativePath
    : otherCodeVersion
    ? otherCodeVersion.url
    : githubVersion?.entrypoint || code?.codeVersion?.code?.name;
  const getArtifactIcon = (artifactType: ArtifactType) =>
    artifactType === ArtifactType.Dataset ? DatasetVersionIcon : ModelVersionIcon;

  useEffect(() => {
    setDataLoaded(!!jobArtifacts);
  }, [jobArtifacts]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip ariaId={ariaId} icon={LineageIcon} label="Lineage">
      <DataList>
        {inputs?.map((input) => (
          <Fragment key={input.id}>
            <Typography id={`input-${input.id}-${ariaId}`} component="dt" variant="callout" color="white">
              {$t({
                id: 'lineage.tooltip.input',
                defaultMessage: 'Input',
              })}
            </Typography>
            <Typography
              aria-labelledby={`input-${input.id}-${ariaId}`}
              color="white"
              component="dd"
              ellipsis
              paragraph
              variant="callout"
            >
              <Icon icon={getArtifactIcon(input.artifactType)} size={18} className={styles.artifact_icon} />
              {input.dataSetVersion?.dataSet?.name || input.modelVersion?.model?.name || '-'}{' '}
              <Badge variant="square" color="accent" size="sm" contrast>
                v{input.dataSetVersion?.versionNumber || input.modelVersion?.versionNumber}
              </Badge>
            </Typography>
          </Fragment>
        ))}
        {code && (
          <>
            <Typography id={`code-${ariaId}`} component="dt" variant="callout" color="white">
              {$t({
                id: 'lineage.tooltip.code',
                defaultMessage: 'Code',
              })}
            </Typography>
            <Typography
              aria-labelledby={`code-${ariaId}`}
              color="white"
              component="dd"
              ellipsis
              paragraph
              variant="callout"
            >
              {hostIcon && <Icon icon={hostIcon} size={18} className={styles.artifact_icon} />} {hostWording}
            </Typography>
          </>
        )}
        {outputs?.map((output) => (
          <Fragment key={output.id}>
            <Typography id={`output-${output.id}-${ariaId}`} component="dt" variant="callout" color="white">
              {$t({
                id: 'lineage.tooltip.output',
                defaultMessage: 'Output',
              })}
            </Typography>
            <Typography
              aria-labelledby={`output-${output.id}-${ariaId}`}
              color="white"
              component="dd"
              ellipsis
              paragraph
              variant="callout"
            >
              <Icon icon={getArtifactIcon(output.artifactType)} size={18} className={styles.artifact_icon} />
              {output.dataSetVersion?.dataSet?.name || output.modelVersion?.model?.name || '-'}{' '}
              <Badge variant="square" color="accent" size="sm" contrast>
                v{output.dataSetVersion?.versionNumber || output.modelVersion?.versionNumber}
              </Badge>
            </Typography>
          </Fragment>
        ))}
      </DataList>
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
