import React from 'react';

import { ReactComponent as ApprovedIcon } from '../../assets/icons/specials/badges/ic-approved.svg';
import { ReactComponent as RejectedIcon } from '../../assets/icons/specials/badges/ic-rejected.svg';
import config from '../../config';
import { CloseReviewStatus, ReviewUpdateInput } from '../../gql/graphql';
import { useVecticeForm } from '../../hooks';
import { Button, Divider, FlexContainer, Form, Icon, Radio, TextArea, Typography } from '../../ui';

import styles from './ReviewForm.module.scss';

type ReviewData = {
  feedback?: string;
  stateReview?: CloseReviewStatus;
};

interface ReviewFormProps extends VecticeHTMLProps<HTMLDivElement> {
  onClose: () => void;
  onReview: (data: ReviewUpdateInput) => Promise<void>;
}

export const ReviewForm = ({ onReview, onClose }: ReviewFormProps) => {
  const {
    preSubmit,
    registerWithErrors,
    formState: { hasErrors, isDirty, isSubmitting },
  } = useVecticeForm<ReviewData>({
    mode: 'onChange',
  });

  return (
    <Form
      role="dialog"
      onSubmit={preSubmit(async (data: ReviewData) => {
        if (data.stateReview) {
          const submitData = { status: data.stateReview, feedback: data.feedback };
          await onReview(submitData);
        }
        onClose();
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.reviewForm} onClick={(e) => e.stopPropagation()}>
        <TextArea
          {...registerWithErrors('feedback', {
            maxLength: {
              value: config.MAXIMUM_REVIEW_LENGTH,
              message: $t({
                id: 'common.fieldMaxLength',
                defaultMessage: 'This field cannot exceed {max} characters',
                values: {
                  max: config.MAXIMUM_REVIEW_LENGTH,
                },
              }),
            },
          })}
          placeholder={$t({ id: 'ReviewForm.placeholder', defaultMessage: 'Submit your review with any feedback...' })}
          gutterBottom
          label="Feedback"
        />
        <FlexContainer direction="column" gap={4}>
          <Radio {...registerWithErrors('stateReview', { required: true })} value={CloseReviewStatus.Approved}>
            <div className={styles.radio_button}>
              <Typography className={styles.radio_title} weight="bold">
                <Icon className={styles.icon_radio} icon={ApprovedIcon} size={20} />
                {$t({ id: 'ReviewForm.approve', defaultMessage: 'Approve' })}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({ id: 'ReviewForm.markAsCompleted', defaultMessage: 'This will mark the Phase as “Completed”.' })}
              </Typography>
            </div>
          </Radio>
          <Radio {...registerWithErrors('stateReview', { required: true })} value={CloseReviewStatus.Rejected}>
            <div className={styles.radio_button}>
              <Typography className={styles.radio_title} weight="bold">
                <Icon className={styles.icon_radio} icon={RejectedIcon} size={20} />
                {$t({ id: 'ReviewForm.reject', defaultMessage: 'Reject' })}
              </Typography>
              <Typography variant="callout" color="tertiary">
                {$t({
                  id: 'ReviewForm.markAsInProgress',
                  defaultMessage: 'This will put the Phase back to “In Progress”.',
                })}
              </Typography>
            </div>
          </Radio>
        </FlexContainer>
      </div>
      <Divider />
      <div className={styles.saveButton}>
        <Button size="sm" variant="phantom" data-toggle="collapse" onClick={onClose}>
          {$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button type="submit" size="sm" variant="primary" disabled={hasErrors || !isDirty} loading={isSubmitting}>
          {$t({ id: 'button.submit', defaultMessage: 'Submit' })}
        </Button>
      </div>
    </Form>
  );
};
