import React from 'react';

import { Badge, BadgeProps, Tooltip } from '../../ui';

export const ReadOnlyBadge = ({ tooltipText, ...props }: { tooltipText?: string | null } & BadgeProps) => (
  <Tooltip
    text={
      tooltipText === undefined
        ? $t({ id: 'badge.readOnly.tooltip', defaultMessage: 'Contact the workspace Manager for full access' })
        : tooltipText
    }
    placement="right"
  >
    <Badge color="accent12" size="sm" {...props}>
      {$t({ id: 'badge.readOnly', defaultMessage: 'Read-only' })}
    </Badge>
  </Tooltip>
);
