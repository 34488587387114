import cn from 'classnames';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { ReactComponent as DragHandleIcon } from '../../assets/icons/interface/ic-drag-handle.svg';
import { IterationStepArtifactFragment, IterationStepArtifactType } from '../../gql/graphql';
import { DropIdData, buildDraggableId, buildDroppableId } from '../../hooks/useDnd';
import { EmptyList, FlexContainer, Icon, Summary } from '../../ui';

import { DraggableIterationStepArtifact } from './DraggableIterationStepArtifact';
import { IterationStepArtifactMovingClone } from './IterationStepArtifactMovingClone';

import styles from './DroppableIterationStep.module.scss';

interface DroppableIterationStepProps {
  deleting?: boolean;
  isDragging: boolean;
  status: string;
  dividerIndex?: number;
  idsDragging: string[];
  artifacts: ({
    __typename?: 'IterationStepArtifact';
    id: number;
    type: IterationStepArtifactType;
    index: number;
  } & {
    ' $fragmentRefs'?: { IterationStepArtifactFragment: IterationStepArtifactFragment };
  })[];
  artifactsCount: number;
  readOnly?: boolean;
  summary: ReactNode;
  dropIdData: DropIdData;
  stepDragAllowed: boolean;
  draggingId?: string;
  onInternalCapture?: (dragId: string) => void;
}

export const DroppableIterationStep = ({
  idsDragging,
  isDragging,
  readOnly,
  artifacts,
  dividerIndex,
  dropIdData,
  artifactsCount,
  summary,
  status,
  stepDragAllowed,
  draggingId,
  onInternalCapture,
}: DroppableIterationStepProps) => {
  const [open, setOpen] = useState(true);
  const [click, setClick] = useState(false);

  const getDividerValue = useCallback(
    (index: number) => {
      if (dividerIndex === -1 && index === 0) return 'top';
      if (dividerIndex === index) return 'bottom';
      return undefined;
    },
    [dividerIndex],
  );

  const onMouseDown = () => {
    setClick(true);
  };
  const onMouseUp = () => {
    setClick(false);
  };
  const onMouseMove = useCallback(() => {
    if (click) {
      setOpen(false);
    }
  }, [click]);

  const containedSummary = useMemo(
    () => (
      <div onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
        {summary}
      </div>
    ),
    [onMouseMove, summary],
  );

  return (
    <Droppable
      droppableId={buildDroppableId(dropIdData)}
      isDropDisabled={readOnly}
      type="ASSET"
      mode="virtual"
      renderClone={(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <IterationStepArtifactMovingClone assetSelectedSize={idsDragging.length}></IterationStepArtifactMovingClone>
        </div>
      )}
    >
      {(droppableProvided, dropSnapshot) => (
        <FlexContainer
          component="li"
          direction="row"
          align="flex-start"
          gap={0}
          className={cn(styles.step, {
            [styles.readOnly]: readOnly,
            [styles.isDraggingOver]: dropSnapshot.isDraggingOver,
            [styles.isDragging]: isDragging,
            [styles.stepDragAllowed]: stepDragAllowed,
            [styles.noData]: artifactsCount === 0,
          })}
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          onMouseUp={
            onMouseUp
          } /* onMouseUp needs to be in the parent otherwise because of rerendering the event isn't caught */
        >
          <div
            className={cn(styles.dragHandle, {
              [styles.isDragging]: isDragging,
              [styles.readOnly]: readOnly,
            })}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
          >
            <Icon icon={DragHandleIcon} size={18} />
          </div>
          <Summary
            className={cn(styles.details, { [styles.readOnly]: readOnly })}
            expandable
            iconClassName={styles.detailsIcon}
            open={open}
            summary={containedSummary}
            summaryClassName={cn(styles.summary, {
              [styles.withArtifacts]: artifactsCount > 0,
            })}
            onToggle={setOpen}
          >
            <FlexContainer
              className={cn(styles.items, {
                [styles.isDraggingOver]: dropSnapshot.isDraggingOver,
              })}
              direction="column"
              component="ul"
              gap={0}
            >
              {artifactsCount > 0 &&
                artifacts.map((artifact, index) => (
                  <DraggableIterationStepArtifact
                    key={artifact.id}
                    dragId={buildDraggableId({ ...dropIdData, draggableDataId: artifact.id.toString() })}
                    status={status}
                    artifactFragment={artifact}
                    readOnly={readOnly}
                    index={index}
                    draggingId={draggingId}
                    idsDragging={idsDragging}
                    divider={getDividerValue(index)}
                    onInternalCapture={onInternalCapture}
                  />
                ))}
              {artifactsCount === 0 && (
                <FlexContainer
                  component="li"
                  direction="row"
                  gap={2}
                  align="flex-start"
                  className={styles.item_wrapper}
                >
                  <EmptyList
                    className={styles.noData}
                    icon={VecticeIcon}
                    iconSize={48}
                    message={$t({ id: 'IterationStep.noAssets.message', defaultMessage: 'No Assets yet!' })}
                  />
                </FlexContainer>
              )}
              {droppableProvided.placeholder && <div></div>}
            </FlexContainer>
          </Summary>
        </FlexContainer>
      )}
    </Droppable>
  );
};
