import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import EmptyRecentProjectsGraphic from '../../../assets/graphics/home/empty-recent-projects.svg';
import { ReactComponent as WorkspaceIcon } from '../../../assets/icons/specials/flat/ic-workspace.svg';
import { useAuthentication } from '../../../context';
import { graphql } from '../../../gql';
import { AssetType } from '../../../gql/graphql';
import { useAnalytics } from '../../../hooks';
import { SearchParameter, VecticeRoutes, buildLink, formatSearchParameters } from '../../../routes';
import {
  Card,
  FlexContainer,
  Graphic,
  Grid,
  GridCell,
  LinkAsButton,
  Section,
  Typography,
  WithLoading,
} from '../../../ui';
import { generateArray } from '../../../utils';
import { ProjectCard, ProjectCardSkeleton } from '../../asset-display';
import { contributorField, encodeFilters } from '../../filters';
import { HintTooltip } from '../../tooltip';

import styles from './RecentProjects.module.scss';

export const GET_RECENT_PROJECTS = graphql(`
  query getRecentProjects {
    getProjectList(
      filters: { myRecent: true }
      order: { field: "updatedDate", direction: DESC }
      page: { size: 6, index: 1 }
    ) {
      items {
        vecticeId
        templateUsed
        ...projectCard
      }
    }
  }
`);

const ProjectsSkeleton = () => (
  <Grid columns={3} gap={16}>
    {generateArray(3).map((uuid) => (
      <GridCell key={uuid}>
        <ProjectCardSkeleton />
      </GridCell>
    ))}
  </Grid>
);

const EmptyRecentProjects = () => (
  <Card inert className={styles.emptyCard}>
    <FlexContainer direction="column" align="center" gap={8} className={styles.emptyContent}>
      <Graphic src={EmptyRecentProjectsGraphic} />
      <Typography color="primary" weight="semi-bold">
        {$t({
          id: 'home.recentProject.empty.label',
          defaultMessage: 'No Projects yet!',
        })}
      </Typography>
      <Typography variant="callout" color="secondary" align="center" paragraph>
        {$t({
          id: 'home.recentProject.empty.description',
          defaultMessage:
            "Projects allow data science leaders to manage and monitor their team's workflows and gain visibility into data science initiatives. Navigate into a Workspace and create your first Project.",
        })}
      </Typography>
      <LinkAsButton
        component={Link}
        to={buildLink(VecticeRoutes.WORKSPACES)}
        variant="primary"
        leftIcon={WorkspaceIcon}
      >
        {$t({
          id: 'home.recentProject.empty.link',
          defaultMessage: 'Go to Workspaces',
        })}
      </LinkAsButton>
    </FlexContainer>
  </Card>
);

export const RecentProjects = () => {
  const { user, defaultWorkspace } = useAuthentication();

  const { logClick } = useAnalytics();

  const { data, loading } = useQuery(GET_RECENT_PROJECTS);
  const projects = data?.getProjectList.items;

  const logRecentProjectWithTemplate = (project: { templateUsed?: string | null }, clickType: 'click' | 'auxclick') => {
    if (project.templateUsed) logClick('Home', `recent-project-${project.templateUsed}`, clickType);
  };

  return (
    <Section
      label={(ariaId) => (
        <FlexContainer justify="space-between" wrap="wrap">
          <FlexContainer>
            <Typography id={ariaId} component="h3" variant="body" weight="semi-bold">
              {$t({ id: 'home.recentProject.title', defaultMessage: 'Recent projects' })}
            </Typography>
            <HintTooltip
              hint={$t({
                id: 'home.recentProject.hint',
                defaultMessage: 'Projects you’ve recently contributed to',
              })}
            />
          </FlexContainer>
          <div>
            {defaultWorkspace && (
              <>
                <Typography
                  component={Link}
                  to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId: defaultWorkspace.vecticeId })}
                  variant="footnote"
                  color="accent"
                  link
                >
                  {$t({
                    id: 'home.recentProject.goToPersonalWorkspace',
                    defaultMessage: 'Go to your personal workspace',
                  })}
                </Typography>
                <Typography variant="footnote" color="tertiary">
                  {' '}
                  |{' '}
                </Typography>
              </>
            )}
            <Typography
              component={Link}
              to={{
                pathname: buildLink(VecticeRoutes.SEARCH),
                search: formatSearchParameters({
                  [SearchParameter.ASSET]: AssetType.Project,
                  [SearchParameter.FILTERS]: encodeFilters({ [contributorField]: [user.id] }),
                }),
              }}
              variant="footnote"
              color="accent"
              link
            >
              {$t({ id: 'home.recentProject.viewAll', defaultMessage: 'View all projects' })}
            </Typography>
          </div>
        </FlexContainer>
      )}
    >
      <WithLoading loading={loading} skeleton={<ProjectsSkeleton />}>
        {projects?.length ? (
          <Grid columns={3} gap={16}>
            {projects?.map((project) => (
              <GridCell
                key={project.vecticeId}
                onAuxClick={() => logRecentProjectWithTemplate(project, 'auxclick')}
                onClick={() => logRecentProjectWithTemplate(project, 'click')}
              >
                <ProjectCard project={project} />
              </GridCell>
            ))}
          </Grid>
        ) : (
          <EmptyRecentProjects />
        )}
      </WithLoading>
    </Section>
  );
};
