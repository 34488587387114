import { useMutation } from '@apollo/client';
import React, { PropsWithChildren, useRef, useState } from 'react';

import { ReactComponent as PenIcon } from '../../../assets/icons/interface/ic-edit.svg';
import { getCategoryTypeIcon, getCategoryTypeLabel } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { ColumnSourceType } from '../../../gql/graphql';
import { WithRoles } from '../../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../../guards/utils';
import { FlexContainer, Icon, Tooltip, Typography } from '../../../ui';
import { EditableDescription } from '../../assets';
import { SourceColumnWithFragment } from '../types';

import styles from './DatasetSourceColumnWrapper.module.scss';

const UPDATE_SOURCE_COLUMN_DESCRIPTION = graphql(`
  mutation updateColumnSourceDescription($id: Float!, $columnSourceType: ColumnSourceType!, $description: String) {
    updateColumnSourceDescription(id: $id, columnSourceType: $columnSourceType, description: $description)
  }
`);

interface DatasetSourceColumnProps {
  column: SourceColumnWithFragment;
  columnSourceType: ColumnSourceType;
}

let globalId = 0;

export const DatasetSourceColumnWrapper = ({
  children,
  column,
  columnSourceType,
}: PropsWithChildren<DatasetSourceColumnProps>) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`dataset-source-column-${globalId++}`);
  const [editableDescription, setEditableDescription] = useState(false);

  const [saveDescription] = useMutation(UPDATE_SOURCE_COLUMN_DESCRIPTION, {
    refetchQueries: ['getDatasetVersionFileColumns', 'getDatasetVersionDbColumns'],
    optimisticResponse: ({ id }) => ({
      updateColumnSourceDescription: id.toString(),
    }),
    onCompleted: () => setEditableDescription(false),
  });

  return (
    <FlexContainer component="li" align="flex-start" aria-labelledby={id} className={styles.container}>
      <Tooltip text={getCategoryTypeLabel(column.categoryType)} placement="left">
        <Icon icon={getCategoryTypeIcon(column.categoryType)} size={18} className={styles.type_icon} />
      </Tooltip>
      <FlexContainer direction="column" gap={0} style={{ flexGrow: 1 }}>
        <FlexContainer justify="space-between">
          <Typography id={id} variant="callout" weight="semi-bold" color="primary" ellipsis>
            {column.name}
          </Typography>
          <WithRoles workspaceRole={DEFAULT_WRITER_ACCESSIBILITY_LEVELS}>
            {!editableDescription && (
              <Tooltip
                placement="left"
                text={$t({ id: 'dataset.source.column.editDescription', defaultMessage: 'Edit column description' })}
              >
                <Icon
                  aria-label={$t({
                    id: 'dataset.source.column.editDescription',
                    defaultMessage: 'Edit column description',
                  })}
                  className={styles.edit_icon}
                  icon={PenIcon}
                  role="button"
                  size={16}
                  onClick={() => setEditableDescription(true)}
                />
              </Tooltip>
            )}
          </WithRoles>
        </FlexContainer>
        <EditableDescription
          className={styles.description}
          description={column.description ?? ''}
          editable={editableDescription}
          hideNoDescription
          placeholder={$t({
            id: 'dataset.source.column.description.placeholder',
            defaultMessage: 'Enter the column description here',
          })}
          onCancel={() => setEditableDescription(false)}
          onSave={(description) =>
            saveDescription({
              variables: {
                id: column.id,
                columnSourceType,
                description,
              },
            })
          }
        />
        {children}
      </FlexContainer>
    </FlexContainer>
  );
};
