import React, { ElementType } from 'react';

import { Button, Placement, Tooltip } from '../../ui';

interface CommentToolbarButtonProps {
  active: boolean;
  placement?: Placement;
  label: string;
  icon?: ElementType;
  onClick?: () => void;
}

export const ToolbarSidebarButton = ({
  active,
  placement = 'top',
  label,
  icon,
  onClick,
}: CommentToolbarButtonProps) => (
  <Tooltip text={label} placement={placement}>
    <Button
      aria-label={label}
      role="switch"
      aria-checked={active}
      leftIcon={icon}
      variant={active ? 'outlined' : 'phantom'}
      color="gray"
      size="sm"
      onClick={onClick}
    />
  </Tooltip>
);
