import React, { useEffect, useRef } from 'react';

import type { AlgorithmFilterComponentProps } from '../ui';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { AlgorithmFilterComponent } from '../ui';

export const pluginField = 'algorithmNames';

class AlgorithmFilterNode<T extends { workspaceIdList: string[] }> extends FilterNode<T> {
  updateProps(props?: T) {
    super.updateProps(props);
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    if (this.props) {
      return (
        <AlgorithmFilterComponent
          key={pluginField}
          {...this.props}
          filter={filter}
          onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
        />
      );
    }

    return null;
  }
}

export const AlgorithmFilter = ({ ...filterProps }: AlgorithmFilterComponentProps) => {
  const { current: node } = useRef(new AlgorithmFilterNode<AlgorithmFilterComponentProps>(filterProps));

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.technique.label', defaultMessage: 'Technique' }),
    node,
  });

  useEffect(() => {
    node.updateProps(filterProps);
  }, [...Object.values(filterProps)]);

  return null;
};
