import { useMutation } from '@apollo/client';
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { IterationStep, IterationStepArtifactType } from '../../gql/graphql';
import { CREATE_ITERATION_STEP_ARTIFACT } from '../../graphql/queries/createIterationStepArtifact.mutation';
import { useVecticeForm } from '../../hooks';
import { AutoComplete, FormItemContainer, message, ModalForm, TextArea, Typography } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration } from '../../utils';

import styles from './IterationSectionModalForm.module.scss';

export interface FormData {
  section?: string;
  text: string;
}

interface CreateIterationCommentModalProps {
  iterationId: string;
  sections: Pick<IterationStep, 'id' | 'name' | 'description'>[] | undefined;
  onCreate: () => void;
  onClose: () => void;
}

export const CreateIterationCommentModal = ({
  iterationId,
  sections = [],
  onCreate,
  onClose,
}: CreateIterationCommentModalProps) => {
  const { control, formState, preSubmit, registerWithErrors } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      section: undefined,
      text: '',
    },
  });
  const { errors, hasErrors, isDirty, isSubmitting } = formState;

  const [createIterationStepArtifact] = useMutation(CREATE_ITERATION_STEP_ARTIFACT, {
    update: () => onCreate(),
    onError: (error) => message.error(error.message),
  });

  const handleSubmit = useCallback(
    async ({ text, section }: FormData) => {
      await createIterationStepArtifact({
        variables: {
          id: iterationId,
          data: { type: IterationStepArtifactType.Comment, text, sectionName: section },
        },
      });
    },
    [iterationId, createIterationStepArtifact],
  );

  const sectionOptions = useMemo(() => sections.map(({ name }) => name), [sections]);

  return (
    <ModalForm
      title={$t({ id: 'modal.CreateIterationComment.title', defaultMessage: 'Create note' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <FormItemContainer
        label={$t({ id: 'modal.CreateIterationComment.name', defaultMessage: 'Section Name' })}
        gutterBottom
        error={errors.section?.message}
      >
        <Controller
          control={control}
          name="section"
          rules={{
            ...defaultNameFieldConfiguration(),
            required: false,
          }}
          render={({ field: { value, onChange } }) => (
            <AutoComplete
              aria-label="Section Name"
              autoComplete="off"
              autoFocus
              beforeOptions={
                <Typography
                  className={styles.optionsHint}
                  color="primary"
                  component="div"
                  variant="callout"
                  weight="bold"
                >
                  {$t({ id: 'modal.CreateIterationComment.requirement.hint', defaultMessage: 'Sections' })}
                </Typography>
              }
              hideNoData
              options={sectionOptions}
              placeholder={$t({ id: 'modal.CreateIterationComment.name.placeholder', defaultMessage: 'Section' })}
              value={value}
              onChange={onChange}
              onSelectOption={onChange}
            />
          )}
        />
      </FormItemContainer>
      <TextArea
        {...registerWithErrors('text', defaultDescriptionFieldConfiguration())}
        label={$t({ id: 'modal.CreateIterationComment.note', defaultMessage: 'Note' })}
        hint={null}
        autoFocus
      />
    </ModalForm>
  );
};
