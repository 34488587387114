import cn from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';

import { Typography, TypographyColors } from '../typography';

import styles from './FilterLabel.module.scss';

interface FilterLabelProps {
  addonBefore?: ReactNode;
  color?: TypographyColors;
}

export const FilterLabel = ({ addonBefore, color = 'secondary', children }: PropsWithChildren<FilterLabelProps>) => (
  <span className={cn(styles.label, { [styles.withAddon]: !!addonBefore })}>
    {addonBefore}
    <Typography variant="callout" color={color} ellipsis>
      {children}
    </Typography>
  </span>
);
