import cn from 'classnames';
import React, { ElementType } from 'react';

import styles from './Typography.module.scss';

export type TypographyVariants =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'subtitle'
  | 'body'
  | 'callout'
  | 'footnote'
  | 'inherit';

export type TypographyColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'white'
  | 'copyright'
  | 'disabled'
  | 'inherit';

export type TypographyWeights = 'regular' | 'medium' | 'semi-bold' | 'bold' | 'inherit';

export type TypographyAlignments = 'center' | 'inherit' | 'justify' | 'left' | 'right';

export type TypographyTransform = 'capitalize' | 'lowercase' | 'uppercase' | 'none';

const variantMap = new Map<TypographyVariants, ElementType>([
  ['heading1', 'h1'],
  ['heading2', 'h2'],
  ['heading3', 'h3'],
  ['subtitle', 'span'],
  ['body', 'span'],
  ['callout', 'span'],
  ['footnote', 'span'],
]);

export interface TypographyProps extends Omit<VecticeHTMLProps<HTMLElement>, 'color'> {
  align?: TypographyAlignments;
  clamp?: number;
  className?: string;
  color?: TypographyColors | string; // string to avoid compatibility issues with HTMLProps
  ellipsis?: boolean;
  gutterBottom?: boolean;
  link?: boolean;
  noBreak?: boolean;
  paragraph?: boolean;
  transform?: TypographyTransform;
  variant?: TypographyVariants;
  weight?: TypographyWeights;
}

export const Typography = React.forwardRef(
  <E extends ElementType>(
    {
      align,
      clamp,
      className,
      color = 'inherit',
      component,
      ellipsis,
      gutterBottom,
      link,
      noBreak,
      paragraph,
      transform = 'none',
      variant = 'inherit',
      weight = 'inherit',
      ...props
    }: TypographyProps & WithComponentProps<E>,
    ref: React.Ref<E>,
  ) => {
    let Element: ElementType = 'span';

    if (component) {
      Element = component;
    } else if (paragraph) {
      Element = 'p';
    } else if (link) {
      Element = 'a';
    } else if (variantMap.has(variant)) {
      Element = variantMap.get(variant)!;
    }

    return (
      <Element
        className={cn(
          styles.typography,
          align && styles[align],
          styles[variant],
          styles[color],
          styles[weight],
          styles[transform],
          {
            [styles.clamp]: !!clamp,
            [styles.ellipsis]: ellipsis,
            [styles.gutterBottom]: gutterBottom,
            [styles.paragraph]: paragraph,
            [styles.link]: link,
            [styles.noBreak]: noBreak,
          },
          className,
        )}
        {...props}
        style={{
          ...props.style,
          WebkitLineClamp: clamp,
        }}
        ref={ref}
      />
    );
  },
);
