import React, { MouseEvent, PropsWithChildren, useMemo } from 'react';

import { ReactComponent as DocumentationIcon } from '../../assets/icons/interface/ic-common-file-text.svg';
import config from '../../config';
import { useAnalytics } from '../../hooks';
import { LinkAsButton, Typography } from '../../ui';

export type Url =
  | 'api_cheat_sheet'
  | 'api_reference'
  | 'autolog_doc'
  | 'documentation'
  | 'getting_started'
  | 'product_tour'
  | 'python_cheatsheet'
  | 'r_cheatsheet'
  | 'review'
  | 'start_your_free_trial'
  | 'vectice_for_banking'
  | 'workspace';

interface DocumentationLinkProps extends VecticeHTMLProps<HTMLAnchorElement> {
  url?: Url;
  asButton?: boolean;
  asTypography?: boolean;
}

const HREFS: Record<Url, string> = {
  api_cheat_sheet: config.DOC_URL_API_CHEATSHEET,
  api_reference: config.API_REFERENCE_URL,
  autolog_doc: config.API_URL_AUTOLOG,
  documentation: config.DOC_URL,
  getting_started: config.DOC_URL_GETTING_STARTED,
  product_tour: config.DOC_URL_PRODUCT_TOUR,
  python_cheatsheet: config.DOC_URL_PYTHON_CHEATSHEET,
  r_cheatsheet: config.DOC_URL_R_CHEATSHEET,
  review: config.DOC_URL_REVIEW,
  start_your_free_trial: config.DOC_URL_START_YOUR_FREE_TRIAL,
  vectice_for_banking: config.DOC_URL_VECTICE_FOR_BANKING,
  workspace: config.WORKSPACE_URL,
};

const commonLinkProps = {
  target: '_blank',
  rel: 'noreferrer',
};

export const DocumentationLink = ({
  url = 'documentation',
  asButton,
  asTypography,
  children,
  onClick,
  ...props
}: PropsWithChildren<DocumentationLinkProps>) => {
  const { logDocumentationVisit } = useAnalytics();

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    logDocumentationVisit();
    onClick?.(e);
  };

  const linkProps = useMemo(
    () => ({
      ...commonLinkProps,
      href: HREFS[url],
    }),
    [url],
  );

  if (asButton) {
    return (
      <LinkAsButton
        component="a"
        {...linkProps}
        {...props}
        onClick={handleClick}
        variant="outlined"
        size="sm"
        leftIcon={DocumentationIcon}
      >
        {children}
      </LinkAsButton>
    );
  }

  if (asTypography) {
    return (
      <Typography link color="accent" {...linkProps} {...props} onClick={handleClick}>
        {children}
      </Typography>
    );
  }

  return (
    <a {...linkProps} {...props} onClick={handleClick}>
      {children}
    </a>
  );
};
