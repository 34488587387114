import React from 'react';

import config from '../../config';
import { Lightbox } from '../../ui';
import { VideoPlayer } from '../player';

interface CDTVideoProps {
  onClose: () => void;
}

export const CDTVideo = ({ onClose }: CDTVideoProps) => (
  <Lightbox
    content={<VideoPlayer url={config.CDT_VIDEO_URL} />}
    title={$t({ id: 'CDTVideo.title', defaultMessage: 'Documentation templates overview' })}
    onClose={onClose}
  />
);
