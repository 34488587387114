import { graphql } from '../../gql';

export const GET_WORKSPACES_TO_INVITE = graphql(`
  query getUserWorkspaceListToInvite($search: String!) {
    getUserWorkspaceListToInvite: getUserWorkspaceList(
      filters: { searchFilter: { search: $search, fields: ["name"] }, type: [PRIVATE, SAMPLE, TUTORIAL] }
    ) {
      items {
        vecticeId
        name
      }
    }
  }
`);

export const GET_ALL_USER_WORKSPACES = graphql(`
  query getAllUserWorkspaces {
    getUserWorkspaceList {
      items {
        vecticeId
        name
      }
    }
  }
`);
