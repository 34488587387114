import React from 'react';

import CrashGraphic from '../assets/graphics/splash/crash.svg';
import { Button, Crash, Typography } from '../ui';

import styles from './Errors.module.scss';

const reloadPage = () => window.location.reload();

export const ConnectionLostPage = () => (
  <Crash graphic={CrashGraphic}>
    <Typography variant="heading1" gutterBottom>
      {$t({ id: 'errorPage.connectionLost.title', defaultMessage: 'Connection Lost' })}
    </Typography>
    <Typography paragraph gutterBottom>
      {$t({
        id: 'errorPage.connectionLost.description',
        defaultMessage:
          'Uh oh! The server connection is lost. Please try again later or contact <support>Support</support> if the issue persists.',
      })}
    </Typography>
    <div className={styles.actions}>
      {!!window.location && (
        <Button variant="primary" onClick={reloadPage}>
          {$t({ id: 'errorPage.serverError.reloadPage', defaultMessage: 'Reload Page' })}
        </Button>
      )}
    </div>
  </Crash>
);
