import React, { useState } from 'react';

import { ReactComponent as CodeIcon } from '../../assets/icons/editor/ic-code.svg';
import { Button } from '../../ui';

import { ApiHelpModal } from './ApiHelpModal';

type Snippet = { label: string; hint?: string; code: string };

interface CodeDropdownProps {
  className?: string;
  phaseId?: string;
  snippets: Snippet | Snippet[];
}

export const ApiHelp = ({ phaseId, snippets, className }: CodeDropdownProps) => {
  const [showApiHelp, setShowApiHelp] = useState(false);
  return (
    <>
      <Button leftIcon={CodeIcon} variant="white" onClick={() => setShowApiHelp(true)} className={className}>
        {$t({
          id: 'apiHelp.codeSnippet.buttonLabel',
          defaultMessage: 'API Help',
        })}
      </Button>
      {showApiHelp && (
        <ApiHelpModal
          title={$t({
            id: 'phase.overview.section.suggestedActions.useApi.title',
            defaultMessage: 'Auto-document iterations via Vectice API',
          })}
          phaseId={phaseId}
          snippets={snippets}
          onClose={() => setShowApiHelp(false)}
        />
      )}
    </>
  );
};
