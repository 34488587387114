import { ModelVersionContextLibrary } from '../../gql/graphql';
import { getModelLibraryIcon } from '../../utils';

export const getModelLibraryDisplayName = (source?: ModelVersionContextLibrary | string | null) => {
  switch (source) {
    case ModelVersionContextLibrary.Mlflow:
      return $t({ id: 'modelLibrary.mlflow.label', defaultMessage: 'Mlflow' });
    case ModelVersionContextLibrary.Other:
      return $t({ id: 'modelLibrary.other.label', defaultMessage: 'Other' });
    default:
      return source ?? $t({ id: 'modelLibrary.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getModelLibraryOptions = () => [
  {
    icon: getModelLibraryIcon(ModelVersionContextLibrary.Mlflow),
    label: getModelLibraryDisplayName(ModelVersionContextLibrary.Mlflow),
    value: ModelVersionContextLibrary.Mlflow,
  },
  {
    icon: getModelLibraryIcon(ModelVersionContextLibrary.Other),
    label: getModelLibraryDisplayName(ModelVersionContextLibrary.Other),
    value: ModelVersionContextLibrary.Other,
  },
];
