import cn from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Page.module.scss';

type BackgroundColors = 'accent' | 'gray';

interface PageProps extends VecticeHTMLProps<HTMLDivElement> {
  header?: ReactNode;
  backgroundColor?: BackgroundColors;
}

export const Page = ({ backgroundColor, header, className, children, ...props }: PageProps) => (
  <div
    className={cn(
      styles.page,
      {
        [styles.withHeader]: !!header,
        [styles.accentBackground]: backgroundColor === 'accent',
        [styles.grayBackground]: backgroundColor === 'gray',
      },
      className,
    )}
    {...props}
  >
    {header}
    {children}
  </div>
);
