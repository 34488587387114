import React from 'react';

import { EntityMetric, EntityProperty } from '../../../gql/graphql';
import { Placement, Tooltip, Typography } from '../../../ui';
import { round } from '../../../utils';

import styles from './PropertyCell.module.scss';

interface PropertyCellProps {
  tooltipPlacement?: Placement;
  value?: EntityProperty['value'] | EntityMetric['value'];
}

export const PropertyCell = ({ tooltipPlacement = 'topLeft', value }: PropertyCellProps) => {
  return (
    <Tooltip placement={tooltipPlacement} text={value || undefined}>
      <Typography className={styles.property} ellipsis paragraph>
        {typeof value === 'number' ? round(value) : value || '-'}
      </Typography>
    </Tooltip>
  );
};
