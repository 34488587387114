import { useMutation } from '@apollo/client';
import cn from 'classnames';
import React, { useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import {
  getDocumentationPageStatusOptions,
  getDocumentationPageStatusColor,
  getDocumentationPageStatusDisplayName,
} from '../../entities/utils';
import { graphql } from '../../gql';
import { DocumentationPageStatus as PhaseStatusEnum, Phase } from '../../gql/graphql';
import { Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';
import { DocumentationPageStatusBadge } from '../badges';

import styles from './AssetStatus.module.scss';

export const UPDATE_PHASE_STATUS = graphql(`
  mutation updatePhaseStatus($phaseId: VecticeId!, $status: DocumentationPageStatus!) {
    updatePhase(id: $phaseId, updateModel: { status: $status }) {
      vecticeId
      status
    }
  }
`);

interface DocumentationPageStatusProps extends ButtonProps {
  phaseId?: string;
  status?: PhaseStatusEnum;
}

export const DocumentationPageStatus = ({ phaseId, status, className, ...props }: DocumentationPageStatusProps) => {
  const { current: statusOptions } = useRef(getDocumentationPageStatusOptions());

  const [updatePhase] = useMutation(UPDATE_PHASE_STATUS, {
    optimisticResponse: ({ phaseId, status }) => ({
      updatePhase: {
        vecticeId: phaseId.toString(),
        status,
        __typename: 'Phase' as Phase['__typename'],
      },
    }),
  });

  const handleStatusUpdate = (updatedStatus: PhaseStatusEnum) => async () => {
    if (phaseId) {
      await updatePhase({
        variables: {
          phaseId,
          status: updatedStatus,
        },
      });
    }
  };

  const menu = (
    <Menu aria-label="Phase Status Menu">
      {statusOptions.map((option) => (
        <MenuItem key={option.value} selected={status === option.value} onClick={handleStatusUpdate(option.value)}>
          <DocumentationPageStatusBadge status={option.value} contrast />
        </MenuItem>
      ))}
    </Menu>
  );

  if (!status) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        variant="primary"
        aria-label="Phase status select"
        className={cn(styles[getDocumentationPageStatusColor(status)], className)}
        {...props}
      >
        {getDocumentationPageStatusDisplayName(status)}
      </DropdownTrigger>
    </Dropdown>
  );
};
