import { graphql } from '../../gql';

export const GET_ASSET_ATTACHMENTS = graphql(`
  query getAssetAttachments($entityId: VecticeId!, $type: EntityMetadataTypeEnum!, $category: ModelVersionStatus) {
    attachments(entityId: $entityId, entityType: $type, category: $category) {
      id
      type
      name
      hasPreview
      mimeType
      attachmentType
      entityId
      ...attachmentFragment
    }
  }
`);
