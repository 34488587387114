import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/ic-dataset-version.svg';
import { ReactComponent as IterationIcon } from '../../../assets/icons/specials/ic-iteration.svg';
import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/ic-model-version.svg';
import { EntityFilesTypeEnum } from '../../../gql/graphql';

export const getEntityFileWidgetIcon = (type?: EntityFilesTypeEnum) => {
  switch (type) {
    case EntityFilesTypeEnum.Iteration:
      return IterationIcon;
    case EntityFilesTypeEnum.DatasetVersion:
      return DatasetVersionIcon;
    case EntityFilesTypeEnum.ModelVersion:
      return ModelVersionIcon;
    default:
      return null;
  }
};
