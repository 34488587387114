import cn from 'classnames';
import React, { ElementType, KeyboardEvent, MouseEvent, ReactElement, ReactNode } from 'react';

import { ReactComponent as MenuIcon } from '../../assets/icons/menu/ic-navigation-menu-horizontal.svg';
import { Button } from '../button';
import { Dropdown } from '../dropdown';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './SideNav.module.scss';

interface SideNavTabProps extends VecticeHTMLProps<HTMLDivElement> {
  icon?: ElementType;
  menu?: ReactElement;
  addonAfter?: ReactNode;
  active?: boolean;
  bold?: boolean;
  inert?: boolean;
}

export const SideNavTab = ({
  icon,
  menu,
  active,
  bold,
  inert,
  addonAfter,
  className,
  onClick,
  onKeyPress,
  children,
  ...props
}: SideNavTabProps) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    onKeyPress?.(event);
    if (event.key === 'Enter' && !inert) {
      // a bit ugly, but... I couldn't find another work around for this
      onClick?.(event as unknown as MouseEvent<HTMLDivElement>);
    }
  };

  return (
    <div
      role={!inert ? 'menuitem' : undefined}
      tabIndex={!inert ? 0 : undefined}
      onKeyPress={handleKeyPress}
      onClick={!inert ? onClick : undefined}
      className={cn(styles.side_nav_tab, { [styles.inert]: inert, [styles.active]: active }, className)}
      {...props}
    >
      {icon && <Icon icon={icon} size={18} className={styles.icon} />}
      <Typography variant="callout" color={active ? 'accent' : 'secondary'} weight={bold ? 'bold' : 'regular'} ellipsis>
        {children}
      </Typography>
      {addonAfter}
      {menu && (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button
            leftIcon={MenuIcon}
            size="sm"
            variant="phantom"
            color="gray"
            aria-label="options"
            className={styles.menu}
          />
        </Dropdown>
      )}
    </div>
  );
};
