import React, { PropsWithChildren, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { PageContextProvider } from '../context';
import { useRetrievePageContext } from '../hooks';
import { buildLink, VecticeRoutes } from '../routes';
import { Loading } from '../ui';

export const WithPageAccess = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();

  const { project, workspace, loading, error } = useRetrievePageContext();

  const contextValue = useMemo(
    () =>
      workspace
        ? {
            project,
            workspace,
            loading,
          }
        : null,
    [project, workspace, loading],
  );

  if (!!error) {
    if (error.graphQLErrors?.[0]?.extensions) {
      const { code, workspaceId } = error.graphQLErrors[0].extensions;
      if (code === 'NOT_FOUND') {
        return <Navigate replace to={buildLink(VecticeRoutes.NOT_FOUND)} />;
      }
      if (code === 'FORBIDDEN') {
        return (
          <Navigate replace to={buildLink(VecticeRoutes.FORBIDDEN, { workspaceId })} state={{ referrer: location }} />
        );
      }
    }

    return <Navigate replace to={buildLink(VecticeRoutes.HOME)} />;
  }

  if (loading) {
    return <Loading />;
  }

  return <PageContextProvider value={contextValue}>{children}</PageContextProvider>;
};
