import React from 'react';

import { UserIdentity } from '../../../graphql/fragments';
import { UserAvatar } from '../../asset-display';
import { FormatUserName } from '../../formatters';

import styles from './ActivityUser.module.scss';

export const AssignedUser = ({ user }: { user?: UserIdentity | null }) =>
  user ? (
    <>
      <UserAvatar user={user} size="xs" className={styles.avatar} />
      <FormatUserName user={user} />
    </>
  ) : (
    <>{$t({ id: 'activity.user.unassigned', defaultMessage: 'unassigned' })}</>
  );
