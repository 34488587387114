import { graphql } from '../../gql';

export const CREATE_ITERATION_STEP_ARTIFACT = graphql(`
  mutation createIterationStepArtifact($id: VecticeId!, $data: IterationStepArtifactInput!) {
    createIterationStepArtifact(id: $id, data: $data) {
      id
      index
      type
      text
      iterationId
      stepId
    }
  }
`);
