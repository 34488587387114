import React, { useState } from 'react';

import { ReactComponent as VisibleIcon } from '../../../assets/icons/interface/ic-view.svg';
import { Icon } from '../../svg';
import { Typography } from '../../typography';
import { Input } from '../input';
import { CommonProps, LabelProps, SizeProps } from '../interfaces';

import styles from './PasswordInput.module.scss';

type PasswordInputProps = CommonProps<HTMLInputElement> & SizeProps & LabelProps;

export const PasswordInput = React.forwardRef((props: PasswordInputProps, ref: React.Ref<HTMLInputElement>) => {
  const [visible, setVisible] = useState(false);

  const visibilitySwitch = (
    <Typography
      component="button"
      type="button"
      color={visible ? 'accent' : 'tertiary'}
      onClick={() => setVisible(!visible)}
      className={styles.toggle}
    >
      <Icon icon={VisibleIcon} size={18} />
    </Typography>
  );

  return <Input {...props} type={visible ? 'text' : 'password'} addonAfter={visibilitySwitch} ref={ref} />;
});
