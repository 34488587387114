import { ModelVersionStatus } from '../../gql/graphql';
import { BadgeColors } from '../../ui';

export const getModelVersionStatusColor = (status?: ModelVersionStatus | string | null): BadgeColors => {
  switch (status) {
    case ModelVersionStatus.Discarded:
      return 'abandoned';
    case ModelVersionStatus.Experimentation:
      return 'info';
    case ModelVersionStatus.Production:
      return 'success';
    case ModelVersionStatus.Retired:
      return 'default';
    case ModelVersionStatus.Staging:
      return 'warning';
    case ModelVersionStatus.Validation:
      return 'on-track';
    default:
      return 'default';
  }
};
export const getModelVersionStatusDisplayName = (status?: ModelVersionStatus | string | null) => {
  switch (status) {
    case ModelVersionStatus.Discarded:
      return $t({ id: 'modelVersionStatus.discarded.label', defaultMessage: 'Discarded' });
    case ModelVersionStatus.Experimentation:
      return $t({ id: 'modelVersionStatus.experimentation.label', defaultMessage: 'Experimentation' });
    case ModelVersionStatus.Production:
      return $t({ id: 'modelVersionStatus.production.label', defaultMessage: 'Production' });
    case ModelVersionStatus.Retired:
      return $t({ id: 'modelVersionStatus.retired.label', defaultMessage: 'Retired' });
    case ModelVersionStatus.Staging:
      return $t({ id: 'modelVersionStatus.staging.label', defaultMessage: 'Staging' });
    case ModelVersionStatus.Validation:
      return $t({ id: 'modelVersionStatus.validation.label', defaultMessage: 'Validation' });
    default:
      return $t({ id: 'modelVersionStatus.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getModelVersionStatusOptions = (withDiscarded = true, withValidation = true) => [
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Experimentation),
    value: ModelVersionStatus.Experimentation,
  },
  ...(withValidation
    ? [
        {
          label: getModelVersionStatusDisplayName(ModelVersionStatus.Validation),
          value: ModelVersionStatus.Validation,
        },
      ]
    : []),
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Staging),
    value: ModelVersionStatus.Staging,
  },
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Production),
    value: ModelVersionStatus.Production,
  },
  ...(withDiscarded
    ? [
        {
          label: getModelVersionStatusDisplayName(ModelVersionStatus.Discarded),
          value: ModelVersionStatus.Discarded,
        },
      ]
    : []),
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Retired),
    value: ModelVersionStatus.Retired,
  },
];
