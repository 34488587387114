import React from 'react';

import { FormatUserName } from '../../formatters';
import { UserNotificationInviteAccepted } from '../types';

import { Notification } from './Notification';

interface InviteAcceptedNotificationProps {
  notification: UserNotificationInviteAccepted;
}

export const InviteAcceptedNotification = ({
  notification: { creator, createdDate },
}: InviteAcceptedNotificationProps) => (
  <Notification creator={creator} createdDate={createdDate}>
    {$t({
      id: 'notification.invite.accepted',
      defaultMessage: '<bold>{creator}</bold> has accepted your invitation to join Vectice',
      values: {
        creator: <FormatUserName user={creator} />,
      },
    })}
  </Notification>
);
