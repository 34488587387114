import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Project, Workspace } from '../../gql/graphql';
import { CREATE_PROJECT } from '../../graphql/mutations';
import { GET_ALL_USER_WORKSPACES } from '../../graphql/queries';
import { useVecticeForm } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import { Input, message, ModalForm, Select, TextArea } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, getCopiedName } from '../../utils';

interface FormData {
  targetWorkspaceId: string;
  name: string;
  description?: string | null;
}

interface Props {
  project: Pick<Project, 'vecticeId' | 'name' | 'description'>;
  currentWorkspace?: Pick<Workspace, 'vecticeId' | 'name'> | null;
  onCheckAvailability: (value: string, targetWorkspaceId: string) => Promise<boolean>;
  onClose: () => void;
}

export const DuplicateProjectModal = ({ project, currentWorkspace, onCheckAvailability, onClose }: Props) => {
  const navigate = useNavigate();
  const { formState, preSubmit, registerWithErrors, trigger, watch } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      targetWorkspaceId: currentWorkspace?.vecticeId,
      name: getCopiedName(project.name),
      description: project.description,
    },
  });
  const { hasErrors, isSubmitting } = formState;
  const selectedTargetId = watch('targetWorkspaceId');

  const duplicateProjectLabel = $t({
    id: 'modal.duplicateProject.duplicateProjectLabel',
    defaultMessage: 'Duplicate Project',
  });

  const [isValidating, setIsValidating] = useState(false);

  const { data } = useQuery(GET_ALL_USER_WORKSPACES, {
    onError: (error) => message.error(error.message),
  });
  const workspaces = data?.getUserWorkspaceList.items ?? [];

  const workspaceOptions = useMemo(() => {
    const _workspaces = [
      ...(workspaces.find((ws) => ws.vecticeId === currentWorkspace?.vecticeId) || !currentWorkspace
        ? []
        : [currentWorkspace]),
      ...workspaces,
    ];
    return _workspaces.map(({ vecticeId, name }) => ({ id: `workspace-${vecticeId}`, value: vecticeId, label: name }));
  }, [currentWorkspace, workspaces]);

  useEffect(() => {
    trigger('name');
  }, [selectedTargetId, trigger]);

  const [duplicateProject] = useMutation(CREATE_PROJECT, {
    onCompleted: ({ createProject: { vecticeId, name } }) => {
      onClose();
      message.success(
        $t({
          id: 'modal.duplicateProject.success',
          defaultMessage: 'Project "{name}" has been successfully created"',
          values: { name },
        }),
      );
      navigate(buildLink(VecticeRoutes.PROJECT, { projectId: vecticeId }));
    },
    onError: (error) => {
      onClose();
      message.error(error.message);
    },
  });

  const onSubmit: SubmitHandler<FormData> = async ({ targetWorkspaceId, name, description }) =>
    await duplicateProject({
      variables: {
        data: {
          duplicateProjectId: project.vecticeId,
          name,
          description,
        },
        workspaceId: targetWorkspaceId,
      },
    });

  const delayedNameTrigger = useCallback(
    debounce(async () => {
      await trigger('name');
    }, 500),
    [trigger],
  );

  return (
    <ModalForm
      disabled={isValidating || hasErrors}
      isSubmitting={isSubmitting}
      title={duplicateProjectLabel}
      submitLabel={duplicateProjectLabel}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(onSubmit)}
      onClose={onClose}
    >
      <Select
        {...registerWithErrors('targetWorkspaceId')}
        gutterBottom
        label={$t({ id: 'modal.duplicateProject.targetWorkspace', defaultMessage: 'Target Workspace' })}
        options={workspaceOptions}
        autoFocus
      />
      <Input
        {...registerWithErrors('name', {
          ...defaultNameFieldConfiguration(),
          onChange: () => {
            setIsValidating(true);
            delayedNameTrigger();
          },
          validate: async (value) => {
            if (!value) {
              setIsValidating(false);
              return false;
            }

            const isNameAvailable = await onCheckAvailability(value, selectedTargetId);

            const errorMessage = $t({
              id: 'modal.duplicateProject.name.error.alreadyUsed',
              defaultMessage: 'Project "{name}" already exists in this project. Please choose another name.',
              values: {
                name: value,
              },
            });

            setIsValidating(false);
            return isNameAvailable || errorMessage;
          },
        })}
        gutterBottom
        label={$t({ id: 'modal.duplicateProject.name.label', defaultMessage: 'Project Name' })}
        required
      />
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        gutterBottom
        label={$t({ id: 'modal.duplicateProject.description.label', defaultMessage: 'Description' })}
        hint={$t({ id: 'modal.duplicateProject.description.optional', defaultMessage: 'Optional' })}
      />
    </ModalForm>
  );
};
