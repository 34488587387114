import { useApolloClient, useQuery } from '@apollo/client';
import { useState } from 'react';

import { LOGOUT } from '../graphql/queries';
import { broadcastClient } from '../services';
import { clearLocalStorageItem, VecticeStoredItem } from '../utils';

export const useLogout = () => {
  const apolloClient = useApolloClient();
  const [loggedOut, setLoggedOut] = useState(false);

  useQuery(LOGOUT, {
    onCompleted: async () => {
      broadcastClient.post('logout', true);

      await apolloClient.clearStore();
      clearLocalStorageItem(VecticeStoredItem.KC_REALM_CONF);
      clearLocalStorageItem(VecticeStoredItem.KC_REFERRER);

      setLoggedOut(true);
    },
  });

  return loggedOut;
};
