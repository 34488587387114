import React, { useRef } from 'react';

import { getUserOrInviteStatusOptions } from '../../../entities/utils';
import { UserAndInvitesStatus } from '../../../gql/graphql';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'status';

const visibleOptions = [UserAndInvitesStatus.Enabled, UserAndInvitesStatus.Disabled, UserAndInvitesStatus.Pending];

class UserOrInviteStatusFilterNode<T> extends FilterNode<T> {
  private options = getUserOrInviteStatusOptions().filter(({ value }) => visibleOptions.includes(value));

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="user-or-invite-status-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const UserOrInviteStatusFilter = () => {
  const { current: node } = useRef(new UserOrInviteStatusFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.userOrInviteStatus.label', defaultMessage: 'Status' }),
    node,
  });

  return null;
};
