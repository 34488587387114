import { Attachment } from '../entities';
import { restClient } from '../services';
import { VecticeResourceType, getEntityFileURL, isImageFile } from '../utils';

export const useUploadFiles = ({
  query,
  resourceId,
  resourceType,
}: {
  query?: Record<string, string>;
  resourceId?: number | string;
  resourceType?: VecticeResourceType;
}) => {
  const postAttachment = (file: File): Promise<Attachment> => {
    const formData = new FormData();
    formData.append('myFile', file);
    return restClient
      .post(getEntityFileURL({ resourceType, resourceId, query }), {
        body: formData,
      })
      .then((res) => res.json());
  };

  const uploadFiles = async (files: File[]) => {
    const promises = files.map(
      (file) =>
        new Promise<Attachment>((resolve, reject) => {
          const { name, size, type } = file;

          const fileSize = Math.round(size / 1024);

          if (fileSize >= 102400) {
            reject(new Error(`${name} exceeds the maximum size of 100 MB.`));
          }

          if (isImageFile(type) && fileSize >= 10240) {
            reject(new Error(`${name} exceeds the maximum image size of 10 MB.`));
          }

          postAttachment(file)
            .then((response) => resolve({ ...response, size }))
            .catch(() => reject(new Error('Can not upload file. Something went wrong')));
        }),
    );

    return Promise.all(promises);
  };

  return { uploadFiles };
};
