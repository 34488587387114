import React from 'react';

import { getAccessibilityLevelDisplayName } from '../../../entities/utils';
import { useFragment } from '../../../gql';
import { AccessibilityLevel, ActivityType, ActivityUpdateType } from '../../../gql/graphql';
import { UserAvatar } from '../../asset-display';
import { FormatUserName } from '../../formatters';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

import styles from './Activity.module.scss';

export const WorkspaceUserActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const actor = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;
  const targetUser = (
    <>
      <UserAvatar user={activity.toUser} size="xs" className={styles.userAvatar} />
      <FormatUserName user={activity.toUser} />
    </>
  );

  if (activity.type === ActivityType.Created) {
    if (activity.actor?.id !== String(activity.targetId)) {
      if (activity.toValue === AccessibilityLevel.Manager) {
        return (
          <>
            {$t({
              id: 'activities.workspaceUser.created.admin',
              defaultMessage: '{actor} added {targetUser} to the workspace as a manager',
              values: {
                actor,
                targetUser,
              },
            })}
          </>
        );
      } else {
        return (
          <>
            {$t({
              id: 'activities.workspaceUser.created.member',
              defaultMessage: '{actor} added {targetUser} to the workspace as an editor',
              values: {
                actor,
                targetUser,
              },
            })}
          </>
        );
      }
    } else {
      if (activity.toValue === AccessibilityLevel.Manager) {
        return (
          <>
            {$t({
              id: 'activities.workspaceUser.joined.admin',
              defaultMessage: '{actor} joined the workspace as a manager',
              values: {
                actor,
              },
            })}
          </>
        );
      } else {
        return (
          <>
            {$t({
              id: 'activities.workspaceUser.joined.member',
              defaultMessage: '{actor} joined the workspace as an editor',
              values: {
                actor,
              },
            })}
          </>
        );
      }
    }
  }

  if (activity.type === ActivityType.Deleted) {
    if (activity.actor?.id !== String(activity.targetId)) {
      return (
        <>
          {$t({
            id: 'activities.workspaceUser.removed',
            defaultMessage: '{actor} removed {targetUser} from the workspace',
            values: {
              actor,
              targetUser,
            },
          })}
        </>
      );
    } else {
      return (
        <>
          {$t({
            id: 'activities.workspaceUser.left',
            defaultMessage: '{actor} left the workspace',
            values: { actor },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.UpdatedProp && activity.updateType === ActivityUpdateType.Role) {
    return (
      <>
        {$t({
          id: 'activities.workspaceUser.update.role',
          defaultMessage: "{actor} updated {targetUser}'s role to {role}",
          values: {
            actor,
            targetUser,
            role: getAccessibilityLevelDisplayName(activity.toValue),
          },
        })}
      </>
    );
  }

  return null;
};
