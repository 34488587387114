import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Typography } from '../typography';

import styles from './MenuItemGroup.module.scss';

interface Props extends Omit<VecticeHTMLProps<HTMLLIElement>, 'title'> {
  title: ReactNode;
  titleClassName?: string;
}

export const MenuItemGroup = ({ title, titleClassName, children, ...props }: Props) => (
  <li className={styles.menu_item_group} {...props}>
    <Typography
      variant="callout"
      color="primary"
      weight="bold"
      component="div"
      className={cn(styles.title, titleClassName)}
      ellipsis
    >
      {title}
    </Typography>
    <ul className={styles.list}>{children}</ul>
  </li>
);
