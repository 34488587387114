import React, { useMemo } from 'react';

import { KYLO_BYTE } from './constants';

export const formatFileSize = (size?: number | null) => {
  const FILE_SIZE_UNITS = [
    $t({ id: 'fileSizeUnit.byte', defaultMessage: 'B' }),
    $t({ id: 'fileSizeUnit.kiloByte', defaultMessage: 'kB' }),
    $t({ id: 'fileSizeUnit.megaByte', defaultMessage: 'MB' }),
    $t({ id: 'fileSizeUnit.gigaByte', defaultMessage: 'GB' }),
    $t({ id: 'fileSizeUnit.teraByte', defaultMessage: 'TB' }),
    $t({ id: 'fileSizeUnit.petaByte', defaultMessage: 'PB' }),
    $t({ id: 'fileSizeUnit.exaByte', defaultMessage: 'EB' }),
    $t({ id: 'fileSizeUnit.zettaByte', defaultMessage: 'ZB' }),
    $t({ id: 'fileSizeUnit.yottaByte', defaultMessage: 'YB' }),
  ];

  if (size === null || size === undefined) {
    return $t({ id: 'formatters.fileSize.noValue', defaultMessage: 'N/A' });
  }

  if (!size) {
    return `0 ${FILE_SIZE_UNITS[0]}`;
  }

  if (Math.abs(size) < KYLO_BYTE) {
    return `${size} ${FILE_SIZE_UNITS[0]}`;
  }

  let unit = 0;
  const r = 10;

  do {
    // eslint-disable-next-line no-param-reassign
    size /= KYLO_BYTE;
    unit += 1;
  } while (Math.round(Math.abs(size) * r) / r >= KYLO_BYTE && unit < FILE_SIZE_UNITS.length - 1);

  return `${size.toFixed(2)} ${FILE_SIZE_UNITS[unit]}`;
};

export interface FormatFileSizeProps {
  size?: number | null;
}

export const FormatFileSize = ({ size }: FormatFileSizeProps) => {
  const formattedSize = useMemo(() => formatFileSize(size), [size]);

  if (!formattedSize) {
    return null;
  }

  return <>{formattedSize}</>;
};
