import { graphql } from '../../gql';

export const DATASETS = graphql(`
  query datasetsToSelect($projectId: VecticeId!, $search: String, $versionId: VecticeId) {
    getDatasetList(
      projectId: $projectId
      filters: { search: $search, versionId: $versionId }
      page: { index: 1, size: 10 }
    ) {
      items {
        vecticeId
        name
      }
      total
    }
  }
`);

export const DATASET_VERSIONS = graphql(`
  query datasetVersionsToSelect($datasetId: VecticeId!, $order: ListOrderInput) {
    getDatasetVersionsList(datasetId: $datasetId, order: $order) {
      items {
        vecticeId
        versionNumber
        dataSet {
          vecticeId
          name
          project {
            vecticeId
          }
        }
      }
      total
    }
  }
`);

export const MODELS = graphql(`
  query modelsToSelect($projectId: VecticeId!, $search: String) {
    getModelList(projectId: $projectId, filters: { search: $search }, page: { index: 1, size: 10 }) {
      items {
        vecticeId
        name
      }
      total
    }
  }
`);

export const MODEL_VERSIONS = graphql(`
  query modelVersionsToSelect($modelId: VecticeId!, $order: ListOrderInput) {
    getModelVersionsList(modelId: $modelId, order: $order) {
      items {
        vecticeId
        versionNumber
        model {
          vecticeId
          name
          project {
            vecticeId
          }
        }
      }
      total
    }
  }
`);

export const ITERATIONS = graphql(`
  query getIterations($filters: IterationFiltersInput!, $order: ListOrderInput) {
    getIterationList(filters: $filters, order: $order) {
      items {
        vecticeId
        index
        name
        phase {
          vecticeId
          name
        }
      }
      total
    }
  }
`);

export const CDT_REPORT_BY_ID = graphql(`
  query getSelectedCDTReport($id: Float!) {
    CDTReport(id: $id) {
      id
      name
    }
  }
`);

export const PROJECT_BY_ID = graphql(`
  query getSelectedProject($projectId: VecticeId!) {
    getProjectById(projectId: $projectId) {
      vecticeId
      name
    }
  }
`);

export const WORKSPACE_BY_ID = graphql(`
  query getSelectedWorkspace($workspaceId: VecticeId!) {
    getWorkspaceById(workspaceId: $workspaceId) {
      vecticeId
      name
    }
  }
`);
