import cn from 'classnames';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { ReactComponent as DragHandleIcon } from '../../assets/icons/interface/ic-drag-handle.svg';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { usePhases } from '../../hooks';
import { FlexContainer, Icon, Section, Skeleton, WithLoading } from '../../ui';
import { generateArray } from '../../utils';
import { PhaseCreationModal } from '../modals';

import { PhaseNavigationHeader } from './PhaseNavigationHeader';
import { PhaseNavigationItem } from './PhaseNavigationItem';

import styles from './PhaseNavigation.module.scss';

export interface PhaseNavigationProps {
  projectId?: string;
  pageId?: string;
}

export const PhaseNavigation = ({ pageId, projectId }: PhaseNavigationProps) => {
  const { allowed: canEdit } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });

  const [showModal, setShowModal] = useState(false);
  const { loading, phases, reorderPhase } = usePhases({ projectId });

  const onDragEnd = (result: DropResult) => {
    if (!canEdit) {
      return;
    }
    if (!phases || !result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    reorderPhase?.(result.source.index, result.destination.index);
  };

  return (
    <Section className={styles.navigation}>
      <PhaseNavigationHeader onCreatePage={() => setShowModal(true)} />
      <WithLoading
        loading={loading || !projectId}
        skeleton={generateArray(5).map((uuid) => (
          <Skeleton.Shape key={uuid} dark height="38px" width="100%" />
        ))}
      >
        {!!phases?.length && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list-documentation-pages" isDropDisabled={!canEdit}>
              {(droppableProvided) => (
                <ul ref={droppableProvided.innerRef} className={styles.items} {...droppableProvided.droppableProps}>
                  {phases.map((phase, index) => (
                    <Draggable
                      key={phase.vecticeId}
                      draggableId={`${phase.vecticeId}`}
                      index={index}
                      isDragDisabled={!canEdit}
                    >
                      {(draggableProvided, snapshot) => (
                        <FlexContainer
                          component="li"
                          direction="row"
                          gap={2}
                          align="flex-start"
                          className={styles.item_wrapper}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          {canEdit && (
                            <div
                              className={cn(
                                styles.dragHandle,
                                snapshot.isDragging && [styles.isDragging, 'isDragging'],
                              )}
                              {...draggableProvided.dragHandleProps}
                            >
                              <Icon icon={DragHandleIcon} size={18} />
                            </div>
                          )}
                          <div className={cn(styles.item, styles[phase.status.toString()])}>
                            <PhaseNavigationItem
                              currentPageId={pageId}
                              phase={phase}
                              iterationsCount={phase.iterationsCount}
                              reviewsCount={phase.reviewsCount}
                              projectId={phase.parent.vecticeId}
                              canEdit={canEdit}
                            />
                          </div>
                        </FlexContainer>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!!projectId && (
          <PhaseCreationModal projectId={projectId} showCreationModal={showModal} onClose={() => setShowModal(false)} />
        )}
      </WithLoading>
    </Section>
  );
};
