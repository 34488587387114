import React from 'react';
import { NavLink } from 'react-router-dom';

import { buildLink, VecticeRoutes } from '../../../routes';
import { Typography } from '../../../ui';

import styles from './Links.module.scss';

export const HomeLink = () => (
  <NavLink to={buildLink(VecticeRoutes.HOME)} className={styles.link} end>
    <Typography variant="callout" weight="semi-bold" color="white" ellipsis className={styles.link_label}>
      {$t({ id: 'navbar.links.home', defaultMessage: 'Home' })}
    </Typography>
  </NavLink>
);
