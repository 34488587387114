import React from 'react';

import { useFragment } from '../../../gql';
import { ColumnSourceType } from '../../../gql/graphql';
import { isDefined } from '../../../utils';
import { FormatPercentage, getPercentageTooltip } from '../../formatters';
import { BooleanSourceColumn as BooleanSourceColumnType, BooleanSourceColumnFragment } from '../types';

import { DatasetSourceColumnWrapper } from './DatasetSourceColumnWrapper';
import { Statistics, StatisticRow, StatisticCell } from './internal';

interface BooleanSourceColumnProps {
  column: BooleanSourceColumnType;
  columnSourceType: ColumnSourceType;
}

export const BooleanSourceColumn = ({ column, columnSourceType }: BooleanSourceColumnProps) => {
  const statistics = useFragment(BooleanSourceColumnFragment, column.statistics);

  return (
    <DatasetSourceColumnWrapper column={column} columnSourceType={columnSourceType}>
      {!!statistics?.boolean && (
        <Statistics>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.boolean.true', defaultMessage: 'True' })} />
            <StatisticCell
              label={
                <FormatPercentage value={isDefined(statistics.boolean.true) ? statistics.boolean.true * 100 : null} />
              }
              tooltip={getPercentageTooltip(isDefined(statistics.boolean.true) ? statistics.boolean.true * 100 : null)}
            />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.boolean.false', defaultMessage: 'False' })} />
            <StatisticCell
              label={
                <FormatPercentage value={isDefined(statistics.boolean.false) ? statistics.boolean.false * 100 : null} />
              }
              tooltip={getPercentageTooltip(
                isDefined(statistics.boolean.false) ? statistics.boolean.false * 100 : null,
              )}
            />
          </StatisticRow>
          <StatisticRow>
            <StatisticCell header label={$t({ id: 'column.statistic.boolean.missing', defaultMessage: 'Missing' })} />
            <StatisticCell
              label={
                <FormatPercentage
                  value={isDefined(statistics.boolean.missing) ? statistics.boolean.missing * 100 : null}
                />
              }
              tooltip={getPercentageTooltip(
                isDefined(statistics.boolean.missing) ? statistics.boolean.missing * 100 : null,
              )}
            />
          </StatisticRow>
        </Statistics>
      )}
    </DatasetSourceColumnWrapper>
  );
};
