export function doABarrelRoll() {
  document.body.animate([{ transform: 'rotate(0)' }, { transform: 'rotate(360deg)' }], {
    duration: 3000,
    iterations: 1,
  });
}

const konamiCodeSequence = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
];

export function listenForKonamiCode(cb?: () => void) {
  let progress = 0;

  const listener = (event: KeyboardEvent) => {
    if (event.key === konamiCodeSequence[progress]) {
      progress += 1;
      if (progress >= konamiCodeSequence.length) {
        cb?.();
      }
    } else {
      progress = 0;
    }
  };

  document.addEventListener('keyup', listener, { passive: true });

  return () => document.removeEventListener('keyup', listener);
}
