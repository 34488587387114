/* eslint-disable no-control-regex */
/* eslint-disable max-len */

export const PASSWORD_FORMAT = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[\W]).{8,}$/;

export const WORKSPACE_NAME_FORMAT = /^[^.].*/;

export const EMAIL_FORMAT =
  /^(?=.{1,256})(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export const validatePassword = (value: string, passwordStrengthWarning: string) =>
  new Promise<boolean>((resolve) => {
    if (!value) {
      resolve(true);
    } else if (!PASSWORD_FORMAT.test(value)) {
      throw new Error(passwordStrengthWarning);
    } else {
      resolve(true);
    }
  });

export const validateURL = (url: string): string | false => {
  try {
    new URL(url);
    return url;
  } catch (e) {
    return false;
  }
};
