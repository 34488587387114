import React, { useState } from 'react';

import { ReactComponent as MoveIcon } from '../../assets/icons/interface/ic-arrow-corner-right.svg';
import { Project, Workspace } from '../../gql/graphql';
import { MoveProjectModal } from '../modals';

import { ActionCard } from './internals';

interface MoveProjectProps {
  project: Pick<Project, 'vecticeId' | 'name'>;
  currentWorkspace?: Pick<Workspace, 'vecticeId' | 'name'> | null;
}

export const MoveProject = ({ project, currentWorkspace }: MoveProjectProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ActionCard
        buttonIcon={MoveIcon}
        buttonLabel={$t({ id: 'component.action.moveProject.button', defaultMessage: 'Move Project' })}
        hint={$t({
          id: 'component.action.moveProject.hint',
          defaultMessage:
            'Moving a project to another workspace moves all phases, models, and datasets, associated with it. You can only move projects to workspaces that you are a member of.',
        })}
        title={$t({ id: 'component.action.moveProject.title', defaultMessage: 'Move Project' })}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <MoveProjectModal project={project} currentWorkspace={currentWorkspace} onClose={() => setShowModal(false)} />
      )}
    </>
  );
};
