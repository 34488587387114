import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReviewNotificationContent } from '../../../entities';
import { graphql } from '../../../gql';
import { UserNotificationType } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationReview } from '../types';

import { Notification } from './Notification';

interface ReviewNotificationProps {
  notification: UserNotificationReview;
  onClose: () => void;
}

const GET_PHASE_BY_ID = graphql(`
  query getPhaseForRequestMention($id: VecticeId!) {
    getPhaseById(id: $id) {
      vecticeId
      name
      parent {
        vecticeId
        workspace {
          vecticeId
        }
      }
    }
  }
`);

export const ReviewNotification = ({
  notification: { creator, content, createdDate, type },
  onClose,
}: ReviewNotificationProps) => {
  const parsedContent: ReviewNotificationContent = JSON.parse(content);
  const phaseId = parsedContent?.phaseId;

  const { data, loading, error } = useQuery(GET_PHASE_BY_ID, {
    skip: !phaseId,
    variables: {
      id: phaseId!,
    },
  });
  const phase = data?.getPhaseById;

  if (!phase || loading) {
    return null;
  }

  const getReviewMessage = (creator: JSX.Element, title: string) => {
    switch (type) {
      case UserNotificationType.ReviewApproved:
        return $t({
          id: 'notification.review.approved',
          defaultMessage: '<bold>{creator}</bold> approved phase <bold>{title}</bold> review',
          values: { creator, title },
        });
      case UserNotificationType.ReviewRejected:
        return $t({
          id: 'notification.review.rejected',
          defaultMessage: '<bold>{creator}</bold> rejected phase <bold>{title}</bold> review',
          values: { creator, title },
        });
      case UserNotificationType.ReviewCommentAdded:
        return $t({
          id: 'notification.review.comment',
          defaultMessage: '<bold>{creator}</bold> added a comment to phase <bold>{title}</bold> review',
          values: {
            creator,
            title,
          },
        });
      default:
        return $t({
          id: 'notification.review.requested',
          defaultMessage: '<bold>{creator}</bold> requested <bold>you</bold> to review phase <bold>{title}</bold>',
          values: {
            creator,
            title,
          },
        });
    }
  };

  return (
    <Notification
      component={!error ? Link : undefined}
      to={buildLink(VecticeRoutes.PHASE_REVIEWS, { phaseId: phase.vecticeId })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {getReviewMessage(
        <FormatUserName user={creator} />,
        phase.name || $t({ id: 'notification.untitled', defaultMessage: 'Untitled' }),
      )}
    </Notification>
  );
};
