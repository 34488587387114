import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';

import { ReactComponent as DownloadIcon } from '../../assets/icons/interface/ic-download.svg';
import { getModelTypeDisplayName } from '../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../gql';
import {
  ModelVersion,
  ModelVersionApproval as ModelVersionApprovalEnum,
  ModelVersionRisk as ModelVersionRiskEnum,
} from '../../gql/graphql';
import { useWithFeatureFlags, useWithRoles, WithFeatureFlags } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { Button, DataEntry, DataList, FlexContainer, Icon, message, Skeleton, Tooltip, Typography } from '../../ui';
import {
  defaultNameFieldConfiguration,
  defaultNumberFieldConfiguration,
  getModelTypeIcon,
  VecticeResourceType,
} from '../../utils';
import { IterationLink, PhaseLink } from '../asset-display';
import { AssetDescription, AssetDetail, AssetDetails, AssetInput, CreationDetails, WithCopyID } from '../assets';
import { Contributors } from '../contributors';
import { ModelVersionStatus } from '../status';
import { ModelVersionApproval } from '../status/ModelVersionApproval';
import { ModelVersionRisk } from '../status/ModelVersionRisk';

import { ValidationNotebookModal } from './ValidationNotebookModal';

import styles from './ModelVersionSidebar.module.scss';

const ModelVersionSidebarFragment = graphql(`
  fragment ModelVersionSidebar on ModelVersion {
    vecticeId
    description
    algorithmName
    status
    risk
    approval
    inventoryReference
    model {
      vecticeId
      type
      project {
        vecticeId
        workspace {
          vecticeId
        }
      }
    }
    origins {
      phase {
        vecticeId
        name
      }
      iteration {
        vecticeId
        name
      }
    }
    createdDate
    createdBy {
      id
      ...userFields
    }
    updatedDate
    lastUpdatedBy {
      id
      ...userFields
    }
  }
`);

export const UPDATE_MODEL_VERSION_DESCRIPTION = graphql(`
  mutation updateModelVersionDescription($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {
      vecticeId
      description
    }
  }
`);

export const UPDATE_MODEL_VERSION_TECHNIQUE = graphql(`
  mutation updateModelVersionTechnique($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {
      vecticeId
      algorithmName
    }
  }
`);

export const UPDATE_MODEL_VERSION_INVENTORY = graphql(`
  mutation updateModelVersionInventory($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {
      vecticeId
      inventoryReference
    }
  }
`);

export const UPDATE_MODEL_VERSION_RISK = graphql(`
  mutation updateModelVersionRisk($modelVersionId: VecticeId!, $risk: ModelVersionRisk!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: { risk: $risk }) {
      vecticeId
      risk
    }
  }
`);

export const UPDATE_MODEL_VERSION_APPROVAL = graphql(`
  mutation updateModelVersionApproval($modelVersionId: VecticeId!, $approval: ModelVersionApproval!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: { approval: $approval }) {
      vecticeId
      approval
    }
  }
`);

interface ModelVersionSidebarProps {
  loading?: boolean;
  modelVersion?: FragmentType<typeof ModelVersionSidebarFragment>;
}

export const ModelVersionSidebar = ({ loading, modelVersion: modelVersionFragment }: ModelVersionSidebarProps) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });
  const { allowed: canEditInventory } = useWithFeatureFlags({
    featureFlag: 'view-and-assign-inventory-information-in-model-version-page',
  });
  const { allowed: modelVersionRisk } = useWithFeatureFlags({
    featureFlag: 'model-version-risk',
  });
  const { allowed: modelVersionApproval } = useWithFeatureFlags({
    featureFlag: 'model-version-approval',
  });
  const { allowed: iterationsListShown } = useWithFeatureFlags({
    featureFlag: 'mdv-linked-iterations-list',
  });
  const [showValidationNotebook, setShowValidationNotebook] = useState(false);

  const modelVersion = useFragment(ModelVersionSidebarFragment, modelVersionFragment);

  const [updateDescription] = useMutation(UPDATE_MODEL_VERSION_DESCRIPTION, {
    onError: (error) => message.error(error.message),
    optimisticResponse: ({ modelVersionId: vecticeId, data: { description } }) => ({
      updateModelVersion: {
        vecticeId: vecticeId.toString(),
        description: description ?? modelVersion!.description,
      },
    }),
  });

  const [updateTechnique] = useMutation(UPDATE_MODEL_VERSION_TECHNIQUE, {
    onError: (error) => message.error(error.message),
    optimisticResponse: ({ modelVersionId: vecticeId, data: { algorithmName } }) => ({
      updateModelVersion: {
        vecticeId: vecticeId.toString(),
        algorithmName: algorithmName ?? modelVersion!.algorithmName,
      },
    }),
  });

  const [updateInventory] = useMutation(UPDATE_MODEL_VERSION_INVENTORY, {
    onError: (error) => message.error(error.message),
    optimisticResponse: ({ modelVersionId: vecticeId, data: { inventoryReference } }) => ({
      updateModelVersion: {
        vecticeId: vecticeId.toString(),
        inventoryReference: inventoryReference ?? modelVersion!.inventoryReference,
      },
    }),
  });

  const [updateRisk] = useMutation(UPDATE_MODEL_VERSION_RISK, {
    onError: (error) => message.error(error.message),
    optimisticResponse: ({ modelVersionId, risk }) => ({
      updateModelVersion: {
        vecticeId: modelVersionId.toString(),
        risk,
        __typename: 'ModelVersion' as ModelVersion['__typename'],
      },
    }),
  });

  const [updateApproval] = useMutation(UPDATE_MODEL_VERSION_APPROVAL, {
    onError: (error) => message.error(error.message),
    optimisticResponse: ({ modelVersionId, approval }) => ({
      updateModelVersion: {
        vecticeId: modelVersionId.toString(),
        approval,
        __typename: 'ModelVersion' as ModelVersion['__typename'],
      },
    }),
  });

  const origins = modelVersion?.origins;
  const showSkeleton = loading || !modelVersion;

  const modelTypeIcon = getModelTypeIcon(modelVersion?.model.type);

  const handleTechniqueSubmit = useCallback(
    async ({ technique }) => {
      if (modelVersion) {
        await updateTechnique({
          variables: {
            modelVersionId: modelVersion.vecticeId,
            data: { algorithmName: technique },
          },
        });
      }
    },
    [modelVersion, updateTechnique],
  );

  const handleInventorySubmit = useCallback(
    async ({ inventory }) => {
      if (modelVersion) {
        await updateInventory({
          variables: {
            modelVersionId: modelVersion.vecticeId,
            data: { inventoryReference: inventory },
          },
        });
      }
    },
    [modelVersion, updateInventory],
  );

  const handleRiskSubmit = useCallback(
    async (risk: ModelVersionRiskEnum) => {
      if (modelVersion && risk !== modelVersion.risk) {
        await updateRisk({
          variables: {
            modelVersionId: modelVersion.vecticeId,
            risk,
          },
        });
      }
    },
    [modelVersion, updateRisk],
  );

  const handleApprovalSubmit = useCallback(
    async (approval: ModelVersionApprovalEnum) => {
      if (modelVersion && approval !== modelVersion.approval) {
        await updateApproval({
          variables: {
            modelVersionId: modelVersion.vecticeId,
            approval,
          },
        });
      }
    },
    [modelVersion, updateApproval],
  );

  return (
    <>
      <AssetDetails
        label={$t({ id: 'ModelVersionSidebar.versionDetails', defaultMessage: 'Version Details' })}
        loading={showSkeleton}
        skeleton={<Skeleton.Paragraph rows={2} width={['200px', '150px']} />}
      >
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.versionStatus', defaultMessage: 'Status' })}>
          <ModelVersionStatus
            className={styles.trigger}
            disabled={!canEdit}
            modelVersionId={modelVersion?.vecticeId}
            status={modelVersion?.status}
          />
        </AssetDetail>
        {modelVersionRisk && (
          <AssetDetail label={$t({ id: 'ModelVersionSidebar.versionRisk', defaultMessage: 'Risk' })}>
            <ModelVersionRisk
              className={styles.trigger}
              disabled={!canEdit}
              risk={modelVersion?.risk}
              handleRiskUpdate={handleRiskSubmit}
            />
          </AssetDetail>
        )}
        {modelVersionApproval && (
          <AssetDetail
            label={$t({ id: 'ModelVersionSidebar.approval', defaultMessage: 'Model version approval status' })}
          >
            <ModelVersionApproval
              approval={modelVersion?.approval}
              className={styles.trigger}
              disabled={!canEdit}
              handleApprovalUpdate={handleApprovalSubmit}
            />
          </AssetDetail>
        )}
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.modelType', defaultMessage: 'Model type' })}>
          <Tooltip text={getModelTypeDisplayName(modelVersion?.model.type)}>
            <FlexContainer gap={4}>
              {modelTypeIcon && <Icon icon={modelTypeIcon} size={16} />}
              <Typography ellipsis>{getModelTypeDisplayName(modelVersion?.model.type)}</Typography>
            </FlexContainer>
          </Tooltip>
        </AssetDetail>
        <AssetInput
          label={$t({ id: 'ModelVersionSidebar.inventory', defaultMessage: 'Model inventory ID' })}
          registerOptions={{
            ...defaultNumberFieldConfiguration(),
            required: false,
          }}
          value={modelVersion?.inventoryReference || ''}
          onChange={canEditInventory && canEdit ? (inventory) => handleInventorySubmit({ inventory }) : undefined}
        />
        <AssetInput
          label={$t({ id: 'ModelVersionSidebar.technique', defaultMessage: 'Technique' })}
          registerOptions={{
            ...defaultNameFieldConfiguration(),
            required: false,
          }}
          value={modelVersion?.algorithmName || ''}
          onChange={canEdit ? (technique) => handleTechniqueSubmit({ technique }) : undefined}
        />
        {!iterationsListShown && (
          <AssetDetail label={$t({ id: 'ModelVersionSidebar.creationDetails', defaultMessage: 'Creation Details' })}>
            <DataList className={styles.origins}>
              <DataEntry label={$t({ id: 'ModelVersionSidebar.phase', defaultMessage: 'Phase:' })} ellipsis>
                <PhaseLink resourceId={origins?.phase?.vecticeId} name={origins?.phase?.name} tooltipPlacement="left" />
              </DataEntry>
              <DataEntry label={$t({ id: 'ModelVersionSidebar.iteration', defaultMessage: 'Iteration:' })} ellipsis>
                <IterationLink
                  resourceId={origins?.iteration?.vecticeId}
                  name={origins?.iteration?.name}
                  tooltipPlacement="left"
                />
              </DataEntry>
            </DataList>
          </AssetDetail>
        )}
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.modelVersionID', defaultMessage: 'Version ID' })}>
          <WithCopyID
            iconLabel={$t({ id: 'ModelVersionSidebar.copyLabel', defaultMessage: 'Copy Model version ID' })}
            idValue={modelVersion?.vecticeId}
          />
        </AssetDetail>
        <WithFeatureFlags featureFlag="validation-notebook-download">
          <div>
            <Button className={styles.trigger} leftIcon={DownloadIcon} onClick={() => setShowValidationNotebook(true)}>
              {$t({ id: 'ValidationNotebook.DownloadButton', defaultMessage: 'Validation notebook' })}
            </Button>
          </div>
        </WithFeatureFlags>
      </AssetDetails>

      {modelVersion && (
        <>
          <AssetDescription
            label={$t({ id: 'ModelVersionSidebar.description', defaultMessage: 'Version Description' })}
            description={modelVersion?.description}
            onChange={
              canEdit
                ? (description) =>
                    updateDescription({
                      variables: {
                        modelVersionId: modelVersion.vecticeId,
                        data: { description },
                      },
                    })
                : undefined
            }
          />
          <AssetDetails label={$t({ id: 'ModelVersionSidebar.contributors', defaultMessage: 'Contributors' })}>
            <Contributors
              resourceId={modelVersion.vecticeId}
              resourceType={VecticeResourceType.MODEL_VERSION}
              workspaceId={modelVersion.model.project.workspace?.vecticeId}
            />
          </AssetDetails>
          <CreationDetails
            createdDate={modelVersion.createdDate}
            createdBy={modelVersion.createdBy}
            updatedDate={modelVersion.updatedDate}
            updatedBy={modelVersion.lastUpdatedBy}
          />
        </>
      )}

      {showValidationNotebook && modelVersion && (
        <ValidationNotebookModal
          modelVersionId={modelVersion.vecticeId}
          onClose={() => setShowValidationNotebook(false)}
        />
      )}
    </>
  );
};
