import React, { useEffect, useRef } from 'react';

import { getAccessibilityLevelDisplayName, getAccessibilityLevelOptions } from '../../../entities/utils';
import { AccessibilityLevel } from '../../../gql/graphql';
import { useWithFeatureFlags } from '../../../guards';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'roles';

const visibleOptions = [AccessibilityLevel.Manager, AccessibilityLevel.Editor];

class UserAccessibilityFilterNode<T> extends FilterNode<T> {
  private options = getAccessibilityLevelOptions().filter(({ value }) => visibleOptions.includes(value));

  addReaderAccessibility() {
    if (!this.options.find((option) => option.value === AccessibilityLevel.ReadOnly)) {
      this.options.push({
        label: getAccessibilityLevelDisplayName(AccessibilityLevel.ReadOnly),
        value: AccessibilityLevel.ReadOnly,
      });
    }
  }

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="user-accessibility-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const UserAccessibilityFilter = () => {
  const { allowed } = useWithFeatureFlags({ featureFlag: 'workspace-reader-member' });
  const { current: node } = useRef(new UserAccessibilityFilterNode());

  useEffect(() => {
    if (allowed) {
      node.addReaderAccessibility();
    }
  }, [allowed]);

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.accessibility.label', defaultMessage: 'Role' }),
    node,
  });

  return null;
};
