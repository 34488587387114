import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { DataSet } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const DELETE_DATASET = graphql(`
  mutation deleteDataset($datasetId: VecticeId!) {
    deleteDataset(datasetId: $datasetId)
  }
`);

interface DeleteDatasetModalProps {
  dataset: Pick<DataSet, 'vecticeId' | 'name'>;
  projectId?: string | null;
  onClose: () => void;
}

export const DeleteDatasetModal = ({ dataset, projectId, onClose }: DeleteDatasetModalProps) => {
  const navigate = useNavigate();

  const [deleteDataset, { loading: deleting }] = useMutation(DELETE_DATASET, {
    variables: {
      datasetId: dataset?.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        onClose();
        navigate(buildLink(VecticeRoutes.PROJECT_DATASETS, { projectId }));
        flushFromGraphQLCache(cache, { vecticeId: data.deleteDataset, __typename: 'DataSet' });
      }
    },
    onCompleted: () => {
      if (dataset) {
        message.success(
          $t({
            id: 'component.action.deleteDataset.successMessage',
            defaultMessage: '{name} dataset deleted',
            values: { name: dataset?.name },
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <DeletionPrompt
      processing={deleting}
      resourceName={dataset.name}
      title={$t({ id: 'dataset.modal.delete.title', defaultMessage: 'Delete Dataset' })}
      onClose={onClose}
      onConfirm={deleteDataset}
    />
  );
};
