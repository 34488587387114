import { useState } from 'react';

import { hexaToRgb, getLocalStorageItem, saveLocalStorageItem, VecticeStoredItem } from '../utils';

export const useCustomColors = () => {
  const [colors, setColors] = useState<string[]>(() => {
    const customColors = JSON.parse(getLocalStorageItem(VecticeStoredItem.VECTICE_CUSTOM_COLORS) ?? '[]');
    return customColors;
  });

  const addColor = (color: string) => {
    const rgbColor = hexaToRgb(color);

    if (!rgbColor || colors.includes(rgbColor)) return;

    const newColors = [...colors, rgbColor];
    saveLocalStorageItem(VecticeStoredItem.VECTICE_CUSTOM_COLORS, JSON.stringify(newColors));
    setColors(newColors);
  };

  return { colors, addColor };
};
