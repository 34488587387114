import { graphql } from '../../gql';

export const CREATE_ITERATION_SECTION = graphql(`
  mutation createIterationStep($parentId: VecticeId!, $createModel: BaseStepCreateInput!) {
    createIterationStep(parentId: $parentId, createModel: $createModel) {
      id
      name
      description
      index
    }
  }
`);
