import { useQuery } from '@apollo/client';
import { max, StackedBar } from '@vectice/data-viz';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { graphql } from '../../../gql';
import { DocumentationCounts, Project } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { buildLink, VecticeRoutes } from '../../../routes';
import { Table, Column, Typography, EmptyData } from '../../../ui';
import { ProjectTooltip, UserAvatar } from '../../asset-display';
import { PhasesProgress } from '../../progress';
import { ColorSwatch } from '../colors';
import { DashboardCard } from '../internals';

export const GET_ONGOING_PROJECTS = graphql(`
  query getWorkspaceOngoingProjects($workspaceIdList: [VecticeId!]!) {
    getWorkspaceOngoingProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        entity {
          vecticeId
          name
          owner {
            ...userFields
          }
          phasesCounts {
            toDo
            ongoing
            done
            total
          }
        }
        days
      }
    }
  }
`);

const getValue = (datum: number) => datum;
const getColor = () => ColorSwatch.cornflowerBlue;
const margins = { top: 8, left: 0, right: 48, bottom: 8 };

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.ongoingProjects.empty',
      defaultMessage: 'No Ongoing Projects',
    })}
  />
);

interface OngoingProjectsProps {
  workspaceIdList: string[];
}

export const OngoingProjects = ({ workspaceIdList }: OngoingProjectsProps) => {
  const { data, loading } = useQuery(GET_ONGOING_PROJECTS, {
    variables: {
      workspaceIdList,
    },
  });

  const { rows, maxValue } = useMemo(() => {
    const rows = data?.getWorkspaceOngoingProjects.items ?? [];
    const maxValue = max(rows, (d) => d.days) || 1;

    return {
      rows,
      maxValue,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.ongoingProjects.title', defaultMessage: 'Top In Progress Projects by Duration' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.ongoingProjects.hint',
        defaultMessage: 'The duration of days that projects have been in the In Progress status.',
      })}
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => row.entity.vecticeId}
        emptyText={<NoData />}
      >
        <Column
          key="entity"
          title={$t({ id: 'dashboard.ongoingProjects.column.project', defaultMessage: 'Project' })}
          ellipsis
        >
          {(project: Pick<Project, 'vecticeId' | 'name'>) => (
            <ProjectTooltip id={project.vecticeId}>
              <Typography
                component={Link}
                to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })}
                color="primary"
                link
              >
                {project.name}
              </Typography>
            </ProjectTooltip>
          )}
        </Column>
        <Column
          key="entity.phasesCounts"
          title={$t({ id: 'dashboard.ongoingProjects.column.phaseProgress', defaultMessage: 'Phase Progress' })}
          width="220px"
        >
          {(phasesCounts: DocumentationCounts) => <PhasesProgress phasesCounts={phasesCounts} width={180} />}
        </Column>
        <Column
          key="days"
          title={$t({ id: 'dashboard.ongoingProjects.column.daysInProgress', defaultMessage: 'Days In Progress' })}
          width="200px"
        >
          {(days: number) => (
            <StackedBar
              data={[days]}
              getValue={getValue}
              getColor={getColor}
              maxValue={maxValue}
              rightContent={
                <Typography variant="callout" color="primary">
                  {days}
                </Typography>
              }
              margins={margins}
            />
          )}
        </Column>
        <Column
          key="entity.owner"
          title={$t({ id: 'dashboard.ongoingProjects.column.projectOwner', defaultMessage: 'Owner' })}
          align="center"
          width="55px"
        >
          {(owner: UserIdentity) => <UserAvatar user={owner} size="xs" />}
        </Column>
      </Table>
    </DashboardCard>
  );
};
