import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import React from 'react';

import { getArray } from '../../utils';

import { Column } from './Column';
import { ExpandIcon } from './ExpandIcon';
import { ColumnsType, ColumnType, DefaultRecordType } from './types';

export function sanitizeColumnKey<T extends string | null | undefined>(key: T): T {
  return key ? (key.replace('.', '_') as T) : key;
}

export function convertChildrenToColumns<RecordType>(
  children: React.ReactNode,
  injectSorting: (column: ColumnType<RecordType>) => ColumnType<RecordType>,
): ColumnsType<RecordType> {
  const childrenArray = getArray<any>(children);

  return childrenArray
    .flat()
    .filter((node) => React.isValidElement(node))
    .map((child) => {
      const { children: nodeChildren, ...restProps } = child.props;
      const column = {
        key: child?.key,
        dataIndex: (child?.key || '').split('.'),
        ...restProps,
      };

      if (child?.type?.name === Column.name) {
        column.render = nodeChildren || ((data: any) => data?.toString());
      } else if (nodeChildren) {
        column.children = convertChildrenToColumns<RecordType>(nodeChildren, injectSorting);
      }

      return injectSorting(column);
    });
}

export function getExpandableProps<RecordType extends DefaultRecordType>(
  expandable: RcTableProps<RecordType>['expandable'],
  withSelection?: boolean,
): RcTableProps<RecordType>['expandable'] | undefined {
  if (!expandable) return undefined;

  return {
    expandIconColumnIndex: withSelection ? 1 : 0,
    indentSize: 15,
    expandIcon: (props) => ExpandIcon<RecordType>(props),
    ...expandable,
  };
}
