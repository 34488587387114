import cn from 'classnames';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  buildLink,
  isDatasetRoute,
  isDatasetVersionRoute,
  isIterationRoute,
  isModelRoute,
  isModelVersionRoute,
  isPhaseRoute,
  isProjectRoute,
  isReportRoute,
  isWorkspaceRoute,
  VecticeRoutes,
} from '../../../routes';
import { Typography } from '../../../ui';

import styles from './Links.module.scss';

function isWorkspaceLinkActive(pathname: string) {
  return (
    isWorkspaceRoute(pathname) ||
    isProjectRoute(pathname) ||
    isPhaseRoute(pathname) ||
    isIterationRoute(pathname) ||
    isDatasetRoute(pathname) ||
    isDatasetVersionRoute(pathname) ||
    isModelRoute(pathname) ||
    isModelVersionRoute(pathname) ||
    isReportRoute(pathname)
  );
}

export const WorkspacesLink = () => {
  const location = useLocation();
  const workspaceLinkActive = isWorkspaceLinkActive(location.pathname);

  return (
    <NavLink to={buildLink(VecticeRoutes.WORKSPACES)} className={cn(styles.link, { active: workspaceLinkActive })}>
      <Typography variant="callout" weight="semi-bold" color="white" ellipsis className={styles.link_label}>
        {$t({ id: 'navbar.links.workspace', defaultMessage: 'Workspaces' })}
      </Typography>
    </NavLink>
  );
};
