import React, { useMemo } from 'react';

import { MentionNotificationContent } from '../../../entities';
import { VecticeResourceType } from '../../../utils';
import { UserNotificationMention } from '../types';

import { MentionNotificationFinding } from './MentionNotificationFinding';
import { MentionNotificationPhase } from './MentionNotificationPhase';
import { MentionNotificationReport } from './MentionNotificationReport';

interface WorkspaceMentionNotificationProps {
  notification: UserNotificationMention;
  onClose: () => void;
}

export const MentionNotification = ({ notification, onClose }: WorkspaceMentionNotificationProps) => {
  const { pageId, pageType, pageName }: MentionNotificationContent = useMemo(() => {
    try {
      return JSON.parse(notification.content);
    } catch {
      return {};
    }
  }, [notification]);

  if (!pageId) {
    return null;
  }

  if (pageType === VecticeResourceType.CDT_REPORT) {
    return (
      <MentionNotificationReport
        notification={notification}
        onClose={onClose}
        reportId={parseInt(pageId, 10)}
        reportName={pageName}
      />
    );
  }

  if (pageType === VecticeResourceType.FINDING) {
    return (
      <MentionNotificationFinding
        notification={notification}
        onClose={onClose}
        findingId={parseInt(pageId, 10)}
        reportName={pageName}
      />
    );
  }

  // pre existing default for pageType is 'phase'
  return (
    <MentionNotificationPhase notification={notification} onClose={onClose} phaseId={pageId} phaseName={pageName} />
  );
};
