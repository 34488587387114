import React, { useRef } from 'react';

import { getDatasetTypeOptions } from '../../../entities/utils';
import { Icon } from '../../../ui';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'dsTypes';

const sizeIcon = 18;

class DatasetTypeFilterNode<T> extends FilterNode<T> {
  private options = getDatasetTypeOptions().map(({ icon, ...option }) => ({
    ...option,
    icon: icon ? <Icon icon={icon} size={sizeIcon} /> : null,
  }));

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="dataset-type-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const DatasetTypeFilter = () => {
  const { current: node } = useRef(new DatasetTypeFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.datasetType.label', defaultMessage: 'Dataset Type' }),
    node,
  });

  return null;
};
