import cn from 'classnames';
import React, { ReactNode } from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './Empty.module.scss';

interface EmptySelectProps extends VecticeHTMLProps<HTMLDivElement> {
  text?: ReactNode;
}

export const EmptySelect = ({ text = 'No Results', ...props }: EmptySelectProps) => (
  <div className={cn(styles.empty, styles.borderless)} {...props}>
    <Icon icon={VecticeIcon} size={60} className={styles.icon} />
    <Typography paragraph weight="semi-bold" color="disabled" align="center" className={styles.message}>
      {text}
    </Typography>
  </div>
);
