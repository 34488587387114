import cn from 'classnames';
import React, { useRef } from 'react';

import { Typography } from '../typography';

import styles from './Section.module.scss';

interface SectionProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'label'> {
  label?: React.ReactNode | ((ariaId: string) => React.ReactNode);
}

let globalId = 0;

export const Section = React.forwardRef(
  ({ className, label, children, ...props }: SectionProps, ref?: React.Ref<HTMLDivElement>) => {
    // eslint-disable-next-line no-plusplus
    const { current: sectionId } = useRef(`section-${globalId++}`);

    const labelComponent = () => {
      if (typeof label === 'string') {
        return (
          <Typography
            id={sectionId}
            component="h3"
            variant="callout"
            color="primary"
            weight="semi-bold"
            className={styles.title}
          >
            {label}
          </Typography>
        );
      }

      if (label instanceof Function) {
        return <div className={styles.title}>{label(sectionId)}</div>;
      }

      return (
        <div id={sectionId} className={styles.title}>
          {label}
        </div>
      );
    };

    return (
      <section
        className={cn(styles.section, className)}
        aria-labelledby={label ? sectionId : undefined}
        {...props}
        ref={ref}
      >
        {label && labelComponent()}
        {children}
      </section>
    );
  },
);
