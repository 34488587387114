import { graphql } from '../../gql';

export const GET_TEMPLATE_HEADER_FOOTER_CONTENT = graphql(`
  query TemplateHeaderFooterContent($id: Float) {
    CDTTemplate(id: $id) {
      id
      name
      headerContent
      footerContent
    }
  }
`);

export const GET_REPORT_HEADER_FOOTER_CONTENT = graphql(`
  query ReportHeaderFooterContent($id: Float!) {
    CDTReport(id: $id) {
      fileId
      name
      file {
        headerContent
        footerContent
      }
    }
  }
`);

export const GET_FINDING_HEADER_FOOTER_CONTENT = graphql(`
  query FindingHeaderFooterContent($id: Float!) {
    Finding(id: $id) {
      fileId
      name
      file {
        headerContent
        footerContent
      }
    }
  }
`);

export const UPDATE_TEMPLATE_HEADER_FOOTER_CONTENT = graphql(`
  mutation updateCDTHeaderFooterContent($id: Float!, $data: CDTTemplateUpdateInput!) {
    updateCDTTemplate(id: $id, data: $data) {
      id
      content
      headerContent
      footerContent
    }
  }
`);

export const UPDATE_DOCFILE_HEADER_FOOTER_CONTENT = graphql(`
  mutation updateDocFileHeaderFooterContent($fileId: Float!, $data: FileHeaderFooterUpdateInput!) {
    updateFileHeaderFooter(fileId: $fileId, data: $data) {
      id
      headerContent
      footerContent
    }
  }
`);
