import { ModelVersionApproval } from '../../gql/graphql';
import { BadgeColors } from '../../ui';

type modelVersionApprovalType = ModelVersionApproval | string | null;

export const getModelVersionApprovalDisplayName = (Approval?: modelVersionApprovalType) => {
  switch (Approval) {
    case 'ToValidate':
      return $t({ id: 'modelVersionApproval.toValidate.label', defaultMessage: 'To validate' });
    case 'InValidation':
      return $t({ id: 'modelVersionApproval.inValidation.label', defaultMessage: 'In validation' });
    case 'Validated':
      return $t({ id: 'modelVersionApproval.validated.label', defaultMessage: 'Validated' });
    case 'Approved':
      return $t({ id: 'modelVersionApproval.approved.label', defaultMessage: 'Approved' });
    case 'Rejected':
      return $t({ id: 'modelVersionApproval.rejected.label', defaultMessage: 'Rejected' });
    default:
      return $t({ id: 'modelVersionApproval.unknown.label', defaultMessage: 'Undefined' });
  }
};

export const getModelVersionApprovalColor = (status?: modelVersionApprovalType): BadgeColors => {
  switch (status) {
    case ModelVersionApproval.ToValidate:
      return 'default';
    case ModelVersionApproval.InValidation:
      return 'warning';
    case ModelVersionApproval.Validated:
      return 'on-track';
    case ModelVersionApproval.Approved:
      return 'success';
    case ModelVersionApproval.Rejected:
      return 'danger';
    default:
      return 'default';
  }
};

export const getModelVersionApprovalOptions = () => [
  {
    label: getModelVersionApprovalDisplayName(ModelVersionApproval.ToValidate),
    value: ModelVersionApproval.ToValidate,
  },
  {
    label: getModelVersionApprovalDisplayName(ModelVersionApproval.InValidation),
    value: ModelVersionApproval.InValidation,
  },
  {
    label: getModelVersionApprovalDisplayName(ModelVersionApproval.Validated),
    value: ModelVersionApproval.Validated,
  },
  {
    label: getModelVersionApprovalDisplayName(ModelVersionApproval.Approved),
    value: ModelVersionApproval.Approved,
  },
  {
    label: getModelVersionApprovalDisplayName(ModelVersionApproval.Rejected),
    value: ModelVersionApproval.Rejected,
  },
];
