import { VecticeRoutes } from './routes-names';

export interface VecticeRouteConfig {
  path: string;
  parent?: VecticeRoutes;
}

export const VecticeRoutesConfiguration: Record<VecticeRoutes, VecticeRouteConfig> = {
  // *****************************
  // UNSAFE ROUTES
  // *****************************
  [VecticeRoutes.LOGIN]: {
    path: '/login',
  },
  [VecticeRoutes.SIGNUP]: {
    path: '/sign-up',
  },
  [VecticeRoutes.AUTH]: {
    path: '/auth',
  },
  [VecticeRoutes.LOGGED_OUT]: {
    path: '/logged-out',
  },
  [VecticeRoutes.LOGOUT]: {
    path: '/logout',
  },
  [VecticeRoutes.UNKNOWN_USER]: {
    path: '/unknown-user',
  },
  [VecticeRoutes.REGISTER]: {
    path: '/register',
  },
  [VecticeRoutes.REGISTER_TOKEN]: {
    path: '/:token?',
    parent: VecticeRoutes.REGISTER,
  },
  [VecticeRoutes.REGISTER_INVALID_TOKEN]: {
    path: '/invalid',
    parent: VecticeRoutes.REGISTER,
  },
  [VecticeRoutes.REGISTER_INVITE_NOT_FOUND]: {
    path: '/not-found',
    parent: VecticeRoutes.REGISTER,
  },
  [VecticeRoutes.REGISTER_INVITE_ALREADY_ACCEPTED]: {
    path: '/already-accepted',
    parent: VecticeRoutes.REGISTER,
  },
  [VecticeRoutes.REGISTER_INVITE_EXPIRED]: {
    path: '/expired',
    parent: VecticeRoutes.REGISTER,
  },
  [VecticeRoutes.PASSWORD_RESET]: {
    path: '/reset-password',
  },
  [VecticeRoutes.PASSWORD_RESET_TOKEN]: {
    path: '/:token?',
    parent: VecticeRoutes.PASSWORD_RESET,
  },
  [VecticeRoutes.PASSWORD_RESET_RESENT]: {
    path: '/resent',
    parent: VecticeRoutes.PASSWORD_RESET,
  },
  [VecticeRoutes.PASSWORD_RESET_EXPIRED]: {
    path: '/expired',
    parent: VecticeRoutes.PASSWORD_RESET,
  },
  [VecticeRoutes.PASSWORD_RESET_NOT_FOUND]: {
    path: '/not-found',
    parent: VecticeRoutes.PASSWORD_RESET,
  },
  [VecticeRoutes.PASSWORD_RESET_RESENT_FAILED]: {
    path: '/resent-failed',
    parent: VecticeRoutes.PASSWORD_RESET,
  },
  [VecticeRoutes.FORGOT_MY_PASSWORD]: {
    path: '/forgot-my-password',
  },
  [VecticeRoutes.VERIFY_EMAIL]: {
    path: '/verify-email/:token?',
  },
  // *****************************
  // SAFE ROUTES
  // *****************************
  [VecticeRoutes.HOME]: {
    path: '/home',
  },
  [VecticeRoutes.MY_WORKSPACE]: {
    path: '/my-workspace',
  },
  [VecticeRoutes.NOT_FOUND]: {
    path: '/not-found',
  },
  [VecticeRoutes.FORBIDDEN]: {
    path: '/forbidden/:workspaceId',
  },
  [VecticeRoutes.SEARCH]: {
    path: '/search',
  },
  [VecticeRoutes.BROWSE]: {
    path: '/browse/:type/:id?',
  },
  // ACCOUNT
  [VecticeRoutes.ACCOUNT]: {
    path: '/account',
  },
  [VecticeRoutes.ACCOUNT_PROFILE]: {
    path: '/profile',
    parent: VecticeRoutes.ACCOUNT,
  },
  [VecticeRoutes.ACCOUNT_API_KEYS]: {
    path: '/api-keys',
    parent: VecticeRoutes.ACCOUNT,
  },
  [VecticeRoutes.MY_ACTIVITY]: {
    path: '/my-activity',
    parent: VecticeRoutes.ACCOUNT,
  },
  [VecticeRoutes.ACCOUNT_CHANGE_PASSWORD]: {
    path: '/change-password',
    parent: VecticeRoutes.ACCOUNT,
  },
  // WORKSPACES
  [VecticeRoutes.WORKSPACES]: {
    path: '/workspaces',
  },
  // WORKSPACE
  [VecticeRoutes.WORKSPACE]: {
    path: '/workspace/:workspaceId',
  },
  [VecticeRoutes.WORKSPACE_ACTIVITY]: {
    path: '/activity',
    parent: VecticeRoutes.WORKSPACE,
  },
  [VecticeRoutes.WORKSPACE_PROJECTS]: {
    path: '/projects',
    parent: VecticeRoutes.WORKSPACE,
  },
  [VecticeRoutes.WORKSPACE_DASHBOARD]: {
    path: '/dashboard',
    parent: VecticeRoutes.WORKSPACE,
  },
  [VecticeRoutes.WORKSPACE_DASHBOARD_PROJECT_PROGRESS]: {
    path: '/project-progress',
    parent: VecticeRoutes.WORKSPACE_DASHBOARD,
  },
  [VecticeRoutes.WORKSPACE_DASHBOARD_TEAM_ACTIVITY]: {
    path: '/team-activity',
    parent: VecticeRoutes.WORKSPACE_DASHBOARD,
  },
  [VecticeRoutes.WORKSPACE_SETTINGS]: {
    path: '/settings',
    parent: VecticeRoutes.WORKSPACE,
  },
  [VecticeRoutes.WORKSPACE_MEMBERS]: {
    path: '/members',
    parent: VecticeRoutes.WORKSPACE,
  },
  [VecticeRoutes.WORKSPACE_MEMBERS_ADD]: {
    path: '/members/add',
    parent: VecticeRoutes.WORKSPACE,
  },
  //EXPORT
  [VecticeRoutes.EXPORT_PHASE]: {
    path: '/export/phase/:phaseId',
  },
  [VecticeRoutes.RAW_PHASE_EXPORT]: {
    path: '/raw-export/phase/:phaseId',
  },
  [VecticeRoutes.RAW_REPORT_EXPORT]: {
    path: '/raw-export/report/:reportId',
  },
  [VecticeRoutes.RAW_FINDING_EXPORT]: {
    path: '/raw-export/finding/:findingId',
  },
  // PROJECT
  [VecticeRoutes.PROJECT]: {
    path: '/project/:projectId',
  },
  [VecticeRoutes.PROJECT_DOCUMENTATION]: {
    path: '/documentation',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_PHASES]: {
    path: '/phases',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_DATASETS]: {
    path: '/datasets',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_MODELS]: {
    path: '/models',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_ACTIVITY]: {
    path: '/activity',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_SETTINGS]: {
    path: '/settings',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_CDT_REPORTS]: {
    path: '/reports',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.PROJECT_FINDINGS]: {
    path: '/issues',
    parent: VecticeRoutes.PROJECT,
  },
  [VecticeRoutes.FINDING]: {
    path: '/issue/:findingId',
  },
  // CDT REPORT
  [VecticeRoutes.CDT_REPORT]: {
    path: '/report/:reportId',
  },
  [VecticeRoutes.CDT_EDIT_TEMPLATE]: {
    path: '/template/:templateId',
  },
  [VecticeRoutes.CDT_EDIT_MACRO]: {
    path: '/macro/:macroId',
  },
  // PHASES
  [VecticeRoutes.PHASE]: {
    path: '/phase/:phaseId',
  },
  [VecticeRoutes.PHASE_DOCUMENTATION]: {
    path: '/documentation',
    parent: VecticeRoutes.PHASE,
  },
  [VecticeRoutes.PHASE_ITERATIONS]: {
    path: '/iterations',
    parent: VecticeRoutes.PHASE,
  },
  [VecticeRoutes.PHASE_REVIEWS]: {
    path: '/reviews',
    parent: VecticeRoutes.PHASE,
  },
  [VecticeRoutes.PHASE_SETTINGS]: {
    path: '/settings',
    parent: VecticeRoutes.PHASE,
  },
  [VecticeRoutes.ITERATION]: {
    path: '/iteration/:iterationId',
  },
  // DATASET
  [VecticeRoutes.DATASET]: {
    path: '/dataset/:datasetId',
  },
  [VecticeRoutes.DATASET_VERSIONS]: {
    path: '/versions',
    parent: VecticeRoutes.DATASET,
  },
  [VecticeRoutes.DATASET_ACTIVITY]: {
    path: '/activity',
    parent: VecticeRoutes.DATASET,
  },
  [VecticeRoutes.DATASET_SETTINGS]: {
    path: '/settings',
    parent: VecticeRoutes.DATASET,
  },
  // DATASET VERSION
  [VecticeRoutes.DATASET_VERSION]: {
    path: '/dataset-version/:datasetVersionId',
  },
  [VecticeRoutes.DATASET_VERSION_ACTIVITY]: {
    path: '/activity',
    parent: VecticeRoutes.DATASET_VERSION,
  },
  // MODEL
  [VecticeRoutes.MODEL]: {
    path: '/model/:modelId',
  },
  [VecticeRoutes.MODEL_VERSIONS]: {
    path: '/versions',
    parent: VecticeRoutes.MODEL,
  },
  [VecticeRoutes.MODEL_ACTIVITY]: {
    path: '/activity',
    parent: VecticeRoutes.MODEL,
  },
  [VecticeRoutes.MODEL_SETTINGS]: {
    path: '/settings',
    parent: VecticeRoutes.MODEL,
  },
  // MODEL VERSION
  [VecticeRoutes.MODEL_VERSION]: {
    path: '/model-version/:modelVersionId',
  },
  [VecticeRoutes.MODEL_VERSION_ACTIVITY]: {
    path: '/activity',
    parent: VecticeRoutes.MODEL_VERSION,
  },
  // ORGANIZATION
  [VecticeRoutes.ORGANIZATION]: {
    path: '/organization',
  },
  [VecticeRoutes.ORGANIZATION_USERS]: {
    path: '/users',
    parent: VecticeRoutes.ORGANIZATION,
  },
  [VecticeRoutes.ORGANIZATION_WORKSPACES]: {
    path: '/workspaces',
    parent: VecticeRoutes.ORGANIZATION,
  },
  [VecticeRoutes.CDT_TEMPLATES]: {
    path: '/templates',
    parent: VecticeRoutes.ORGANIZATION,
  },
  [VecticeRoutes.CDT_MACROS]: {
    path: '/macros',
    parent: VecticeRoutes.ORGANIZATION,
  },
  [VecticeRoutes.ORGANIZATION_AUTHENTICATION_METHODS]: {
    path: '/authentications',
    parent: VecticeRoutes.ORGANIZATION,
  },
  [VecticeRoutes.ORGANIZATION_AUTHENTICATION_CREATION]: {
    path: '/create',
    parent: VecticeRoutes.ORGANIZATION_AUTHENTICATION_METHODS,
  },
  [VecticeRoutes.ORGANIZATION_AUTHENTICATION_EDIT]: {
    path: '/edit/:alias',
    parent: VecticeRoutes.ORGANIZATION_AUTHENTICATION_METHODS,
  },
  // ADMINISTRATION
  [VecticeRoutes.ADMINISTRATION]: {
    path: '/administration',
  },
  [VecticeRoutes.ADMINISTRATION_ORGANIZATIONS]: {
    path: '/organizations',
    parent: VecticeRoutes.ADMINISTRATION,
  },
  [VecticeRoutes.ADMINISTRATION_ORGANIZATIONS_CREATION]: {
    path: '/create',
    parent: VecticeRoutes.ADMINISTRATION_ORGANIZATIONS,
  },
  [VecticeRoutes.ADMINISTRATION_ORGANIZATION]: {
    path: '/organization/:organizationId',
    parent: VecticeRoutes.ADMINISTRATION,
  },
  [VecticeRoutes.ADMINISTRATION_SETTINGS]: {
    path: '/settings',
    parent: VecticeRoutes.ADMINISTRATION,
  },
  [VecticeRoutes.ADMINISTRATION_SUPER_ADMIN_USERS]: {
    path: '/super-admin-users',
    parent: VecticeRoutes.ADMINISTRATION,
  },
  [VecticeRoutes.ADMINISTRATION_FEATURE_FLAGS]: {
    path: '/feature-flags',
    parent: VecticeRoutes.ADMINISTRATION,
  },
  [VecticeRoutes.ADMINISTRATION_ARCHIVES]: {
    path: '/archives',
    parent: VecticeRoutes.ADMINISTRATION,
  },
  [VecticeRoutes.ADMINISTRATION_LOG]: {
    path: '/log',
    parent: VecticeRoutes.ADMINISTRATION,
  },
};
