import { useQuery } from '@apollo/client';
import React from 'react';

import { graphql } from '../../../gql';
import { message, TypographyVariants } from '../../../ui';

import { ModelVersionLink } from './ModelVersionLink';

interface ModelVersionLinkWithoutNameProps {
  resourceId: string;
  iconSize?: number;
  variant?: TypographyVariants;
}

const GET_MODEL_VERSION = graphql(`
  query getModelVersionLinkInfo($modelVersionId: VecticeId!) {
    getModelVersion(modelVersionId: $modelVersionId) {
      versionNumber
      model {
        name
      }
    }
  }
`);

export const ModelVersionLinkWithoutName = ({ resourceId, variant, iconSize }: ModelVersionLinkWithoutNameProps) => {
  const { data } = useQuery(GET_MODEL_VERSION, {
    fetchPolicy: 'cache-and-network',
    skip: !resourceId,
    variables: { modelVersionId: resourceId! },
    onError: (error) => message.error(error.message),
  });

  const modelVersion = data?.getModelVersion;

  if (!modelVersion) return null;
  return (
    <ModelVersionLink
      resourceId={resourceId}
      name={`${modelVersion.model.name} - v${modelVersion.versionNumber}`}
      variant={variant}
      iconSize={iconSize}
    />
  );
};
