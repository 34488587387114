import React from 'react';

import { ReactComponent as DatasetIcon } from '../../../assets/icons/specials/ic-dataset.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { DatasetTooltip } from './DatasetTooltip';

interface DatasetLinkProps {
  name?: string | null;
  resourceId?: string | null;
}

export const DatasetLink = ({ name, resourceId }: DatasetLinkProps) => (
  <DatasetTooltip id={resourceId} placement="topLeft">
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.DATASET, { datasetId: resourceId }) : undefined}
      icon={DatasetIcon}
    />
  </DatasetTooltip>
);
