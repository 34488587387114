import { useOrgConfig } from '../context';
import { UserRole } from '../gql/graphql';

import { useWithRoles } from './useWithRoles';

export const useWithWorkspaceCreationRight = () => {
  const { loading: loadingUser, error, allowed, userRole } = useWithRoles({ organizationRole: UserRole.OrgAdmin });
  const { configuration, loading: loadingOrgConfig } = useOrgConfig();

  return {
    allowed: userRole !== UserRole.Observer && (allowed || configuration.everyOneCanCreateWorkspace),
    loading: loadingUser || loadingOrgConfig,
    error: error,
  };
};
