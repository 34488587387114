import React, { Suspense } from 'react';

import type { LightboxProps } from '../../ui';

import { ReactComponent as TableIcon } from '../../assets/icons/editor/ic-table.svg';
import { AttachmentOutput, EntityFile } from '../../gql/graphql';
import { FlexContainer, Icon, Lightbox, Loading, Typography } from '../../ui';
import { downloadEntityFile, getMimeTypeIcon, isImageFile, isNotebookFile } from '../../utils';

import { EntityFilePreview } from './EntityFilePreview';

type AttachmentPreview = Pick<AttachmentOutput, 'id' | 'name' | 'mimeType' | 'content'>;
type EntityFilePreview = Pick<EntityFile, 'id' | 'fileName' | 'mimeType'>;
type AssetPreview = AttachmentPreview | EntityFilePreview;

function isAttachmentPreview(asset: AssetPreview): asset is AttachmentPreview {
  return 'name' in asset;
}

interface LightboxPreviewProps extends Omit<LightboxProps, 'content' | 'title' | 'onDownload'> {
  entityFile: AssetPreview;
}

export const LightboxPreview = ({ entityFile, ...props }: LightboxPreviewProps) => {
  const lightboxProps = {
    onDownload: entityFile.mimeType ? () => downloadEntityFile(entityFile) : undefined,
    fullscreen: isImageFile(entityFile.mimeType),
    scrollable: isNotebookFile(entityFile.mimeType),
  };
  const icon = entityFile.mimeType ? getMimeTypeIcon(entityFile.mimeType) : TableIcon;

  return (
    <Lightbox
      aria-label="File preview"
      content={
        <Suspense fallback={<Loading color="white" />}>
          <EntityFilePreview
            mimeType={entityFile.mimeType}
            entityFileId={entityFile.id}
            content={isAttachmentPreview(entityFile) && entityFile.content}
            noPreviewVariant="white"
            isLightBoxPreview
          />
        </Suspense>
      }
      title={
        <FlexContainer gap={4}>
          <Icon icon={icon} size={34} />
          <Typography ellipsis component="div" variant="heading2" color="white">
            {isAttachmentPreview(entityFile) ? entityFile.name : entityFile.fileName}
          </Typography>
        </FlexContainer>
      }
      {...lightboxProps}
      {...props}
    />
  );
};
