export const cleanFilters = (plugins: string[], filters: Record<string, any>) => {
  const cleanedUpFilters: Record<string, any> = {};
  Object.entries(filters).forEach(([label, filter]) => {
    if (plugins.includes(label)) {
      if (Array.isArray(filter)) {
        const filteredFilters = filter.filter((f) => !Object.getOwnPropertyDescriptor(f, 'value') || f.value);
        if (filteredFilters.length) {
          cleanedUpFilters[label] = filteredFilters;
        }
      } else {
        cleanedUpFilters[label] = filter;
      }
    }
  });

  return cleanedUpFilters;
};
