import { useQuery } from '@apollo/client';
import React, { PropsWithChildren, useRef } from 'react';
import { Link } from 'react-router-dom';

import type { AssetWidgetProps } from '../../../ui';

import { graphql } from '../../../gql';
import { ArtifactType, EntityPropertyType } from '../../../gql/graphql';
import { useAttachments } from '../../../hooks';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetWidget, FlexContainer, Icon, Summary, Typography } from '../../../ui';
import { getVecticeResourceTypeIcon, VecticeResourceType } from '../../../utils';
import { Lineage } from '../../lineage/Lineage';
import { SummaryAttachments } from '../../summary-attachments';
import { VersionPropertiesWidget } from '../../widgetComponents';

import { DatasetVersionResourceWidget } from './DatasetVersionResourceWidget';
import { DatasetVersionRow } from './DatasetVersionRow';

import styles from './DatasetVersionWidget.module.scss';

export const GET_DATASET_VERSION_WIDGET = graphql(`
  query getDatasetVersionWidget($id: VecticeId!) {
    getDatasetVersion(datasetVersionId: $id) {
      vecticeId
      name
      versionNumber
      ...datasetVersionRow
      properties {
        id
      }
      dataSet {
        vecticeId
        name
      }
      datasetSources {
        id
        itemsCount
      }
      origin {
        id
        datasetInputs
      }
    }
  }
`);

let globalId = 0;

interface DatasetWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary'> {
  id?: string;
  nodeKey?: string;
  showLineage?: boolean;
  showProperties?: boolean;
  showAttachments?: boolean;
  showResources?: boolean;
  onAutoDoc?: () => void;
}

export const DatasetVersionWidget = ({
  children,
  id,
  nodeKey,
  showLineage = true,
  showProperties = true,
  showAttachments = true,
  showResources = true,
  onAutoDoc,
  actionsMenu,
  customizeMenu,
  ...props
}: PropsWithChildren<DatasetWidgetProps>) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`dataset-version-widget-${globalId++}`);
  const { data, error, loading } = useQuery(GET_DATASET_VERSION_WIDGET, {
    skip: !id,
    variables: { id: id! },
  });
  const { attachments, total: attachmentsTotal } = useAttachments(id, VecticeResourceType.DATASET_VERSION);

  const datasetVersion = data?.getDatasetVersion;

  if (error || !id) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        className="hide-pdf-export"
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getVecticeResourceTypeIcon(VecticeResourceType.DATASET_VERSION)} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                {$t({ id: 'datasetVersionWidget.asset.deleted', defaultMessage: 'Asset was deleted' })}
              </Typography>
            </FlexContainer>
          </div>
        }
        actionsMenu={actionsMenu}
        {...props}
        open={false}
        expandable={false}
      />
    );
  }

  const itemsCount = datasetVersion?.datasetSources.reduce(
    (accumulator, currentValue) => accumulator + currentValue.itemsCount,
    0,
  );

  const isExpandable = datasetVersion
    ? showLineage || showAttachments || showProperties || showResources || !!children
    : !!children;

  return (
    <AssetWidget
      aria-labelledby={ariaId}
      loading={loading}
      summary={
        <Link to={buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: id })} target="_blank">
          <DatasetVersionRow id={ariaId} datasetVersion={datasetVersion} onAutoDoc={onAutoDoc} interaction="tab" />
        </Link>
      }
      actionsMenu={customizeMenu}
      expandable={isExpandable}
      {...props}
    >
      {datasetVersion && (
        <>
          {showLineage && (
            <Summary
              className={styles.lineage}
              open={!!datasetVersion.origin}
              summary={
                <Typography component="h2" color="primary" variant="body" weight="semi-bold">
                  {$t({ id: 'datasetVersionWidget.lineage', defaultMessage: 'Lineage' })}
                </Typography>
              }
              withBackground={false}
            >
              <Lineage
                datasetInputs={datasetVersion.origin?.datasetInputs ?? []}
                lineageContext={{
                  artifactType: ArtifactType.Model,
                  lineageId: datasetVersion?.origin?.id,
                  parentId: datasetVersion.dataSet.vecticeId,
                  versionId: id,
                }}
                lineageId={datasetVersion?.origin?.id}
              />
            </Summary>
          )}
          {showResources && (
            <Summary
              className={styles.lineage}
              open={itemsCount !== 0}
              summary={
                <Typography component="h2" color="primary" variant="body" weight="semi-bold">
                  {$t({ id: 'datasetVersionWidget.resource', defaultMessage: 'Resource summary' })}
                </Typography>
              }
              withBackground={false}
            >
              <DatasetVersionResourceWidget
                name={datasetVersion.dataSet.name}
                resourceId={id!}
                versionNumber={datasetVersion.versionNumber}
              />
            </Summary>
          )}

          {showProperties && (
            <Summary
              className={styles.lineage}
              open={datasetVersion.properties.length > 0}
              summary={
                <Typography component="h2" color="primary" variant="body" weight="semi-bold">
                  {$t({ id: 'datasetVersionWidget.properties', defaultMessage: 'Properties' })}
                </Typography>
              }
              withBackground={false}
            >
              <VersionPropertiesWidget
                resourceId={id!}
                entityType={EntityPropertyType.DataSetVersion}
                emptyMessage={$t({
                  id: 'properties.empty.doc.message',
                  defaultMessage: 'No Properties',
                })}
              />
            </Summary>
          )}
          {showAttachments && (
            <SummaryAttachments nodeKey={nodeKey} open={attachmentsTotal > 0} attachments={attachments} />
          )}
        </>
      )}
      {children}
    </AssetWidget>
  );
};
