import React, { useMemo } from 'react';

import { useWithFeatureFlags } from '../../guards';
import {
  getEntityFileURL,
  isAudioFile,
  isCsvFile,
  isImageFile,
  isNotebookFile,
  isPdfFile,
  isVideoFile,
  isJsonFile,
  isTextFile,
  isTableAttachment,
  isHTMLFile,
} from '../../utils';

import { CsvRenderer } from './csv/CsvRenderer';
import { EntityFilePreviewProps } from './interfaces';
import { NoPreview } from './NoPreview';

const AudioPreview = React.lazy(() => import('./audio'));
const CsvPreview = React.lazy(() => import('./csv'));
const ImagePreview = React.lazy(() => import('./image'));
const NotebookPreview = React.lazy(() => import('./notebook'));
const PdfPreview = React.lazy(() => import('./pdf'));
const VideoPreview = React.lazy(() => import('./video'));
const TextPreview = React.lazy(() => import('./text'));
const HTMLPreview = React.lazy(() => import('./html'));

export const EntityFilePreview = ({
  entityFileId,
  mimeType,
  noPreviewVariant,
  extractImg,
  content,
  isLightBoxPreview,
  hasStaticView,
  handleShowPreview,
}: EntityFilePreviewProps) => {
  const { allowed: previewHTMLEnabled } = useWithFeatureFlags({ featureFlag: 'preview-html-attachment' });

  return useMemo(() => {
    if (isTableAttachment(mimeType, content)) {
      return <CsvRenderer content={JSON.stringify(content.data)} />;
    }

    if (isNotebookFile(mimeType)) {
      return (
        <NotebookPreview entityFileId={entityFileId} noPreviewVariant={noPreviewVariant} extractImg={extractImg} />
      );
    }

    if (isImageFile(mimeType)) {
      return <ImagePreview url={getEntityFileURL({ fileId: entityFileId })} noPreviewVariant={noPreviewVariant} />;
    }

    if (isCsvFile(mimeType)) {
      return <CsvPreview entityFileId={entityFileId} noPreviewVariant={noPreviewVariant} />;
    }

    if (isPdfFile(mimeType)) {
      return <PdfPreview url={getEntityFileURL({ fileId: entityFileId })} noPreviewVariant={noPreviewVariant} />;
    }

    if (isTextFile(mimeType)) {
      return <TextPreview entityFileId={entityFileId} extractImg={extractImg} noPreviewVariant={noPreviewVariant} />;
    }

    if (isHTMLFile(mimeType)) {
      const textPreview = (
        <TextPreview entityFileId={entityFileId} extractImg={extractImg} noPreviewVariant={noPreviewVariant} />
      );
      if (previewHTMLEnabled) {
        if (isLightBoxPreview) {
          return <HTMLPreview url={getEntityFileURL({ fileId: entityFileId })} noPreviewVariant={noPreviewVariant} />;
        }
        if (hasStaticView) {
          return (
            <div
              onClick={() => {
                if (handleShowPreview) {
                  handleShowPreview();
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <ImagePreview
                url={getEntityFileURL({ fileId: entityFileId, hasStaticView })}
                noPreviewVariant={noPreviewVariant}
              />
            </div>
          );
        }
        return textPreview;
      }
      return textPreview;
    }

    if (isJsonFile(mimeType)) {
      return (
        <TextPreview entityFileId={entityFileId} extractImg={extractImg} noPreviewVariant={noPreviewVariant} isJson />
      );
    }

    if (isAudioFile(mimeType)) {
      return (
        <AudioPreview
          url={getEntityFileURL({ fileId: entityFileId })}
          mimeType={mimeType}
          noPreviewVariant={noPreviewVariant}
        />
      );
    }

    if (isVideoFile(mimeType)) {
      return (
        <VideoPreview
          url={getEntityFileURL({ fileId: entityFileId })}
          mimeType={mimeType}
          noPreviewVariant={noPreviewVariant}
        />
      );
    }

    return <NoPreview variant={noPreviewVariant} />;
  }, [mimeType, entityFileId, content]);
};
