import cn from 'classnames';
import RcAlign from 'rc-align';
import { AlignPoint, AlignType, TargetType } from 'rc-align/lib/interface';
import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import styles from './Align.module.scss';

const autoAdjustOverflow = {
  adjustX: true,
  adjustY: true,
};

const defaultTargetOffset = [0, 0];

const POSITIONS = {
  topLeft: {
    points: ['bl', 'tl'],
    overflow: autoAdjustOverflow,
    offset: [0, -4],
    targetOffset: defaultTargetOffset,
  },
  topCenter: {
    points: ['bc', 'tc'],
    overflow: autoAdjustOverflow,
    offset: [0, -4],
    targetOffset: defaultTargetOffset,
  },
  topRight: {
    points: ['br', 'tr'],
    overflow: autoAdjustOverflow,
    offset: [0, -4],
    targetOffset: defaultTargetOffset,
  },
  bottomLeft: {
    points: ['tl', 'bl'],
    overflow: autoAdjustOverflow,
    offset: [0, 4],
    targetOffset: defaultTargetOffset,
  },
  bottomCenter: {
    points: ['tc', 'bc'],
    overflow: autoAdjustOverflow,
    offset: [0, 4],
    targetOffset: defaultTargetOffset,
  },
  bottomRight: {
    points: ['tr', 'br'],
    overflow: autoAdjustOverflow,
    offset: [0, 4],
    targetOffset: defaultTargetOffset,
  },
  centerLeft: {
    points: ['cl', 'bl'],
    overflow: autoAdjustOverflow,
    offset: [0, 0],
    targetOffset: defaultTargetOffset,
  },
  centerCenter: {
    points: ['cc', 'bc'],
    overflow: autoAdjustOverflow,
    offset: [0, 0],
    targetOffset: defaultTargetOffset,
  },
  centerRight: {
    points: ['cr', 'br'],
    overflow: autoAdjustOverflow,
    offset: [0, 0],
    targetOffset: defaultTargetOffset,
  },
};

export type AlignTargetType = TargetType;

interface AlignProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'target'> {
  anchorElem?: HTMLElement;
  offset?: (number | string)[];
  placement?: keyof typeof POSITIONS;
  points?: AlignPoint[];
  target: AlignTargetType;
  targetOffset?: (number | string)[];
}

export const Align = ({
  anchorElem,
  className,
  offset,
  placement = 'bottomLeft',
  points,
  target,
  targetOffset,
  ...props
}: AlignProps) => {
  const defaultAnchorRef = useRef<HTMLElement>(document.createElement('div'));

  useLayoutEffect(() => {
    const anchorDiv = defaultAnchorRef.current;
    anchorDiv.style.position = 'absolute';
    anchorDiv.style.top = '0px';
    anchorDiv.style.left = '0px';
    anchorDiv.style.width = '100%';
    if (!anchorDiv.isConnected) {
      document.body.append(anchorDiv);
    }
    defaultAnchorRef.current = anchorDiv;

    return () => {
      document.body.removeChild(defaultAnchorRef.current);
    };
  }, []);

  // @ts-expect-error RcAlignType type is wrong
  const align: AlignType = {
    ...POSITIONS[placement],
    ...(points ? { points } : {}),
    ...(offset ? { offset } : {}),
    ...(targetOffset ? { targetOffset } : {}),
  };

  return createPortal(
    <div>
      <RcAlign align={align} target={target} monitorWindowResize>
        <div className={cn(styles.overlay, className)} {...props} />
      </RcAlign>
    </div>,
    anchorElem || defaultAnchorRef.current,
  );
};
