import React from 'react';

import { getFindingSeverityColor, getFindingSeverityDisplayName } from '../../entities/utils';
import { FindingSeverity } from '../../gql/graphql';
import { Badge, BadgeProps } from '../../ui';

interface SeverityBadgeProps extends BadgeProps {
  severity?: FindingSeverity | null;
  contrast?: boolean;
}

export const SeverityBadge = ({ size = 'sm', contrast = false, severity, ...props }: SeverityBadgeProps) => (
  <Badge size={size} contrast={contrast} color={getFindingSeverityColor(severity)} {...props}>
    {getFindingSeverityDisplayName(severity)}
  </Badge>
);
