import React from 'react';

import { isNil } from '../../../utils';
import { FormatDate, FormatDateProps } from '../../formatters';

import styles from './DateCell.module.scss';

interface DateCellProps extends Omit<FormatDateProps, 'date'> {
  date?: Date | null;
}

export const DateCell = ({ date, ...props }: DateCellProps) => {
  if (isNil(date)) {
    return null;
  }

  return (
    <div className={styles.updated}>
      <FormatDate date={date} {...props} />
    </div>
  );
};
