import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import type { AssetWidgetProps } from '../../../ui';

import { ReactComponent as TableIcon } from '../../../assets/icons/editor/ic-table.svg';
import { ReactComponent as FullscreenIcon } from '../../../assets/icons/interface/ic-fullscreen.svg';
import { ReactComponent as NewTabIcon } from '../../../assets/icons/interface/ic-link.svg';
import { ReactComponent as DatasetVersionIcon } from '../../../assets/icons/specials/ic-dataset-version.svg';
import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/ic-model-version.svg';
import { graphql } from '../../../gql';
import { EntityFile, EntityMetadata, IterationStepArtifact, IterationStepArtifactType } from '../../../gql/graphql';
import { VecticeRoutes, buildLink } from '../../../routes';
import { AssetWidget, Badge, EmptyData, EmptyList, FlexContainer, Icon, Summary, Typography } from '../../../ui';
import {
  VecticeResourceType,
  assetInfoRecord,
  getMimeTypeIcon,
  getVecticeResourceTypeIcon,
  isArtifactDeletedRecord,
} from '../../../utils';
import { LightboxEntityMetaPreview } from '../../previewEntityMetadata';
import { LightboxPreview } from '../../previews';
import { DatasetVersionTooltip } from '../dataset-version';
import { ModelVersionTooltip } from '../model-version';

import { IterationRow } from './IterationRow';

import styles from './IterationWidget.module.scss';

graphql(`
  fragment artifact on IterationStepArtifact {
    id
    text
    type
    datasetVersion {
      vecticeId
      versionNumber
      dataSet {
        vecticeId
        name
      }
    }
    entityFile {
      id
      fileName
      mimeType
      contentType
      entityId
    }
    modelVersion {
      vecticeId
      versionNumber
      model {
        vecticeId
        name
      }
    }
    entityMetadata {
      id
      name
    }
  }
`);

const GET_ITERATION_WIDGET = graphql(`
  query getIterationWidget($id: VecticeId!) {
    getIterationById(id: $id) {
      vecticeId
      description
      ...iterationRow
    }
  }
`);

export const GET_ITERATION_ARTIFACTS = graphql(`
  query getIterationArtifactsForWidget($id: VecticeId!) {
    getIterationById(id: $id) {
      paginatedArtifacts(page: { index: 1, size: 100 }) {
        items {
          id
          type
          ...artifact
        }
      }
    }
  }
`);

const GET_ITERATION_STEPS_WIDGET = graphql(`
  query getIterationStepListForWidget($id: VecticeId!) {
    sections(parentId: $id, page: { index: 1, size: 100 }, order: { field: "index", direction: ASC }) {
      items {
        id
        name
        description
        paginatedArtifacts(page: { index: 1, size: 100 }) {
          total
          items {
            id
            type
            ...artifact
          }
        }
      }
      total
    }
  }
`);

let globalId = 0;

interface IterationWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary'> {
  id: string;
  onAutoDoc?: () => void;
}

const IterationArtifact = ({
  artifact,
  onSelectEntityFile,
  onSelectEntityMetadata,
}: {
  artifact: Pick<
    IterationStepArtifact,
    'id' | 'text' | 'type' | 'datasetVersion' | 'modelVersion' | 'entityFile' | 'entityMetadata'
  >;
  onSelectEntityFile: (entityFile: EntityFile) => void;
  onSelectEntityMetadata: (entityMetadata: Pick<EntityMetadata, 'id' | 'name'>) => void;
}) => {
  if (isArtifactDeletedRecord[artifact.type](artifact)) {
    const { text, icon } = assetInfoRecord[artifact.type];
    return (
      <li key={`deleted-${artifact.id}`} className={styles.listItem}>
        <FlexContainer gap={4}>
          <Icon icon={icon} size={16} />
          <Typography className={styles.comment} color="secondary" paragraph variant="callout">
            {text()}
          </Typography>
        </FlexContainer>
      </li>
    );
  }
  if (artifact.type === IterationStepArtifactType.Comment && artifact.text) {
    return (
      <li key={`comment-${artifact.id}`} className={styles.listItem}>
        <Typography className={styles.comment} color="secondary" paragraph variant="callout">
          {artifact.text}
        </Typography>
      </li>
    );
  }
  if (artifact.datasetVersion) {
    return (
      <li key={`dataset-version-${artifact.datasetVersion.vecticeId}`} className={styles.listItem}>
        <FlexContainer gap={4}>
          <Icon icon={DatasetVersionIcon} size={16} />
          <DatasetVersionTooltip id={artifact.datasetVersion.vecticeId}>
            <Typography
              component={Link}
              to={buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: artifact.datasetVersion.vecticeId })}
              variant="callout"
              color="primary"
              target="_blank"
              className={styles.versionLink}
              link
              ellipsis
            >
              {artifact.datasetVersion.dataSet.name}
            </Typography>
          </DatasetVersionTooltip>
          <Badge variant="square" color="accent" size="sm">
            v{artifact.datasetVersion.versionNumber}
          </Badge>
          <Icon icon={NewTabIcon} size={16} className={styles.tab} />
        </FlexContainer>
      </li>
    );
  }
  if (artifact.modelVersion) {
    return (
      <li key={`model-version-${artifact.modelVersion.vecticeId}`} className={styles.listItem}>
        <FlexContainer gap={4}>
          <Icon icon={ModelVersionIcon} size={16} />
          <ModelVersionTooltip id={artifact.modelVersion.vecticeId}>
            <Typography
              component={Link}
              to={buildLink(VecticeRoutes.MODEL_VERSION, { modelVersionId: artifact.modelVersion.vecticeId })}
              variant="callout"
              color="primary"
              target="_blank"
              className={styles.versionLink}
              link
              ellipsis
            >
              {artifact.modelVersion.model.name}
            </Typography>
          </ModelVersionTooltip>
          <Badge variant="square" color="accent" size="sm">
            v{artifact.modelVersion.versionNumber}
          </Badge>
          <Icon icon={NewTabIcon} size={16} className={styles.tab} />
        </FlexContainer>
      </li>
    );
  }
  if (artifact.entityFile) {
    return (
      <li key={`entity-file-${artifact.entityFile.id}`} className={styles.listItem}>
        <FlexContainer gap={4}>
          <Icon icon={getMimeTypeIcon(artifact.entityFile.mimeType)} size={16} />
          <Typography
            variant="callout"
            color="primary"
            ellipsis
            onClick={() => (artifact.entityFile ? onSelectEntityFile(artifact.entityFile) : null)}
            className={styles.entityFile}
          >
            {artifact.entityFile.fileName}
          </Typography>
          <Icon icon={FullscreenIcon} size={16} className={styles.fullscreen} />
        </FlexContainer>
      </li>
    );
  }
  if (artifact.entityMetadata) {
    return (
      <li key={`entity-metadata-${artifact.entityMetadata.id}`} className={styles.listItem}>
        <FlexContainer gap={4}>
          <Icon icon={TableIcon} size={16} />
          <Typography
            variant="callout"
            color="primary"
            onClick={() => (artifact.entityMetadata ? onSelectEntityMetadata(artifact.entityMetadata) : null)}
            ellipsis
            className={styles.entityFile}
          >
            {artifact.entityMetadata.name}
          </Typography>
          <Icon icon={FullscreenIcon} size={16} className={styles.fullscreen} />
        </FlexContainer>
      </li>
    );
  }

  return null;
};

export const IterationWidget = ({ id, onAutoDoc, children, ...props }: IterationWidgetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`iteration-widget-${globalId++}`);
  const [selectedEntityFile, setSelectedEntityFile] = useState<Pick<
    EntityFile,
    'id' | 'fileName' | 'contentType' | 'mimeType'
  > | null>(null);
  const [selectedMetadata, setSelectedMetadata] = useState<Pick<EntityMetadata, 'id' | 'name'> | null>(null);

  const { data: iterationData, error } = useQuery(GET_ITERATION_WIDGET, {
    variables: { id },
  });

  const { data: iterationArtifactsData, loading: loadingIterationArtifacts } = useQuery(GET_ITERATION_ARTIFACTS, {
    skip: !props.open,
    variables: { id },
  });

  const { data: stepsData, loading: loadingSteps } = useQuery(GET_ITERATION_STEPS_WIDGET, {
    skip: !props.open,
    variables: { id },
  });

  const iteration = iterationData?.getIterationById;
  const iterationArtifacts = iterationArtifactsData?.getIterationById.paginatedArtifacts.items;
  const steps = stepsData?.sections.items;

  const noData = iterationArtifacts?.length === 0 && steps && steps?.length === 0;

  if (error) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        className="hide-pdf-export"
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getVecticeResourceTypeIcon(VecticeResourceType.ITERATION)} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                Iteration was deleted
              </Typography>
            </FlexContainer>
          </div>
        }
        {...props}
        open={false}
        expandable={false}
      />
    );
  }

  return (
    <>
      <AssetWidget
        aria-labelledby={ariaId}
        loading={loadingIterationArtifacts || loadingSteps}
        summary={
          <Link to={buildLink(VecticeRoutes.ITERATION, { iterationId: id })} target="_blank">
            <IterationRow id={ariaId} iteration={iteration} onAutoDoc={onAutoDoc} interaction="tab" />
          </Link>
        }
        {...props}
      >
        {iteration?.description && (
          <Typography variant="callout" color="tertiary" paragraph gutterBottom className={styles.description}>
            {iteration.description}
          </Typography>
        )}
        {children}
        {noData && (
          <EmptyData
            message={$t({
              id: 'widget.iteration.noSections',
              defaultMessage: 'No Sections',
            })}
          />
        )}
        {iterationArtifacts && iterationArtifacts?.length > 0 && (
          <Summary
            className={styles.section}
            detailsClassName={styles.sectionContent}
            open
            summary={
              <FlexContainer>
                <Typography variant="callout" weight="semi-bold">
                  {$t({
                    id: 'widget.iteration.artifacts.label',
                    defaultMessage: 'Assets not assigned to section',
                  })}
                </Typography>
              </FlexContainer>
            }
          >
            <FlexContainer component="ul" direction="column" gap={4}>
              {iterationArtifacts.map((artifact) => (
                <IterationArtifact
                  key={artifact.id}
                  artifact={artifact}
                  onSelectEntityFile={setSelectedEntityFile}
                  onSelectEntityMetadata={setSelectedMetadata}
                />
              ))}
            </FlexContainer>
          </Summary>
        )}
        {steps &&
          steps?.length > 0 &&
          steps.map(({ id, name, paginatedArtifacts: { items: artifacts, total } }) => (
            <Summary
              key={id}
              className={styles.section}
              detailsClassName={styles.sectionContent}
              open
              summary={
                <Typography variant="callout" weight="semi-bold">
                  {name}
                </Typography>
              }
            >
              <FlexContainer component="ul" direction="column" gap={4}>
                {total > 0 ? (
                  artifacts.map((artifact) => (
                    <IterationArtifact
                      key={artifact.id}
                      artifact={artifact}
                      onSelectEntityFile={setSelectedEntityFile}
                      onSelectEntityMetadata={setSelectedMetadata}
                    />
                  ))
                ) : (
                  <EmptyList
                    className={styles.noData}
                    borderless
                    message={
                      <Typography variant="callout" weight="regular" color="tertiary">
                        {$t({
                          id: 'widget.section.no-artifacts.label',
                          defaultMessage: 'This section is empty',
                        })}
                      </Typography>
                    }
                  />
                )}
              </FlexContainer>
            </Summary>
          ))}
      </AssetWidget>
      {selectedEntityFile && (
        <LightboxPreview entityFile={selectedEntityFile} onClose={() => setSelectedEntityFile(null)} />
      )}
      {selectedMetadata && (
        <LightboxEntityMetaPreview entityMetadata={selectedMetadata} onClose={() => setSelectedMetadata(null)} />
      )}
    </>
  );
};
