import React, { useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CreatedDateFilterComponent } from '../ui';

export const pluginField = 'createdDate';

class CreatedDateFilterNode<T> extends FilterNode<T> {
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CreatedDateFilterComponent
        key={pluginField}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, { ...value, field: pluginField })}
      />
    );
  }
}

export const CreatedDateFilter = () => {
  const { current: node } = useRef(new CreatedDateFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.createdDate.label', defaultMessage: 'Created date' }),
    node,
  });

  return null;
};
