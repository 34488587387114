import { graphql } from '../../gql';

export const CLOSE_REVIEW = graphql(`
  mutation closeReview($review: ReviewUpdateInput!, $id: VecticeId!) {
    closeReview(review: $review, id: $id) {
      vecticeId
      status
      ...Review
    }
  }
`);
