import { graphql } from '../../gql';

export const GET_ALL_EXPORTED_ARCHIVES = graphql(`
  query getExportedArchivesList {
    getExportedArchivesList {
      name
      path
      itemCreatedDate
    }
  }
`);
