import cn from 'classnames';
import React, { ElementType, forwardRef, HTMLAttributes, MouseEventHandler } from 'react';

import { IconColors } from './types';

import styles from './Svg.module.scss';

interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {
  color?: IconColors;
  icon: ElementType;
  size?: number;
  onClick?: MouseEventHandler<HTMLOrSVGElement> | undefined;
}

export const Icon = forwardRef(
  (
    { icon: IconComponent, className, color = 'default', role, size = 24, ...props }: IconProps,
    ref: React.LegacyRef<HTMLOrSVGElement>,
  ) => (
    <IconComponent
      className={cn(styles.svg, styles[color], { [styles.button]: role === 'button' }, className)}
      height={size}
      role={role}
      width={size}
      ref={ref}
      {...props}
    />
  ),
);
