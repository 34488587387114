import React, { useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { Phase, StepDefinition } from '../../gql/graphql';
import { useStep, useSteps } from '../../hooks';
import { FlexContainer, Menu, MenuDivider, MenuItem, MoreActions, Typography } from '../../ui';
import { VecticeResourceType } from '../../utils';
import { DeletionPrompt, ResourceModal } from '../modals';

import styles from './Requirement.module.scss';

export type RequirementModal = 'edit' | 'delete' | null;

interface RequirementProps {
  phaseId?: string;
  readOnly?: boolean;
  requirement: Pick<StepDefinition, 'id' | 'slug' | 'name' | 'description'> & {
    parent: Pick<Phase, 'vecticeId'>;
  };
}

export const Requirement = ({ phaseId, readOnly, requirement }: RequirementProps) => {
  const { checkStepNameAvailability } = useSteps(phaseId);
  const { updateStep, deleteStep } = useStep(requirement);

  const [showModal, setShowModal] = useState<RequirementModal>(null);

  const closeModal = () => setShowModal(null);

  const moreActionsMenu = (
    <Menu>
      <MenuItem leftIcon={EditIcon} onClick={() => setShowModal('edit')}>
        {$t({
          id: 'phase.requirement.editResource',
          defaultMessage: 'Edit requirement',
        })}
      </MenuItem>
      <MenuDivider />
      <MenuItem leftIcon={DeleteIcon} color="danger" onClick={() => setShowModal('delete')}>
        {$t({
          id: 'phase.requirement.deleteResource',
          defaultMessage: 'Delete requirement',
        })}
      </MenuItem>
    </Menu>
  );

  return (
    <FlexContainer direction="column">
      <FlexContainer>
        <Typography variant="callout" weight="semi-bold" color="primary" className={styles.name}>
          {requirement.name}
        </Typography>
        {!readOnly && (
          <MoreActions
            className={styles.menu_trigger}
            dropdownProps={{
              onOverlayClick: (e) => e.stopPropagation(),
            }}
            moreActionsMenu={moreActionsMenu}
          />
        )}
      </FlexContainer>
      {requirement.description && (
        <Typography paragraph variant="callout" color="secondary" className={styles.description}>
          {requirement.description}
        </Typography>
      )}
      {showModal === 'edit' && (
        <ResourceModal
          edit
          defaultValues={{ name: requirement.name, description: requirement.description ?? '' }}
          descriptionPlaceholder={$t({
            id: 'phase.requirement.placeholder',
            defaultMessage: 'Describe the outcome of this requirement',
          })}
          resourceType={VecticeResourceType.REQUIREMENT}
          withDescription
          checkNameAvailability={checkStepNameAvailability(requirement.id)}
          onClose={closeModal}
          onSubmit={({ name, description }) =>
            updateStep({
              variables: {
                id: requirement.id,
                updateModel: {
                  name,
                  description,
                },
              },
            })
          }
        />
      )}
      {requirement && showModal === 'delete' && (
        <DeletionPrompt
          resourceName={requirement.name}
          resourceType={$t({ id: 'resourcetype.requirement', defaultMessage: 'Requirement' })}
          title={$t({
            id: 'phase.requirement.deleteResource',
            defaultMessage: 'Delete requirement',
          })}
          onClose={closeModal}
          onConfirm={() => {
            closeModal();
            deleteStep();
          }}
        />
      )}
    </FlexContainer>
  );
};
