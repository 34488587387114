import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { graphql } from '../gql';
import {
  isDatasetRoute,
  isDatasetVersionRoute,
  isFindingRoute,
  isIterationRoute,
  isModelRoute,
  isModelVersionRoute,
  isPhaseRawExportRoute,
  isPhaseExportRoute,
  isPhaseRoute,
  isProjectRoute,
  isReportRawExportRoute,
  isReportRoute,
  isWorkspaceRoute,
  isFindingRawExportRoute,
} from '../routes';

export const GET_DATASET_CONTEXT = graphql(`
  query getDatasetContext($datasetId: VecticeId!) {
    getDataset(datasetId: $datasetId) {
      vecticeId
      project {
        vecticeId
        name
        workspace {
          vecticeId
          name
          type
          userContext {
            accessibility
          }
        }
      }
    }
  }
`);

export const GET_DATASET_VERSION_CONTEXT = graphql(`
  query getDatasetVersionContext($datasetVersionId: VecticeId!) {
    getDatasetVersion(datasetVersionId: $datasetVersionId) {
      vecticeId
      dataSet {
        vecticeId
        project {
          vecticeId
          name
          workspace {
            vecticeId
            name
            type
            userContext {
              accessibility
            }
          }
        }
      }
    }
  }
`);

export const GET_ITERATION_CONTEXT = graphql(`
  query getIterationContext($iterationId: VecticeId!) {
    getIterationById(id: $iterationId) {
      vecticeId
      phase {
        vecticeId
        name
        index
        parent {
          vecticeId
          name
          workspace {
            vecticeId
            name
            type
            userContext {
              accessibility
            }
          }
        }
      }
    }
  }
`);

export const GET_MODEL_CONTEXT = graphql(`
  query getModelContext($modelId: VecticeId!) {
    getModelById(modelId: $modelId) {
      vecticeId
      project {
        vecticeId
        name
        workspace {
          vecticeId
          name
          type
          userContext {
            accessibility
          }
        }
      }
    }
  }
`);

export const GET_MODEL_VERSION_CONTEXT = graphql(`
  query getModelVersionContext($modelVersionId: VecticeId!) {
    getModelVersion(modelVersionId: $modelVersionId) {
      vecticeId
      model {
        vecticeId
        project {
          vecticeId
          name
          workspace {
            vecticeId
            name
            type
            userContext {
              accessibility
            }
          }
        }
      }
    }
  }
`);

export const GET_PHASE_CONTEXT = graphql(`
  query getPhaseContext($phaseId: VecticeId!) {
    getPhaseById(id: $phaseId) {
      vecticeId
      name
      index
      parent {
        vecticeId
        name
        workspace {
          vecticeId
          name
          type
          userContext {
            accessibility
          }
        }
      }
    }
  }
`);

export const GET_PROJECT_CONTEXT = graphql(`
  query getProjectContext($projectId: VecticeId!) {
    getProjectById(projectId: $projectId) {
      vecticeId
      name
      workspace {
        vecticeId
        name
        type
        userContext {
          accessibility
        }
      }
    }
  }
`);

export const GET_REPORT_CONTEXT = graphql(`
  query getReportContext($reportId: Float!) {
    CDTReport(id: $reportId) {
      id
      name
      project {
        vecticeId
        name
        workspace {
          vecticeId
          name
          type
          userContext {
            accessibility
          }
        }
      }
    }
  }
`);

export const GET_FINDING_CONTEXT = graphql(`
  query getFindingContext($findingId: Float!) {
    Finding(id: $findingId) {
      id
      name
      project {
        vecticeId
        name
        workspace {
          vecticeId
          name
          type
          userContext {
            accessibility
          }
        }
      }
    }
  }
`);

export const GET_WORKSPACE_CONTEXT = graphql(`
  query getWorkspaceContext($workspaceId: VecticeId!) {
    getWorkspaceById(workspaceId: $workspaceId) {
      vecticeId
      name
      type
      userContext {
        accessibility
      }
    }
  }
`);

export const useRetrievePageContext = () => {
  const location = useLocation();
  const {
    datasetId,
    datasetVersionId,
    iterationId,
    modelId,
    modelVersionId,
    phaseId,
    projectId,
    reportId,
    workspaceId,
    findingId,
  } = useParams();

  const {
    data: datasetData,
    loading: datasetLoading,
    error: datasetError,
  } = useQuery(GET_DATASET_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isDatasetRoute(location.pathname) || !datasetId,
    variables: { datasetId: datasetId! },
  });

  const {
    data: datasetVersionData,
    loading: datasetVersionLoading,
    error: datasetVersionError,
  } = useQuery(GET_DATASET_VERSION_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isDatasetVersionRoute(location.pathname) || !datasetVersionId,
    variables: { datasetVersionId: datasetVersionId! },
  });

  const {
    data: iterationData,
    loading: iterationLoading,
    error: iterationError,
  } = useQuery(GET_ITERATION_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isIterationRoute(location.pathname) || !iterationId,
    variables: { iterationId: iterationId! },
  });

  const {
    data: modelData,
    loading: modelLoading,
    error: modelError,
  } = useQuery(GET_MODEL_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isModelRoute(location.pathname) || !modelId,
    variables: { modelId: modelId! },
  });

  const {
    data: modelVersionData,
    loading: modelVersionLoading,
    error: modelVersionError,
  } = useQuery(GET_MODEL_VERSION_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isModelVersionRoute(location.pathname) || !modelVersionId,
    variables: { modelVersionId: modelVersionId! },
  });

  const {
    data: phaseData,
    loading: phaseLoading,
    error: phaseError,
  } = useQuery(GET_PHASE_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip:
      (!isPhaseRoute(location.pathname) &&
        !isPhaseExportRoute(location.pathname) &&
        !isPhaseRawExportRoute(location.pathname)) ||
      !phaseId,
    variables: { phaseId: phaseId! },
  });

  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useQuery(GET_PROJECT_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isProjectRoute(location.pathname) || !projectId,
    variables: { projectId: projectId! },
  });

  const {
    data: reportData,
    loading: reportLoading,
    error: reportError,
  } = useQuery(GET_REPORT_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: (!isReportRoute(location.pathname) && !isReportRawExportRoute(location.pathname)) || !reportId,
    variables: { reportId: Number(reportId!) },
  });

  const {
    data: findingData,
    loading: findingLoading,
    error: findingError,
  } = useQuery(GET_FINDING_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: (!isFindingRoute(location.pathname) && !isFindingRawExportRoute(location.pathname)) || !findingId,
    variables: { findingId: Number(findingId!) },
  });

  const {
    data: workspaceData,
    loading: workspaceLoading,
    error: workspaceError,
  } = useQuery(GET_WORKSPACE_CONTEXT, {
    fetchPolicy: 'cache-and-network',
    skip: !isWorkspaceRoute(location.pathname) || !workspaceId,
    variables: { workspaceId: workspaceId! },
  });

  const loading =
    datasetLoading ||
    datasetVersionLoading ||
    iterationLoading ||
    modelLoading ||
    modelVersionLoading ||
    phaseLoading ||
    projectLoading ||
    reportLoading ||
    findingLoading ||
    workspaceLoading;

  const error =
    datasetError ??
    datasetVersionError ??
    iterationError ??
    modelError ??
    modelVersionError ??
    phaseError ??
    projectError ??
    reportError ??
    findingError ??
    workspaceError;

  const { phase, project, workspace } = useMemo(() => {
    if (datasetData?.getDataset) {
      return {
        project: datasetData.getDataset.project,
        workspace: datasetData.getDataset.project.workspace,
      };
    }

    if (datasetVersionData?.getDatasetVersion) {
      return {
        project: datasetVersionData.getDatasetVersion.dataSet.project,
        workspace: datasetVersionData.getDatasetVersion.dataSet.project.workspace,
      };
    }

    if (iterationData?.getIterationById) {
      return {
        phase: iterationData.getIterationById.phase,
        project: iterationData.getIterationById.phase.parent,
        workspace: iterationData.getIterationById.phase.parent.workspace,
      };
    }

    if (modelData?.getModelById) {
      return {
        project: modelData.getModelById.project,
        workspace: modelData.getModelById.project.workspace,
      };
    }

    if (modelVersionData?.getModelVersion) {
      return {
        project: modelVersionData.getModelVersion.model.project,
        workspace: modelVersionData.getModelVersion.model.project.workspace,
      };
    }

    if (phaseData?.getPhaseById) {
      return {
        phase: phaseData.getPhaseById,
        project: phaseData.getPhaseById.parent,
        workspace: phaseData.getPhaseById.parent.workspace,
      };
    }

    if (projectData?.getProjectById) {
      return {
        project: projectData.getProjectById,
        workspace: projectData.getProjectById.workspace,
      };
    }

    if (reportData?.CDTReport) {
      return {
        project: reportData.CDTReport.project,
        workspace: reportData.CDTReport.project.workspace,
      };
    }

    if (findingData?.Finding) {
      return {
        project: findingData.Finding.project,
        workspace: findingData.Finding.project.workspace,
      };
    }

    if (workspaceData?.getWorkspaceById) {
      return {
        workspace: workspaceData.getWorkspaceById,
      };
    }

    return {};
  }, [
    datasetData,
    datasetVersionData,
    iterationData,
    modelData,
    modelVersionData,
    phaseData,
    projectData,
    reportData,
    findingData,
    workspaceData,
  ]);

  return {
    phase,
    project,
    workspace,
    error,
    loading,
  };
};
