import { useMutation } from '@apollo/client';
import cn from 'classnames';
import React, { useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import {
  getModelVersionStatusColor,
  getModelVersionStatusDisplayName,
  getModelVersionStatusOptions,
} from '../../entities/utils';
import { graphql } from '../../gql';
import { ModelVersion, ModelVersionStatus as ModelVersionStatusEnum } from '../../gql/graphql';
import { useWithFeatureFlags } from '../../guards';
import { Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';
import { ModelVersionStatusBadge } from '../badges';

import styles from './RevampedAssetStatus.module.scss';

export const UPDATE_MODEL_VERSION_STATUS = graphql(`
  mutation updateModelVersionStatus($modelVersionId: VecticeId!, $status: ModelVersionStatus!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: { status: $status }) {
      vecticeId
      status
    }
  }
`);

interface ModelVersionStatusProps extends ButtonProps {
  modelVersionId?: string;
  readOnly?: boolean;
  status?: ModelVersionStatusEnum;
}

export const ModelVersionStatus = ({
  className,
  modelVersionId,
  readOnly,
  status,
  ...props
}: ModelVersionStatusProps) => {
  const { allowed: withDiscarded } = useWithFeatureFlags({ featureFlag: 'model-version-discarded' });
  const { allowed: withValidationStatus } = useWithFeatureFlags({ featureFlag: 'mdv_assets_by_status' });

  const { current: statusOptions } = useRef(getModelVersionStatusOptions(withDiscarded, withValidationStatus));

  const [updateModelVersion] = useMutation(UPDATE_MODEL_VERSION_STATUS, {
    optimisticResponse: ({ modelVersionId, status }) => ({
      updateModelVersion: {
        vecticeId: modelVersionId.toString(),
        status,
        __typename: 'ModelVersion' as ModelVersion['__typename'],
      },
    }),
  });

  const handleStatusUpdate = (updatedStatus: ModelVersionStatusEnum) => async () => {
    if (modelVersionId) {
      await updateModelVersion({
        variables: {
          modelVersionId,
          status: updatedStatus,
        },
      });
    }
  };

  const menu = (
    <Menu aria-label="ModelVersion Status Menu">
      {statusOptions.map((option) => (
        <MenuItem key={option.value} selected={status === option.value} onClick={handleStatusUpdate(option.value)}>
          <ModelVersionStatusBadge status={option.value} />
        </MenuItem>
      ))}
    </Menu>
  );

  if (!status) {
    return null;
  }

  if (readOnly) {
    return <ModelVersionStatusBadge status={status} />;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        variant="primary"
        aria-label="Model version status select"
        className={cn(styles[getModelVersionStatusColor(status)], className)}
        {...props}
      >
        {getModelVersionStatusDisplayName(status)}
      </DropdownTrigger>
    </Dropdown>
  );
};
