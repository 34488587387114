import cn from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as CopyIcon } from '../../assets/icons/interface/ic-copy.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/interface/ic-edit.svg';
import { IterationStepArtifact } from '../../gql/graphql';
import { AutoLinkText, Card, FlexContainer, Menu, MenuItem, message, MoreActions, Typography } from '../../ui';
import { copyToClipboard } from '../../utils';
import { EditIterationCommentModal } from '../modals';

import styles from './IterationCommentCard.module.scss';

export interface IterationCommentCardProps extends Omit<VecticeHTMLProps<HTMLDetailsElement>, 'open' | 'summary'> {
  iterationArtifact: Pick<IterationStepArtifact, 'id' | 'text'>;
}

export const IterationCommentCard = ({
  iterationArtifact: { id, text },
  className,
  readOnly,
  ...props
}: IterationCommentCardProps) => {
  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const moreActionsMenu = (
    <MoreActions
      moreActionsMenu={
        <Menu>
          {!readOnly && (
            <MenuItem
              leftIcon={PencilIcon}
              onClick={() => {
                setModalOpened(true);
              }}
            >
              {$t({ id: 'iteration.comment.edit', defaultMessage: 'Edit' })}
            </MenuItem>
          )}
          <MenuItem
            leftIcon={CopyIcon}
            onClick={() => {
              copyToClipboard(text ?? '')
                .then(() =>
                  message.success($t({ id: 'component.WithCopyOnClick.successMessage', defaultMessage: 'Copied!' })),
                )
                .catch(() =>
                  message.error($t({ id: 'component.WithCopyOnClick.errorMessage', defaultMessage: 'Can not copy!' })),
                );
            }}
          >
            {$t({ id: 'iteration.comment.copy', defaultMessage: 'Copy' })}
          </MenuItem>
        </Menu>
      }
      size="xxs"
      onClick={(e) => e.stopPropagation()}
      className={styles.actions}
    />
  );

  return (
    <Card className={cn(styles.textCard, className)} {...props}>
      <FlexContainer>
        <Typography paragraph variant="callout">
          <AutoLinkText text={text ?? ''} />
        </Typography>
        {moreActionsMenu}
      </FlexContainer>
      {modalOpened && (
        <EditIterationCommentModal artifactId={id} text={text ?? ''} onClose={() => setModalOpened(false)} />
      )}
    </Card>
  );
};
