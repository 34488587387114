import React, { useRef } from 'react';

import { getIterationStatusOptions } from '../../../entities/utils';
import { Icon } from '../../../ui';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'status';

const sizeIcon = 18;

class IterationStatusFilterNode<T> extends FilterNode<T> {
  private options = getIterationStatusOptions().map(({ color, icon, ...option }) => ({
    ...option,
    icon: icon ? <Icon color={color} icon={icon} size={sizeIcon} /> : null,
  }));

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="iteration-version-status-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const IterationStatusFilter = () => {
  const { current: node } = useRef(new IterationStatusFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.iterationStatus.label', defaultMessage: 'Iteration status' }),
    node,
  });

  return null;
};
