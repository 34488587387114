import { graphql } from '../../gql';

export const CREATE_REVIEW_COMMENT = graphql(`
  mutation createReviewComment($reviewId: VecticeId!, $content: String!) {
    createReviewComment(reviewComment: { reviewId: $reviewId, content: $content }) {
      id
      createdBy {
        ...userFields
      }
      content
      createdDate
    }
  }
`);
