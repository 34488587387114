import React from 'react';

import { ModalPrompt } from '../../ui';

interface ConfirmStageRestorationModalProps {
  processing: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmStageRestorationModal = ({ processing, onConfirm, onClose }: ConfirmStageRestorationModalProps) => (
  <ModalPrompt
    cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
    confirmLabel={$t({ id: 'modal.confirm', defaultMessage: 'Confirm' })}
    processing={processing}
    title={$t({ id: 'modal.confirmStageRestoration.confirmation', defaultMessage: 'Confirmation' })}
    onClose={onClose}
    onConfirm={onConfirm}
  >
    {$t({
      id: 'modal.confirmStageRestoration.restoreVersionConfirmation',
      defaultMessage: 'Are you sure you want to restore this version?',
    })}
  </ModalPrompt>
);
