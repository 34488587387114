import React, { useMemo } from 'react';

import { ColumnSourceType } from '../../../gql/graphql';
import { SourceColumnWithFragment } from '../types';
import { isBooleanSourceColumn, isDateSourceColumn, isNumericalSourceColumn, isTextSourceColumn } from '../utils';

import { BooleanSourceColumn } from './BooleanSourceColumn';
import { DateSourceColumn } from './DateSourceColumn';
import { FallbackSourceColumn } from './FallbackSourceColumn';
import { NumericalSourceColumn } from './NumericalSourceColumn';
import { TextSourceColumn } from './TextSourceColumn';

interface DatasetSourceColumnProps {
  column: SourceColumnWithFragment;
  columnSourceType: ColumnSourceType;
}

export const DatasetSourceColumnFactory = ({ column, columnSourceType }: DatasetSourceColumnProps) =>
  useMemo(() => {
    if (isBooleanSourceColumn(column)) {
      return <BooleanSourceColumn column={column} columnSourceType={columnSourceType} />;
    }

    if (isDateSourceColumn(column)) {
      return <DateSourceColumn column={column} columnSourceType={columnSourceType} />;
    }

    if (isNumericalSourceColumn(column)) {
      return <NumericalSourceColumn column={column} columnSourceType={columnSourceType} />;
    }

    if (isTextSourceColumn(column)) {
      return <TextSourceColumn column={column} columnSourceType={columnSourceType} />;
    }

    return <FallbackSourceColumn column={column} columnSourceType={columnSourceType} />;
  }, [column]);
