import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/specials/ic-report.svg';
import { CdtReport, OrderDirection } from '../../gql/graphql';
import { Breadcrumb, Breadcrumbs, Button, Dropdown, FlexContainer, MenuItem, Typography } from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { getVecticeResourceTypeIcon, isNil } from '../../utils';

import { CDT_REPORTS_SELECTOR } from './CDTReportSelector.query';
import { CDT_REPORT_BY_ID } from './queries';

import styles from './AssetSelector.module.scss';

type SelectedCDTReport = Pick<CdtReport, 'id' | 'name'>;

export const CDTReportSelector = ({
  assetType,
  projectId,
  selectedAssetId,
  disabled = false,
  canClearAsset = true,
  triggerClassName,
  onSelect,
}: AssetSelectorProps) => {
  const [selectedVersion, setSelectedVersion] = useState<SelectedCDTReport>();
  const [search, setSearch] = useState<string | undefined>(undefined);

  const { data: reportsData, loading: loadingReports } = useQuery(CDT_REPORTS_SELECTOR, {
    skip: !projectId || disabled,
    variables: {
      filters: {
        search,
        projectId,
      },
      order: { field: 'updatedDate', direction: OrderDirection.Desc },
      page: {
        size: 10,
      },
    },
  });
  const options = reportsData?.CDTReports.items;

  useQuery(CDT_REPORT_BY_ID, {
    skip: !selectedAssetId,
    variables: { id: Number(selectedAssetId)! },
    onCompleted: (data) => {
      setSelectedVersion(data.CDTReport);
    },
  });

  useEffect(() => {
    onSelect(selectedAssetId);
    if (isNil(selectedAssetId)) {
      setSelectedVersion(undefined);
    }
  }, [selectedAssetId]);

  const clearAsset = () => {
    setSelectedVersion(undefined);
    onSelect(null);
  };

  const overlay = (
    <MenuWithSearch search={search} onSearch={setSearch} loading={loadingReports} isEmpty={!options || !options.length}>
      {options?.map((report) => (
        <MenuItem
          key={`option-${report.id}`}
          leftIcon={ReportIcon}
          onClick={() => {
            setSelectedVersion(report);
            onSelect(report.id.toString());
          }}
        >
          <FlexContainer>
            <Typography variant="callout">{report.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  if (!loadingReports && search === '' && options?.length === 0) {
    return (
      <Typography className={styles.emptyAssetSelector} variant="callout" color="disabled" weight="semi-bold">
        {$t({ id: 'assetSelector.reportsSelector.empty', defaultMessage: 'No assets available' })}
      </Typography>
    );
  }

  return (
    <>
      {!selectedVersion && (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={(visible) => {
            if (!visible) setSearch('');
          }}
        >
          <Button
            aria-label="Select asset"
            className={cn(styles.trigger, triggerClassName, { [styles.disabled]: disabled })}
            disabled={disabled}
            variant="white"
          >
            <Typography ellipsis color="tertiary">
              {$t({ id: 'assetSelector.selectAReport', defaultMessage: 'Select a report' })}
            </Typography>
          </Button>
        </Dropdown>
      )}
      {selectedVersion && (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.name}
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      )}
    </>
  );
};
