import React, { useCallback, useEffect, useState } from 'react';

import { DatasetSourceType, DatasetSourceUsage } from '../../gql/graphql';
import { EmptySection, FlexContainer, Section, WithLoading } from '../../ui';

import { useDatasetSources } from './hooks';
import { ResourcesNavigation } from './navigation';
import { ResourcePreview } from './preview';

import styles from './DatasetResources.module.scss';

type SelectedResource = { id: number; type: DatasetSourceType };

interface DatasetResourcesProps {
  datasetVersionId: string;
}

export const DatasetResources = ({ datasetVersionId }: DatasetResourcesProps) => {
  const [selectedResource, setSelectedResource] = useState<SelectedResource | null>(null);

  const {
    loading,
    targetColumn,
    datasetSources,
    defaultResources,
    testingResources,
    trainingResources,
    validationResources,
    noResources,
  } = useDatasetSources(datasetVersionId);

  useEffect(() => {
    if (!selectedResource && !loading) {
      if (datasetSources?.default && defaultResources.sources?.[0]) {
        setSelectedResource({
          id: defaultResources.sources[0].id,
          type: datasetSources.default.type,
        });
      } else if (datasetSources?.[DatasetSourceUsage.Training] && trainingResources.sources?.[0]) {
        setSelectedResource({
          id: trainingResources.sources[0].id,
          type: datasetSources[DatasetSourceUsage.Training].type,
        });
      } else if (datasetSources?.[DatasetSourceUsage.Testing] && testingResources.sources?.[0]) {
        setSelectedResource({
          id: testingResources.sources[0].id,
          type: datasetSources[DatasetSourceUsage.Testing].type,
        });
      } else if (datasetSources?.[DatasetSourceUsage.Validation] && validationResources.sources?.[0]) {
        setSelectedResource({
          id: validationResources.sources[0].id,
          type: datasetSources[DatasetSourceUsage.Validation].type,
        });
      }
    }
  }, [
    loading,
    selectedResource,
    datasetSources,
    defaultResources.sources,
    testingResources.sources,
    trainingResources.sources,
    validationResources.sources,
  ]);

  const handleResourceSelection = useCallback(
    (id: number, type: DatasetSourceType) =>
      setSelectedResource({
        id,
        type,
      }),
    [],
  );

  return (
    <Section label={$t({ id: 'datasetResources.title', defaultMessage: 'Dataset Resources' })}>
      <WithLoading loading={loading}>
        {noResources ? (
          <EmptySection
            message={$t({
              id: 'datasetResources.empty.message',
              defaultMessage: 'To attach a Resource, you will need to use the Vectice API.',
            })}
          />
        ) : (
          <FlexContainer align="flex-start" gap="0" className={styles.container}>
            <ResourcesNavigation
              sources={datasetSources}
              defaultResources={defaultResources}
              testingResources={testingResources}
              trainingResources={trainingResources}
              validationResources={validationResources}
              selectedResourceId={selectedResource?.id}
              onSelectResource={handleResourceSelection}
            />
            <ResourcePreview
              datasetVersionId={datasetVersionId}
              resourceId={selectedResource?.id}
              targetColumn={targetColumn ?? undefined}
              sourceType={selectedResource?.type}
            />
          </FlexContainer>
        )}
      </WithLoading>
    </Section>
  );
};
