import cn from 'classnames';
import React from 'react';

import styles from './Loading.module.scss';

interface LoadingAnimationProps {
  size?: number;
  color?: 'primary' | 'white' | 'inherit';
  className?: string;
}

export const Loading = ({ size = 36, color = 'primary', className }: LoadingAnimationProps) => (
  <div className={cn(styles.container, styles[color], className)} role="alert" aria-live="polite">
    <svg height={size} width={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" aria-label="Loading...">
      <circle
        className={styles.circle}
        cx="18"
        cy="18"
        r="16"
        strokeWidth="3"
        strokeDasharray="25 100"
        strokeLinecap="round"
      />
    </svg>
  </div>
);
