import React, { ReactNode, useEffect, useRef } from 'react';
import { RegisterOptions, SubmitHandler } from 'react-hook-form';

import { WorkspaceType } from '../../gql/graphql';
import { useValidateName, useVecticeForm } from '../../hooks';
import { Button, Form, Input, TextArea, WithAsyncValidation } from '../../ui';
import {
  VecticeResourceType,
  WORKSPACE_NAME_FORMAT,
  defaultDescriptionFieldConfiguration,
  defaultNameFieldConfiguration,
} from '../../utils';

import styles from './ResourceBaseForm.module.scss';

export interface WorkspaceEditFormValues {
  name: string;
  description?: string | null;
}

interface WorkspaceEditFormProps extends Omit<VecticeHTMLProps<HTMLFormElement>, 'onSubmit'> {
  cancelButton?: ReactNode;
  defaultValues?: WorkspaceEditFormValues;
  loading?: boolean;
  workspaceType?: null | WorkspaceType;
  onCheckAvailability: (value: string) => Promise<boolean>;
  onSubmit: (values: WorkspaceEditFormValues) => Promise<void>;
}

export const WorkspaceEditForm = ({
  cancelButton,
  defaultValues,
  disabled,
  loading,
  workspaceType,

  onCheckAvailability,
  onSubmit,
  ...props
}: WorkspaceEditFormProps) => {
  const { validateName } = useValidateName(onCheckAvailability, VecticeResourceType.WORKSPACE);

  const currentName = useRef(defaultValues?.name);
  const { formState, preSubmit, registerWithErrors, reset, setError, trigger } = useVecticeForm({
    mode: 'onChange',
    defaultValues,
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  useEffect(() => {
    if (defaultValues) {
      currentName.current = defaultValues.name;
    }
  }, [defaultValues]);

  const submitAndReset: SubmitHandler<WorkspaceEditFormValues> = async ({ name, description }) => {
    await onSubmit({ name, description });
    reset({ name, description });
  };

  return (
    <Form {...props} onSubmit={preSubmit(submitAndReset)}>
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', {
            ...defaultNameFieldConfiguration(),
            ...(workspaceType !== WorkspaceType.Tutorial
              ? {
                  pattern: {
                    value: WORKSPACE_NAME_FORMAT,
                    message: $t({
                      id: 'form.WorkspaceForm.nameError.dotPrefix',
                      defaultMessage: "Invalid workspace name. Can not use '.' to prefix the workspace name.",
                    }),
                  },
                }
              : {}),
          } as RegisterOptions<WorkspaceEditFormValues, 'name'>)}
          gutterBottom
          disabled={disabled}
          label={$t({
            id: 'form.WorkspaceForm.nameLabel',
            defaultMessage: 'Workspace Name',
          })}
          required
        />
      </WithAsyncValidation>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        disabled={disabled}
        label={$t({ id: 'workspaceEditForm.description.label', defaultMessage: 'Description' })}
        gutterBottom
      />
      {!disabled && (
        <div className={styles.buttons}>
          {!defaultValues && cancelButton}
          <Button type="submit" disabled={!isDirty || hasErrors || loading} loading={isSubmitting} size="sm">
            {$t({ id: 'button.save', defaultMessage: 'Save' })}
          </Button>
        </div>
      )}
    </Form>
  );
};
