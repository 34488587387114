import React from 'react';

import { ReactComponent as ProjectIcon } from '../../../assets/icons/specials/ic-project.svg';
import { AssetLink } from '../../../ui';

interface FormatProjectProps {
  name?: string | null;
}

export const FormatProject = ({ name }: FormatProjectProps) => (
  <AssetLink name={name} icon={ProjectIcon} color="inherit" />
);
