import { useQuery } from '@apollo/client';
import React from 'react';

import { Typography, WithLoading } from '../../ui';
import { getActivityTargetByResourceType, getActivityTargetFilter, VecticeResourceType } from '../../utils';
import { UserAvatars, UserAvatarsSkeleton } from '../asset-display';

import { GET_CONTRIBUTOR_LIST } from './getContributorList.query';

interface Props {
  workspaceId?: string;
  resourceId?: string;
  resourceType?: VecticeResourceType;
  projectId?: string;
}

export const Contributors = ({ resourceId, resourceType, projectId, workspaceId }: Props) => {
  const { loading, data } = useQuery(GET_CONTRIBUTOR_LIST, {
    fetchPolicy: 'no-cache',
    skip: !workspaceId,
    variables: {
      workspaceIdList: workspaceId ? [workspaceId] : [],
      filters: {
        targetFilter: getActivityTargetFilter(resourceType!, [resourceId!]),
        activityTargetTypes: getActivityTargetByResourceType(resourceType!),
        projectIds: projectId ? [projectId] : undefined,
      },
    },
  });

  const contributors = data?.getContributorList?.items;

  return (
    <WithLoading loading={loading} skeleton={<UserAvatarsSkeleton />}>
      {contributors && contributors.length === 0 && (
        <Typography variant="callout" color="disabled">
          {$t({ id: 'Contributors.noContributors', defaultMessage: 'No contributors' })}
        </Typography>
      )}
      {contributors && contributors.length > 0 && <UserAvatars users={contributors} />}
    </WithLoading>
  );
};
