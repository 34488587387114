import React from 'react';

import type { BadgeProps } from '../../ui';

import { getModelVersionStatusColor, getModelVersionStatusDisplayName } from '../../entities/utils';
import { ModelVersionStatus } from '../../gql/graphql';
import { Badge } from '../../ui';

interface ModelVersionStatusBadgeProps extends BadgeProps {
  status?: ModelVersionStatus | null;
}

export const ModelVersionStatusBadge = ({ contrast = false, size = 'sm', status }: ModelVersionStatusBadgeProps) => (
  <Badge color={getModelVersionStatusColor(status)} contrast={contrast} size={size}>
    {getModelVersionStatusDisplayName(status)}
  </Badge>
);
