import React from 'react';

import { getAccessibilityLevelDisplayName } from '../../entities/utils';
import { AccessibilityLevel, User } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { ModalPrompt } from '../../ui';
import { FormatUserName } from '../formatters';

interface ChangeWorkspaceAccessibilityModalProps {
  newLevel: AccessibilityLevel;
  oldLevel: AccessibilityLevel;
  user: Pick<User, 'name'> & UserIdentity;
  onClose: () => void;
  onConfirm: (newLevel: AccessibilityLevel) => void;
}

const RankedLevels = [AccessibilityLevel.ReadOnly, AccessibilityLevel.Editor, AccessibilityLevel.Manager];

export const ChangeWorkspaceAccessibilityModal = ({
  newLevel,
  oldLevel,
  user,
  onClose,
  onConfirm,
}: ChangeWorkspaceAccessibilityModalProps) => {
  const isPromotion = RankedLevels.indexOf(oldLevel) < RankedLevels.indexOf(newLevel);

  const title = isPromotion
    ? $t({
        id: 'ChangeWorkspaceAccessibilityModal.promoteTitle',
        defaultMessage: 'Promote {name} to {level}?',
        values: { name: user.name, level: getAccessibilityLevelDisplayName(newLevel) },
      })
    : $t({
        id: 'ChangeWorkspaceAccessibilityModal.demoteTitle',
        defaultMessage: 'Demote {name} to {level}?',
        values: { name: user.name, level: getAccessibilityLevelDisplayName(newLevel) },
      });

  const message = isPromotion
    ? $t({
        id: 'ChangeWorkspaceAccessibilityModal.promoteMessage',
        defaultMessage:
          'You are about to promote <bold>{user}</bold> to become a(n) <bold>{level}</bold> of this workspace.',
        values: {
          user: <FormatUserName user={user} />,
          level: getAccessibilityLevelDisplayName(newLevel),
        },
      })
    : $t({
        id: 'ChangeWorkspaceAccessibilityModal.demoteMessage',
        defaultMessage:
          'You are about to demote <bold>{user}</bold> to become a(n) <bold>{level}</bold> of this workspace.',
        values: {
          user: <FormatUserName user={user} />,
          level: getAccessibilityLevelDisplayName(newLevel),
        },
      });

  return (
    <ModalPrompt
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      confirmLabel={$t({ id: 'modal.confirm', defaultMessage: 'Confirm' })}
      title={title}
      onClose={onClose}
      onConfirm={() => onConfirm(newLevel)}
    >
      {message}
    </ModalPrompt>
  );
};
