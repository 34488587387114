import React from 'react';

import {
  PhaseProgress,
  getPhaseProgressColor,
  getPhaseProgressIcon,
  getPhaseProgressDisplayName,
} from '../../entities/utils';
import { Badge, BadgeColors, BadgeProps } from '../../ui';

interface PhaseProgressBadgeProps extends BadgeProps {
  className?: string;
  iconOnly?: boolean;
  nbOfPages?: number;
  status: PhaseProgress;
}

export const PhaseProgressBadge = ({
  iconOnly = false,
  nbOfPages,
  size = 'sm',
  status,
  ...props
}: PhaseProgressBadgeProps) => (
  <Badge
    color={getPhaseProgressColor(status) as BadgeColors}
    leftIcon={getPhaseProgressIcon(status)}
    size={size}
    {...props}
  >
    {!iconOnly && (
      <>
        {nbOfPages !== undefined && `${nbOfPages} `}
        {getPhaseProgressDisplayName(status)}
      </>
    )}
  </Badge>
);
