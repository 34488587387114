import { graphql } from '../../gql';

export const RENAME_ENTITY_METADATA = graphql(`
  mutation renameEntityMetadata($id: Float!, $name: String!) {
    renameEntityMetadata(id: $id, name: $name) {
      id
      name
    }
  }
`);
