import { useQuery } from '@apollo/client';
import React, { Suspense, useRef, useState } from 'react';

import type { AssetWidgetProps } from '../../../ui';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/interface/ic-download.svg';
import { ReactComponent as ConvertImageIcon } from '../../../assets/icons/interface/ic-image-file-landscape.svg';
import { InsertWidgetData } from '../../../editor/interfaces';
import { graphql } from '../../../gql';
import { EntityFilesTypeEnum } from '../../../gql/graphql';
import { AssetWidget, FlexContainer, Icon, Menu, MenuItem, Typography } from '../../../ui';
import { downloadEntityFile, getMimeTypeIcon } from '../../../utils';
import { VersionBadge } from '../../badges';
import { LightboxPreview } from '../../previews';
import { EntityFilePreview } from '../../previews/EntityFilePreview';

import { EntityFileRow } from './EntityFileRow';
import { getEntityFileWidgetIcon } from './utils';

export const GET_ENTITY_FILE_WIDGET = graphql(`
  query getEntityFileWidget($id: Float!) {
    getEntityFileById(id: $id) {
      id
      fileName
      mimeType
      entityId
      type
      hasPreview
      hasStaticView
      ...entityFileFragment
    }
  }
`);

export const GET_ENTITY_FILE_ITERATION_ORIGIN = graphql(`
  query getEntityFileIterationOrigin($id: VecticeId!) {
    getIterationById(id: $id) {
      vecticeId
      name
    }
  }
`);

export const GET_ENTITY_FILE_DATASET_VERSION_ORIGIN = graphql(`
  query getEntityFileDatasetVersionOrigin($id: VecticeId!) {
    getDatasetVersion(datasetVersionId: $id) {
      vecticeId
      versionNumber
      dataSet {
        vecticeId
        name
        type
      }
    }
  }
`);

export const GET_ENTITY_FILE_MODEL_VERSION_ORIGIN = graphql(`
  query getEntityFileModelVersionOrigin($id: VecticeId!) {
    getModelVersion(modelVersionId: $id) {
      vecticeId
      versionNumber
      model {
        vecticeId
        name
        type
      }
    }
  }
`);

let globalId = 0;

interface EntityFileWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary' | 'actionsMenu'> {
  id?: number;
  extractImg?: (data: string, mimeType: string) => void;
  onConvertToInline?: (widget: InsertWidgetData) => void;
  onDelete?: () => void;
  onDownload?: () => void;
  onMimeType?: (mimeType?: string) => void;
}

export const EntityFileWidget = ({
  children,
  expandable,
  id,
  open,
  extractImg,
  onConvertToInline,
  onDelete,
  onMimeType,
  ...props
}: EntityFileWidgetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`entity-file-widget-${globalId++}`);
  const [showPreview, setShowPreview] = useState(false);

  const { data, error, loading } = useQuery(GET_ENTITY_FILE_WIDGET, {
    skip: !id,
    variables: { id: id! },
    onCompleted: ({ getEntityFileById: { mimeType } }) => {
      onMimeType && onMimeType(mimeType || undefined);
    },
  });

  const entityFile = data?.getEntityFileById;

  const { data: iterationData } = useQuery(GET_ENTITY_FILE_ITERATION_ORIGIN, {
    skip: !entityFile?.entityId || entityFile?.type !== EntityFilesTypeEnum.Iteration,
    variables: { id: entityFile?.entityId! },
  });

  const { data: datasetVersionData } = useQuery(GET_ENTITY_FILE_DATASET_VERSION_ORIGIN, {
    skip: !entityFile?.entityId || entityFile?.type !== EntityFilesTypeEnum.DatasetVersion,
    variables: { id: entityFile?.entityId! },
  });

  const { data: modelVersionData } = useQuery(GET_ENTITY_FILE_MODEL_VERSION_ORIGIN, {
    skip: !entityFile?.entityId || entityFile?.type !== EntityFilesTypeEnum.ModelVersion,
    variables: { id: entityFile?.entityId! },
  });

  const assetVersion =
    iterationData?.getIterationById || datasetVersionData?.getDatasetVersion || modelVersionData?.getModelVersion;
  const assetParentName =
    datasetVersionData?.getDatasetVersion.dataSet.name ??
    modelVersionData?.getModelVersion.model.name ??
    iterationData?.getIterationById.name;
  const assetIcon = getEntityFileWidgetIcon(entityFile?.type);
  const versionNumber =
    datasetVersionData?.getDatasetVersion.versionNumber ?? modelVersionData?.getModelVersion.versionNumber;

  if (error || !id) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        className="hide-pdf-export"
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getMimeTypeIcon()} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                {$t({ id: 'entityFile.widget.file.deleted', defaultMessage: 'File was deleted' })}
              </Typography>
            </FlexContainer>
          </div>
        }
        {...props}
        open={false}
        expandable={false}
        actionsMenu={
          !!onDelete ? (
            <Menu onClick={(e) => e.stopPropagation()}>
              <MenuItem leftIcon={DeleteIcon} onClick={onDelete}>
                {$t({ id: 'menuItem.delete', defaultMessage: 'Delete' })}
              </MenuItem>
            </Menu>
          ) : undefined
        }
      />
    );
  }

  const summary = (
    <EntityFileRow
      id={ariaId}
      icon={getMimeTypeIcon(entityFile?.mimeType)}
      entityFile={entityFile}
      interaction={!!entityFile && 'file'}
    >
      {assetVersion && assetParentName ? (
        <FlexContainer gap={4}>
          <Icon icon={assetIcon} size={16} />
          <Typography variant="footnote" color="primary">
            {assetParentName}
          </Typography>
          {versionNumber && <VersionBadge versionNumber={versionNumber} />}
        </FlexContainer>
      ) : undefined}
    </EntityFileRow>
  );

  const actionsMenu = entityFile ? (
    <Menu onClick={(e) => e.stopPropagation()}>
      {!!onConvertToInline && (
        <MenuItem
          leftIcon={ConvertImageIcon}
          onClick={() =>
            onConvertToInline({
              entityFileId: entityFile.id,
              entityId: entityFile.entityId,
              fileName: entityFile.fileName,
              mimeType: entityFile?.mimeType,
              type: entityFile.type,
            })
          }
        >
          {$t({ id: 'menuItem.convertToInlineImage', defaultMessage: 'Convert to inline image' })}
        </MenuItem>
      )}
      <MenuItem leftIcon={DownloadIcon} onClick={() => downloadEntityFile(entityFile)}>
        {$t({ id: 'menuItem.download', defaultMessage: 'Download' })}
      </MenuItem>
      {!!onDelete && (
        <MenuItem leftIcon={DeleteIcon} onClick={onDelete}>
          {$t({ id: 'menuItem.delete', defaultMessage: 'Delete' })}
        </MenuItem>
      )}
    </Menu>
  ) : undefined;

  const handleShowPreview = (e?: React.MouseEvent<HTMLDetailsElement, MouseEvent>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setShowPreview(true);
  };
  return (
    <>
      <AssetWidget
        aria-labelledby={ariaId}
        loading={loading}
        summary={summary}
        open={open}
        expandable={expandable}
        onClick={handleShowPreview}
        actionsMenu={actionsMenu}
        {...props}
      >
        {entityFile && open && (
          <Suspense fallback={null}>
            <EntityFilePreview
              mimeType={entityFile.mimeType}
              entityFileId={entityFile.id}
              isLightBoxPreview={false}
              hasStaticView={entityFile.hasStaticView}
              handleShowPreview={handleShowPreview}
            />
          </Suspense>
        )}
      </AssetWidget>
      {showPreview && entityFile && <LightboxPreview entityFile={entityFile} onClose={() => setShowPreview(false)} />}
    </>
  );
};
