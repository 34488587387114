import cn from 'classnames';
import React from 'react';

import styles from './DataList.module.scss';

interface DataListProps extends VecticeHTMLProps<HTMLDListElement> {
  gutterBottom?: boolean;
}

export const DataList = ({ gutterBottom, className, ...props }: DataListProps) => (
  <dl role="group" className={cn(styles.data_list, { [styles.gutterBottom]: gutterBottom }, className)} {...props} />
);
