import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { Iteration } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const DELETE_ITERATION = graphql(`
  mutation removeIteration($iterationId: VecticeId!) {
    removeIteration(id: $iterationId)
  }
`);

interface DeleteIterationModalProps {
  iteration: Pick<Iteration, 'vecticeId' | 'name'>;
  phaseId?: string | null;
  onClose: () => void;
}

export const DeleteIterationModal = ({ iteration, phaseId, onClose }: DeleteIterationModalProps) => {
  const navigate = useNavigate();

  const [deleteIteration, { loading: deleting }] = useMutation(DELETE_ITERATION, {
    variables: {
      iterationId: iteration?.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        flushFromGraphQLCache(cache, { vecticeId: data.removeIteration, __typename: 'Iteration' });
        if (phaseId) {
          navigate(buildLink(VecticeRoutes.PHASE_ITERATIONS, { phaseId }));
        }
        onClose();
      }
    },
    onCompleted: () => {
      if (iteration) {
        message.success(
          $t({
            id: 'component.action.deleteIteration.successMessage',
            defaultMessage: '{name} iteration deleted',
            values: { name: iteration?.name },
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <DeletionPrompt
      processing={deleting}
      resourceName={iteration.name}
      title={$t({ id: 'iteration.modal.delete.title', defaultMessage: 'Delete Iteration' })}
      onClose={onClose}
      onConfirm={deleteIteration}
    />
  );
};
