import { BroadcastChannel } from 'broadcast-channel';

import type { OnMessageHandler } from 'broadcast-channel';

export type AvailableChannel = 'login' | 'logout' | 'update';

class BroadcastClient {
  private channels: Map<AvailableChannel, BroadcastChannel>;

  constructor() {
    this.channels = new Map();
  }

  private getChannel<T>(channelName: AvailableChannel): BroadcastChannel<T> {
    let channel = this.channels.get(channelName);
    if (!channel) {
      channel = new BroadcastChannel(channelName);
      this.channels.set(channelName, channel);
    }

    return channel;
  }

  listen<T>(channelName: AvailableChannel, onmessage: OnMessageHandler<T>) {
    const channel = this.getChannel<T>(channelName);
    channel.onmessage = onmessage;

    return () => {
      this.close(channelName);
    };
  }

  async post<T>(channelName: AvailableChannel, message: T, includeSelf = false) {
    if (includeSelf) {
      // Broadcast channel don't listen to messages that come from the same instance
      const inclusiveChannel = new BroadcastChannel<T>(channelName);
      await inclusiveChannel.postMessage(message);
      await inclusiveChannel.close();
    } else {
      const channel = this.getChannel<T>(channelName);
      await channel.postMessage(message);
    }
  }

  async close(channelName: AvailableChannel): Promise<void> {
    const channel = this.channels.get(channelName);
    if (channel) {
      this.channels.delete(channelName);
      await channel.close();
    }
  }
}

export const broadcastClient = new BroadcastClient();
