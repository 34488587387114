import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { IterationRow } from './IterationRow';

const IterationListItemFragment = graphql(`
  fragment iterationListItem on Iteration {
    vecticeId
    ...iterationRow
  }
`);

let globalId = 0;

interface IterationListItemProps {
  iteration: FragmentType<typeof IterationListItemFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const IterationListItem = ({ project, workspace, ...props }: IterationListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`iteration-list-item-${globalId++}`);
  const iteration = useFragment(IterationListItemFragment, props.iteration);

  return (
    <AssetListItem
      to={buildLink(VecticeRoutes.ITERATION, { iterationId: iteration.vecticeId })}
      aria-labelledby={ariaId}
    >
      <IterationRow id={ariaId} iteration={iteration} project={project} workspace={workspace} />
    </AssetListItem>
  );
};
