import { graphql } from '../../gql';

export const RESET_ORGANIZATION_FEATURE_FLAG = graphql(`
  mutation resetOrganizationFeatureFlag($organizationId: Float!, $code: String!) {
    resetOrganizationFeatureFlag(organizationId: $organizationId, code: $code) {
      code
      enabled
      organizationId
      overridden
    }
  }
`);
