import React, { PropsWithChildren } from 'react';

import { FlexContainer, Typography } from '../../../ui';

import styles from './Legend.module.scss';

interface LegendProps {
  color: string;
}

export const Legend = ({ color, children }: PropsWithChildren<LegendProps>) => (
  <FlexContainer>
    <div style={{ backgroundColor: color }} className={styles.legend} />
    <Typography variant="callout">{children}</Typography>
  </FlexContainer>
);
