import React, { useState } from 'react';

import { useOrgConfig } from '../../context';
import { UserInvite, UserInviteStatus, UserRole } from '../../gql/graphql';
import { useWithRoles } from '../../guards';
import { message } from '../../ui';
import { InvitesSummaryModal, UserInviteModal } from '../modals';
import { UserInviteType } from '../modals/InvitesSummaryModal';

import { InviteUserModal } from './InviteUserModal';

interface InviteModalPrompts {
  readonly onClose: () => void;
}

export function InviteModal({ onClose }: InviteModalPrompts) {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showInviteSummary, setShowInviteSummary] = useState(false);
  const { emailDomains, loading: loadingConfig } = useOrgConfig();
  const [noWorkspaces, setNoWorkspaces] = useState(true);
  const [createdInvite, setCreatedInvite] = useState<Pick<UserInvite, 'email' | 'inviteLinkToken'> | null>();
  const [createdInvites, setCreatedInvites] = useState<UserInviteType[]>([]);
  const { allowed: isOrgAdmin } = useWithRoles({ organizationRole: UserRole.OrgAdmin });

  const config = useOrgConfig();

  const handleCreateUserInvitation = (createUserInvite: UserInviteType[], noWorkspaces: boolean) => {
    setNoWorkspaces(noWorkspaces);
    if (createUserInvite.length > 1) {
      setCreatedInvites(createUserInvite);
      setShowInviteSummary(true);
    } else if (createUserInvite.length === 1) {
      const { email, status: inviteStatus } = createUserInvite[0];
      if (inviteStatus === UserInviteStatus.CreatedEmailDomainBlocked) {
        setCreatedInvite(createUserInvite[0].invite);
        setShowInviteModal(true);
        return;
      }
      switch (inviteStatus) {
        case UserInviteStatus.Success:
          message.success($t({ id: 'organization.sentInvitation', defaultMessage: 'Invitation sent.' }));
          break;
        case UserInviteStatus.InviteExists:
          message.info($t({ id: 'organization.alreadyExistingInvite', defaultMessage: 'Already invited.' }));
          break;
        case UserInviteStatus.SuccessUserExists:
          if (noWorkspaces)
            message.info(
              $t({
                id: 'organization.alreadyExistingUser.noWorkspace',
                defaultMessage: '"{email}" is an active user and was not invited again',
                values: { email },
              }),
            );
          else
            message.info(
              $t({
                id: 'organization.alreadyExistingUser',
                defaultMessage:
                  '"{email}" is already an active user. You have now added them to the specified workspace(s)',
                values: { email },
              }),
            );
          break;
        case UserInviteStatus.FailedUserDisabled:
          isOrgAdmin
            ? message.warning(
                $t({
                  id: 'organization.noWorkspace.disableUser.isOrgAdmin',
                  defaultMessage:
                    '"{email}" is disabled from this organization. You can re-enable the user in users settings.',
                  values: { email },
                }),
              )
            : message.warning(
                $t({
                  id: 'organization.noWorkspace.disableUser.isMember',
                  defaultMessage: '"{email}" is disabled from this organization. Please contact your org admin.',
                  values: { email },
                }),
              );
          break;
        case UserInviteStatus.FailedUsersLimitReached:
          message.warning(
            $t({
              id: 'organization.usersLimitReached',
              defaultMessage: 'Users limit reached. Please contact support.',
            }),
          );
          break;
        default:
          break;
      }
      onClose();
    }
  };

  if (showInviteModal && createdInvite)
    return (
      <UserInviteModal
        invite={createdInvite}
        onClose={() => {
          setShowInviteModal(true);
          onClose();
        }}
      />
    );

  if (showInviteSummary && !!createdInvites.length)
    return (
      <InvitesSummaryModal
        emailsEnabled={config.sendEmail}
        invites={createdInvites}
        noWorkspaces={noWorkspaces}
        onClose={() => {
          setShowInviteSummary(true);
          onClose();
        }}
      />
    );

  return (
    <InviteUserModal
      emailDomains={emailDomains}
      loading={loadingConfig}
      onClose={onClose}
      onCreateInvite={handleCreateUserInvitation}
    />
  );
}
