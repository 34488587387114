import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { VecticeRoutes, buildLink } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { ReportRow } from './ReportRow';

const ReportListItemFragment = graphql(`
  fragment reportListItem on CDTReport {
    id
    ...reportRow
  }
`);

let globalId = 0;

interface ReportListItemProps {
  report: FragmentType<typeof ReportListItemFragment>;
}

export const ReportListItem = ({ ...props }: ReportListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`report-list-item-${globalId++}`);
  const report = useFragment(ReportListItemFragment, props.report);

  return (
    <AssetListItem to={buildLink(VecticeRoutes.CDT_REPORT, { reportId: report.id })} aria-labelledby={ariaId}>
      <ReportRow id={ariaId} report={report} />
    </AssetListItem>
  );
};
