import React from 'react';
import { Link } from 'react-router-dom';

import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { FindingReviewerChangeNotification as NotificationType } from '../types';

import { Notification } from './Notification';

interface FindingReviewerChangeNotificationProps {
  notification: NotificationType;
  onClose: () => void;
}

export const FindingReviewerChangeNotification = ({
  notification: { content, creator, createdDate },
  onClose,
}: FindingReviewerChangeNotificationProps) => {
  const parsed = JSON.parse(content);
  return (
    <Notification
      component={Link}
      to={buildLink(VecticeRoutes.FINDING, { findingId: parsed?.finding?.id })}
      creator={creator}
      createdDate={createdDate}
      onClose={onClose}
    >
      {$t({
        id: 'notification.issue.assigneeChange',
        defaultMessage: '<bold>{creator}</bold> assigned <bold>you</bold> to issue <bold>{title}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          title: parsed?.finding?.name ?? '',
        },
      })}
    </Notification>
  );
};
