import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ListItem } from '../list';

import styles from './AssetListItem.module.scss';

export const AssetListItem = ({ to, className, ...linkProps }: PropsWithChildren<LinkProps>) => (
  <ListItem component={to ? Link : 'li'} to={to} className={cn(styles.listItem, className)} {...linkProps} />
);
