import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CREATE_USER_VIEW_ACTIVITY } from '../graphql/queries';

// This list must be copied from the backend as we have no way to pass a string union through graphql right now
const ALL_USER_VIEW_ACTIVITY_TYPE = [
  'Page_Visit',
  'Tutorial',
  'Invite',
  'Accepted_Invite',
  'Documentation_Visit',
  'Ask_AI',
  'SDK_Auth',
  'UI_Auth',
  'Thread',
  'Thread_Reply',
  'CDTReport',
  'Lineage',
  'Macro',
  'Workspace',
  'API_Key',
  'Home',
  'Contact_Support',
  'Open_Trailhead',
] as const;

type UserViewActivityTypeTuple = typeof ALL_USER_VIEW_ACTIVITY_TYPE;

type UserViewActivityType = UserViewActivityTypeTuple[number];

export const useAnalytics = (listen?: boolean) => {
  const location = useLocation();

  const [logPageVisit] = useMutation(CREATE_USER_VIEW_ACTIVITY, {
    variables: { userViewActivity: { type: 'Page_Visit', operationName: location.pathname } },
    onError: () => null,
  });

  const [logDocumentationVisit] = useMutation(CREATE_USER_VIEW_ACTIVITY, {
    variables: { userViewActivity: { type: 'Documentation_Visit' } },
    onError: () => null,
  });

  const [logClickCall] = useMutation(CREATE_USER_VIEW_ACTIVITY, {
    onError: () => null,
  });

  const logClick = (type: UserViewActivityType, operation: string, clickType: 'click' | 'auxclick') => {
    logClickCall({ variables: { userViewActivity: { type, operationName: `${operation}-${clickType}` } } });
  };

  useEffect(() => {
    if (listen) logPageVisit();
  }, [logPageVisit, location, listen]);

  return {
    logPageVisit,
    logDocumentationVisit,
    logClick,
  };
};
