import React, { PropsWithChildren } from 'react';

import { Divider, EmptySelect, FilterSearch, FlexContainer, Loading, Menu, WithLoading } from '../../ui';

import styles from './MenuWithSearch.module.scss';

interface MenuWithSearchProps {
  isEmpty?: boolean;
  loading?: boolean;
  search?: string;
  onSearch: (search: string) => void;
}

export const MenuWithSearch = ({
  children,
  isEmpty,
  loading,
  search,
  onSearch,
}: PropsWithChildren<MenuWithSearchProps>) => {
  return (
    <Menu className={styles.menu} preventCloseOnClick>
      <FilterSearch
        autoFocus
        initialSearch={search}
        placeholder={$t({ id: 'search.placeholder', defaultMessage: 'Search...' })}
        onSearch={onSearch}
      />
      <Divider />
      <WithLoading
        loading={loading}
        skeleton={
          <FlexContainer align="center" className={styles.loader} justify="center">
            <Loading color="primary" size={36} />
          </FlexContainer>
        }
      >
        {isEmpty ? (
          <EmptySelect text={$t({ id: 'search.noResults', defaultMessage: 'No results' })} />
        ) : (
          <>{children}</>
        )}
      </WithLoading>
    </Menu>
  );
};
