import { graphql } from '../../gql';

export const GET_USER_LIST = graphql(`
  query getOrganizationUsersOfOrganization($filters: UserListFiltersInput) {
    getOrganizationUsersForUser(filters: $filters) {
      id
      role
      name
      email
      ...userFields
    }
  }
`);
