import React, { useMemo, useState } from 'react';

import { Modal, Tab, Tabs, Typography } from '../../ui';
import { autologCell, autologConfiguration, autologNotebook, autologSetupSnippet, getArray } from '../../utils';

import { VecticeCodeSnippets } from './VecticeCodeSnippets';

import styles from './ApiHelp.module.scss';

type Snippet = { label: string; hint?: string; code: string };

interface ApiHelpModalProps {
  title?: string;
  phaseId?: string;
  snippets: Snippet | Snippet[];
  onClose?: () => void;
}

export const ApiHelpModal = ({ title, phaseId, snippets, onClose }: ApiHelpModalProps) => {
  const autoLogSnippets = useMemo(
    () => [
      {
        label: $t({
          id: 'codeSnippet.iteration.autoLog.setup.label',
          defaultMessage: 'Setup',
        }),
        code: autologSetupSnippet(),
      },
      {
        label: $t({
          id: 'codeSnippet.iteration.autoLog.config.label',
          defaultMessage: 'Autolog configuration',
        }),
        hint: $t({
          id: 'codeSnippet.iteration.autoLog.config.hint',
          defaultMessage:
            'Note: Configuration must happen at the beginning of the notebook prior to any cells execution for autolog.notebook to behave optimally',
        }),
        code: autologConfiguration(phaseId),
      },
      {
        label: $t({
          id: 'codeSnippet.iteration.autoLog.notebook.label',
          defaultMessage: 'Autolog your entire notebook',
        }),
        code: autologNotebook(),
      },
      {
        label: $t({
          id: 'codeSnippet.iteration.autoLog.notebook.cell.label',
          defaultMessage: 'Autolog a notebook cell',
        }),
        code: autologCell(),
      },
    ],
    [phaseId],
  );

  const arr = getArray(snippets);
  const [tab, setTab] = useState(0);

  const codeSnippetsArray: {
    tabContent: string;
    description: { title: string; content: string } | undefined;
    content: Snippet[];
    footer: string[];
  }[] = [
    {
      tabContent: $t({
        id: 'autoDoc.modal.tab.vecticeAPI.autolog',
        defaultMessage: 'Autolog',
      }),
      description: undefined,
      content: autoLogSnippets,
      footer: [
        $t({
          id: 'apiHelp.codeSnippet.autolog.information',
          defaultMessage: 'For detailed information, supported libraries <autolog_doc>click here</autolog_doc>',
        }),
      ],
    },
    {
      tabContent: $t({
        id: 'autoDoc.modal.tab.vecticeAPI.core',
        defaultMessage: 'Core API',
      }),
      description: undefined,
      content: arr,
      footer: [
        $t({
          id: 'apiHelp.codeSnippet.python.button',
          defaultMessage:
            'For Python with other frameworks or data storage, <python_cheatsheet>click here</python_cheatsheet>',
        }),
        $t({
          id: 'apiHelp.codeSnippet.r.button',
          defaultMessage: 'For R, please <r_cheatsheet>click here</r_cheatsheet>',
        }),
      ],
    },
  ];

  return (
    <Modal
      title={
        title ??
        $t({
          id: 'apiHelp.modal.title',
          defaultMessage: 'API Help',
        })
      }
      className={styles.modal}
      contentClassName={styles.dialogContent}
      closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
      onClose={onClose}
    >
      <>
        <Tabs className={styles.tabs}>
          {codeSnippetsArray.map(({ tabContent }, index) => (
            <Tab key={`outputs-${index + 1}`} onClick={() => setTab(index)} active={index === tab}>
              {tabContent}
            </Tab>
          ))}
        </Tabs>
        <div className={styles.content}>
          {!!codeSnippetsArray[tab].description && (
            <>
              <Typography variant="callout" weight="semi-bold" color="primary" paragraph>
                {codeSnippetsArray[tab].description?.title}
              </Typography>
              <Typography variant="callout" color="primary" paragraph className={styles.descriptionContent}>
                {codeSnippetsArray[tab].description?.content}
              </Typography>
            </>
          )}
          <Typography className={styles.results} paragraph>
            {
              <VecticeCodeSnippets
                snippets={codeSnippetsArray[tab].content}
                tab={tab}
                footerContent={codeSnippetsArray[tab].footer}
              />
            }
          </Typography>
        </div>
      </>
    </Modal>
  );
};
