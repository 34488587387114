import React, { Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

import { AuthenticationProvider } from '../context';
import { UserRole } from '../gql/graphql';
import { Allowed, Fallback, WithRoles } from '../guards';
import { PublicPagesLayout } from '../layout/PublicPagesLayout';
import { getRoutePath, VecticeRoutes } from '../routes';
import { FlexContainer, Launch, Loading } from '../ui';

const AuthPage = React.lazy(() => import('../pages/auth'));
const LoggedOutPage = React.lazy(() => import('../pages/errors/LoggedOutPage'));
const UnknownUserPage = React.lazy(() => import('../pages/errors/UnknownUserPage'));
const ForgotPasswordPage = React.lazy(() => import('../pages/forgot-password'));
const LoginPage = React.lazy(() => import('../pages/login'));
const LogoutPage = React.lazy(() => import('../pages/logout'));
const SignUpPage = React.lazy(() => import('../pages/signup'));
const PasswordResetPage = React.lazy(() => import('../pages/password-reset'));
const RegisterPage = React.lazy(() => import('../pages/register'));
const VerifyEmailPage = React.lazy(() => import('../pages/verify-email'));
const PrivateRoutes = React.lazy(() => import('../pages/PrivateRoutes'));
const AdministrationRoutes = React.lazy(() => import('../pages/AdministrationRoutes'));

export const AppRouter = () => (
  <Routes>
    <Route
      element={
        <PublicPagesLayout>
          <Suspense
            fallback={
              /* Skeleton that matches the log in page dimension */
              <FlexContainer align="center" justify="center" style={{ minHeight: 200 }}>
                <Loading />
              </FlexContainer>
            }
          >
            <Outlet />
          </Suspense>
        </PublicPagesLayout>
      }
    >
      <Route path={getRoutePath(VecticeRoutes.LOGIN)} element={<LoginPage />} />
      <Route path={getRoutePath(VecticeRoutes.SIGNUP)} element={<SignUpPage />} />
      <Route path={getRoutePath(VecticeRoutes.AUTH)} element={<AuthPage />} />
      <Route path={getRoutePath(VecticeRoutes.LOGOUT)} element={<LogoutPage />} />
      <Route path={getRoutePath(VecticeRoutes.LOGGED_OUT)} element={<LoggedOutPage />} />
      <Route path={getRoutePath(VecticeRoutes.UNKNOWN_USER)} element={<UnknownUserPage />} />
      <Route path={getRoutePath(VecticeRoutes.REGISTER, { wildCard: true })} element={<RegisterPage />} />
      <Route path={getRoutePath(VecticeRoutes.FORGOT_MY_PASSWORD)} element={<ForgotPasswordPage />} />
      <Route
        path={getRoutePath(VecticeRoutes.PASSWORD_RESET, { fullPath: true, wildCard: true })}
        element={<PasswordResetPage />}
      />
      <Route path={getRoutePath(VecticeRoutes.VERIFY_EMAIL)} element={<VerifyEmailPage />} />
    </Route>
    <Route
      path="*"
      element={
        <AuthenticationProvider>
          <Suspense fallback={<Launch />}>
            <WithRoles organizationRole={UserRole.Superadmin}>
              <Allowed>
                <AdministrationRoutes />
              </Allowed>
              <Fallback>
                <PrivateRoutes />
              </Fallback>
            </WithRoles>
          </Suspense>
        </AuthenticationProvider>
      }
    />
  </Routes>
);
