import React from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as IterationIcon } from '../../../assets/icons/specials/ic-iteration.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { IterationTooltip } from './IterationTooltip';

interface IterationLinkProps {
  name?: string | null;
  resourceId?: string | null;
  tooltipPlacement?: TooltipProps['placement'];
}

export const IterationLink = ({ name, resourceId, tooltipPlacement = 'topLeft' }: IterationLinkProps) => (
  <IterationTooltip id={resourceId} placement={tooltipPlacement}>
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.ITERATION, { iterationId: resourceId }) : undefined}
      icon={IterationIcon}
    />
  </IterationTooltip>
);
