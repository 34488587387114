import { graphql } from '../../gql';

export const CANCEL_ITERATION = graphql(`
  mutation cancelIteration($id: VecticeId!) {
    updateIteration(id: $id, data: { status: Abandoned }) {
      vecticeId
      status
    }
  }
`);
