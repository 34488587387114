import React from 'react';

import { FormatUserName } from '../../formatters';
import { UserRemovedFromWorkspace } from '../types';

import { Notification } from './Notification';

interface UserRemovedFromWorkspaceNotificationProps {
  notification: UserRemovedFromWorkspace;
}

export const UserRemovedFromWorkspaceNotification = ({
  notification: { creator, createdDate, workspace },
}: UserRemovedFromWorkspaceNotificationProps) => {
  return (
    <Notification creator={creator} createdDate={createdDate}>
      {$t({
        id: 'notification.userRemovedFromWorkspace',
        defaultMessage: '<bold>{creator}</bold> removed <bold>you</bold> from the <bold>{workspace}</bold> workspace',
        values: {
          creator: <FormatUserName user={creator} />,
          workspace: workspace?.name,
        },
      })}
    </Notification>
  );
};
