import { ReactComponent as WarningIcon } from '../../assets/icons/interface/ic-alert-triangle.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/interface/ic-check-circle.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/interface/ic-information-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/interface/ic-remove-circle.svg';

import { MessageVariant } from './interfaces';

export const getMessageIcon = (variant: MessageVariant) => {
  switch (variant) {
    case 'error':
      return ErrorIcon;
    case 'info':
      return InfoIcon;
    case 'success':
      return SuccessIcon;
    case 'warning':
      return WarningIcon;
    default:
      return undefined;
  }
};
