import { ApolloCache } from '@apollo/client';

import type { AdvancedFilter, DateFilter, GroupFilter, ListOrderInput, ValueMatchMap } from '../gql/graphql';

import { DatasetSourceOrigin, FilterConditionEnum, MatchEnum, OrderDirection, ProjectStatus } from '../gql/graphql';
import { getArray } from '../utils';

export const flushFromGraphQLCache = <T>(
  cache: ApolloCache<T>,
  storedObject: { [key: string]: any; __typename?: string },
) => {
  const normalizedId = cache.identify(storedObject);
  const dataWasRemoved = cache.evict({ id: normalizedId });
  cache.gc();

  return dataWasRemoved;
};

export const updateInGraphQLCache = <T>(
  cache: ApolloCache<T>,
  storedObject: { [key: string]: any; __typename?: string },
  fields: Record<string, (cachedDate: any) => any>,
) => {
  const normalizedId = cache.identify(storedObject);
  const dataWasModified = cache.modify({
    id: normalizedId,
    fields,
  });
  cache.gc();

  return dataWasModified;
};

export const buildOrder = (
  fields: { field: string; direction?: OrderDirection } | { field: string; direction?: OrderDirection }[],
): ListOrderInput => {
  const array = getArray(fields);

  const [primaryOrder, ...nestedOrders] = array;

  return {
    field: primaryOrder.field,
    direction: primaryOrder.direction ?? OrderDirection.Desc,
    secondaryOrder: nestedOrders.length > 0 ? buildOrder(nestedOrders) : undefined,
  };
};

export const buildAdvancedBooleanFilter = (field: string, value?: boolean): GroupFilter[] => {
  if (value === undefined) return [];
  return [
    {
      filters: [
        {
          field,
          valuesWithMatch: [{ value, match: MatchEnum.Equal }],
        },
      ],
    },
  ];
};

export const buildAdvancedDateFilter = (dateFilter?: DateFilter): GroupFilter[] => {
  if (!dateFilter) return [];
  const { field, start, end } = dateFilter;
  const startValue = start ? { value: start, match: MatchEnum.GreaterOrEqual } : undefined;
  const endValue = end ? { value: end, match: MatchEnum.LowerOrEqual } : undefined;
  return [
    {
      filters: [
        {
          field: field || 'createdDate',
          valuesWithMatch: [...(startValue ? [startValue] : []), ...(endValue ? [endValue] : [])],
        },
      ],
    },
  ];
};

export const buildAdvancedEntitiesFilter = (
  entityFilter?: (ValueMatchMap & { key?: string })[],
  fieldName?: string,
): GroupFilter[] => {
  if (!entityFilter || !fieldName) return [];
  const metricAdvancedFilter: AdvancedFilter[] = [];
  entityFilter.forEach((filter) => {
    if (filter.value !== '' && filter.key) {
      metricAdvancedFilter.push({
        field: fieldName,
        nestedKey: { field: 'key', value: filter.key },
        nestedValue: {
          field: 'value',
          valuesWithMatch: [...[{ value: filter.value, match: filter.match }]],
        },
      });
    }
  });
  return [{ filters: metricAdvancedFilter }];
};

export const buildAdvancedNameFilter = (nameFilters?: ValueMatchMap[]): GroupFilter[] => {
  if (!nameFilters) return [];
  const groups = nameFilters
    .filter((nameFilter) => nameFilter.value !== '')
    .map((nameFilter) => ({
      filters: [
        {
          field: 'name',
          valuesWithMatch: [
            {
              value: nameFilter.value,
              match: nameFilter.match,
            },
          ],
        },
      ],
    }));
  return [
    {
      groups,
      condition: FilterConditionEnum.Or,
    },
  ];
};

export const buildAdvancedIntegerFilter = (field: string, ids?: number[]): GroupFilter[] => {
  if (!ids || ids?.length === 0) return [];
  return [
    {
      filters: [
        {
          field: field,
          values: ids,
          match: MatchEnum.Equal,
        },
      ],
    },
  ];
};

export const buildAdvancedProjectStatusFilter = (statuses?: ProjectStatus[]): GroupFilter[] => {
  if (!statuses) return [];
  const group = statuses.map((status) => ({
    field: 'status',
    values: [status],
    match: MatchEnum.Equal,
  }));
  return [
    {
      condition: FilterConditionEnum.Or,
      filters: group,
    },
  ];
};

export const buildAdvancedExcludedVecticeIdFilter = (ids?: string[]): GroupFilter[] => {
  if (!ids || ids?.length === 0) return [];
  return [
    {
      filters: [
        {
          field: 'vecticeId',
          values: ids,
          match: MatchEnum.Different,
        },
      ],
    },
  ];
};

export const buildAdvancedDatasetSourceOriginFilter = (sources?: DatasetSourceOrigin[]): GroupFilter[] => {
  if (!sources) return [];
  const group = sources.map((source) => ({
    field: 'sourceOrigin',
    values: [source],
    match: MatchEnum.Equal,
  }));
  return [
    {
      condition: FilterConditionEnum.Or,
      filters: group,
    },
  ];
};

export const buildAdvancedCreatedByFilter = (userIds?: number[]) => buildAdvancedIntegerFilter('createdById', userIds);

export const buildAdvancedOwnerFilter = (userIds?: number[]) => buildAdvancedIntegerFilter('ownerId', userIds);
