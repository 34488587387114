import React, { createContext, PropsWithChildren, useContext } from 'react';

import { VecticeDateOptions } from '../utils';

interface ExportFormatType {
  dateOptions: VecticeDateOptions;
  hideAvatar?: boolean;
  imagesAsLink?: boolean;
}

const defaultContext: ExportFormatType = {
  dateOptions: {},
  hideAvatar: undefined,
  imagesAsLink: undefined,
};

const ExportFormatContext = createContext<ExportFormatType>(defaultContext);

export const useExportFormatContext = () => {
  const context = useContext<ExportFormatType>(ExportFormatContext as unknown as React.Context<ExportFormatType>);

  if (context === undefined) {
    return defaultContext;
  }

  return context;
};

export const ExportFormatContextProvider = ({ children, ...value }: PropsWithChildren<ExportFormatType>) => (
  <ExportFormatContext.Provider value={value}>{children}</ExportFormatContext.Provider>
);
