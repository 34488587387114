import { useQuery } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { ReactComponent as AddIcon } from '../../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/interface/ic-remove.svg';
import { useVecticeForm } from '../../../hooks';
import { Button, FlexContainer, Typography } from '../../../ui';
import { VecticeResourceType } from '../../../utils';
import { AssetSelector } from '../../asset-selector';
import { DATASETS } from '../../asset-selector/queries';

import styles from './LineageInput.module.scss';

interface LineageInputProps {
  inputs: string[];
  parentId: string;
  projectId: string;
  versionId: string;
  onUpdate: (inputs: string[]) => void;
}

interface LineageFormData {
  variables: { vecticeId: string }[];
}

export const LineageInput = ({ inputs, parentId, projectId, versionId, onUpdate }: LineageInputProps) => {
  const [errorIndex, setErrorIndex] = useState<number | null>(null);

  const { control, formState, clearErrors, setError, setValue } = useVecticeForm<LineageFormData>({
    defaultValues: {
      variables: inputs.map((vecticeId) => ({
        vecticeId,
      })),
    },
    mode: 'all',
  });
  const { errors } = formState;

  const { fields, append, replace } = useFieldArray({
    control,
    name: 'variables',
  });

  const emptyFieldId = useMemo(() => {
    if (fields.length === 0) return null;
    return fields.find(({ vecticeId }) => !vecticeId)?.id;
  }, [fields]);

  const validateFields = (variables: { vecticeId: string }[]) => {
    if (errorIndex !== null) {
      clearErrors('variables');
      setErrorIndex(null);
    }

    const selectedIds = variables.map(({ vecticeId }) => vecticeId).filter((v) => v !== '');
    const uniques = new Set();

    for (let index = 0; index < selectedIds.length; index++) {
      if (uniques.has(selectedIds[index])) {
        setErrorIndex(index);
        return $t({
          id: 'modal.editLineage.duplicate.msg',
          defaultMessage: 'The same version cannot be selected twice',
        });
      }
      uniques.add(selectedIds[index]);
    }
  };

  const onSelect = useCallback(
    (fieldId: string, vecticeId: string) => {
      const field = fields.find(({ id }) => id === fieldId);
      if (field) {
        const newFields = fields.map((field) => (field.id === fieldId ? { ...field, vecticeId } : { ...field }));
        setValue(`variables`, newFields);
        const error = validateFields(newFields);
        if (error) {
          setError('variables', { type: 'validate', message: error });
        } else {
          onUpdate(newFields.map(({ vecticeId }) => vecticeId));
        }
      }
    },
    [fields, validateFields],
  );

  const onRemove = useCallback(
    (fieldId: string) => {
      const field = fields.find(({ id }) => id === fieldId);
      if (field) {
        const newFields = fields.filter(({ id }) => id !== fieldId);
        const error = validateFields(newFields);
        if (field.vecticeId && !error) {
          onUpdate(newFields.map(({ vecticeId }) => vecticeId).filter((v) => v !== ''));
        } else if (error) {
          setError('variables', { type: 'validate', message: error });
        }
        replace(newFields);
      }
    },
    [fields, validateFields],
  );

  const { data: datasetList, loading: loadingDatasetTotal } = useQuery(DATASETS, {
    variables: { projectId },
  });

  const noDataSet = Boolean(
    !datasetList ||
      datasetList.getDatasetList.total === 0 ||
      (datasetList.getDatasetList.total === 1 && datasetList.getDatasetList.items[0].vecticeId === parentId),
  );

  return (
    <FlexContainer className={styles.container}>
      {fields.length === 0 ? (
        <Typography variant="callout" color="tertiary">
          {$t({ id: 'LineageInput.noInputDefined', defaultMessage: 'No input dataset defined' })}
        </Typography>
      ) : (
        <FlexContainer direction="column">
          {fields.map((field, index) => (
            <FlexContainer key={field.id} align="start">
              <AssetSelector
                assetType={VecticeResourceType.DATASET_VERSION}
                canClearAsset={false}
                dropdownPlacement="bottomLeft"
                projectId={projectId}
                selectedAssetId={field.vecticeId}
                unavailableAssetIds={[versionId, ...fields.map(({ vecticeId }) => vecticeId), parentId]}
                onSelect={(vecticeId) => onSelect(field.id, vecticeId as string)}
              />
              <Button
                aria-label={$t({ id: 'editLineage.modal.variable.remove', defaultMessage: 'Remove input' })}
                color="gray"
                leftIcon={DeleteIcon}
                variant="phantom"
                onClick={() => onRemove(field.id)}
              />
              {errorIndex === index && errors.variables?.type === 'validate' && (
                <Typography variant="footnote" weight="semi-bold" color="danger" align="center">
                  {errors.variables?.message}
                </Typography>
              )}
            </FlexContainer>
          ))}
        </FlexContainer>
      )}
      <Button
        className={styles.add}
        disabled={noDataSet || !!emptyFieldId}
        leftIcon={AddIcon}
        loading={loadingDatasetTotal}
        variant="white"
        onClick={() => append({ vecticeId: '' })}
      >
        {$t({ id: 'button.add', defaultMessage: 'Add' })}
      </Button>
    </FlexContainer>
  );
};
