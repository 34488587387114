import React from 'react';

import { User, Workspace } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { ModalPrompt } from '../../ui';
import { FormatUserName } from '../formatters';

interface RemoveWorkspaceMemberModalProps {
  user: Pick<User, 'name'> & UserIdentity;
  workspace: Pick<Workspace, 'name'>;
  onCancel: () => void;
  onConfirm: () => void;
}

export const RemoveWorkspaceMemberModal = ({
  user,
  workspace,
  onCancel,
  onConfirm,
}: RemoveWorkspaceMemberModalProps) => (
  <ModalPrompt
    cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
    confirmLabel={$t({ id: 'modal.remove', defaultMessage: 'Remove' })}
    destructive
    title={$t({ id: 'RemoveWorkspaceMemberModal.title', defaultMessage: 'Remove Workspace Member' })}
    onClose={onCancel}
    onConfirm={onConfirm}
  >
    {$t({
      id: 'RemoveWorkspaceMemberModal.message',
      defaultMessage:
        'Are you sure you want to remove <bold>{user}</bold> from <bold>{workspace}</bold>? If you remove this member, they will no longer be able to access this workspace and its projects. Their contributions to this workspace will be retained.',
      values: {
        user: <FormatUserName user={user} />,
        workspace: workspace.name,
      },
    })}
  </ModalPrompt>
);
