import React, { useEffect, useState } from 'react';

import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { useVecticeForm } from '../../hooks';
import { Button, FlexContainer, Form, TextArea, Typography } from '../../ui';
import { defaultDescriptionFieldConfiguration } from '../../utils';

import { AssetDetails } from './AssetDetails';

interface FormData {
  description?: string | null;
}

interface AssetDescriptionProps {
  label: string;
  description?: string | null;
  onChange?: (description?: string | null) => any;
}

export const AssetDescription = ({ label, description, onChange }: AssetDescriptionProps) => {
  const [editing, setEditing] = useState(false);

  const { preSubmit, reset, registerWithErrors, formState } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: { description },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  useEffect(() => {
    reset({ description: description });
  }, [description]);

  const handleSubmit = async (data: FormData) => {
    await onChange?.(data.description);
    setEditing(false);
    reset({ description: data.description });
  };

  const handleCancel = () => {
    setEditing(false);
    reset({ description: description });
  };

  if (editing) {
    return (
      <AssetDetails label={label}>
        <FlexContainer component={Form} direction="column" onSubmit={preSubmit(handleSubmit)}>
          <TextArea
            aria-label={label}
            {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
            hint={null}
            placeholder={$t({ id: 'asset.details.description.placeholder', defaultMessage: 'Add description' })}
            autoFocus
            small
          />
          <FlexContainer align="center" justify="flex-end" gap={0}>
            <Button size="xs" variant="phantom" onClick={handleCancel}>
              {$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
            </Button>
            <Button size="xs" disabled={hasErrors || !isDirty} loading={isSubmitting} type="submit">
              {$t({ id: 'button.save', defaultMessage: 'Save' })}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </AssetDetails>
    );
  }

  return (
    <AssetDetails
      label={
        !!onChange
          ? (ariaId) => (
              <FlexContainer align="flex-start" justify="space-between">
                <Typography id={ariaId} component="h3" variant="callout" color="primary" weight="semi-bold">
                  {label}
                </Typography>
                <Button
                  aria-label={$t({ id: 'asset.details.description.edit', defaultMessage: 'Edit description' })}
                  leftIcon={EditIcon}
                  size="xs"
                  color="gray"
                  variant="phantom"
                  onClick={() => setEditing(true)}
                />
              </FlexContainer>
            )
          : label
      }
    >
      {description ? (
        <Typography variant="callout" color="primary" paragraph>
          {description}
        </Typography>
      ) : (
        <Typography variant="callout" color="disabled" paragraph>
          {$t({ id: 'asset.details.description.empty', defaultMessage: 'No description' })}
        </Typography>
      )}
    </AssetDetails>
  );
};
