import React, { useEffect, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { useValidateName, useVecticeForm } from '../../hooks';
import { Button, Form, Input, TextArea, WithAsyncValidation } from '../../ui';
import { defaultDescriptionFieldConfiguration, defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';

import styles from './ResourceBaseForm.module.scss';

export interface ProjectEditFormValues {
  name: string;
  description?: string | null;
}

interface ProjectEditFormProps extends Omit<VecticeHTMLProps<HTMLFormElement>, 'onSubmit'> {
  defaultValues?: ProjectEditFormValues;
  loading?: boolean;
  onCheckAvailability: (value: string) => Promise<boolean>;
  onSubmit: (values: ProjectEditFormValues) => Promise<void>;
}

export const ProjectEditForm = ({
  defaultValues,
  disabled,
  loading,
  onCheckAvailability,
  onSubmit,
  ...props
}: ProjectEditFormProps) => {
  const { validateName } = useValidateName(onCheckAvailability, VecticeResourceType.PROJECT);

  const currentName = useRef(defaultValues?.name);
  const { formState, preSubmit, registerWithErrors, reset, setError, trigger } = useVecticeForm({
    mode: 'onChange',
    defaultValues,
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  useEffect(() => {
    if (defaultValues) {
      currentName.current = defaultValues.name;
    }
  }, [defaultValues]);

  const submitAndReset: SubmitHandler<ProjectEditFormValues> = async ({ name, description }) => {
    await onSubmit({ name, description });
    reset({ name, description });
  };

  return (
    <Form {...props} onSubmit={preSubmit(submitAndReset)}>
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          gutterBottom
          disabled={disabled}
          label={$t({
            id: 'form.projectEdit.nameLabel',
            defaultMessage: 'Project Name',
          })}
          required
        />
      </WithAsyncValidation>
      <TextArea
        {...registerWithErrors('description', defaultDescriptionFieldConfiguration())}
        disabled={disabled}
        label={$t({ id: 'projectEditForm.description.label', defaultMessage: 'Description' })}
        gutterBottom
      />
      {!disabled && (
        <div className={styles.buttons}>
          <Button type="submit" disabled={!isDirty || hasErrors || loading} loading={isSubmitting} size="sm">
            {$t({ id: 'button.save', defaultMessage: 'Save' })}
          </Button>
        </div>
      )}
    </Form>
  );
};
