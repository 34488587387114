import React, { PropsWithChildren, ReactElement } from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as TableIcon } from '../../../assets/icons/editor/ic-table.svg';
import { FragmentType, useFragment } from '../../../gql';
import { AttachmentFragment } from '../../../graphql/fragments';
import { AssetRow } from '../../../ui';
import { getMimeTypeIcon } from '../../../utils';

interface AttachmentRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  icon?: ReactElement;
  attachment?: FragmentType<typeof AttachmentFragment>;
}

export const AttachmentRow = (props: PropsWithChildren<AttachmentRowProps>) => {
  const attachment = useFragment(AttachmentFragment, props.attachment);

  if (!attachment) {
    return null;
  }

  return (
    <AssetRow
      icon={attachment.mimeType ? getMimeTypeIcon(attachment.mimeType) : TableIcon}
      label={attachment.name ?? $t({ id: 'attachmentRow.file', defaultMessage: 'File' })}
      {...props}
    />
  );
};
