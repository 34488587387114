import CSSMotion from 'rc-motion';
import React from 'react';

export type MaskProps = {
  prefixCls: string;
  visible: boolean;
  motionName?: string;
  style?: React.CSSProperties;
  maskProps?: React.HTMLAttributes<HTMLDivElement>;
};

export default function Mask(props: MaskProps) {
  const { prefixCls, style, visible, maskProps, motionName } = props;

  return (
    <CSSMotion key="mask" visible={visible} motionName={motionName} leavedClassName={`${prefixCls}-mask-hidden`}>
      {(_: any, ref: React.LegacyRef<HTMLDivElement> | undefined) => (
        <div ref={ref} style={{ ...style }} className={`${prefixCls}-mask`} {...maskProps} />
      )}
    </CSSMotion>
  );
}
