import cn from 'classnames';
import React from 'react';

import { getArray } from '../../utils';

import { Main } from './Main';

import styles from './Content.module.scss';

type ContainerAlignment = 'left' | 'center';

interface ContainerProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'size'> {
  align?: ContainerAlignment;
  fullscreen?: boolean;
  overflow?: 'auto' | 'hidden';
}

const Scrollable = ({ children }: VecticeHTMLProps<HTMLDivElement>) => {
  return <div className={styles.scroller}>{children}</div>;
};

const Content = ({ align = 'center', fullscreen, className, overflow, children, ...props }: ContainerProps) => {
  const childrenArray = getArray<any>(children);

  const hasMain = childrenArray?.find((child) => child?.type?.name === Main.name);
  const hasScrollable = childrenArray?.some((child) => child?.type.name === Scrollable?.name);
  const noOverflow = overflow === 'hidden';
  if (noOverflow && hasScrollable) throw new Error('noOverflow and hasScrollable are mutually excluded option');

  if (hasMain) {
    return (
      <div
        className={cn(styles.content, styles[align], className, {
          [styles.noOverflow]: noOverflow,
          [styles.scrollable]: hasScrollable,
        })}
        {...props}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={cn(styles.content, styles[align], className, {
        [styles.noOverflow]: noOverflow,
        [styles.scrollable]: hasScrollable,
      })}
      {...props}
    >
      <Main fullscreen={fullscreen}>{children}</Main>
    </div>
  );
};

Content.Scrollable = Scrollable;

export { Content };
