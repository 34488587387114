import cn from 'classnames';
import React from 'react';

import { formatCssSizes, generateArray } from '../../../utils';

import { CommonSkeletonProps } from './interfaces';
import { SkeletonShape } from './Shape';

import styles from './Skeleton.module.scss';

interface SkeletonParagraphProps
  extends Omit<VecticeHTMLProps<HTMLDivElement>, 'children' | 'shape' | 'width'>,
    CommonSkeletonProps {
  rows?: number;
  height?: number | string;
  width?: number | string | (number | string)[];
  variant?: 'subtitle' | 'body' | 'callout' | 'footnote';
  inline?: boolean;
  gutterBottom?: boolean;
}

export const SkeletonParagraph = ({
  rows = 1,
  variant = 'body',
  height,
  width,
  inline,
  gutterBottom,
  dark,
  darker,
  className,
  ...props
}: SkeletonParagraphProps) => {
  let allRowsWidth = new Array(rows).fill('100%');

  if (Array.isArray(width)) {
    allRowsWidth = allRowsWidth.map((_, i) => formatCssSizes(width[i % width.length], '%'));
  } else if (width !== undefined) {
    allRowsWidth[allRowsWidth.length - 1] = formatCssSizes(width, '%');
  }

  return (
    <>
      {generateArray(rows).map((uuid, i) => (
        <SkeletonShape
          key={uuid}
          height={height}
          width={allRowsWidth[i]}
          className={cn(
            styles.paragraph,
            styles[variant],
            {
              [styles.multiple]: rows && rows > 1,
              [styles.inline]: inline,
              [styles.gutterBottom]: gutterBottom,
              [styles.dark]: dark,
              [styles.darker]: darker,
            },
            className,
          )}
          {...props}
        />
      ))}
    </>
  );
};
