import React, { createContext, PropsWithChildren, useContext } from 'react';

import { VecticeResourceType } from '../utils';

export interface EditorContextType {
  resourceType?: VecticeResourceType;
  resourceId?: string | number;
}

export const defaultEditorContext: EditorContextType = {};

export const EditorContext = createContext<EditorContextType>(defaultEditorContext);

export const useEditorContext = () => {
  const context = useContext<EditorContextType>(EditorContext as unknown as React.Context<EditorContextType>);

  if (context === undefined) {
    throw new Error('useEditorContext must be used within EditorContextProvider');
  }

  return context;
};

export const EditorContextProvider = ({ children, ...value }: PropsWithChildren<EditorContextType>) => (
  <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
);
