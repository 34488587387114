import { useQuery } from '@apollo/client';
import React, { useRef } from 'react';

import type { AssetWidgetProps } from '../../../ui';

import { graphql } from '../../../gql';
import { AssetWidget, FlexContainer, Icon, Typography } from '../../../ui';
import { getVecticeResourceTypeIcon, VecticeResourceType } from '../../../utils';

import { LineageRow } from './LineageRow';

export const GET_LINEAGE_WIDGET = graphql(`
  query getLineageWidget($id: Float!) {
    getJobRunById(lineageId: $id) {
      id
    }
  }
`);

let globalId = 0;

interface LineageWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary'> {
  id: number;
}

export const LineageWidget = ({ id, children, ...props }: LineageWidgetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`lineage-widget-${globalId++}`);
  const { error, loading } = useQuery(GET_LINEAGE_WIDGET, {
    variables: { id },
  });

  if (error) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getVecticeResourceTypeIcon(VecticeResourceType.RUN)} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                {$t({ id: 'component.lineage.deleted', defaultMessage: 'Lineage was deleted' })}
              </Typography>
            </FlexContainer>
          </div>
        }
        {...props}
        open={false}
        expandable={false}
      />
    );
  }

  return <AssetWidget aria-labelledby={ariaId} loading={loading} summary={<LineageRow id={ariaId} />} {...props} />;
};
