import cn from 'classnames';
import React from 'react';

import styles from './Grid.module.scss';

interface GridProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'columns' | 'rows'> {
  columns?: number;
  rows?: number;
  gap?: string | number;
}

export const Grid = ({ columns, rows, gap = 16, className, style, ...props }: GridProps) => (
  <div
    className={cn(styles.grid, className)}
    style={{
      gridTemplateColumns: columns ? `repeat(${columns},minmax(0,1fr))` : undefined,
      gridTemplateRows: rows ? `repeat(${rows},1fr)` : undefined,
      gridGap: gap,
      ...style,
    }}
    {...props}
  />
);
