import { graphql } from '../../gql';

export const CDT_REPORTS_SELECTOR = graphql(`
  query CDTReportsSelector(
    $type: CDTReportQueryType
    $filters: CDTReportFiltersInput!
    $order: ListOrderInput
    $page: PageInput
  ) {
    CDTReports(type: $type, filters: $filters, order: $order, page: $page) {
      items {
        id
        name
      }
      page {
        ...pageIndexFields
      }
      total
    }
  }
`);
