import cn from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

import { Typography } from '../typography';

import styles from './ShowMoreText.module.scss';

interface ShowMoreTextProps {
  lines: number;
  more?: string;
  less?: string;
  className?: string;
  anchorClass?: string;
  onClick?: () => void;
  expanded?: boolean;
  customButton?: (isExpanded: boolean, toggleText: () => void) => React.ReactNode;
  children: React.ReactNode;
}

const ShowMoreText: React.FC<ShowMoreTextProps> = ({
  lines,
  more,
  less,
  className,
  anchorClass,
  onClick,
  expanded = false,
  customButton,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      const { clientHeight, scrollHeight } = contentRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  }, [children, lines]);

  const toggleText = () => {
    setIsExpanded((prevState) => !prevState);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={className}>
      <div
        ref={contentRef}
        className={cn(styles.description, {
          [styles.expanded]: isExpanded,
          [styles.collapsed]: !isExpanded,
        })}
        style={{ WebkitLineClamp: isExpanded ? 'unset' : lines }}
      >
        <Typography paragraph variant="callout">
          {children}
        </Typography>
      </div>
      {isTruncated &&
        (customButton ? (
          customButton(isExpanded, toggleText)
        ) : (
          <Typography
            variant="callout"
            color="accent"
            className={cn(styles.toggleButton, anchorClass)}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              e.preventDefault();
              toggleText();
            }}
          >
            {isExpanded
              ? less ||
                $t({
                  id: 'showMoreText.less.text',
                  defaultMessage: 'Show less',
                })
              : more ||
                $t({
                  id: 'showMoreText.more.text',
                  defaultMessage: 'Show more',
                })}
          </Typography>
        ))}
    </div>
  );
};

export default ShowMoreText;
