import config from '../config';

export const autologSetupSnippet = () => `%pip install -q vectice -U`;

export const autologApiKeySnippet = (apiKey: string, host: string = config.ORIGIN) =>
  `${autologSetupSnippet()}
from vectice import autolog

autolog.config(
  api_token = '${apiKey}',
  host = '${host}',
  phase = 'PHA-XXX',
)
`;

export const autologConfiguration = (phaseId = 'PHA-XXXX', host = config.ORIGIN) =>
  `from vectice import autolog
autolog.config(
  api_token = 'API_KEY_FROM_VECTICE',
  host = '${host}',
  phase = '${phaseId}',
)
`;

export const autologNotebook = () =>
  `#Add this command at the end of notebook to log all the assets in memory
autolog.notebook(note = "Your note to remember what you logged")`;

export const autologCell = () => `#Add this command at the end of the desired cell to log all the cell's assets
autolog.cell(note = "Your note to remember what you logged")`;
