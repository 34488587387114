import React from 'react';

import { ReactComponent as PhaseIcon } from '../../../assets/icons/specials/ic-phase.svg';
import { AssetLink } from '../../../ui';

interface FormatPhaseProps {
  name?: string | null;
}

export const FormatPhase = ({ name }: FormatPhaseProps) => <AssetLink name={name} icon={PhaseIcon} color="inherit" />;
