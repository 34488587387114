import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import { Badge } from '../badge';
import { Dropdown } from '../dropdown';
import { EmptySelect } from '../empty';
import { CheckboxGroup } from '../form';
import { LoadingOverlay } from '../loading-animation';
import { Menu, MenuDivider } from '../menu';
import { Typography } from '../typography';

import { FilterControls, FilterControlsProps } from './FilterControls';
import { FilterMultipleOption, FilterSingleOption } from './FilterOption';
import { FilterSearchInput } from './FilterSearchInput';
import { FilterTrigger } from './FilterTrigger';
import { FilterOptionProps } from './interfaces';

import styles from './Filter.module.scss';

type MultipleSelectProps =
  | { multiple: true; controls: Omit<FilterControlsProps, 'disabled' | 'onApply' | 'onClear'> }
  | { multiple?: false; controls?: never };

interface Props {
  label?: React.ReactNode;
  defaultValues?: string[];
  loading?: boolean;
  withFilter?: boolean;
  withBadge?: boolean;
  options: Omit<FilterOptionProps, 'selected' | 'onClick'>[];
  triggerClassName?: string;
  triggerContent: React.ReactNode;
  regular?: boolean;
  onDebounceSearch?: (value: string) => void;
  onSearch?: (value: string) => void;
  onSelect: (values: string[]) => void;
}

export const Filter = ({
  label,
  controls,
  defaultValues,
  loading,
  multiple,
  options,
  withFilter,
  withBadge,
  triggerClassName,
  triggerContent,
  regular,
  onDebounceSearch,
  onSearch,
  onSelect,
}: React.PropsWithChildren<Props & MultipleSelectProps>) => {
  const [visible, setVisible] = useState(false);
  const [appliedValues, setAppliedValues] = useState<string[]>(defaultValues || []);
  const [selectedValues, setSelectedValues] = useState<string[]>(defaultValues || []);
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(() => {
    if (!withFilter) return options;

    return options.filter((option) =>
      option.label?.toString().match(new RegExp(search.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&'), 'i')),
    );
  }, [withFilter, options, search]);

  useEffect(() => {
    setSelectedValues(defaultValues || []);
    setAppliedValues(defaultValues || []);
  }, [defaultValues]);

  const handleSearch = (value: string) => {
    setSearch(value);
    onSearch?.(value);
  };

  const handleSelection = (values: string[]) => {
    setAppliedValues(values);
    onSelect(values);
    setVisible(false);
  };

  const handleVisibleChange = (newState: boolean) => {
    setVisible(newState);
    // Clean up the state when closing filter
    if (!newState) {
      setSearch('');
      onSearch?.('');
      onDebounceSearch?.('');
      setSelectedValues(appliedValues);
    }
  };

  const hasSearch = onSearch || onDebounceSearch || withFilter;

  const overlay = (
    <div
      className={cn(styles.overlayContent, {
        [styles.withSearch]: !!hasSearch,
        [styles.withControls]: !!controls,
      })}
    >
      {hasSearch && (
        <>
          <FilterSearchInput value={search} onDebounceSearch={onDebounceSearch} onSearch={handleSearch} />
          <MenuDivider />
        </>
      )}
      <LoadingOverlay loading={loading}>
        {multiple ? (
          <CheckboxGroup
            className={cn(styles.optionsContainer, styles.multiple)}
            name="checkbox-group"
            checkedValues={selectedValues}
            onChange={setSelectedValues}
          >
            {filteredOptions.map((option) => (
              <FilterMultipleOption key={`option-${option.value}`} {...option} />
            ))}
            {hasSearch && !filteredOptions.length && <EmptySelect />}
          </CheckboxGroup>
        ) : (
          <Menu className={cn(styles.optionsContainer, styles.single)}>
            {filteredOptions.map((option) => (
              <FilterSingleOption
                key={`option-${option.value}`}
                {...option}
                selected={defaultValues && defaultValues[0] === option.value}
                onClick={() =>
                  handleSelection(defaultValues && defaultValues[0] === option.value ? [] : [option.value])
                }
              />
            ))}
            {hasSearch && !filteredOptions.length && <EmptySelect />}
          </Menu>
        )}
      </LoadingOverlay>
      {controls && (
        <FilterControls
          {...controls}
          disabled={loading}
          onApply={() => handleSelection(selectedValues)}
          onClear={() => {
            setSelectedValues([]);
            setAppliedValues([]);
            handleSelection([]);
            setSearch('');
            if (onSearch) onSearch('');
            if (onDebounceSearch) onDebounceSearch('');
          }}
        />
      )}
    </div>
  );

  return (
    <Dropdown overlay={overlay} trigger={['click']} visible={visible} onVisibleChange={handleVisibleChange}>
      <FilterTrigger regular={regular} label={label} className={triggerClassName}>
        <Typography ellipsis>{triggerContent}</Typography>
        {withBadge && multiple && appliedValues.length > 0 && (
          <Badge color="info" size="sm" className={styles.badge}>
            {appliedValues.length}
          </Badge>
        )}
      </FilterTrigger>
    </Dropdown>
  );
};
