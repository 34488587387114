import React from 'react';

import { generateArray } from '../../utils';
import { Skeleton } from '../loading-animation';

import { MenuItem } from './MenuItem';

export const MenuSkeleton = () => (
  <>
    {generateArray(3).map((uuid) => (
      <MenuItem>
        <Skeleton.Shape key={uuid} height="20px" width="100%" />
      </MenuItem>
    ))}
  </>
);
