import React, { useEffect, useState } from 'react';

import type { PropsWithChildren } from 'react';

import { OfflineError } from '../errors';
import { useWithFeatureFlags } from '../guards';

const reloadPage = () => window.location.reload();

export const NetworkObserver = ({ children }: PropsWithChildren<unknown>) => {
  const { allowed: active } = useWithFeatureFlags({ featureFlag: 'offline-error-page' });

  // need to throw the error within a set state content to make React error boundary work
  const [, setError] = useState();
  const [isOnline, setOnline] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (!active) return;

    const onLoad = () => {
      const value = navigator.onLine;
      setOnline(value);
      if (!value) {
        setError(() => {
          logger.error('🌐 Network status: Offline ❌');
          throw new OfflineError();
        });
      } else {
        logger.log('🌐 Network status: Online ✅');
      }
    };

    const onOnline = () => {
      logger.log('🌐 Network status: Online ✅');
      const value = navigator.onLine;
      setOnline(value);
      if (!isOnline && value) {
        reloadPage();
      }
    };

    const onOffline = () => {
      setOnline(false);
      if (isOnline) {
        setError(() => {
          logger.error('🌐 Network status: Offline ❌');
          throw new OfflineError();
        });
      }
    };

    window.addEventListener('load', onLoad);
    window.addEventListener('online', () => onOnline());
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('load', onLoad);
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, [active, isOnline]);

  return <>{children}</>;
};
