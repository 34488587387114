import cn from 'classnames';
import React, { ElementType, MouseEvent } from 'react';

import { Icon } from '../svg';
import { Typography, TypographyColors } from '../typography';

import styles from './MenuItem.module.scss';

interface Props extends VecticeHTMLProps<HTMLLIElement> {
  className?: string;
  clickable?: boolean;
  color?: TypographyColors;
  disabled?: boolean;
  iconSize?: number;
  iconClassName?: string;
  leftIcon?: ElementType;
  highlighted?: boolean;
  selected?: boolean;
}

export const MenuItem = <E extends ElementType>({
  children,
  className,
  clickable,
  color = 'secondary',
  component,
  disabled,
  iconSize = 18,
  iconClassName,
  leftIcon,
  highlighted,
  selected,
  onClick,
  ...props
}: Props & WithComponentProps<E>) => {
  const handleOnClick = (e: MouseEvent<any>) => {
    if (!disabled) {
      onClick?.(e);
    }
  };

  return (
    <Typography
      variant="callout"
      color={color}
      component={component || 'li'}
      role="menuitem"
      onClick={handleOnClick}
      disabled={disabled}
      className={cn(
        styles.menu_item,
        styles[color],
        {
          [styles.clickable]: onClick || clickable,
          [styles.disabled]: disabled,
          [styles.highlighted]: highlighted,
          [styles.selected]: selected,
        },
        className,
      )}
      ellipsis
      {...props}
    >
      {leftIcon && <Icon className={iconClassName} icon={leftIcon} size={iconSize} />}
      {children}
    </Typography>
  );
};
