import React from 'react';

import { generateArray } from '../../utils';
import { Skeleton } from '../loading-animation';

import styles from './TableSkeleton.module.scss';

const sizes = {
  lg: 56,
  md: 40,
  sm: 32,
};

interface TableSkeletonProps {
  rows?: number;
  size?: 'lg' | 'md' | 'sm';
}

export const TableSkeleton = ({ rows = 5, size = 'md' }: TableSkeletonProps) => (
  <div className={styles.table}>
    <Skeleton.Shape height={53} light />
    {generateArray(rows).map((uuid, index) => (
      <Skeleton.Shape key={uuid} height={sizes[size]} light={index % 2 === 0} />
    ))}
  </div>
);
