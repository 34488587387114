import {
  MentionedInThreadContent,
  ThreadCreatedContent,
  ThreadRepliedContent,
  ThreadResolvedContent,
} from '../../../entities';
import { UserNotificationType } from '../../../gql/graphql';
import { getFindingThreadLink, getPhaseThreadLink, getReportThreadLink } from '../../../routes';
import { VecticeResourceType } from '../../../utils';
import {
  AdminNotifWorkspaceRoleChangedNotification,
  FindingOwnerChangeNotification,
  FindingReviewerChangeNotification,
  FindingStatusChangeNotification,
  NotificationWithFragments,
  OwnUserTypeChangedNotification,
  UserAddedToWorkspace,
  UserAskedForUpgradeNotification,
  UserNotificationAccessRequestApproved,
  UserNotificationAccessRequestRejected,
  UserNotificationInviteAccepted,
  UserNotificationMention,
  UserNotificationMentionedInThread,
  UserNotificationNewWorkspaceAccessRequest,
  UserNotificationPhaseOwnerAssigned,
  UserNotificationProjectDeleted,
  UserNotificationProjectOwnerAssigned,
  UserNotificationProjectOwnershipTransferred,
  UserNotificationProjectStatusChanged,
  UserNotificationQuickStartProjectCreated,
  UserNotificationReview,
  UserNotificationThreadCreated,
  UserNotificationThreadReplied,
  UserNotificationThreadResolved,
  UserNotificationUserJoinedOrganization,
  UserNotificationWorkspaceDeleted,
  UserNotificationWorkspaceInvite,
  UserRemovedFromWorkspace,
} from '../types';

export const isUserJoinedOrganizationNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationUserJoinedOrganization =>
  notification.type === UserNotificationType.UserJoinedOrganization;

export const isMentionedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationMention => notification.type === UserNotificationType.UserMentioned;

export const isWorkspaceInviteNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationWorkspaceInvite => notification.type === UserNotificationType.UserAddedToWorkspace;

export const isInviteAcceptedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationInviteAccepted => notification.type === UserNotificationType.UserAcceptedInvite;

export const isNewWorkspaceAccessRequestNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationNewWorkspaceAccessRequest =>
  notification.type === UserNotificationType.NewWorkspaceAccessRequest;

export const isAccessRequestApprovedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationAccessRequestApproved =>
  notification.type === UserNotificationType.WorkspaceUserRequestApproved;

export const isAccessRequestRejectedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationAccessRequestRejected =>
  notification.type === UserNotificationType.WorkspaceUserRequestRejected;

export const isReviewRequestedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationReview => notification.type === UserNotificationType.ReviewRequested;

export const isReviewApprovedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationReview => notification.type === UserNotificationType.ReviewApproved;

export const isReviewRejectedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationReview => notification.type === UserNotificationType.ReviewRejected;

export const isPhaseOwnerChanged = (
  notification: NotificationWithFragments,
): notification is UserNotificationPhaseOwnerAssigned => notification.type === UserNotificationType.PhaseOwnerAssigned;

export const isQuickstartProjectCreated = (
  notification: NotificationWithFragments,
): notification is UserNotificationQuickStartProjectCreated =>
  notification.type === UserNotificationType.QuickstartProjectCreated;

export const isProjectOwnerChanged = (
  notification: NotificationWithFragments,
): notification is UserNotificationProjectOwnerAssigned =>
  notification.type === UserNotificationType.ProjectOwnerAssigned;

export const isProjectOwnershipTransferred = (
  notification: NotificationWithFragments,
): notification is UserNotificationProjectOwnershipTransferred =>
  notification.type === UserNotificationType.ProjectOwnershipTransferred;

export const isProjectDeleted = (
  notification: NotificationWithFragments,
): notification is UserNotificationProjectDeleted => notification.type === UserNotificationType.ProjectDeleted;

export const isProjectStatusChanged = (
  notification: NotificationWithFragments,
): notification is UserNotificationProjectStatusChanged =>
  notification.type === UserNotificationType.ProjectStatusChange;

export const isReviewCommentAddedNotification = (
  notification: NotificationWithFragments,
): notification is UserNotificationReview => notification.type === UserNotificationType.ReviewCommentAdded;

export const isWorkspaceDeleted = (
  notification: NotificationWithFragments,
): notification is UserNotificationWorkspaceDeleted => notification.type === UserNotificationType.WorkspaceDeleted;

export const isUserAddedToWorkspaceNotification = (
  notification: NotificationWithFragments,
): notification is UserAddedToWorkspace => notification.type === UserNotificationType.UserAddedToWorkspace;

export const isUserRemovedFromWorkspaceNotification = (
  notification: NotificationWithFragments,
): notification is UserRemovedFromWorkspace => notification.type === UserNotificationType.UserRemovedFromWorkspace;

export const isUserAddedToWorkspaceYouOwnNotification = (
  notification: NotificationWithFragments,
): notification is UserAddedToWorkspace => notification.type === UserNotificationType.AdminNotifUserAddedToWorkspace;

export const isThreadCreated = (
  notification: NotificationWithFragments,
): notification is UserNotificationThreadCreated => notification.type === UserNotificationType.ThreadCreated;

export const isThreadReplied = (
  notification: NotificationWithFragments,
): notification is UserNotificationThreadReplied => notification.type === UserNotificationType.ThreadReply;

export const isThreadResolved = (
  notification: NotificationWithFragments,
): notification is UserNotificationThreadResolved => notification.type === UserNotificationType.ThreadResolved;

export const isThreadDeleted = (
  notification: NotificationWithFragments,
): notification is UserNotificationThreadResolved => notification.type === UserNotificationType.ThreadDeleted;

export const isUserMentionedInThread = (
  notification: NotificationWithFragments,
): notification is UserNotificationMentionedInThread =>
  notification.type === UserNotificationType.UserMentionedInThread;

export const isUserAskedForUpgradeNotification = (
  notification: NotificationWithFragments,
): notification is UserAskedForUpgradeNotification => notification.type === UserNotificationType.AskUserTypeUpgrade;

export const isOwnUserTypeChangedNotification = (
  notification: NotificationWithFragments,
): notification is OwnUserTypeChangedNotification => notification.type === UserNotificationType.OwnUserTypeChanged;

export const isAdminNotifWorkspaceRoleChangedNotification = (
  notification: NotificationWithFragments,
): notification is AdminNotifWorkspaceRoleChangedNotification =>
  notification.type === UserNotificationType.AdminNotifWorkspaceRoleChanged;

export const getThreadNotificationInformation = (
  content: ThreadRepliedContent | MentionedInThreadContent | ThreadCreatedContent | ThreadResolvedContent,
) => {
  const { phase, report, finding, threadEditorId } = content;

  if (phase) {
    return {
      type: VecticeResourceType.PHASE,
      link: getPhaseThreadLink(phase.id, threadEditorId),
      title: phase.name,
    };
  }
  if (report)
    return {
      type: VecticeResourceType.CDT_REPORT,
      link: getReportThreadLink(report.id, threadEditorId),
      title: report.name,
    };

  if (finding)
    return {
      type: VecticeResourceType.FINDING,
      link: getFindingThreadLink(finding.id, threadEditorId),
      title: finding.name,
    };

  throw new Error('Unmanaged Comment Notification');
};

export const isFindingOwnerChange = (
  notification: NotificationWithFragments,
): notification is FindingOwnerChangeNotification => notification.type === UserNotificationType.FindingOwnerChange;

export const isFindingReviewerChange = (
  notification: NotificationWithFragments,
): notification is FindingReviewerChangeNotification =>
  notification.type === UserNotificationType.FindingReviewerChange;

export const isFindingStatusChange = (
  notification: NotificationWithFragments,
): notification is FindingStatusChangeNotification => notification.type === UserNotificationType.FindingStatusChange;
