import { useMutation } from '@apollo/client';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { graphql } from '../../gql';
import { Model } from '../../gql/graphql';
import { flushFromGraphQLCache } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

export const DELETE_MODEL = graphql(`
  mutation deleteModel($modelId: VecticeId!) {
    deleteModel(modelId: $modelId)
  }
`);

interface DeleteModelModalProps {
  model: Pick<Model, 'vecticeId' | 'name'>;
  projectId?: string | null;
  onClose: () => void;
}

export const DeleteModelModal = ({ model, projectId, onClose }: DeleteModelModalProps) => {
  const navigate = useNavigate();

  const [deleteModel, { loading: deleting }] = useMutation(DELETE_MODEL, {
    variables: {
      modelId: model?.vecticeId,
    },
    update: (cache, { data }) => {
      if (data) {
        onClose();
        navigate(buildLink(VecticeRoutes.PROJECT_MODELS, { projectId }));
        flushFromGraphQLCache(cache, { vecticeId: data.deleteModel, __typename: 'Model' });
      }
    },
    onCompleted: () => {
      if (model) {
        message.success(
          $t({
            id: 'component.action.deleteModel.successMessage',
            defaultMessage: '{name} model deleted',
            values: { name: model?.name },
          }),
        );
      }
    },
    onError: (error) => {
      message.error(error.message);
    },
  });

  return (
    <DeletionPrompt
      processing={deleting}
      resourceName={model.name}
      title={$t({ id: 'model.modal.delete.title', defaultMessage: 'Delete Model' })}
      onClose={onClose}
      onConfirm={deleteModel}
    />
  );
};
