import { graphql } from '../../../gql';

export const GET_UNIQUE_INVENTORY_REFERENCES = graphql(`
  query getUniqueInventoryReferences(
    $workspaceIdList: [VecticeId!]!
    $modelIdList: [VecticeId!]
    $search: String!
    $projectIdList: [VecticeId!]
  ) {
    getUniqueInventoryReferences(
      workspaceIdList: $workspaceIdList
      modelIdList: $modelIdList
      projectIdList: $projectIdList
      filters: { search: $search }
    ) {
      items {
        inventoryReference
      }
    }
  }
`);
