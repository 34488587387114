import React from 'react';

import { EmptyData } from '../../ui';

export const NoProperties = () => (
  <EmptyData
    message={$t({ id: 'NoResults.properties.message', defaultMessage: 'No properties yet!' })}
    hint={$t({
      id: 'NoResults.properties.hint',
      defaultMessage: "To use this filter, add properties to your assets' version pages",
    })}
  />
);
