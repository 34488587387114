import { useQuery } from '@apollo/client';
import { max, StackedBar, sum } from '@vectice/data-viz';
import React, { ReactElement, useMemo } from 'react';

import { getReviewStatusLabel } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { ReviewStatus, User } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { Column, EmptyData, FlexContainer, Table, Tooltip, Typography } from '../../../ui';
import { UserAvatar, UserTooltip } from '../../asset-display';
import { ReviewBadge } from '../../badges';
import { FormatUserName } from '../../formatters';
import { ColorSwatch } from '../colors';
import { DashboardCard, Legend } from '../internals';

export const GET_COMPLETED_REVIEWS = graphql(`
  query getCompletedReviewsCountPerUser($workspaceIdList: [VecticeId!]!) {
    getCompletedReviewsCountPerUser(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        user {
          ...userFields
        }
        total
        items {
          status
          count
        }
      }
    }
  }
`);

interface Datum {
  status: ReviewStatus.Approved | ReviewStatus.Rejected;
  count: number;
}

const getValue = (datum: Datum) => datum.count;
const getColor = (datum: Datum) =>
  datum.status === ReviewStatus.Approved ? ColorSwatch.summerGreen : ColorSwatch.burntSienna;
const margins = { top: 8, left: 0, right: 48, bottom: 8 };

interface CompletedReviewsTooltipProps {
  user: UserIdentity;
  data: Datum[];
  children: ReactElement;
}

const CompletedReviewsTooltip = ({ user, data, children }: CompletedReviewsTooltipProps) => {
  return (
    <Tooltip
      overlay={
        <FlexContainer component={Typography} direction="column" gap={8} variant="callout" color="white">
          <FlexContainer align="center" gap={4}>
            <UserAvatar user={user} size="xs" />
            <Typography weight="semi-bold" ellipsis>
              <FormatUserName user={user} />
            </Typography>
          </FlexContainer>
          <div>
            <FlexContainer align="center" gap={4}>
              <Typography>
                {$t({
                  id: 'dashboard.completedReviews.tooltip.approved.count',
                  defaultMessage: '{count, plural, one {<bold>#</bold> Review} other {<bold>#</bold> Reviews}}',
                  values: { count: getValue(data[0]) },
                })}
              </Typography>
              <ReviewBadge status={ReviewStatus.Approved} contrast />
            </FlexContainer>
            <FlexContainer align="center" gap={4}>
              <Typography>
                {$t({
                  id: 'dashboard.completedReviews.tooltip.rejected.count',
                  defaultMessage: '{count, plural, one {<bold>#</bold> Review} other {<bold>#</bold> Reviews}}',
                  values: { count: getValue(data[1]) },
                })}
              </Typography>
              <ReviewBadge status={ReviewStatus.Rejected} contrast />
            </FlexContainer>
          </div>
        </FlexContainer>
      }
      placement="top"
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const ReviewLegend = ({ status }: { status: ReviewStatus }) => (
  <Legend color={status === ReviewStatus.Approved ? ColorSwatch.summerGreen : ColorSwatch.burntSienna}>
    <Typography variant="callout">{getReviewStatusLabel(status)}</Typography>
  </Legend>
);

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.completedReviews.empty',
      defaultMessage: 'No Reviews Completed',
    })}
  />
);

interface CompletedReviewsProps {
  workspaceIdList: string[];
}

export const CompletedReviews = ({ workspaceIdList }: CompletedReviewsProps) => {
  const { data, loading } = useQuery(GET_COMPLETED_REVIEWS, {
    variables: {
      workspaceIdList,
    },
  });

  const { rows, maxValue } = useMemo(() => {
    const rows =
      data?.getCompletedReviewsCountPerUser.items?.map(({ user, items, total }) => ({
        user,
        total,
        data: items
          .filter((item) => [ReviewStatus.Approved, ReviewStatus.Rejected].includes(item.status))
          .sort((a) => (a.status === ReviewStatus.Rejected ? 0 : 1)),
      })) ?? [];
    const maxValue = max(rows, (row) => row.total) || 1;

    return {
      rows,
      maxValue,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.completedReviews.title', defaultMessage: 'Completed Reviews by Approver' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.relativeTime', defaultMessage: 'In the last 30 days' })}
      hint={$t({
        id: 'dashboard.completedReviews.hint',
        defaultMessage: 'The total count of reviews completed by approver.',
      })}
      legend={
        <>
          <ReviewLegend status={ReviewStatus.Approved} />
          <ReviewLegend status={ReviewStatus.Rejected} />
        </>
      }
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => JSON.stringify(row.user)}
        emptyText={<NoData />}
      >
        <Column
          key="user"
          title={$t({ id: 'dashboard.completedReviews.column.approver', defaultMessage: 'Approver' })}
          ellipsis
        >
          {(user: Pick<User, 'id'> & UserIdentity) => (
            <UserTooltip id={user.id}>
              <Typography variant="callout" color="secondary" ellipsis>
                <FormatUserName user={user} />
              </Typography>
            </UserTooltip>
          )}
        </Column>
        <Column
          key="data"
          title={$t({ id: 'dashboard.completedReviews.column.reviews', defaultMessage: 'Nb of Reviews' })}
          width="65%"
        >
          {(data: Datum[], record) => (
            <CompletedReviewsTooltip user={record.user} data={data}>
              <StackedBar
                data={data}
                getValue={getValue}
                getColor={getColor}
                maxValue={maxValue}
                rightContent={
                  <Typography variant="callout" color="primary">
                    {sum(data, getValue)}
                  </Typography>
                }
                margins={margins}
              />
            </CompletedReviewsTooltip>
          )}
        </Column>
      </Table>
    </DashboardCard>
  );
};
