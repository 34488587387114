import React from 'react';

import { getAccessibilityLevelDisplayName, getUserRoleDisplayName } from '../../entities/utils';
import { AccessibilityLevel, User, UserRole } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { ModalPrompt } from '../../ui';
import { FormatUserName } from '../formatters';

interface ChangeUserRoleModalProps {
  user: Pick<User, 'name' | 'role'> & UserIdentity;
  role: UserRole;
  onConfirm: (role: UserRole) => void;
  onClose: () => void;
}

const RoleRanked = [UserRole.Member, UserRole.OrgAdmin, UserRole.Superadmin];

export const ChangeUserRoleModal = ({ user, role, onConfirm, onClose }: ChangeUserRoleModalProps) => {
  const isPromotion = RoleRanked.indexOf(user.role) < RoleRanked.indexOf(role);

  const title = isPromotion
    ? $t({
        id: 'modal.changeUserRole.promote',
        defaultMessage: 'Promote {name} to {role}?',
        values: { name: user.name, role: getUserRoleDisplayName(role) },
      })
    : $t({
        id: 'modal.changeUserRole.demote',
        defaultMessage: 'Demote {name} to {role}?',
        values: { name: user.name, role: getUserRoleDisplayName(role) },
      });

  let message;
  if (role === UserRole.Observer) {
    message = $t({
      id: 'modal.changeUserRole.demoteToObserver',
      defaultMessage:
        "You are about to demote <bold>{user}</bold> to become a(n) {role} of your organization. <bold>{user}</bold>'s existing role in each workspace will also be set to {observerLevel}.",
      values: {
        user: <FormatUserName user={user} />,
        role: getUserRoleDisplayName(role),
        observerLevel: getAccessibilityLevelDisplayName(AccessibilityLevel.ReadOnly),
      },
    });
  } else if (isPromotion && user.role === UserRole.Observer) {
    message = $t({
      id: 'modal.changeUserRole.promoteToMember',
      defaultMessage:
        "You are about to promote <bold>{user}</bold> to become a(n) {role} of your organization. <bold>{user}</bold>'s existing role in each workspace will be set to {memberLevel}.",
      values: {
        user: <FormatUserName user={user} />,
        role: getUserRoleDisplayName(role),
        memberLevel: getAccessibilityLevelDisplayName(AccessibilityLevel.Editor),
      },
    });
  } else {
    message = isPromotion
      ? $t({
          id: 'modal.changeUserRole.aboutPromote',
          defaultMessage:
            "You are about to promote <bold>{user}</bold> to become a(n) {role} of your organization. <bold>{user}</bold>'s existing role in each workspace they belong to will remain the same.",
          values: {
            user: <FormatUserName user={user} />,
            role: getUserRoleDisplayName(role),
          },
        })
      : $t({
          id: 'modal.changeUserRole.aboutDemote',
          defaultMessage:
            "You are about to demote <bold>{user}</bold> to become a(n) {role} of your organization. <bold>{user}</bold>'s existing role in each workspace they belong to will remain the same.",
          values: {
            user: <FormatUserName user={user} />,
            role: getUserRoleDisplayName(role),
          },
        });
  }

  return (
    <ModalPrompt
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      confirmLabel={$t({ id: 'modal.confirm', defaultMessage: 'Confirm' })}
      title={title}
      onConfirm={() => onConfirm(role)}
      onClose={onClose}
    >
      {message}
    </ModalPrompt>
  );
};
