import React, { useRef } from 'react';

import { getProjectStatusOptions } from '../../../entities/utils';
import { ProjectStatusBadge } from '../../badges';
import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CheckBoxFilterComponent } from '../ui';

export const pluginField = 'status';

class ProjectStatusFilterNode<T> extends FilterNode<T> {
  private options = getProjectStatusOptions().map((option) => ({
    label: option.label,
    value: option.value,
    labelComponent: <ProjectStatusBadge status={option.value} />,
  }));

  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CheckBoxFilterComponent
        key={pluginField}
        label="project-status-checkbox-group"
        options={this.options}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, value)}
      />
    );
  }
}

export const ProjectStatusFilter = () => {
  const { current: node } = useRef(new ProjectStatusFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.projectStatus.label', defaultMessage: 'Project status' }),
    node,
  });

  return null;
};
