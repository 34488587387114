import React, { PropsWithChildren } from 'react';

import { Logo } from '../brand';
import { Content, Main, Page, Section } from '../containers';
import { Navbar } from '../navbar';
import { Graphic } from '../svg';

import styles from './Splashes.module.scss';

interface CrashProps {
  graphic: string;
}

export const Crash = ({ graphic, children }: PropsWithChildren<CrashProps>) => (
  <div className={styles.page}>
    <Navbar logo={<Logo aria-label="Vectice" />} />
    <Page>
      <Content>
        <Main className={styles.horizontal_container}>
          <Section>{children}</Section>
          <div className={styles.graphic_container} aria-hidden>
            <Graphic src={graphic} className={styles.graphic} />
          </div>
        </Main>
      </Content>
    </Page>
  </div>
);
