import React from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { Menu, MenuItem } from '../../ui';

export type SelectedDatasetModal = 'rename' | 'delete' | null;

interface DatasetMenuProps {
  onOpenModal: (modal: SelectedDatasetModal) => void;
}

export const DatasetMenu = ({ onOpenModal }: DatasetMenuProps) => (
  <Menu>
    <MenuItem leftIcon={DeleteIcon} color="danger" onClick={() => onOpenModal('delete')}>
      {$t({ id: 'dataset.menu.delete', defaultMessage: 'Delete dataset' })}
    </MenuItem>
  </Menu>
);
