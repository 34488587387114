import React from 'react';

import { Grid, GridCell } from '../../ui';
import { generateArray } from '../../utils';

import { WorkspaceCardSkeleton } from './WorkspaceCardSkeleton';

export const WorkspaceGridSkeleton = () => (
  <Grid columns={3} gap={32}>
    {generateArray(3).map((uuid) => (
      <GridCell key={uuid}>
        <WorkspaceCardSkeleton />
      </GridCell>
    ))}
  </Grid>
);
