import React from 'react';

import { getDatasetTypeDisplayName } from '../../entities/utils';
import { DatasetType as DatasetTypeEnum } from '../../gql/graphql';
import { Icon, Typography } from '../../ui';
import { getDatasetTypeIcon } from '../../utils';

import styles from './Dataset.module.scss';

export const DatasetType = ({ type }: { type?: DatasetTypeEnum | string | null }) => {
  const icon = getDatasetTypeIcon(type);

  return (
    <Typography variant="callout" color="tertiary">
      {icon && <Icon className={styles.icon} icon={icon} size={16} />}
      <Typography ellipsis>{getDatasetTypeDisplayName(type)}</Typography>
    </Typography>
  );
};
