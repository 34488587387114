import React from 'react';
import { Link } from 'react-router-dom';

import { buildLink, VecticeRoutes } from '../../../routes';
import { FormatUserName } from '../../formatters';
import { UserNotificationWorkspaceInvite } from '../types';

import { Notification } from './Notification';

interface WorkspaceInviteNotificationProps {
  notification: UserNotificationWorkspaceInvite;
  onClose: () => void;
}

export const WorkspaceInviteNotification = ({
  notification: { creator, createdDate, workspace },
  onClose,
}: WorkspaceInviteNotificationProps) => {
  if (!workspace) return null;

  return (
    <Notification
      component={Link}
      to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId: workspace.vecticeId })}
      onClose={onClose}
      creator={creator}
      createdDate={createdDate}
    >
      {$t({
        id: 'notification.workspace.added',
        defaultMessage: '<bold>{creator}</bold> has added <bold>you</bold> to workspace <bold>{workspace}</bold>',
        values: {
          creator: <FormatUserName user={creator} />,
          workspace: workspace ? workspace.name : '',
        },
      })}
    </Notification>
  );
};
