import { useQuery } from '@apollo/client';
import React from 'react';
import { Link } from 'react-router-dom';

import { graphql } from '../../../gql';
import { Project } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { buildLink, VecticeRoutes } from '../../../routes';
import { Column, EmptyData, Table, Typography } from '../../../ui';
import { ProjectTooltip, UserAvatar } from '../../asset-display';
import { FormatDate } from '../../formatters';
import { DashboardCard } from '../internals';

export const GET_COMPLETED_PROJECTS = graphql(`
  query getWorkspaceCompletedProjects($workspaceIdList: [VecticeId!]!) {
    getMostRecentlyCompletedProjects(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        entity {
          vecticeId
          name
          createdDate
          owner {
            ...userFields
          }
        }
        completionDate
      }
    }
  }
`);

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.completedProjects.empty',
      defaultMessage: 'No Completed Projects',
    })}
  />
);

interface CompletedProjectsProps {
  workspaceIdList: string[];
}

export const CompletedProjects = ({ workspaceIdList }: CompletedProjectsProps) => {
  const { data, loading } = useQuery(GET_COMPLETED_PROJECTS, {
    variables: {
      workspaceIdList,
    },
  });

  const rows = data?.getMostRecentlyCompletedProjects.items ?? [];

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.completedProjects.title', defaultMessage: 'Latest Completed Projects' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.completedProjects.hint',
        defaultMessage: 'The last 10 completed projects ordered by date of completion.',
      })}
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => row.entity.vecticeId}
        emptyText={<NoData />}
      >
        <Column
          key="entity"
          title={$t({ id: 'dashboard.completedProjects.column.project', defaultMessage: 'Project' })}
          ellipsis
        >
          {(project: Pick<Project, 'vecticeId' | 'name'>) => (
            <ProjectTooltip id={project.vecticeId}>
              <Typography
                component={Link}
                to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })}
                color="primary"
                link
              >
                {project.name}
              </Typography>
            </ProjectTooltip>
          )}
        </Column>
        <Column
          key="entity"
          title={$t({ id: 'dashboard.completedProjects.column.creationDate', defaultMessage: 'Creation Date' })}
          width="120px"
          ellipsis
        >
          {(project: Pick<Project, 'createdDate'>) => <FormatDate date={project.createdDate} />}
        </Column>
        <Column
          key="completionDate"
          title={$t({ id: 'dashboard.completedProjects.column.completionDate', defaultMessage: 'Completion Date' })}
          width="140px"
          ellipsis
        >
          {(completionDate: Date) => <FormatDate date={completionDate} />}
        </Column>
        <Column
          key="entity.owner"
          title={$t({ id: 'dashboard.completedProjects.column.projectOwner', defaultMessage: 'Owner' })}
          align="center"
          width="55px"
        >
          {(owner: UserIdentity) => !!owner && <UserAvatar user={owner} size="xs" />}
        </Column>
      </Table>
    </DashboardCard>
  );
};
