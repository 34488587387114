import { useApolloClient, useLazyQuery } from '@apollo/client';
import Keycloak from 'keycloak-js';
import { useNavigate } from 'react-router-dom';

import config from '../config';
import { RealmInformation } from '../gql/graphql';
import { AUTHENTICATE_WITH_PASSWORD } from '../graphql/queries';
import { buildLink, VecticeRoutes } from '../routes';
import { message } from '../ui';
import { clearLocalStorageItem, saveLocalStorageItem, VecticeStoredItem } from '../utils';

export const useLogin = () => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const [authenticateWithPassword] = useLazyQuery(AUTHENTICATE_WITH_PASSWORD, {
    fetchPolicy: 'no-cache',
    onError: () => {
      message.error('Error while trying to login');
    },
  });

  const loginWithPassword = async (email: string, password: string) => {
    await authenticateWithPassword({
      variables: { email, password },
    });
    navigate(buildLink(VecticeRoutes.HOME));
  };

  const login = async (configuration: RealmInformation, email: string, referrer?: string) => {
    const redirectUrl = `${window.location.origin}${buildLink(VecticeRoutes.AUTH)}`;

    await apolloClient.clearStore();
    saveLocalStorageItem(VecticeStoredItem.KC_REALM_CONF, configuration);
    if (referrer) {
      saveLocalStorageItem(VecticeStoredItem.KC_REFERRER, referrer);
    } else {
      clearLocalStorageItem(VecticeStoredItem.KC_REFERRER);
    }

    const keycloakInstance = Keycloak({
      ...configuration,
      url: config.AUTH_URL,
    });

    await keycloakInstance.init({
      checkLoginIframe: false,
      useNonce: false,
      enableLogging: ['development'].includes(config.ENVIRONMENT),
    });

    const redirectUri = keycloakInstance.createLoginUrl({
      redirectUri: redirectUrl.toString(),
      loginHint: email,
      prompt: 'login',
    });

    await keycloakInstance.logout({ redirectUri });
  };

  const trialGoogleLogin = async () => {
    const realmConf = {
      realm: 'trial',
      clientId: 'trial-connect-service',
    };
    const redirectUrl = `${window.location.origin}${buildLink(VecticeRoutes.AUTH)}`;

    await apolloClient.clearStore();
    saveLocalStorageItem(VecticeStoredItem.KC_REALM_CONF, realmConf);
    clearLocalStorageItem(VecticeStoredItem.KC_REFERRER);

    const keycloakInstance = Keycloak({
      ...realmConf,
      url: config.AUTH_URL,
    });

    await keycloakInstance.init({
      checkLoginIframe: false,
      useNonce: false,
      enableLogging: ['development'].includes(config.ENVIRONMENT),
    });

    const redirectUri = keycloakInstance.createLoginUrl({
      redirectUri: redirectUrl.toString(),
      prompt: 'login',
      idpHint: 'google',
    });

    await keycloakInstance.logout({ redirectUri });
  };

  return {
    trialGoogleLogin,
    login,
    loginWithPassword,
  };
};
