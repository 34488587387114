import { graphql } from '../../gql';

export const ADD_USER_TO_WORKSPACE = graphql(`
  mutation addUsersToWorkspace($data: WorkspaceUserInput!) {
    addUsersToWorkspace(data: $data) {
      userId
    }
  }
`);

export const ADD_MYSELF_TO_WORKSPACE = graphql(`
  mutation addMyselfToWorkspace($workspaceId: VecticeId!) {
    addMyselfToWorkspace(workspaceId: $workspaceId) {
      userId
    }
  }
`);
