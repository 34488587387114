import { useQuery } from '@apollo/client';
import { max, StackedBar, sum } from '@vectice/data-viz';
import React, { ReactElement, useMemo } from 'react';

import { ReactComponent as FlatDatasetVersionIcon } from '../../../assets/icons/specials/flat/ic-dataset-version.svg';
import { ReactComponent as FlatModelVersionIcon } from '../../../assets/icons/specials/flat/ic-model-version.svg';
import { graphql } from '../../../gql';
import { User } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { Column, EmptyData, FlexContainer, Icon, Table, Tooltip, Typography } from '../../../ui';
import { VecticeResourceType } from '../../../utils';
import { UserAvatar, UserTooltip } from '../../asset-display';
import { FormatUserName } from '../../formatters';
import { ColorSwatch } from '../colors';
import { DashboardCard, Legend } from '../internals';

export const GET_TOP_ASSETS_CREATORS = graphql(`
  query getTopAssetsCreators($workspaceIdList: [VecticeId!]!) {
    getTopAssetsCreators(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }, numberOfDays: 30) {
      items {
        user {
          ...userFields
        }
        datasetVersionCount
        modelVersionCount
      }
    }
  }
`);

interface Datum {
  resourceType: VecticeResourceType;
  value: number;
}

const getValue = (datum: Datum) => datum.value;
const getColor = (datum: Datum) =>
  datum.resourceType === VecticeResourceType.DATASET_VERSION ? ColorSwatch.portage : ColorSwatch.tacha;
const margins = { top: 8, left: 0, right: 48, bottom: 8 };

interface AssetsCreatorTooltipProps {
  user: UserIdentity;
  data: Datum[];
  children: ReactElement;
}

const AssetsCreatorTooltip = ({ user, data, children }: AssetsCreatorTooltipProps) => {
  return (
    <Tooltip
      overlay={
        <FlexContainer component={Typography} direction="column" gap={8} variant="callout" color="white">
          <FlexContainer align="center" gap={4}>
            <UserAvatar user={user} size="xs" />
            <Typography weight="semi-bold" ellipsis>
              <FormatUserName user={user} />
            </Typography>
          </FlexContainer>
          <div>
            <FlexContainer align="center" gap={4}>
              <Icon icon={FlatDatasetVersionIcon} size={16} />
              <Typography>
                {$t({
                  id: 'dashboard.topAssetsCreators.tooltip.datasetVersion',
                  defaultMessage:
                    '{count, plural, one {<bold>#</bold> Dataset version created} other {<bold>#</bold> Dataset versions created}}',
                  values: { count: getValue(data[0]) },
                })}
              </Typography>
            </FlexContainer>
            <FlexContainer align="center" gap={4}>
              <Icon icon={FlatModelVersionIcon} size={16} />
              <Typography>
                {$t({
                  id: 'dashboard.topAssetsCreators.tooltip.modelVersion',
                  defaultMessage:
                    '{count, plural, one {<bold>#</bold> Model version created} other {<bold>#</bold> Model versions created}}',
                  values: { count: getValue(data[1]) },
                })}
              </Typography>
            </FlexContainer>
          </div>
        </FlexContainer>
      }
      placement="top"
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.topAssetsCreators.empty',
      defaultMessage: 'No Assets Created',
    })}
  />
);

interface TopAssetsCreatorsProps {
  workspaceIdList: string[];
}

export const TopAssetsCreators = ({ workspaceIdList }: TopAssetsCreatorsProps) => {
  const { data, loading } = useQuery(GET_TOP_ASSETS_CREATORS, {
    variables: {
      workspaceIdList,
    },
  });

  const { rows, maxValue } = useMemo(() => {
    const rows =
      data?.getTopAssetsCreators.items?.map(({ user, modelVersionCount, datasetVersionCount }) => ({
        user,
        data: [
          { value: datasetVersionCount, resourceType: VecticeResourceType.DATASET_VERSION },
          { value: modelVersionCount, resourceType: VecticeResourceType.MODEL_VERSION },
        ],
      })) ?? [];
    const maxValue = max(rows, (row) => sum(row.data, getValue)) || 1;

    return {
      rows,
      maxValue,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.topAssetsCreators.title', defaultMessage: 'Top Creators of Assets' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.relativeTime', defaultMessage: 'In the last 30 days' })}
      hint={$t({
        id: 'dashboard.topAssetsCreators.hint',
        defaultMessage:
          'The total count of models and datasets versions created in this workspace by the top creators.',
      })}
      legend={
        <>
          <Legend color={ColorSwatch.portage}>
            <Typography variant="callout">
              {$t({ id: 'dashboard.topAssetsCreators.legend.datasetVersion', defaultMessage: 'Dataset versions' })}
            </Typography>
          </Legend>
          <Legend color={ColorSwatch.tacha}>
            <Typography variant="callout">
              {$t({ id: 'dashboard.topAssetsCreators.legend.modelVersion', defaultMessage: 'Model versions' })}
            </Typography>
          </Legend>
        </>
      }
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => JSON.stringify(row.user)}
        emptyText={<NoData />}
      >
        <Column
          key="user"
          title={$t({ id: 'dashboard.topAssetsCreators.column.creator', defaultMessage: 'Creator' })}
          ellipsis
        >
          {(user: Pick<User, 'id'> & UserIdentity) => (
            <UserTooltip id={user.id}>
              <Typography variant="callout" color="secondary" ellipsis>
                <FormatUserName user={user} />
              </Typography>
            </UserTooltip>
          )}
        </Column>
        <Column
          key="data"
          title={$t({ id: 'dashboard.topAssetsCreators.column.assetsCreated', defaultMessage: '# Assets Created' })}
          width="65%"
        >
          {(data: Datum[], record) => (
            <AssetsCreatorTooltip user={record.user} data={data}>
              <StackedBar
                data={data}
                getValue={getValue}
                getColor={getColor}
                maxValue={maxValue}
                rightContent={
                  <Typography variant="callout" color="primary">
                    {sum(data, getValue)}
                  </Typography>
                }
                margins={margins}
              />
            </AssetsCreatorTooltip>
          )}
        </Column>
      </Table>
    </DashboardCard>
  );
};
