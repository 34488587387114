import React, { useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CreatedDateFilterComponent } from '../ui';

export const pluginField = 'createdDate';

class WorkspaceJoinedDateFilterNode<T> extends FilterNode<T> {
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CreatedDateFilterComponent
        key={pluginField}
        filter={filter}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, { ...value, field: pluginField })}
        options={[
          {
            label: $t({ id: 'filters.workspaceJoinedDateFilter.joinedOn.label', defaultMessage: 'Joined on' }),
            value: 'createdOn',
          },
          {
            label: $t({ id: 'filters.createdDateFilter.dateRange.label', defaultMessage: 'Date range' }),
            value: 'dateRange',
          },
        ]}
      />
    );
  }
}

export const WorkspaceJoinedDateFilter = () => {
  const { current: node } = useRef(new WorkspaceJoinedDateFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.workspaceJoinedDate.label', defaultMessage: 'Join date (workspace)' }),
    node,
  });

  return null;
};
