import cn from 'classnames';
import RcPagination, { PaginationProps } from 'rc-pagination';
import React, { useCallback, useRef } from 'react';

import { Button } from '../button';

import styles from './Pagination.module.scss';

const itemRender =
  (current?: number) =>
  (page: number, type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next'): React.ReactNode => {
    if (type === 'page') {
      if (page === current) {
        return (
          <Button variant="white" size="sm">
            {page}
          </Button>
        );
      }
      return (
        <Button variant="phantom" size="sm">
          {page}
        </Button>
      );
    }
    if (type === 'prev') {
      return null;
    }
    if (type === 'next') {
      return (
        <Button variant="phantom" size="sm">
          Next
        </Button>
      );
    }
    if (type === 'jump-prev' || type === 'jump-next') {
      return (
        <Button variant="phantom" size="sm">
          ...
        </Button>
      );
    }
    return null;
  };

export const Pagination = ({
  current,
  pageSize = 0,
  total = 0,
  onChange,
  className,
  ...props
}: Omit<PaginationProps, 'current'> & { current?: number | null }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (page: number, pageSize: number) => {
      if (ref.current) {
        ref.current.parentElement?.scrollIntoView(true);
      }
      onChange?.(page, pageSize);
    },
    [onChange, ref],
  );

  if (!total || total < pageSize) {
    return null;
  }

  return (
    <div className={cn(styles.pagination, className)} ref={ref}>
      <RcPagination
        {...props}
        showTitle={false}
        current={current ?? undefined}
        pageSize={pageSize}
        total={total}
        onChange={handleChange}
        hideOnSinglePage
        itemRender={itemRender(current ?? undefined)}
        selectPrefixCls="vectice-select"
        prefixCls="vectice-pagination"
      />
    </div>
  );
};
