import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppRoot } from './app';
import { Logger } from './services';

import './index.scss';

Logger.init();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppRoot />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
