import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';

import type { AssetWidgetProps } from '../../../ui';

import { AssetWidget } from '../../../ui';
import { EntityMetadataPreview, LightboxEntityMetaPreview } from '../../previewEntityMetadata';

import { ENTITY_METADATA } from './entityMetadata.query';
import { EntityMetadataRow } from './EntityMetadataRow';

interface EntityMetadataWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary' | 'actionsMenu'> {
  entityMetadataId: number;
}

let globalId = 0;
export const EntityMetadataWidget = ({
  children,
  expandable,
  open,
  entityMetadataId,
  ...props
}: EntityMetadataWidgetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`entity-metadata-widget-${globalId++}`);
  const [showPreview, setShowPreview] = useState(false);

  const { data, loading } = useQuery(ENTITY_METADATA, {
    variables: { id: entityMetadataId },
  });

  const entityMetadata = data?.entityMetadata;

  const summary = (
    <EntityMetadataRow id={ariaId} name={entityMetadata?.name} interaction={!!entityMetadata && 'file'} />
  );

  return (
    <>
      <AssetWidget
        aria-labelledby={ariaId}
        summary={summary}
        open={open}
        loading={loading}
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          setShowPreview(true);
        }}
        expandable={expandable}
        {...props}
      >
        {open && <EntityMetadataPreview entityMetadataId={entityMetadataId} />}
      </AssetWidget>
      {showPreview && entityMetadata && (
        <LightboxEntityMetaPreview entityMetadata={entityMetadata} onClose={() => setShowPreview(false)} />
      )}
    </>
  );
};
