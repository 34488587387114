import cn from 'classnames';
import React from 'react';

import styles from './Table.module.scss';

export const TableWrapper = ({
  className,
  scrollable,
  ...props
}: VecticeHTMLProps<HTMLDivElement> & { scrollable?: boolean }) => (
  <div className={cn(styles.table, className, { [styles.scrollable]: scrollable })} {...props} />
);
