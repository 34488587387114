import { useQuery } from '@apollo/client';
import { max, StackedBar } from '@vectice/data-viz';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getDocumentationPageStatusColor, getDocumentationPageStatusDisplayName } from '../../../entities/utils';
import { graphql } from '../../../gql';
import { DocumentationPageStatus, Phase, Project } from '../../../gql/graphql';
import { UserIdentity } from '../../../graphql/fragments';
import { buildLink, VecticeRoutes } from '../../../routes';
import { Badge, BadgeColors, Column, EmptyData, Table, Typography } from '../../../ui';
import { getDocumentationPageStatusIcon } from '../../../utils';
import { PhaseTooltip, ProjectTooltip, UserAvatar } from '../../asset-display';
import { ColorSwatch } from '../colors';
import { DashboardCard } from '../internals';

export const GET_ONGOING_PHASES = graphql(`
  query getWorkspaceOngoingPhases($workspaceIdList: [VecticeId!]!) {
    getWorkspaceOngoingPhases(workspaceIdList: $workspaceIdList, page: { index: 1, size: 10 }) {
      items {
        entity {
          vecticeId
          name
          status
          parent {
            vecticeId
            name
          }
          owner {
            ...userFields
          }
        }
        days
      }
    }
  }
`);

const getValue = (datum: number) => datum;
const getColor = () => ColorSwatch.cornflowerBlue;
const margins = { top: 8, left: 0, right: 48, bottom: 8 };

const NoData = () => (
  <EmptyData
    message={$t({
      id: 'dashboard.ongoingPhases.empty',
      defaultMessage: 'No Ongoing Phases',
    })}
  />
);

interface OngoingPhasesProps {
  workspaceIdList: string[];
}

export const OngoingPhases = ({ workspaceIdList }: OngoingPhasesProps) => {
  const { data, loading } = useQuery(GET_ONGOING_PHASES, {
    variables: {
      workspaceIdList,
    },
  });

  const { rows, maxValue } = useMemo(() => {
    const rows = data?.getWorkspaceOngoingPhases.items ?? [];
    const maxValue = max(rows, (d) => d.days) || 1;

    return {
      rows,
      maxValue,
    };
  }, [data]);

  return (
    <DashboardCard
      title={$t({ id: 'dashboard.ongoingPhases.title', defaultMessage: 'Top Ongoing Phases by Duration' })}
      subtitle={$t({ id: 'dashboard.widgetSubtitle.allTime', defaultMessage: 'All-time' })}
      hint={$t({
        id: 'dashboard.ongoingPhases.hint',
        defaultMessage: 'The duration of days that phases have been in the In Progress or Under Review status.',
      })}
      loading={loading}
    >
      <Table
        data={rows}
        size="sm"
        borderless
        tableLayout="fixed"
        rowKey={(row) => row.entity.vecticeId}
        emptyText={<NoData />}
      >
        <Column
          key="entity.parent"
          title={$t({ id: 'dashboard.ongoingPhases.column.project', defaultMessage: 'Project' })}
          ellipsis
        >
          {(project: Pick<Project, 'vecticeId' | 'name'>) => (
            <ProjectTooltip id={project.vecticeId}>
              <Typography
                component={Link}
                to={buildLink(VecticeRoutes.PROJECT, { projectId: project.vecticeId })}
                color="primary"
                link
              >
                {project.name}
              </Typography>
            </ProjectTooltip>
          )}
        </Column>
        <Column
          key="entity"
          title={$t({ id: 'dashboard.ongoingPhases.column.phase', defaultMessage: 'Phase' })}
          ellipsis
        >
          {(phase: Pick<Phase, 'vecticeId' | 'name'>) => (
            <PhaseTooltip id={phase.vecticeId}>
              <Typography
                component={Link}
                to={buildLink(VecticeRoutes.PHASE, { phaseId: phase.vecticeId })}
                color="primary"
                link
              >
                {phase.name}
              </Typography>
            </PhaseTooltip>
          )}
        </Column>
        <Column
          key="days"
          title={$t({ id: 'dashboard.ongoingPhases.column.daysOngoing', defaultMessage: 'Days Ongoing' })}
          width="200px"
        >
          {(days: number) => (
            <StackedBar
              data={[days]}
              getValue={getValue}
              getColor={getColor}
              maxValue={maxValue}
              rightContent={
                <Typography variant="callout" color="primary">
                  {days}
                </Typography>
              }
              margins={margins}
            />
          )}
        </Column>
        <Column
          key="entity.status"
          title={$t({ id: 'dashboard.ongoingPhases.column.phaseStatus', defaultMessage: 'Phase Status' })}
          width="120px"
        >
          {(status: DocumentationPageStatus) => (
            <Badge
              color={getDocumentationPageStatusColor(status) as BadgeColors}
              leftIcon={getDocumentationPageStatusIcon(status)}
              size="sm"
            >
              {getDocumentationPageStatusDisplayName(status)}
            </Badge>
          )}
        </Column>
        <Column
          key="entity.owner"
          title={$t({ id: 'dashboard.ongoingPhases.column.phaseOwner', defaultMessage: 'Owner' })}
          align="center"
          width="55px"
        >
          {(owner: UserIdentity) => !!owner && <UserAvatar user={owner} size="xs" />}
        </Column>
      </Table>
    </DashboardCard>
  );
};
