import cn from 'classnames';
import React, { useMemo } from 'react';

import { LazyImage } from '../lazy';
import { Typography } from '../typography';

import styles from './Thumbnail.module.scss';

export type ThumbnailSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

interface ThumbnailProps extends Omit<VecticeHTMLProps<HTMLImageElement>, 'size' | 'onError'> {
  size?: ThumbnailSizes;
  className?: string;
  src?: string;
  name?: string;
  initials?: string;
  color?: string;
  onError?: () => void;
}

export const Thumbnail = ({
  size = 'md',
  name,
  initials = '?',
  src,
  color,
  style,
  className,
  ...props
}: ThumbnailProps) => {
  const variant = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'footnote';
      case 'sm':
        return 'callout';
      case 'xxxl':
        return 'heading1';
      default:
        return 'body';
    }
  }, [size]);

  const fallback = (
    <Typography
      component="span"
      variant={variant}
      color={color ? 'white' : 'accent'}
      weight={size === 'sm' ? 'medium' : 'semi-bold'}
      className={cn(styles.initials, styles[size], className)}
      style={{ backgroundColor: color, ...style }}
      aria-label={name}
      {...props}
    >
      {initials}
    </Typography>
  );

  return (
    <LazyImage
      alt={name}
      className={cn(styles.thumbnail, styles[size], className)}
      errorFallback={fallback}
      fallback={fallback}
      src={src}
      {...props}
    />
  );
};
