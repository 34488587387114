import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType } from '../../../gql/graphql';
import { ProjectLink, WorkspaceLink } from '../../asset-display';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const WorkspaceActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const workspace = (
    <WorkspaceLink name={activity.targetName} resourceId={activity.targetVecticeId} type={activity.workspace?.type} />
  );

  const oldNameLink = (
    <WorkspaceLink name={activity.fromValue} resourceId={activity.targetVecticeId} type={activity.workspace?.type} />
  );

  const newNameLink = (
    <WorkspaceLink name={activity.toValue} resourceId={activity.targetVecticeId} type={activity.workspace?.type} />
  );

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.workspace.created',
          defaultMessage: '{user} created {workspace}',
          values: {
            user,
            workspace,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.workspace.deleted',
          defaultMessage: '{user} deleted {workspace}',
          values: {
            user,
            workspace,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Moved) {
    const project = <ProjectLink name={activity.sourceName} resourceId={activity.sourceVecticeId} />;
    const targetWorkspace = <WorkspaceLink name={activity.targetName} resourceId={activity.targetVecticeId} />;
    return (
      <>
        {$t({
          id: 'activities.workspace.moved',
          defaultMessage: '{user} moved {project} to {targetWorkspace}',
          values: {
            user,
            project,
            targetWorkspace,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.workspace.update.description',
            defaultMessage: '{user} updated the description of {workspace}',
            values: {
              user,
              workspace,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.workspace.update.name',
            defaultMessage: '{user} renamed {oldName} to {newName}',
            values: {
              user,
              oldName: oldNameLink,
              newName: newNameLink,
            },
          })}
        </>
      );
    }
  }

  return null;
};
