import { ElementType } from 'react';

import { ReactComponent as BitBucketIcon } from '../assets/icons/code/BitBucketIcon.svg';
import { ReactComponent as GitHubIcon } from '../assets/icons/code/GitHubIcon.svg';
import { ReactComponent as WhiteGitHubIcon } from '../assets/icons/code/GitHubIconWhite.svg';
import { ReactComponent as GitIcon } from '../assets/icons/code/GitIcon.svg';
import { ReactComponent as GitLabIcon } from '../assets/icons/code/GitLabIcon.svg';
import { ReactComponent as DataBricksIcon } from '../assets/icons/external/ic-databricks.svg';
import { ReactComponent as UnknownIcon } from '../assets/icons/files/ic-unkown.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/interface/ic-history.svg';
import { GitHost } from '../entities';
import { GitVersion } from '../gql/graphql';

import { validateURL } from './validation';

const getHTTPBaseUrl = (uri?: string | null) => {
  let url = uri;
  if (url) {
    if (url && url.startsWith('git@')) {
      // compatible with https://github.com, https://bitbucket.org and https://gitlab.
      url = url.replace(':', '/').replace('git@', 'https://');
    }
    if (url.endsWith('.git')) {
      url = url.substring(0, url.length - 4);
    }
  }
  return url || '';
};

const getGitHost = (uri?: string | null): GitHost | undefined => {
  if (!uri) return undefined;
  if (uri.toLowerCase().includes(GitHost.GITHUB.toLowerCase())) return GitHost.GITHUB;
  if (uri.toLowerCase().includes(GitHost.GITLAB.toLowerCase())) return GitHost.GITLAB;
  if (uri.toLowerCase().includes(GitHost.BITBUCKET.toLowerCase())) return GitHost.BITBUCKET;
  return undefined;
};

export const getOtherIcon = () => UnknownIcon;

export const getDataBricksHostIcon = () => DataBricksIcon;

export const getDataBricksSubIcon = () => HistoryIcon;

export const getGitHostIcon = (gitVersion?: Pick<GitVersion, 'uri'> | null, contrastedIcons?: boolean): ElementType => {
  const host = getGitHost(gitVersion?.uri);

  switch (host) {
    case GitHost.GITHUB:
      return contrastedIcons ? WhiteGitHubIcon : GitHubIcon;
    case GitHost.GITLAB:
      return GitLabIcon;
    case GitHost.BITBUCKET:
      return BitBucketIcon;
    default:
      return GitIcon;
  }
};

export const getGitHostLabel = (gitVersion?: Pick<GitVersion, 'uri'> | null): (() => string) => {
  const host = getGitHost(gitVersion?.uri);

  const gitHostRecord: Record<GitHost, () => string> = {
    [GitHost.GITHUB]: () => $t({ id: 'gitHost.github', defaultMessage: 'GitHub' }),
    [GitHost.GITLAB]: () => $t({ id: 'gitHost.gitlab', defaultMessage: 'GitLab' }),
    [GitHost.BITBUCKET]: () => $t({ id: 'gitHost.bitbucket', defaultMessage: 'BitBucket' }),
  };

  if (!host) return () => $t({ id: 'gitHost.git', defaultMessage: 'Git' });
  return gitHostRecord[host];
};

export const getGitHostLink = (gitVersion?: Pick<GitVersion, 'uri' | 'entrypoint' | 'commitHash'> | null) => {
  if (!gitVersion) {
    return undefined;
  }

  const gitHost = getGitHost(gitVersion?.uri || null);

  if (gitVersion && gitHost) {
    const url = getHTTPBaseUrl(gitVersion.uri);
    const relativePath = gitVersion.entrypoint || '';
    if (gitVersion.commitHash)
      return `${url}/${gitHost === GitHost.BITBUCKET ? 'commits' : 'commit'}/${gitVersion.commitHash}${
        relativePath !== '' ? `/${relativePath}` : ''
      }`;
    return `${url}/${relativePath}`;
  }

  const validGitVersionURI = !gitHost && validateURL(gitVersion?.uri || '');
  if (validGitVersionURI) {
    return validGitVersionURI;
  }

  return undefined;
};
