import { useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import type { AssetWidgetProps } from '../../../ui';

import { graphql } from '../../../gql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetWidget, FlexContainer, Icon, Typography } from '../../../ui';
import { getVecticeResourceTypeIcon, VecticeResourceType } from '../../../utils';

import { DatasetRow } from './DatasetRow';

export const GET_DATASET_WIDGET = graphql(`
  query getDatasetWidget($id: VecticeId!) {
    getDataset(datasetId: $id) {
      vecticeId
      ...datasetRow
    }
  }
`);

let globalId = 0;

interface DatasetWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary'> {
  id: string;
}

export const DatasetWidget = ({ id, ...props }: DatasetWidgetProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`dataset-widget-${globalId++}`);
  const { data, error, loading } = useQuery(GET_DATASET_WIDGET, {
    variables: { id },
  });

  const dataset = data?.getDataset;

  if (error) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        className="hide-pdf-export"
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getVecticeResourceTypeIcon(VecticeResourceType.DATASET)} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                {$t({ id: 'datasetWidget.asset.deleted', defaultMessage: 'Asset was deleted' })}
              </Typography>
            </FlexContainer>
          </div>
        }
        {...props}
        open={false}
        expandable={false}
      />
    );
  }

  return (
    <AssetWidget
      aria-labelledby={ariaId}
      loading={loading}
      summary={
        <Link to={buildLink(VecticeRoutes.DATASET, { datasetId: id })} target="_blank">
          <DatasetRow id={ariaId} dataset={dataset} interaction="tab" />
        </Link>
      }
      {...props}
    />
  );
};
