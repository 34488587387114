import React from 'react';

import { ReactComponent as CopyIcon } from '../../assets/icons/interface/ic-copy.svg';
import { Icon, Input, Placement } from '../../ui';
import { CommonProps, LabelProps, SizeProps } from '../../ui/form/interfaces';

import { WithCopyOnClick } from './WithCopyOnClick';

import styles from './CopyInput.module.scss';

type CopyInputProps = CommonProps<HTMLInputElement> &
  LabelProps &
  SizeProps & {
    iconLabel?: string;
    tooltipPlacement?: Placement;
  };

export const CopyInput = React.forwardRef(
  ({ iconLabel, tooltipPlacement, value, ...props }: CopyInputProps, ref: React.Ref<HTMLInputElement>) => (
    <Input
      value={value}
      disabled
      {...props}
      addonAfter={
        <WithCopyOnClick value={value} tooltipPlacement={tooltipPlacement}>
          <Icon
            aria-label={iconLabel || 'Copy input content'}
            role="button"
            icon={CopyIcon}
            size={20}
            className={styles.copy_icon}
          />
        </WithCopyOnClick>
      }
      ref={ref}
    />
  ),
);
