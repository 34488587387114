const HEXA_COLOR_REGEX = /^#?((?:[a-f\d]{2})(?:[a-f\d]{2})(?:[a-f\d]{2}))$/i;
const RGB_COLOR_REGEX = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/;

export const isHexaColorValid = (color?: string) => {
  if (!color) return false;
  return HEXA_COLOR_REGEX.exec(color) !== null;
};

export const isRGBColorValid = (color?: string) => {
  if (!color) return false;
  return RGB_COLOR_REGEX.exec(color) !== null;
};

export const getHexaInputValue = (color: string) => {
  const match = HEXA_COLOR_REGEX.exec(color);
  if (!match) return color;
  return match[1].toUpperCase();
};

export const hexaToRgb = (hexa?: string) => {
  if (isRGBColorValid(hexa)) return hexa as string;
  if (!hexa || !isHexaColorValid(hexa)) return null;
  const hex = hexa.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgb(${r},${g},${b})`;
};

export const rgbToHexa = (rgb?: string) => {
  if (isHexaColorValid(rgb)) return rgb as string;
  if (!rgb || !isRGBColorValid(rgb)) return null;
  const [r, g, b] = rgb
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((color) => parseInt(color).toString(16).padStart(2, '0'));
  return `#${r}${g}${b}`.toUpperCase();
};
