import { ReactNode } from 'react';

export class FilterNode<T> {
  props?: T;

  constructor(props?: T) {
    this.props = props;
  }

  updateProps(props?: T): void {
    this.props = props;
  }

  render(_filter: any, _onFilterUpdate: (field: string, filter: any) => void): ReactNode {
    throw new Error(`Render method of ${this.constructor.name} has no implementation`);
  }
}

export interface RegisteredPlugin {
  field: string;
  label: string;
  node: FilterNode<any>;
}

export interface CommonFilterProps {
  filter: any;
  label?: string;
  options?: {
    label: string;
    labelComponent?: ReactNode;
    icon?: ReactNode;
    value: any;
  }[];
  onFilterUpdate: (filter: any) => void;
}
