import React, { PropsWithChildren, ReactNode, Suspense, useState } from 'react';

interface WithImageError {
  onError?: () => void;
}

const imageCache = new Set();

function useSuspenseImage(src: string | undefined, onError?: () => void) {
  if (src && !imageCache.has(src)) {
    throw new Promise<void>((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        imageCache.add(src);
        resolve();
      };
      img.onerror = () => {
        onError?.();
        resolve();
      };
    });
  }
}

interface SuspenseImageProps extends Omit<VecticeHTMLProps<HTMLImageElement>, 'onError'>, WithImageError {}

const SuspenseImage = React.forwardRef(
  ({ alt, src, onError, ...props }: SuspenseImageProps, ref: React.Ref<HTMLImageElement>) => {
    useSuspenseImage(src, onError);
    return <img src={src} alt={alt} ref={ref} {...props} onError={onError} />;
  },
);

interface LazyImageProps extends Omit<VecticeHTMLProps<HTMLImageElement>, 'onError'>, WithImageError {
  errorFallback?: ReactNode;
  fallback?: ReactNode;
}

export const LazyImage = React.forwardRef(
  (
    { children, errorFallback = null, fallback = null, src, onError, ...props }: PropsWithChildren<LazyImageProps>,
    ref: React.Ref<HTMLImageElement>,
  ) => {
    const [hasError, setHasError] = useState(false);

    if (!src) {
      return <>{fallback}</>;
    }

    if (hasError) {
      return <>{errorFallback}</>;
    }

    return (
      <Suspense fallback={fallback}>
        <SuspenseImage
          src={src}
          ref={ref}
          onError={() => {
            setHasError(true);
            onError?.();
          }}
          {...props}
        />
        {children}
      </Suspense>
    );
  },
);
