import cn from 'classnames';
import RcPicker, { RangePicker as RcRangePicker } from 'rc-picker';
import defaultLocale from 'rc-picker/lib/locale/en_US';
import { PickerProps as RcPickerProps } from 'rc-picker/lib/Picker';
import { RangePickerProps as RcRangePickerProps } from 'rc-picker/lib/RangePicker';
import React from 'react';

import 'rc-picker/assets/index.css';

import styles from './DatePicker.module.scss';

interface DatePickerProps<DateType> extends Omit<RcPickerProps<DateType>, 'locale' | 'picker'> {
  dropdownClassName?: string;
}

export function DatePicker<DateType>({ className, ...props }: DatePickerProps<DateType>) {
  return (
    <RcPicker<DateType>
      inputReadOnly
      dropdownClassName={styles.picker_dropdown}
      className={cn(styles.picker, className)}
      locale={defaultLocale}
      {...props}
    />
  );
}

interface RangePickerProps<DateType> extends Omit<RcRangePickerProps<DateType>, 'locale'> {
  dropdownClassName?: string;
}

export function RangePicker<DateType>({ className, ...props }: RangePickerProps<DateType>) {
  return (
    <RcRangePicker<DateType>
      inputReadOnly
      dropdownClassName={styles.picker_dropdown}
      className={cn(styles.picker, className)}
      locale={defaultLocale}
      {...props}
    />
  );
}
