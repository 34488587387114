import csv from 'csvtojson';
import React, { useEffect, useState } from 'react';

import { Column, Pagination, Table, Typography, Loading } from '../../../ui';
import { NoData } from '../../no-results';

import styles from './CsvRenderer.module.scss';

const PAGE_SIZE = 30;

interface ColumnInterface {
  field: string;
}

interface DataFileRendererProps {
  content: string;
}

export const CsvRenderer = ({ content }: DataFileRendererProps) => {
  const [formatting, setFormatting] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableContent, setTableContent] = useState<{ columns: ColumnInterface[]; rows: any[] }>({
    columns: [],
    rows: [],
  });

  const prepareTable = async () => {
    if (content) {
      let fileContent: any[] = [];
      try {
        fileContent = JSON.parse(content);
      } catch {
        try {
          fileContent = await csv().fromString(content);
        } catch (e) {
          // do nothing here
        }
      }
      if (fileContent && fileContent.length > 0) {
        const parsedColumns = Object.keys(fileContent[0]).map((field) => ({ field }));
        setTableContent({ columns: parsedColumns, rows: fileContent });
      }
    }

    setFormatting(false);
  };

  useEffect(() => {
    setFormatting(true);
    setCurrentPage(0);
    prepareTable();
  }, [content]);

  if (formatting) return <Loading />;

  const slicedRows = tableContent.rows.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);

  const [firstColumn, ...columns] = tableContent.columns;

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <Table
          data={slicedRows}
          emptyText={<NoData />}
          rowKey={(_, index) => `data-preview-row-${index}`}
          scroll={{ x: true, y: true }}
          borderless
        >
          {firstColumn && <Column key={firstColumn.field} title={firstColumn.field.trim()} fixed ellipsis />}
          {columns.map((column) => (
            <Column key={column.field} title={column.field.trim()} ellipsis>
              {(data?: string | null) => (
                <Typography ellipsis className={styles.data}>
                  {data ?? '-'}
                </Typography>
              )}
            </Column>
          ))}
        </Table>
        <Pagination
          current={currentPage + 1}
          pageSize={PAGE_SIZE}
          total={tableContent.rows.length}
          onChange={(page) => setCurrentPage(page - 1)}
        />
      </div>
    </div>
  );
};
