import { useMutation } from '@apollo/client';

import { GENERATE_API_KEY } from '../graphql/queries';
import { message } from '../ui';

export const useApiKey = () => {
  const [generate] = useMutation(GENERATE_API_KEY, {
    refetchQueries: ['getApiKeyList'],
    onError: (error) => message.error(error.message),
  });

  const generateApiKey = async (name: string) =>
    await generate({
      variables: { apiKey: { name } },
    });

  return { generateApiKey };
};
