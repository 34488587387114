import React, { PropsWithChildren, ReactNode, useRef } from 'react';

import { Placement, Tooltip } from '../tooltip';
import { Typography } from '../typography';

import styles from './DataList.module.scss';

interface DataEntryProps {
  ellipsis?: boolean;
  label: ReactNode;
  tooltip?: ReactNode;
  tooltipPlacement?: Placement;
  verticalAlign?: 'center' | 'top';
  withTooltip?: boolean;
}

let globalId = 0;

export const DataEntry = ({
  children,
  ellipsis,
  label,
  tooltip,
  tooltipPlacement = 'topLeft',
  verticalAlign = 'center',
  withTooltip,
}: PropsWithChildren<DataEntryProps>) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`data-entry-${globalId++}`);

  return (
    <>
      <Tooltip text={withTooltip && label} placement={tooltipPlacement}>
        <Typography
          id={id}
          component="dt"
          variant="callout"
          color="secondary"
          className={styles[verticalAlign]}
          ellipsis={ellipsis}
        >
          {label}
        </Typography>
      </Tooltip>
      <Tooltip text={tooltip || (withTooltip && children)} placement={tooltipPlacement}>
        <Typography
          aria-labelledby={id}
          component="dd"
          variant="callout"
          color="tertiary"
          paragraph
          ellipsis={ellipsis}
        >
          {children}
        </Typography>
      </Tooltip>
    </>
  );
};
