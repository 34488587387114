import React from 'react';

import { useAuthentication } from '../../../context';
import { WorkspaceDeletedContent } from '../../../entities';
import { FormatUserName } from '../../formatters';
import { UserNotificationWorkspaceDeleted } from '../types';

import { Notification } from './Notification';

interface WorkspaceDeletedNotificationProps {
  notification: UserNotificationWorkspaceDeleted;
}

export const WorkspaceDeletedNotification = ({
  notification: { creator, content, createdDate },
}: WorkspaceDeletedNotificationProps) => {
  const { user } = useAuthentication();
  const parsedContent: WorkspaceDeletedContent = JSON.parse(content);
  const workspaceName = parsedContent?.workspaceName;

  return (
    <Notification creator={creator} createdDate={createdDate}>
      {user.id === creator?.id
        ? $t({
            id: 'notification.workspace.deleted.self',
            defaultMessage: '<bold>You</bold> deleted workspace <bold>{title}</bold>',
            values: {
              title: workspaceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
            },
          })
        : $t({
            id: 'notification.workspace.deleted',
            defaultMessage: '<bold>{creator}</bold> deleted workspace <bold>{title}</bold>',
            values: {
              creator: <FormatUserName user={creator} />,
              title: workspaceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' }),
            },
          })}
    </Notification>
  );
};
