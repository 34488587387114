import { useMutation, useQuery } from '@apollo/client';
import React, { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import type { Workspace, User, UserPreferencesInput } from '../gql/graphql';
import type { UserIdentity } from '../graphql/fragments';

import { UPDATE_USER_PREFERENCES } from '../graphql/mutations';
import { WHO_AM_I } from '../graphql/queries';
import { getRoutePath, VecticeRoutes } from '../routes';
import { broadcastClient } from '../services';
import { Launch } from '../ui/splashes/Launch';

export interface AuthenticationContextType {
  defaultWorkspace?: Pick<Workspace, 'vecticeId' | 'name' | 'type'> | null;
  user: Pick<
    User,
    'id' | 'email' | 'avatarId' | 'emailVerificationStatus' | 'name' | 'about' | 'role' | 'preferences'
  > &
    UserIdentity;
  updatePreferences?: (data: UserPreferencesInput) => Promise<any>;
}

export const AuthenticationContext = createContext<AuthenticationContextType | null>(null);

export const useAuthentication = () => {
  const context = React.useContext(AuthenticationContext);

  if (!context) {
    throw new Error('useAuthentication must be used within AuthenticationProvider');
  }

  return context;
};

export const AuthenticationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return broadcastClient.listen('logout', () => {
      navigate(getRoutePath(VecticeRoutes.LOGGED_OUT), { state: { referrer: location } });
    });
  }, []);

  const { data, loading } = useQuery(WHO_AM_I, {
    onCompleted: () => broadcastClient.post('login', true),
    onError: async ({ graphQLErrors }) => {
      if (graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].extensions) {
        if (
          graphQLErrors[0].extensions.code === 'UNAUTHENTICATED' &&
          graphQLErrors[0].message === 'User does not exists'
        ) {
          navigate(getRoutePath(VecticeRoutes.UNKNOWN_USER));
        }
      }
    },
  });

  const user = data?.whoAmI.user;
  const defaultWorkspace = data?.whoAmI.defaultWorkspace;

  const [updatePreferences] = useMutation(UPDATE_USER_PREFERENCES, {
    optimisticResponse: ({ data: { onboardingStatus } }) => ({
      updateUser: {
        preferences: {
          ...user?.preferences,
          onboardingStatus: onboardingStatus || [],
        },
      },
    }),
    update: (cache, { data: mutationData }) => {
      if (user && mutationData) {
        cache.updateQuery({ query: WHO_AM_I }, (data) =>
          data
            ? {
                whoAmI: {
                  ...data.whoAmI,
                  user: {
                    ...data.whoAmI.user,
                    preferences: {
                      ...user.preferences,
                      ...mutationData.updateUser.preferences,
                    },
                  },
                },
              }
            : undefined,
        );
      }
    },
  });

  useEffect(() => {
    return logger.setUser({ id: user?.id });
  }, [user?.id]);

  const contextValue = useMemo(
    () =>
      user
        ? {
            defaultWorkspace,
            user,
            updatePreferences: (data: UserPreferencesInput) => updatePreferences({ variables: { data } }),
          }
        : null,
    [defaultWorkspace, user, updatePreferences],
  );

  if (loading) {
    return <Launch />;
  }

  if (!user) {
    return (
      <Navigate
        replace
        state={{ referrer: location }}
        to={{
          pathname: getRoutePath(VecticeRoutes.LOGIN),
        }}
      />
    );
  }

  return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>;
};
