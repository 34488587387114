import cn from 'classnames';
import React, { useRef, useContext, ChangeEvent, PropsWithChildren } from 'react';

import { ReactComponent as RadioCheckedIcon } from '../../../assets/icons/interface/ic-radio-checked.svg';
import { ReactComponent as RadioIcon } from '../../../assets/icons/interface/ic-radio.svg';
import { Icon } from '../../svg';
import { Typography } from '../../typography';

import { RadioGroupContext } from './context';

import styles from './Radio.module.scss';
import formStyles from '../Form.module.scss';

let globalId = 0;

interface RadioProps extends Omit<VecticeHTMLProps<HTMLInputElement>, 'type' | 'id' | 'value' | 'children'> {
  value?: any;
  hideIcon?: boolean;
  gutterBottom?: boolean;
}

export const Radio = React.forwardRef(
  (
    {
      className,
      checked,
      disabled,
      name,
      value,
      hideIcon,
      gutterBottom,
      onChange,
      children,
      ...props
    }: PropsWithChildren<RadioProps>,
    ref: React.LegacyRef<HTMLInputElement>,
  ) => {
    const context = useContext(RadioGroupContext);

    const radioProps = {
      name: context?.name || name || 'generic-radio',
      checked: context ? context.value === value || checked || false : checked,
      disabled: disabled || context?.disabled,
    };

    // eslint-disable-next-line no-plusplus
    const { current: uniqueId } = useRef(`${radioProps.name}-${++globalId}`);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!radioProps.disabled) {
        onChange?.(e);
        context?.onChange?.(value, e);
      }
    };

    return (
      <Typography
        component="label"
        variant="callout"
        color={radioProps.disabled ? 'disabled' : 'primary'}
        className={cn(styles.label, { [formStyles.gutterBottom]: gutterBottom }, className)}
        htmlFor={uniqueId}
      >
        <input
          type="radio"
          name={radioProps.name}
          id={uniqueId}
          value={value}
          checked={radioProps.checked}
          disabled={radioProps.disabled}
          className={styles.input}
          onChange={handleChange}
          ref={ref}
          {...props}
        />
        {!hideIcon && (
          <>
            <Icon className={cn(styles.icon, styles.checked)} icon={RadioCheckedIcon} size={20} />
            <Icon className={cn(styles.icon, styles.unchecked)} icon={RadioIcon} size={20} />
          </>
        )}
        {children}
      </Typography>
    );
  },
);
