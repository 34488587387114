import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND, LexicalEditor } from 'lexical';
import { useEffect, useState } from 'react';

export const useEditorFocus = (editor: LexicalEditor) => {
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setHasFocus(false);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  );

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setHasFocus(true);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [],
  );

  return hasFocus;
};
