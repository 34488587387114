import React from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as SaveIcon } from '../../assets/icons/interface/ic-common-file-text.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/interface/ic-copy.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { ReactComponent as RefreshIcon } from '../../assets/icons/interface/ic-refresh.svg';
import { UserRole } from '../../gql/graphql';
import { useWithFeatureFlags, useWithRoles } from '../../guards';
import { FlexContainer, Menu, MenuDivider, MenuItem } from '../../ui';
import { ComingSoonBadge } from '../badges';

export type SelectedReportModal = 'rename' | 'delete' | 'duplicate' | 'templatize' | null;

interface ReportMenuProps {
  onOpenModal: (modal: SelectedReportModal) => void;
  showItems?: SelectedReportModal[];
}

export const ReportMenu = ({
  onOpenModal,
  showItems = ['rename', 'delete', 'duplicate', 'templatize'],
}: ReportMenuProps) => {
  const { allowed: isOrgAdmin } = useWithRoles({ organizationRole: UserRole.OrgAdmin });
  const { allowed: viewModelVersion } = useWithFeatureFlags({
    featureFlag: 'view-and-assign-model-version-to-report-in-report-page',
  });

  return (
    <Menu>
      {showItems.includes('rename') && (
        <MenuItem leftIcon={EditIcon} onClick={() => onOpenModal('rename')}>
          {viewModelVersion
            ? $t({ id: 'report.menu.edit', defaultMessage: 'Edit report' })
            : $t({ id: 'report.menu.rename', defaultMessage: 'Edit report name' })}
        </MenuItem>
      )}
      {showItems.includes('duplicate') && (
        <MenuItem leftIcon={DuplicateIcon} onClick={() => onOpenModal('duplicate')}>
          {$t({ id: 'ProjectCDTReports.actions.duplicate', defaultMessage: 'Duplicate report' })}
        </MenuItem>
      )}
      {showItems.includes('templatize') && (
        <MenuItem disabled={!isOrgAdmin} leftIcon={SaveIcon} onClick={() => onOpenModal('templatize')}>
          {$t({ id: 'ProjectCDTReports.actions.saveAsTemplate', defaultMessage: 'Save report as a template' })}
        </MenuItem>
      )}
      <MenuItem disabled leftIcon={RefreshIcon}>
        <FlexContainer gap={4}>
          {$t({ id: 'ProjectCDTReports.actions.refreshReport', defaultMessage: 'Refresh report' })}
          <ComingSoonBadge color="default" />
        </FlexContainer>
      </MenuItem>
      {showItems.includes('delete') && (
        <>
          <MenuDivider />
          <MenuItem leftIcon={DeleteIcon} color="danger" onClick={() => onOpenModal('delete')}>
            {$t({ id: 'report.menu.delete', defaultMessage: 'Delete report' })}
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
