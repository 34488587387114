import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType } from '../../../gql/graphql';
import { PhaseLink, ProjectLink, UserAvatar } from '../../asset-display';
import { FormatUserName } from '../../formatters';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

import styles from './Activity.module.scss';

export const ReviewActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const project = <ProjectLink name={activity.project?.name} resourceId={activity.project?.vecticeId} />;

  const phase = (
    <PhaseLink
      name={activity.parentName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
      resourceId={activity.parentVecticeId}
    />
  );

  const reviewer = (
    <>
      <UserAvatar user={activity.toUser} size="xs" className={styles.userAvatar} />
      <FormatUserName user={activity.toUser} />
    </>
  );

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.review.requested',
          defaultMessage: '{user} requested review on {phase} in {project} from {reviewer}',
          values: {
            user,
            phase,
            project,
            reviewer,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp && activity.updateType === ActivityUpdateType.Status) {
    if (activity.toValue === 'Cancelled') {
      return (
        <>
          {$t({
            id: 'activities.review.cancelled',
            defaultMessage: '{user} cancelled review on {phase} in {project}',
            values: {
              user,
              phase,
              project,
            },
          })}
        </>
      );
    }

    if (activity.toValue === 'Rejected') {
      return (
        <>
          {$t({
            id: 'activities.review.rejected',
            defaultMessage: '{user} rejected {phase} in {project}',
            values: {
              user,
              phase,
              project,
            },
          })}
        </>
      );
    }

    if (activity.toValue === 'Approved') {
      return (
        <>
          {$t({
            id: 'activities.review.approved',
            defaultMessage: '{user} approved {phase} in {project}',
            values: {
              user,
              phase,
              project,
            },
          })}
        </>
      );
    }
  }

  return null;
};
