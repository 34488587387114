import React, { PropsWithChildren, useRef } from 'react';

import { FlexContainer, Typography } from '../../ui';
import { HintTooltip } from '../tooltip';

import styles from './AssetDetail.module.scss';

let globalId = 0;

interface AssetDetailProps {
  label: React.ReactNode;
  hint?: string;
  className?: string;
}

export const AssetDetail = ({ label, hint, className, children }: PropsWithChildren<AssetDetailProps>) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`asset-detail-${globalId++}`);

  return (
    <div className={className}>
      <FlexContainer align="center" gap={8} className={styles.label}>
        <Typography id={ariaId} component="div" variant="footnote" color="tertiary" ellipsis>
          {label}
        </Typography>
        <HintTooltip hint={hint} />
      </FlexContainer>
      <Typography aria-labelledby={ariaId} component="div" variant="callout" color="primary" ellipsis>
        {children}
      </Typography>
    </div>
  );
};
