import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { graphql } from '../../../gql';
import { Tooltip } from '../../../ui';

import { UserCard } from './UserCard';

import styles from './UserTooltip.module.scss';

export const GET_USER_TOOLTIP = graphql(`
  query getUserTooltip($userId: Float!) {
    getOrganizationUser(userId: $userId) {
      id
      name
      avatarId
      email
      color
      role
      disabled
    }
  }
`);

interface UserTooltipProps {
  id?: string | null;
  hideTooltip?: boolean;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const UserTooltip = ({ id, placement = 'top', hideTooltip, children }: UserTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`user-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_USER_TOOLTIP, {
    skip: skipQuery || !id || hideTooltip,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: Number(id!),
    },
  });

  const user = data?.getOrganizationUser;

  useEffect(() => {
    setDataLoaded(!!user || !!error);
  }, [user, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (hideTooltip || !id) {
    return children;
  }

  const overlay = <div className={styles.overlay}>{user && <UserCard user={user} contrast />}</div>;

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
