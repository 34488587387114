import cn from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';

import { Typography } from '../typography';

import styles from './Navbar.module.scss';

interface NavbarProps extends VecticeHTMLProps<HTMLDivElement> {
  logo: ReactNode;
}

export const Navbar = ({ logo, className, children, ...props }: PropsWithChildren<NavbarProps>) => (
  <Typography
    component="nav"
    color="white"
    aria-label="Main navigation"
    className={cn(styles.navbar, className)}
    {...props}
  >
    <span className={styles.logo}>{logo}</span>
    {children}
  </Typography>
);
