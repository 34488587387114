import debounce from 'lodash/debounce';
import { useMemo, useRef } from 'react';

export const useDebounce = <T extends (...args: never[]) => void>(fn: T, wait: number, maxWait?: number) => {
  const fnRef = useRef<T | null>(null);
  fnRef.current = fn;

  return useMemo(
    () =>
      debounce(
        (...args: Parameters<T>) => {
          if (fnRef.current) {
            fnRef.current(...args);
          }
        },
        wait,
        { maxWait },
      ),
    [wait, maxWait],
  );
};
