import { graphql } from '../../gql';

export const GET_REVIEW_COMMENT_LIST = graphql(`
  query getReviewCommentList($filters: ReviewCommentFiltersInput!, $page: PageInput) {
    getReviewCommentList(filters: $filters, order: { field: "createdDate", direction: ASC }, page: $page) {
      items {
        id
        createdBy {
          ...userFields
        }
        createdDate
        content
      }
    }
  }
`);
