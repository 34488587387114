import { graphql } from '../../gql';

export const DATASET_VERSION = graphql(`
  query getDatasetVersion($datasetVersionId: VecticeId!) {
    getDatasetVersion(datasetVersionId: $datasetVersionId) {
      vecticeId
      versionNumber
      dataSet {
        vecticeId
        name
        project {
          vecticeId
        }
      }
    }
  }
`);
