import React, { ReactNode } from 'react';

export interface FormatUpdatedByProps {
  date?: ReactNode;
  user?: ReactNode;
}

export const FormatUpdatedBy = ({ date, user }: FormatUpdatedByProps) => {
  if (!user && !date) return null;

  if (!user) {
    return (
      <>
        {$t({
          id: 'components.formatters.FormatUpdatedBy.updatedAt',
          defaultMessage: 'Updated {date}',
          values: { date },
        })}
      </>
    );
  }

  if (!date) {
    return (
      <>
        {$t({
          id: 'components.formatters.FormatUpdatedBy.updatedBy',
          defaultMessage: 'Updated by {user}',
          values: { user },
        })}
      </>
    );
  }

  return (
    <>
      {$t({
        id: 'components.formatters.FormatUpdatedBy.updatedAtBy',
        defaultMessage: 'Updated {date} by {user}',
        values: { date, user },
      })}
    </>
  );
};
