import { graphql } from '../../gql';

export const IterationStepArtifactFragment = graphql(`
  fragment IterationStepArtifact on IterationStepArtifact {
    id
    text
    type
    index
    datasetVersion {
      vecticeId
      attachments(page: { size: 100, index: 1 }) {
        items {
          ...entityFileFragment
        }
      }
    }
    entityFileId
    entityFile {
      ...entityFileFragment
    }
    modelVersion {
      vecticeId
    }
    entityMetadata {
      id
      name
    }
  }
`);
