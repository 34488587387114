import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Typography } from '../../typography';

import styles from './CheckboxContainer.module.scss';
import formStyles from '../Form.module.scss';

interface Props extends VecticeHTMLProps<HTMLDivElement> {
  gutterBottom?: boolean;
  help?: ReactNode;
}

export const CheckboxContainer = ({ children, gutterBottom, help, ...props }: Props) => (
  <div className={cn(styles.checkboxContainer, { [formStyles.gutterBottom]: gutterBottom })} {...props}>
    {children}
    {help && (
      <Typography variant="footnote" color="tertiary" className={styles.checkboxHelp}>
        {help}
      </Typography>
    )}
  </div>
);
