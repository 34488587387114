import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getRoutePath, VecticeRoutes } from '../routes';
import { broadcastClient } from '../services';

interface LoggedOutProps {
  onHandleError?: () => void;
}

export const LoggedOutErrorPage = ({ onHandleError }: LoggedOutProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    broadcastClient.post('logout', true);
    onHandleError?.();
    navigate(getRoutePath(VecticeRoutes.LOGGED_OUT), {
      state: { referrer: location },
    });
  }, [onHandleError]);

  return null;
};
