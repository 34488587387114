import { graphql } from '../../gql';

export const GET_CONTRIBUTOR_LIST = graphql(`
  query getContributorList(
    $workspaceIdList: [VecticeId!]!
    $order: ListOrderInput
    $filters: ContributorListFiltersInput
    $page: PageInput = { size: 30, index: 1 }
  ) {
    getContributorList(workspaceIdList: $workspaceIdList, order: $order, filters: $filters, page: $page) {
      items {
        id
        name
        ...userFields
      }
    }
  }
`);
