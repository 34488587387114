import React from 'react';

import { Thumbnail, Typography } from '../../../ui';
import { getUserInitial } from '../../../utils';

import styles from './InviteCell.module.scss';

interface InviteCellProps {
  email?: string;
  hideTooltip?: boolean;
  showEmail?: boolean;
}

export const InviteCell = ({ email }: InviteCellProps) => {
  const initials = getUserInitial(email);

  return (
    <div className={styles.inviteCell}>
      <Thumbnail name={email} initials={initials} />
      <div className={styles.info}>
        <Typography variant="callout" weight="semi-bold" ellipsis>
          {email}
        </Typography>
      </div>
    </div>
  );
};
