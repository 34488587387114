import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '../typography';
interface Props {
  text: string;
}

const URL_MATCHER =
  /((?:(?:https?:\/\/(?:www\.)?)|(?:www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))/g;

export const AutoLinkText = ({ text }: Props) => {
  const textSplits = text.split(URL_MATCHER);
  const matchingUrls = [...text.matchAll(URL_MATCHER)].map((match) => match[0]);
  return (
    <>
      {textSplits.map((split, index) => {
        if (matchingUrls.includes(split)) {
          return (
            <Typography
              component={Link}
              key={index}
              to={`${!split.startsWith('http') ? 'http://' : ''}${split}`}
              variant="callout"
              color="accent"
              target="_blank"
              link
              ellipsis
            >
              {split}
            </Typography>
          );
        }
        return <span key={index}>{split}</span>;
      })}
    </>
  );
};
