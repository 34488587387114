import { useQuery } from '@apollo/client';
import React, { PropsWithChildren, useRef } from 'react';
import { Link } from 'react-router-dom';

import type { AssetWidgetProps } from '../../../ui';

import { graphql } from '../../../gql';
import { ArtifactType, EntityPropertyType } from '../../../gql/graphql';
import { useAttachments } from '../../../hooks';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetWidget, FlexContainer, Icon, Summary, Typography } from '../../../ui';
import { getVecticeResourceTypeIcon, VecticeResourceType } from '../../../utils';
import { Lineage } from '../../lineage/Lineage';
import { SummaryAttachments } from '../../summary-attachments';
import { VersionMetricsWidget, VersionPropertiesWidget } from '../../widgetComponents';

import { ModelVersionRow } from './ModelVersionRow';

import styles from './ModelVersionWidget.module.scss';

export const GET_MODEL_VERSION_WIDGET = graphql(`
  query getModelVersionWidget($id: VecticeId!) {
    getModelVersion(modelVersionId: $id) {
      vecticeId
      ...modelVersionRow
      properties {
        id
      }
      metrics {
        id
      }
      model {
        vecticeId
      }
      origin {
        id

        datasetInputs
      }
    }
  }
`);

let globalId = 0;

interface ModelWidgetProps extends Omit<AssetWidgetProps, 'id' | 'summary'> {
  id?: string;
  nodeKey?: string;
  showLineage?: boolean;
  showProperties?: boolean;
  showMetrics?: boolean;
  showAttachments?: boolean;
  onAutoDoc?: () => void;
}

export const ModelVersionWidget = ({
  children,
  id,
  nodeKey,
  actionsMenu,
  customizeMenu,
  showLineage = true,
  showProperties = true,
  showMetrics = true,
  showAttachments = true,
  onAutoDoc,
  ...props
}: PropsWithChildren<ModelWidgetProps>) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`model-version-widget-${globalId++}`);
  const { data, error, loading } = useQuery(GET_MODEL_VERSION_WIDGET, {
    skip: !id,
    variables: { id: id! },
  });

  const { attachments, total: attachmentsTotal } = useAttachments(id, VecticeResourceType.MODEL_VERSION);
  const modelVersion = data?.getModelVersion;

  if (error || !id) {
    return (
      <AssetWidget
        aria-labelledby={ariaId}
        className="hide-pdf-export"
        summary={
          <div>
            <FlexContainer gap={4}>
              <Icon icon={getVecticeResourceTypeIcon(VecticeResourceType.MODEL_VERSION)} size={20} />
              <Typography id={ariaId} variant="callout" color="danger">
                {$t({ id: 'modelVersionWidget.asset.deleted', defaultMessage: 'Asset was deleted' })}
              </Typography>
            </FlexContainer>
          </div>
        }
        actionsMenu={actionsMenu}
        {...props}
        open={false}
        expandable={false}
      />
    );
  }

  const isExpandable = modelVersion
    ? showLineage || showAttachments || showProperties || showMetrics || !!children
    : !!children;

  return (
    <AssetWidget
      aria-labelledby={ariaId}
      loading={loading}
      summary={
        <Link to={buildLink(VecticeRoutes.MODEL_VERSION, { modelVersionId: id })} target="_blank">
          <ModelVersionRow id={ariaId} modelVersion={modelVersion} onAutoDoc={onAutoDoc} interaction="tab" />
        </Link>
      }
      expandable={isExpandable}
      actionsMenu={customizeMenu}
      {...props}
    >
      {modelVersion && (
        <>
          {showLineage && (
            <Summary
              className={styles.lineage}
              open={!!modelVersion.origin}
              summary={
                <Typography component="h2" color="primary" variant="body" weight="semi-bold">
                  {$t({ id: 'modelVersionWidget.lineage', defaultMessage: 'Lineage' })}
                </Typography>
              }
              withBackground={false}
            >
              <Lineage
                datasetInputs={modelVersion.origin?.datasetInputs ?? []}
                lineageContext={{
                  artifactType: ArtifactType.Dataset,
                  lineageId: modelVersion?.origin?.id,
                  parentId: modelVersion.model.vecticeId,
                  versionId: id,
                }}
                lineageId={modelVersion?.origin?.id}
              />
            </Summary>
          )}
          {showMetrics && (
            <Summary
              className={styles.lineage}
              open={modelVersion.metrics.length > 0}
              summary={
                <Typography component="h2" color="primary" variant="body" weight="semi-bold">
                  {$t({ id: 'modelVersionWidget.metrics', defaultMessage: 'Metrics' })}
                </Typography>
              }
              withBackground={false}
            >
              <VersionMetricsWidget
                resourceId={id}
                emptyMessage={$t({
                  id: 'metrics.empty.doc.message',
                  defaultMessage: 'No Metrics',
                })}
              />
            </Summary>
          )}
          {showProperties && (
            <Summary
              className={styles.lineage}
              open={modelVersion.properties.length > 0}
              summary={
                <Typography component="h2" color="primary" variant="body" weight="semi-bold">
                  {$t({ id: 'modelVersionWidget.properties', defaultMessage: 'Properties' })}
                </Typography>
              }
              withBackground={false}
            >
              <VersionPropertiesWidget
                resourceId={id}
                entityType={EntityPropertyType.ModelVersion}
                emptyMessage={$t({
                  id: 'properties.empty.doc.message',
                  defaultMessage: 'No Properties',
                })}
              />
            </Summary>
          )}
          {showAttachments && (
            <SummaryAttachments nodeKey={nodeKey} open={attachmentsTotal > 0} attachments={attachments} />
          )}
        </>
      )}
      {children}
    </AssetWidget>
  );
};
