import React, { FormEvent, FocusEvent, KeyboardEvent, useCallback, useRef, useState, useEffect } from 'react';

import { Dropdown } from '../../dropdown';
import { Input } from '../input';
import { CommonProps, LabelProps, SizeProps } from '../interfaces';

import { Option, InputProps } from './interfaces';
import { ClearInput, OptionMenu } from './internal';
import { getOptionLabel, isOptionDisabled, naiveFilter } from './utils';

type ComboBoxProps<T extends Option | string> = InputProps<T> &
  Omit<CommonProps<HTMLInputElement>, 'value'> &
  LabelProps &
  SizeProps & { allowClear?: boolean };

let globalId = 0;

export const ComboBox = <T extends Option | string = string>({
  options,
  value = null,
  renderFn,
  onSearch,
  onSelectOption,
  noData,
  allowClear = true,
  ...inputProps
}: ComboBoxProps<T>) => {
  // eslint-disable-next-line no-plusplus
  const { current: menuId } = useRef(`combobox-menu-${globalId++}`);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const [internalOptions, setInternalOptions] = useState<T[]>(options);
  const [isVisible, setIsVisible] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState<string | null>(value);

  useEffect(() => {
    setInternalOptions(options);
    setHighlightedIndex(0);
  }, [options]);

  // Naive internal search
  useEffect(() => {
    if (!onSearch) {
      setInternalOptions(options.filter(naiveFilter(inputValue)));
      setHighlightedIndex(0);
    }
  }, [inputValue, onSearch]);

  // Controlled mode
  useEffect(() => {
    setInputValue(value || '');
    setSelectedValue(value);
  }, [value]);

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      inputProps.onChange?.(event);
      const newValue = event.currentTarget.value;
      setInputValue(newValue);
      // Reset selected value if the user empties the field
      if (!newValue) {
        setSelectedValue(null);
        onSelectOption?.(null);
      }
    },
    [inputProps?.onChange, onSearch, options],
  );

  const handleSearch = useCallback(
    async (search: string) => {
      inputProps.onDebouncedChange?.(search);
      if (onSearch) {
        // Handle spinner while refreshing data
        setIsSearching(true);
        setIsVisible(true);
        try {
          await onSearch(search);
        } finally {
          setIsSearching(false);
        }
      }
    },
    [inputProps.onDebouncedChange, onSearch],
  );

  const handleSelectOption = useCallback(
    (option: T) => () => {
      if (!isOptionDisabled(option) && !inputProps.disabled) {
        onSelectOption?.(option);
        setSelectedValue(getOptionLabel(option));
        setInputValue(getOptionLabel(option));
        setIsVisible(false);
        // Reset search on select to display all options when focusing again
        onSearch?.('');
      }
    },
    [onSelectOption, onSearch, inputProps.disabled],
  );

  const handleEnterKey = useCallback(() => {
    const highlightedOption = internalOptions[highlightedIndex];
    if (highlightedOption) {
      handleSelectOption(highlightedOption)();
      return true;
    }

    return false;
  }, [internalOptions, highlightedIndex, handleSelectOption]);

  const handleArrowKeys = useCallback(
    (direction: 'ArrowUp' | 'ArrowDown') => {
      setIsVisible(true);
      setHighlightedIndex((currentIndex) => {
        if (direction === 'ArrowUp') {
          return Math.max(currentIndex - 1, 0);
        } else {
          return Math.min(currentIndex + 1, internalOptions.length - 1);
        }
      });

      return true;
    },
    [internalOptions],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      inputProps.onKeyDown?.(e);
      if (e.key === 'Enter') {
        const eventHandled = handleEnterKey();
        if (eventHandled) {
          e.preventDefault();
        }
      }

      if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && !e.metaKey && !e.shiftKey) {
        const eventHandled = handleArrowKeys(e.key);
        if (eventHandled) {
          e.preventDefault();
        }
      }
    },
    [handleEnterKey, handleArrowKeys],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      inputProps.onBlur?.(e);
      if (!menuRef.current?.contains(e.relatedTarget)) {
        // Reset options on blur
        setInputValue(selectedValue || '');
        setIsVisible(false);
        onSearch?.('');
      } else {
        inputRef.current?.focus();
      }
    },
    [selectedValue, inputProps.onBlur, menuRef, onSearch],
  );

  const handleClear = useCallback(() => {
    setInputValue('');
    onSearch?.('');
    setSelectedValue(null);
    onSelectOption?.(null);
    inputRef.current?.focus();
  }, [onSearch, onSelectOption, inputRef]);

  return (
    <Dropdown
      overlay={
        <OptionMenu<T>
          id={menuId}
          currentValue={selectedValue}
          highlightedIndex={highlightedIndex}
          options={internalOptions}
          noData={noData}
          ref={menuRef}
          renderFn={renderFn}
          searching={isSearching}
          onSelect={handleSelectOption}
        />
      }
      trigger={[]}
      visible={isVisible}
      onVisibleChange={setIsVisible}
      showAction={['focus']}
    >
      <Input
        {...inputProps}
        ref={inputRef}
        value={inputValue}
        role="combobox"
        aria-haspopup="listbox"
        aria-owns={menuId}
        aria-autocomplete="list"
        aria-controls={menuId}
        aria-activedescendant={menuId}
        aria-expanded={isVisible}
        addonAfter={selectedValue && allowClear && <ClearInput onClear={handleClear} />}
        onChange={handleChange}
        onDebouncedChange={handleSearch}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </Dropdown>
  );
};
