import React from 'react';

import { FragmentType, graphql, useFragment } from '../../gql';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { Skeleton } from '../../ui';
import { UserAvatar } from '../asset-display';
import { AssetDescription, AssetDetail, AssetDetails, CreationDetails, WithCopyID } from '../assets';
import { FormatUserName } from '../formatters';
import { IterationStatus } from '../status';

import styles from './IterationSidebar.module.scss';

const IterationSidebarFragment = graphql(`
  fragment IterationSidebar on Iteration {
    vecticeId
    status
    description
    phase {
      vecticeId
      parent {
        vecticeId
        workspace {
          vecticeId
          type
        }
      }
    }
    owner {
      id
      ...userFields
      disabled
    }
    createdDate
    createdBy {
      id
      ...userFields
    }
    updatedDate
    lastUpdatedBy {
      id
      ...userFields
    }
  }
`);

interface IterationSidebarProps {
  iteration?: FragmentType<typeof IterationSidebarFragment>;
}

export const IterationSidebar = ({ iteration: iterationFragment }: IterationSidebarProps) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });
  const iteration = useFragment(IterationSidebarFragment, iterationFragment);

  const showSkeleton = !iteration;

  return (
    <>
      <AssetDetails
        label={$t({ id: 'iterationSidebar.iterationDetails', defaultMessage: 'Iteration Details' })}
        loading={showSkeleton}
        skeleton={<Skeleton.Paragraph rows={2} width={['200px', '150px']} />}
      >
        <AssetDetail label={$t({ id: 'iterationSidebar.iterationStatus', defaultMessage: 'Status' })}>
          <IterationStatus
            className={styles.trigger}
            disabled={!canEdit}
            iterationId={iteration?.vecticeId}
            status={iteration?.status}
          />
        </AssetDetail>
        <AssetDetail label={$t({ id: 'iterationSidebar.iterationOwner', defaultMessage: 'Iteration owner' })}>
          <UserAvatar user={iteration?.owner} size="xs" /> <FormatUserName user={iteration?.owner} />
        </AssetDetail>
        <AssetDetail label={$t({ id: 'iterationSidebar.iterationID', defaultMessage: 'Iteration ID' })}>
          <WithCopyID
            iconLabel={$t({ id: 'iterationSidebar.copyLabel', defaultMessage: 'Copy Iteration ID' })}
            idValue={iteration?.vecticeId}
          />
        </AssetDetail>

        {iteration && (
          <AssetDescription
            label={$t({ id: 'iterationSidebar.description', defaultMessage: 'Iteration description' })}
            description={iteration.description}
          />
        )}
      </AssetDetails>

      {iteration && (
        <CreationDetails
          createdDate={iteration.createdDate}
          createdBy={iteration.createdBy}
          updatedDate={iteration.updatedDate}
          updatedBy={iteration.lastUpdatedBy}
        />
      )}
    </>
  );
};
