import React from 'react';

import type { BadgeColors, BadgeProps } from '../../ui';

import { getIterationStatusDisplayName, getIterationStatusColor } from '../../entities/utils';
import { IterationStatus } from '../../gql/graphql';
import { Badge } from '../../ui';
import { getIterationStatusIcon } from '../../utils';

interface IterationStatusBadgeProps extends BadgeProps {
  status: IterationStatus;
}

export const IterationStatusBadge = ({ status, ...props }: IterationStatusBadgeProps) => (
  <Badge
    color={getIterationStatusColor(status) as BadgeColors}
    leftIcon={getIterationStatusIcon(status)}
    size="sm"
    {...props}
  >
    {getIterationStatusDisplayName(status)}
  </Badge>
);
