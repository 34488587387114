import cn from 'classnames';
import React, { ElementType } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '../svg';
import { Tooltip } from '../tooltip';
import { Typography, TypographyColors, TypographyVariants, TypographyWeights } from '../typography';

import styles from './Breadcrumbs.module.scss';

export interface BreadcrumbProps extends VecticeHTMLProps<HTMLElement> {
  icon?: ElementType;
  iconSize?: number;
  inert?: boolean;
  tooltip?: string;
  variant?: TypographyVariants;
  color?: TypographyColors;
  weight?: TypographyWeights;
  wide?: boolean;
}

export const Breadcrumb = <E extends ElementType>({
  children,
  className,
  component: Component = 'div',
  icon,
  iconSize = 18,
  inert,
  tooltip,
  variant = 'callout',
  color,
  wide,
  ...props
}: React.PropsWithChildren<BreadcrumbProps> & WithComponentProps<E>) => {
  const defaultColor = inert ? 'secondary' : 'accent';

  return (
    <Component className={cn(styles.breadcrumb, { [styles.wide]: wide }, className)}>
      {icon && <Icon className={styles.icon} icon={icon} size={iconSize} />}
      <Tooltip text={tooltip} placement="topLeft">
        <Typography
          className={styles.link}
          color={color ?? defaultColor}
          component={inert ? 'span' : Link}
          ellipsis
          link={!inert}
          variant={variant}
          {...props}
        >
          {children}
        </Typography>
      </Tooltip>
      <Typography className={styles.slash} component="span" variant={variant}>
        /
      </Typography>
    </Component>
  );
};
