import { useMutation } from '@apollo/client';
import React from 'react';

import { graphql } from '../../gql';
import { message } from '../../ui';

import { DeletionPrompt } from './DeletionPrompt';

const REMOVE_DOMAIN_FROM_ORGANIZATION = graphql(`
  mutation removeEmailDomainFromOrganization($organizationId: Float!, $emailDomain: String!) {
    removeEmailDomainFromOrganization(emailDomain: $emailDomain, organizationId: $organizationId) {
      id
      emailDomains {
        domain
      }
    }
  }
`);

interface DeleteEmailDomainModalProps {
  organizationId: number;
  domain: string;
  onClose: () => void;
}

export const DeleteEmailDomainModal = ({ domain, organizationId, onClose }: DeleteEmailDomainModalProps) => {
  const [removeEmailDomain, { loading }] = useMutation(REMOVE_DOMAIN_FROM_ORGANIZATION, {
    variables: {
      emailDomain: domain,
      organizationId,
    },
    update: (_, { data }) => {
      if (data) {
        onClose();
      }
    },
    onCompleted: (data) => {
      if (data && data.removeEmailDomainFromOrganization) {
        message.success(
          $t({
            id: 'administration.organization.domains.removed.success',
            defaultMessage: 'Domain has been successfully removed',
          }),
        );
      }
    },
    onError: (error) => message.error(error.message),
  });

  return (
    <DeletionPrompt
      processing={loading}
      resourceName={domain}
      title={$t({ id: 'emailDomain.modal.delete.title', defaultMessage: 'Delete Email domain' })}
      onClose={onClose}
      onConfirm={removeEmailDomain}
    />
  );
};
