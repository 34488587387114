import cn from 'classnames';
import React, { ChangeEvent, useRef } from 'react';

import { Typography } from '../../typography';

import { RadioGroupContextProvider } from './context';

import styles from './RadioGroup.module.scss';

interface RadioGroupProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'name' | 'onChange'> {
  name: string;
  value?: any;
  label?: string;
  onChange?: (value: any, event: ChangeEvent<HTMLInputElement>) => void;
}

let globalId = 0;

export const RadioGroup = ({
  name,
  value,
  label,
  disabled,
  className,
  onChange,
  children,
  ...props
}: RadioGroupProps) => {
  const { current: radioGroupId } = useRef(`${name || 'radioGroup'}-${globalId++}`);

  const contextValue = {
    name: radioGroupId,
    value,
    disabled,
    onChange,
  };

  return (
    <RadioGroupContextProvider value={contextValue}>
      {label && (
        <Typography
          id={radioGroupId}
          component="span"
          variant="callout"
          weight="semi-bold"
          color="primary"
          className={styles.itemLabel}
        >
          {label}
        </Typography>
      )}
      <div className={cn(styles.radio_group, className)} aria-labelledby={radioGroupId} {...props}>
        {children}
      </div>
    </RadioGroupContextProvider>
  );
};
