import React, { ElementType, useRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Button, Card, LinkAsButton, Typography } from '../../../ui';

import styles from './Card.module.scss';

let globalId = 0;

interface Props {
  buttonIcon?: ElementType;
  buttonLabel: React.ReactNode;
  hint: React.ReactNode;
  title: React.ReactNode;
  onClick?: () => void;
  to?: LinkProps['to'];
}

export const ActionCard = ({ buttonIcon: ButtonIcon, buttonLabel, hint, title, onClick, to }: Props) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`action-card-${globalId++}`);

  return (
    <Card aria-labelledby={id} inert className={styles.card}>
      <div className={styles.card_content}>
        <Typography id={id} weight="semi-bold" color="primary" paragraph gutterBottom>
          {title}
        </Typography>
        <Typography paragraph variant="callout" color="tertiary">
          {hint}
        </Typography>
      </div>
      {onClick && (
        <Button variant="white" size="sm" leftIcon={ButtonIcon} onClick={onClick}>
          {buttonLabel}
        </Button>
      )}
      {to && (
        <LinkAsButton component={Link} to={to} variant="white" size="sm" leftIcon={ButtonIcon}>
          {buttonLabel}
        </LinkAsButton>
      )}
    </Card>
  );
};
