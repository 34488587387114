import React from 'react';

import { useOrgConfig } from '../../../context';
import { OrganizationLevel } from '../../../gql/graphql';
import { Section, Typography, WithLoading } from '../../../ui';

import { GetStartedCards as RegularGetStarted, GetStartedSkeleton } from './GetStartedCards';
import { TrialHomeCards } from './TrialHomeCards';

export const GetStarted = () => {
  const { loading: orgConfigLoading, organization } = useOrgConfig();

  return (
    <Section
      aria-label="Discover Vectice"
      label={(ariaId) => (
        <Typography id={ariaId} component="h3" variant="body" weight="semi-bold">
          {$t({ id: 'home.getStarted.title', defaultMessage: 'Discover Vectice 🔎' })}
        </Typography>
      )}
    >
      <WithLoading loading={orgConfigLoading || !organization} skeleton={<GetStartedSkeleton />}>
        {organization.level === OrganizationLevel.Trial ? <TrialHomeCards /> : <RegularGetStarted />}
      </WithLoading>
    </Section>
  );
};
