import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';

import { graphql } from '../../gql';
import { CdtReport } from '../../gql/graphql';
import { useWithFeatureFlags } from '../../guards';
import { useValidateName, useVecticeForm } from '../../hooks';
import { useCheckReportNameAvailability } from '../../hooks/useCheckReportNameAvailability';
import { FormItemContainer, Input, message, ModalForm, WithAsyncValidation } from '../../ui';
import { defaultNameFieldConfiguration, VecticeResourceType } from '../../utils';
import { AssetSelector } from '../asset-selector';

export const UPDATE_REPORT = graphql(`
  mutation updateCDTReport($id: Float!, $data: CDTReportUpdateInput!) {
    updateCDTReport(id: $id, data: $data) {
      id
      name
      modelVersion {
        vecticeId
        name
      }
    }
  }
`);

export interface FormData {
  name: string;
  modelVersion?: string;
}

interface EditReportModalProps {
  report: Pick<CdtReport, 'id' | 'name' | 'modelVersion'>;
  projectId: string;
  onClose: () => void;
}

export const EditReportModal = ({ report, projectId, onClose }: EditReportModalProps) => {
  const { allowed: editModelVersion } = useWithFeatureFlags({
    featureFlag: 'view-and-assign-model-version-to-report-in-report-page',
  });
  const { control, formState, preSubmit, registerWithErrors, setError, trigger } = useVecticeForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      name: report.name,
      modelVersion: report.modelVersion?.vecticeId,
    },
  });
  const { hasErrors, isDirty, isSubmitting } = formState;

  const [updateReport] = useMutation(UPDATE_REPORT, {
    optimisticResponse: ({ id, data: { name } }) => ({
      updateCDTReport: {
        id,
        name: name ?? report!.name,
      },
    }),
    update: () => onClose(),
    onCompleted: ({ updateCDTReport: { name } }) =>
      message.success(
        $t({ id: 'report.modal.edit.success', defaultMessage: 'Report "{name}" has been updated', values: { name } }),
      ),
    onError: (error) => message.error(error.message),
  });

  const { checkCDTReportNameAvailability } = useCheckReportNameAvailability(projectId, report.id);
  const { validateName } = useValidateName(checkCDTReportNameAvailability, VecticeResourceType.CDT_REPORT);

  const handleSubmit = useCallback(
    async ({ name, modelVersion }: FormData) => {
      await updateReport({
        variables: {
          id: report.id,
          data: { name, modelVersionId: modelVersion || null },
        },
      });
    },
    [report, updateReport],
  );

  return (
    <ModalForm
      title={$t({ id: 'report.modal.rename.title', defaultMessage: 'Edit report' })}
      submitLabel={$t({ id: 'modal.save', defaultMessage: 'Save' })}
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(handleSubmit)}
      onClose={onClose}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <WithAsyncValidation
        validate={validateName}
        onSuccess={() => trigger('name')}
        onError={(error) => setError('name', error)}
      >
        <Input
          {...registerWithErrors('name', defaultNameFieldConfiguration())}
          label={$t({ id: 'form.reportEdit.nameLabel', defaultMessage: 'Report name' })}
          required
          autoFocus
          gutterBottom
        />
      </WithAsyncValidation>
      {editModelVersion && (
        <Controller
          control={control}
          name="modelVersion"
          rules={{
            required: false,
          }}
          render={({ field: { value, onChange } }) => (
            <FormItemContainer label={$t({ id: 'form.reportEdit.modelVersionLabel', defaultMessage: 'Model version' })}>
              <AssetSelector
                assetType={VecticeResourceType.MODEL_VERSION}
                fullWidth
                projectId={projectId}
                selectedAssetId={value}
                onSelect={(selectedId) => onChange(selectedId || null)}
              />
            </FormItemContainer>
          )}
        />
      )}
    </ModalForm>
  );
};
