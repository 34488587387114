import React from 'react';

import { getProjectStatusColor, getProjectStatusDisplayName } from '../../entities/utils';
import { ProjectStatus } from '../../gql/graphql';
import { Badge, BadgeColors, BadgeProps } from '../../ui';
import { getProjectStatusIcon } from '../../utils';

interface ProjectStatusBadgeProps extends BadgeProps {
  iconOnly?: boolean;
  status?: ProjectStatus | string | null;
  contrast?: boolean;
}

export const ProjectStatusBadge = ({
  iconOnly = false,
  size = 'sm',
  contrast = false,
  status,
  ...props
}: ProjectStatusBadgeProps) => (
  <Badge
    size={size}
    contrast={contrast}
    color={getProjectStatusColor(status) as BadgeColors}
    leftIcon={getProjectStatusIcon(status)}
    {...props}
  >
    {!iconOnly && getProjectStatusDisplayName(status)}
  </Badge>
);
