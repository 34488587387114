import { isNil } from './utils';
import { VecticeResourceType } from './vectice-resource-type';

const VECTICE_ID_FORMAT = /^(WSP|PRJ|PHA|ITR|REV|DTS|DTV|MDL|MDV)\-(\d+)$/i;
const WORKSPACE_ID_FORMAT = /^WSP\-(\d+)$/i;
const PROJECT_ID_FORMAT = /^PRJ\-(\d+)$/i;
const PHASE_ID_FORMAT = /^PHA\-(\d+)$/i;
const ITERATION_ID_FORMAT = /^ITR\-(\d+)$/i;
const DATASET_ID_FORMAT = /^DTS\-(\d+)$/i;
const DATASET_VERSION_ID_FORMAT = /^DTV\-(\d+)$/i;
const MODEL_ID_FORMAT = /^MDL\-(\d+)$/i;
const MODEL_VERSION_ID_FORMAT = /^MDV\-(\d+)$/i;

export const isVecticeId = (str?: string | null): boolean => {
  if (isNil(str)) {
    return false;
  }
  return VECTICE_ID_FORMAT.test(str);
};

export const isDatasetId = (str?: string | null): boolean => {
  if (isNil(str)) {
    return false;
  }
  return DATASET_ID_FORMAT.test(str);
};

export const isDatasetVersionId = (str?: string | null): boolean => {
  if (isNil(str)) {
    return false;
  }
  return DATASET_VERSION_ID_FORMAT.test(str);
};

export const isModelId = (str?: string | null): boolean => {
  if (isNil(str)) {
    return false;
  }
  return MODEL_ID_FORMAT.test(str);
};

export const isModelVersionId = (str?: string | null): boolean => {
  if (isNil(str)) {
    return false;
  }
  return MODEL_VERSION_ID_FORMAT.test(str);
};

export const getVecticeIdType = (str?: string | null): VecticeResourceType => {
  if (isNil(str)) {
    return VecticeResourceType.UNKNOWN;
  }

  if (WORKSPACE_ID_FORMAT.test(str)) return VecticeResourceType.WORKSPACE;
  if (PROJECT_ID_FORMAT.test(str)) return VecticeResourceType.PROJECT;
  if (PHASE_ID_FORMAT.test(str)) return VecticeResourceType.PHASE;
  if (ITERATION_ID_FORMAT.test(str)) return VecticeResourceType.ITERATION;
  if (DATASET_ID_FORMAT.test(str)) return VecticeResourceType.DATASET;
  if (DATASET_VERSION_ID_FORMAT.test(str)) return VecticeResourceType.DATASET_VERSION;
  if (MODEL_ID_FORMAT.test(str)) return VecticeResourceType.MODEL;
  if (MODEL_VERSION_ID_FORMAT.test(str)) return VecticeResourceType.MODEL_VERSION;

  return VecticeResourceType.UNKNOWN;
};
