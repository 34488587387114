import cn from 'classnames';
import React, { ReactNode, ElementType } from 'react';

import { ReactComponent as VecticeIcon } from '../../assets/brand/logo-solid.svg';
import { Icon } from '../svg';
import { Typography } from '../typography';

import styles from './Empty.module.scss';

interface EmptyFilterProps extends VecticeHTMLProps<HTMLDivElement> {
  hint?: ReactNode;
  icon?: ElementType;
  iconSize?: number;
  message: ReactNode;
}

export const EmptyData = ({
  hint,
  icon = VecticeIcon,
  iconSize = 72,
  message,
  className,
  ...props
}: EmptyFilterProps) => (
  <div className={cn(styles.empty, styles.borderless, className)} {...props}>
    {icon && <Icon icon={icon} size={iconSize} className={styles.icon} />}
    <Typography
      paragraph
      variant="body"
      weight="semi-bold"
      color="primary"
      align="center"
      className={styles.message_small}
    >
      {message}
    </Typography>
    {hint && (
      <Typography paragraph variant="callout" color="secondary" align="center" className={styles.hint_small}>
        {hint}
      </Typography>
    )}
  </div>
);
