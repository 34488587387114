import React, { useRef } from 'react';

import { useRegisterPlugin } from '../context';
import { FilterNode } from '../types';
import { CreatedDateFilterComponent } from '../ui';

export const pluginField = 'dueDate';

class FindingDueDateFilterNode<T> extends FilterNode<T> {
  render(filter: any, onFilterUpdate: (field: string, filter: any) => void) {
    return (
      <CreatedDateFilterComponent
        key={pluginField}
        filter={filter}
        disableDateAfterToday={false}
        onFilterUpdate={(value) => onFilterUpdate(pluginField, { ...value, field: pluginField })}
        options={[
          {
            label: $t({ id: 'filters.issueDueDateFilter.dueOn.label', defaultMessage: 'Due on' }),
            value: 'createdOn',
          },
          {
            label: $t({ id: 'filters.issueDueDateFilter.dateRange.label', defaultMessage: 'Date range' }),
            value: 'dateRange',
          },
        ]}
      />
    );
  }
}

export const FindingDueDateFilter = () => {
  const { current: node } = useRef(new FindingDueDateFilterNode());

  useRegisterPlugin({
    field: pluginField,
    label: $t({ id: 'filters.issueDueDateFilter.label', defaultMessage: 'Due date' }),
    node,
  });

  return null;
};
