import config from '../config';

export const getConnectionSnippet = (
  apiToken: string | undefined = 'API_TOKEN_FROM_VECTICE',
  host: string = config.ORIGIN,
) =>
  `%pip install -q vectice -U
import vectice

connect = vectice.connect(
  api_token="${apiToken}",
  host="${host}",
)`;

const setupSnippet = () =>
  `%pip install -q vectice -U
import vectice
from vectice import FileResource, Dataset, Model
connect = vectice.connect(
  api_token="API_KEY_FROM_VECTICE",
  host="${config.ORIGIN}"
)`;

export const setupSnippetIteration = (iterationId = 'ITR-XXXX') =>
  `${setupSnippet()}
iteration = connect.iteration("${iterationId}")
`;

export const setupSnippetPhase = (phaseId = 'PHA-XXXX') =>
  `${setupSnippet()}
phase = connect.phase("${phaseId}")
iteration = phase.create_or_get_current_iteration()
`;

export const autoDocumentNotesSnippet = () => `iteration.log("this is a comment")`;

export const autoDocumentDatasetSnippet = () =>
  `resource = FileResource(paths="your/dataset/path", dataframes=your_df)
dataset = Dataset.clean(resource=file_resource, name="your_dataset_name")
iteration.log(dataset)`;

export const autoDocumentModelSnippet = () =>
  `model = Model(metrics, properties, attachments, predictor)
iteration.log(model)`;
