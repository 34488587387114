import { graphql } from '../../gql/gql';

export const GET_PROJECTS_SELECTOR = graphql(`
  query getProjectsSelector(
    $workspaceId: VecticeId!
    $filters: ProjectListFiltersInput!
    $order: ListOrderInput
    $page: PageIndexInput
  ) {
    getProjectList(workspaceId: $workspaceId, filters: $filters, order: $order, page: $page) {
      items {
        name
        vecticeId
      }
    }
  }
`);
