import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { defaultActivityTypes, useUserActivities } from '../../hooks';
import { Section } from '../../ui';
import { getActivityTargetByResourceType, getActivityTargetFilterForIteration, VecticeResourceType } from '../../utils';
import { ActivityList } from '../activities';

export const IterationActivities = () => {
  const { iterationId } = useParams();
  const { current: iterationIds } = useRef(iterationId ? [iterationId] : []);
  const { activities, hasNextPage, loading, handleShowMore } = useUserActivities({
    activityTypes: defaultActivityTypes,
    targetFilter: getActivityTargetFilterForIteration(iterationIds),
    activityTargetTypes: getActivityTargetByResourceType(VecticeResourceType.ITERATION),
  });

  return (
    <Section label={$t({ id: 'iterationTabs.activity', defaultMessage: 'Iteration activity' })}>
      <ActivityList activities={activities} hasMore={hasNextPage} loading={loading} onShowMore={handleShowMore} />
    </Section>
  );
};
