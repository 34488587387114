import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { OnboardingStatus, OrganizationLevel } from '../gql/graphql';
import {
  LegacyTrialStep,
  VecticeStoredItem,
  clearLocalStorageItem,
  getLocalStorageItem,
  isDefined,
  isTrialOnboardingComplete,
  saveLocalStorageItem,
} from '../utils';

import { useAuthentication } from './Authentication';
import { useFeatureFlags, useOrgConfig } from './Config';

interface ProductContextType {
  isOnboardingEnabled: boolean;
  onboardingStatus?: OnboardingStatus[];
  showTrialModal?: boolean;
  closeTrialModal: () => void;
  updateOnboardingStatus: (step: OnboardingStatus) => void;
}

const defaultContext: ProductContextType = {
  isOnboardingEnabled: false,
  onboardingStatus: undefined,
  closeTrialModal: () => null,
  updateOnboardingStatus: () => null,
};

const ProductContext = createContext<ProductContextType>(defaultContext);

export const useProductContext = () => {
  const context = useContext<ProductContextType>(ProductContext as unknown as React.Context<ProductContextType>);

  if (context === undefined) {
    return defaultContext;
  }

  return context;
};

export const ProductContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { user, updatePreferences } = useAuthentication();
  const { loading: orgConfigLoading, organization } = useOrgConfig();

  const [showTrialModal, setShowTrialModal] = useState<boolean | undefined>(undefined); // undefined is required here to have trialTrailheadAcknowledgement updated

  const trialTrailheadAcknowledgement = getLocalStorageItem(
    VecticeStoredItem.VECTICE_FREE_TRIAL_TRAILHEAD_ACKNOWLEDGMENT,
  );

  // don't use useWithFeatureFlags here to avoid circular dependency
  const { featureFlags, loading: loadingFF } = useFeatureFlags();

  const onboardingStepsFF = !loadingFF && featureFlags?.find((flag) => flag.code === 'show-onboarding-steps')?.enabled;

  const isOnboardingEnabled =
    onboardingStepsFF ||
    (!orgConfigLoading && isDefined(organization) && organization.level === OrganizationLevel.Trial);

  useEffect(() => {
    if (!user || !updatePreferences) return;

    /**
     * The init step converts data from local storage to user preferences in database
     * The init step will be removable and no longer needed once the trial steps become outdated.
     */
    const init = async () => {
      const appTour = getLocalStorageItem(VecticeStoredItem.VECTICE_TRIAL_STEPS);

      if (appTour?.[user.id]) {
        let localStorageStatus = null;
        const steps = appTour[user.id].completed;
        if (steps) {
          if (steps.includes(LegacyTrialStep.ExploreSampleProject)) {
            localStorageStatus = OnboardingStatus.Step4;
          } else if (steps.includes(LegacyTrialStep.InviteColleagues)) {
            localStorageStatus = OnboardingStatus.Step3;
          } else if (steps.includes(LegacyTrialStep.ExploreQuickstartProject)) {
            localStorageStatus = OnboardingStatus.Step2;
          } else if (steps.includes(LegacyTrialStep.IntroductionVideo)) {
            localStorageStatus = OnboardingStatus.Step1;
          }
        }
        if (!localStorageStatus || user.preferences?.onboardingStatus?.includes(localStorageStatus)) {
          // local storage value can be ignored
          clearLocalStorageItem(VecticeStoredItem.VECTICE_TRIAL_STEPS);
          return;
        }
        if (!user.preferences?.onboardingStatus?.includes(localStorageStatus)) {
          let onboardingStatus: OnboardingStatus[] = [];
          switch (localStorageStatus) {
            case OnboardingStatus.Step1:
              onboardingStatus = [OnboardingStatus.Step1];
              break;
            case OnboardingStatus.Step2:
              onboardingStatus = [OnboardingStatus.Step1, OnboardingStatus.Step2];
              break;
            case OnboardingStatus.Step3:
              onboardingStatus = [OnboardingStatus.Step1, OnboardingStatus.Step2, OnboardingStatus.Step3];
              break;
            case OnboardingStatus.Step4:
              onboardingStatus = [
                OnboardingStatus.Step1,
                OnboardingStatus.Step2,
                OnboardingStatus.Step3,
                OnboardingStatus.Step4,
              ];
              break;
            default:
              onboardingStatus = [];
              break;
          }
          // Syncing value with user preferences and removing from local storage
          await updatePreferences({ onboardingStatus });
          clearLocalStorageItem(VecticeStoredItem.VECTICE_TRIAL_STEPS);
        }
      }
    };

    init();
  }, [user, updatePreferences]);

  const updateOnboardingStatus = useCallback(
    (step: OnboardingStatus) => {
      if (
        (step !== OnboardingStatus.Skipped && isTrialOnboardingComplete(user?.preferences?.onboardingStatus)) ||
        user?.preferences?.onboardingStatus?.includes(step)
      ) {
        return;
      }

      updatePreferences?.({ onboardingStatus: [...(user?.preferences?.onboardingStatus || []), step] });
    },
    [user],
  );

  const value = useMemo<ProductContextType>(
    () => ({
      isOnboardingEnabled,
      onboardingStatus: user?.preferences?.onboardingStatus,
      showTrialModal:
        trialTrailheadAcknowledgement !== 'true' &&
        showTrialModal !== false &&
        !user?.preferences?.onboardingStatus?.includes(OnboardingStatus.Skipped),
      closeTrialModal: () => {
        setShowTrialModal(false);
        saveLocalStorageItem(VecticeStoredItem.VECTICE_FREE_TRIAL_TRAILHEAD_ACKNOWLEDGMENT, 'true');
      },
      updateOnboardingStatus,
    }),
    [isOnboardingEnabled, showTrialModal, trialTrailheadAcknowledgement, user, updateOnboardingStatus],
  );

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
};
