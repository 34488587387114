import React from 'react';

import { EntityProperty } from '../../gql/graphql';
import { useVecticeForm } from '../../hooks';
import { FlexContainer, Input, ModalForm } from '../../ui';
import { defaultNameFieldConfiguration, defaultValueFieldConfiguration } from '../../utils';
import { ModelVersionStatusBadge } from '../badges';
import { Category } from '../model-version/ModelVersionAssetsContext';

interface PropertyFormModalProps {
  category?: Category;
  selectedProperty?: EntityProperty;
  onClose: () => void;
  onSubmit: (data: Pick<EntityProperty, 'key' | 'value'>) => Promise<any>;
}

export const PropertyFormModal = ({ category, selectedProperty, onClose, onSubmit }: PropertyFormModalProps) => {
  const { formState, preSubmit, registerWithErrors } = useVecticeForm({
    mode: 'onChange',
    defaultValues: {
      propertyName: selectedProperty?.key,
      propertyValue: selectedProperty?.value,
    },
  });
  const { isDirty, hasErrors, isSubmitting } = formState;

  let title: React.ReactNode = '';

  if (selectedProperty) {
    title = $t({ id: 'modal.propertyForm.updateResource', defaultMessage: 'Update property' });
  } else {
    title = category ? (
      <FlexContainer>
        {$t({
          id: 'modal.propertyForm.createResourceInCategory',
          defaultMessage: 'Create property in {category}',
          values: { category: <ModelVersionStatusBadge size="md" status={category} /> },
        })}
      </FlexContainer>
    ) : (
      $t({ id: 'modal.propertyForm.createResource', defaultMessage: 'Create property' })
    );
  }

  return (
    <ModalForm
      title={title}
      onClose={onClose}
      submitLabel={
        selectedProperty
          ? $t({ id: 'button.save', defaultMessage: 'Save' })
          : $t({ id: 'button.add', defaultMessage: 'Add' })
      }
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(async (data: { propertyName?: string; propertyValue?: string }) => {
        if (data.propertyName && data.propertyValue) {
          await onSubmit({ key: data.propertyName, value: data.propertyValue });
        }
      })}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <Input
        {...registerWithErrors('propertyName', defaultNameFieldConfiguration())}
        label={$t({ id: 'modal.propertyForm.propertyName', defaultMessage: 'Property Name' })}
        placeholder={$t({ id: 'modal.propertyForm.placeholder', defaultMessage: 'Type the property name' })}
        autoFocus
        gutterBottom
      />
      <Input
        {...registerWithErrors('propertyValue', defaultValueFieldConfiguration())}
        label={$t({ id: 'modal.propertyForm.valueLabel', defaultMessage: 'Value' })}
        placeholder={$t({ id: 'modal.propertyForm.valuePlaceholder', defaultMessage: 'Type value' })}
      />
    </ModalForm>
  );
};
