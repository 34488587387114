import React, { useRef } from 'react';

import { FragmentType, graphql, useFragment } from '../../../gql';
import { Workspace } from '../../../gql/graphql';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetListItem } from '../../../ui';

import { ReviewRow } from './ReviewRow';

const ReviewListItemFragment = graphql(`
  fragment reviewListItem on Review {
    vecticeId
    ...reviewRow
    phase {
      vecticeId
    }
  }
`);

let globalId = 0;

interface ReviewListItemProps {
  review: FragmentType<typeof ReviewListItemFragment>;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const ReviewListItem = ({ workspace, ...props }: ReviewListItemProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`review-list-item-${globalId++}`);
  const review = useFragment(ReviewListItemFragment, props.review);

  return (
    <AssetListItem
      to={buildLink(VecticeRoutes.PHASE_REVIEWS, { phaseId: review.phase.vecticeId })}
      aria-labelledby={ariaId}
    >
      <ReviewRow id={ariaId} review={review} workspace={workspace} />
    </AssetListItem>
  );
};
