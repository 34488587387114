import { graphql } from '../../gql';

export const CANCEL_REVIEW = graphql(`
  mutation cancelReview($id: VecticeId!) {
    cancelReview(id: $id) {
      vecticeId
      status
    }
  }
`);
