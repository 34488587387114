import React from 'react';

import { getModelTypeDisplayName } from '../../entities/utils';
import { ModelType } from '../../gql/graphql';
import { Badge, Icon } from '../../ui';
import { getModelTypeIcon } from '../../utils';

import styles from './ModelTypeBadge.module.scss';

interface ModelTypeBadgeProps {
  contrast?: boolean;
  type: ModelType;
}

export const ModelTypeBadge = ({ type, contrast }: ModelTypeBadgeProps) => {
  const typeIcon = getModelTypeIcon(type);

  return (
    <Badge size="sm" contrast={contrast}>
      {typeIcon && <Icon className={styles.icon} icon={typeIcon} size={12} />}
      {getModelTypeDisplayName(type)}
    </Badge>
  );
};
