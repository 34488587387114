import { useCallback, useEffect, useState } from 'react';

import type { CellSize } from './config';

import { isNil } from '../../utils';

import { tableBreakPoints } from './config';

export const useTableSizeObserver = (container: HTMLDivElement | null) => {
  const [cellSize, setCellSize] = useState<CellSize>('lg');
  const [sizeFactor, setSizeFactor] = useState<number>(1);

  useEffect(() => {
    if (isNil(container)) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === container) {
          const { width } = entry.contentRect;
          if (width < tableBreakPoints.small) {
            setCellSize('sm');
            setSizeFactor(0.6);
          } else if (width < tableBreakPoints.medium) {
            setCellSize('md');
            setSizeFactor(0.75);
          } else {
            setCellSize('lg');
            setSizeFactor(1);
          }
        }
      }
    });

    observer.observe(container);

    return () => observer.disconnect();
  }, [container]);

  const applySizeFactor = useCallback(
    (size: number | string) => {
      if (typeof size === 'string') return size;
      return sizeFactor * size;
    },
    [sizeFactor],
  );

  return { cellSize, applySizeFactor };
};
