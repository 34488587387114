import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as ProjectIcon } from '../../assets/icons/specials/flat/ic-project.svg';
import { getWorkspaceIcon, getWorkspaceTypeDisplayName } from '../../entities/utils';
import { AccessibilityLevel, UserRole } from '../../gql/graphql';
import { useWithRoles, WithFeatureFlags } from '../../guards';
import { useRetrievePageContext } from '../../hooks';
import { buildLink, getRoutePath, VecticeRoutes } from '../../routes';
import { FlexContainer, Icon, Skeleton, Tab, Tabs, Tooltip, Typography, WithLoading } from '../../ui';
import { ReadOnlyBadge } from '../badges/ReadOnlyBadge';

import { useIsTabActive } from './useIsTabActive';

import styles from './WorkspacePageHeader.module.scss';

export const ProjectNavigation = () => {
  const { projectId } = useParams();
  const { workspace, project, loading } = useRetrievePageContext();
  const { allowed: isViewer } = useWithRoles({ organizationRole: UserRole.Observer });
  const { isTabActive } = useIsTabActive();

  const linkParams = { projectId: projectId ?? project?.vecticeId };

  return (
    <>
      <div className={styles.title_wrapper}>
        <WithLoading
          loading={loading && !project}
          skeleton={
            <>
              <Skeleton.Paragraph variant="callout" width="150px" inline />
              <br />
              <Skeleton.Paragraph variant="subtitle" width="350px" inline />
            </>
          }
        >
          {workspace && (
            <FlexContainer gap={4}>
              <Typography
                component={workspace ? Link : 'span'}
                to={buildLink(VecticeRoutes.WORKSPACE, { workspaceId: workspace.vecticeId })}
                variant="callout"
                color="secondary"
                className={styles.workspace_link}
                link
              >
                <Tooltip text={getWorkspaceTypeDisplayName(workspace.type)} placement="top">
                  <Icon icon={getWorkspaceIcon(workspace.type, true)} size={14} />
                </Tooltip>
                <Typography ellipsis>{workspace.name}</Typography>
              </Typography>
              {!isViewer && workspace.userContext.accessibility === AccessibilityLevel.ReadOnly && <ReadOnlyBadge />}
            </FlexContainer>
          )}
          <div>
            <Link
              to={buildLink(VecticeRoutes.PROJECT, { projectId: linkParams.projectId })}
              className={styles.title_link}
            >
              <Typography component="h1" variant="subtitle" color="primary" link className={styles.title}>
                <Icon icon={ProjectIcon} size={20} />
                <Typography ellipsis>{project?.name}</Typography>
              </Typography>
            </Link>
          </div>
        </WithLoading>
      </div>
      <Tabs className={styles.tabs}>
        <Tab
          disabled={!linkParams.projectId}
          component={Link}
          to={buildLink(VecticeRoutes.PROJECT, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT), undefined, true)}
        >
          {$t({ id: 'navigation.project.tabs.overview', defaultMessage: 'Overview' })}
        </Tab>
        <Tab
          component={Link}
          disabled={!linkParams.projectId}
          to={buildLink(VecticeRoutes.PROJECT_PHASES, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_PHASES), [
            getRoutePath(VecticeRoutes.PHASE),
            getRoutePath(VecticeRoutes.ITERATION),
          ])}
        >
          {$t({ id: 'navigation.project.tabs.phases', defaultMessage: 'Phases' })}
        </Tab>
        <Tab
          disabled={!linkParams.projectId}
          component={Link}
          to={buildLink(VecticeRoutes.PROJECT_DATASETS, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_DATASETS), [
            getRoutePath(VecticeRoutes.DATASET),
            getRoutePath(VecticeRoutes.DATASET_VERSION),
          ])}
        >
          {$t({ id: 'navigation.project.tabs.datasets', defaultMessage: 'Datasets' })}
        </Tab>
        <Tab
          disabled={!linkParams.projectId}
          component={Link}
          to={buildLink(VecticeRoutes.PROJECT_MODELS, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_MODELS), [
            getRoutePath(VecticeRoutes.MODEL),
            getRoutePath(VecticeRoutes.MODEL_VERSION),
          ])}
        >
          {$t({ id: 'navigation.project.tabs.models', defaultMessage: 'Models' })}
        </Tab>
        <Tab
          disabled={!linkParams.projectId}
          component={Link}
          to={buildLink(VecticeRoutes.PROJECT_CDT_REPORTS, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_CDT_REPORTS), [
            getRoutePath(VecticeRoutes.CDT_REPORT),
          ])}
        >
          {$t({ id: 'navigation.project.tabs.reports', defaultMessage: 'Reports' })}
        </Tab>
        <WithFeatureFlags featureFlag="findings-tab">
          <Tab
            disabled={!linkParams.projectId}
            component={Link}
            to={buildLink(VecticeRoutes.PROJECT_FINDINGS, linkParams)}
            active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_FINDINGS), [getRoutePath(VecticeRoutes.FINDING)])}
          >
            {$t({ id: 'navigation.project.tabs.issues', defaultMessage: 'Issues' })}
          </Tab>
        </WithFeatureFlags>
        <Tab
          disabled={!linkParams.projectId}
          component={Link}
          to={buildLink(VecticeRoutes.PROJECT_ACTIVITY, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_ACTIVITY))}
        >
          {$t({ id: 'navigation.project.tabs.activity', defaultMessage: 'Project Activity' })}
        </Tab>
        <Tab
          disabled={!linkParams.projectId}
          component={Link}
          to={buildLink(VecticeRoutes.PROJECT_SETTINGS, linkParams)}
          active={isTabActive(getRoutePath(VecticeRoutes.PROJECT_SETTINGS))}
        >
          {$t({ id: 'navigation.project.tabs.settings', defaultMessage: 'Settings' })}
        </Tab>
      </Tabs>
    </>
  );
};
