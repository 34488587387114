import React, { ElementType, useRef } from 'react';

import { Button, Card, Typography } from '../../../ui';

import styles from './Card.module.scss';

let globalId = 0;

interface Props extends VecticeHTMLProps<HTMLDivElement> {
  buttonIcon?: ElementType;
  buttonLabel: React.ReactNode;
  hint: React.ReactNode;
  onClick: () => void;
}

export const DangerCard = ({ buttonIcon: ButtonIcon, buttonLabel, hint, onClick, ...props }: Props) => {
  // eslint-disable-next-line no-plusplus
  const { current: id } = useRef(`danger-card-${globalId++}`);

  return (
    <Card inert className={styles.card} aria-labelledby={id} {...props}>
      <div className={styles.card_content}>
        <Typography color="danger" weight="semi-bold" id={id} paragraph gutterBottom>
          {$t({ id: 'component.action.dangerCard.dangerZone', defaultMessage: 'Danger Zone' })}
        </Typography>
        <Typography paragraph variant="callout" color="tertiary">
          {hint}
        </Typography>
      </div>
      <Button variant="outlined" color="danger" size="sm" leftIcon={ButtonIcon} onClick={onClick}>
        {buttonLabel}
      </Button>
    </Card>
  );
};
