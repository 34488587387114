import React from 'react';

import { UserInvite, UserInviteStatus } from '../../gql/graphql';
import { getUserInviteLink } from '../../routes';
import { FlexContainer, Modal, Summary, Typography } from '../../ui';
import { CopyInput } from '../copy-on-click';

import styles from './InvitesSummaryModal.module.scss';

export type UserInviteType = {
  invite?: Pick<UserInvite, 'email' | 'inviteLinkToken'> | null;
  email: string;
  status: UserInviteStatus;
};

interface UserInviteModalProps {
  emailsEnabled?: boolean;
  invites: UserInviteType[];
  noWorkspaces: boolean;
  onClose: () => void;
}

export const InvitesSummaryModal = ({ emailsEnabled, noWorkspaces, invites, onClose }: UserInviteModalProps) => {
  const successfulInvites = invites
    .filter(
      ({ invite, status }) =>
        !!invite &&
        (status === UserInviteStatus.Success || (emailsEnabled && status === UserInviteStatus.InviteExists)),
    )
    .map(({ invite }) => invite);

  const blockedByDomainInvites = invites
    .filter(
      ({ invite, status }) =>
        !!invite &&
        (status === UserInviteStatus.CreatedEmailDomainBlocked ||
          (!emailsEnabled && status === UserInviteStatus.InviteExists)),
    )
    .map(({ invite }) => invite);

  const alreadyExistingUsers = invites.filter(({ status }) => status === UserInviteStatus.SuccessUserExists);

  const disabledUsers = invites.filter(({ status }) => status === UserInviteStatus.FailedUserDisabled);

  const renderCopyInput = (invite?: Pick<UserInvite, 'email' | 'inviteLinkToken'> | null) =>
    invite ? (
      <CopyInput key={invite.inviteLinkToken} label={invite.email} value={getUserInviteLink(invite.inviteLinkToken)} />
    ) : null;

  const renderEmailListItem = ({ email }: { email: string }) => (
    <Typography key={email} component="li" variant="callout">
      {email}
    </Typography>
  );

  return (
    <Modal
      title={$t({ id: 'modal.invitesSummary.invitationSummary', defaultMessage: 'Invitation Summary' })}
      closeLabel={$t({ id: 'modal.close', defaultMessage: 'Close' })}
      onClose={onClose}
    >
      <FlexContainer direction="column" gap={10}>
        {successfulInvites.length > 0 && (
          <>
            <Typography weight="semi-bold">
              {$t({
                id: 'invites.summary.sentInvitation',
                defaultMessage:
                  '{count, plural, one {Successfully sent an user invite!} other {Successfully sent # invitation links!}}',
                values: { count: successfulInvites.length },
              })}
            </Typography>
            <Typography variant="callout" className={styles.hint}>
              {$t({
                id: 'component.modals.sentInvitation.summary.hint',
                defaultMessage: 'They will receive an email to join your organization.',
              })}
            </Typography>
            <Summary
              summary={
                <Typography variant="callout">
                  {$t({
                    id: 'invites.summary.invitationLink',
                    defaultMessage: '{count, plural, one {Invitation Link} other {Invitation Links}}',
                    values: { count: successfulInvites.length },
                  })}
                </Typography>
              }
            >
              <FlexContainer direction="column" gap={16}>
                {successfulInvites.map(renderCopyInput)}
              </FlexContainer>
            </Summary>
          </>
        )}
        {blockedByDomainInvites.length > 0 && (
          <>
            <Typography weight="semi-bold">
              {$t({
                id: 'invites.summary.createdInvitations',
                defaultMessage:
                  '{count, plural, one {Successfully created an invitation link!} other {Successfully created # invitation links!}}',
                values: { count: blockedByDomainInvites.length },
              })}
            </Typography>
            <Typography variant="callout" className={styles.hint}>
              {$t({
                id: 'invites.summary.hint.createdInvitations',
                defaultMessage:
                  '{count, plural, one {In the list below, you can copy and send each invitation link to the corresponding user.} other {You can copy and send the invitation link to the corresponding user.}}',
                values: { count: blockedByDomainInvites.length },
              })}
            </Typography>
            <Summary
              summary={
                <Typography variant="callout">
                  {$t({
                    id: 'invites.summary.hint.invitationsLink',
                    defaultMessage: '{count, plural, one {Invitation Link} other {Invitation Links}}',
                    values: { count: blockedByDomainInvites.length },
                  })}
                </Typography>
              }
            >
              <FlexContainer direction="column" gap={16}>
                {blockedByDomainInvites.map(renderCopyInput)}
              </FlexContainer>
            </Summary>
          </>
        )}
        {alreadyExistingUsers.length > 0 && (
          <Summary
            expandable={alreadyExistingUsers.length > 1}
            summary={
              <Typography variant="callout">
                {noWorkspaces
                  ? $t({
                      id: 'invites.summary.noWorksapce.alreadyExistingUsers',
                      defaultMessage:
                        '{count, plural, one {"{email}" is already an active user.} other {The following # users are already active users.}}',
                      values: {
                        email: alreadyExistingUsers[0].email,
                        count: alreadyExistingUsers.length,
                      },
                    })
                  : $t({
                      id: 'invites.summary.alreadyExistingUsers',
                      defaultMessage:
                        '{count, plural, one {"{email}" is already an active user. You have now added them to the specified workspace(s).} other {The following # users are already active users. You have now added them to the specified workspace(s).}}',
                      values: {
                        email: alreadyExistingUsers[0].email,
                        count: alreadyExistingUsers.length,
                      },
                    })}
              </Typography>
            }
          >
            <ul>{alreadyExistingUsers.map(renderEmailListItem)}</ul>
          </Summary>
        )}
        {disabledUsers.length > 0 && (
          <Summary
            expandable={disabledUsers.length > 1}
            summary={
              <Typography variant="callout">
                {$t({
                  id: 'invites.summary.disabledUsers',
                  defaultMessage: `{count, plural, one {"{email}" is disabled from this organization. To re-enable this user, go back to your organization's users list.} other {The following # users are disabled from this organization. To re-enable the users, go back to your organization's users list.}}`,
                  values: {
                    email: disabledUsers[0].email,
                    count: disabledUsers.length,
                  },
                })}
              </Typography>
            }
          >
            <ul>{disabledUsers.map(renderEmailListItem)}</ul>
          </Summary>
        )}
      </FlexContainer>
    </Modal>
  );
};
