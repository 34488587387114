import React, { PropsWithChildren } from 'react';

import { GuardedContent } from './GuardedContent';
import { WithRolesProps } from './interfaces';
import { useWithRoles } from './useWithRoles';

export const WithRoles = ({ organizationRole, workspaceRole, children }: PropsWithChildren<WithRolesProps>) => {
  const { loading, error, allowed } = useWithRoles({ organizationRole, workspaceRole });

  return (
    <GuardedContent allowed={allowed} loading={loading} error={error}>
      {children}
    </GuardedContent>
  );
};
