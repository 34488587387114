import React from 'react';

import { useFragment } from '../../../gql';
import {
  ActivityType,
  ActivityUpdateType,
  ModelVersionApproval,
  ModelVersionRisk,
  ModelVersionStatus,
} from '../../../gql/graphql';
import { DatasetVersionLink, ModelLink, ModelVersionLink } from '../../asset-display';
import { ModelVersionApprovalBadge, ModelVersionRiskBadge, ModelVersionStatusBadge } from '../../badges';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

const ModelVersionStatusRanked = [
  ModelVersionStatus.Discarded,
  ModelVersionStatus.Retired,
  ModelVersionStatus.Experimentation,
  ModelVersionStatus.Staging,
  ModelVersionStatus.Production,
];

export const ModelVersionActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const modelVersion = <ModelVersionLink name={activity.targetName} resourceId={activity.targetVecticeId} />;
  const model = <ModelLink name={activity.parentName} resourceId={activity.parentVecticeId} />;
  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const oldStatus = <ModelVersionStatusBadge status={activity.fromValue as ModelVersionStatus} />;
  const newStatus = <ModelVersionStatusBadge status={activity.toValue as ModelVersionStatus} />;

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.modelVersion.created',
          defaultMessage: '{user} created {resource} of {parent}',
          values: {
            user,
            resource: modelVersion,
            parent: model,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.modelVersion.deleted',
          defaultMessage: '{user} deleted {resource} of {parent}',
          values: {
            user,
            resource: modelVersion,
            parent: model,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Properties || activity.type === ActivityType.Metrics) {
    const entityName = activity.type === ActivityType.Properties ? 'property' : 'metric';
    const entityNames = activity.type === ActivityType.Properties ? 'properties' : 'metrics';
    if (activity.updateType === ActivityUpdateType.Add) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.entity.add',
            defaultMessage: '{user} added a {entityName} to {resource} of {parent}',
            values: {
              user,
              entityName,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.MultipleAdd) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.entity.multipleAdd',
            defaultMessage: '{user} added {entityNames} to {resource} of {parent}',
            values: {
              user,
              entityNames,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Delete) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.entity.delete',
            defaultMessage: '{user} deleted a {entityName} to {resource} of {parent}',
            values: {
              user,
              entityName,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.MultipleDelete) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.propertyEntity.multipleDelete',
            defaultMessage: '{user} deleted {entityNames} to {resource} of {parent}',
            values: {
              user,
              entityNames,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.MultipleEdit) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.entity.multipleEdit',
            defaultMessage: '{user} edited {entityNames} to {resource} of {parent}',
            values: {
              user,
              entityNames,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Unknown) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.entity.edit',
            defaultMessage: '{user} edited a {entityName} to {resource} of {parent}',
            values: {
              user,
              entityName,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.AttachmentFile) {
    if (activity.updateType === ActivityUpdateType.Add) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.attachmentFile.add',
            defaultMessage: '{user} uploaded an attachment to {resource} of {parent}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.attachmentFile.rename',
            defaultMessage: '{user} renamed an attachment to {resource} of {parent}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Delete) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.attachmentFile.delete',
            defaultMessage: '{user} deleted an attachment to {resource} of {parent}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.update.description',
            defaultMessage: '{user} updated the description of {resource} of {parent}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.AlgorithmName) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.update.technique',
            defaultMessage: '{user} updated the technique of {resource} of {parent}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.InventoryReference) {
      return (
        <>
          {$t({
            id: 'activities.modelVersion.update.inventoryReference',
            defaultMessage: '{user} updated the model inventory ID of {resource} of {parent}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
            },
          })}
        </>
      );
    }
    if (activity.updateType === ActivityUpdateType.Star) {
      if (activity.toValue === 'true') {
        return (
          <>
            {$t({
              id: 'activities.modelVersion.update.AddToStarredList',
              defaultMessage: '{user} starred {resource} of {parent}',
              values: {
                user,
                resource: modelVersion,
                parent: model,
              },
            })}
          </>
        );
      } else {
        return (
          <>
            {$t({
              id: 'activities.modelVersion.update.RemoveFromStarredList',
              defaultMessage: '{user} unstarred {resource} of {parent}',
              values: {
                user,
                resource: modelVersion,
                parent: model,
              },
            })}
          </>
        );
      }
    }
    if (activity.updateType === ActivityUpdateType.Status) {
      if (
        ModelVersionStatusRanked.indexOf(activity.fromValue as ModelVersionStatus) <
        ModelVersionStatusRanked.indexOf(activity.toValue as ModelVersionStatus)
      ) {
        return (
          <>
            {$t({
              id: 'activities.modelVersion.update.status.promote',
              defaultMessage: '{user} promoted {resource} of {parent} from {oldStatus} to {newStatus}',
              values: {
                user,
                resource: modelVersion,
                parent: model,
                oldStatus,
                newStatus,
              },
            })}
          </>
        );
      }
      return (
        <>
          {$t({
            id: 'activities.modelVersion.update.status.demote',
            defaultMessage: '{user} demoted {resource} of {parent} from {oldStatus} to {newStatus}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
              oldStatus,
              newStatus,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Approval) {
      const oldApproval = <ModelVersionApprovalBadge approval={activity.fromValue as ModelVersionApproval} />;
      const newApproval = <ModelVersionApprovalBadge approval={activity.toValue as ModelVersionApproval} />;
      return (
        <>
          {$t({
            id: 'activities.modelVersion.update.approval',
            defaultMessage: '{user} updated the approval of {resource} in {parent} from {oldApproval} to {newApproval}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
              oldApproval,
              newApproval,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Risk) {
      const oldRisk = <ModelVersionRiskBadge risk={activity.fromValue as ModelVersionRisk} />;
      const newRisk = <ModelVersionRiskBadge risk={activity.toValue as ModelVersionRisk} />;
      return (
        <>
          {$t({
            id: 'activities.modelVersion.update.risk',
            defaultMessage: '{user} updated the risk of {resource} in {parent} from {oldRisk} to {newRisk}',
            values: {
              user,
              resource: modelVersion,
              parent: model,
              oldRisk,
              newRisk,
            },
          })}
        </>
      );
    }

    const source = (
      <DatasetVersionLink
        name={activity.sourceName || $t({ id: 'activities.untitled', defaultMessage: 'Untitled' })}
        resourceId={activity.sourceVecticeId}
      />
    );

    if (activity.updateType === ActivityUpdateType.LineageAdded) {
      return (
        <>
          {$t({
            id: 'activities.lineage.created',
            defaultMessage: '{user} added {dataset} to the input of {target} lineage',
            values: {
              user,
              dataset: source,
              target: modelVersion,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.LineageRemoved) {
      return (
        <>
          {$t({
            id: 'activities.lineage.removed',
            defaultMessage: '{user} removed {dataset} from the input of {target} lineage',
            values: {
              user,
              dataset: source,
              target: modelVersion,
            },
          })}
        </>
      );
    }
  }

  return null;
};
