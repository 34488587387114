import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import { AlgorithmNameOutput } from '../../../gql/graphql';
import { Checkbox, CheckboxGroup, Divider, EmptySelect, FilterLabel, FilterSearch, WithLoading } from '../../../ui';
import { CommonFilterProps } from '../types';

import { GET_UNIQUE_ALGORITHM_NAMES } from './getUniqueAlgorithmNames';

import styles from './Filters.module.scss';

export interface AlgorithmFilterComponentProps {
  workspaceIdList: string[];
  projectIdList?: string[];
  modelIdList?: string[];
}

type Props = CommonFilterProps & AlgorithmFilterComponentProps;

export const AlgorithmFilterComponent = ({
  workspaceIdList,
  projectIdList,
  modelIdList,
  filter,
  onFilterUpdate,
}: Props) => {
  const [search, setSearch] = useState('');

  const { data, loading } = useQuery(GET_UNIQUE_ALGORITHM_NAMES, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdList,
      projectIdList,
      modelIdList,
      search,
    },
  });

  const uniqueAlgorithmNames = useMemo(() => {
    const names = [...(data?.getUniqueAlgorithmNames.items ?? [])];

    return names.sort((a, b) => (!a.algorithmName ? 1 : !b.algorithmName ? -1 : 0));
  }, [data]);

  return (
    <>
      <FilterSearch initialSearch={search} onSearch={setSearch} />
      <Divider className={styles.divider} />
      <WithLoading loading={loading}>
        <CheckboxGroup
          name="algorithmNames-options"
          checkedValues={filter}
          onChange={(value) => onFilterUpdate(value.length ? value : undefined)}
          className={styles.options}
        >
          {uniqueAlgorithmNames.map(({ algorithmName }: AlgorithmNameOutput) => (
            <Checkbox
              key={algorithmName}
              label={
                <FilterLabel color={!algorithmName ? 'tertiary' : undefined}>
                  {algorithmName ??
                    $t({
                      id: 'filter.algorithmNames.noValue',
                      defaultMessage: 'N/A',
                    })}
                </FilterLabel>
              }
              className={styles.option}
              value={algorithmName}
            />
          ))}
        </CheckboxGroup>
        {search && !uniqueAlgorithmNames.length && <EmptySelect />}
      </WithLoading>
    </>
  );
};
