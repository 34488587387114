import { ProjectStatus } from '../../gql/graphql';
import { IconColors } from '../../ui';
import { getProjectStatusIcon } from '../../utils';

type projectStatusType = ProjectStatus | string | null;

export const getProjectStatusDisplayName = (status?: projectStatusType) => {
  switch (status) {
    case ProjectStatus.Completed:
      return $t({ id: 'projectSTatus.completed.label', defaultMessage: 'Completed' });
    case ProjectStatus.InProgress:
      return $t({ id: 'projectSTatus.inProgress.label', defaultMessage: 'In Progress' });
    case ProjectStatus.NotStarted:
      return $t({ id: 'projectSTatus.notStarted.label', defaultMessage: 'Not Started' });
    default:
      return status || $t({ id: 'projectSTatus.unknown.label', defaultMessage: 'Unknown Status' });
  }
};

export const getProjectStatusColor = (status?: projectStatusType): IconColors => {
  switch (status) {
    case ProjectStatus.InProgress:
      return 'on-going';
    case ProjectStatus.Completed:
      return 'success';
    case ProjectStatus.NotStarted:
    default:
      return 'default';
  }
};

export const getProjectStatusOptions = () => [
  {
    color: getProjectStatusColor(ProjectStatus.NotStarted),
    icon: getProjectStatusIcon(ProjectStatus.NotStarted),
    label: getProjectStatusDisplayName(ProjectStatus.NotStarted),
    value: ProjectStatus.NotStarted,
  },
  {
    color: getProjectStatusColor(ProjectStatus.InProgress),
    icon: getProjectStatusIcon(ProjectStatus.InProgress),
    label: getProjectStatusDisplayName(ProjectStatus.InProgress),
    value: ProjectStatus.InProgress,
  },
  {
    color: getProjectStatusColor(ProjectStatus.Completed),
    icon: getProjectStatusIcon(ProjectStatus.Completed),
    label: getProjectStatusDisplayName(ProjectStatus.Completed),
    value: ProjectStatus.Completed,
  },
];
