import React, { useState } from 'react';

import { ReactComponent as CopyIcon } from '../../assets/icons/interface/ic-copy.svg';
import { Project, Workspace } from '../../gql/graphql';
import { DuplicateProjectModal } from '../modals';

import { ActionCard } from './internals';

interface Props {
  project: Pick<Project, 'vecticeId' | 'name' | 'description'>;
  currentWorkspace?: Pick<Workspace, 'vecticeId' | 'name'> | null;
  onCheckAvailability: (value: string, targetWorkspaceId: string) => Promise<boolean>;
}

export const DuplicateProject = ({ project, currentWorkspace, onCheckAvailability }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ActionCard
        buttonIcon={CopyIcon}
        buttonLabel={$t({
          id: 'component.action.duplicateProject.button',
          defaultMessage: 'Duplicate Project',
        })}
        hint={$t({
          id: 'component.action.duplicateProject.hint',
          defaultMessage:
            'Duplicating a Project creates a new Project while preserving all the Phases, their Documentation and Requirements in the new Project. Iterations, models and datasets are not copied to the new Project',
        })}
        title={$t({
          id: 'component.action.duplicateProject.title',
          defaultMessage: 'Duplicate Project',
        })}
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <DuplicateProjectModal
          project={project}
          currentWorkspace={currentWorkspace}
          onCheckAvailability={onCheckAvailability}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
