import cn from 'classnames';
import { DrawerProps as RcDrawerProps } from 'rc-drawer';
import React, { PropsWithChildren, ReactNode } from 'react';

import styles from './PermanentDrawer.module.scss';

interface PersistentDrawerProps extends Omit<RcDrawerProps, 'width' | 'open' | 'onClose'> {
  drawerClassName?: string;
  drawerContent: ReactNode;
}

export const PermanentDrawer = ({
  className,
  children,
  drawerClassName,
  drawerContent,
  placement = 'right',
  ...props
}: PropsWithChildren<PersistentDrawerProps>) => {
  return (
    <div className={cn(styles.permanentDrawer, styles[placement], className)} {...props}>
      <aside className={cn(styles.drawer)}>
        <div className={cn(styles.drawerContent, drawerClassName)}>{drawerContent}</div>
      </aside>
      {children}
    </div>
  );
};
