import cn from 'classnames';
import { DrawerProps as RcDrawerProps } from 'rc-drawer';
import React, { PropsWithChildren, ReactNode } from 'react';

import styles from './HistoryDrawer.module.scss';

interface HistoryDrawerProps extends Omit<RcDrawerProps, 'width'> {
  drawerClassName?: string;
  drawerContent: ReactNode;
}

export const HistoryDrawer = ({
  className,
  children,
  drawerClassName,
  drawerContent,
  open,
  placement = 'right',
  ...props
}: PropsWithChildren<HistoryDrawerProps>) => (
  <div className={cn(styles.historyDrawer, styles[placement], { [styles.active]: open }, className)} {...props}>
    <div className={styles.content}>{children}</div>
    <aside className={cn(styles.drawer, drawerClassName)}>{drawerContent}</aside>
  </div>
);
