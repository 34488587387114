import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as DatasetIcon } from '../../../assets/icons/specials/ic-dataset.svg';
import { getDatasetTypeDisplayName } from '../../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { AssetRow, Icon, Typography } from '../../../ui';
import { getDatasetTypeIcon } from '../../../utils';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const DatasetRowFragment = graphql(`
  fragment datasetRow on DataSet {
    vecticeId
    name
    type
    versionCount
    updatedDate
    lastUpdatedBy {
      ...userFields
    }
  }
`);

interface DatasetRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  dataset?: FragmentType<typeof DatasetRowFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const DatasetRow = (props: DatasetRowProps) => {
  const dataset = useFragment(DatasetRowFragment, props.dataset);

  if (!dataset) {
    return null;
  }

  const typeIcon = getDatasetTypeIcon(dataset.type);

  return (
    <AssetRow icon={DatasetIcon} parents={props.project?.name} label={dataset.name} {...props}>
      <Typography variant="footnote" color="secondary">
        {dataset.vecticeId}
      </Typography>
      {props.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace name={props.workspace.name} iconSize={14} type={props.workspace.type} ellipsis />
        </Typography>
      )}
      <Typography variant="footnote" color="secondary">
        {typeIcon && <Icon icon={typeIcon} size={14} />} {getDatasetTypeDisplayName(dataset.type)}
      </Typography>
      <Typography variant="footnote" color="secondary">
        {$t({
          id: 'assetDisplay.dataset.versionCount.label',
          defaultMessage: '{versionCount, plural, one {# Version} other {# Versions}}',
          values: { versionCount: dataset.versionCount },
        })}
      </Typography>
      {!!dataset.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy
            date={<FormatDate date={dataset.updatedDate} fromNow />}
            user={<UserAvatar user={dataset.lastUpdatedBy} size="xs" />}
          />
        </Typography>
      )}
    </AssetRow>
  );
};
